

<!-- Modal : 스냅샷 생성 모달 -->
<div id="snapshot_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>스토리지 스냅샷 생성</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:25%">
                    <col style="width:75%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">스냅샷 이름</th>
                        <td>
                            <input type="text" [(ngModel)]="newSnapshotData.name" placeholder="내용을 입력해주세요" maxlength="60" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <textarea rows="5" [(ngModel)]="newSnapshotData.description" placeholder="내용을 입력해주세요" maxlength="255"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">대상 스토리지</th>
                        <td>
                            <select [(ngModel)]="newSnapshotData.volume_id" name="" required>
                              <option value="">-선택-</option>
                              <ng-template [ngIf]="volumeList">
                                  <option *ngFor="let volume of volumeList" [value]="volume.id">{{ volume.name }}</option>
                              </ng-template>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp2">
          <a (click)="createVolumeSnapshot()"  class="btn big ciblue mg_r4">생성</a>
          <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->