import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConsoleLoadBalancerSettingComponent } from './console-load-balancer-setting/console-load-balancer-setting.component';
import { ConsoleLoadBalancerListComponent } from './console-load-balancer-list/console-load-balancer-list.component';
import { ConsoleImageListComponent } from './console-image-list/console-image-list.component';
import { ConsoleIpListComponent } from './console-ip-list/console-ip-list.component';
import { ConsoleKeypairListComponent } from './console-keypair-list/console-keypair-list.component';
import { ConsoleServerListComponent } from './console-server-list/console-server-list.component';
import { ConsoleServerSettingComponent } from './console-server-setting/console-server-setting.component';
import { ConsoleStorageListComponent } from './console-storage-list/console-storage-list.component';
import { ConsoleSnapShotListComponent } from './console-snapShot-list/console-snapShotList.component';
import { ConsoleRuleManageListComponent } from './console-rule-manage-list/console-rule-manage-list.component';
import { ConsoleSecurityGroupListComponent } from './console-security-group-list/console-security-group-list.component';
import { ConsoleSubuserListComponent } from './console-subuser-list/console-subuser-list.component';
import { ConsoleDashboardComponent } from './console-dashboard/console-dashboard.component';
import { ConsoleNasListComponent } from './console-nas-list/console-nas-list.component';
import { ConsoleSubnetListComponent } from './console-subnet-list/console-subnet-list.component';
import { ConsoleNetworkListComponent } from './console-network-list/console-network-list.component';
import { ConsoleNavComponent } from './console-nav/console-nav.component';
import { ConsoleDbPostgresqlComponent } from './console-db-postgresql/console-db-postgresql.component';
import { ConsoleDbInstanceComponent } from './console-db-instance/console-db-instance.component';
import { ConsoleDbBlsComponent } from './console-db-bls/console-db-bls.component';
import { ConsoleDnsListComponent } from './console-dns-list/console-dns-list.component';
import { ConsoleCommercialDbmsComponent } from './console-commercial-dbms/console-commercial-dbms.component';
import { ConsoleCommercialDbmsSettingComponent } from './console-commercial-dbms-setting/console-commercial-dbms-setting.component';
import { ConsoleBackupDbComponent } from './console-backup-db/console-backup-db.component';
import { ConsoleBackupServerComponent } from './console-backup-server/console-backup-server.component';
import { ConsoleMessageQueueComponent } from './console-message-queue/console-message-queue.component';
import { ConsoleBaremetalListComponent } from './console-baremetal-list/console-baremetal-list.component';
import { ConsoleBaremetalSettingComponent } from './console-baremetal-setting/console-baremetal-setting.component';
import {ConsoleTestComponent} from './console-test/console-test.component';
import {ConsoleProjectListComponent} from './console-project-list/console-project-list.component';
/** 20200901 cbkim 사설네트워크 추가 */
import {ConsolePrivateNetworkListComponent} from './console-private-network-list/console-private-network-list.component';
// 20200908 ogto 네트워크/서버/스토리지 정보 추가
import {ConsoleNetworkStatusComponent} from './console-network-status/console-network-status.component';
import {ConsoleServerStatusComponent} from './console-server-status/console-server-status.component';
import {ConsoleStorageStatusComponent} from './console-storage-status/console-storage-status.component';
import {ConsoleProjectStatusComponent} from './console-project-status/console-project-status.component';


/** 20200910 mby 네트워크 토폴로지  */
// import {ConsoleTopologyComponent} from './console-topology/console-topology.component';

/** 20210106 ldh API Gateway  */
import { ConsoleApigatewayServiceComponent } from './console-apigateway-service/console-apigateway-service.component';
import { ConsoleApigatewayApiListComponent } from './console-apigateway-api-list/console-apigateway-api-list.component';
import { ConsoleApigatewayAuthListComponent } from './console-apigateway-auth-list/console-apigateway-auth-list.component';
import { ConsoleApigatewayUsageComponent } from './console-apigateway-usage/console-apigateway-usage.component';
import { ConsoleApigatewayApiSettingComponent } from './console-apigateway-api-setting/console-apigateway-api-setting.component';

// 20201123 boogle 오브젝트 스토리지 추가
import { ConsoleObjectStorageListComponent } from './console-objectstorage-list/console-objectstorage-list.component';

import { AuthGuard, LoginAuthGuard, ThemeGuard } from '../auth.guards';

import { ConsoleServerMonitorComponent } from './console-server-monitor/console-server-monitor.component';
import { ConsoleBatchComponent } from './console-batch/console-batch.component';

const routes: Routes = [
  {
    path: 'console',
    component: ConsoleNavComponent, canActivate:[LoginAuthGuard, AuthGuard, ThemeGuard],
    children: [
      { path: '', component: ConsoleDashboardComponent,canActivate:[ThemeGuard] },
      // { path: 'topology', component: ConsoleTopologyComponent },
      { path: 'image', component: ConsoleImageListComponent,canActivate:[ThemeGuard] },
      { path: 'publicip', component: ConsoleIpListComponent,canActivate:[ThemeGuard] },
      { path: 'keypair', component: ConsoleKeypairListComponent,canActivate:[ThemeGuard] },
      { path: 'server', component: ConsoleServerListComponent,canActivate:[ThemeGuard] },
      { path: 'server/create', component: ConsoleServerSettingComponent,canActivate:[ThemeGuard] },
      { path: 'server/monitor', component: ConsoleServerMonitorComponent,canActivate:[ThemeGuard]},
      { path: 'storage', component: ConsoleStorageListComponent,canActivate:[ThemeGuard] },
      { path: 'snapshot', component: ConsoleSnapShotListComponent,canActivate:[ThemeGuard] },
      { path: 'securitygroup', component: ConsoleSecurityGroupListComponent,canActivate:[ThemeGuard] },
      { path: 'securitygroup/ruleManage/:sgId', component: ConsoleRuleManageListComponent,canActivate:[ThemeGuard] },
      { path: 'loadbalancer', component: ConsoleLoadBalancerListComponent,canActivate:[ThemeGuard] },
      {
        path: 'loadbalancer/create',
        component: ConsoleLoadBalancerSettingComponent,canActivate:[ThemeGuard]
      },
      { path: 'subuser', component: ConsoleSubuserListComponent,canActivate:[ThemeGuard] },
      { path: 'nas', component: ConsoleNasListComponent,canActivate:[ThemeGuard] },
      { path: 'subnet', component: ConsoleSubnetListComponent,canActivate:[ThemeGuard] },
      { path: 'network', component: ConsoleNetworkListComponent,canActivate:[ThemeGuard] },
      { path: 'cloud', component: ConsoleDbPostgresqlComponent,canActivate:[ThemeGuard] },
      { path: 'instance', component: ConsoleDbInstanceComponent,canActivate:[ThemeGuard] },
      { path: 'blsdb', component: ConsoleDbBlsComponent,canActivate:[ThemeGuard] },
      { path: 'dns', component: ConsoleDnsListComponent,canActivate:[ThemeGuard] },
      { path: 'dbms', component: ConsoleCommercialDbmsComponent,canActivate:[ThemeGuard] },
      { path: 'dbms/create', component: ConsoleCommercialDbmsSettingComponent,canActivate:[ThemeGuard] },
      { path: 'serverbackup', component: ConsoleBackupServerComponent,canActivate:[ThemeGuard] },
      { path: 'dbbackup', component: ConsoleBackupDbComponent,canActivate:[ThemeGuard] },
      { path: 'messagequeue', component: ConsoleMessageQueueComponent,canActivate:[ThemeGuard] },
      { path: 'baremetal', component: ConsoleBaremetalListComponent,canActivate:[ThemeGuard] },
      { path: 'baremetal/create', component: ConsoleBaremetalSettingComponent,canActivate:[ThemeGuard] },
      { path: 'test',component:ConsoleTestComponent,canActivate:[ThemeGuard]},
      { path: 'project',component:ConsoleProjectListComponent,canActivate:[ThemeGuard]},
      /** 20200901 cbkim 사설네트워크 추가 */
      { path: 'privateNetwork',component:ConsolePrivateNetworkListComponent,canActivate:[ThemeGuard]},
      // 20200908 ogto 네트워크/서버/스토리지/프로젝트 정보 추가
      { path: 'networkStatus',component:ConsoleNetworkStatusComponent,canActivate:[ThemeGuard]},
      { path: 'serverStatus',component:ConsoleServerStatusComponent,canActivate:[ThemeGuard]},
      { path: 'storageStatus',component:ConsoleStorageStatusComponent,canActivate:[ThemeGuard]},
      { path: 'projectStatus',component:ConsoleProjectStatusComponent,canActivate:[ThemeGuard]},
      /** 20210106 ldh API Gateway  */
      { path: 'apigateway/service',component:ConsoleApigatewayServiceComponent,canActivate:[ThemeGuard]},
      { path: 'apigateway/api',component:ConsoleApigatewayApiListComponent,canActivate:[ThemeGuard]},
      { path: 'apigateway/api/create',component:ConsoleApigatewayApiSettingComponent,canActivate:[ThemeGuard]},
      { path: 'apigateway/api/auth',component:ConsoleApigatewayAuthListComponent,canActivate:[ThemeGuard]},
      { path: 'apigateway/api/usage',component:ConsoleApigatewayUsageComponent,canActivate:[ThemeGuard]},
      // 20201123 boogle 오브젝트 스토리지 추가
      { path: 'objectstorage', component: ConsoleObjectStorageListComponent,canActivate:[ThemeGuard] },
      { path: 'batch', component: ConsoleBatchComponent,canActivate:[ThemeGuard]}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConsoleRoutingModule {}
