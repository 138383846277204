<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>회원관리</h2>
		<div class="lineMap">
			회원관리 / <span>서브 계정 관리</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>서브 계정 관리</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block">
						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
								<colgroup>
									<col style="width: 60px;" />
									<col style="width: 250px;" />
									<col style="width: 200px;" />
									<col style="width: 200px;" />
									<col style="width: 100px;" />
									<col style="width: 100px;" />
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">ID</th>
										<th scope="col">회사</th>
										<th scope="col">프로젝트</th>
										<th scope="col">사용자 유형</th>
										<th scope="col">생성일</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let subUser of subUsers; index as i">
										<td>{{ i + 1 }}</td>
										<td>
											<a>{{ subUser.user_id }}</a>
											<input type="hidden" value="subUser.userId" />
										</td>
										<td>{{subUser.company_name === 'empty company' ? '개인' : subUser.company_name}}</td>
										<td><p *ngFor="let project of subUser.projects" >{{project.name}}</p></td>
										<td>
											<span *ngIf="subUser.type==1">운영담당자</span>
											<span *ngIf="subUser.type==2">회계담당자</span>
										</td>
										<td>{{ subUser.created_date.split("T")[0] }}</td>
									</tr>
								</tbody>
							</table>
							
							<!-- 페이징 -->
							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
						</div>
					</div>
				</div>
				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->