import { Component, OnInit,  AfterViewInit, Inject, Renderer2, getDebugNode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { AdminService } from '../../../admin.service';
import { getNumberDependable } from '@syncfusion/ej2-base';
import {FrontApiService} from '../../../frontapi.service';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
})
export class AdminNavComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private api: AdminService,
    private frontapi:FrontApiService,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'console');
    this.getThemes();

    this.frontapi.currentMessage.subscribe((message:any) => {
      if (message.type == "theme") {
        this.getThemes();
      }
    }, (error) => {

    });
  }

  getThemes() {
    this.api.getThemes().subscribe((data: {}) => {
      this.kinLookup(data);
      // this.kinLookupFont(data);
    });
  }

  getThemeFont() {
    this.api.getThemes().subscribe((data: {}) => {
      this.kinLookupFont(data);
    });
  }

  kinLookup(gubn) {
    var wrap = document.querySelector('.console');
    var leftMenu = document.querySelector('.menu_inner');
    var consolebody = document.querySelectorAll('.lyBox');
    var logo = document.querySelector('.logo');
    var modal_h = document.querySelectorAll('.modal_header');
    var modal_c = document.querySelectorAll('.modal_content');
    var leftFix = document.querySelectorAll('.leftFix');
    var tooltip_h = document.querySelectorAll('.tooltip-head');
    var tooltip_c = document.querySelectorAll('.tooltip-content');
    var createBox = document.querySelectorAll('.createBox');
    var button = document.querySelectorAll('.btn.blue');
    var leftnoneBar = document.querySelectorAll('.subMenu');
    var leftBar = document.querySelectorAll('.on');
    var leftButton = document.querySelectorAll('.lnb');

    var _p = document.querySelectorAll('p');
    var _a = document.querySelectorAll('a');
    var _i = document.querySelectorAll('i');
    var _h2 = document.querySelectorAll('h2');
    var _h3 = document.querySelectorAll('h3');
    var _td = document.querySelectorAll('td');
    var _th = document.querySelectorAll('th');
    var _small = document.querySelectorAll('small');
    var _span = document.querySelectorAll('span');
    var _select = document.querySelectorAll('select');
    
    if(gubn == "1") {

      wrap.classList.remove('darkTheme');
      wrap.classList.remove('navyTheme');

      leftMenu.classList.remove('seeTheme');
      leftMenu.classList.remove('cosmicTheme');

      logo.classList.remove('lightlogo');

      var i=0;
      for(i=0;i<button.length;i++) {
        button[i].classList.add('darkButton');
      }
      for(i=0;i<leftFix.length;i++) {
        leftFix[i].classList.remove('seeTheme');
        leftFix[i].classList.remove('cosmicTheme');
      }
      for(i=0;i<consolebody.length;i++) {
        consolebody[i].classList.remove('seeTheme');
        consolebody[i].classList.remove('cosmicTheme');
      }
      for(i=0;i<tooltip_h.length;i++) {
        tooltip_h[i].classList.remove('seeTheme');
        tooltip_h[i].classList.remove('cosmicTheme');
        tooltip_c[i].classList.remove('seeTheme');
        tooltip_c[i].classList.remove('cosmicTheme');
      }
      for(i=0;i<modal_h.length;i++) {
        modal_h[i].classList.remove('seeTheme');
        modal_h[i].classList.remove('cosmicTheme');
        modal_c[i].classList.remove('seeTheme');
        modal_c[i].classList.remove('cosmicTheme');
      }
      for(i=0;i<createBox.length;i++) {
      createBox[i].classList.remove('seeTheme');
        createBox[i].classList.remove('cosmicTheme');
      }
      for(i=0;i<leftnoneBar.length;i++) {
        leftnoneBar[i].classList.remove('darkT');
        leftnoneBar[i].classList.remove('cosmicT');
      }
      for(i=0;i<leftBar.length;i++) {
        leftBar[i].classList.remove('darkT');
        leftBar[i].classList.remove('cosmicT');
      }
      for(i=0;i<leftButton.length;i++) {
        leftButton[i].classList.remove('darkT');
        leftButton[i].classList.remove('cosmicT');
      }
      for(i=0;i<_p.length;i++) {
        _p[i].style.color = '';
      }
      for(i=0;i<_a.length;i++) {
        _a[i].style.color = '';
      }
      for(i=0;i<_i.length;i++) {
        _i[i].style.color = '';
      }
      for(i=0;i<_h2.length;i++) {
        _h2[i].style.color = '';
      }
      for(i=0;i<_h3.length;i++) {
        _h3[i].style.color = '';
      }
      for(i=0;i<_th.length;i++) {
        _th[i].style.color = '';
        _th[i].style.background = '';
      }
      for(i=0;i<_td.length;i++) {
        _td[i].style.color = '';
      }
      for(i=0;i<_span.length;i++) {
        _span[i].style.color = '';
      }
      for(i=0;i<_small.length;i++) {
        _small[i].style.color = '';
      }
      for(i=0;i<_select.length;i++) {
        _select[i].style.color = '';
        _select[i].style.background = '';
      }

    } else if(gubn == "2") {
      wrap.classList.remove('navyTheme');
      wrap.classList.add('darkTheme');

      leftMenu.classList.remove('cosmicTheme');
      leftMenu.classList.add('seeTheme');

      logo.classList.add('lightlogo');

      var i=0;
      for(i=0;i<button.length;i++) {
        button[i].classList.add('darkButton');
      }
      for(i=0;i<leftFix.length;i++) {
        leftFix[i].classList.remove('cosmicTheme');
        leftFix[i].classList.add('seeTheme');
      }
      for(i=0;i<consolebody.length;i++) {
        consolebody[i].classList.remove('cosmicTheme');
        consolebody[i].classList.add('seeTheme');
      }
      for(i=0;i<tooltip_h.length;i++) {
        tooltip_h[i].classList.remove('cosmicTheme');
        tooltip_h[i].classList.add('seeTheme');
        tooltip_c[i].classList.remove('cosmicTheme');
        tooltip_c[i].classList.add('seeTheme');
      }
      for(i=0;i<modal_h.length;i++) {
        modal_h[i].classList.remove('cosmicTheme');
        modal_h[i].classList.add('seeTheme');
        modal_c[i].classList.remove('cosmicTheme');
        modal_c[i].classList.add('seeTheme');
      }
      for(i=0;i<createBox.length;i++) {
        createBox[i].classList.remove('cosmicTheme');
        createBox[i].classList.add('seeTheme');
      }
      for(i=0;i<_p.length;i++) {
        _p[i].style.color = '#eee';
      }
      for(i=0;i<_a.length;i++) {
        _a[i].style.color = '#eee';
      }
      for(i=0;i<_i.length;i++) {
        _i[i].style.color = '#eee';
      }
      for(i=0;i<_h2.length;i++) {
        _h2[i].style.color = '#eee';
      }
      for(i=0;i<_h3.length;i++) {
        _h3[i].style.color = '#eee';
      }
      for(i=0;i<_th.length;i++) {
        _th[i].style.color = '#eee';
        _th[i].style.background = '#222b45';
      }
      for(i=0;i<_td.length;i++) {
        _td[i].style.color = '#eee';
      }
      for(i=0;i<_span.length;i++) {
        _span[i].style.color = '#eee';
      }
      for(i=0;i<_small.length;i++) {
        _small[i].style.color = '#eee';
      }
      for(i=0;i<_select.length;i++) {
        _select[i].style.color = '#eee';
        _select[i].style.background = '#222b45';
      }
      for(i=0;i<leftnoneBar.length;i++) {
        leftnoneBar[i].classList.remove('cosmicT');
        leftnoneBar[i].classList.add('darkT');
      }
      for(i=0;i<leftBar.length;i++) {
        leftBar[i].classList.remove('cosmicT');
        leftBar[i].classList.add('darkT');
      }
      for(i=0;i<leftButton.length;i++) {
        leftButton[i].classList.remove('cosmicT');
        leftButton[i].classList.add('darkT');
      }

    } else if(gubn == "3") {

      wrap.classList.remove('darkTheme');
      wrap.classList.add('navyTheme');

      leftMenu.classList.remove('seeTheme');
      leftMenu.classList.add('cosmicTheme');

      logo.classList.add('lightlogo');

      var i=0;
      for(i=0;i<button.length;i++) {
        button[i].classList.add('cosmicButton');
      }
      for(i=0;i<leftFix.length;i++) {
        leftFix[i].classList.remove('seeTheme');
        leftFix[i].classList.add('cosmicTheme');
      }
      for(i=0;i<consolebody.length;i++) {
        consolebody[i].classList.remove('seeTheme');
        consolebody[i].classList.add('cosmicTheme');
      }
      for(i=0;i<tooltip_h.length;i++) {
        tooltip_h[i].classList.remove('seeTheme');
        tooltip_h[i].classList.add('cosmicTheme');
        tooltip_c[i].classList.remove('seeTheme');
        tooltip_c[i].classList.add('cosmicTheme');
      }
      for(i=0;i<modal_h.length;i++) {
        modal_h[i].classList.remove('seeTheme');
        modal_h[i].classList.add('cosmicTheme');
        modal_c[i].classList.remove('seeTheme');
        modal_c[i].classList.add('cosmicTheme');
      }
      for(i=0;i<createBox.length;i++) {
        createBox[i].classList.remove('seeTheme');
        createBox[i].classList.add('cosmicTheme');
      }
      for(i=0;i<_p.length;i++) {
        _p[i].style.color = '#eee';
      }
      for(i=0;i<_a.length;i++) {
        _a[i].style.color = '#eee';
      }
      for(i=0;i<_i.length;i++) {
        _i[i].style.color = '#eee';
      }
      for(i=0;i<_h2.length;i++) {
        _h2[i].style.color = '#eee';
      }
      for(i=0;i<_h3.length;i++) {
        _h3[i].style.color = '#eee';
      }
      for(i=0;i<_th.length;i++) {
        _th[i].style.color = '#eee';
        _th[i].style.background = '#323259';
      }
      for(i=0;i<_td.length;i++) {
        _td[i].style.color = '#eee';
      }
      for(i=0;i<_span.length;i++) {
        _span[i].style.color = '#eee';
      }
      for(i=0;i<_small.length;i++) {
        _small[i].style.color = '#eee';
      }
      for(i=0;i<_select.length;i++) {
        _select[i].style.color = '#eee';
        _select[i].style.background = '#323259';
      }
      for(i=0;i<leftnoneBar.length;i++) {
        leftnoneBar[i].classList.remove('darkT');
        leftnoneBar[i].classList.add('cosmicT');
      }
      for(i=0;i<leftBar.length;i++) {
        leftBar[i].classList.remove('cosmicT');
        leftBar[i].classList.add('darkT');
      }
      for(i=0;i<leftButton.length;i++) {
        leftButton[i].classList.remove('darkT');
        leftButton[i].classList.add('cosmicT');
      }
    }
  }

  kinLookupFont(gubn) {
    var _td = document.querySelectorAll('td');
    var _th = document.querySelectorAll('th');
    var _a = document.querySelectorAll('a');
    var auth_theme = document.querySelectorAll('.auth_theme');

    if(gubn == "1") {

      for(var i=0;i<_td.length;i++) {
        _td[i].style.color = '';
      }
      for(var i=0;i<_a.length;i++) {
        _a[i].style.color = '';
      }
      for(i=0;i<auth_theme.length;i++) {
        auth_theme[i].classList.remove('cosmicTheme');
        auth_theme[i].classList.remove('seeTheme');
      }

    } else if(gubn == "2" || gubn == "3") {
     
      for(var i=0;i<_td.length;i++) {
        _td[i].style.color = '#eee';
      }
      for(var i=0;i<_th.length;i++) {
        _th[i].style.color = '#eee';
      }
      for(var i=0;i<_a.length;i++) {
        _a[i].style.color = '#eee';
      }
      if(gubn == "3") {
        for(i=0;i<auth_theme.length;i++) {
          auth_theme[i].classList.remove('cosmicTheme');
          auth_theme[i].classList.add('seeTheme');
        }
      }
      if(gubn == "4") {
        for(i=0;i<auth_theme.length;i++) {
          auth_theme[i].classList.remove('seeTheme');
          auth_theme[i].classList.add('cosmicTheme');
        }
      }
    }
  }
}