import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class BatchapiService {

  constructor(private http: HttpClient,private router:Router) {
    this.handleError = this.handleError.bind(this);
  }

  regiBatch(data) {
    console.log(data);
    return this.http.post('/batchapi/api/regiBatch', data).pipe(catchError(this.handleError));
  }

  getBatchList(data) {
    return this.http.get('/batchapi/api/getBatchList/'+data.userEmail).pipe(catchError(this.handleError));
  }

  deleteBatch(data) {
    return this.http.delete('/batchapi/api/deleteBatch/'+data.userEmail+'/'+data.hour+'/'+data.min).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        // 백엔드에서 실패한 것으로 보낸 에러.
        // console.log(error.error)
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error);
  }
}