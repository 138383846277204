import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-service-baremetal',
  templateUrl: './admin-service-baremetal.component.html',
})
export class AdminServiceBaremetalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  deleteService(){

  }
}
