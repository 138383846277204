import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';
import { FileUploadService } from './file-upload.services';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-dialog-objectstorage-upload',
  templateUrl: './dialog-objectstorage-upload.component.html',
  styleUrls: ['../dialog-common.css', 'dialog-objectstorage-upload.css']
})
export class DialogObjectStorageUploadComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogObjectStorageUploadComponent>,
    private fileUploadService: FileUploadService,
    private cephapi: CephapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
    this.fileUploadService.eventCallback$.subscribe(data => {
      this.changePercent(data);
    });
  }

  objectOptions: any = { prefix: '', type: 'file', acl: 'private' };

  // files: File[] = [];
  files: File[] = [];
  sending_files: boolean;
  uploadComplete: number;

  ngOnInit(): void {
    this.objectOptions.prefix = this.dialogData.prefix;
    this.uploadComplete = 0;
    this.sending_files = false;
  }

  // files: any = new FormData();
  newObjects: any = [];

  uploadFile(event: any) {
    let fileList : File[];
    if(event.target) {
      fileList = event.target.files;
    } else {
      fileList = event;
    }
    
    if (fileList.length != 0) {
      for(let i = 0; i < fileList.length; i++) {
        this.newObjects.push(fileList[i].name);
        this.files.push(fileList[i]);
      }
    }
  }

  deleteAttachment(index) {
    this.newObjects.splice(index, 1)
    this.files.splice(index, 1);
  }

  uploadObject() {
    if(this.files.length == 0) {
      alert('업로드 할 오브젝트가 존재하지 않습니다.');
      return;
    }
    this.sending_files = true;
    this.files.forEach(f => {
      this.fileUploadService.fileUpload(f, this.dialogData.bucket, f.name, this.objectOptions).then(complete => {
        this.uploadComplete++;
        // console.log(`${f.name} Uploaded Successfully`);
        if(this.files.length == this.uploadComplete) {
          alert('오브젝트 업로드가 완료되었습니다.');
          this.closeDialogMsg({code:"success",data:{}});
        }
      }).catch(error => {
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: `파일(${f.name}) 업로드 중 일시적으로 문제가 발생하였습니다. 새로고침해주세요.`
          }
        });
        document.getElementById(`${f.name}_progress`).innerText = "오류";
        document.getElementById(`${f.name}_progress`).style.color = "#FF0000";
      });
    });
  }

  closeUploadDialog() {
    if(this.sending_files) {
      let yesno = confirm('현재 업로드 중인 오브젝트가 존재합니다.\n창을 닫을 시 업로드 중인 오브젝트는 초기화 됩니다.');
      if(yesno) {
        this.fileUploadService.changeStatus({stopUpload:true});
        try {
          this.files.forEach(async f => {
            await this.cephapi.deleteTempObject({bucket:this.dialogData.bucket, prefix:this.dialogData.prefix, name:f.name}).toPromise();
          });
          this.closeDialogMsg({code:"success",data:{}});
        } catch (e) {
        }
      } 
    } else {
      this.closeDialog();
    }
  }

  changePercent(data) {
    if(document.getElementById(data.id)) {
      document.getElementById(data.id).innerText = data.percent;
    }
  }
}
