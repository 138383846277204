import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../../admin.service';
import { WebApiService } from '../../../../webapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AdminNavComponent } from '../../admin-nav/admin-nav.component'

declare function fn_modalOpen(id: any): any;
declare const $: any;

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
})
export class AdminUserComponent implements OnInit {
  companies: any = [];
  constructor(
    private adminAPI: AdminService,
    private webapi: WebApiService,
    private spinner: NgxSpinnerService,
    private common: AdminNavComponent,
  ) { }

  modal_company_user: any = [];
  modal_comp_userId: String = '';
  modal_comp_name: String = '';
  modal_comp_telephone: String = '';
  modal_comp_mobile: String = '';
  modal_comp_joinChannel: String = '';
  modal_comp_accountLock: String = '';

  //페이징변수
  curPage: any;
  totalCount: any;

  ngOnInit(): void {
    this.getCompanies(0);
  }

  getCompanies(page) {
    this.curPage = page;
    var searchQuerys = 'o=created_date.DESC&l=10&s=' + this.curPage * 10;
    this.spinner.show();
    this.webapi.getCompanyList(searchQuerys).subscribe(
      (response) => {

        const data = response.body;
        this.companies = data['result'];

        this.totalCount = data['total'];
        for(let company of this.companies){

          this.webapi.getAvailableCredit(company.registrationNumber,company.registrationNumberSub).subscribe((result) => {
            company.credit = result.body.sum;
          });
        }
        this.common.getThemeFont();
      }, (err) => {

      }
    );
    this.spinner.hide();
  }

  getCompaniesSearch() {
    this.curPage = 0;
    var searchKeyword = $('#search').val();
    var searchQuerys ='o=created_date.DESC&l=10&s=' + this.curPage * 10 + '&user_id=' + searchKeyword +'&lk=user_id';

    this.spinner.show();
    this.webapi.getUserListSearCh(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.companies = data['result'];
        this.totalCount = data['total'];
        for(let company of this.companies){
          this.webapi.getAvailableCredit(company.registrationNumber,company.registrationNumberSub).subscribe((result) => {
            company.credit = result.body.sum;
          });
        }
      }, (err) => {

      }
    );
    this.spinner.hide();
  }

  //페이징함수
  getMovePage(e) {

    this.getCompanies(e.page);
  }

  enableCompany(registrationNumber,registrationNumberSub) {
    this.spinner.show();
    this.companies = this.companies.map((company) => {
      if (company.registrationNumber === registrationNumber && company.registrationNumberSub === registrationNumberSub) {
        try {
          // api 처리 로직
          this.adminAPI.enableCompany(company.registrationNumber,company.registrationNumberSub).subscribe(
            (response) => {
              // this.webapi.createCreditHistory({ companyId, credit, description, totalInvoiceId, sign });

              company.status = 2;
              this.spinner.hide();
            },
            (error) => {
              alert("승인 실패");

              this.spinner.hide();
            }
          );
          // throw Error;
        } catch (e) {
          this.spinner.hide();

          company.status = 1;
        } finally {
          return company;
        }
      }
      return company;
    });
  }

  fn_downloadRegistration(registrationNumber,registrationNumberSub) {
    location.href = '/webapi/companies/' + registrationNumber+'/'+registrationNumberSub + '/document';
  }

  fn_detailCall(userData) {
    if (userData.accountLock) {
      (<HTMLInputElement>(
        document.getElementById('comp_accountLockT')
      )).checked = true;
      (<HTMLInputElement>(
        document.getElementById('comp_accountLockF')
      )).checked = false;
    } else {
      (<HTMLInputElement>(
        document.getElementById('comp_accountLockT')
      )).checked = false;
      (<HTMLInputElement>(
        document.getElementById('comp_accountLockF')
      )).checked = true;
    }
    (<HTMLInputElement>(
      document.getElementById('comp_createdDate')
    )).innerHTML = userData.createdDate.substring(0, 10);

    this.modal_company_user = userData;

    this.modal_comp_userId = userData.userId;
    this.modal_comp_name = userData.name;
    this.modal_comp_telephone = userData.telephone;
    this.modal_comp_mobile = userData.mobile;
    this.modal_comp_joinChannel = userData.joinChannel;
    this.modal_comp_accountLock = userData.accountLock;

    fn_modalOpen('user_detail');
  }

  onUserIdChange(deviceValue) {
    this.modal_comp_userId = deviceValue;
  }
  onUserNameChange(deviceValue) {
    this.modal_comp_name = deviceValue;
  }
  onUserTelephoneChange(deviceValue) {
    this.modal_comp_telephone = deviceValue;
  }
  onUserMobileChange(deviceValue) {
    this.modal_comp_mobile = deviceValue;
  }
  onUserJoinChannelChange(deviceValue) {
    this.modal_comp_joinChannel = deviceValue;
  }
  onUserAccountLockChange(deviceValue) {
    this.modal_comp_accountLock = deviceValue;
  }

  onSubmit(): void {
    const body = {
      userId: this.modal_comp_userId,
      name: this.modal_comp_name,
      telephone: this.modal_comp_telephone,
      mobile: this.modal_comp_mobile,
      joinChannel: this.modal_comp_joinChannel,
      accountLock: this.modal_comp_accountLock,
    };

    this.webapi.blsUserUpdate(body).subscribe(
      (response) => {
        alert('수정되었습니다.');
        this.modal_company_user.name = this.modal_comp_name;
        this.modal_company_user.telephone = this.modal_comp_telephone;
        this.modal_company_user.mobile = this.modal_comp_mobile;
        this.modal_company_user.joinChannel = this.modal_comp_joinChannel;
        this.modal_company_user.accountLock = this.modal_comp_accountLock;

        (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
      }, (err) => {

      }
    );
  }

  test(comp){

    this.webapi.emailenable(comp.adminId).subscribe((data)=>{});
  }
}
