<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
	  <h2>메일 발송 내역</h2>
	  <div class="lineMap">
		  메일/문자 발송 /  <span>메일 발송 내역</span>
	  </div>
	</div>
	<!-- //content_header -->
  
	<!-- content_body -->
	<div class="content_body">
	  <!-- section -->
	  <section class="sec col12">
		<div class="sec_content">
		  <!-- lyBox -->
		  <div class="lyBox consoleRightBody">
			<div class="titArea">
			  <h3>메일 발송</h3>
			</div>
			<div class="contArea" style="display: block;">
			  <div class="boardBox">
				<table class="tbl_col_tp1 pdTp2">
				  <colgroup>
					<col style="width: 10%;"/>
					<col style="width: 20%;"/>
					<col style="width: 45%;"/>
					<col style="width: 25%;"/>
				  </colgroup>
				  <thead>
					<tr>
					  <th scope="col">번호</th>
					  <th scope="col">일자</th>
					  <th scope="col">제목</th>
					  <th scope="col">수신자</th>
					</tr>
				  </thead>
  
				  <tbody>
					<tr *ngFor="let user of userList; index as i">
						<td>{{ 10 * curPage + (i + 1) }}</td>
					  	<td>{{ user.createdDate | date:'yyyy-MM-dd HH:mm:ss' }}</td>
					  	<td>{{ user.title }}</td>
						<td>{{ user.receiver }}</td>
					</tr>
				  </tbody>
				</table>

				<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
	
			  </div>
			</div>
		  </div>
		  <!-- //lyBox -->
  
		  <!-- //lyBox -->
		</div>
	  </section>
	  <!-- //section -->
	</div>
	<!-- //content_body -->
  </div>
  <!-- //Contents -->
  