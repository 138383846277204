import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-term-email',
  templateUrl: './term-email.component.html',
})
export class TermEmailComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
  }

}
