<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>장비 관리</h2>
		<div class="lineMap">
			Dashboard /  <span>equipment</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
                <!-- <div class="lyBox">								
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%;">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>                                    
                                    <tr>
                                        <th scope="row">장비 생성</th>
                                        <td>
                                            <a class="btn mid orange mg_r4" onclick="fn_modalOpen('nas_create')">장비 생성</a>
                                        </td>
                                    </tr>                                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
                <!-- //lyBox -->
				<!-- lyBox -->
				<div class="lyBox consoleBody">
					<div class="titArea">
						<h3>장비 목록</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
								<button type="submit" class="btn mid dBlue"><i class="ico ico_search_white"></i>검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block"> 

						<div class="boardBox">
							<table class="tbl_col_tp1 dropmenu pdTp2">
                                <colgroup>
                                    <!-- <col style="width:4%"> -->
									<col style="width:33%">
									<col style="width:33%">
									<col style="width:33%">
								</colgroup>
								<thead>
									<tr>
                                        <!-- <th scope="col"><input type="checkbox" name="" id=""></th> -->
										<th scope="col">장비 이름</th>
										<th scope="col">상태</th>
										<th scope="col">생성일</th>
									</tr>
								</thead>
								<tbody>
									<tr>
                                        <!-- <td><input type="checkbox" name="" id=""></td> -->
                                        <td>장비1 </td>
                                        <td><label class="dot green"></label>Active </td>
										<td>2020-06-30</td>										
                                    </tr>
								</tbody>
							</table>	
                        </div>
                    
                        <div class="board_pager_wrap tp1">
                            <!-- <div class="board_pager">
                                <strong class="boardPage">1</strong>
                                <a href="javascript:void(0);" class="boardPage">2</a>
                                <a href="javascript:void(0);" class="boardPage">3</a>
                                <a href="javascript:void(0);" class="boardPage">4</a>
                                <a href="javascript:void(0);" class="boardPage">5</a>
                            </div> -->
                        </div>
					</div>
				</div>
				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a onclick="fn_modalMsg('장비를 삭제하시겠습니까?')">장비 삭제</a>
    </div>
</div>

