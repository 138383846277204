import {MemberData} from "./MemberData";
import {HealthMonitorData} from "./HealthMonitorData";

export class PoolData{
      public name: string;
      public protocol: string; /** HTTP, HTTPS, TCP, UDP */
      public lb_algorithm:string;
      // public members:any[];
      // public monitor:HealthMonitorData;
      public session_persistence:{};  
      public description:string;
      public admin_state_up:boolean;
    constructor(){
        this.name="LB_POOL";
        this.protocol="HTTP"; 
        this.lb_algorithm="ROUND_ROBIN";
        // this.members=null;
        // this.monitor=null;
        this.session_persistence=null;
        this.description='';
        this.admin_state_up= true;
    }
  }