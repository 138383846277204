import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-server-security-group-update',
  templateUrl: './dialog-server-security-group-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerSecurityGroupUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerSecurityGroupUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  
  selected_server: any = {
    image_snapshot_name: '',
    image_snapshot_description: '',
  };

  // security_group
  securityGroupList: any = [];
  securityGroupRuleList: any = [];

  ngOnInit(): void {

    this.selected_server = this.dialogData.selected_server;
    this.securityGroupList = this.dialogData.securityGroupList;
    this.getSecurityGroupRuleList();
  }


  
  getSecurityGroupRuleList() {
    this.securityGroupRuleList = [];
    for (let sg of this.securityGroupList) {

      if (sg.checked) {
        this.spinner.show();
        this.osapi.getSecurityGroupRuleList(sg.id).subscribe((data: any) => {
          this.spinner.hide();


          for (let rule of data) {
            let rule_flag = true;
            for (let before_rule of this.securityGroupRuleList) {
              if (
                rule.direction == before_rule.direction &&
                rule.ethertype == before_rule.ethertype &&
                rule.from_port == before_rule.from_port &&
                rule.to_port == before_rule.to_port &&
                rule.ip_protocol == before_rule.ip_protocol &&
                isEquivalent(rule.ip_range, before_rule.ip_range)
              ) {
                rule_flag = false;
              }
            }
            if (rule_flag) {
              this.securityGroupRuleList.push(rule);
            }
          }

        },
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
      }
    }
  }

  
  updateServerSecurityGroup() {
    const selected_sglist = this.securityGroupList
      .map(function (e) {
        if (e.checked) return e.id;
      })
      .filter(function (el) {
        return el != null;
      });

    this.spinner.show();
    this.osapi
      .updateServerSecurityGroup(
        this.selected_server.server_id,
        selected_sglist
      )
      .subscribe((data: any) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }

}


function isEquivalent(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
}