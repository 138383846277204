<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>메일/문자 발송</h2>
		<div class="lineMap">
			메일/문자 발송 / <span>메일 발송 목록</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">

				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>메일 발송</h3>
					</div>
					<div class="contArea" style="display:block">
						<form [formGroup]="sendForm" (ngSubmit)="fn_send();">
							<div class="boardBox">
								<table class="tbl_row_tp1 pdTp2">
									<colgroup>
										<col style="width:20%;">
										<col />
										<col style="width:105px;">
									</colgroup>
									<tbody>
										<tr class="required">
											<th scope="row">받는사람</th>
											<td><input type="text" formControlName="receiver" [value]="receivers"></td>
											<td><a href="javascript:void(0);" class="btn mid blue" (click)="fn_getAdress()">주소록</a></td>
										</tr>
										<tr class="required">
											<th scope="row">제목</th>
											<td colspan="2">
												<input type="text" formControlName="subject" maxlength="200"
													[class]="sendChk.subject.invalid && sendChk.subject.dirty ? 'error-invalid' : ''">
												<div *ngIf="sendChk.subject.dirty">
													<div class="error-invalid-feedback" *ngIf="sendChk.subject.errors?.required">
														제목은 필수 값입니다. 200자 이내로 입력해주세요.
													</div>
												</div>
											</td>
										</tr>
										<tr class="required">
											<th scope="row">내용</th>
											<td colspan="2"><textarea rows="7" formControlName="text" maxlength="250"
													[class]="sendChk.text.invalid && sendChk.text.dirty ? 'error-invalid' : ''"></textarea>
												<div *ngIf="sendChk.text.dirty">
													<div class="error-invalid-feedback" *ngIf="sendChk.text.errors?.required">
														내용은 필수 값입니다. 200자 이내로 입력해주세요.
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="btn_wrap tp1">
								<button type="submit" class="btn big orange mg_r4" [disabled]='!sendForm.valid'>보내기</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->



<!-- Modal : adress 추가모달 -->
<div id="adressModal" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>주소록</h2>
		<div class="btnArea">
			<div class="input-group fl">
				<input type="search" class="form-control" [(ngModel)]="listFilter" id="filterInput" placeholder="검색어를 입력해주세요" />
			</div>
		</div>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<div class="contArea" style="display:block">
			<table class="tbl_col_tp1">
				<colgroup>
					<col style="width:10%">
					<col style="width:20%">
					<col style="width:70%">
				</colgroup>
				<thead>
					<tr>
						<!-- <input type="checkbox"value=""> -->
						<th scope="col"></th>
						<th scope="col">사용자명</th>
						<th scope="col">주소</th>
					</tr>
				</thead>
			</table>

			<div class="boardBox" style="overflow:auto; height:300px;">
				<table class="tbl_col_tp1">
					<colgroup>
						<col style="width:10%">
						<col style="width:30%">
						<col style="width:60%">
					</colgroup>
					<tbody>
						<tr *ngFor='let user of userListFilter'>
							<td><input type="checkbox" [value]="user.userId" name="userReceiver"></td>
							<td><span>{{user.name}}</span></td>
							<td class="l"><span>{{user.userId}}</span></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="btnArea tp1">
			<a href="javascript:void(0);" (click)="fn_getAdressReceiver()" class="btn big orange mg_r4">완료</a>
			<a href="#close-modal" rel="modal:close" id="closeModalBtn" class="btn big orange">닫기</a>
		</div>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->