import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-service-dns',
  templateUrl: './admin-service-dns.component.html',
})
export class AdminServiceDnsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  deleteService(){

  }
}
