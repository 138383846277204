import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { SubnetData } from "./subnetdata";
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router, ActivatedRoute} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function valiAllChk(params:any);

@Component({
  selector: 'app-console-subnet-list',
  templateUrl: './console-subnet-list.component.html',
})
export class ConsoleSubnetListComponent implements OnInit {
  subnetList: any = [];
  networkList: any = [];
  resource: any;
  networkRegex: string;
  selected_subnet: any = {};
  subnetModel = new SubnetData('', '', '', '');

  // 검색 텍스트
  filterText: string;

  isActived: boolean = false;
  isChecked: boolean = false;

  routeAction: string="";

  is_network_list_loaded=false;
  
  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    public global: GlobalService,
    private dialogs: DialogSetService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private common: ConsoleNavComponent
  ) { 
    this.routeAction =  this.actRoute.snapshot.paramMap.get('action'); }

  ngOnInit(): void {
    this.getSubnetList();
    if(this.routeAction){
      if(this.routeAction == 'create_subnet'){
        this.openCreateModal()
      }
    }
  }

	// 전체선택
	onAllCheck(status) {
		this.isActived = status.isActived;
	}

	onListCheck(subnet){
		const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
		this.isActived = false;
      for (let i = 0; i < $_CHKEDLIST.length; i++) {
        let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
        if (chkedbox.checked) {
          chkedbox.checked = false;
        }
        if(chkedbox.getAttribute('data-subnet-id') == subnet.id) {
          chkedbox.checked = true;
          this.onClickList(subnet);
          this.isActived = true;
        }
      }
	}

  onClickList(item) {
    this.isActived = true;
    this.selected_subnet = item;
    this.subnetModel = new SubnetData('', '', '', '');
    this.subnetModel.name = this.selected_subnet.name
  }

  openCreateModal(){
    if(this.is_network_list_loaded){
      this.subnetModel = new SubnetData('', '', '', '');
      // fn_modalOpen('subnet_create')
      this.dialogs.openSubnetCreateDialog({subnetModel:this.subnetModel,networkList:this.networkList,resource:this.resource});
    }else{
      setTimeout(() =>  { this.openCreateModal(); }, 500);
    }
  }
  openUpdateModal(){
    if(Object.keys(this.selected_subnet).length !== 0) {
      this.dialogs.openSubnetUpdateDialog({selected_subnet:this.selected_subnet});
    } else {
      const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
      const result = valiAllChk($_CHKEDLIST);   
      if(result > 1) {
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '하나의 서브넷만 선택해주세요.'
          }
        });
      } else {
        //document.getElementById('droparea').setAttribute('data-is-password', "false");
        let checkedIndex:number = 0;
        $_CHKEDLIST.forEach((item:any, index) => {
          if (item.checked === true) { checkedIndex = index; return; }
        });
        let checkedSubnetId:string = $_CHKEDLIST[checkedIndex].getAttribute("data-subnet-id");
        var checkedObj:any = this.subnetList.filter(t=>t.network_id == checkedSubnetId);
        this.dialogs.openSubnetUpdateDialog({selected_subnet:checkedObj[0]});
      }
    }
  }
  openDeleteModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"서브넷 삭제",
      content:"선택하신 서브넷("+this.selected_subnet.name+")를 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteSubnet();
        }
      });
  }
  /**사용안함 */
  getPortList() {
    this.osapi.getPortList().subscribe(
      (data: any) => {
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  getSubnetList() {
    this.spinner.show();
    this.osapi.getSubnetList().subscribe(
      (data: any) => {
        this.subnetList = data;

        for(let subnet of this.subnetList){
          subnet.network_type = "ext";
          subnet.isDefaultSubnet = false;
        }
        this.common.getThemeFont();
        this.spinner.hide();
        
        this.getNetworkList();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  getNetworkList() {
    this.osapi.getResourceAll().subscribe(
      (data: any[]) => {
        this.resource = data;

        this.osapi.getNetworkList().subscribe(
          (data:any) => {
            this.spinner.hide();
            this.is_network_list_loaded=true;
            /**
             * 20200902 cbkim
             * 소유한 네트워크만 표시
             */
            this.networkList = [];
            for(let network of data){
              network.isDefaultNetwork = false;
              if(network.isNetworkOwner){
                if(network.name == 'Default Network' && network.cidr == "192.168.0.0/16"){
                  network.isDefaultNetwork = true;
                }
                this.networkList.push(network);
              }
            }

            //this.networkList = data;

            // cidr 찾기
            for (let r of this.resource) {
              for (let n of this.networkList) {
                if (r.objectId == n.id) {
                  n.cidr = r.value;
                }
              }
            }
            
            for (let subnet of this.subnetList) {
              for (let network of this.networkList) {
                if(subnet.network_id == network.id){
                  subnet.network_type = network.network_type;
                  subnet.isDefaultSubnet = network.isDefaultNetwork;
                  subnet.network = network
                }
              }
            }
          },
          (error) => {
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }



  deleteSubnet() {
    this.spinner.show();
    var subnetId = this.selected_subnet.id;
    if (this.selected_subnet.existHostPort) {
      this.spinner.hide();
      // 호스트 연결되어있음
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'연결 된 인스턴스가 존재합니다.'
        }
      });
    } else {
      // 서브넷 삭제
      this.osapi.deleteSubnet(subnetId).subscribe(
        (data: any) => {
          //this.ngOnInit();
          this.getSubnetList();
          // Resource 삭제
        },
        (error) => {
          this.spinner.hide();
          if(error.status == 409){
            // 연결 된 인터페이스 있음
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '서브넷 삭제',
                message: '외부와 연결 된 서브넷은 삭제 할 수 없습니다. 네트워크를 삭제해주세요.' 
              }
            });
          }else{
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          }
        }
      )
    }

  }

}
