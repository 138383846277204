import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { ResourceCntData } from "./ResourceCntData";

@Component({
  selector: 'app-dialog-project-delete',
  templateUrl: './dialog-project-delete.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogProjectDeleteComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogProjectDeleteComponent>
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
    this.deleteCheckMessage = "";
    this.deleteCheck = true;
  }
  deleteCheck:boolean = true;
  deleteCheckMessage:string ="";
 
  selected_project: any = {};
  resourceCountMap = [];
  resource: any = [];

  ngOnInit(): void {


    this.resource = this.dialogData.resource
    this.selected_project = this.dialogData.selected_project

    
    this.resourceCountMap = [];
    /**
     * network | subnet | instance | storage | image | snapshot | load balancer | NAS
    */
    var networkCnt = new ResourceCntData("network", "네트워크", 0);
    var subnetCnt = new ResourceCntData("subnet", "서브넷", 0);
    var instanceCnt = new ResourceCntData("instance", "인스턴스", 0);
    var storageCnt = new ResourceCntData("storage", "스토리지", 0);
    var imageCnt = new ResourceCntData("image", "이미지", 0);
    var snapshotCnt = new ResourceCntData("snapshot", "스냅샷", 0);
    var loadbalancerCnt = new ResourceCntData("loadbalancer", "네트워크", 0);
    var nasCnt = new ResourceCntData("NAS", "NAS", 0);
    

    for (let item of this.resource) {
      if (item.projectId == this.selected_project.id) {
        switch (item.resourceType) {
          case "network":
            networkCnt.cnt++;
            break;
          case "subnet":
            subnetCnt.cnt++;
            break;
          case "instance":
            instanceCnt.cnt++;
            break;
          case "storage":
            storageCnt.cnt++;
            break;
          case "image":
            imageCnt.cnt++;
            break;
          case "snapshot":
            snapshotCnt.cnt++;
            break;
          case "loadbalancer":
            loadbalancerCnt.cnt++;
            break;
          case "NAS":
            nasCnt.cnt++;
            break;
        }
      }
    }
    if(networkCnt.cnt != 0){
      this.resourceCountMap.push(networkCnt);
    }
    if(subnetCnt.cnt != 0){
      this.resourceCountMap.push(subnetCnt);
    }
    if(instanceCnt.cnt != 0){
      this.resourceCountMap.push(instanceCnt);
    }
    if(storageCnt.cnt != 0){
      this.resourceCountMap.push(storageCnt);
    }
    if(imageCnt.cnt != 0){
      this.resourceCountMap.push(imageCnt);
    }
    if(snapshotCnt.cnt != 0){
      this.resourceCountMap.push(snapshotCnt);
    }
    if(loadbalancerCnt.cnt != 0){
      this.resourceCountMap.push(loadbalancerCnt);
    }
    if(nasCnt.cnt != 0){
      this.resourceCountMap.push(nasCnt);
    }
  }

  
  deleteBLSProject() {

    if(this.deleteCheckMessage != "프로젝트삭제"){
      // alert("'프로젝트삭제'를 정확하게 입력해주세요.");
      this.deleteCheck = false;
      setTimeout(()=>{
        this.deleteCheck = true;
      },5000);
      return;
    }
    const answer = confirm(
      '정말 삭제하시겠습니까? 삭제 이후 모든 자원은 절대 복구 하실 수 없습니다.'
    );
    if (answer) {
      this.spinner.show();
      try {
        this.osapi.deleteBLSProjectId(this.selected_project.id).subscribe((data) => {
          this.spinner.hide();


          this.closeDialogMsg("success");

          // this.onProjectChange(this.projectList[0].id);
          // this.userInfo.projectId = this.projectList[0].id;
        },
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
      } catch (e) {

        this.closeDialogMsg("fail");
        this.spinner.hide();
      }
    }
  }
  
}
