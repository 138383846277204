<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>인증키</h2>
    <div class="lineMap">Server / <span>KeyPair</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- <form id="frm"> -->
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="lyBox">
            <div class="title_wrap">
                <div class="contArea">
                    <div class="tit">
                        <app-console-projects></app-console-projects>
                    </div>
                    <div class="cont">
                        <app-console-server-status></app-console-server-status>
                    </div>
                    <div class="contSub">
                        <button class="btn mid blue mg_r4" (click)="openNewKeypairModal()"><i class="ico ico_register_white"></i>신규생성</button>
                        <!-- <button class="btn mid red mg_r4" [disabled]="!isActived"
                          onclick="fn_modalMsg('인증키를 삭제하시겠습니까?')"><i class="ico ico_cancel_white"></i>인증키 삭제</button> -->
                        <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openModifyKeypairModal()"><i class="ico ico_register_white"></i>기본정보수정</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="lyBox">
          <div class="title_wrap">
            <div class="contArea">
              <div class="tit">
                  <app-console-projects></app-console-projects>
              </div>
              <div class="cont">
                  <button class="btn mid blue mg_r4" (click)="openNewKeypairModal()"><i class="ico ico_register_white"></i>신규생성</button> -->
                  <!-- <button class="btn mid red mg_r4" [disabled]="!isActived"
                    onclick="fn_modalMsg('인증키를 삭제하시겠습니까?')"><i class="ico ico_cancel_white"></i>인증키 삭제</button> -->
                  <!-- <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openModifyKeypairModal()"><i class="ico ico_register_white"></i>기본정보수정</button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- //lyBox -->
        <!-- lyBox -->
        <div class="lyBox consoleRightBody">
          <div class="titArea">
            <h3>인증키 목록</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <input type="search" class="form-control" name="" id="search" [(ngModel)]="filterText" value=""
                  placeholder="검색어를 입력해주세요" />
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                </div>
                <!-- 접기 버튼 -->
                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <table class="tbl_col_tp1 dropmenu">
                <colgroup>
                  <col style="width: 5%;" />
                  <col style="width: 25%;" />
                  <col style="width: 25%;" />
                  <col style="width: 25%;" />
                  <col style="width: 20%;" />
                </colgroup>
                <thead>
                  <th scope="col">
                    <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                  </th>
                  <th scope="col">인증키 명</th>
                  <th scope="col">설명</th>
                  <th scope="col">인증키 지문</th>
                  <th scope="col">기능</th>
                </thead>
                <tbody>
                  <tr *ngFor="let key of keyPairList | tableFilter: filterText" (contextmenu)="onClickList(key.keypair)"
                    (click)="onClickList(key.keypair)">
                    <td>
                      <input type="checkbox" name="sel_chkbox" (change)="onListCheck(key)"
                        [attr.data-id]="key.keypair.name" />
                    </td>
                    <td>{{ key.keypair.name }}</td>
                    <td>{{ key.keypair.description?key.keypair.description:"없음" }}</td> 
                    <td>{{ key.keypair.fingerprint }}</td>
                    <td>
                      <!-- <a class="btn sml red" (click)="openDeleteKeyPairModal(key.keypair)"><i class="ico ico_delete_white"></i>삭제</a> -->
                      <a class="btn sml bor_gray" (click)="openDeleteKeyPairModal(key.keypair)">삭제</a>
                    </td>
                    <!-- <td><a href="/console/keypair" class="btn sml dGray" (click)="deleteKeyPair(key.keypair.name)">삭제</a></td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
        </div>
        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->

    <!-- </form> -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->


<!-- dropdown menu -->
<div class="dropdown">
  <div id="myDropdown" class="dropdown-content">
    <a (click)="openModifyKeypairModal()">인증키 기본정보</a>
    <a (click)="openDeleteKeyPairModal(selected_keypair)">인증키 삭제</a>
  </div>
</div>

