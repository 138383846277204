<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Server</li>
      <li class="active">Image</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let image of imageLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons"
                          [class]="image.status === 'active' ? 'dotActive' : 'dotStop'">filter</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{image.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                      <tr>
                        <th>이름</th>
                        <td>{{image.name}}</td>
                      </tr>
                      <tr>
                        <th>OS</th>
                        <td *ngIf="image.properties.OS != null; else osNull;">
                          {{image.properties.OS}}
                        </td>
                        <ng-template #osNull>
                          <td>없음</td>
                        </ng-template>
                      </tr>
                      <tr>
                        <th>유형</th>
                        <td>{{image.disk_format | uppercase}}</td>
                      </tr>
                      <tr>
                        <th>생성일</th>
                        <td>{{image.created_at.substring(0, 10)}}</td>
                      </tr>
                      <tr>
                        <th>상태</th>
                        <td>
                          <label [class]="image.status == 'active' ? 'dot green' : 'dot red'"></label>
                          {{image.status | uppercase}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>