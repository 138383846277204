<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>Flavor 관리</h2>
        <div class="lineMap">
            Admin  /  <span>Flavor</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12 mg_t10">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>Step 1. flavor 정보</h3>
                        <div class="btnArea">
                            <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                        </div>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            <span id=""> flavor 설명입니다. </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">이름</th>
                                        <td>
                                            <input type="text" name="" id="" [(ngModel)]="newFlavorData.name" placeholder="이름을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">ID</th>
                                        <td>
                                            <input type="text" name="" id="" [(ngModel)]="newFlavorData.flavor_id" placeholder="(default auto) 숫자, 영문자, 밑줄, 마침표, 콜론, 하이픈, 스페이스 포함 가능" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">vcpus</th>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickDecreaseVcpus()" type="button" class="btn sml blue on"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                                <input type="number" name="" id="" [(ngModel)]="newFlavorData.vcpus">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickIncreaseVcpus()" type="button" class="btn sml blue"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">RAM(MB)</th>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickDecreaseRam()" type="button" class="btn sml blue on"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                                <input type="number" name="" id="" [(ngModel)]="newFlavorData.ram">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickIncreaseRam()" type="button" class="btn sml blue"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">root 디스크 (GB)</th>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickDecreaseDisk()" type="button" class="btn sml blue on"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                                <input type="number" name="" id="" [(ngModel)]="newFlavorData.disk">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickIncreaseDisk()" type="button" class="btn sml blue"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">ephemeral 디스크 (GB)</th>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickDecreaseEphemeral()" type="button" class="btn sml blue on"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                                <input type="number" name="" id="" [(ngModel)]="newFlavorData.ephemeral">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickIncreaseEphemeral()" type="button" class="btn sml blue"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">swap 디스크 (MB)</th>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickDecreaseSwap()" type="button" class="btn sml blue on"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                                <input type="number" name="" id="" [(ngModel)]="newFlavorData.swap">
                                                <div class="input-group-btn">
                                                    <button (click)="onClickIncreaseSwap()" type="button" class="btn sml blue"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <th scope="row">RX/TX요인</th>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-btn">
                                                    <button type="button" class="btn sml blue on"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                                <input type="number" name="" id="" value="1">
                                                <div class="input-group-btn">
                                                    <button type="button" class="btn sml blue"><i class="ico ico_moreTp1"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>Step 2. flavor 접근 권한</h3>
                        <div class="btnArea">
                            <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                        </div>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width: 85%;"/>
                                    <col style="width: 15%;"/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>선택 된 프로젝트</th>
                                        <th></th>
                                        <!-- <th>
                                            <select name="">
                                                <option value="">-필터-</option>
                                            </select>
                                        </th> -->
                                    </tr>
                                    <tr *ngFor="let project of sel_projectList">
                                        <td>{{project.name}}</td>
                                        <td><button (click)="onClickDeselectProject(project)" class="btn sml dGray">삭제</button></td>
                                    </tr>                                        
                                </tbody>
                            </table>
                        </div>

                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width: 85%;"/>
                                    <col style="width: 15%;"/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>모든 프로젝트</th>
                                        <th></th>
                                        <!-- <th>
                                            <select name="">
                                                <option value="">-필터-</option>
                                            </select>
                                        </th> -->
                                    </tr>
                                    <tr >
                                        <td>
                                            <select (change)="onChangeAddProject($event.target.value)" >
                                                <option value="">선택</option>
                                                <option *ngFor="let project of projectList" [attr.value]="project.id">{{project.name}})</option>
                                            </select>
                                        </td>
                                        <td><button (click)="onClickSelectProject(add_project)" class="btn sml dGray">추가</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="btn_wrap tp1">
                            <button (click)="createFlavor()" class="btn big orange" >Flavor 생성</button>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->


<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>삭제확인</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="msg"></div>
        <div class="btnArea">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">삭제</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->