import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-mobile-console-image-list',
  templateUrl: './mobile-console-image-list.component.html',
  styleUrls: ['./mobile-console-image-list.component.css'],
})
export class MobileConsoleImageListComponent implements OnInit {
  imageLists:any = [];
  _blank = true;
  
  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getImageList();
  }

  getImageList() {
    this.osapi.getCurrentProjectImageList().subscribe(
      (res: any) => {
        let data = res.body;
        this.imageLists = data;
        this._blank = ((this.imageLists != 0) ? true : false);
        this.spinner.hide();
      },
      (error) => {
        alert(error);
        return;
      }
    );
  }

}
