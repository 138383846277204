import { Injectable, Query } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, Subject,BehaviorSubject } from 'rxjs';
import { catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FrontApiService {
  private messageSource = new BehaviorSubject<Object>("");
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient) { }

  changeMessage(message: {}) {
    this.messageSource.next(message)
  }

  toastErrorMessage(message='일시적으로 문제가 발생하였습니다. 새로고침해주세요.'){
    var msg = {
      type:'toast',
      body:{
        type:'error',
        title:'오류 알림',
        message:message
      }
    }
    this.messageSource.next(msg);
  }

  toastMessage(type,title,message){
    var msg = {
      type:'toast',
      body:{
        type:'error',
        title:'오류 알림',
        message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
      }
    }
    this.messageSource.next(msg);
  }
}