<h5 mat-dialog-title>{{data.title}}</h5>
<div mat-dialog-content>
  <p>{{data.content}}</p>
</div>
<section>
  <div mat-dialog-actions>
    <button *ngIf="data.gubn == 'start';"
      mat-button [mat-dialog-close]="true" cdkFocusInitial class="di_button waves-effect waves-light btn darken white-text"
      (click)="startServer(data.id);">확인
    </button>
    <button *ngIf="data.gubn == 'stop';"
      mat-button [mat-dialog-close]="true" cdkFocusInitial class="di_button waves-effect waves-light btn darken white-text"
      (click)="stopServer(data.id);">확인
    </button>
    <button *ngIf="data.gubn == 'reboot';"
      mat-button [mat-dialog-close]="true" cdkFocusInitial class="di_button waves-effect waves-light btn darken white-text"
      (click)="softRebootServer(data.id);">확인
    </button>
    <button mat-button mat-dialog-close class="di_button waves-effect waves-light btn materialize-red darken-2 white-text" (click)="closeDialog();">취소</button>
  </div>
</section>