import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-project-update',
  templateUrl: './dialog-project-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogProjectUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogProjectUpdateComponent>
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
 
  selected_project: any = {};

  subUsers=[];
  user_list=[];
  ngOnInit(): void {
    this.selected_project = this.dialogData.selected_project;
    this.getSubUsers();
  }

  updateProjectData(){
    var regex = /^[A-Za-z0-9\-_+]{4,20}$/;
    
    if (this.selected_project.resource_data.value.length >60 ) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "프로젝트 이름은 60자 이내로 작성해주세요"
        }
      });
      return;
    }
    if (this.selected_project.resource_data.value == "" || this.selected_project.resource_data.value == null ) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "프로젝트 이름을 입력해주세요."
        }
      });
      return;
    }
    
    if(this.selected_project.resource_data.description.length >255){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'프로젝트 설명은 255자 이내로 작성해주세요.'
        }
      });
      return;
    }
    if (this.selected_project.resource_data.projectManager == "" || this.selected_project.resource_data.projectManager == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "프로젝트 담당자를 지정해주세요."
        }
      });
      return;
    }
    this.osapi.updateProject(this.selected_project.id,this.selected_project.resource_data.value,
      this.selected_project.resource_data.description,
      this.selected_project.resource_data.projectManager).subscribe((data) => {
      
      this.closeDialogMsg("success");
    });
    
  }
  
  getSubUsers() {
    this.spinner.show();
    this.osapi.getSubUsers().subscribe((data: { result: any }) => {

      if (data.result == null) {
        throw 'osapi.getSubUsers Fail';
      }
      this.subUsers = data.result;
      for(let subuser of this.subUsers){
        subuser.is_project_loaded = false;
        this.setProjectsById(subuser);
      }

      this.spinner.hide();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  
  setProjectsById(subuser) {
    try {
      subuser.projects=[{name:"Loading..."}]
      if(subuser.horizonUid){
        this.osapi.getProjectsById(subuser.horizonUid).subscribe((data: { items }) => {
          subuser.is_project_loaded = true;

          subuser.projects = data.items;
          for(const prj of subuser.projects){
            if(prj.id == this.selected_project.id){
              this.user_list.push(subuser);
            }
          }
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
        });
      }
    } catch (e) {

    }
  }

}
