import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Buffer } from 'buffer';
import { Subject } from 'rxjs';

declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private eventCallback = new Subject<any>();

  eventCallback$ = this.eventCallback.asObservable();

  constructor(
    private httpClient: HttpClient
  ) { }
  public siteUrl: string = '';
  public siteRelativeUrl: string = '';
  public stopUpload: boolean = false;
  public fileUpload(file: any, documentLibrary: string, fileName: string, optionHeaders: any) {
    return new Promise((resolve, reject) => {
      this.stopUpload = false;
      let fr = new FileReader();
      let offset = 0;
      let total = file.size;
      // console.log(total);
      let length = 1000000 > total ? Math.round(total * 0.8) : 1000000
      let chunks = [];
      fr.readAsArrayBuffer(file);
      fr.onload = (evt: any) => {
        while (offset < total) {
          if(this.stopUpload) {
            break;
          }
          if (offset + length > total) {
            length = total - offset;
          }
          chunks.push({
            offset,
            length,
            method: this.getUploadMethod(offset, length, total),
            total
          });
          offset += length;
        }
        const chunkPercentage = (total / chunks.length) / total * 100;
        if (chunks.length > 0) {
          this.uploadFile(evt.target.result, documentLibrary, fileName, chunks, 0, chunkPercentage, optionHeaders, resolve, reject);
        }
      };
    });
  }
  
  uploadFileChunk(libraryPath, fileName, chunk, data, optionHeaders) {
    return new Promise((resolve, reject) => {
      let endpoint = `/cephapi/s3/bucket/${libraryPath}/object`;
      let encode_fileName = Buffer.from(fileName, "utf8");
      let encode_prefix = (optionHeaders.prefix === '' ? optionHeaders.prefix : optionHeaders.prefix.split('/').map(p => Buffer.from(p, "utf8").toString('base64')).join('/'));
      const headers = {
        headers: new HttpHeaders().set('Accept', 'application/json; odata=verbose').set('Content-Type', 'application/octet-stream')
                    .set('allieum-objectstorage-filename', encode_fileName.toString('base64')).set('allieum-objectstorage-type', optionHeaders.type).set('allieum-objectstorage-acl', optionHeaders.acl)
                    .set('allieum-objectstorage-prefix', encode_prefix)
                    .set('allieum-objectstorage-chunks', Buffer.from(JSON.stringify(chunk)).toString("base64"))
      }
      this.executePost(endpoint, data, headers).then(offset => resolve(offset)).catch(err => reject(err));
    });
  }
  
  uploadFile(result, libraryPath, fileName, chunks, index, chunkPercentage, optionHeaders, resolve, reject) {
    const data = this.convertFileToBlobChunks(result, chunks[index]);
    this.uploadFileChunk(libraryPath, fileName, chunks[index], data, optionHeaders).then(value => {
      const isFinished = index === chunks.length - 1;
      index += 1;
      const percentageComplete = isFinished ? 100 : Math.round((index * chunkPercentage));
      if(percentageComplete == 100) {
        this.eventCallback.next({id:fileName+'_progress', percent:'완료'});
      } else {
        this.eventCallback.next({id:fileName+'_progress', percent:percentageComplete+'%'});
      }

      if(this.stopUpload) {
        return;
      }

      if (index < chunks.length) {
        this.uploadFile(result, libraryPath, fileName, chunks, index, chunkPercentage, optionHeaders, resolve, reject);
      } else {
        resolve(value);
      }
    }).catch(err => {
      console.log('Error in uploadFileChunk! ' + err);
      reject(err);
    });
  }
  
  getUploadMethod(offset, length, total) {
    if (offset + length + 1 > total) {
      return 'finishupload';
    } else if (offset === 0) {
      return 'startupload';
    } else if (offset < total) {
      return 'continueupload';
    } 
    return null;
  }
  
  convertFileToBlobChunks(result, chunkInfo) {
    return result.slice(chunkInfo.offset, chunkInfo.offset + chunkInfo.length);
  }

  async executePost(url, data, requestHeaders) {
    const res = await this.httpClient.post(url, data, requestHeaders).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      throw error;
    });
    return this.parseRetSingle(res);
  }

  async deleteTempFile(url, fileName) {
    let hp = new HttpParams();

    hp = hp.append('fileName', fileName);
    const res = await this.httpClient.delete(url, {params:hp}).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return this.parseRetSingle(res);
  }

  parseRetSingle(res) {
    if (res) {
      if (res.hasOwnProperty('d')) {
        return res.d;
      } else if (res.hasOwnProperty('error')) {
        const obj: any = res.error;
        obj.hasError = true;
        return obj;
      } else {
        return {
          hasError: true,
          comments: res
        };
      }
    } else {
      return {
        hasError: true,
        comments: 'Check the response in network trace'
      };
    }
  }

  changeStatus(data) {
    this.stopUpload = data.stopUpload;
  }
}