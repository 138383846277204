import { Component, OnInit, Inject } from '@angular/core';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';

@Component({
  selector: 'app-dialog-objectstorage-join',
  templateUrl: './dialog-objectstorage-join.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogObjectStorageJoinComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private cephapi: CephapiService,
    public dialogRef: MatDialogRef<DialogObjectStorageJoinComponent>

  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }


  ngOnInit(): void {


  }


  joinObjectStorage() {
    try {
      this.spinner.show();
      this.cephapi.createRgwUser().subscribe((data) => {
        this.spinner.hide();
        this.closeDialogMsg({code:"success",data:data});
      },
      (error) => {
        let msg = "일시적으로 문제가 발생하였습니다. 새로고침해주세요.";
        // if(error.status == 409) msg = "이미 존재하는 버킷 이름입니다.";
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:msg
        }
      });
      });
    } catch (e) {
      this.spinner.hide();

    }
  }

}
