import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-subnet-update',
  templateUrl: './dialog-subnet-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogSubnetUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSubnetUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
 
  selected_subnet: any = {};
  updateSubnetData:{name:string}={name:""}


  ngOnInit(): void {

    this.selected_subnet = this.dialogData.selected_subnet;
    this.updateSubnetData.name = this.selected_subnet.name;
  }


  
  updateSubnet() {
    if(this.updateSubnetData.name ==""){;
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `이름을 입력해주세요.`
        }
      });
      return;
    }
    this.spinner.show();
    var subnet = this.selected_subnet;
    this.osapi.updateSubnet(subnet.id, { "name": this.updateSubnetData.name }).subscribe(
      (data: any) => {
        this.spinner.hide();

        this.closeDialogMsg("success");
      },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

}
