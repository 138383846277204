import { Component, OnInit, Inject } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { OsapiService } from '../../osapi.service';
import { LoadbalancerData } from "./LoadbalancerData";
import { HealthMonitorData } from "./HealthMonitorData";
import { ListenersData } from "./ListenersData";
import { MemberData } from "./MemberData";
import { PoolData } from "./PoolData";
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router, ActivatedRoute, ParamMap} from '@angular/router';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-load-balancer-setting',
  templateUrl: './console-load-balancer-setting.component.html',
})
export class ConsoleLoadBalancerSettingComponent implements OnInit {
  lbdata = new LoadbalancerData();
  lbpool = new PoolData();
  lbListener = new ListenersData();
  lbmember: any = [];
  lbmonitor = new HealthMonitorData();

  selected_protocol: string;
  subnetList: any = [];
  selected_subnet_id: string;
  selected_flavor_id: string;

  serverList: any = [];

  servers: any = [];

  // 검색 텍스트
  filterText: string;

  loadbalancer_id:string;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    public global: GlobalService,
    private actRoute: ActivatedRoute,
    @Inject(ConsoleNavComponent)private parent:ConsoleNavComponent
  ) {
    this.loadbalancer_id = this.actRoute.snapshot.paramMap.get('loadbalancer_id');
   }

  ngOnInit(): void {
    this.getSubnetList();
    this.getServerList();
    this.getLoadbalancerflavorList();
    this.getLoadbalancer();
  }

  getLoadbalancer(){
    if(this.loadbalancer_id != null){
      this.osapi.getLoadbalancerId(this.loadbalancer_id).subscribe(
        (data:any)=>{
          this.lbdata.name = data.name;
          this.lbdata.description = data.description;
          this.lbdata.vip_subnet_id = data.vip_subnet_id;
          this.selected_subnet_id = data.vip_subnet_id;
        }
      )
    }
  }

  getSubnetList() {
    this.osapi.getSubnetList().subscribe(
      (data: any) => {
        this.osapi.getResource('network').subscribe(
          (networkResource:any)=>{
            data.forEach(subnet => {
              networkResource.forEach(resource => {
                if(subnet.network_id == resource.objectId){
                  if(resource.description == "ext"){
                    this.subnetList.push(subnet);
                  }
                }
              });
            });
            //this.subnetList = data;
          }
        )
        
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  getServerList() {
    //parent 가져오기
    this.spinner.show();
    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList();
      }, 200)
    } else {
      this.serverList = this.parent.serverList;
      this.spinner.hide();
    }
  }

  changeProtocol(protocol) {
    this.selected_protocol = protocol;
    this.lbListener.protocol = protocol;
    this.lbpool.protocol = protocol;
  }

  changePersistence(value) {
    if (value === "None") {
      this.lbpool.session_persistence = null;
    } else {
      this.lbpool.session_persistence = { type: "SOURCE_IP" };
    }
  }

  changeAlgorithm(value) {
    this.lbpool.lb_algorithm = value;
  }
  changeMonitorType(value) {
    this.lbmonitor.type = value;
  }

  createLoadbalancer() {
    
    if(this.lbdata.name == null ||this.lbdata.name == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '로드밸런서의 이름을 입력해주세요.'
        }
      });
      return;
    }
    if(this.selected_subnet_id == null ||this.selected_subnet_id == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '서브넷을 선택해주세요.'
        }
      });
      return;
    }
    if(this.lbListener.name == null ||this.lbListener.name == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '리스너 이름을 입력해주세요.'
        }
      });
      return;
    }

    this.spinner.show();
    this.lbdata.vip_subnet_id = this.selected_subnet_id;
    this.lbdata.flavor_id = this.selected_flavor_id;

    for (let i = 0; i < this.lbmember.length; i++) {
      this.lbmember[i].id = i;
    }

    var loadbalancer = JSON.parse(JSON.stringify(this.lbdata));
    var listeners = JSON.parse(JSON.stringify(this.lbListener));
    var pools = JSON.parse(JSON.stringify(this.lbpool));
    var members = JSON.parse(JSON.stringify(this.lbmember));
    var monitors = JSON.parse(JSON.stringify(this.lbmonitor));

    if(monitors.type != 'HTTP'){
      delete monitors.url_path;
      delete monitors.expected_codes;
      delete monitors.http_method;
    }

    /**
     * 20200824 cbkim 
     * member subnet_id 추가 
     */
    for (let item of members) {
      item.subnet_id = this.selected_subnet_id;
    }

    const param = {
      loadbalancer: loadbalancer,
      listener: listeners,
      pool: pools,
      members: members,
      monitor: monitors
    };


    this.osapi.createLoadbalancer(param).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.router.navigate(['/console/loadbalancer']);
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  addSubnet(server) {
    var memberData = new MemberData();
    if (server.ip_private.length > 0) {
      memberData.name = server.server_name;
      memberData.address = server.ip_private[0] || '';
      memberData.backup = server.backup;
      this.lbmember.push(memberData)
    }
  }

  delSubnet(server) {
    var newServers = []
    for (let item of this.lbmember) {
      if (item.address != server.address) {
        newServers.push(item);
      }
    }
    this.lbmember = newServers;
  }

  selectSubnet(value) {
    this.selected_subnet_id = value;
  }

  getLoadbalancerflavorList() {
    this.osapi.getLoadbalancerflavorList().subscribe(
      (data: any) => {
        for (let item of data.items) {
          if (item.name === "Amphora-flavor-basic") {
            this.selected_flavor_id = item.id;
            break;
          }
        }
      }
    )
  }

  checkSubnet(server) {
    for (let intf of server.network_interfaces) {
      for (let port of intf.ports) {
        for (let fixed_ip of port.fixed_ips) {
          if (fixed_ip.subnet_id == this.selected_subnet_id) {
            return true
          }
        }
      }
    }
    return false;
  }

  changeMode(result){
    if("0" == result){
      this.selectedServer.backup = false;
    }else{
      this.selectedServer.backup = true;
    }
  }

  selectedServer;  
  onClickMemberList(server){
    this.selectedServer = server;
  }

  createListener(){
    this.spinner.show();

    // member 넘버링
    for (let i = 0; i < this.lbmember.length; i++) {
      this.lbmember[i].id = i;
    }

    var listeners = JSON.parse(JSON.stringify(this.lbListener));
    var pools = JSON.parse(JSON.stringify(this.lbpool));
    var members = JSON.parse(JSON.stringify(this.lbmember));
    var monitors = JSON.parse(JSON.stringify(this.lbmonitor));

    if(monitors.type != 'HTTP'){
      delete monitors.url_path;
      delete monitors.expected_codes;
      delete monitors.http_method;
    }



    for (let item of members) {
      item.subnet_id = this.selected_subnet_id;
    }

    const param = {
      loadbalancer_id: this.loadbalancer_id,
      listener: listeners,
      pool: pools,
      members: members,
      monitor: monitors
    };

    console.log(param);

    this.osapi.createListener(param).subscribe(
      (data)=>{
        this.spinner.hide();
        this.router.navigate(['/console/loadbalancer']);
      }
    )
  }
}
