import { Component, OnInit } from '@angular/core';

import { GWService } from '../../gwapi.service';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import { DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-apigateway-service',
  templateUrl: './console-apigateway-service.component.html',
})

export class ConsoleApigatewayServiceComponent implements OnInit {


  constructor(
    // @Inject(DOCUMENT) private document: Document,
    // private renderer: Renderer2,
    // private http: HttpClient,
    private gwapi:GWService,
    private osapi:OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private router:Router,
    public global: GlobalService,
    private dialogs:DialogSetService,
    private common:ConsoleNavComponent
    ) { }

    ngOnInit(): void {

      this.getAlreadyUsed();
    }
    already_used_flag = true;
    getAlreadyUsed(){
      this.spinner.show();
      this.gwapi.getAlreadyUsed().subscribe((data: any) => {
        this.spinner.hide();
        console.log(data)
        this.already_used_flag = data.result
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });

    }
    startGatewayService(){
      this.spinner.show();
      this.gwapi.startGatewayService().subscribe((data: any) => {
        this.spinner.hide();
        this.router.navigate(['/console/apigateway/api']);
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });

    }
}
