<!-- Modal : Bucket 생성 모달 -->
<div id="folder_create" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      API 앤드포인트 정보
      <p class="sub-txt">액세스 키를 사용하여 S3 SDK 또는 직접 S3 API 호출을 통해 오브젝트 스토리지를 프로그래밍 방식으로 호출합니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>Endpoint</th>
            <td><input type="text" [(ngModel)]="objectStorageInfo.endpoint" readonly /></td>
          </tr>
          <tr>
            <th>Access Key</th>
            <td class='showKey'>
              <ng-template [ngIf]="accessKeyHide === true" [ngIfElse]="showAccessKey">
                <button class="btn mid bor_gray mg_r4" style="width:100%" (click)="accessKeyHide=!accessKeyHide">
                    <i class="fa fa-eye fa-lg"></i>
                </button>
              </ng-template>
              <ng-template #showAccessKey>
                <input [(ngModel)]="objectStorageInfo.accessKey" type="text" #accessKeyInput readonly>
                <button class="btn bor_gray copy_btn" type="button" (click)="copytokey(accessKeyInput)">
                  <i class="fa fa-clipboard" title="Copy to clipboard"></i>
                </button>
              </ng-template>
            </td>
          </tr>
          <tr>
            <th>Secret Access Key</th>
            <td class='showKey'>
              <ng-template [ngIf]="secretAccessKeyHide === true" [ngIfElse]="showSecretAccessKey">
                <button class="btn mid bor_gray mg_r4" style="width:100%" (click)="secretAccessKeyHide=!secretAccessKeyHide">
                    <i class="fa fa-eye fa-lg"></i>
                </button>
              </ng-template>
              <ng-template #showSecretAccessKey>
                <input type="text" [(ngModel)]="objectStorageInfo.secretAccessKey" #secretAccessKeyInput readonly />
                <button class="btn bor_gray copy_btn" type="button" (click)="copytokey(secretAccessKeyInput)">
                  <i class="fa fa-clipboard" title="Copy to clipboard"></i>
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <!-- <a (click)="createFolder()" class="btn big ciblue mg_r4">생성</a> -->
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->