import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { Router } from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';


@Component({
  selector: 'app-console-storage-list',
  templateUrl: './console-storage-list.component.html',
})


export class ConsoleStorageListComponent implements OnInit {
  volumeList: any=[];
  serverList: any=[];
  selected_volume;

  volume_list_loading_flag=false;
  
  // 검색 텍스트
  filterText: string;

  isActived: boolean = false;
  isChecked: boolean = false;

  constructor(
    private osapi:OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private router:Router,
    private dialogs:DialogSetService,
    @Inject(ConsoleNavComponent) private parent:ConsoleNavComponent
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getVolumeList();
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);    
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }
  
  onClickList(volume){
    this.isActived = true;
    this.selected_volume = volume;
  }
  refreshStorageList(){
    this.parent.getServerList().then(() => {
      this.selected_volume = null;
      this.volume_list_loading_flag=false;
      this.getVolumeList();  
    });
  }
  openNewStorageModal(){
    this.dialogs.openStorageCreateDialog({serverList:this.serverList});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.parent.isLoadedServer =false;
        this.refreshStorageList();
      }
    });
  }
  openNewSnapshotModal(){
    if(this.volume_list_loading_flag){
      this.dialogs.openStorageSnapshotCreateDialog({selected_volume:this.selected_volume,volumeList:this.volumeList});
    }else{
      this.spinner.show();
      setTimeout(() =>  { this.openNewSnapshotModal();  }, 500);
    }
  }
  openNewImageModal(){
    this.dialogs.openStorageImageCreateDialog({selected_volume:this.selected_volume});
  }
  openAttachStorageModal(){
    if(this.volume_list_loading_flag){
      this.dialogs.openStorageAttachDialog({selected_volume:this.selected_volume,serverList:this.serverList});
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "success"){
          this.refreshStorageList();
        }
      });
    }else{
      this.spinner.show();
      setTimeout(() =>  { this.openAttachStorageModal();  }, 500);
    }
  }
  openDetachStorageModal(){
    this.dialogs.openStorageDetachDialog({selected_volume:this.selected_volume});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.refreshStorageList();
      }
    });
  }
  openUpdateStorageModal(){
    this.dialogs.openStorageUpdateDialog({selected_volume:this.selected_volume});
  }
  openDeleteStorageModal(){
    if(this.selected_volume.attachments.length > 0){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'인스턴스가 연결되어있습니다.'
        }
      });
      return;
    }
    this.dialogs.openDefaultDeleteDialog({
      title:"블록 스토리지 삭제",
      content:"선택하신 스토리지("+this.selected_volume.name+")를 정말로 삭제하시겠습니까?<br>한 번 삭제한 스토리지는 복구할 수 없습니다."});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteVolume();
        }
      });
  }
  getVolumeList(){    
    this.osapi.getVolumeList().subscribe(
      (data:{items:[]}) => {

      this.volumeList= data.items;
      this.getServerList();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
  getServerList(){

    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList();
      }, 200)
    } else {
      
      this.spinner.hide();
      this.serverList = this.parent.serverList;
      this.volumeList.forEach(volume => {
        volume.attachments.forEach(attachment => {
          this.serverList.forEach(server => {
            if (attachment.server_id == server.server_id) {
              attachment.server_name = server.server_name;
            }
          });
        });
      });
      this.volume_list_loading_flag = true;

    }
    
    // this.osapi.getServerList().subscribe(
    //   (data: {}) => {
    //   this.serverList= data;

    //   this.volumeList.forEach(volume => {
    //     volume.attachments.forEach(attachment => {
    //       this.serverList.forEach(server => {
    //         if(attachment.server_id == server.server_id){
    //           attachment.server_name = server.server_name;
    //         }
    //       });
    //     });
    //   });
    //   this.volume_list_loading_flag= true;
    //   this.spinner.hide();
    // }, 
    // (error) => {

    //   this.spinner.hide();
    //   this.frontapi.changeMessage({
    //     type:'toast',
    //     body:{
    //       type:'error',
    //       title:'오류 알림',
    //       message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
    //     }
    //   });
    // });
  }
  deleteVolume(){    
    this.spinner.show();
    this.osapi.deleteVolume(this.selected_volume.id).subscribe(
      (data:{}) => {
        if(this.router.url=='/console/storage'){
          setTimeout(() => 
          {
            this.router.navigateByUrl('/console', {
              skipLocationChange: true,
            }).then(
              () => {
                this.router.navigate(['/console/storage']);
              }
            );
          },
          1000);
        }
    }, 
    (error) => {

      this.spinner.hide();
      if(error.status ==400){
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'삭제할 수 없는 스토리지입니다. 해당 스토리지와 연결된 이미지나 스토리지를 먼저 삭제해주시기 바랍니다.'
          }
        });
        return;
      }
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
}
