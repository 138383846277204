<!-- SkipMenu -->
<div id="SkipMenu">
  <div class="row">
    <nav class="skipNav">
      <ul>
        <li><a href="#Content" title="본문바로가기">본문 바로가기</a></li>
      </ul>
    </nav>
  </div>
  <div class="skipClose">
    <a href="#" title="바로가기메뉴 닫기" class="ico"
      ><span class="ico ico_close txtNone">Close</span></a
    >
  </div>
</div>
<!--// SkipMenu -->

<!-- wrap -->
<div id="Wrap" name="webNav" class="main">
  <!-- Header -->
  <div id="Header">
    <div class="logo" (click)="routerHome()" style="cursor: pointer;">
      <a></a>
    </div>
    <div id="Lnb">
      <a href="javascript:void(0)" (click)="menuListOpen()" class="btn_allMenu"></a>
      <ul class="ls_lnb" id="side_nav">
        <li>
          <a routerLink="introduction" [class]="introduction.isActive || contact.isActive  ? 'on' : ''">소개</a>
          <ul class="subMenu">
            <li>
              <a routerLink="introduction" routerLinkActive="on" #introduction="routerLinkActive">회사소개</a>
            </li>
            <li>
              <a routerLink="contact-us" routerLinkActive="on" #contact="routerLinkActive">오시는 길</a>
            </li>
          </ul>
        </li>
        <li><a routerLink="service" routerLinkActive="on">{{'web-navigation-service'|translate}}</a></li>
        <li><a routerLink="servicePrice" routerLinkActive="on">{{'web-navigation-price'|translate}}</a></li>
        <li>
          <a routerLink="customer-center"
            [class]="notice.isActive || faq.isActive || material.isActive  ? 'on' : ''">고객지원</a>
          <ul class="subMenu">
            <li>
              <a routerLink="noticeList" routerLinkActive="on" #notice="routerLinkActive" data-langNum="1">공지사항</a>
            </li>
            <li>
              <a routerLink="faqList" routerLinkActive="on" #faq="routerLinkActive">FAQ</a>
            </li>
            <li>
              <a routerLink="materialList" routerLinkActive="on" #material="routerLinkActive">자료실</a>
            </li>
          </ul>
        </li>        
        <li>
          <a href="assets/guide.html" target="_blank">사용자가이드</a>
          <ul class="subMenu">
            <li>
               <a href="assets/quick-start.html" target="_blank">빠른 시작</a>
            </li>
            <li>
                <a href="assets/guide.html" target="_blank">사용자가이드</a>
            </li>
          </ul>
        </li>
        <li *ngIf="loginCheck">
          <a href="javascript:void(0)" 
            [class]="credit.isActive || payment.isActive || history.isActive || inquriy.isActive ? 'on' : ''">마이페이지</a>
          <ul class="subMenu">
            <li>
              <a href="javascript:void(0)" (click)="checkMypage()">회원정보</a>
            </li>              
            <li>
              <a routerLink="mypage/credit" routerLinkActive="on" #credit="routerLinkActive">크레딧</a>
            </li>              
            <li>
              <a routerLink="mypage/payment" routerLinkActive="on" #payment="routerLinkActive">결제수단</a>
            </li>
            <li>
              <a routerLink="mypage/seviceHistory" routerLinkActive="on" #history="routerLinkActive">서비스이용내역</a>
            </li>
            <li>
              <a routerLink="mypage/inquriyList" routerLinkActive="on" #inquriy="routerLinkActive">1:1문의</a>
            </li>
          </ul>
        </li>
        <li *ngIf="loginCheck">
          <a href="http://service.bizlinkersys.com/" target="_blank">베타서비스</a>
        </li>
      </ul>
    </div>
    <div id="Gnb">
      <ul class="ls_gnb">
        <li *ngIf="loginCheck; else loggedOut">
          <a href="javascript:void(0)" (click)="logOut()">로그아웃</a>
        </li>
        <ng-template #loggedOut>
          <li>
            <a routerLink="/member/login">로그인</a>
          </li>
        </ng-template>
        <li *ngIf="!loginCheck">
          <a routerLink="/member/signUp">회원가입</a>
        </li>
        <li *ngIf="blsAdmincheck">
          <a class="btn_console mg_r4" routerLink="/admin">관리자</a>
        </li>
        <ng-template [ngIf]="memberStatus == '1' && loginCheck">
          <ng-template [ngIf]="memberPosition == '0' || memberPosition == '1'">
            <li>
              <a class="btn_console" routerLink="/console">승인대기중</a>
            </li>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="(memberStatus == '2' ) && loginCheck">
          <ng-template [ngIf]="memberPosition == '0' || memberPosition == '1'">
            <li>
              <a *ngIf="!mobileCheck" class="btn_console" routerLink="/console" target="_blank">console</a>
              <a *ngIf="mobileCheck" class="btn_console" routerLink="/m" target="_blank">console</a>
            </li>
            <!-- <li>
              &nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <a *ngIf="!mobileCheck" class="btn_console" href="http://service.bizlinkersys.com/" target="_blank">beta-service</a>
              <a *ngIf="mobileCheck" class="btn_console" href="http://service.bizlinkersys.com/" target="_blank">beta-service</a>
            </li> -->
          </ng-template>
        </ng-template>
        
        <!-- <li>
            <select style="height:auto; margin-left:20px;"
            (change)="lang($event.target.value)" required>
              <option data-lang="ko" value="1" selected="selected">한국어</option>
              <option data-lang="en" value="2">English</option>
              <option data-lang="ja" value="3">日本語</option>
            </select>
        </li> -->
      </ul>
    </div>
  </div>
  <!-- //Header -->
  <hr />

  <!-- ROUTER -->
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <!-- //ROUTER -->

  <!-- Scroll To Top -->
  <app-common-top></app-common-top>
  <!-- Footer -->
  <div id="Footer">
    <div class="footer_inner">
      <div class="logo">
        <a href="javascript:void(0)"></a>
      </div>
      <ul class="ls_footer">
        <li><a routerLink="introduction">회사소개</a></li>
        <li><a routerLink="policy">이용약관</a></li>
        <li><a routerLink="privacy">개인정보처리방침</a></li>
        <li><a routerLink="term-email">이메일무단수집거부</a></li>
        <li><a routerLink="contact-us">오시는 길</a></li>
      </ul>
      <div class="address">
        <span>
          서울특별시 금천구 가산디지털2로 123, 315호(가산동,
          월드메르디앙2차)
          </span>
        <span>대표 : 이정신  메일주소 : contact@bizlinkersys.com</span>
        <span>전화번호 : 070-7500-1290</span>
      </div>
      <div class="copyright">Copyright © 2020 올이음. All rights reserved.</div>
    </div>
  </div>
  <!-- //Footer -->
</div>
<!-- //wrap -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>