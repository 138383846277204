<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>로드밸런서</h2>
        <div class="lineMap">
            Network / <span>LoadBalancer</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="title_wrap">
                        <div class="contArea">
                            <div class="tit">
                                <app-console-projects></app-console-projects>
                            </div>
                            <div class="cont">
                                <app-console-network-status></app-console-network-status>
                            </div>
                            <div class="contSub">
                                <button routerLink="/console/loadbalancer/create" routerLinkActive="router-link-active"
                                    class="btn mid blue mg_r4">
                                    <i class="ico ico_register_white"></i>로드밸런서 생성
                                </button>
                                <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="createListener()">리스너 추가</button>
                                <!-- <button class="btn mid ciblue mg_r4" (click)="updateMember()" [disabled]="!isActived">맴버
                                    수정</button>
                                <button class="btn mid ciblue mg_r4" (click)="updatePool()" [disabled]="!isActived">풀
                                    수정</button>
                                <button class="btn mid ciblue mg_r4" (click)="updateListener()"
                                    [disabled]="!isActived">리스너 수정</button> -->
                                <button class="btn mid ciblue mg_r4"
                                    [disabled]="!(isActived && !selected_loadbalancer.is_floating_ip)"
                                    (click)="openAssociateIpModal()">공인IP 할당</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->
                <!-- lyBox -->
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>로드밸런서 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" [(ngModel)]="filterText" id="search"
                                    value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i
                                            class="ico ico_search_white"></i>검색</button>
                                </div>
                                <!-- 접기 버튼 -->
                                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                            </div>
                        </div>
                    </div>
                    <div class="contArea" style="display:block">
                        <div class="boardBox">
                            <mat-accordion>
                                <table class="tbl_dtl_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 15%;" />
                                        <!-- <col > -->
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                        </th>
                                        <th scope="col">이름</th>
                                        <th scope="col">사설 IP</th>
                                        <th scope="col">공인 IP</th>
                                        <th scope="col">멤버 상태</th>
                                        <th scope="col">상태</th>
                                        <th scope="col">리스너</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let loadbalancer of loadbalancerList | tableFilter: filterText"
                                            (click)="onClickList(loadbalancer)"
                                            (contextmenu)="onClickList(loadbalancer)">
                                            <td colspan="7" style="padding:0;">

                                                <mat-expansion-panel hideToggle class="auth_theme">
                                                    <mat-expansion-panel-header>

                                                        <table class="  dropmenu">
                                                            <colgroup>
                                                                <col style="width: 5%;" />
                                                                <col style="width: 15%;" />
                                                                <col style="width: 15%;" />
                                                                <col style="width: 15%;" />
                                                                <col style="width: 15%;" />
                                                                <col style="width: 15%;" />
                                                                <col style="width: 15%;" />
                                                            </colgroup>
                                                            <tr class="btn_expand">
                                                                <!-- (contextmenu)="open($event, 'test'); $event. preventDefault();" -->
                                                                <td><input type="checkbox" name="sel_chkbox"
                                                                        (change)="onListCheck(loadbalancer)"
                                                                        [attr.data-id]="loadbalancer.id">
                                                                </td>
                                                                <td>{{loadbalancer.name}}</td>
                                                                <td>
                                                                    <p>{{loadbalancer.vip_address}}</p>
                                                                </td>
                                                                <td>
                                                                    <p *ngIf="loadbalancer.is_floating_ip;else elseFIP">
                                                                        {{loadbalancer.floating_ip.ip}}</p>
                                                                    <ng-template #elseFIP>
                                                                        <p>없음</p>
                                                                    </ng-template>
                                                                </td>
                                                                <td>
                                                                    <label
                                                                        [class]="loadbalancer.operating_status_ko == 'ONLINE'? 'dot green': loadbalancer.operating_status == 'WARNING' ? 'dot yellow' : 'dot red'"></label>
                                                                    {{loadbalancer.operating_status_ko}}
                                                                </td>
                                                                <td><label
                                                                        [class]="loadbalancer.provisioning_status == 'ACTIVE'? 'dot green': 'dot red'"></label>
                                                                    {{loadbalancer.provisioning_status}}
                                                                </td>
                                                                <td>
                                                                    <p *ngIf="!loadbalancer.listener">
                                                                        없음
                                                                    </p>
                                                                    <p *ngFor="let listener of loadbalancer.listener">
                                                                        {{listener.protocol }} {{listener.protocol_port}}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </mat-expansion-panel-header>
                                                    <div class="result-tbl" style="min-height:60px;" class="auth_theme">
                                                        <div *ngIf="!loadbalancer.listener" style="position: absolute;left:50%;top:50%;">
                                                            불러오는 중 입니다.
                                                        </div>
                                                        <mat-tab-group animationDuration="0ms">
                                                            <div *ngIf="loadbalancer.listener && loadbalancer.listener.length == 0" style="position: absolute;left:50%;top:50%;">
                                                                등록된 리스너가 없습니다.
                                                            </div>
                                                            <mat-tab *ngFor="let listener of loadbalancer.listener"
                                                                [label]="listener.name?listener.name:'Listener'">
                                                                <table class="tbl_dtl_tp2">
                                                                    <colgroup>
                                                                        <col style="width: 10%;" />
                                                                        <col style="width: 23%;" />
                                                                        <col style="width: 10%;" />
                                                                        <col style="width: 23%;" />
                                                                        <col style="width: 10%;" />
                                                                        <col style="width: 23%;" />
                                                                        <!-- <col > -->
                                                                    </colgroup>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th colspan="5">
                                                                                * 리스너
                                                                                <!-- <a (click)="updateListener()"
                                                                                    class="btn tiny dShow"></a> -->
                                                                            </th>
                                                                            <td>
                                                                                <!-- <button style="float:right" class="btn mid bor_gray mg_r4" (click)="refersh()">새로고침</button> -->
                                                                                <button style="float:right" class="btn mid ciblue mg_r4" (click)="createListener()">리스너 추가</button>
                                                                                <button style="float:right" class="btn mid bor_gray mg_r4" (click)="openDeleteListenerDialog(listener)">삭제</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>리스너 이름</th>
                                                                            <td>{{listener.name}}</td>
                                                                            <th>Protocol</th>
                                                                            <td>{{listener.protocol}}</td>
                                                                            <th>상태</th>
                                                                            <td>
                                                                                <label
                                                                                    [class]="listener.operating_status_ko == 'ONLINE'? 'dot green': listener.operating_status == 'WARNING' ? 'dot yellow' : 'dot red'"></label>
                                                                                {{listener.operating_status_ko}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>프로토콜 포트</th>
                                                                            <td>{{listener.protocol_port}}</td>
                                                                            <th>연결수 제한</th>
                                                                            <td>{{listener.connection_limit}}</td>

                                                                            <ng-template #emptyFloatingIp>
                                                                                <td>---</td>
                                                                            </ng-template>
                                                                        </tr>
                                                                        <tr>

                                                                        </tr>
                                                                        <tr>
                                                                            <th colspan="6"
                                                                                style="border-top: solid 1px #e2e2e2;">
                                                                                * POOL
                                                                                <!-- <a (click)="updatePool()"
                                                                                    class="btn tiny dShow"></a> -->
                                                                            </th>
                                                                        </tr>
                                                                        <tr *ngIf="listener.pool">
                                                                            <th>풀 이름</th>
                                                                            <td>{{listener.pool.name}}</td>
                                                                            <th>풀 프로토콜</th>
                                                                            <td>{{listener.pool.protocol}}</td>
                                                                            <th>상태</th>
                                                                            <td>
                                                                                <label
                                                                                    [class]="listener.pool.operating_status_ko == 'ONLINE'? 'dot green': listener.pool.operating_status == 'WARNING' ? 'dot yellow' : 'dot red'"></label>
                                                                                {{listener.pool.operating_status_ko}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr *ngIf="listener.pool">
                                                                            <th>알고리즘</th>
                                                                            <td>{{listener.pool.lb_algorithm}}</td>

                                                                        </tr>
                                                                        <tr *ngIf="listener.pool">
                                                                            <th colspan="6"
                                                                                style="border-top: solid 1px #e2e2e2;">
                                                                                * 멤버 리스트
                                                                                <a (click)="updateMember(listener)"
                                                                                    class="btn tiny dShow"></a>
                                                                            </th>
                                                                        </tr>
                                                                        <tr *ngFor="let member of listener.pool.members; let i = index"
                                                                            [attr.data-index]="i">
                                                                            <th>
                                                                                <div class="tooltip">
                                                                                    <label
                                                                                        [class]="member.backup? 'dot yellow': 'dot green'"></label>멤버{{i+1}}
                                                                                    <div class="tooltip-content"
                                                                                        style="width:50px;">
                                                                                        {{member.backup? 'StandBy' : 'Active'}}
                                                                                    </div>
                                                                                </div>
                                                                            </th>
                                                                            <td>
                                                                                {{member.name}} [{{member.address}}]
                                                                            </td>
                                                                            <th>포트</th>
                                                                            <td>
                                                                                {{member.protocol_port}}
                                                                            </td>
                                                                            <th>상태</th>
                                                                            <td>
                                                                                <label
                                                                                    [class]="member.operating_status_ko == 'ONLINE'? 'dot green': member.operating_status == 'WARNING' ? 'dot yellow' : 'dot red'"></label>
                                                                                {{member.operating_status_ko}}
                                                                            </td>
                                                                        </tr>
                                                                        <!-- <tr>
                                                                                    <th colspan="6" style="border-top: solid 1px #e2e2e2;">
                                                                                        * HealthMonitor
                                                                                        <a (click)="updateHealthMonitor()"class="btn tiny dShow"></a>
                                                                                    </th>
                                                                                </tr>
                                                                                <tr *ngIf="loadbalancer.healthmonitor.type=='HTTP'">
                                                                                    <th>HTTP 메소드</th>
                                                                                    <td>{{loadbalancer.healthmonitor.http_method}}</td>
                                                                                    <th>URL 경로</th>
                                                                                    <td>{{loadbalancer.healthmonitor.url_path}}</td>
                                                                                    <th>expected_codes</th>
                                                                                    <td>{{loadbalancer.healthmonitor.expected_codes}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>max_retries</th>
                                                                                    <td>{{loadbalancer.healthmonitor.max_retries}}</td>
                                                                                    <th>max_retries_down</th>
                                                                                    <td>{{loadbalancer.healthmonitor.max_retries_down}}</td>
                                                                                    <th>timeout</th>
                                                                                    <td>{{loadbalancer.healthmonitor.timeout}}</td>
                                                                                </tr> -->
                                                                    </tbody>
                                                                </table>
                                                            </mat-tab>

                                                        </mat-tab-group>

                                                    </div>
                                                </mat-expansion-panel>

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </mat-accordion>
                        </div>
                        <!-- <div class="btn_wrap tp1"></div> -->
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a routerLink="/console/loadbalancer/create" routerLinkActive="router-link-active">로드밸런서 생성</a>
        <!-- <a routerLink="/console/loadbalancer/create" routerLinkActive="router-link-active">로드밸런서 수정</a> -->
        <!-- <a (click)="goSettings(selected_loadbalancer)">로드밸런서 수정</a> -->
        <!-- <a (click)="updateMember()">맴버 수정</a>
        <a (click)="updateListener()">리스너 수정</a>
        <a (click)="updatePool()">풀 수정</a> -->
        <a (click)="openDeleteLBModal()">로드밸런서 삭제</a>
        <a *ngIf="!selected_loadbalancer.is_floating_ip" (click)="openAssociateIpModal()">공인 IP 할당</a>
        <a *ngIf="selected_loadbalancer.is_floating_ip" (click)="openDisassociateIpModal()">공인 IP 해제</a>
    </div>
</div>