import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WebApiService } from '../../../webapi.service';
import { OsapiService } from '../../../osapi.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-mypage-service-history',
  templateUrl: './mypage-service-history.component.html',
  providers: [DatePipe]
})
export class MypageServiceHistoryComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private osapi: OsapiService,
    private spiner: NgxSpinnerService,
    private datePipe: DatePipe
    ) {}

  userInfoList : any = [];
  activePaymentType: any = [];
  totalInvoiceInfo : any = [];
  projectList : any =[];

  invoiceDataObject:any ={
    projects:[],
    total_sum:0,
    total_reduce:0,
    before_tax:0,
    tax:0,
    real_price:0
  };
  totalRealCost: number = 0;
  usedCredit: number = 0;
  totalCount: any;
  yearList: any;
  monthList: any = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  nowYear: any;
  nowMonth: any;
  
  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'))
    const selectDate = new Date();
    this.nowYear = parseInt(this.datePipe.transform(selectDate, 'yyyy'));
    this.yearList = [this.nowYear, this.nowYear-1 , this.nowYear-2];
    this.nowMonth = this.datePipe.transform(selectDate, 'MM');
    this.getProjectList();
    this.getCompanyPaymentWay();
  }

  getCompanyPaymentWay(){
    let searchQuerys = `registration_number=${this.userInfoList.registrationNumber}&registration_number_sub=${this.userInfoList.registrationNumberSub}&active=true`;
    this.spiner.show();
    this.webapi.getCompanyPaymentWayActive(searchQuerys).subscribe((data) => {
      if (data['result']){
        this.activePaymentType = data['result'][0];        
      }
    });
    this.spiner.hide();
    this.getTotalInvoice();    
  }
  project_list_loading_flag = false;
  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        }
        this.projectList = data.items;
        this.project_list_loading_flag =true;
      },
        (error) => {
          console.error(error.status);
        });
    } catch (err) {
      console.error(err);
    }
  }
  getTotalInvoice(){
    this.webapi.getInvoiceData(`${this.nowYear}-${this.nowMonth}`).subscribe((data) => { 
      this.invoiceDataObject = data
      console.log(data)
    });
    // if(!this.project_list_loading_flag){
    //   setTimeout(() => {
    //     this.getTotalInvoice()
    //   }, 200);
    //   return;
    // }
    
    // this.invoiceDataObject ={
    //   projects:[],
    //   total_sum:0,
    //   total_reduce:0,
    //   before_tax:0,
    //   tax:0,
    //   real_price:0

    // };
    // this.totalRealCost = 0;
    // let searchQueries = `registration_number=${this.userInfoList.registrationNumber}&registration_number_sub=${this.userInfoList.registrationNumberSub}&total_invoice_month=${this.nowYear}-${this.nowMonth}`;
    // this.spiner.show();
    // this.webapi.getTotalInvoice(searchQueries).subscribe((data) => { 
    //   this.totalCount = data['total'];
      
    //   if(data['result'].length > 0){
    //     this.totalInvoiceInfo = data['result'][0];

    //     this.totalRealCost = this.totalInvoiceInfo.realCost;
    //     this.getUsedCredit(this.totalInvoiceInfo.totalInvoiceMonth);
        
    //     for(let project of this.projectList){
    //       if(!project.resource_data){
    //         continue;
    //       }
    //       let project_data ={
    //         project_id:project.id,
    //         project_name:project.resource_data.value,
    //         invoice_list:[],
    //         project_sum:0,
    //         project_reduce:0,
    //         project_raw:0,
    //       }
    //       project_data.invoice_list = this.totalInvoiceInfo.Invoices.filter(e=>(e.projectId == project_data.project_id && e.rawCost != 0 ));
    //       for(let iv of project_data.invoice_list){
    //         project_data.project_sum += parseInt(iv.realCost);
    //         project_data.project_reduce += (parseInt(iv.rawCost) -  parseInt(iv.realCost));
    //         project_data.project_raw += (parseInt(iv.rawCost));
    //       }
    //       this.invoiceDataObject.projects.push(project_data);
    //     }
    //   }
    //   for(let prj of this.invoiceDataObject.projects){
    //     this.invoiceDataObject.total_sum += prj.project_raw;
    //     this.invoiceDataObject.total_reduce += prj.project_reduce;
    //     prj.invoice_list.sort(function(a, b) {
    //       a.serviceType = a.serviceType.toUpperCase(); // ignore upper and lowercase
    //       b.serviceType = b.serviceType.toUpperCase(); // ignore upper and lowercase
    //       let a_sorting_flag = 0
    //       let b_sorting_flag = 0
    //       switch(a.serviceType){
    //         case "SERVER" : a_sorting_flag = 10;break;
    //         case "BLOCK STORAGE" : a_sorting_flag = 9;break;
    //         case "공인 IP" : a_sorting_flag = 8;break;
    //         case "LOADBALANCER" : a_sorting_flag = 7;break;
    //         case "NAS" : a_sorting_flag = 6;break;
    //         case "WINDOWS" : a_sorting_flag = 5;break;
    //       }
    //       switch(b.serviceType){
    //         case "SERVER" : b_sorting_flag = 10;break;
    //         case "BLOCK STORAGE" : b_sorting_flag = 9;break;
    //         case "공인 IP" : b_sorting_flag = 8;break;
    //         case "LOADBALANCER" : b_sorting_flag = 7;break;
    //         case "NAS" : b_sorting_flag = 6;break;
    //         case "WINDOWS" : b_sorting_flag = 5;break;
    //       }
    //       if (a_sorting_flag < b_sorting_flag) {
    //         return 1;
    //       }
    //       if (a_sorting_flag > b_sorting_flag) {
    //         return -1;
    //       }
    //       return 0;
    //     });
    //   }
    //   this.invoiceDataObject.before_tax = this.invoiceDataObject.total_sum - this.invoiceDataObject.total_reduce;
    //   this.invoiceDataObject.tax = Math.floor(this.invoiceDataObject.before_tax*0.1)
    //   this.invoiceDataObject.real_price = this.invoiceDataObject.before_tax + this.invoiceDataObject.tax;
      
    // });

    // this.spiner.hide();
  }

  getUsedCredit(totalInvoiceMonth){
    let searchQueries = `total_invoice_month=${totalInvoiceMonth}&registration_number=${this.userInfoList.registrationNumber}&registration_number_sub=${this.userInfoList.registrationNumberSub}`;
    this.spiner.show();
    this.webapi.getCreditHistory(searchQueries).subscribe((data) => {            
      if (data['total'] > 0){
        this.usedCredit = data['result'][0].credit;
      }
    });
    this.spiner.hide();
  }

  changeYear(e){
    this.nowYear = e;
    this.usedCredit = 0;
    // this.getTotalInvoice(this.companyId);
    this.getTotalInvoice();
  }

  changeMonth(e){
    this.nowMonth = e;
    this.usedCredit = 0;
    // this.getTotalInvoice(this.companyId);
    this.getTotalInvoice();
  }

  downloadToPdf(){
    const selectDate = `${this.nowYear}-${this.nowMonth}`;
    // this.spiner.show();
    this.webapi.createServiceHistoryPdf({invoiceDataObject:this.invoiceDataObject, selectDate:selectDate}).subscribe((data) => {
      // this.spiner.hide();
      this.fn_downloadPdf();
      
    });
    // this.webapi.createServiceHistoryPdf({invoiceDataObject:this.invoiceDataObject, selectDate:selectDate}).subscribe(
    //   response  => this.downLoadFile(response, "application/pdf")
    // );
  }
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }
  }
  fn_downloadPdf(){
    location.href = "/webapi/invoices/servicehistory/pdf"
  }
}
