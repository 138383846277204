<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>배치 서비스</h2>
        <div class="lineMap">Dashboard / <span>배치</span></div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>배치 목록</h3>
                        <div class="btnArea">
                        <div class="input-group fl">
                            <div class="input-group-btn pd_r10">
                            <button class="btn mid ciblue" (click)="openBatchCreateDialog()">등록</button>
                            </div>
                            <!-- 접기 버튼 -->
                            <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                        </div>
                        </div>
                    </div>
                    <div class="contArea" style="display: block;">
                        <div class="boardBox">
                                <table class="tbl_col_tp1 pdTp2">
                                        <colgroup>
                                            <col style="width: 5%;" />
                                            <col style="width: 12.8%" />
                                        </colgroup>
                                        <thead>
                                            <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                            </th>
                                            <th scope="col">일간</th>
                                            <th scope="col">시간</th>
                                            <th scope="col">분</th>
                                            <th scope="col">이메일</th>
                                            <th scope="col">삭제</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let b of batchList">
                                                <td></td>
                                                <td >
                                                    매일
                                                </td>
                                                <td >
                                                    {{ b.hour }}
                                                </td>
                                                <td >
                                                    {{ b.min }}
                                                </td>
                                                <td>
                                                    {{ b.user_email }}
                                                </td>
                                                <td>
                                                    <button type="button" class="btn mid ciblue" (click)="openDeleteModal(b.user_email, b.hour, b.min)">삭제</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                        </div>
                        <!-- <div class="btn_wrap tp1"></div> -->
                    </div>
                </div>
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->
</div>
<!-- //Contents -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#31a3cb" type="square-jelly-box" [fullScreen]="false">
    <!-- <p style="color: white;font-weight: bold;"></p> -->
</ngx-spinner>