import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-admin-board-faq',
  templateUrl: './admin-board-faq.component.html'
})
export class AdminBoardFaqComponent implements OnInit {
  constructor(
    private webapi: WebApiService,
    private spiner: NgxSpinnerService,
    private common: AdminNavComponent
  ) { }

  userInfoList: any = [];

  faqList: any = [];
  selected_faq: any = {};

  modal_comp_category: String = '요금';
  modal_comp_title: String = '';
  modal_comp_contents: String = '';

  searchCate: String = '';
  searchTitle: String = '';

  //페이징변수
  curPage: any;
  totalCount: any;

  faqForm = new FormGroup({
    boardId: new FormControl('2', []),
    userId: new FormControl('', []),
    category: new FormControl('요금', []),
    enabled: new FormControl(true, []),
    commentEnabled: new FormControl(true, []),
    title: new FormControl('', [Validators.required]),
    contents: new FormControl('', [Validators.required]),
  });
  get faqCheckForm() {
    return this.faqForm.controls;
  }

  ngOnInit(): void {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    this.getFaqList(0);
  }

  getFaqList(page) {
    this.curPage = page;
    var searchQuerys = '&o=created_date.DESC&l=10&s=' + this.curPage * 10;
    if (this.searchTitle != '') {
      searchQuerys +=
        '&title=' + decodeURIComponent(this.searchTitle + '') + '&lk=title';
    }
    this.spiner.show();
    this.webapi.getFaqList(searchQuerys).subscribe(
      (response) => {
        const data = response.body;
        this.faqList = data['result'];
        this.totalCount = data['total'];
        this.common.getThemeFont();
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  getFaqDetail(faq: any) {
    this.selected_faq = faq;

    this.modal_comp_category = this.selected_faq.category;
    this.modal_comp_title = this.selected_faq.title;
    this.modal_comp_contents = this.selected_faq.contents;

    fn_modalOpen('faq_detail');
  }

  onClickList(faq) {

    this.selected_faq = faq;
  }

  // 검색
  fn_faqSearch(title) {
    this.searchTitle = title;
    this.getFaqList(0);
  }

  onSearchTitleChange(deviceValue) {
    this.searchTitle = deviceValue;
  }

  onSubmit(): void {
    this.faqForm.value.userId = this.userInfoList.userId;
    this.spiner.show();
    this.webapi.createArticles(this.faqForm.value).subscribe((data: {}) => {
      alert('저장되었습니다.');
      (<HTMLInputElement>document.getElementById('closeModalBtn')).click();

      this.submitFormReset();
      //재검색
      this.getFaqList(0);
    });
    this.spiner.hide();
  }

  submitFormReset() {
    this.faqForm.setValue({
      boardId: '2',
      userId: this.userInfoList.userId,
      enabled: true,
      commentEnabled: true,
      category: '요금',
      title: '',
      contents: '',
    });
  }

  onCategoryChange(deviceValue) {
    this.modal_comp_category = deviceValue;
  }
  onTitleChange(deviceValue) {
    this.modal_comp_title = deviceValue;
  }
  onContentsChange(deviceValue) {
    this.modal_comp_contents = deviceValue;
  }

  deleteFaq() {
    this.spiner.show();
    this.webapi.deleteArticles(this.selected_faq).subscribe(
      (response) => {
        alert('삭제되었습니다.');
        this.getFaqList(0);
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  updateFaq() {
    this.selected_faq.category = this.modal_comp_category;
    this.selected_faq.title = this.modal_comp_title;
    this.selected_faq.contents = this.modal_comp_contents;


    if (this.modal_comp_title.trim() == '') {
      alert('제목을 입력해 주십시요.');
    } else if (this.modal_comp_contents.trim() == '') {
      alert('내용을 입력해 주십시요.');
    } else {
      this.spiner.show();
      this.webapi.updateArticles(this.selected_faq).subscribe((data) => {
        alert('수정되었습니다.');
        (<HTMLInputElement>document.getElementById('closeModalBtn2')).click();
        this.getFaqList(0);
      });
      this.spiner.hide();
    }
  }

  //페이징함수
  getMovePage(e) {
    this.getFaqList(e.page);
  }
}
