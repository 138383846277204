
<!-- Modal : 공인IP 연결해제 모달 -->
<div id="ip_disconnect" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>공인IP 연결해제</h2>
        </div>
        <!-- //modal_header -->
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width: 20%;" />
                        <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">대상 IP</th>
                            <td>
                                <span *ngIf="selected_loadbalancer.is_floating_ip">
                                    {{ selected_loadbalancer.floating_ip.ip }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp1">
                <a (click)="disassociateFloatingIp(selected_loadbalancer.floating_ip.id)" class="btn big ciblue mg_r4">해제</a>
                <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    