import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-commercial-dbms',
  templateUrl: './admin-commercial-dbms.component.html',
})
export class AdminCommercialDbmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  deleteService(){
      
  }
}
