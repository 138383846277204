<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Network</li>
      <li class="active">Subnet</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let subnet of subnetLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons">settings_overscan</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{subnet.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
            <tbody>
            <tr>
              <th>이름</th>
              <td>{{subnet.name}}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td>{{subnet.cidr}}</td>
            </tr>
            <tr>
              <th>게이트웨이</th>
              <td>{{subnet.gateway_ip}}</td>
            </tr>
            <tr>
              <th>소속</th>
              <td>
                <ng-template [ngIf]="subnet.network">
                  {{subnet.network.name}} ({{subnet.network.cidr}})
              </ng-template>
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td *ngIf="subnet.network_type == 'ext';else elseExt">
                  외부 네트워크
              </td>
              <ng-template #elseExt>
                <td>내부 네트워크</td>
              </ng-template>
            </tr>
            </tbody>
          </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>