<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>Flavor 목록</h2>
        <div class="lineMap">
            Admin  /  <span>Flavor</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>Flavor 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid dBlue"><i class="ico ico_search_white"></i>검색</button>
                                </div>
                                <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                            </div>
                        </div>									
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox" style="overflow:auto;">
                            <table class="tbl_col_tp1 dropmenu">
                                <colgroup>
                                    <col style="width:8%;" >
                                    <col style="width:14%;">
                                    <col style="width:9%;" span="3">
                                    <col style="width:11%;">
                                    <col style="width:8%;" span="4">
                                </colgroup>
                                <thead>
                                    <th scope="col"><input type="checkbox" name="" id=""></th>
                                    <th scope="col">이름</th>
                                    <th scope="col">vcpus</th>
                                    <th scope="col">RAM</th>
                                    <th scope="col">root 디스크</th>
                                    <th scope="col">ephemeral 디스크</th>
                                    <th scope="col">swap 디스크</th>
                                    <th scope="col">rx/tx요인</th>
                                    <th scope="col">id</th>
                                    <th scope="col">공용</th>
                                    <!-- <th scope="col">메타데이터</th> -->
                                </thead>                                
                                <tbody>																										
                                    <tr *ngFor="let flavor of flavorLists" (contextmenu)="onClickList(flavor)">
                                        <td><input type="checkbox" name="" id=""></td>
                                        <td>{{flavor.name}}</td>
                                        <td>{{flavor.vcpus}}</td>
                                        <td>{{flavor.ram}} MB</td>
                                        <td>{{flavor.disk}} GB</td>
                                        <td >{{flavor['OS-FLV-EXT-DATA:ephemeral']}}GB</td>
                                        <td *ngIf="flavor.swap ; else swapNull">{{flavor.swap}}GB</td>
                                        <ng-template #swapNull><td>0 GB</td></ng-template>
                                        <td>{{flavor.rxtx_factor}}</td>
                                        <td>{{flavor.id}}</td>
                                        <td>{{flavor['os-flavor-access:is_public']}}</td>
                                        <!-- <td>아니오</td> -->
                                    </tr>                                    
                                </tbody>
                            </table>
                        </div>
                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->


<!-- dropdown menu -->
<div class="dropdown">
    <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
    <div id="myDropdown" class="dropdown-content">
        <a routerLink="/admin/flavor/create">flavor 생성</a>
        <a onclick="fn_modalMsg('flavor을 삭제하시겠습니까?');">flavor 삭제</a>
        <!-- <a onclick="fn_modalOpen('modal_metadata')">flavor 메타데이터 편집</a> -->
    </div>
</div>


<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>삭제확인</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="msg"></div>
        <div class="btnArea">
            <a href="#close-modal" rel="modal:close" (click)="deleteFlavor()" class="btn big orange mg_r4">삭제</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->

<!-- Modal : flavor 메타데이터 업데이트 모달 -->
<div id="modal_metadata" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>flavor 메타데이터 업데이트</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width: 80%;"/>
                    <col style="width: 20%;"/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>기존 메타데이터</th>
                        <th>
                            <select name="">
                                <option value="">-필터-</option>
                            </select>
                        </th>
                    </tr>
                    <tr>
                        <td><input type="text" name="" value="code-1234" readonly></td>
                        <td><button class="btn sml dGray">삭제</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width: 80%;"/>
                    <col style="width: 20%;"/>
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">사용 가능한 메타데이터</th>
                        <th>
                            <select name="">
                                <option value="">-필터-</option>
                            </select>
                        </th>
                    </tr>
                    <tr>
                        <td><input type="text" name="" value="" placeholder="사용자지정"></td>
                        <td><button class="btn sml dGray">추가</button></td>
                    </tr>
                    <tr>
                        <td><input type="text" name="" value="code-1234" readonly></td>                        
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">확인</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->
