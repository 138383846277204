import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { ConsoleNavComponent } from '../../console/console-nav/console-nav.component';

@Component({
  selector: 'app-mobile-console-ip-list',
  templateUrl: './mobile-console-ip-list.component.html',
  styleUrls: ['./mobile-console-ip-list.component.css'],
  providers: [ConsoleNavComponent]
})
export class MobileConsoleIpListComponent implements OnInit {
  portfowarding_ip: any = {};
  ipLists: any = [];
  serverLists: any = [];
  loading_flag = 11;
  _blank = true;
  
  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private comp: ConsoleNavComponent,
  ) { }

  ngOnInit(): void {
    this.getIpList();
    
  }

  getIpList() {
    this.spinner.show();
    this.loading_flag =11;
    this.osapi
      .getFloatingipList()
      .subscribe((data: { items:any }) => {
        this.ipLists=[];
        data.items.forEach((fip) => {
          if (fip.is_port_forwarding) {
            this.portfowarding_ip = fip;
          } else {
            this.ipLists.push(fip);
          }
        });
        this.loading_flag-=10;
        this.getServerList();
        this.getLoadbalancerList();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }


  async getServerList() {
    this.spinner.show();
    this.serverLists = await this.comp.getServerList();
    this.ipLists.forEach((fip) => {
      if (fip.port_details) {
        this.serverLists.forEach((server) => {
          if (fip.instance_id == server.server_id) {
            fip.server = server;
          }
        });
      }
    });
    this.loading_flag-=1;
    this._blank = ((this.ipLists != 0) ? true : false);
    this.spinner.hide();
  }

  currentTimer;
  loadbalancerList;

  getLoadbalancerList(){
    this.spinner.show();
    this.loadbalancerList = [];
    this.osapi.getLoadbalancerList().subscribe(
      (data:any)=>{
        this.loadbalancerList = data.items;
        this.loadbalancerList = this.loadbalancerList.filter(lb => lb.provisioning_status != "PENDING_DELETE");
        this.getLoadbalancer();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      }
    )
  }

  async getLoadbalancer() {

    if (this.loadbalancerList != undefined) {
      for (let lb of this.loadbalancerList) {
        let data: any = await this.osapi.getLoadbalancerFull(lb.id).toPromise();
        if (data.floating_ip.hasOwnProperty('ip')) {
          lb.floating_ip = data.floating_ip;

          for (let fip of this.ipLists) {
            if (fip.port_details) {
              if (fip.port_details.hasOwnProperty('device_id')) {
                if (fip.port_details.device_id == ("lb-" + lb.id)) {
                  lb.server_name = "로드밸런서 " + lb.name;
                  lb.fixed_ip = lb.vip_address;
                  fip.server = lb;

                  /** IP 추출하면 리스트에서 삭제 */
                  this.loadbalancerList = this.loadbalancerList.filter(item => item.id != lb.id);
                }
              }
            }
          }
        }
      }
      
      /** IP 추출이 완료되면 타이머 멈춤, 공인IP할당안된 LB가있을 경우 루프 돌 수 있음 */
      if(this.loadbalancerList.length == 0){
        clearTimeout(this.currentTimer);
        return;
      }
      this.currentTimer = setTimeout(() => { this.getLoadbalancer() }, 1000);
    }
  }
}
