
<!-- Modal : 이미지 생성 모달 -->
<div id="image_snapshot_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        서버 이미지 생성
        <p class="sub-txt">서버의 현재 상태를 이미지로 저장합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">이미지 이름</th>
              <td>
                <input type="text" name="" id="" maxlength="60" [(ngModel)]="new_image_data.name"
                  placeholder="생성할 이미지의 이름을 작성해주세요.(60자 이내)" />
              </td>
            </tr>
            <tr>
              <th scope="row">설명</th>
              <td>
                <input type="text" name="" id="" [(ngModel)]="new_image_data.description" maxlength="255"
                  placeholder="내용을 입력해주세요" />
              </td>
            </tr>
            <tr>
                <th scope="row">대상 서버</th>
                <td>
                    <select [(ngModel)]="new_image_data.server_id" name="" required>
                      <option value="">-선택-</option>
                      <ng-template [ngIf]="serverList">
                          <option *ngFor="let server of serverList" [value]="server.server_id">{{ server.server_name }}</option>
                      </ng-template>
                    </select>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
          <a (click)="createServerImage()" class="btn big ciblue mg_r4">확인</a>
          <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->