import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../../../osapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

@Component({
  selector: 'app-admin-image-list',
  templateUrl: './admin-image-list.component.html',
})
export class AdminImageListComponent implements OnInit {
  imageLists: any=[];
  selected_image:any={};
  constructor(private osapi:OsapiService,private common:AdminNavComponent) { }

  ngOnInit(): void {
    this.getImageList();
  }

  onClickList(image){

    this.selected_image = image;
  }
  getImageList(){
    this.osapi.getImageList().subscribe( 
      (res) =>{
        const data = res.body;

        this.imageLists= data
        this.common.getThemeFont();
      }
    )
  }
  deleteImage(){    

    this.osapi.deleteImage(this.selected_image.image_id).subscribe(
      (data:{}) => {
      location.reload();
    });    
  }

  onListCheck(){
    let chkedlist: any = document.getElementsByName('sel_chkbox');
    this.valiAllChk(chkedlist);
  }
  
  // 전체선택
  allCheck(e) {
    const checked = e.target.checked;
    let chkedlist: any = document.getElementsByName('sel_chkbox');

    chkedlist.forEach(item => item.checked = checked);
  }

  // 전체선택 여부
  valiAllChk(chkedlist) {
    let result: boolean = true;
    const all_chkbox = document.getElementById('all_chkbox') as HTMLInputElement;

    chkedlist.forEach(item => {
      if (item.checked === false) {
        result = false;
      }
    });

    if (!result) {
      all_chkbox.checked = false;
    } else {
      all_chkbox.checked = true;
    }
  }

}
