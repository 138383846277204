<!-- Container -->
<div id="Container" class="mypage">
        <div class="visual tp1">
            <div class="cont_inner">
                <h2>서비스 이용내역</h2>
            </div>
        </div>
        <div style="display:none">
            <div #pdfContainer>
                TEST
            </div>
        </div>
        <div>
            <!-- section -->
            <section class="sec sec_1">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <h3>서비스 이용내역</h3>
                    </div>
                    <!-- //sec_header -->
                    
                    <!-- sec_content -->
                    <div class="sec_content">
                        <div class="serviceInfo">
                            <div class="titArea">
                                <div class="termArea">
                                    <ul class="lsTp4">
                                        <li>
                                            <div class="tit">이용 월</div>
                                            <div class="cont">                                                
                                                <select name="nowYear" class="mg_r4" (change)="changeYear($event.target.value)">
                                                    <option *ngFor="let year of yearList" [value]="year" [selected]="year === nowYear">{{year}}</option>
                                                </select>
                                                <select name="nowMonth" (change)="changeMonth($event.target.value)">
                                                    <option *ngFor="let month of monthList" [value]="month" [selected]="month === nowMonth">{{month}}</option>
                                                </select>
                                            </div>
                                        </li>                                        
                                    </ul>
                                </div>
                                
                                <div class="priceArea">
                                    <ul class="lsTp4">
                                        <li>
                                            <p class="tit">{{ nowMonth }}월 청구금액</p>
                                            <p class="cont price">{{ invoiceDataObject.real_price | currency:'KRW':'' }} 원</p>
                                        </li>
                                        <!-- <li>
                                            <p class="tit">결제수단</p>
                                            <p class="cont" *ngIf="activePaymentType">
                                                <label>{{ activePaymentType.bank }} {{ activePaymentType.cardNumber }}</label>
                                            </p>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                            <div class="btnArea">
                                <a class="btn mid bor_gray mg_r4" (click)="downloadToPdf()">PDF로 저장</a>
                                <!-- <a href="/web/mypage/payment" class="btn mid bor_gray mg_r4">결제수단 변경</a> -->
                                <!--a href="#this" class="btn mid bor_gray mg_r4">이용요금 안내주소 변경</a-->
                                <!-- <a href="#this" class="btn mid bor_gray">이용확인서 발급</a> -->
                            </div>
                            <div class="contArea">
                                <div class="boardBox">
                                    <!-- <table class="tbl_col_tp2 colorTpA">
                                        <colgroup>
                                            <col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">프로젝트</th>
                                                <th scope="col">종류</th>
                                                <th scope="col">서비스</th>
                                                <th scope="col">당월 사용 금액</th>
                                                <th scope="col">서비스별 할인 금액</th>
                                                <th scope="col">금액</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="totalCount > 0">
                                            <tr *ngFor="let invoice of totalInvoiceInfo.Invoices">
                                                <td rowspan="1">{{ invoice.projectId ? invoice.projectId : '-'}}</td>
                                                <td rowspan="1">{{ invoice.serviceType ? invoice.serviceType : '-'}}</td>
                                                <td rowspan="1">{{ invoice.serviceName ? invoice.serviceName : '-'}}</td>
                                                <td>{{ invoice.rawCost | currency:'KRW' }}</td>
                                                <td>{{ (invoice.rawCost - invoice.realCost)| currency:'KRW' }}</td>
                                                <td>{{ invoice.realCost | currency:'KRW' }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="totalCount < 1">
                                            <tr>
                                                <td colspan="5">조회된 내역이 없습니다</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="totalCount > 0">
                                            <tr>
                                                <td class="bgTpA">예상 합계</td>
                                                <td colspan="4">{{ totalRealCost | currency:'KRW' }}</td>
                                            </tr>
                                            <tr>
                                                <td class="bgTpA">크레딧</td>
                                                <td colspan="4">{{ usedCredit | number }}</td>
                                            </tr>
                                            <tr>
                                                <td class="bgTpA">합계</td>
                                                <td colspan="4">{{ totalRealCost - usedCredit | currency:'KRW' }}</td>
                                            </tr>
                                            <tr>
                                                <td class="bgTpA">청구 금액 (VAT 포함)</td>
                                                <td colspan="4">{{ (totalRealCost - usedCredit) * 1.1 | currency:'KRW' }}</td>
                                            </tr>
                                        </tbody>
                                    </table> -->
                                </div>
                                <table class="tbl_col_tp2 colorTpA">
                                        <colgroup>
                                            <col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">프로젝트</th>
                                                <th scope="col">종류</th>
                                                <th scope="col">서비스</th>
                                                <th scope="col">당월 사용 금액</th>
                                                <th scope="col">서비스별 할인 금액</th>
                                                <th scope="col">금액</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-template ngFor let-project [ngForOf]="invoiceDataObject.projects" >
                                                <ng-template [ngIf]="project.invoice_list.length >0">
                                                    <tr *ngFor="let invoice of project.invoice_list; let i = index">
                                                        <td class="bgTpA" *ngIf="i==0" [attr.rowspan]="project.invoice_list.length+3">
                                                            {{ project.project_name ? project.project_name : '-'}}
                                                        </td>
                                                        <td rowspan="1">{{ invoice.serviceType ? invoice.serviceType : '-'}}</td>
                                                        <td rowspan="1">{{ invoice.serviceName ? invoice.serviceName : '-'}}</td>
                                                        <td>{{ invoice.rawCost | currency:'KRW':'' }}</td>
                                                        <td>{{ (invoice.rawCost - invoice.realCost)| currency:'KRW':'' }}</td>
                                                        <td>{{ invoice.realCost | currency:'KRW':'' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="bgTpA">프로젝트 사용 금액</td>
                                                        <td colspan="3">{{ project.project_raw | currency:'KRW':'' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="bgTpA">프로젝트 할인 금액 (크레딧 포함)</td>
                                                        <td colspan="3">{{ project.project_reduce | currency:'KRW':'' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="bgTpA">프로젝트 합계</td>
                                                        <td colspan="3">{{ project.project_sum | currency:'KRW':'' }}</td>
                                                    </tr>

                                                </ng-template>

                                            </ng-template>
                                            <tr>
                                                <td colspan="3" class="bgTpA">월 전체 합계</td>
                                                <td colspan="3">{{ invoiceDataObject.total_sum | currency:'KRW':'' }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="bgTpA">총 할인 금액</td>
                                                <td colspan="3">{{ invoiceDataObject.total_reduce | currency:'KRW':'' }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="bgTpA">공급가액</td>
                                                <td colspan="3">{{ invoiceDataObject.before_tax | currency:'KRW':'' }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="bgTpA">부가세(VAT)</td>
                                                <td colspan="3">{{ invoiceDataObject.tax | currency:'KRW':'' }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="bgTpA">실 청구 금액</td>
                                                <td colspan="3">{{ invoiceDataObject.real_price | currency:'KRW':'' }}</td>
                                            </tr>
                                        </tbody>
                                </table>
                                <ul class="lsTp5 mg_t20">
                                    <li>월정액 서비스를 1개월 미만으로 사용했을때는 사용 날짜에 따른 일할 계산을 원칙으로 합니다.</li>
                                    <li>사업자 및 공공기관에는 신용카드  현금영수증 대신 세금계산서를 제공 합니다.</li>
                                    <!-- <li>이용금액은 리전별 이용량이 합산되어 계정단위로 청구됩니다.</li> -->
                                    <li>서비스 이용 요금 정산은 한국 표준시간대(UTC+9)를 기준으로 산정됩니다.</li>
                                    <!-- <li>자세한 요금 납부 기간은 자주하는 질문(FAQ)을 참고하시기 바랍니다.</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- //sec_content -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->
        </div>
    </div>
    <!-- //Container -->
    <hr>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>