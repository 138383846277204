import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { OsapiService } from '../../osapi.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-server-status',
  templateUrl: './console-server-status.component.html',
})
export class ConsoleServerStatusComponent implements OnInit {

  @Input() message: string;
  @Output() modalMsg = new EventEmitter();

  serverList:any = [];
  imageList:any =[];
  keypairList:any=[];

  serverActive:number=0;
  imageActive:number=0;

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,
    private osapi:OsapiService,
    @Inject(ConsoleNavComponent) private parent: ConsoleNavComponent
  ) {
    
  }

  ngOnInit(): void {
    this.getServerList();
    this.getImageList();
    this.getKeyPairList();
  }

  getServerList(){
    if(!this.parent.isLoadedServer){
      setTimeout(()=>{
        this.getServerList();
      },200)
    }else{
      this.serverList = this.parent.serverList;
      for(let server of this.serverList){
        if(server.status === "ACTIVE"){
          this.serverActive++;
        }
      }
    }
  }

  getImageList(){
    this.osapi.getCurrentProjectImageList().subscribe(
      (data:any)=>{
        this.imageList= data.body;
        this.imageList.forEach(image=>{
          if(image.status === "ACTIVE"){
            this.imageActive++;
          }
        })
      }
    )
  }

  getKeyPairList(){
    this.osapi.getKeyPairList().subscribe(
      (data:any)=>{
        this.keypairList = data.items;
      }
    )
  }
}
