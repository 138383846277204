import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service-price',
  templateUrl: './service-price.component.html',
})
export class ServicePriceComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {     
  }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    const param = this.route.snapshot.paramMap.get('param');    
    if (param){
        document.querySelector(`.${param}`).scrollIntoView(true);
    }
  }  
}
