<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2 *ngIf="security_group !=null">'{{security_group.name}}'의 규칙관리</h2>
        <h2 *ngIf="security_group ==null">규칙관리</h2>
        <div class="lineMap">
            Network  /  Security Group  /  <span>Rules</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm" class="frmBox1">
        <!-- section -->
            <section class="sec col12">
                <div class="sec_content">       
                    <!-- lyBox -->
                    <div class="lyBox">
                        <div class="title_wrap">
                            <div class="contArea">
                                <div class="tit">
                                    <app-console-projects></app-console-projects>
                                </div>
                                <div class="cont">
                                    <app-console-network-status></app-console-network-status>
                                </div>
                                <div class="contSub">
                                    <tr>
                                        <!-- <th scope="row">허용규칙</th> -->
                                        <td colspan="2">
                                            <a class="btn mid blue" (click)="openNewRuleModal()">규칙 추가</a>   
                                        </td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                    </div>
            <!--    <div class="lyBox">
                        <div class="contArea" style="display: block;">
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr> -->
                                        <!-- <th scope="row">허용규칙</th> -->
                                        <!--<td colspan="2">
                                            <a class="btn mid blue" (click)="openNewRuleModal()">규칙 추가</a>   
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div> -->
                    <!-- //lyBox -->         
                    <!-- lyBox -->
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>규칙관리 목록</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                    <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                    </div>
                                    <a class="btn_more btn mid_fix_one blue on"><i class="ico ico_moreTp1"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display:block"> 
                            <div class="boardBox">
                                <table class="tbl_col_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;">
                                        <col style="width: 12%;">
                                        <col style="width: 12%;">
                                        <col style="width: 13%;">
                                        <col style="width: 13%;">
                                        <col style="width: 20%;">
                                        <col style="width: 25%;">
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                        </th>
                                        <th scope="col">송/수신</th>
                                        <th scope="col">Ether 타입</th>
                                        <th scope="col">IP 프로토콜</th>
                                        <th scope="col">포트 범위</th>
                                        <th scope="col">CIDR</th>
                                        <th scope="col">설명</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of securityGroupRuleList" (contextmenu)="onClickList(item)">
                                            <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)" [attr.data-id]="item.id"></td>
                                            <td *ngIf="item.direction=='ingress'">수신</td>
                                            <td *ngIf="item.direction=='egress'">송신</td>
                                            <td>{{item.ethertype}}</td>
                                            <td *ngIf="item.ip_protocol != null; else protocolNull">{{item.ip_protocol}}</td>
                                            <ng-template #protocolNull>
                                                <td>전체</td>
                                            </ng-template> 
                                            <td *ngIf="item.from_port == null ||item.to_port == null; else portNotNull">전체</td>
                                            <ng-template #portNotNull>
                                                <td *ngIf="item.to_port == item.from_port">
                                                    {{item.to_port}}
                                                    <ng-template [ngIf]="item.to_port=='80'">(HTTP)</ng-template>
                                                    <ng-template [ngIf]="item.to_port=='443'">(HTTPS)</ng-template>
                                                    <ng-template [ngIf]="item.to_port=='22'">(SSH)</ng-template>
                                                </td>
                                                <td *ngIf="item.to_port != item.from_port">{{item.from_port}} ~ {{item.to_port}}</td>
                                            </ng-template>    
                                            <td>
                                                {{item.ip_range.cidr}}
                                            </td>
                                            <td>
                                                <ng-template [ngIf]="item.description==null || item.description=='' ">
                                                    없음
                                                </ng-template>
                                                <ng-template [ngIf]="item.description!=null && item.description!='' ">
                                                    {{item.description}}
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1"></div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
        <!-- //section -->
        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropmenu -->
<div class="dropdown">
    <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
    <div id="myDropdown" class="dropdown-content">
        <!-- <a onclick="fn_modalOpen('rule_create')">규칙 추가</a> -->
        <a (click)="openModifiedRuleModal()">규칙 수정</a>
        <a (click)="openDeleteRuleModal()">규칙 삭제</a>
    </div>
</div>




<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>규칙 삭제</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="msg"></div>
        <div class="btnArea">
            <a href="#close-modal" rel="modal:close" (click)="deleteRule()" class="btn big orange mg_r4">삭제</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->