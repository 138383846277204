import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { OsapiService } from '../../osapi.service';

@Component({
  selector: 'app-console-project-status',
  templateUrl: './console-project-status.component.html',
})
export class ConsoleProjectStatusComponent implements OnInit {

  @Input() message: string;
  @Output() modalMsg = new EventEmitter();

  projectList:any=[];
  subuserList:any=[];
  
  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,
    private osapi: OsapiService
  ) {
    
  }

  ngOnInit(): void {
    this.getResource();
    this.getSubUser();
  }

  getResource(){
    this.osapi.getResourceAll().subscribe(
      (data:any)=>{
        data.forEach(resource => {
          
          if(resource.resourceType == "project"){
            this.projectList.push(resource);
          }
        });
        //this.projectList = data;
      }
    )
  }

  getSubUser(){
    this.osapi.getSubUsers().subscribe(
      (data:any)=>{
        this.subuserList = data.result;
      }
    )
  }
}