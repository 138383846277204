import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { BatchapiService } from '../../batchapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import { DialogSetService } from '../dialog/dialog-set.service';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';
import { SaveSectionEventHandler } from '@syncfusion/ej2-pdf-export';
import { saveAs } from 'file-saver';

@Component({
  selector:  'app-console-batch',
  templateUrl: './console-batch.component.html',
  styleUrls: ['./console-batch.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsoleBatchComponent implements OnInit {
  bMin: string = '00';
  bHour: string = '00';
  selecteHourList: Array<string> = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
  selecteMinList: Array<string> = ['00','05','10','15','20','25','30','35','40','45','50','55'];
  userEmail: string = '';
  batchList: Array<Object> = [];

  constructor(
    private batchapi: BatchapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private dialogs:DialogSetService,
    private router: Router,
    public global: GlobalService,
    @Inject(ConsoleNavComponent) private parent: ConsoleNavComponent,
  ) {
    // console.log(this.parent.userInfo);
    this.userEmail = this.parent.userInfo.userId;
    // console.log(this.userEmail);
  }

  ngOnInit() {
    this.getBatchList();
  }

  onClickRegiBatch(event) {
    let param = {
      hour: this.bHour,
      min: this.bMin,
      userEmail: this.userEmail
    }
    this.batchapi.regiBatch(param).subscribe((result:any) => {
      alert(result.result);
    });
  }

  getBatchList() {
    this.batchapi.getBatchList({userEmail: this.userEmail}).subscribe((result:any) => {
      // console.log(result);
      this.batchList = result.result;
    });
  }

  openBatchCreateDialog(){
    this.dialogs.openBatchCreateDialog({userEmail: this.userEmail});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result.code == "success"){
        alert(result.data);
        this.getBatchList();
      }
    });

  }

  openDeleteModal(userEmail, hour, min){
    this.dialogs.openDefaultDeleteDialog({
      title:"배치 삭제",
      content:"선택하신 배치를 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteBatch(userEmail, hour, min);
        }
      });
  }

  deleteBatch(userEmail, hour, min) {
    this.batchapi.deleteBatch({userEmail, hour, min}).subscribe(
      (data: any) => {
        alert(data.result);
        //this.ngOnInit();
        this.getBatchList();
        // Resource 삭제
      },
      (error) => {
        
      }
    )
  }

}
