<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>회원관리</h2>
		<div class="lineMap">
			회원탈퇴 / <span>회원 탈퇴 목록</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>회원 탈퇴 목록</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요"
									spellcheck="false" autocomplete="off" />
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid orange" (click)="getWithdrawalSearch()">검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block">

						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
								<colgroup>
									<col style="width:5%">
									<col style="width:15%">
									<col style="width:20%">
									<col style="width:10%">
									<col style="width:12%">
									<col style="width:15%">
									<col style="width:12%">
									<col style="width:20%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">ID</th>
										<th scope="col">회사</th>
										<th scope="col">이름</th>
										<th scope="col">전화번호</th>
										<th scope="col">요청일시</th>
										<th scope="col">상태</th>
										<th scope="col">승인 / 취소</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let withdrawal of withdrawals; index as i">
										<td>{{10 * curPage + i + 1}}</td>
										<td style="text-align: left; cursor: pointer;" (click)="fn_detailCall(withdrawal)">
											{{withdrawal.User.userId}}
										</td>
										<td>
											{{withdrawal.Company.companyName == 'empty company' ? '개인' : withdrawal.Company.companyName}}
										</td>
										<td>
											{{withdrawal.User.name}}
										</td>
										<td>
											{{withdrawal.User.mobile}}
										</td>
										<td>
											{{withdrawal.createdDate.split("T")[0]}}
										</td>
										<td>
											{{withdrawal.Company.status == '3' ? "탈퇴 신청" : "탈퇴" }}
										</td>
										<td>
											<ng-template [ngIf]="withdrawal.Company.status == '3'">
												<button type="button" class="btn sml blue mg_r4" (click)="withdrawalOk(withdrawal)">
													승인
												</button>												
												<button type="button" class="btn sml red" (click)="withdrawalNo(withdrawal)">
													취소
												</button>
											</ng-template>
										</td>
									</tr>
								</tbody>
							</table>
							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)">
							</app-common-paging>
						</div>
					</div>
				</div>
				<!-- //lyBox -->

				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->
<!-- Modal : 탈퇴 사유 모달 -->
<div id="user_detail" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>회원 탈퇴 사유</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<fieldset class="userDetailForm">
			<div class="boardBox">
				<table class="tbl_row_tp1">
					<colgroup>
						<col style="width: 30%;" />
						<col style="width: 70%;" />
					</colgroup>
					<tbody>
						<tr>
							<td>
								<span>탈퇴 사유</span>
							</td>
							<td>
								<span>{{modal_withdrawal_reson}}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="btnArea tp1">
				<a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn">닫기</a>
			</div>
		</fieldset>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->