<div id="route_update" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        접근경로 편집
        <p class="sub-txt">API에 접근할 수 있는 경로를 정의합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>
          <tbody>
            <tr class="required">
              <th scope="row">이름</th>
              <td>
                <input type="text" maxlength="20" name="r_name" id="r_name" readonly [(ngModel)]="update_route_object.name" placeholder="20자 이내로 작성해주세요. 영문자와 숫자, '-', '_'만 입력 가능합니다." />
              </td>
            </tr>
            <tr class="required">
              <th scope="row">접근경로</th>
              <td>
                <input type="text" maxlength="255" name="r_path" id="r_path" [(ngModel)]="update_route_object.path" placeholder="/path" />
              </td>
            </tr>
            <tr class="required">
              <th scope="row">프로토콜</th>
              <td>
                <select name="r_protocol" id="r_protocol" class="size435" [(ngModel)]="update_route_object.protocol[0]">
                  <option value="http">HTTP</option>
                  <option value="https">HTTPS</option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">HTTP 메서드</th>
              <td>
                <select name="r_method" id="r_method" class="size435" [(ngModel)]="update_route_object.methods[0]">
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                </select>
              </td>
            </tr>
            <tr class="">
              <th scope="row">Request 데이터 크기 제한(Mb)</th>
              <td>
                <input type="number" name="r_size_limit" id="r_size_limit" [(ngModel)]="update_route_object.request_size_limit_size" placeholder="0으로 설정하면 제한없음" />
              </td>
            </tr>
            <tr class="">
              <th scope="row">호스트 이름 유지 옵션 사용</th>
              <td>
                <label for="route-preservehost-radio1" (change)="update_route_object.is_preserve_host=$event.target.checked" class="mg_r8"> <input type="radio" name="route-preservehost" id="route-preservehost-radio1" [attr.checked]="update_route_object.is_preserve_host?'':null" />사용</label>
                <label for="route-preservehost-radio2" (change)="update_route_object.is_preserve_host=!$event.target.checked"> <input type="radio" name="route-preservehost" id="route-preservehost-radio2" [attr.checked]="!update_route_object.is_preserve_host?'':null"/>사용 안함</label>
              </td>
            </tr>
            <tr class="">
              <th scope="row">CORS 활성화</th>
              <td>
                <label for="route-cors-radio1" (change)="update_route_object.is_cors=$event.target.checked" class="mg_r8"> <input type="radio" name="route-cors" id="route-cors-radio1" />활성화</label>
                <label for="route-cors-radio2" (change)="update_route_object.is_cors=!$event.target.checked"> <input type="radio" name="route-cors" id="route-cors-radio2" checked/>비활성화</label>
              </td>
            </tr>
            <tr class="" *ngIf="update_route_object.is_cors">
              <th scope="row">Access-Control-Allow-Method</th>
              <td>
                
                <input type="text" [(ngModel)]="update_route_object.cors_params_text.methods" placeholder="" />
              </td>
            </tr>
            <tr class="" *ngIf="update_route_object.is_cors">
              <th scope="row">Access-Control-Allow-Headers</th>
              <td>
                  <input type="text" [(ngModel)]="update_route_object.cors_params_text.headers" placeholder="" />
                
              </td>
            </tr>
            <tr class="" *ngIf="update_route_object.is_cors">
              <th scope="row">Access-Control-Allow-Origin</th>
              <td>
                  <input type="text" [(ngModel)]="update_route_object.cors_params_text.origins" placeholder="" />
                
              </td>
            </tr>
            <tr class="" *ngIf="update_route_object.is_cors">
              <th scope="row">Access-Control-Expose-Headers</th>
              <td>
                  <input type="text" [(ngModel)]="update_route_object.cors_params_text.exposed_headers" placeholder="" />
                
              </td>
            </tr>
            <tr class="" *ngIf="update_route_object.is_cors">
              <th scope="row">Access-Control-Max-Age</th>
              <td>
                  <input type="number" [(ngModel)]="update_route_object.cors_params_text.max_age" placeholder="" />
                
              </td>
            </tr>
            <tr class="" *ngIf="update_route_object.is_cors">
              <th scope="row">Access-Control-Allow-Credentials</th>
              <td>
                  <input type="text" [(ngModel)]="update_route_object.cors_params_text.credentials" placeholder="" />
                
              </td>
            </tr>
            <tr class="">
              <th scope="row">접근경로 전체공개<br>(API KEY 사용하지 않음)</th>
              <td>
                <label for="route-iskeyauth-radio1" (change)="update_route_object.is_keyauth=!$event.target.checked" class="mg_r8"> <input type="radio" name="route-iskeyauth" id="route-iskeyauth-radio1" [attr.checked]="!update_route_object.is_keyauth?'':null"/>전체공개</label>
                <label for="route-iskeyauth-radio2" (change)="update_route_object.is_keyauth=$event.target.checked"> <input type="radio" name="route-iskeyauth" id="route-iskeyauth-radio2"  [attr.checked]="update_route_object.is_keyauth?'':null"/>API KEY 사용</label>
              </td>
            </tr>
            <tr *ngIf="update_route_object.is_keyauth">
                <th scope="row" >사용자 목록</th>
                <td>
                  <table>
                    <colgroup>
                      <col style="width: 80%;" />
                      <col style="width: 20%;" />
                    </colgroup>
                    <tr>
                      <td>
                        <!-- 인증키 목록 -->
                        <select name="r_acl" id="r_acl" (change)="changeNewAcl($event.target.value)">
                          <option value="">---선택---</option>
                          <option *ngFor="let auth of auth_list" [value]="auth.kong_consumer_id">{{auth.name}}</option>
                        </select>
                      </td>
                      <td><a class="btn sml dGray" (click)="addAcl()">추가</a></td>
                    </tr>
                    <tr *ngFor="let auth of acl_auth_list">
                      <td>{{auth.name}}</td>
                      <td><a class="btn sml dGray" (click)="removeAcl(auth)">제외</a></td>
                    </tr>
                  </table>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="updateRoute()" class="btn big ciblue mg_r4">저장</a>
        <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->