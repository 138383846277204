import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { postcode } from 'src/assets/js/util.js';
import { Router } from '@angular/router';
import { WebApiService } from '../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from '@angular/compiler/src/util';


declare const $: any;
declare function checkRegistrationNumber(value);
declare function sendAuthNumCompany(flag);
declare function sendAuthNumUser(flag);
declare function sendAuthNumPhoneCompany(flag);
declare function sendAuthNumPhoneUser(flag);


@Component({
  selector: 'app-member-signup',
  templateUrl: './member-signup.component.html',
})

export class MemberSignupComponent implements OnInit {
  
  hide = true;
  // 가입 관련 변수
  registerType: string;
  submitted = false;
  //이메일 인증 관련 변수
  confirmCompanyCode: string;
  isuniqueidCompanyCheck = false;
  isuniquedomainCompanyCheck = false;
  confirmUserCode: string;
  isuniqueidUserCheck = false;
  //모바일 인증 관련 변수
  confirmPhoneCompanyCode: string;
  isuniquePhoneCompanyCheck = false;
  confirmPhoneUserCode: string;
  isuniquePhoneUserCheck = false;

  //파일관련 변수
  fileInfo: string;

  constructor(
    private spiner: NgxSpinnerService,
    private webapi: WebApiService,
    private router: Router
  ) { }

  // 기업용 폼 //
  profileCompanyForm = new FormGroup({
    userId: new FormControl('', [      
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(/[A-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.pattern(/[!@#$%^&*()_\[+=\]{}~\?:;`|]/),
      Validators.minLength(8),
    ]),
    confirmPassword: new FormControl('', [Validators.required]),
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/[가-힣]/),
    ]),
    mobile: new FormControl('', [
      Validators.pattern(/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/),
      Validators.minLength(12),
    ]),
    companyName: new FormControl('', [Validators.required]),
    registrationName: new FormControl('', [
      Validators.required,
      Validators.pattern(/[가-힣]/),
    ]),
    registrationNumber: new FormControl('', [
      Validators.required,
      // Validators.pattern('^[0-9]{3}-[0-9]{2}-[0-9]{5}$'),
      // Validators.minLength(4),
    ]),
    registrationNumberSub: new FormControl('0', [
      // Validators.pattern('^[0-9]*$'),
      // Validators.minLength(4),
    ]),
    telephone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/),
    ]),
    zipCode: new FormControl(''),
    address: new FormControl(''),
    addressDetail: new FormControl(''),
    file: new FormControl('', [Validators.required]),
    reson: new FormControl(''),
    companyDomainId: new FormControl('', [
      Validators.required,
      Validators.pattern(/[a-zA-Z0-9]/),
      Validators.minLength(3),
    ])
  });
  get companyForm() {
    return this.profileCompanyForm.controls;
  }

  // 개인용 폼 //
  profileUserForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/[가-힣]/),
    ]),
    userId: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$'
      ),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(/[A-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.pattern(/[!@#$%^&*()_\[+=\]{}~\?:;`|]/),
      Validators.minLength(8),
    ]),
    confirmPassword: new FormControl('', [Validators.required]),
    telephone: new FormControl('', [
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10),
    ]),
    mobile: new FormControl('', [
      Validators.minLength(10),
    ]),
    zipCode: new FormControl(''),
    address: new FormControl(''),
    addressDetail: new FormControl(''),
    reson: new FormControl(''),
  });
  get userForm() {
    return this.profileUserForm.controls;
  }

  ngOnInit(): void { }

  userSubmit(){ //개인고객 회원가입 로직
    // let formData = this.profileUserForm.value;

    // for(let key in formData){ //빈값체크 후 포커싱 로직

    //   if(formData[key] === '' || formData[key] === null || formData[key] === undefined) {
    //     $('input[formControlName='+key+']').focus();
    //     return;
    //   }
    // }
    this.submitted = true;
    this.webapi //아이디 체크로직 ?
      .isuniqueid(this.profileUserForm.controls.userId.value)
      .subscribe((response) => {
        if (response === false) {
          this.isuniqueidUserCheck = false;
          return;
        }
      });
    if (this.isuniqueidUserCheck !== true) { //이메일인증 체크로직
      alert('이메일 인증을 해주세요');
        $('#userUserId').focus();
        // $('#confirmUserCodeLi').hide();
        // $('#confirmUserCodeLi_check').hide();
        // $('#confirmUserCodeLi_resend').hide();
        $('#confirmUserCode').val('');
      return;
    }
    let pwdResult = this.profileUserForm.controls.password.value; //비밀번호
    let pwdCheck = this.profileUserForm.controls.confirmPassword.value; //비밀번호확인
    if (pwdResult !== pwdCheck) { //비밀번호 체크로직
      this.profileUserForm.controls.confirmPassword.setValue('');
      alert('비밀번호가 일치 하지 않습니다.');
      $('#userConfirmPassword').focus();
      return;
    }
    this.profileUserForm.controls.mobile.setValue(this.profileUserForm.controls.mobile.value.replace(/-/g,''))
    if(this.isuniquePhoneUserCheck !== true) { //휴대폰인증 체크로직
      alert('휴대폰 인증을 해주세요');
      $('#UserMobile').focus();
      // $('#confirmUserPhoneLi').hide();
      // $('#confirmUserPhoneLi_check').hide();
      // $('#confirmUserPhoneLi_resend').hide();
      $('#confirmUserPhoneCode').val('');
      return;
    }
    if (this.profileUserForm.invalid) {
      const invalid = [];
      const controls = this.profileUserForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      return;
    }
    this.spiner.show();
    this.webapi.signup(this.profileUserForm.value).subscribe((data: {}) => {
      alert('회원가입이 완료되었습니다.');
      this.confirmMail(this.profileUserForm.controls.userId.value);
      this.router.navigate(['member/login']);
    });
    this.spiner.hide();
  }

  async companySubmit(files: FileList){ //기업고객 회원가입
    this.submitted = true;
      this.webapi.isuniqueid(this.profileCompanyForm.controls.userId.value).subscribe(
        (response) => {
          if (response === false) {
            this.isuniqueidCompanyCheck = false;
            return;
          }
        }, (err) => {
        }
      );

      let chkCompanyDomain:any = await this.webapi.isuniquedomain(this.profileCompanyForm.controls.companyDomainId.value).toPromise();
      this.isuniquedomainCompanyCheck = chkCompanyDomain.check;

      if (this.isuniqueidCompanyCheck !== true) { //이메일인증 체크로직
        alert('이메일 인증을 해주세요');
        $('#companyUserId').focus();
        $('#confirmCompanyCodeLi').hide();
        $('#confirmCompanyCode').val('');
        return;
      }
      let pwdResult = this.profileUserForm.controls.password.value; //비밀번호
      let pwdCheck = this.profileUserForm.controls.confirmPassword.value; //비밀번호확인
      console.log(`company : ${pwdResult} ${pwdCheck}`);
      if (pwdResult !== pwdCheck) { //비밀번호 체크로직
        this.profileCompanyForm.controls.confirmPassword.setValue('');
        alert('비밀번호가 일치 하지 않습니다.');
        $('#companyConfirmPassword').focus();
        return;
      }
      if(this.isuniquePhoneCompanyCheck !== true){ //휴대폰인증 체크로직
        alert('휴대폰 인증을 해주세요');
        $('#adminMobile').focus();
        $('#confirmCompanyPhoneLi').hide();
        $('#confirmCompanyPhoneCode').val('');
        return;
      }
      if (this.isuniquedomainCompanyCheck !== true) { // 식별 ID 체크로직
        alert('이미 존재하는 식별 ID입니다.');
        $('#companyDomainId').focus();
        return;
      }
      if (checkRegistrationNumber(this.profileCompanyForm.controls.registrationNumber.value) !== true ) {
        alert('사업자 번호가 유효하지 않습니다.');
        return;
      }
      if ($('#registrationNumberSub').is(':checked')) {
        if (
          this.profileCompanyForm.controls.registrationNumberSub.value === '' ||
          this.profileCompanyForm.controls.registrationNumberSub.value ===
          undefined
        ) {
          $('#registrationNumberSubId').focus();
          alert('종사업자등록번호를 입력해 주세요.');
          return;
        }
      }else{
        this.profileCompanyForm.controls.registrationNumberSub.setValue(0);
      }

      const formData = new FormData();
      formData.append(
        'userId',
        this.profileCompanyForm.controls.userId.value
      );
      formData.append(
        'password',
        this.profileCompanyForm.controls.password.value
      );
      formData.append('name', this.profileCompanyForm.controls.name.value);
      /**
       * 20200811 cbkim
       * 전송 시 하이픈 제거
       */
      let formDataMobile = this.profileCompanyForm.controls.mobile.value.replace(/-/g,"")
      formData.append('mobile', formDataMobile);
      formData.append('companyName', this.profileCompanyForm.controls.companyName.value);
      formData.append('companyDomainId', this.profileCompanyForm.controls.companyDomainId.value);
      formData.append('registrationName', this.profileCompanyForm.controls.registrationName.value);
      let formDataRegistrationNumber = this.profileCompanyForm.controls.registrationNumber.value.replace(/-/g,"");
      formData.append('registrationNumber', formDataRegistrationNumber);
      formData.append('registrationNumberSub', this.profileCompanyForm.controls.registrationNumberSub.value);
      formData.append('telephone', this.profileCompanyForm.controls.telephone.value);
      formData.append('zipCode', this.profileCompanyForm.controls.zipCode.value);
      formData.append('address', this.profileCompanyForm.controls.address.value);
      formData.append('addressDetail', this.profileCompanyForm.controls.addressDetail.value);
      if (this.profileCompanyForm.invalid) {
        const invalid = [];
        const controls = this.profileCompanyForm.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        return;
      }
      formData.append('file', files[0]);
      this.spiner.show();
      this.webapi.signup(formData).subscribe((data) => {
        alert('회원가입이 완료되었습니다.');
        this.confirmMail(this.profileCompanyForm.controls.userId.value);
        this.router.navigate(['member/login']);
      });
    this.spiner.hide();
  }

  registrationNumberSubCheck() {
    if ($('#registrationNumberSub').is(':checked')) {
      $('#registrationNumberSubLi').show();
    } else {
      this.profileCompanyForm.controls.registrationNumberSub.setValue('');
      $('#registrationNumberSubLi').hide();
    }
  }

  setRegisterType(registerType): void {
    this.registerType = registerType;
    $('#tabFlag').hide();
    if (this.registerType === 'company') {
      $('#terms_form_companydiv').show();
    } else if(this.registerType === 'test') {
      $('#tab_test').show();
    } else {
      $('#terms_form_userdiv').show();
    } 
    $('html').scrollTop(0);
  }

  companyAllcheck(): void {
    if ($('#check_companyAllcheck').is(':checked')) {
      $('input[name=company]').prop('checked', true);
    } else {
      $('input[name=company]').prop('checked', false);
    }
  }

  companyCheck(): void {
    if ($("input[name='company']:checked").length === 2) {
      $('#check_companyAllcheck').prop('checked', true);
    } else {
      $('#check_companyAllcheck').prop('checked', false);
    }
  }

  userCheck(): void {
    if ($("input[name='user']:checked").length === 2) {
      $('#check_userAllcheck').prop('checked', true);
    } else {
      $('#check_userAllcheck').prop('checked', false);
    }
  }

  userAllcheck(): void {
    if ($('#check_userAllcheck').is(':checked')) {
      $('input[name=user]').prop('checked', true);
    } else {
      $('input[name=user]').prop('checked', false);
    }
  }

  nextStepCompany(): void {
    if ($('#check_company1').is(':checked') === false) {
      alert('모든 약관에 동의 하셔야 다음 단계로 진행 가능합니다.');
      return;
    } else if ($('#check_company2').is(':checked') === false) {
      alert('모든 약관에 동의 하셔야 다음 단계로 진행 가능합니다.');
      return;
    } else {
      $('html').scrollTop(0);
      $('#terms_form_companydiv').hide();
      $('#tab_content_company').show();
    }
  }

  nextStepUser(): void {
    if ($('#check_user1').is(':checked') === false) {
      alert('모든 약관에 동의 하셔야 다음 단계로 진행 가능합니다.');
      return;
    } else if ($('#check_user2').is(':checked') === false) {
      alert('모든 약관에 동의 하셔야 다음 단계로 진행 가능합니다.');
      return;
    } else {
      $('html').scrollTop(0);
      $('#terms_form_userdiv').hide();
      $('#tab_content_user').show();
      
    }
  }

  preStep(): void {
    $('html').scrollTop(0);
    $('#confirmUserCodeLi').hide();
    $('#confirmUserCodeLi_check').hide();
    $('#confirmUserCodeLi_resend').hide();
    $('#confirmUserPhoneLi').hide();
    $('#confirmUserPhoneLi_check').hide();
    $('#confirmUserPhoneLi_resend').hide();
    $('#confirmCompanyCodeLi').hide();
    $('input[type=checkbox]').prop('checked', false);
    $('#terms_form_userdiv').hide();
    $('#terms_form_companydiv').hide();
    $('#tab_content_user').hide();
    $('#tab_content_company').hide();
    $('#tabFlag').show();
    
    this.isuniqueidCompanyCheck = false;
    this.isuniqueidUserCheck = false;
    this.isuniquePhoneCompanyCheck = false;
    this.isuniquePhoneUserCheck = false;
    /**
     * 20200811 cbkim
     * 인증내역 삭제
     */    
  }

  isuniqueidCompany(params: string): string {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (params === '' || params === null || params === undefined) {
      this.isuniqueidCompanyCheck = false;
      alert('아이디를 입력해주세요');
      return;
    } else if (!regExp.test(params)) {
      this.isuniqueidCompanyCheck = false;
      alert('이메일 형식이아닙니다');
      return;
    } else {
      this.spiner.show();
      this.webapi.isuniqueid(params).subscribe((response) => {
        if (response === true) {
          alert('인증코드를 해당 이메일로 보냈습니다.');
          this.webapi.emailauth(params).subscribe(
            (response) => {
              $('#confirmCompanyCodeLi').show();
              this.confirmCompanyCode = response['code'];
              sendAuthNumCompany(false);
              $('#companyCountdown').attr('disabled', true);
              $('#companyCountdown')
                .css({ cursor: 'default' })
                .css('background-color', '#a0a0a0');
            },
            (err) => {

            }
          );
        } else {
          this.isuniqueidCompanyCheck = false;
          this.profileCompanyForm.controls.userId.setValue('');
          alert('이미 가입된 아이디 입니다.');
          $('#companyUserId').focus();
        }
      });
      this.spiner.hide();
    }
  }
  /**
   * 20200810 cbkikm
   * 임시조치 false로 변경 필요 
   */
  

  isRegNumCheck = true;
  chkRegistrationNum(params: string): string {
    const reg_val = this.profileCompanyForm.controls.registrationNumber.value;

    if (reg_val === '' || reg_val === null || reg_val === undefined) {
      this.isRegNumCheck = false;
      alert('사업자등록번호를 입력해주세요');
      return;
    } 
    // else if (checkRegistrationNumber(reg_val) !== true) {
    //   this.isRegNumCheck = false;
    //   alert('유효하지 않은 사업자번호입니다.');
    //   return;
    // } 
    else {
      this.spiner.show();
      this.webapi.blsCheckBSNO(reg_val).subscribe((result: any) => {
        this.spiner.hide();
        if(result== 'true'){
          this.isRegNumCheck = true;
          $('#registrationNumber').attr('readonly', true)
        }else{
          this.isRegNumCheck = false;
        }
      }, 
      (error) => {
        this.spiner.hide();
      });
    }
  }  
  rewriteRegNum(){
    $('#registrationNumber').attr('readonly', false)
    $('#registrationNumber').val("");
    this.isRegNumCheck = false;
  }
  confirmCompanyCodeCheck(): void {
    if (this.confirmCompanyCode === $('#confirmCompanyCode').val()) {
      this.isuniqueidCompanyCheck = true;
      $('#confirmCompanyCode').val('');
      $('#confirmCompanyCodeLi').css('display', 'none');
      alert('인증을 완료했습니다.');
      sendAuthNumCompany(true);
      return;
    } else {
      this.isuniqueidCompanyCheck = false;
      alert('인증번호가 일치 하지 않습니다.');
      return;
    }
  }

  confirmCompanyCodeCall(params): void {
    this.isuniqueidCompanyCheck = false;
    $('#confirmCompanyCode').val('');
    this.spiner.show();
    this.webapi.emailauth(params).subscribe(
      (response) => {
        this.confirmCompanyCode = response['code'];
        sendAuthNumCompany(false);
        alert('인증코드를 해당 이메일로 보냈습니다.');
      },
      (err) => {
      }
    );
    this.spiner.hide();
  }
  isuniquePhoneCompany(params) {

    const regExp = /^\d{3}[-]?\d{4}[-]?\d{4}$/
    if(params === '' || params === null || params === undefined){
      this.isuniquePhoneCompanyCheck = false;
      alert('핸드폰 번호를 입력해 주세요.');
      return
    } else if(!regExp.test(params)){
      this.isuniquePhoneCompanyCheck = false;
      alert('올바른 핸드폰 번호 형식이 아닙니다.')
      return
    } else {
      this.webapi.makeSignUpMobileCode(params.replace(/\-/g,'')).subscribe(res=>{
        alert('인증코드를 보냈습니다.');
        $('#confirmCompanyPhoneLi').show();
        $('#phoneCompanyCountDown').attr('disabled', true)
        $('#phoneCompanyCountDown').css({ cursor: 'default' }).css('background-color', '#a0a0a0');
        $('#adminMobile').attr('disabled', true);
        // 인증코드 받는 부분
        sendAuthNumPhoneCompany(false);
      }, err=>{
        alert("실패")
      })
    }
  }
  confirmCompanyPhoneCodeCheck(){
    const code = $('#confirmCompanyPhoneCode').val()    
    this.webapi.confirmSignUpMobileCode(code).subscribe(res=>{  
      this.isuniquePhoneCompanyCheck = true;
      $('#confirmCompanyPhoneLi').css('display','none');
      $('#confirmCompanyPhoneCode').val('');
      alert('인증을 완료 했습니다.');
      sendAuthNumPhoneCompany(true);
    }, err=>{
      this.isuniquePhoneCompanyCheck = false;
      alert('인증번호가 일치 하지 않습니다.')
    })
  }
  confirmCompanyPhoneCodeCall(param){
    this.isuniquePhoneCompanyCheck = false
    $('#confirmCompanyPhoneCode').val('');
    //인증코드 받아오는 부분
    this.webapi.makeSignUpMobileCode(param.replace(/\-/g,'')).subscribe(
      (response)=>{
        alert('인증코드를 보냈습니다.');
        sendAuthNumPhoneCompany(false);
      }, (err) => {      }
    )
  }
  
  isuniqueidUser(params: string) {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (params === '' || params === null || params === undefined) {
      this.isuniqueidUserCheck = false;
      alert('아이디를 입력해주세요');
      $('#userUserId').focus();
      return;
    } else if (!regExp.test(params)) {
      this.isuniqueidUserCheck = false;
      alert('이메일 형식이아닙니다');
      $('#userUserId').focus();
      return;
    } else {
      this.spiner.show();
      this.webapi.isuniqueid(params).subscribe((response) => {
        if (response === true) {
          alert('인증코드를 해당 이메일로 보냈습니다.');
          this.webapi.emailauth(params).subscribe(
            (response) => {
              $('#confirmUserCodeLi').show();
              $('#confirmUserCodeLi_check').show();
              $('#confirmUserCodeLi_resend').show();
              this.confirmUserCode = response['code'];

              $('#userCountdown').attr('disabled', true);
              $('#userCountdown').css({ cursor: 'default' }).css('background-color', '#a0a0a0');
              $('#userUserId').attr('disabled', true);
              sendAuthNumUser(false);
            },
            (err) => {
            }
          );
        } else {
          this.isuniqueidUserCheck = false;
          this.profileUserForm.controls.userId.setValue('');
          alert('이미 가입된 아이디 입니다.');
          $('#userUserId').focus();
        }
      });
      this.spiner.hide();
    }
  }
  confirmUserCodeCheck(): void {
    if (this.confirmUserCode === $('#confirmUserCode').val()) {
      this.isuniqueidUserCheck = true;
      $('#confirmUserCodeLi').css('display', 'none');
      $('#confirmUserCodeLi_check').css('display', 'none');
      $('#confirmUserCodeLi_resend').css('display', 'none');
      $('#confirmUserCode').val('');
      $('#userCountdown').attr('disabled', false);
      $('#userCountdown').css({ cursor: 'pointer' }).css('background-color', '#fff');
      sendAuthNumUser(true);
      alert('인증을 완료했습니다.');
      return;
    } else {
      this.isuniqueidUserCheck = false;
      alert('인증번호가 일치 하지 않습니다.');
      return;
    }
  }
  confirmUserCodeCall(params): void {
    this.isuniqueidUserCheck = false;
    $('#confirmUserCode').val('');
    this.webapi.emailauth(params).subscribe(
      (response) => {
        this.confirmUserCode = response['code'];
        sendAuthNumUser(false);
        alert('인증코드를 해당 이메일로 보냈습니다.');
      },
      (err) => {
      }
    );
  }
  isuniquePhoneUser(params){
    const regExp = /^\d{3}[-]?\d{4}[-]?\d{4}$/
    if(params === '' || params === null || params === undefined){
      this.isuniquePhoneUserCheck = false;
      alert('핸드폰 번호를 입력해 주세요.');
      $('#UserMobile').focus();
      return;
    }else if(!regExp.test(params)){
      this.isuniquePhoneUserCheck = false;
      alert('올바른 핸드폰 형식이 아닙니다.');
      $('#UserMobile').focus();
      return;
    } else {
      this.webapi.makeSignUpMobileCode(params.replace(/\-/g,'')).subscribe(res=>{
        $('#confirmUserPhoneLi').show();
        $('#confirmUserPhoneLi_check').show();
        $('#confirmUserPhoneLi_resend').show();
        $('#userPhoneCountdown').attr('disabled', true)
        $('#userPhoneCountdown').css({ cursor: 'default' }).css('background-color', '#a0a0a0');
        $('#UserMobile').attr('disabled', true);
        alert('인증번호를 보냈습니다.');
        sendAuthNumPhoneUser(false);
      }, err=>{
        alert("실패")
      })
    }    
  }

  confirmUserPhoneCodeCheck() {
    const code = $('#confirmUserPhoneCode').val()
    this.webapi.confirmSignUpMobileCode(code).subscribe(res=>{
      $('#confirmUserPhoneLi').css('display','none');
      $('#confirmUserPhoneLi_check').css('display','none');
      $('#confirmUserPhoneLi_resend').css('display','none');
      $('#confirmUserPhoneCode').val('');
      alert('인증을 완료 했습니다.');
      this.isuniquePhoneUserCheck = true;
      sendAuthNumPhoneUser(true);
    }, err=>{
      this.isuniquePhoneUserCheck = false;
      alert('인증번호가 일치 하지 않습니다.')
    })  
  }
  confirmUserPhoneCodeCall(param){
    this.isuniquePhoneUserCheck = false;
    $('confirmUserPhoneCode').val('');
    this.webapi.makeSignUpMobileCode(param.replace(/\-/g,'')).subscribe(res=>{
      alert('인증번호를 보냈습니다.');
      sendAuthNumPhoneUser(false)      
    }, err=>{
      alert("실패")
    })
    sendAuthNumPhoneUser(false);
  }

  openDaumPopup(params) {
    postcode((data: any) => {
      if (params === 'user') {
        this.profileUserForm.controls.zipCode.setValue(data.zonecode);
        this.profileUserForm.controls.address.setValue(data.address);
        this.profileUserForm.controls.addressDetail.setValue('');
      } else {
        this.profileCompanyForm.controls.zipCode.setValue(data.zonecode);
        this.profileCompanyForm.controls.address.setValue(data.address);
        this.profileCompanyForm.controls.addressDetail.setValue('');
      }
    });
  }

  changeCompanyReson(e) {
    this.profileCompanyForm.controls.reson.setValue(e.target.value);
  }

  changeUserReson(e) {
    this.profileUserForm.controls.reson.setValue(e.target.value);
  }

  routerHome(){
    this.router.navigate(['/']);
  }

  changePhoneNumber(mobile, type){
    var number = mobile.value;
    number = number.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-")

    if(0 == type){
      // company
      this.profileCompanyForm.controls.mobile.setValue(number);
    }else{
      // user
      this.profileUserForm.controls.mobile.setValue(number);
    }
    
    //mobile.value = number;
    
    //$('#adminMobile').val(number);
  }

  changeRegistrationNumber(registrationName){
    var number = registrationName.value;
    number = number.replace(/[^0-9]/g, "").replace(/([0-9]{3})([0-9]{2})([0-9]{5})/,"$1-$2-$3").replace("--", "-")
    this.profileCompanyForm.controls.registrationNumber.setValue(number);
    //$('#registrationNumber').val(number);
  }

  userName = new FormControl('', )
  email = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.email.hasError('required')) { 
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  confirmMail(params: string) {
    let dataForm={
      receiver:'helpdesk@bizlinkersys.com',
      subject:'회원가입요청메일',
      text:params + ' 님이 회원가입요청 하셨습니다.'
    }
    this.webapi.sendEmail(dataForm).subscribe((data: {}) => {});
  }
}