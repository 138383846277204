import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-nas-server-interface-update',
  templateUrl: './dialog-nas-server-interface-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNasServerInterfaceUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNasServerInterfaceUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  attachedServerList: any = [];
  detachedServerList: any = [];
  
  serverIdForAttachingInterface: string = '';
  attaching_interface_modal_server_data:any=null;

  ngOnInit(): void {

    this.attachedServerList = this.dialogData.attachedServerList;
    this.detachedServerList = this.dialogData.detachedServerList;
  }

  
  attachNasInterface() {

    try {
      this.spinner.show();
      this.osapi
        .attachNasInterface(this.serverIdForAttachingInterface)
        .subscribe((data) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type:'toast',
            body:{
              type:'success',
              title:'NAS',
              message:'NAS 네트워크에 인터페이스 연결 성공'
            }
          });
          this.closeDialogMsg("success");
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type:'toast',
            body:{
              type:'error',
              title:'오류 알림',
              message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {

    }
  }

  detachNasInterface(serverId) {

    try {
      this.spinner.show();
      this.osapi.detachNasInterface(serverId).subscribe((data) => {
        this.spinner.hide();

        
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'success',
            title:'NAS',
            message:'서버에서 NAS 네트워크 인터페이스 제거 성공'
          }
        });
        this.closeDialogMsg("success");
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
    } catch (e) {

    }
  }
  
  
  onChangeAttachingInterface(){
    let server_id = this.serverIdForAttachingInterface
    for(let server of this.detachedServerList){
      if(server.server_id == server_id){
        this.attaching_interface_modal_server_data = server;
        return;
      }
    }
    this.attaching_interface_modal_server_data =null;
  }

  
  joinInterfaces(interfaces) {
    const _ = interfaces.map((int) => `${int.name}: ${int.adresses[0]}`);

    return `${_.join(', ')}`;
  }

}
