<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>서버 백업 서비스</h2>
        <div class="lineMap">
            Service  / <span>Server Backup</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">								
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%;">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">서비스 신청</th>
                                        <td>
                                            <button class="btn mid orange mg_r4" (click)="openCreateModal()">백업 신청</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>서비스 이용 현황 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                </div>
                                <a href="javascript:void(0)" class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                            </div>
                        </div>									
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <!-- 우클릭 메뉴 사용시 dropmenu class 추가 -->
                            <table class="tbl_col_tp1">
                                <colgroup>
                                    <col >
                                    <col>
                                    <col>
                                    <col>
                                    <col>
                                    <col>
                                </colgroup>
                                <thead>
                                    <th scope="col"><input type="checkbox" name="" id="checkbox"></th>
                                    <th scope="col">백업 대상 서버</th>
                                    <th scope="col">방식</th>
                                    <th scope="col">스케줄</th>
                                    <th scope="col">경로</th>
                                    <th scope="col">처리현황</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let service of backupServerList">
                                        <td><input class="mycheckbox" type="checkbox" name="" id=""></td>
                                        <td>{{service.metadata.instanceName}}</td>
                                        <td>{{service.metadata.backupType}}</td>
                                        <td>{{service.metadata.schedule}}</td>
                                        <td>{{service.metadata.serverPath}}</td>
                                        <td>{{service.status}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">        
        <a onclick="fn_modalOpen('msque_edit')">DNS 수정</a>
        <a onclick="fn_modalOpen('msque_delete')">DNS 삭제</a>
    </div>
</div> -->

<!-- Modal : 백업 신청 팝업 모달 -->
<div id="serberbak_create" class="modal">
    <div class="modal_header">
        <h2>백업 신청
            <p class="sub-txt"> | VM 인스턴스에 대해 백업정책을 신청해주시면 해당 인스턴스의 데이터를 주기적으로 백업합니다. </p>
        </h2>
    </div>
    
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">백업 서버</th>
                        <td>														
                            <select (change)="onChangeBackupServer($event.target.value)">
                                <option value="-1" selected>-선택-</option>
                                <ng-template [ngIf]="serverList">
                                    <option  *ngFor="let server of serverList; let i = index" [value]="i">{{server.server_name}}</option>
                                </ng-template>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">백업 방식</th>
                        <td>														
                            <select (change)="onChangeBackupType($event.target.value)">
                                <option *ngFor="let type of bakTypeList" [value]="type" >{{type}}</option>
                            </select>
                        </td>
                    </tr>
                    <!-- Synthetic Full 선택시 -->
                    <tr *ngIf="baktype === 'Synthetic Full'">
                        <th scope="row">백업 옵션</th>
                        <td>
                            <select [(ngModel)]="newBackupServerData.option" >
                                <option value="DEFAULT" selected>-기본-</option>
                                <option value="AdditionalIncrementalBeforeBackup" >백업 실행 전 Incremental 백업 추가 수행</option>
                                <option value="AdditionalIncrementalAfterBackup" >백업 실행 후 Incremental 백업 추가 수행</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">백업 스케줄</th>
                        <td>														
                            <select [(ngModel)]="newBackupServerData.schedule">
                                <option *ngFor="let schedule of bakSchedule" [value]="schedule">{{schedule}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">백업 보관 개수</th>
                        <td>
                            <input [(ngModel)]="newBackupServerData.copy_num" type="number" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">백업 경로</th>
                        <td>
                            <input [(ngModel)]="newBackupServerData.server_path" type="text" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>        
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" (click)="createServerBackupService()" class="btn big orange mg_r4">신청</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
</div>

<!-- Modal : 백업신청 수정 팝업 모달
<div id="msque_edit" class="modal">
    <div class="modal_header">
        <h2>백업신청 수정
            <p class="sub-txt"> | 백업신청 수정 팝업 텍스트 설명입니다. </p>
        </h2>
    </div>
    
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">레코드명</th>
                        <td>														
                            <input type="text" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">레코드 타입</th>
                        <td>
                            <select required>
                                <option value="">-선택-</option>
                                <option value="">A</option>
                                <option value="">AAA</option>
                                <option value="">CNAME</option>
                                <option value="">MX</option>
                                <option value="">SPF</option>
                                <option value="">PTR</option>
                                <option value="">TXT</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">레코드 값</th>
                        <td>
                            <input type="text" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">추가</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
</div> -->

<!-- <app-console-common-modal (modalMsg)="deleteDns()"></app-console-common-modal> -->