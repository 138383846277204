import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../../../webapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any): any;
declare const $: any;
@Component({
  selector: 'app-admin-withdrawal-list',
  templateUrl: './admin-withdrawal-list.component.html',
  providers: [AdminNavComponent],
})

export class AdminwithdrawalListComponent implements OnInit {

  withdrawals: any = [];

  modal_withdrawal_reson: string = '';

  //페이징변수
  curPage: any;
  totalCount: any;

  constructor(
    private webapi: WebApiService,
    private comp: AdminNavComponent
  ) { }

  ngOnInit(): void {
    this.getWithdrawalList(0)
    // this.comp.kinLookup();
  }

  //탈퇴 목록 조회
  getWithdrawalList(page) {
    this.curPage = page
    var searchQuerys = "&o=created_date.DESC&l=10&s=" + (this.curPage * 10);

    this.webapi.getWithdrawalsList(searchQuerys).subscribe(
      (response) => {

        this.withdrawals = response.body['result'];
        this.totalCount = response.body['total'];
      }
    ), (err) => {

    }
  }

  //탈퇴 목록 검색
  getWithdrawalSearch() {
    this.curPage = 0;
    var searchKeyword = $('#search').val()
    var searchQuerys = "o=created_date.DESC&l=10&s=" + (this.curPage * 10) + "&user_id=" + (searchKeyword) + "&lk=user_id"

    this.webapi.getWithdrawalsList(searchQuerys).subscribe(
      (response) => {

        this.withdrawals = response.body['result'];
        this.totalCount = response.body['total'];

      }
    ), (err) => {

    }
  }

  withdrawalOk(userData) {
    userData.confirmedDate = 'confirm'

    this.webapi.putWithdrawalsConfirm(userData).subscribe(
      (response) => {
        this.getWithdrawalList(0)
      }, (err) => {

      }
    )
  }

  withdrawalNo(userData) {
    userData.deletedDate = 'delete'

    this.webapi.putWithdrawalsNotConfirm(userData).subscribe(
      (response) => {
        this.getWithdrawalList(0)
      }, (err) => {

      }
    )
  }

  //탈퇴 목록 상세보기
  fn_detailCall(userData) {
    this.modal_withdrawal_reson = userData.reason
    fn_modalOpen('user_detail');
  }

  //페이징함수
  getMovePage(e) {

    this.getWithdrawalList(e.page)
  }


}