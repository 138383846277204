import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import {WebApiService} from './webapi.service';

@Injectable({
  providedIn: 'root',
})
export class OsapiService {
  projects = [];
  // userInfo: { projectId: string; userId: string; companyId: number };
  userInfo: any;
  _that = this;

  constructor(private http: HttpClient,private router:Router, private webapi:WebApiService) {
    this.handleError = this.handleError.bind(this);
   }

  getServerList() {
    return this.http.get('/api/nova/servers').pipe(catchError(this.handleError));
  }
  getServer(server_id) {
    return this.http.get('/api/nova/servers/' + server_id).pipe(catchError(this.handleError));
  }
  createServer(
    name,
    description,
    flavor_id,
    imagetype,
    image_id,
    instance_count,
    user_data,
    network_id,
    sg_id,
    availability_zone,
    key_name,
    flavor_volume_size,
    metadata
  ) {
    if (!network_id) {
      throw 'Cannot find default network';
    }
    
    //scheduler_hints: {group: "c2bf09e9-5fa2-4662-b8b0-ac673722ef2b"}
    
    const body = {
      name: name,
      description: description,
      source_id: null,
      flavor_id: flavor_id,
      instance_count: instance_count,
      scheduler_hints: {},
      user_data: user_data,
      block_device_mapping: null,
      nics: [{ 'port-id': network_id, 'v4-fixed-ip': '' }],
      // nics: [{ 'net-id': network_id, 'v4-fixed-ip': '' }],
      config_drive: false,
      // "admin_pass": admin_pass,
      disk_config: 'AUTO',
      create_volume_default: true,
      hide_create_volume: false,
      block_device_mapping_v2: [
        {
          source_type: imagetype,
          destination_type: 'volume',
          delete_on_termination: true,
          uuid: image_id,
          boot_index: '0',
          volume_size: flavor_volume_size,
        },
      ],
      security_groups: [sg_id],
      availability_zone: availability_zone,
      key_name: key_name,
      meta: metadata,
    };
    return this.http.post('/api/nova/servers', body).pipe(catchError(this.handleError));
  }
  deleteServer(server_id) {
    return this.http.delete('/api/nova/servers/' + server_id).pipe(catchError(this.handleError));
  }
  updateServerInfo(server_id, name, description) {
    const body = {
      name: name,
      description: description,
    };
    return this.http.post('/api/nova/servers/' + server_id, body).pipe(catchError(this.handleError));
  }
  pauseServer(server_id) {
    const params = new HttpParams();
    return this.http.post('/api/nova/servers/' + server_id + '/pause', params).pipe(catchError(this.handleError));
  }
  unpauseServer(server_id) {
    const params = new HttpParams();
    return this.http.post(
      '/api/nova/servers/' + server_id + '/unpause',
      params
    ).pipe(catchError(this.handleError));
  }
  suspendServer(server_id) {
    const params = new HttpParams();
    return this.http.post(
      '/api/nova/servers/' + server_id + '/suspend',
      params
    ).pipe(catchError(this.handleError));
  }
  resumeServer(server_id) {
    const params = new HttpParams();
    return this.http.post('/api/nova/servers/' + server_id + '/resume', params).pipe(catchError(this.handleError));
  }
  softRebootServer(server_id) {
    const params = new HttpParams();
    return this.http.post(
      '/api/nova/servers/' + server_id + '/softreboot',
      params
    ).pipe(catchError(this.handleError));
  }
  hardRebootServer(server_id) {
    const params = new HttpParams();
    return this.http.post(
      '/api/nova/servers/' + server_id + '/hardreboot',
      params
    ).pipe(catchError(this.handleError));
  }
  startServer(server_id) {
    const params = new HttpParams();
    return this.http.post('/api/nova/servers/' + server_id + '/start', params).pipe(catchError(this.handleError));
  }
  stopServer(server_id) {
    const params = new HttpParams();
    return this.http.post('/api/nova/servers/' + server_id + '/stop', params).pipe(catchError(this.handleError));
  }
  getServerSecurityGroupList(server_id) {
    return this.http.get('/api/nova/servers/' + server_id + '/security-groups').pipe(catchError(this.handleError));
  }
  updateServerSecurityGroup(server_id, sg_list) {
    const body = {
      security_groups: sg_list,
    };
    return this.http.post(
      '/api/nova/servers/' + server_id + '/security-groups',
      body
    ).pipe(catchError(this.handleError));
  }
  getServerVolumeList(server_id) {
    this.http.get('/api/nova/servers/' + server_id + '/volumes').pipe(catchError(this.handleError));
  }
  attachVolume(server_id, volume_id) {
    const body = {};
    return this.http.post(
      '/api/nova/servers/' + server_id + '/volumes/' + volume_id + '/attach',
      body
    ).pipe(catchError(this.handleError));
  }
  detachVolume(server_id, volume_id) {
    const body = {};
    return this.http.post(
      '/api/nova/servers/' + server_id + '/volumes/' + volume_id + '/detach',
      body
    ).pipe(catchError(this.handleError));
  }

  createSnapShotImage(instance_id, image_name, image_description) {
    const body = {
      instance_id: instance_id,
      name: image_name,
      description: image_description,
    };
    return this.http.post('/api/nova/snapshots', body).pipe(catchError(this.handleError));
  }

  getAvailzones() {
    return this.http.get('/api/nova/availzones').pipe(catchError(this.handleError));
  }

  getFlavorList() {
    return this.http.get('/api/nova/flavors').pipe(catchError(this.handleError));
  }
  getFlavor(flavor_id) {
    return this.http.get('/api/nova/flavors/' + flavor_id).pipe(catchError(this.handleError));
  }
  createFlavor(
    flavor_id,
    name,
    ram,
    vcpus,
    disk,
    ephemeral,
    swap,
    flavor_access
  ) {
    const body = {
      id: flavor_id,
      name: name,
      ram: ram,
      vcpus: vcpus,
      disk: disk,
      'OS-FLV-EXT-DATA:ephemeral': ephemeral,
      swap: swap,
      flavor_access: flavor_access,
    };
    return this.http.post('/api/nova/flavors', body).pipe(catchError(this.handleError));
  }
  getFlavorExtraList(flavor_id) {
    return this.http.get('/api/nova/flavors/' + flavor_id + '/extra-specs').pipe(catchError(this.handleError));
  }
  updateFlavorExtraList(flavor_id) {
    //TODO
    const params = new HttpParams();
    return this.http.post(
      '/api/nova/flavors/' + flavor_id + '/extra-specs',
      params
    ).pipe(catchError(this.handleError));
  }
  deleteFlavor(flavor_id) {
    return this.http.delete('/api/nova/flavors/' + flavor_id).pipe(catchError(this.handleError));
  }

  getKeyPairList() {
    return this.http.get('/api/nova/keypairs').pipe(catchError(this.handleError));
  }
  getKeyPair(keypair_name) {
    return this.http.get('/api/nova/keypairs/' + keypair_name).pipe(catchError(this.handleError));
  }
  createKeyPair(name, key_type, key_description) {
    const body = {
      name: name,
      key_type: key_type,
      description: key_description,
    };
    return this.http.post('/api/nova/keypairs', body).pipe(catchError(this.handleError));
  }
  deleteKeyPair(keypair_name) {
    return this.http.delete('/api/nova/keypairs/' + keypair_name).pipe(catchError(this.handleError));
  }
  updateKeyPair(name, key_description) {
    const body = {
      description: key_description,
    };
    return this.http.post('/api/nova/keypairs/' + name, body).pipe(catchError(this.handleError));
  }
  getLimits() {
    return this.http.get('/api/nova/limits').pipe(catchError(this.handleError));
  }

  getServerGroups() {
    return this.http.get('/api/nova/servergroups').pipe(catchError(this.handleError));
  }

  showLog(server_id) {
    return this.http.get(
      '/api/nova/servers/' + server_id + '/server_console_output'
    ).pipe(catchError(this.handleError));
  }
  resizeServer(server_id, flavor_id) {
    const body = {
      instance_id: server_id,
      flavor_id: flavor_id,
    };
    return this.http.post('/api/nova/servers/' + server_id + '/resize', body).pipe(catchError(this.handleError));
  }
  confirmResize(server_id){
    return this.http.post('/api/nova/servers/' + server_id + '/resize/confirm', {}).pipe(catchError(this.handleError));
  }


  /* Glance */
  getImageList() {
    return this.http.get('/api/glance/images', { observe: 'response' }).pipe(catchError(this.handleError));
  }
  getCurrentProjectImageList() {
    return this.http.get('/api/glance/images/current', { observe: 'response' }).pipe(catchError(this.handleError));
  }
  getImage(image_id) {
    return this.http.get('/api/glance/images' + image_id).pipe(catchError(this.handleError));
  }
  createImage(form_data) {
    let options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
    return this.http.post('/api/glance/images/', form_data, options).pipe(catchError(this.handleError));
  }
  deleteImage(image_id) {
    return this.http.delete('/api/glance/images/' + image_id).pipe(catchError(this.handleError));
  }
  updateImage(
    image_id,
    image_name,
    image_description,
    disk_format,
    visibility,
    image_protected
  ) {
    const body = {
      name: image_name,
      description: image_description,
      disk_format: disk_format,
      visibility: visibility,
      protected: image_protected,
    };
    return this.http.post('/api/glance/images/' + image_id, body).pipe(catchError(this.handleError));
  }

  /** neutron */

  getNetworkList() {
    return this.http.get('/api/neutron/networks').pipe(catchError(this.handleError));
  }

  deleteNetwork(network_id) {
    return this.http.delete(`/api/neutron/networks/${network_id}`).pipe(catchError(this.handleError));
  }

  getNetwork(network_id) {
    return this.http.get('/api/neutron/networks' + network_id).pipe(catchError(this.handleError));
  }

  createNetwork(admin_state_up, name, shared) {
    return this.http.post('/api/neutron/networks', {
      admin_state_up: admin_state_up,
      name: name,
      shared: shared,
    }).pipe(catchError(this.handleError));
  }
  getSubnetList() {
    return this.http.get('/api/neutron/subnets').pipe(catchError(this.handleError));
  }

  createSubnet(param) {
    return this.http.post('/api/neutron/subnets', param).pipe(catchError(this.handleError));
  }

  updateSubnet(id, param) {
    return this.http.put(`/api/neutron/subnets/${id}/`, param).pipe(catchError(this.handleError));
  }

  deleteSubnet(id) {
    return this.http.delete(`/api/neutron/subnets/${id}/`).pipe(catchError(this.handleError));
  }

  getPortList() {
    return this.http.get('/api/neutron/ports').pipe(catchError(this.handleError));
  }
  createPort(network_id, subnet_id, security_groups) {
    const body = {
      network_id: network_id,
      fixed_ips: [{ subnet_id: subnet_id }],
      security_groups : security_groups
    };
    return this.http.post('/api/neutron/ports', body).pipe(catchError(this.handleError));
  }

  updatePort(network_id, host_id) {
    const body = {
      network_id: network_id,
      host_id: host_id,
    };
    return this.http.put('/api/neutron/ports', body).pipe(catchError(this.handleError));
  }
  
  deletePort(network_id,port_id){
    const body = {
      network_id:network_id,
      port_id:port_id
    };
    return this.http.post('/api/neutron/del-port',body).pipe(catchError(this.handleError));
  }

  getFloatingipList() {
    return this.http.get('/api/neutron/floatingips').pipe(catchError(this.handleError));
  }
  createFloatingIpFf(body) {
    return this.http.post('/api/neutron/floatingipPf/', body).pipe(catchError(this.handleError));
  }
  createFloatingIp(description) {
    const body = {
      description: description,
    };
    return this.http.post('/api/neutron/floatingips', body).pipe(catchError(this.handleError));
  }
  deleteFloatingIp(fip_id) {
    return this.http.delete('/api/neutron/floatingips/' + fip_id).pipe(catchError(this.handleError));
  }
  updateFloatingIp(fip_id, description) {
    const body = {
      description: description,
    };
    return this.http.post('/api/neutron/floatingips/' + fip_id, body).pipe(catchError(this.handleError));
  }
  associateFloatingIp(fip_id, port_id) {
    //port_id :: port_id+"_"+int_ip
    const body = {
      address_id: fip_id,
      port_id: port_id,
    };
    return this.http.patch('/api/neutron/floatingips', body).pipe(catchError(this.handleError));
  }
  disassociateFloatingIp(fip_id) {
    const body = {
      address_id: fip_id,
      port_id: null,
    };
    return this.http.patch('/api/neutron/floatingips/', body).pipe(catchError(this.handleError));
  }
  getUserDefaultNework() {
    return this.http.get('/api/neutron/defaultNetwork').pipe(catchError(this.handleError));
  }
  getSecurityGroupList() {
    return this.http.get('/api/neutron/securitygroups').pipe(catchError(this.handleError));
  }
  getSecurityGroup(sg_id) {
    return this.http.get('/api/neutron/securitygroups/' + sg_id).pipe(catchError(this.handleError));
  }
  createSecurityGroup(name, description) {
    const body = {
      name: name,
      desc: description,
    };
    return this.http.post('/api/neutron/securitygroups', body).pipe(catchError(this.handleError));
  }
  deleteSecurityGroup(sg_id) {
    return this.http.delete('/api/neutron/securitygroups/' + sg_id).pipe(catchError(this.handleError));
  }
  getSecurityGroupRuleList(sg_id) {
    return this.http.get('/api/neutron/securitygroups/' + sg_id + '/rules').pipe(catchError(this.handleError));
  }
  createSecurityGroupRule(
    sg_id,
    direction,
    ip_protocol,
    from_port,
    to_port,
    cidr,
    group_id,
    description
  ) {
    const body = {
      direction: direction,
      ip_protocol: ip_protocol,
      from_port: from_port,
      to_port: to_port,
      cidr: cidr,
      group_id: group_id,
      description: description,
    };
    return this.http.post(
      '/api/neutron/securitygroups/' + sg_id + '/add_rule',
      body
    ).pipe(catchError(this.handleError));
  }
  deleteSecurityGroupRule(rule_id) {
    return this.http.delete('/api/neutron/securitygroup-rule/' + rule_id).pipe(catchError(this.handleError));
  }

  // getPortForwardingList(id) {
  //   return this.http.get('/api/neutron/networks/port_forwardings/');
  // }
  getPortForwardingList2(id) {
    return this.http.get(`/api/neutron/networks/port_forwarding2/${id}`).pipe(catchError(this.handleError));
  }
  getPfList() {
    return this.http.get(`/api/neutron/networks/all_port_forwarding`).pipe(catchError(this.handleError));
  }

  createPortForwarding(param) {
    return this.http.post('/api/neutron/networks/port_forwardings', param).pipe(catchError(this.handleError));
  }
  createPortForwarding2(id, param) {
    return this.http.post(`/api/neutron/networks/port_forwarding2/${id}`, {
      port_forwarding: param,
    }).pipe(catchError(this.handleError));
  }
  deletePortForwarding2(id, param) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        port_forwarding_id: param,
      },
    };

    return this.http.delete(
      `/api/neutron/networks/port_forwarding2/${id}`,
      options
    ).pipe(catchError(this.handleError));
  }

  /**END neutron */

  /** cinder */
  getVolumeList() {
    return this.http.get('/api/cinder/volumes').pipe(catchError(this.handleError));
  }
  getVolume(volume_id) {
    return this.http.get('/api/cinder/volumes/' + volume_id).pipe(catchError(this.handleError));
  }
  createVolume(
    name,
    description,
    availability_zone,
    size,
    volume_type,
    metadata,
    image_id,
    source_volid,
    snapshot_id
  ) {
    const body = {
      name: name,
      description: description,
      availability_zone: availability_zone,
      size: size,
      volume_type: volume_type,
      metadata: metadata,
      image_id: image_id,
      source_volid: source_volid,
      snapshot_id: snapshot_id,
    };
    return this.http.post('/api/cinder/volumes', body).pipe(catchError(this.handleError));
  }
  deleteVolume(volume_id) {
    return this.http.delete('/api/cinder/volumes/' + volume_id).pipe(catchError(this.handleError));
  }
  updateVolume(volume_id, volume_name, volume_description) {
    const body = {
      name: volume_name,
      description: volume_description,
    };
    return this.http.post('/api/cinder/volumes/' + volume_id, body).pipe(catchError(this.handleError));
  }
  getVolumeSnapshotList() {
    return this.http.get('/api/cinder/volumesnapshots').pipe(catchError(this.handleError));
  }
  createVolumeSnapshot(volume_id, snapshot_name, description) {
    const body = {
      name: snapshot_name,
      description: description,
    };
    return this.http.post(
      '/api/cinder/volumes/' + volume_id + '/create_snapshot',
      body
    ).pipe(catchError(this.handleError));
  }
  deleteVolumeSnapshot(snapshot_id) {
    return this.http.delete('/api/cinder/volumesnapshots/' + snapshot_id).pipe(catchError(this.handleError));
  }
  updateVolumeSnapshot(snapshot_id, snapshot_name, snapshot_description) {
    const body = {
      name: snapshot_name,
      description: snapshot_description,
    };
    return this.http.post('/api/cinder/volumesnapshots/' + snapshot_id, body).pipe(catchError(this.handleError));
  }
  createVolumeImage(volume_id, image_name, disk_format, force) {
    const body = {
      image_name: image_name,
      disk_format: disk_format,
      force: force,
    };
    return this.http.post(
      '/api/cinder/volumes/' + volume_id + '/create_image',
      body
    ).pipe(catchError(this.handleError));
  }

  /** keystone */
  createProject(name, description) {
    return this.http.post('api/keystone/projects/', {
      name,
      description,
    }).pipe(catchError(this.handleError));
  }
  updateProject(project_id, name, description, project_manager) {
    return this.http.post('api/keystone/projects/'+project_id, {
      value:name,
      description:description,
      project_manager:project_manager
    }).pipe(catchError(this.handleError));
  }


  createProjectParam(param) {
    return this.http.post('api/keystone/projects/', param).pipe(catchError(this.handleError));
  }

  getProjectList() {
    return this.http.get('/api/keystone/user-projects/').pipe(catchError(this.handleError));
  }
  getProjectsById(horizonUid) {
    return this.http.post('/api/keystone/projects-by-id/', { horizonUid }).pipe(catchError(this.handleError));
  }
  switchProject(project_id) {
    return this.http.post('/api/keystone/switch', { project_id }).pipe(catchError(this.handleError));
  }
  getProjectInfo() {
    return this.http.get('/api/keystone/project-info').pipe(catchError(this.handleError));
  }
  createRoleBind(project_id, horizonUid) {
    return this.http.put(
      `/api/keystone/projects/${project_id}/${horizonUid}`,
      {}
    ).pipe(catchError(this.handleError));
  }
  deleteRoleBind(project_id, horizonUid) {
    return this.http.delete(
      `/api/keystone/projects/${project_id}/${horizonUid}`
    ).pipe(catchError(this.handleError));
  }
  /**BLS DB*/
  blsGetCompanyId() {
    return this.http.get('blsapi/companyid').pipe(catchError(this.handleError));
  }
  getUserInfo() {
    return this.http.get('blsapi/user-info').pipe(catchError(this.handleError));
  }
  getSubUsers() {
    return this.http.get('blsapi/sub-users').pipe(catchError(this.handleError));
  }
  createSubUser({ userId, userName, password, project, type }) {
    return this.http.post('blsapi/signup/sub-users', {
      userId,
      userName,
      password,
      project,
      type,
    }).pipe(catchError(this.handleError));
  }
  deleteSubUser(users) {
    // users = ['horizonUid', 'horizonUid', ...]
    return this.http.post('blsapi/signup/sub-users/delete', users).pipe(catchError(this.handleError));
  }
  // 200916 ldh 추가

  updateSubUser(params) {
    return this.http.post('blsapi/signup/sub-users/update', params).pipe(catchError(this.handleError));
  }
  delegateMaster(params) {
    return this.http.post('blsapi/signup/sub-users/delegate', params).pipe(catchError(this.handleError));
  }

  // getPfIp() {
  //   return this.http.get('blsapi/pf-ip');
  // }
  getPfIP(networkId) {
    return this.http.get(`blsapi/pf-ip/${networkId}`).pipe(catchError(this.handleError));
  }

  blsGetResource() {
    return this.http.get('blsapi/resource').pipe(catchError(this.handleError));
  }

  blsSetResource(params) {
    return this.http.post('blsapi/company/resource/', params).pipe(catchError(this.handleError));
  }

  blsDestroyResource(params) {
    return this.http.post('blsapi/company/destroy-resource', params).pipe(catchError(this.handleError));
  }

  // manila
  getNasList() {
    return this.http.get('api/manila/shares').pipe(catchError(this.handleError));
  }

  createNas(newNas) {
    return this.http.post(`api/manila/shares`, { newNas }).pipe(catchError(this.handleError));
  }

  extendNas(shareId, newSize) {
    return this.http.post(`api/manila/shares/${shareId}/extend/`, { newSize }).pipe(catchError(this.handleError));
  }

  deleteNas(shareId) {
    return this.http.delete(`api/manila/shares/${shareId}/`).pipe(catchError(this.handleError));
  }

  getNasAccessRules(shareId) {
    return this.http.get(`api/manila/shares/${shareId}/rules`).pipe(catchError(this.handleError));
  }

  getNas(shareId) {
    return this.http.get(`api/manila/shares/${shareId}/`).pipe(catchError(this.handleError));
  }

  createNasAcceessRule(shareId, params) {
    return this.http.post(`api/manila/shares/${shareId}/create-rule`, {
      params,
    }).pipe(catchError(this.handleError));
  }

  deleteNasAcceessRule(shareId, oldRuleId) {
    return this.http.post(`api/manila/shares/${shareId}/delete-rule`, {
      oldRuleId,
    }).pipe(catchError(this.handleError));
  }

  attachNasInterface(serverId) {
    return this.http.post(`api/nova/${serverId}/attach-nas-interface`, {}).pipe(catchError(this.handleError));
  }

  detachNasInterface(serverId) {
    return this.http.delete(`api/nova/${serverId}/detach-nas-interface`, {}).pipe(catchError(this.handleError));
  }

  getResource(type) {
    return this.http.get(`blsapi/resource/${type}`).pipe(catchError(this.handleError));
  }
  getResourceAll() {
    return this.http.get('blsapi/resourceAll').pipe(catchError(this.handleError));
  }

  getResourceGroupCount(){
    return this.http.get('blsapi/resource-group-count').pipe(catchError(this.handleError));
  }

  getRouters(id) {
    return this.http.get(`api/neutron/routers/${id}`).pipe(catchError(this.handleError));
  }

  createRouters(params) {
    return this.http.post(`api/neutron/routers/`, params).pipe(catchError(this.handleError));
  }

  deleteRouters(routerId){
    return this.http.delete(`api/neutron/routers/${routerId}`).pipe(catchError(this.handleError));
  }

  addExtInterface(params) {
    return this.http.post('api/neutron/routers/add-interface/', params).pipe(catchError(this.handleError));
  }

  updateRouters(id, params) {
    return this.http.put(`api/neutron/routers/${id}`, params).pipe(catchError(this.handleError));
  }

  deleteBLSProject() {
    return this.http.delete('/api/keystone/bls-project/').pipe(catchError(this.handleError));
  }

  deleteBLSProjectId(id) {
    return this.http.delete(`/api/keystone/bls-project/${id}`)
  }

  // Database 서비스
  createCloudDB(newDB) {
    return this.http.post(`blsapi/db-service/cloud-db`, { newDB }).pipe(catchError(this.handleError));
  }
  serverInfAttach(serverId, param) {
    const body = {
      network_id: param,
    };
    return this.http.post(`api/nova/servers/${serverId}/inf-attach`, body).pipe(catchError(this.handleError));
  }
  serverInfDetach(serverId, port_id) {
    const body = {
      port_id: port_id,
    };
    return this.http.post(`api/nova/servers/${serverId}/inf-detach`, body).pipe(catchError(this.handleError));
  }

  /** LB */
  getLoadbalancerList() {
    return this.http.get('api/neutron/loadbalancers').pipe(catchError(this.handleError));
  }

  getLoadbalancer(id) {
    return this.http.get(`api/neutron/loadbalancer/${id}`).pipe(catchError(this.handleError));
  }

  getLoadbalancerId(id) {
    return this.http.get(`api/neutron/loadbalancers/${id}`).pipe(catchError(this.handleError));
  }

  createLoadbalancer(params) {
    return this.http.post('api/neutron/loadbalancer', params).pipe(catchError(this.handleError));
  }
  deleteLoadbalancer(id) {
    return this.http.delete(`api/neutron/loadbalancer/${id}`).pipe(catchError(this.handleError));
  }
  updateLoadbalancer(id, param) {
    return this.http.put(`api/neutron/loadbalancer/${id}`, param).pipe(catchError(this.handleError));
  }
  getLoadbalancerflavorList() {
    return this.http.get('api/neutron/loadbalancer-flavor').pipe(catchError(this.handleError));
  }

  updateListener(id, param) {
    return this.http.put(`api/neutron/loadbalancer-listener/${id}`, param).pipe(catchError(this.handleError));
  }

  deleteListener(id) {
    return this.http.delete(`api/neutron/loadbalancer-listener/${id}`).pipe(catchError(this.handleError));
  }

  createListener(param) {
    return this.http.post(`api/neutron/loadbalancer-listener`, param).pipe(catchError(this.handleError));
  }

  getHealthMonitor(id){
    return this.http.get(`api/neutron/loadbalancer-healthmonitor/${id}`).pipe(catchError(this.handleError));
  }

  updateHealthMonitor(id,param){
    return this.http.put(`api/neutron/loadbalancer-healthmonitor/${id}`,param).pipe(catchError(this.handleError));
  }

  updateMembers(pool_id, param) {
    return this.http.put(`api/neutron/loadbalancer-members/${pool_id}`, param).pipe(catchError(this.handleError));
  }

  updateMember(pool_id, param) {
    return this.http.put(`api/neutron/loadbalancer-member/${pool_id}`, param).pipe(catchError(this.handleError));
  }

  deleteMember(pool_id, member_id){
    return this.http.delete(`api/neutron/loadbalancer-member/${pool_id}`,member_id).pipe(catchError(this.handleError));
  }

  updatePool(pool_id, param) {
    return this.http.put(`api/neutron/loadbalancer-pool/${pool_id}`, param).pipe(catchError(this.handleError));
  }

  getPools(pool_id) {
    return this.http.get(`api/neutron/loadbalancer-pools/${pool_id}`).pipe(catchError(this.handleError));
  }

  getPoolMembers(pool_id) {
    return this.http.get(`api/neutron/loadbalancer-members/${pool_id}`).pipe(catchError(this.handleError));
  }

  getLoadbalancerFull(loadbalancer_id) {
    return this.http.get(`api/neutron/loadbalancer-full/${loadbalancer_id}`).pipe(catchError(this.handleError));
  }

  getLoadbalancerListener(loadbalancer_id){
    return this.http.get(`api/neutron/loadbalancer-listener/${loadbalancer_id}`).pipe(catchError(this.handleError));
  }

  // 백업 서비스
  createBackupServer(data) {
    return this.http.post(`blsapi/backup-service/server`, data).pipe(catchError(this.handleError));
  }
  getBackupServerList() {
    return this.http.get(`blsapi/backup-service/server`).pipe(catchError(this.handleError));
  }

  /** quota */

  getNovaQuota() {
    return this.http.get("api/nova/quota/").pipe(catchError(this.handleError));
  }

  getNovaQuotaEditable() {
    return this.http.get("api/nova/quotaEditable/").pipe(catchError(this.handleError));
  }

  getNeutronQuota() {
    return this.http.get("api/neutron/quota/").pipe(catchError(this.handleError));
  }

  getCinderQuota() {
    return this.http.get("api/cinder/quota/").pipe(catchError(this.handleError));
  }

  /** windows password */
  getWinPassword(server_id, param) {
    return this.http.post(`api/nova/getWinPassword/${server_id}/`, param).pipe()
  }

  /**
   *  20200901 cbkim
   *  rbac 추가
   */

  getRBACList(){
    return this.http.get(`api/rbac/policies`).pipe(catchError(this.handleError));
  }

  createRBAC(param){
    return this.http.post(`api/rbac/policies`,param).pipe(catchError(this.handleError));
  }

  deleteRBAC(rbac_policy_id){
    return this.http.delete(`api/rbac/policies/${rbac_policy_id}`).pipe(catchError(this.handleError));
  }

  getCurrentProject(){
    return this.http.get("blsapi/currentProject").pipe(catchError(this.handleError));
  }

  setRawDataFlavor(){
    return this.http.get("blsapi/updateRawDataFlavor").pipe(catchError(this.handleError));
  }

  logout(){
    //alert('로그인 해주시기 바랍니다.');
    localStorage.clear();
    this.webapi.blsLogout().subscribe((data: {}) => {
      this.router.events.subscribe(e => {
        if(e instanceof NavigationEnd){
          window.location.reload();
        }
      });
      this.router.navigate(["/member/login/"]);
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
      console.log(error);
    } else {
      // console.error(
      //   // 백엔드에서 실패한 것으로 보낸 에러.
      //   `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      // );

      // 백엔드 공통 오류 처리
      switch (error.status) {
        case 500:
          break;
        case 401:          
          this.logout();
          break;
      }
    }
    return throwError(error);
  }
}