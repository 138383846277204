import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-network-snat-manage',
  templateUrl: './dialog-network-snat-manage.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNetworkSnatManageComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNetworkSnatManageComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  selected_network: any = {};
  checkSnatState: boolean = false;

  ngOnInit(): void {

    this.selected_network = this.dialogData.selected_network;

    
    if(this.selected_network.external_gateway_info.enable_snat){
      document.getElementById('radio_snat_type_able').click();
    }else{
      document.getElementById('radio_snat_type_disable').click();
    }
  }


  setSantState(b) {
    this.checkSnatState = b;
  }
  
  
  updateRouterSnatState() {
    // check 되었는지 확인 필요
    this.spinner.show();
    const params = {
      external_gateway_info:
      {
        enable_snat: this.selected_network.external_gateway_info.enable_snat,
        network_id: this.selected_network.external_gateway_info.network_id
      }
    };

    params.external_gateway_info.enable_snat = this.checkSnatState;


    this.osapi
      .updateRouters(this.selected_network.routerId, params)
      .subscribe((data: any) => {


        // ResourceStatusLog 업데이트
        // const params = {
        //   resourceType: 'snat',
        //   value: this.selected_network.external_gateway_info.external_fixed_ips.ip_address,
        //   objectId: data.id,
        // };
        // this.osapi.blsSetResource(params).subscribe((data: any) => {

        // });

        this.spinner.hide();
        this.closeDialogMsg('success');

        if(this.router.url=='/console/network'){
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/network']);
            }
          );
        }else{
          this.router.navigate(['/console/network']);
        }
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }
}
