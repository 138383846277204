<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>베어메탈 목록</h2>
        <div class="lineMap">
            Service  / <span>Baremetal</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">								
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%;">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">프로젝트명</th>
                                        <td>
                                            <div class="input-group">
                                                <select>
                                                    <option value="">-선택-</option>
                                                </select>
                                                <div class="input-group-btn">
                                                    <button class="btn mid orange mg_r4" onclick="fn_modalOpen('create_baremetal');">프로젝트 생성</button>
                                                </div>
                                            </div>
                                        </td>                                        
                                    </tr>
                                    <tr>
                                        <th scope="row">서버</th>
                                        <td>
                                            <a
                                            routerLink="/console/baremetal/create"
                                            routerLinkActive="active"
                                            class="btn mid orange mg_r4"
                                            >베어메탈 생성</a
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>베어메탈 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                </div>
                            </div>
                        </div>									
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox" style="overflow:auto;">
                            <table class="tbl_dtl_tp1 dropmenu">
                                <colgroup>
                                    <col style="width:60px;">
                                    <col style="width:200px;">
                                    <col style="width:200px;">
                                    <col style="width:200px;">
                                    <col style="width:120px;">
                                    <col style="width:140px;">
                                    <col style="width:140px;">
                                    <col style="width:130px;">
                                </colgroup>
                                <thead>
                                    <th scope="col"><input type="checkbox" name="" id=""></th>
                                    <th scope="col">서버명</th>
                                    <th scope="col">이미지명</th>
                                    <th scope="col">서버유형</th>
                                    <th scope="col">상태</th>
                                    <th scope="col">사설IP</th>
                                    <th scope="col">공인IP</th>
                                    <th scope="col">상세</th>
                                </thead>
                                <!-- foreach -->
                                <tbody>																										
                                    <tr>
                                        <td><input type="checkbox" name="" id=""></td>
                                        <td>CentOS-8.2-TEST USERDATA</td>
                                        <td>CentOS-8.2.2004-Cloudinit</td>
                                        <td>Server-c2m2D50</td>
                                        <td><label class="dot green"></label>ACTIVE</td>
                                        <td>10.0.1.126</td>
                                        <td>ip_public</td>
                                        <td><a class="btn sml dGray btn_expand">상세보기</a></td>
                                    </tr>
                                    <tr class="detail">
                                        <td colspan="8">
                                            <div class="result-tbl">
                                                <table class="tbl_dtl_tp2">
                                                    <tbody>
                                                        <tr>
                                                            <th>서버명</th>
                                                            <td>CentOS-8.2-TEST USERDATA</td>
                                                            <th>이미지명</th>
                                                            <td>CentOS-8.2.2004-Cloudinit</td>
                                                        </tr>
                                                        <tr>
                                                            <th>상태</th>
                                                            <td>ACTIVE</td>
                                                            <th>OS</th>
                                                            <td>CentOS 7.3 (64-bit)</td>
                                                        </tr>
                                                        <tr>
                                                            <th>스토리지</th>
                                                            <td>
                                                                <table>
                                                                    <tr>fd866c96-53c9-4643-8f79-52c6edb304c8(50GB): /dev/vda</tr>
                                                                </table>
                                                            </td>
                                                            <th>인증키</th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th>사설IP</th>
                                                            <td>10.0.1.126</td>
                                                            <th>공인IP</th>
                                                            <td>Loading...</td>
                                                        </tr>
                                                        <tr>
                                                            <th>포트 포워딩</th>
                                                            <td>접속IP : (port )</td>
                                                            <th>보안그룹</th>
                                                            <td>default</td>                                                                                                                        
                                                        </tr>
                                                        <tr>
                                                            <th>생성일시</th>
                                                            <td>2020-07-03T09:02:44Z</td>
                                                            <th>변경일시</th>
                                                            <td>2020-07-03T09:02:44Z</td>
                                                        </tr>                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>																										
                                    <tr>
                                        <td><input type="checkbox" name="" id=""></td>
                                        <td>CnetOS 6.8 -TEST</td>
                                        <td>CentOS-6-8-minimal</td>
                                        <td>Server-c12m12D100</td>
                                        <td><label class="dot red"></label>PAUSED</td>
                                        <td>10.0.1.126</td>
                                        <td>ip_public</td>
                                        <td><a class="btn sml dGray btn_expand">상세보기</a></td>
                                    </tr>
                                    <tr class="detail">
                                        <td colspan="8">
                                            <div class="result-tbl">
                                                <table class="tbl_dtl_tp2">
                                                    <tbody>
                                                        <tr>
                                                            <th>서버명</th>
                                                            <td>CentOS-8.2-TEST USERDATA</td>
                                                            <th>이미지명</th>
                                                            <td>CentOS-8.2.2004-Cloudinit</td>
                                                        </tr>
                                                        <tr>
                                                            <th>상태</th>
                                                            <td>ACTIVE</td>
                                                            <th>OS</th>
                                                            <td>CentOS 7.3 (64-bit)</td>
                                                        </tr>
                                                        <tr>
                                                            <th>스토리지</th>
                                                            <td>
                                                                <table>
                                                                    <tr>fd866c96-53c9-4643-8f79-52c6edb304c8(50GB): /dev/vda</tr>
                                                                </table>
                                                            </td>
                                                            <th>인증키</th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th>사설IP</th>
                                                            <td>10.0.1.126</td>
                                                            <th>공인IP</th>
                                                            <td>Loading...</td>
                                                        </tr>
                                                        <tr>
                                                            <th>포트 포워딩</th>
                                                            <td>접속IP : (port )</td>
                                                            <th>보안그룹</th>
                                                            <td>default</td>                                                                                                                        
                                                        </tr>
                                                        <tr>
                                                            <th>생성일시</th>
                                                            <td>2020-07-03T09:02:44Z</td>
                                                            <th>변경일시</th>
                                                            <td>2020-07-03T09:02:44Z</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- //foreach -->
                            </table>
                        </div>
                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a disabled>메뉴1</a>
        <a >메뉴2</a>
        <a >메뉴3</a>
    </div>
</div>


<!-- Modal : 베어메탈 신청 모달 -->
<div id="create_baremetal" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>베어메탈 신청
            <p class="sub-txt">베어메탈 신청 설명입니다. </p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">이름 </th>
                        <td>														
                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명 </th>
                        <td>
                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big blue mg_r4"><i class="ico ico_register_white"></i>생성</a>
            <a href="#close-modal" rel="modal:close" class="btn big dGray"><i class="ico ico_cancel_white"></i>취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->
