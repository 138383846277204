<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>정산내역</h2>
		<div class="lineMap">
			정산내역 /  <span>정산내역</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>정산내역</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" name="" id="search" value="" spellcheck="false" autocomplete="off" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
								<button type="submit" class="btn mid dBlue" (click)="getClaimSearch()"><i class="ico ico_search_white"></i>검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>						
					</div>
					<div class="contArea" style="display:block"> 
						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
								<colgroup>
									<col style="width: 60px;">
									<col style="width: 150px;">
									<col style="width: 100px;">
									<col style="width: 100px;">
									<col style="width: 100px;">
									<col style="width: 100px;">
									<col style="width: 100px;">
									<col style="width: 100px;">
									<col style="width: 80px;">
									<col style="width: 100px;">
									<col style="width: 150px;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">회사명</th>
										<th scope="col">사업자번호</th>
										<th scope="col">청구 월</th>
										<th scope="col">사용 금액</th>
										<th scope="col">할인 금액</th>
										<th scope="col">청구 금액<br>(VAT 포함)</th>
										<th scope="col">입금자명</th>
										<th scope="col">입금 금액</th>
										<th scope="col">상태</th>
                                        <th scope="col">비고</th>
									</tr>
								</thead>
								<tbody>									
									<tr *ngFor="let claim of claimList; index as i" (click)="fn_detailCall(claim)">
										<td>{{10 * curPage + (i + 1)}}</td>
										<td >{{claim.Company.companyName === 'empty company' ?  '개인' : claim.Company.companyName}}</td>
										<td>
											<ng-template [ngIf]="claim.Company.registrationNumber !== null || claim.Company.registrationNumber !== undefined">
												{{claim.Company.registrationNumber}}
											</ng-template>
										</td>
										<td>
											<ng-template [ngIf]="claim.TotalInvoice.totalInvoiceMonth !== null || claim.TotalInvoice.totalInvoiceMonth !== undefined">
												{{claim.TotalInvoice.totalInvoiceMonth}}
											</ng-template>
										</td>
										<td>{{claim.TotalInvoice.rawCost | currency:'KRW'}}</td>
										<td>{{claim.TotalInvoice.realCost | currency:'KRW'}}</td>
										<td>{{claim.TotalInvoice.realCost * 1.1 | currency:'KRW'}}</td>
										<td>{{claim.depositName}}</td>
										<td>{{claim.depositPrice | currency:'KRW'}}</td>
										<td>
											<label [class]="claim.status == '0' ? 'btn tiny red radius' : 'btn tiny blue radius'">
												{{ claim.status == '0' ? '미완료' : '완료'}}
											</label>
										</td>
										<td>{{claim.description}}</td>
									</tr>
								</tbody>
							</table>

							<!-- 페이징 -->
                            <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
						</div>
					</div>
				</div>
				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>

<!-- //Contents -->
<!-- Modal : 정산하기 모달 -->
<div id="claim_confirm" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>정산하기</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
			<form [formGroup]="claimForm">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
					<tr>
						<th><span>청구번호</span></th>
						<td><span>{{claim.claimNumber.value}}</span></td>
					</tr>
					<tr>
						<th><span>회사명</span></th>
						<td><span>{{claim.companyName.value}}</span></td>
					</tr>
					<tr>
						<th><span>사업자번호</span></th>
						<td><span>{{claim.registrationNumber.value}}</span></td>
					</tr>
					<tr>
						<th><span>청구 월</span></th>
						<td><span>{{claim.claimMonth.value}}</span></td>						
					</tr>
					<tr>
						<th><span>청구 금액 (VAT 포함)</span></th>
						<td><span>{{claim.claimPrice.value * 1.1 | currency:'KRW'}}</span></td>
					</tr>
					<tr>
						<th><span>입금자명</span></th>
						<td>
							<input type="text" formControlName="depositName" autocomplete="off" spellcheck="false">
							<div class="error-invalid-feedback" *ngIf="submitted && claim.depositName.errors?.required">
								입금자명은 필수 값입니다.
							</div>
						</td>						
					</tr>
					<tr>
						<th><span>입금금액</span></th>
						<td>
							<input type="number" formControlName="depositPrice" autocomplete="off" spellcheck="false"/>
							<div class="error-invalid-feedback" *ngIf="submitted && claim.depositPrice.errors">
								<div *ngIf="claim.depositPrice.errors.required">입금금액은 필수 값입니다.</div>
								<div *ngIf="claim.depositPrice.errors.pattern">숫자만 입력 가능합니다.</div>
							</div>
						</td>
					</tr>
					<tr>
						<th><span>비고</span></th>
						<td><span><input type="text" formControlName="description" autocomplete="off" spellcheck="false"/></span></td>
					</tr>
				</tbody>
			</table>
			</form>
			</div>
			<div class="btnArea tp1">
				<a class="btn big orange mg_r4" (click)="putClaim()">확인</a>
				<a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn2" (click)="submitted=false">닫기</a>
			</div>		
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->