<style>

.mat-dialog-container {
  padding: 0px;
  border-radius: 8px;
}
</style>

<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>공인 IP</h2>
        <div class="lineMap">
            Network / <span>Public IP</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm" class="frmBox1">
            <!-- section -->
            <section class="sec col12">
                <div class="sec_content">
                    <!-- lyBox -->
                    <div class="lyBox">
                        <div class="title_wrap">
                            <div class="contArea">
                                <div class="tit">
                                    <app-console-projects></app-console-projects>
                                </div>
                                <div class="cont">
                                    <app-console-network-status></app-console-network-status>
                                </div>
                                <div class="contSub">
                                    <button class="btn mid blue mg_r4" (click)="openNewFloatingIpModal()"><i class="ico ico_register_white"></i>공인IP생성</button>
                                    <button class="btn mid ciblue mg_r4" (click)="openConnect()" [disabled]="selected_floatingip.fixed_ip != null">서버 연결</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                    <!-- lyBox -->
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>IP 목록</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                    <input type="search" class="form-control" name="" id="search" value=""
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="filterText"
                                        placeholder="검색어를 입력해주세요">
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                    </div>
                                    <!-- 접기 버튼 -->
                                    <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display:block">
                            <div class="boardBox">
                                <table class="tbl_col_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;">
                                        <col style="width: 23.75%;">
                                        <col style="width: 23.75%;">
                                        <col style="width: 23.75%;">
                                        <col style="width: 23.75%;">
                                        <!-- <col style="width: 100px;"> -->
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                        </th>
                                        <th scope="col">IP 주소</th>
                                        <th scope="col">서버</th>
                                        <th scope="col">설명</th>
                                        <th scope="col">상태</th>
                                        <!-- <th scope="col">연결</th> -->
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of ipList | tableFilter: filterText"
                                            (click)="onClickList(item)" (contextmenu)="onClickList(item)">
                                            <!-- <td><input type="checkbox" (change)="onIPListCheck($event.target.dataset.id)" [attr.data-id]="item.id"></td> -->
                                            <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)" [attr.data-id]="item.id"></td>
                                            <td>{{item.ip}}</td>
                                            <td *ngIf="item.status.toUpperCase() == 'ACTIVE';else elseCase">
                                                <ng-template [ngIf]="item.server==null">로딩 중</ng-template>
                                                <ng-template [ngIf]="item.server!=null">{{item.server.server_name}} ({{item.fixed_ip}})</ng-template>
                                            </td>
                                            <td *ngIf="item.description;else elseCase">{{item.description}}</td>
                                            <ng-template #elseCase>
                                                <td>없음</td>
                                            </ng-template>
                                            <td><label
                                                [class]="item.status == 'ACTIVE'? 'dot green': 'dot red'"></label>
                                                {{item.status}}
                                            </td>
                                            <!-- <td>
                                                <a class="btn sml dGray" (click)="openDisconnect()"
                                                    *ngIf="item.fixed_ip != null;else isnull">연결해제</a>
                                                <ng-template #isnull>
                                                    <a class="btn sml dGray" (click)="openConnect()">
                                                        &nbsp;&nbsp;연&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;결&nbsp;&nbsp;</a>
                                                </ng-template>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1">
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
    <div *ngIf="selected_floatingip == null;else seletedFipNull" id="myDropdown" class="dropdown-content">
        <a>오류보고</a>
    </div>
    <ng-template #seletedFipNull>
        <div id="myDropdown" class="dropdown-content">
            <a (click)="openEditFloatingIpModal()">기본정보 수정</a>
            <ng-template [ngIf]="selected_floatingip.fixed_ip == null">
                <a (click)="openConnect()">서버연결</a>
                <a disabled>연결해제</a>
                <a (click)="openDeleteFloatingIpModal()">반납</a>
            </ng-template>
            <ng-template [ngIf]="selected_floatingip.fixed_ip != null">
                <a disabled>연결</a>
                <a (click)="openDisconnect()">연결해제</a>
                <a (click)="openDeleteFloatingIpModal()">반납</a>
            </ng-template>
        </div>
    </ng-template>
</div>
