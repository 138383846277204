import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-attach',
  templateUrl: './dialog-storage-attach.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageAttachComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageAttachComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  serverList: any=[];
  attach_volume_modal_server="";
  selected_volume;

  ngOnInit(): void {

    this.selected_volume = this.dialogData.selected_volume;
    this.serverList = this.dialogData.serverList;
  }

  attachVolume(server_id,volume_id){  
    if(!server_id){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'오류 알림',
          message:'연결할 서버를 식별할 수 없습니다. 서버를 선택해주세요.'
        }
      });
      return;
    }  
    if(!volume_id){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'연결할 볼륨을 식별할 수 없습니다. 새로고침해주세요.'
        }
      });
      return;
    }  
    this.spinner.show();
    this.osapi.attachVolume(server_id,volume_id).subscribe(
      (data:{}) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
    }, 
    (error) => {

      this.spinner.hide();
      
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
