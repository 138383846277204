<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>크레딧 부여</h2>
		<div class="lineMap">
			청구/정산관리 /  <span>크레딧 부여</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>크레딧 부여</h3>
					</div>
					<div class="contArea" style="display:block">
						<form [formGroup]="creditForm" (ngSubmit)="onSubmit();">
							<div class="boardBox">
								<table class="tbl_row_tp1 pdTp2">
									<colgroup>
										<col style="width:20%;">
										<col />
										<col style="width:105px;">
									</colgroup>
									<tbody>
										<tr class="required">
											<th scope="row">받는사람</th>
											<td>
												<input type="text" [value]="selected_user.userId+'('+ selected_user.name+')'" readonly>
												<input style="display: none;" type="text" formControlName="registrationNumber" readonly>
												<input style="display: none;" type="text" formControlName="registrationNumberSub" readonly>
											</td>
											<td><a href="javascript:void(0);" class="btn mid blue" onClick="fn_modalOpen('creditModal')">주소록</a></td>
										</tr>
										<tr class="required">
											<th scope="row">크레딧</th>
											<td colspan="2">
												<input type="number" formControlName="credit" placeholder="숫자만 입력해주세요."
													[class]="creditChk.credit.invalid && creditChk.credit.dirty ? 'error-invalid' : ''"
												>
												<input type="hidden" formControlName="sign" value=1>
												<div *ngIf="creditChk.credit.dirty">
													<div class="error-invalid-feedback" *ngIf="creditChk.credit.errors?.required">
														크레딧은 필수 값입니다. 숫자만 입력해주세요.
													</div>
												</div>
											</td>
										</tr>
										<tr class="required">
											<th scope="row">비고</th>
											<td colspan="2">
												<textarea rows="7" formControlName="description" maxlength="250" placeholder="내용을 입력해주세요."
													[class]="creditChk.description.invalid && creditChk.description.dirty ? 'error-invalid' : ''"
												></textarea>
												<div *ngIf="creditChk.description.dirty">
													<div class="error-invalid-feedback" *ngIf="creditChk.description.errors?.required">
														내용은 필수 값입니다. 250자 이내로 입력해주세요.
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="btn_wrap tp1">
								<button type="submit" class="btn big orange" [disabled]='!creditForm.valid'>크레딧 지급</button>
							</div>
						</form> 
					</div>
				</div>
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->



<!-- Modal : address 추가모달 -->
<div id="creditModal" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>주소록</h2>
		<div class="btnArea">
			<div class="input-group fl">
				<input type="search" class="form-control" [(ngModel)]="listFilter" id="filterInput" placeholder="검색어를 입력해주세요"/>
			</div>
		</div>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<div class="contArea" style="display:block">
			<table class="tbl_col_tp1">
				<colgroup>
					<col style="width:15%">
					<col style="width:50%">
					<col style="width:45%">
				</colgroup>
				<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col">사용자명</th>
						<th scope="col">회사ID</th>
					</tr>
				</thead>
			</table>
	
			<div class="boardBox" style="overflow:auto; height:300px;">
				<table class="tbl_col_tp1">
					<colgroup>
						<col style="width:15%">
						<col style="width:50%">
						<col style="width:45%">
					</colgroup>
					<tbody>
						<tr *ngFor='let user of userListFilter'>
							<td><input type="checkbox" (click)="clickUserCheck(user,$event.target)" name="userReceiver" ></td>
							<td><span>{{user.name}}</span></td>
							<td class="l"><span>{{user.registrationNumber}}-{{user.registrationNumberSub}}</span></td>
						</tr>
					</tbody>
				</table>				
			</div>
		</div>
		<div class="btnArea tp1">
			<a href="#close-modal" rel="modal:close" id="closeModalBtn" class="btn big orange">닫기</a>
		</div>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->