
    <!-- Modal : 인증키 생성 모달 -->
    <div id="modal_authkey" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
          <h2>
            인증키 생성
            <p class="sub-txt">
              인증키는 VM 서버에 SSH로 접속하는 데 이용됩니다.
            </p>
          </h2>
        </div>
        <!-- //modal_header -->
  
        <!-- modal_content -->
        <div class="modal_content">
          <div class="boardBox">
            <table class="tbl_row_tp1">
              <colgroup>
                <col style="width: 20%;" />
                <col style="width: 80%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">인증키 이름</th>
                  <td>
                    <input type="text" maxlength="20" name="" id="secureNm" placeholder="20자 이내로 작성해주세요. 영문자와 숫자, '-', '_'만 입력 가능합니다."
                      [(ngModel)]="newKeyPairData.name" (change)="onChangeKeypairName()"/>
                      
                      <div *ngIf="flag_validate_name_rule==false" class="invalid-feedback">
                        <div>20자 이내로 작성해주세요. 영문자와 숫자, '-', '_'만 입력 가능합니다.</div>
                      </div>
                      <div *ngIf="flag_validate_name_duplicated==false" class="invalid-feedback">
                        <div>인증키의 이름은 중복해서 사용할 수 없습니다.</div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">설명</th>
                  <td>
                    <textarea name="" id="secureMemo" rows="5" placeholder="255자 이내로 작성해주세요."
                      [(ngModel)]="newKeyPairData.description" maxlength="255"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btnArea tp2">
            <a (click)="createKeypair()" class="btn big ciblue mg_r4" >생성</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
          </div>
        </div>
        <!-- //modal_content -->
      </div>
      <!-- //Modal -->