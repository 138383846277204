import { Component, OnInit, Inject } from '@angular/core';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';

@Component({
  selector: 'app-dialog-objectstorage-info',
  templateUrl: './dialog-objectstorage-info.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogObjectStorageInfoComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private cephapi: CephapiService,
    public dialogRef: MatDialogRef<DialogObjectStorageInfoComponent>

  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  bucket: string;
  object: any;
  info: any = {
    name: '',
    fileLength: 'calculating...',
    folderLength: 'calculating...',
    totalLength: 'calculating...',
    totalSize: 'calculating...',
    transTotalSize: 'calculating...',
    size: 'calculating...',
  };

  ngOnInit(): void {
    this.bucket = this.dialogData.bucket;
    this.object = this.dialogData.object;
    this.getObjectInfo();
  }

  getObjectInfo() {
    try {
      this.spinner.show();
      this.cephapi.getObjectInfo({bucket:this.bucket, object: this.object}).subscribe((data) => {
        this.info = data;
        this.info.totalSize = this.info.totalSize + ' Bytes';
        if(this.info.totalLength >= 9999) {
          this.info.fileLength += '+';
          this.info.folderLength += '+';
          this.info.totalLength += '+';
          this.info.totalSize += '+';
          this.info.transTotalSize += '+';
        }

        if(this.info.totalSize == this.info.transTotalSize) this.info.size = this.info.totalSize;
        else this.info.size = this.info.transTotalSize + ' ( ' + this.info.totalSize + ' )';
        
        if(this.object.type == 'folder') this.info.name = this.object.name + '/';
        
        this.spinner.hide();
      },
      (error) => {
        let msg = "일시적으로 문제가 발생하였습니다. 새로고침해주세요.";
        // console.log(error);
        // if(409 == error.status) msg = "이미 존재하는 버킷 이름입니다.";
        // else if(403 === error.status) msg = "입력하신 버킷명은 규칙에 맞지 않습니다.<br>다시 한번 확인바랍니다.";
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:msg
        }
      });
      });
    } catch (e) {
      this.spinner.hide();
    }
  }

}
