import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any):any;

@Component({
  selector: 'app-admin-send-mail-list',
  templateUrl: './admin-send-mail-list.component.html',
})
export class AdminSendMaillistComponent implements OnInit {

  constructor(
    private webapi: WebApiService,
    private common: AdminNavComponent
  ) { }
  userList: any = [];
  receivers :any
  
  sendForm = new FormGroup({
    receiver: new FormControl('', [
      Validators.required,
    ]),
    subject: new FormControl('', [
      Validators.required,
    ]),
    text: new FormControl('', [
      Validators.required,  
    ]),
  });

  //// List Filter
  _listFilter = '';
  get listFilter(): string {
      return this._listFilter;
  }
  userListFilter:any
  set listFilter(value: string) {
    this._listFilter = value;
    this.userListFilter = this.listFilter ? this.doFilter(this.listFilter) : this.userList;
  }
  doFilter(filterBy: string) {

    return this.userList.filter(userList =>
      userList.name.indexOf(filterBy) !== -1);
  }
  //// List Filter

  ngOnInit(): void {    
    this.getUserList(0);    
    this.listFilter = '';
  }

    //페이징변수
    curPage: any;
    totalCount: any;

     //페이징함수
  getMovePage(e) {
    this.getUserList(e.page);
  }


  getUserList(page) {

    this.curPage = page;
    var searchQueries = '&o=created_date.DESC&l=10&s=' + this.curPage * 10;
    // if (this.searchTitle != '') {
    //   searchQuerys +=
    //     '&title=' + decodeURIComponent(this.searchTitle + '') + '&lk=title';
    // }
    
    this.webapi.getEmailHistoryList(searchQueries).subscribe((data: {}) => {
      this.userList = data['result'];
      this.userListFilter = data['result'];
      this.totalCount = data['total'];
      this.common.getThemeFont();
    });
  }

  fn_send(){
    // receiver: 받는사람 이메일
    // subject: 제목
    // text: 본문

    var receiverGrp = "";
    var receiver=[];

    receiverGrp = this.sendForm.controls.receiver.value;

    receiver=receiverGrp.split(",");

    var msg = "";
    var valChk = true;

    for(var i=0;i<receiver.length;i++){
      var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

      if(!emailVal.test(receiver[i])){
        valChk = false
        msg = receiver[i]+"은 이메일형식이 아닙니다.";
      }
    }

    if(valChk){
      for(var i=0;i<receiver.length;i++){

        this.sendForm.value.receiver = receiver[i];

        this.webapi.sendEmail(this.sendForm.value).subscribe((data: {}) => {});
        if(i==(receiver.length-1))
          alert("전송되었습니다.");
      }
    }else{
      alert("Err : "+msg)
    }
  }

  fn_getAdress(){
    fn_modalOpen('adressModal');
  }

  fn_getAdressReceiver(){
    this.receivers=""

    var chkReceiver = document.getElementsByName('userReceiver')
    for(var i=0;i<chkReceiver.length;i++){
      if((<HTMLInputElement>chkReceiver[i]).checked){
        this.receivers+=(<HTMLInputElement>chkReceiver[i]).value+",";
      }
    }
    this.receivers=this.receivers.substring(0,this.receivers.length-1);

    
    (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
  }

  onSearch(deviceValue){

  }
}
