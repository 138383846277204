import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WebNavigationComponent } from './web-navigation/web-navigation.component';
import { ServiceComponent } from './service/service.component';
import { ServicePriceComponent } from './service-price/service-price.component';
import { PolicyComponent } from './policy/policy.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MainComponent } from './main/main.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { TermEmailComponent } from './termEmail/term-email.component';
import { CustomerCenterComponent } from './customer-center/customer-center.component';
import { FaqListComponent } from './faq-list/faq-list.component';
import { InquiryListComponent } from './inquiry-list/inquiry-list.component';
import { InquiryRegisterComponent } from './inquiry-register/inquiry-register.component';
import { InquiryViewComponent } from './inquiry-view/inquiry-view.component';
import { NoticeListComponent } from './notice-list/notice-list.component';
import { NoticeViewComponent } from './notice-view/notice-view.component';
import { MaterialListComponent } from "./material-list/material-list.component";
import { MaterialViewComponent } from "./material-view/material-view.component";

import { MypageMemberComponent } from './mypage/mypage-member/mypage-member.component';
import { MypageCompanyComponent } from './mypage/mypage-company/mypage-company.component';
import { MypageAccountComponent } from './mypage/mypage-account/mypage-account.component';
import { MypagePaymentComponent } from './mypage/mypage-payment/mypage-payment.component';
import { MypageCreditComponent } from './mypage/mypage-credit/mypage-credit.component';
import { MypageServiceHistoryComponent } from './mypage/mypage-service-history/mypage-service-history.component';
import { MypageWithdrawalComponent } from './mypage/mypage-withdrawal/mypage-withdrawal.component';

import { AdminNavComponent } from './admin/admin-nav/admin-nav.component';

import { AdminUserComponent } from "./admin/admin-users/admin-user/admin-user.component";
import { AdminSubuserComponent } from "./admin/admin-users/admin-subuser/admin-subuser.component";
import { AdminwithdrawalListComponent } from './admin/admin-users/admin-withdrawal-list/admin-withdrawal-list.component';

/**
 *  20201022 cbkim
 * User Log 목록
 */
import {AdminUsersLogComponent} from './admin/admin-users/admin-log/admin-users-log.component';


import { AdminBoardFaqComponent } from "./admin/admin-board/admin-board-faq/admin-board-faq.component";
import { AdminBoardQnaComponent } from "./admin/admin-board/admin-board-qna/admin-board-qna.component";
import { AdminBoardNoticeComponent } from "./admin/admin-board/admin-board-notice/admin-board-notice.component";
import { AdminBoardRefComponent } from "./admin/admin-board/admin-board-ref/admin-board-ref.component";

import { AdminClaimClaimListComponent } from "./admin/admin-claim/admin-claim-claimList/admin-claim-claimList.component";
import { AdminClaimClaimComponent } from "./admin/admin-claim/admin-claim-claim/admin-claim-claim.component";
import { AdminClaimClaimPayComponent } from "./admin/admin-claim/admin-claim-claimPay/admin-claim-claimPay.component";
import { AdminClaimPayBoardComponent } from "./admin/admin-claim/admin-claim-payBoard/admin-claim-payBoard.component";

import { AdminSendMailComponent } from "./admin/admin-send/admin-send-mail/admin-send-mail.component";
import { AdminSendMaillistComponent } from "./admin/admin-send/admin-send-mail-list/admin-send-mail-list.component";
import { AdminSendSmsComponent } from "./admin/admin-send/admin-send-sms/admin-send-sms.component";
import { AdminCreditComponent } from "./admin/admin-claim/admin-claim-credit/admin-claim-credit.component";
import { AdminCreditListComponent } from "./admin/admin-claim/admin-claim-creditlist/admin-claim-creditlist.component";

import { AdminFlavorListComponent } from './admin/admin-flavor/admin-flavor-list/admin-flavor-list.component';
import { AdminFlavorSettingComponent } from './admin/admin-flavor/admin-flavor-setting/admin-flavor-setting.component';
import { AdminImageListComponent } from './admin/admin-image/admin-image-list/admin-image-list.component';
import { AdminImageSettingComponent } from './admin/admin-image/admin-image-setting/admin-image-setting.component';

import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminEquipmentListComponent } from './admin/admin-equipment/admin-equipment-list/admin-equipment-list.component';
import { AdminEquipmentMonitoringComponent } from './admin/admin-equipment/admin-equipment-monitoring/admin-equipment-monitoring.component';
import { AdminServiceDnsComponent } from './admin/admin-service/admin-service-dns/admin-service-dns.component';
import { AdminBackupServerComponent } from './admin/admin-service/admin-backup-server/admin-backup-server.component';
import { AdminBackupDbComponent } from './admin/admin-service/admin-backup-db/admin-backup-db.component';
import { AdminCommercialDbmsComponent } from './admin/admin-service/admin-commercial-dbms/admin-commercial-dbms.component';
import { AdminServiceBaremetalComponent } from './admin/admin-service/admin-service-baremetal/admin-service-baremetal.component';
import { AdminMessageQueueComponent } from './admin/admin-service/admin-message-queue/admin-message-queue.component';
import { AdminSettingComponent } from './admin/admin-setting/admin-setting.component';

import { LoginComponent } from './login/login.component';
import { MemberSignupComponent } from './member-signup/member-signup.component';
import { FindIdComponent } from './find-id/find-id.component';
import { FindPassWordComponent } from './find-password/find-password.component';
import { ResetPasswordByCodeComponent } from './reset-password-by-code/reset-password-by-code.component';

import { LoginAuthGuard, blsAdminAuthGuard,ThemeGuard } from '../auth.guards';
const routes: Routes = [  
  {path: 'web', component: WebNavigationComponent,
    children: [
      {path: '', component: MainComponent},
      {path: 'service', component: ServiceComponent},
      {path: 'servicePrice', component: ServicePriceComponent},
      {path: 'policy', component:PolicyComponent},
      {path: 'privacy', component:PrivacyComponent},
      {path: 'contact-us', component: ContactUsComponent},
      {path: 'customer-center', component: CustomerCenterComponent},
      {path: 'introduction', component: IntroductionComponent},
      {path: 'term-email', component: TermEmailComponent},
      {path: 'faqList', component: FaqListComponent},      
      {path: 'noticeList', component: NoticeListComponent},
      {path: 'noticeView', component: NoticeViewComponent},
      {path: 'materialList', component: MaterialListComponent, canActivate:[LoginAuthGuard]},
      {path: 'materialView', component: MaterialViewComponent, canActivate:[LoginAuthGuard]},
      {path: 'mypage', canActivate: [LoginAuthGuard],
        children:[
          {path: 'member', component: MypageMemberComponent},
          {path: 'company', component: MypageCompanyComponent},
          {path: 'acount', component: MypageAccountComponent},
          {path: 'payment', component: MypagePaymentComponent},
          {path: 'credit', component: MypageCreditComponent},
          {path: 'seviceHistory', component: MypageServiceHistoryComponent},
          {path: 'withdrawal', component: MypageWithdrawalComponent},
          {path: 'inquriyList', component: InquiryListComponent, canActivate: [LoginAuthGuard]},
          {path: 'inquriyRegister', component: InquiryRegisterComponent},
          {path: 'inquriyView', component: InquiryViewComponent}
        ]
      }
    ]
  },
  {path: 'member',
    children: [
      {path: 'signUp', component: MemberSignupComponent },
      {path: 'login', component: LoginComponent},   
      {path: 'find-id', component: FindIdComponent},
      {path: 'find-password', component: FindPassWordComponent},
      {path: 'reset-password-by-code', component: ResetPasswordByCodeComponent},  
    ]
  },
  {path: 'admin', component: AdminNavComponent, canActivate: [blsAdminAuthGuard , LoginAuthGuard,ThemeGuard],
    children: [
      {path: '', component: AdminDashboardComponent ,canActivate:[ThemeGuard]},
      {path: 'user', component: AdminUserComponent,canActivate:[ThemeGuard]},
      {path: 'subuser', component: AdminSubuserComponent,canActivate:[ThemeGuard]},
      {path: 'withdrawal-list', component: AdminwithdrawalListComponent,canActivate:[ThemeGuard]},
      {path: 'user-log', component: AdminUsersLogComponent,canActivate:[ThemeGuard]},

      {path: 'boardFaq', component: AdminBoardFaqComponent,canActivate:[ThemeGuard]},
      {path: 'boardQna', component: AdminBoardQnaComponent,canActivate:[ThemeGuard]},
      {path: 'boardNotice', component: AdminBoardNoticeComponent,canActivate:[ThemeGuard]},
      {path: 'boardRef', component: AdminBoardRefComponent,canActivate:[ThemeGuard]},
      
      {path: 'claimList', component: AdminClaimClaimListComponent,canActivate:[ThemeGuard]},
      {path: 'claim', component: AdminClaimClaimComponent,canActivate:[ThemeGuard]},
      {path: 'claimPay', component: AdminClaimClaimPayComponent,canActivate:[ThemeGuard]},
      {path: 'payBoard', component: AdminClaimPayBoardComponent,canActivate:[ThemeGuard]},

      {path: 'sendMail', component: AdminSendMailComponent,canActivate:[ThemeGuard]},
      {path: 'sendMail_list', component: AdminSendMaillistComponent,canActivate:[ThemeGuard]},
      {path: 'sendSms', component: AdminSendSmsComponent,canActivate:[ThemeGuard]},

      {path: 'credit', component: AdminCreditComponent,canActivate:[ThemeGuard]},
      {path: 'creditlist', component: AdminCreditListComponent,canActivate:[ThemeGuard]},

      {path: 'flavor', component: AdminFlavorListComponent,canActivate:[ThemeGuard]},
      {path: 'flavor/create', component: AdminFlavorSettingComponent,canActivate:[ThemeGuard]},
      {path: 'image', component: AdminImageListComponent,canActivate:[ThemeGuard]},
      {path: 'image/create', component: AdminImageSettingComponent,canActivate:[ThemeGuard]},

      {path: 'equipment', component: AdminEquipmentListComponent,canActivate:[ThemeGuard]},
      {path: 'monitoring', component: AdminEquipmentMonitoringComponent,canActivate:[ThemeGuard]},
      {path: 'dns', component: AdminServiceDnsComponent,canActivate:[ThemeGuard]},
      {path: 'serverbackup', component: AdminBackupServerComponent,canActivate:[ThemeGuard]},
      {path: 'dbbackup', component: AdminBackupDbComponent,canActivate:[ThemeGuard]},
      {path: 'dbms', component: AdminCommercialDbmsComponent,canActivate:[ThemeGuard]},
      {path: 'baremetal', component: AdminServiceBaremetalComponent,canActivate:[ThemeGuard]},
      {path: 'messagequeue', component: AdminMessageQueueComponent,canActivate:[ThemeGuard]},
      {path: 'setting', component: AdminSettingComponent,canActivate:[ThemeGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebRoutingModule { }
