<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>DB 백업 서비스</h2>
        <div class="lineMap">
            Service  / <span>DB Backup</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">								
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%;">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">서비스 신청</th>
                                        <td>
                                            <button class="btn mid orange mg_r4" onclick="fn_modalOpen('serberbak_create')">백업 신청</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>서비스 이용 현황 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                </div>
                                <a href="javascript:void(0)" class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                            </div>
                        </div>									
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <!-- 우클릭 메뉴 사용시 dropmenu class 추가 -->
                            <table class="tbl_col_tp1">
                                <colgroup>
                                    <col style="width:60px;">
                                    <col style="width:200px;">
                                    <col style="width:200px;">
                                    <col style="width:120px;">
                                    <col style="width:120px;">
                                </colgroup>
                                <thead>
                                    <th scope="col"><input type="checkbox" name="" id="checkbox"></th>
                                    <th scope="col">필드1</th>
                                    <th scope="col">필드2</th>
                                    <th scope="col">필드3</th>
                                    <th scope="col">필드4</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input class="mycheckbox" type="checkbox" name="" id=""></td>
                                        <td>필드1</td>
                                        <td>필드2</td>
                                        <td>필드3</td>
                                        <td>필드4</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">        
        <a onclick="fn_modalOpen('msque_edit')">DNS 수정</a>
        <a onclick="fn_modalOpen('msque_delete')">DNS 삭제</a>
    </div>
</div> -->

<!-- Modal : 백업 신청 팝업 모달 -->
<div id="serberbak_create" class="modal">
    <div class="modal_header">
        <h2>백업 신청
            <p class="sub-txt"> | 백업 신청 텍스트 설명입니다. </p>
        </h2>
    </div>
    
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">백업 DB</th>
                        <td>														
                            <select>
                                <option value="">-선택-</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">기본 용량</th>
                        <td>
                            <select required>
                                <option value="">-선택-</option>                                
                                <option value="">100GB</option>
                                <option value="">200GB</option>
                                <option value="">300GB</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">월 서비스 가격</th>
                        <td>
                            <span>50,000원/월(VAT별도)</span>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">결제예상 금액</th>
                        <td>
                            <span>16,650원/월(VAT별도)</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>        
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">신청</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
</div>

<!-- Modal : 백업신청 수정 팝업 모달
<div id="msque_edit" class="modal">
    <div class="modal_header">
        <h2>백업신청 수정
            <p class="sub-txt"> | 백업신청 수정 팝업 텍스트 설명입니다. </p>
        </h2>
    </div>
    
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">레코드명</th>
                        <td>														
                            <input type="text" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">레코드 타입</th>
                        <td>
                            <select required>
                                <option value="">-선택-</option>
                                <option value="">A</option>
                                <option value="">AAA</option>
                                <option value="">CNAME</option>
                                <option value="">MX</option>
                                <option value="">SPF</option>
                                <option value="">PTR</option>
                                <option value="">TXT</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">레코드 값</th>
                        <td>
                            <input type="text" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">추가</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
</div> -->

<!-- <app-console-common-modal (modalMsg)="deleteDns()"></app-console-common-modal> -->