
<!-- Modal : 네트워크 삭제 모달 -->
<div id="modal_projectlist_delete" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        프로젝트 수정
        <p class="sub-txt"> | 프로젝트의 기본 정보를 수정하고 담당자를 지정합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" placeholder="내용을 입력해주세요" maxlength="60" [(ngModel)]="selected_project.resource_data.value" >
              </td>
            </tr>
            <tr>
              <th>설명</th>
              <td>
                <input type="text" placeholder="내용을 입력해주세요" maxlength="255" [(ngModel)]="selected_project.resource_data.description">
              </td>
            </tr>
            <tr>
              <th>담당자</th>
              <td>
                  <select [(ngModel)]="selected_project.resource_data.projectManager">
                      <option value="">담당자를 지정해주세요.</option>
                      <option *ngFor="let user of user_list" [value]="user.userId">
                          {{user.name}} ({{user.userId}})
                      </option>
                  </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="updateProjectData()" class="btn big ciblue mg_r4">수정</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        <!-- <a (click)="closeDialog()" class="btn big red"><i class="ico ico_cancel_white"></i>취소</a> -->
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->