
    <!-- Modal : 보안 그룹 생성 모달 -->
    <div id="security_create" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
          <h2>
            보안 그룹 생성
            <p class="sub-txt">
              보안그룹은 서버 그룹에 대한 네트워크 접근 제어 및 관리를 돕는
              상품입니다. <br />
              최초 생성 시 모든 네트워크 접근을 차단하므로 추가적인 설정이
              필요합니다.
            </p>
          </h2>
        </div>
        <!-- //modal_header -->
  
        <!-- modal_content -->
        <div class="modal_content">
          <div class="boardBox">
            <table class="tbl_row_tp1">
              <colgroup>
                <col style="width: 20%;" />
                <col style="width: 80%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">보안그룹명</th>
                  <td>
                    <input type="text" placeholder="내용을 입력해주세요" minlength="6" maxlength="30"
                      [(ngModel)]="newSecurityGroupData.name" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">설명</th>
                  <td>
                    <textarea name="" placeholder="내용을 입력해주세요." maxlength="255"
                      [(ngModel)]="newSecurityGroupData.description"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btnArea tp2">
            <a (click)="createSecurityGroup()" class="btn big ciblue mg_r4">확인</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
          </div>
        </div>
        <!-- //modal_content -->
      </div>