import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { ConsoleNavComponent } from '../../console/console-nav/console-nav.component';

@Component({
  selector: 'app-mobile-console-storage-list',
  templateUrl: './mobile-console-storage-list.component.html',
  styleUrls: ['./mobile-console-storage-list.component.css'],
  providers: [ConsoleNavComponent]
})
export class MobileConsoleStorageListComponent implements OnInit {
  volumeLists: any = [];
  serverLists: any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private comp: ConsoleNavComponent,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getVolumeList();
  }

  getVolumeList(){
    this.osapi.getVolumeList().subscribe(
      (data:{items:[]}) => {
      this.volumeLists= data.items;
      this._blank = ((this.volumeLists != 0) ? true : false);
      this.getServerList();
    },
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  async getServerList() {
    this.spinner.show();
    this.serverLists = await this.comp.getServerList();
    this.volumeLists.forEach((fip) => {
        this.serverLists.forEach((server) => {
          if(fip.attachments[0] != null) {
            if (fip.attachments[0].server_id == server.server_id) {
              fip.server = server;
            }
          }
        });
    });
    this.spinner.hide();
  }

}
