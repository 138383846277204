import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { CephapiService } from '../../cephapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import { DialogSetService } from '../dialog/dialog-set.service';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';
import { SaveSectionEventHandler } from '@syncfusion/ej2-pdf-export';
import { saveAs } from 'file-saver';

@Component({
  selector:  'app-console-objectstorage-list',
  templateUrl: './console-objectstorage-list.component.html',
  styleUrls: ['./console-objectstorage-list.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsoleObjectStorageListComponent implements OnInit {
  public bucketList: any = [];
  public objectList: any = [];
  // isActived:boolean = false;
  selected_object: any = [];
  public items: ItemModel[] = [
    // {
    //     text: '정책 변경',
    //     id: 'edit_policy'
    // },
    {
        text: '버킷 삭제',
        id: 'delete_bucket'
    }];

  // 검색 텍스트
  filterBucket: string;
  filterObject: string;

  public isJoined: boolean;

  public currentBucket: string = '';
  public delimiter: string = '/';
  public currentPath: any = [];
  public currentPrefix: string = '';
  // public companyDomainId: string = '';

  public chkCount: number = 0;
  public chkObjects: any = [];

  public objectStorageInfo: any = {};
  public currentPathUsage: any = {};

  public isOneFile: boolean = false;

  constructor(
    private cephapi: CephapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private dialogs: DialogSetService,
    public global: GlobalService,
    @Inject(ConsoleNavComponent) private parent: ConsoleNavComponent,
  ) { }

  async ngOnInit() {
    await this.getJoined();

    if(this.isJoined) {
      await this.getBucketList();
      this.currentBucket = this.getFirstBucketName();
      await this.getObjectList(this.currentPrefix);
    }
  }

  async getJoined() {
    if (!this.parent.isLoadedObjectStorageJoined) {
      setTimeout(() => {
        this.getJoined();
      }, 200)
    } else {
      this.isJoined = this.parent.isObjectStorageJoined;
      // this.companyDomainId = this.parent.companyDomainId;
      if(this.isJoined) {
        await this.getBucketList();
        this.currentBucket = this.getFirstBucketName();
        await this.getObjectList(this.currentPrefix);
        await this.getObjectStorageInfo();
      }
    }
  }

  async getObjectStorageInfo() {
    this.objectStorageInfo = await this.cephapi.getInfo().toPromise();
  }

  onBucketMenuSelect(args: MenuEventArgs) {
    let item:any = args.item;
    if(typeof item.parentObj === 'undefined') {
      return;
    }
    let bid = item.parentObj.element.parentNode.parentNode.textContent;
    if(args.item.id === 'edit_policy') {
      // this.openNewBucketModal();
    } else if(args.item.id === 'delete_bucket') {
      this.openDeleteBucketModal(bid);
    }
  }

  openNewBucketModal() {
    this.dialogs.openBucketCreateDialog({});
    this.dialogs.setAfterClosed().subscribe(async (result) => {
      if (result ? result.code == "success" : false) {
        // this.bucketList.push(result.data);
        // this.currentBucket = this.getFirstBucketName();
        await this.getBucketList();
        this.currentBucket = result.data.name;
        this.currentPrefix = '';
        this.currentPath = [];
        await this.getObjectList(this.currentPrefix);
        // this.updateStatus();
      }
    });
  }

  openNewObjectStorageFolderModal() {
    this.dialogs.openObjectStorageFolderCreateDialog({bucket:this.currentBucket, prefix:this.currentPrefix});
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result ? result.code == "success" : false) {
        // console.log(this.currentPath);
        this.getBucketList();
        this.getObjectList(this.currentPrefix);
      }
    });
  }

  async openDeleteBucketModal(bid) {
    // let objList:any = await this.cephapi.getObjects(bid, {
    //   delimiter: this.delimiter,
    //   prefix: ''
    // }).toPromise();

    // if(objList.length > 0) {
    //   alert('하위 오브젝트가 존재하는 버킷은 삭제가 불가능합니다.');
    //   return;
    // }

    this.dialogs.openDefaultDeleteDialog({
      title: "버킷 삭제",
      content: "선택한 버킷(" + bid + ")를 삭제합니다.<br/>버킷에 존재하는 모든 오브젝트가 삭제됩니다.<br/>삭제된 버킷 및 오브젝트는 복구가 불가능합니다."
    });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.deleteBucket(bid);
      }
    })
  }

  openObjectUploadModal() {
    this.dialogs.openObjectStorageUpload({bucket:this.currentBucket, prefix:this.currentPrefix});
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result ? result.code == "success" : false) {
        // console.log(this.currentPath);
        this.getBucketList();
        this.getObjectList(this.currentPrefix);
      }
    });
  }

  async openObjectDeleteModal() {
    // if(this.chkCount > 1) {
    //   alert('하나의 오브젝트만 삭제 가능합니다.');
    //   return;
    // } else 
    if(this.chkCount == 0) {
      alert('삭제 하실 오브젝트를 선택해 주세요.');
      return;
    }

    let folderList = this.chkObjects.filter(o => o.type === 'folder');
    // let isFolderExist = folderList.length > 0 ? true : false;
    let deleteMsg = "";
    // if(this.chkObjects[0].type == 'folder') {
    //   let objList:any = await this.cephapi.getObjects(this.currentBucket, {
    //     delimiter: this.delimiter,
    //     prefix: `${this.chkObjects[0].originName}`
    //   }).toPromise();

    //   if(objList.length > 0) {
    //     alert('하위 오브젝트가 존재하는 폴더는 삭제가 불가능합니다.');
    //     return;
    //   }
    // }
    if(this.chkCount > 1) {
      deleteMsg = `선택한 오브젝트 목록을 삭제합니다.<br/>삭제된 오브젝트는 복구가 불가능합니다.<br/>오브젝트는 최대 10,000개만 삭제 가능합니다.`
    } else {
      deleteMsg = `선택한 오브젝트(${this.chkObjects[0].name})를 삭제합니다.<br/>삭제된 오브젝트는 복구가 불가능합니다.`
    }

    //delete Object
    this.dialogs.openDefaultDeleteDialog({
      title: "오브젝트 삭제",
      content: deleteMsg
    });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.deleteObject();
      }
    })
  }

  openApiEndpointModal() {
    this.dialogs.openApiEndpointDialog(this.objectStorageInfo);
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result ? result.code == "success" : false) {
        // console.log(this.currentPath);
        // this.getObjectList(this.currentPrefix);
      }
    });
  }

  async openCopyMoveObjectModal(type) {
    this.spinner.show();
    let bucketList:any = this.bucketList.map(m => {
      return {
        name: m.name,
        hasChildren: true
      }
    });
    let chkCnt:any = await this.cephapi.getObjectCount({bucket:this.currentBucket, objects:this.chkObjects}).toPromise();
    // console.log(chkCnt);
    if(chkCnt.result) {
      alert('하위 오브젝트 수가 10,000개 미만인 경우에만 사용 가능합니다.');
      this.spinner.hide();
      return;
    }
    this.spinner.hide();
    this.dialogs.openCopyMoveObjectDialog({bucketList,currentBucket:this.currentBucket, objectList:this.chkObjects, type});
    this.dialogs.setAfterClosed().subscribe(async result => {
      if (result ? result.code == "success" : false) {
        // console.log(this.currentPath);
        // this.getObjectList(this.currentPrefix);
        await this.getBucketList();
        await this.getObjectList(this.currentPrefix);
        if(type == 'copy') {
          alert('오브젝트 복사가 완료되었습니다.');
        } else if (type == 'move') {
          alert('오브젝트 이동이 완료되었습니다.');
        }
      }
    });
  }

  async openRenameObjectModal() {
    this.spinner.show();
    let chkCnt:any = await this.cephapi.getObjectCount({bucket:this.currentBucket, objects:this.chkObjects}).toPromise();
    // console.log(chkCnt);
    if(chkCnt.result) {
      alert('하위 오브젝트 수가 10,000개 미만인 경우에만 사용 가능합니다.');
      this.spinner.hide();
      return;
    }
    this.spinner.hide();
    this.dialogs.openRenameObjectDialog({currentBucket:this.currentBucket, object:this.chkObjects[0], prefix:this.currentPrefix, type:'rename'});
    this.dialogs.setAfterClosed().subscribe(async result => {
      if (result ? result.code == "success" : false) {
        // console.log(this.currentPath);
        // this.getObjectList(this.currentPrefix);
        await this.getBucketList();
        await this.getObjectList(this.currentPrefix);
        alert('오브젝트 이름 수정이 완료되었습니다.');
      }
    });
  }

  async openObjectInfoModal() {
    if(this.chkObjects.length != 1) {
      alert('하니의 오브젝트만 선택 가능합니다.');
      return;
    }
    // let chkCnt:any = await this.cephapi.getObjectCount({bucket:this.currentBucket, objects:this.chkObjects}).toPromise();
    // console.log(chkCnt);
    // if(chkCnt.result) {
    //   alert('하위 오브젝트 수가 10,000개 미만인 경우에만 사용 가능합니다.');
    //   this.spinner.hide();
    // }
    
    this.dialogs.openInfoObjectDialog({bucket:this.currentBucket, object:this.chkObjects[0]});
    this.dialogs.setAfterClosed().subscribe(async result => {
      if (result ? result.code == "success" : false) {
        // console.log(this.currentPath);
        // this.getObjectList(this.currentPrefix);
      }
    });
  }

  // async openMoveObjectModal() {
  //   let bucketList:any = this.bucketList.map(m => {
  //     return {
  //       name: m.name,
  //       hasChildren: true
  //     }
  //   });
  //   this.dialogs.openCopyMoveObjectDialog({bucketList,currentBucket:this.currentBucket, objectList:this.chkObjects, type:'move'});
  //   this.dialogs.setAfterClosed().subscribe(async result => {
  //     if (result ? result.code == "success" : false) {
  //       // console.log(this.currentPath);
  //       // this.getObjectList(this.currentPrefix);
  //       await this.getBucketList();
  //       await this.getObjectList(this.currentPrefix);
  //       alert('오브젝트 이동이 완료되었습니다.');
  //     }
  //   });
  // }

  async objectDownload() {
    if(this.chkCount > 1) {
      alert('하나의 오브젝트만 다운로드 가능합니다.');
      return;
    } else if(this.chkCount == 0) {
      alert('다운로드 하실 오브젝트를 선택해 주세요.');
      return;
    }

    // let folderList = this.chkObjects.filter(o => o.type === 'folder');
    if(this.chkObjects[0].type == 'folder') {
      alert('폴더는 다운로드 불가능합니다.');
      return;
    }

    try {
      this.spinner.show();
      await this.cephapi.downloadObject({bucket:this.currentBucket, prefix:this.currentPrefix, object:this.chkObjects[0]}).toPromise()
      .then(data => {
        saveAs(data, this.chkObjects[0].name);
        this.chkObjectinit();
        // this.bucketList = data;
        this.spinner.hide();
      })
      .catch(e => {
        this.spinner.hide();
        // console.log(error);
        // console.log(1);
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      })
    } catch (e) {
    }
    
  }

  onChangeCheckedObject(event, object) {
    // console.log(object);
    let checked = event.target.checked;
    
    if(checked) {
      this.chkCount++;
      this.chkObjects.push(object);
    } else {
      this.chkCount--;
      this.chkObjects.splice(this.chkObjects.indexOf(object), 1);
    }

    if(this.chkCount == 1) {
      if(this.chkObjects[0].type == 'file') {
        this.isOneFile = true;
      } else {
        this.isOneFile = false;
      }
    } else {
      this.isOneFile = false;
    }
    // console.log(this.chkObjects);
  }

  deleteBucket(bid) {
    try {
      this.spinner.show();
      this.cephapi.deleteBucket(bid).subscribe(async (data) => {
        // console.log(data);
        let findBucketIndex = this.bucketList.findIndex(b => b.name == bid);
        this.bucketList.splice(findBucketIndex, 1);
        if(this.bucketList.length == 0) {
          this.currentBucket = ''; 
          this.objectList = [];
        } else {
          this.currentBucket = this.getFirstBucketName();
          await this.getObjectList(this.currentPrefix);
        }
        this.currentPrefix = '';
        
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          // console.log(2);
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
    }
  }

  deleteObject() {
    try {
      this.spinner.show();
      this.cephapi.deleteObject({bucket:this.currentBucket, prefix:this.currentPrefix, objects:this.chkObjects}).subscribe(async (data: any) => {
        if(data.statusCode == "204") {
          alert('존재하지 않는 오브젝트이거나 이미 삭제된 오브젝트 입니다.')
        } else if(data.statusCode == "200") {
          alert('오브젝트가 삭제되었습니다.');
          await this.getBucketList();
          this.currentPrefix = '';
          await this.getObjectList(this.currentPrefix);
        }
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          // console.log(3);
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
    }
  }

  async getBucketList() {
    try {
      this.spinner.show();
      await this.cephapi.getBuckets().toPromise()
      .then(data => {
        // console.log(data);
        this.bucketList = data;
        this.spinner.hide();
      })
      .catch(e => {
        this.spinner.hide();
        // console.log(error);
        // console.log(4);
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      })
    } catch (e) {
    }
  }

  async getObjectList(prefix) {
    try {
      if(this.currentBucket == '') {
        return;
      }
      this.spinner.show();
      await this.cephapi.getObjects(this.currentBucket, {
        delimiter: this.delimiter,
        prefix: prefix
      }).toPromise()
      .then((data:any) => {
        // console.log(data);
        this.objectList = data.data;
        this.chkObjectinit();
        this.currentPathUsage = data.summary;
        // console.log(this.objectList);

        this.spinner.hide();
      })
      .catch(e => {
        this.spinner.hide();
        // console.log(error);
        // console.log(this.currentBucket)
        // console.log(5);
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      })
    } catch (e) {
    }
  }

  async changeFolder(folderName, type) {
    if(type == 'file') {
      return;
    }

    this.currentPath = [];

    let pathArr = folderName.split('/');
    let pathObj:any = {};
    let currentPath: any = [];

    for(let i = 0; i < pathArr.length-1; i++) {
      pathObj = {};
      pathObj.name = pathArr[i];
      pathObj.path = pathArr.slice(0, i+1).join('/') + '/';
      currentPath.push(pathObj);
    }

    this.currentPath = currentPath;
    this.currentPrefix = folderName;
    // console.log(this.currentPrefix);

    // let allchkbox = document.getElementsByName('allchkbox');
    // let chkedbox: HTMLInputElement = <HTMLInputElement>allchkbox[0];
    // chkedbox.checked = false;

    await this.getObjectList(`${this.currentPrefix}`);
  }

  async onClickBucket(event) {
    this.currentBucket = event.srcElement.innerText;
    // console.log(this.currentBucket);

    this.currentPath = [];
    this.currentPrefix = '';

    await this.getObjectList('');
  }

  onClickJoin() {
    this.dialogs.openObjectStorageJoinDialog();
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result ? result.code == "success" : false) {
        // console.log(result);
        this.isJoined = true;
      }
    });
  }

  getFirstBucketName() {
    return (this.bucketList[0]) ? this.bucketList[0].name : '';
  }

  getCurrentBucketSize() {
    // let cur_bucket = this.bucketList.filter(b => b.name === this.currentBucket);
    // console.log(cur_bucket);
    let usage = '';
    // if(cur_bucket.length > 0) {
    if(this.currentPathUsage.currentSize == this.currentPathUsage.transCurrentSize) {
      usage = this.currentPathUsage.currentSize;
    } else {
      usage = this.currentPathUsage.transCurrentSize + " (" + this.currentPathUsage.currentSize + ")";
    }  
    // }

    return usage;
  }

  chkObjectinit() {
    this.chkCount = 0;
    this.chkObjects = [];

    const $_ALLCHKED = document.getElementsByName('allchkbox');
    let allChkBox:HTMLInputElement = <HTMLInputElement>$_ALLCHKED[0];
    allChkBox.checked = false;

    const $_CHKEDLIST = document.getElementsByName('chkbox');
    for(let i = 0; i < $_CHKEDLIST.length; i++ ) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      chkedbox.checked = false;
    }

    this.isOneFile = false;
  }

  onChangeAllCheck(event) {
    let allChkBox = event.target;
    const $_CHKEDLIST = document.getElementsByName('chkbox');

    for(let i = 0; i < $_CHKEDLIST.length; i++ ) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if(allChkBox.checked) {
        chkedbox.checked = true;
        this.chkCount = this.objectList.length;
        this.chkObjects = [];
        this.chkObjects = [...this.objectList];
      } else {
        chkedbox.checked = false;
        this.chkObjects = [];
        this.chkCount = 0;
      }

    }
    this.isOneFile = false;
    // console.log(this.chkObjects);
  }
}
