
<!-- Modal : 공인IP 할당 모달 -->
<div id="modal_publicip" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            POOL 수정
            <p class="sub-txt">로드밸런서 알고리즘 프로토콜/포트 등을 수정 할 수 있습니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->


    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">이름</th>
                        <td>
                            <input type="text" [(ngModel)]="lbpool.name" maxlength="60" placeholder="이름을 입력해주세요" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명 </th>
                        <td>
                            <input type="text" [(ngModel)]="lbpool.description" maxlength="255" placeholder="설명을 입력해주세요">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">로드밸런서 방식 </th>
                        <td>
                            <select (change)="changeAlgorithm($event.target.value)" >
                                <option value="LEAST_CONNECTIONS" [selected]="lbpool.lb_algorithm == 'LEAST_CONNECTIONS'">LEAST_CONNECTIONS</option>
                                <option value="SOURCE_IP" [selected]="lbpool.lb_algorithm == 'SOURCE_IP'">SOURCE_IP</option>
                                <option value="ROUND_ROBIN" [selected]="lbpool.lb_algorithm == 'ROUND_ROBIN'">ROUND_ROBIN</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">세션 지속성</th>
                        <td>
                            <select (change)="changePersistence($event.target.value)" required>
                                <option value="None">-선택-</option>
                                <option value="SOURCE_IP">SOURCE_IP</option>
                                <!-- <option value="">Http cookie</option>
                                <option value="">App cookie</option> -->
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="updatePool()" class="btn big ciblue mg_r4">할당</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->