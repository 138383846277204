<!-- Container -->
<div id="Container" class="mypage">
        <div class="visual tp1">
            <div class="cont_inner">
                <h2>서브 계정</h2>
            </div>
        </div>
        <div>
            <!-- section -->
            <section class="sec sec_1">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <h3>서브 계정</h3>
                        <div class="subTit">
                            올이음은 고객님의 소중한 개인정보를 위해 최선을 다하고 있습니다.<br>
                            개인정보 중 변경된 내용이 있는 경우 아래에서 해당 내용을 수정해주세요.
                        </div>
                    </div>
                    <!-- //sec_header -->
                    
                    <!-- sec_content -->
                    <div class="sec_content">
                        <div class="boardBox">
                            <table class="tbl_col_tp1 pdTp2">
                                <colgroup>
                                    <col style="width:10%">
                                    <col style="width:18%">
                                    <col style="width:18%">
                                    <col style="width:18%">
                                    <col style="width:18%">
                                    <col style="width:18%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">번호</th>
                                        <th scope="col">계정 아이디</th>
                                        <th scope="col">이름</th>
                                        <th scope="col">직급</th>
                                        <th scope="col">생성일자</th>
                                        <th scope="col">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            <a href="#this">k_webDesign</a>
                                        </td>
                                        <td>강경호</td>
                                        <td>팀장</td>
                                        <td>2006-06-16</td>
                                        <td>
                                            <a href="#this" class="btn sml blue" onclick="fn_modalOpen('accountModify');">수정</a>
                                            <a href="#this" class="btn sml red">삭제</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>
                                            <a href="#this">k_webDesign</a>
                                        </td>
                                        <td>강경호</td>
                                        <td>팀장</td>
                                        <td>2006-06-16</td>
                                        <td>
                                            <a href="#this" class="btn sml blue">수정</a>
                                            <a href="#this" class="btn sml red">삭제</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>
                                            <a href="#this">k_webDesign</a>
                                        </td>
                                        <td>강경호</td>
                                        <td>팀장</td>
                                        <td>2006-06-16</td>
                                        <td>
                                            <a href="#this" class="btn sml blue">수정</a>
                                            <a href="#this" class="btn sml red">삭제</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <a href="#this">k_webDesign</a>
                                        </td>
                                        <td>강경호</td>
                                        <td>팀장</td>
                                        <td>2006-06-16</td>
                                        <td>
                                            <a href="#this" class="btn sml blue">수정</a>
                                            <a href="#this" class="btn sml red">삭제</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
    
                            <div class="board_pager_wrap tp1">
                                <div class="board_pager">
                                    <strong class="boardPage">1</strong>
                                    <a href="#this" class="boardPage">2</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //sec_content -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->
        </div>
    </div>
    <!-- //Container -->
    <hr>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>