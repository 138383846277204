/** 
 * 20200813 cbkim
 * 다국어 translate 등을 일괄적용하기위한 extends 클래스
 */

import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams, HttpHeaders,HttpErrorResponse } from '@angular/common/http';



export class BlsCommon{
    /** i18n */
    private translate:TranslateService;
    private translate_file:any={};
    private lang = "ko";
    private configUrl = "assets/i18n/ko.json";
    private http: HttpClient;
    
    constructor(){
    }

    getConfig(){
        return this.http.get(this.configUrl);
    }
}