import { Component, OnInit, ViewChild } from '@angular/core';
import { MonitorService } from '../../monitorapi.service';
import { OsapiService } from '../../osapi.service';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-console-server-monitor',
  templateUrl: './console-server-monitor.component.html',
  styleUrls: ['./console-server-monitor.component.css'],
})
export class ConsoleServerMonitorComponent implements OnInit {

  constructor(
    private api: MonitorService,
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,

  ) { }

  public cpuData: Object[];
  public memData: Object[];
  public diskData: Object[];
  public diskReadData: Object[];
  public diskWriteData: Object[];
  public networkInData: Object[];
  public networkOutData: Object[];

  public primaryXAxis: Object;
  public primaryYAxis: Object;
  public memoryYAxis: Object;
  public cpuYAxis: Object;
  public networkYAxis: Object;
  public diskYAxis: Object;
  public diskRootYAxis: Object;

  public title: string;

  public crosshair: Object;
  public tooltip:Object;
  // public tooltip: Object = {
  //   enable: true, shared: true,
  //   header: "<b>${point.x}</b>", format: "Server load : <b>${point.y}</b>"
  // };
  public memTooltip: Object = {
    enable: true, shared: true,
    header: "<b>${point.x}</b>", format: "<b>${point.y}</b>"
  }

  public chartData: Object[];
  public border: Object;
  public zoom: Object;
  public animation: Object;
  public legend: Object;
  public dataList: any = [];
  public dataOnlineList: any = [];
  public selectedInstance = '불러오는 중...';

  /** 조회 날짜 오늘~  */
  public whereDate: string;

  /** style */
  public margin:Object;
  public marker:Object;

  @ViewChild('cpuChart')
  public cpuChart: ChartComponent;
  @ViewChild('memoryChart')
  public memoryChart: ChartComponent;
  @ViewChild('diskChart')
  public diskChart: ChartComponent;
  @ViewChild('networkChart')
  public networkChart: ChartComponent;


  ngOnInit(): void {
    this.spinner.show();

    this.margin = { left: 40, right: 40, top: 8, bottom: 8 };
    // this.marker = { dataLabel: { visible: true }};
    this.marker = { visible: true, width: 10, height: 10 };
    this.tooltip = { enable: true, shared: true, header:'${point.x}', format: '${point.tooltip}', fadeOutDuration:0 };
    this.crosshair = { enable: true, lineType: 'Vertical' };

    this.zoom = {
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableSelectionZooming: true
    };
    this.animation = { enable: false };
    this.legend = { visible: true, toggleVisibility: false };
    this.border = { width: 2, color: '#eee' };


    this.primaryXAxis = {
      title: 'Time',
      minimum: new Date(new Date().setDate(new Date().getDate() - 1)), maximum: new Date(),
      labelFormat: 'h',
      valueType: 'DateTime',
      //crosshairTooltip: { enable: true },
      intervalType: 'Hours',
      interval: 3,
    };

    this.primaryYAxis = {
      title: 'MB',
    }

    this.networkYAxis = {
      title:'KB',
      labelFormat: '{value}',
      labelStyle: {
        size: '5px'
      }
    }

    this.diskYAxis = {
      labelFormat: '{value}',
      labelStyle: {
        size: '5px'
      }
    }

    this.cpuYAxis = {
      title:'%',
       minimum: 0, maximum: 100, interval: 20,
      labelStyle: {
        size: '5px'
      }
    };

    this.diskRootYAxis = {
      title:'%',
      minimum: 0, maximum: 100, interval: 20,
      labelStyle: {
        size: '5px'
      }
    }

    this.memoryYAxis = {
      title:'GB',
      interval: 1,
      maximum: 8,
      labelStyle: {
        size: '5px'
      }
    }
    this.whereDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);

    // this.primaryXAxis = {
    //   title: '',
    //   minimum: new Date(new Date().setDate(new Date().getDate() - 7)), maximum: new Date(),
    //   labelFormat: 'EEEE',
    //   valueType: 'DateTime',
    //   //crosshairTooltip: { enable: true },
    //   intervalType: 'Days',
    //   interval: 1,
    // };
    
    // this.tooltip = {
    //   enable: true, format: '${point.tooltip}'
    // };

    this.osapi.getCurrentProject().subscribe(
      (projectId) => {
        this.api.getServerMetrics(projectId, this.whereDate).subscribe(
          (data: any) => {
            // console.log(data);
            this.originList = data;

            let tempList =[];
            for(let instance of data){
              if(instance.online){
                tempList.push(instance);
              }
            }
            this.instanceList = tempList;
            
            this.setMetricData(data);
            this.selectedInstance = '전체'
          }
        )
      }
    )
  }

  instanceList:any=[];
  originList:any=[];

  setMetricData(data) {
    this.cpuChart.clearSeries();
    this.memoryChart.clearSeries();
    this.diskChart.clearSeries();
    this.networkChart.clearSeries();

    this.dataList = data;
    this.cpuData = [];
    this.memData = [];
    this.networkInData = [];
    this.networkOutData = [];
    this.diskData = [];

    /** 삭제 된 서버 예외처리 */
    let maxMemory = 0;
    for (let instance of this.dataList) {
      // console.log(instance);
      if (instance.online) {
        if(instance.memorySize/1024 > maxMemory){
          maxMemory = instance.memorySize/1024
        }
        
        this.selectedInstance = instance.display_name;
        /** tooltip */

        let tempData = [];
        for (let cpu of instance.cpu) {
          var dataDate = new Date(cpu[0]);
          var minDate = new Date().setDate(new Date().getDate() - 1);
          if (dataDate.getTime() > minDate) {
            tempData.push({
              x: new Date(cpu[0]),
              y: cpu[1],
              name:this.selectedInstance + ': ' + cpu[1].toFixed(1) + '%'
            })
          }
        }

        if (tempData.length != 0) {
          this.cpuData.push(tempData);
        }

        
        var minDate = new Date().setDate(new Date().getDate() - 1);

        tempData = [];
        for (let memory of instance.memory) {
          var dataDate = new Date(memory[0]);
          if (dataDate.getTime() > minDate) {
            tempData.push({
              x: new Date(memory[0]),
              y: (memory[1] / 1024).toFixed(1),
              name:this.selectedInstance + ': ' + (memory[1] / 1024).toFixed(1) + 'GB'
            })
          }
        }
        if (tempData.length != 0) {
          this.memData.push(tempData);
        }

        tempData = [];
        for (let disk of instance.rootdisk) {
          for (let item of disk.disk_root) {
            tempData.push({
              x: new Date(item[0]),
              y: item[1],
              name:this.selectedInstance + ': ' + item[1].toFixed(1) + '%'
            })
          }
        }
        //if (tempData.length != 0) {
          this.diskData.push(tempData);
        //}

        this.diskReadData = [];
        this.diskWriteData = [];

        for (let disk of instance.disks) {
          for (let item of disk.disk_read) {
            this.diskReadData.push({
              x: new Date(item[0]),
              y: item[1],
              name:this.selectedInstance + ': ' + item[1]
            })
          }
          for (let item of disk.disk_write) {
            this.diskWriteData.push({
              x: new Date(item[0]),
              y: item[1],
              name:this.selectedInstance + ': ' + item[1]
            })
          }
        }


        let tempInData = []; 
        let tempOutData = [];
        for (let network of instance.networks) {
          for (let item of network.incoming) {
            tempInData.push({
              x: new Date(item[0]),
              y: item[1],
              name:this.selectedInstance + ': IN ' + item[1].toFixed(1) + 'KB'
            })
          }
          for (let item of network.outgoing) {
            tempOutData.push({
              x: new Date(item[0]),
              y: item[1],
              name:this.selectedInstance + ': OUT ' + item[1].toFixed(1) + 'KB'
            })
          }
        }

        if (tempInData.length != 0) {
          this.networkInData.push(tempInData);
        }

        if (tempOutData.length != 0) {
          this.networkOutData.push(tempOutData);
        }
      }
    }

    let type = 'Line'
    if(this.dataList.length == 1){
      this.addSeries(this.cpuChart,this.cpuData,'Area');
      this.addSeries(this.memoryChart,this.memData,'Area');
      this.addSeries(this.diskChart,this.diskData,'Area');
      this.addSeries(this.networkChart,this.networkInData,'Line');
      this.addSeries(this.networkChart,this.networkOutData,'Line');  
    }else{
      this.addSeries(this.cpuChart,this.cpuData,'Line');
      this.addSeries(this.memoryChart,this.memData,'Line');
      this.addSeries(this.diskChart,this.diskData,'Line');
      this.addSeries(this.networkChart,this.networkInData,'Line');
      this.addSeries(this.networkChart,this.networkOutData,'Line');
    }

    let memoryInterval = maxMemory / 8;

    this.memoryYAxis = {
      minimum:0,
      interval:memoryInterval,
      labelFormat: '{value}',
      maximum: maxMemory,
      labelStyle: {
        size: '5px'
      }
    };

    // console.log(this.diskData);

    this.spinner.hide();
  }

  addSeries(chart,datas,type){
    let opacity = 0.5;
    if(type == 'Line'){
      opacity = 0.9;
    }
    for(let series of datas){
      chart.addSeries([{
        type:type,
        dataSource: series,
        xName:'x',
        yName:'y',
        tooltipMappingName:'name',
        opacity:opacity
        // marker:this.marker
      }])
    }
  }

  changeChartData(server_id) {
    if(server_id == 0){
      this.setMetricData(this.instanceList);
      return;
    }

    for (let data of this.instanceList) {
      if (data.server_id == server_id) {
        this.setMetricData([data]);
        break;
      }
    }
  }

  changeWhereDate(type) {
    let min =0;
    let lebelFormat = 'h';
    let intervalType = 'Hours'

    if(0 == type){
      min = 1;
      lebelFormat = 'h';
      intervalType = 'Hours'
    }else if (1 == type){
      min = 7;
      lebelFormat = 'EEEE'
      intervalType = 'Days'
    }else if(2 == type){
      min = 365;
      lebelFormat = 'yM'
      intervalType = 'Months'
    }

    this.primaryXAxis = {
      title: '',
      minimum: new Date(new Date().setDate(new Date().getDate() - min)), maximum: new Date(),
      labelFormat: lebelFormat,
      valueType: 'DateTime',
      //crosshairTooltip: { enable: true },
      intervalType: intervalType,
      interval: 1,
    };

    this.whereDate = new Date(new Date().setDate(new Date().getDate() - min)).toISOString().slice(0, 10);
    
    // this.tooltip = {
    //   enable: true, format: '${point.tooltip}'
    // };

    
    this.osapi.getCurrentProject().subscribe(
      (projectId) => {
        this.api.getServerMetrics(projectId, this.whereDate).subscribe(
          (data: any) => {
            // console.log(data);
            this.originList = data;

            let tempList =[];
            for(let instance of data){
              if(instance.online){
                tempList.push(instance);
              }
            }
            this.instanceList = tempList;
            
            this.setMetricData(data);
            this.selectedInstance = '전체'
          }
        )
      }
    )
  }
}
