import { Component, OnInit, Inject } from '@angular/core';
import { BatchapiService } from '../../../batchapi.service';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-batch-create',
  templateUrl: './dialog-batch-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogBatchCreateComponent extends DialogCommon implements OnInit {

  bMin: string = '00';
  bHour: string = '00';
  selecteHourList: Array<string> = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
  selecteMinList: Array<string> = ['00','05','10','15','20','25','30','35','40','45','50','55'];
  userEmail: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private batchapi: BatchapiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogBatchCreateComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
    this.userEmail = this.dialogData.userEmail;
  }


  ngOnInit(): void {
  
  }

  onClickRegiBatch(event) {
    let param = {
      hour: this.bHour,
      min: this.bMin,
      userEmail: this.userEmail
    }
    this.batchapi.regiBatch(param).subscribe((result:any) => {
      this.closeDialogMsg({code:"success",data:result.result});
    });
  }

}
