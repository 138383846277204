

<!-- Modal : 관리자 비밀번호 확인 모달 -->
<div id="admin-password" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>관리자 비밀번호 확인</h2>
  </div>
  <!-- //modal_header -->
  <!-- modal_content -->

  <!-- <div class="modal_content" *ngIf="seleted_floatingip.server != null"> -->
  <div class="modal_content">
    <div class="boardBox" >
      <div #dropareaEl id="droparea" style="width:100%;min-height:150px;line-height: 150px;text-align: center;" (click)="getClipboard(dropareaEl)">
        <span style="padding:8px;" id="dropareamessage"></span>
      </div>
      <div #defaultuploadbox>
        <input type="file" id="defaultupload" name="UploadFiles"/>
      </div>
      <!-- <ejs-uploader id="defaultupload" autoUpload='false' (uploading)='onUploadBegin($event)'></ejs-uploader> -->
    </div>
    <div class="btnArea tp1">
      <!-- <a  (click)="clearAdminPasswordPopup()" class="btn big ciblue mg_r4">닫기</a> -->
      <a  (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>


<!-- //Modal -->