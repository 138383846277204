import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-mobile-console-keypair-list',
  templateUrl: './mobile-console-keypair-list.component.html',
  styleUrls: ['./mobile-console-keypair-list.component.css']
})
export class MobileConsoleKeypairListComponent implements OnInit {
  keypairLists:any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getKeyPairList();
  }

  getKeyPairList() {
    this.spinner.show();
    this.osapi.getKeyPairList().subscribe((data: { items: [] }) => {
      this.keypairLists = data.items;
      this._blank = ((this.keypairLists != 0) ? true : false);
      this.spinner.hide();
    }, 
    (error) => {
      this.spinner.hide();
      this.keypairLists.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
