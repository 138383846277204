import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-common-chkbox',
  templateUrl: './common-chkbox.component.html',
})
export class CommonChkboxComponent implements OnInit {

  $_ALLCHKBOX = document.getElementById('all_chkbox') as HTMLInputElement;

  @Input() chkCnt: number;
  @Input() isChecked: boolean;
  @Input() $_CHKEDLIST: any;

  @Output() chkEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  // 전체선택
  allCheck(e) {
    this.isChecked = e.target.checked;
    this.$_CHKEDLIST = document.getElementsByName('sel_chkbox');
    this.$_CHKEDLIST.forEach(item => item.checked = this.isChecked);
    this.chkEvent.emit({isActived: this.isChecked});
  }

  // 전체선택 여부
  valiAllChk(chkedlist): number {
    let result = 0;
    chkedlist.forEach(item => {
      if (item.checked === true) { result++; }
    });

    return result;

    
    // this.$_ALLCHKBOX.checked = chkedlist.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
  }
}
