import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';


@Component({
  selector: 'app-dialog-default-multiaction',
  templateUrl: './dialog-default-multiaction.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogDefaultMultiactionComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogDefaultMultiactionComponent>,
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  checked_list =[];
  ngOnInit(): void {
    this.checked_list=this.dialogData.checked_list;
  }

}
