import { Component, OnInit, Inject } from '@angular/core';
import { GWService } from '../../../gwapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-gw-auth-create',
  templateUrl: './dialog-gw-auth-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogGwAuthCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private gwapi: GWService,
    public dialogRef: MatDialogRef<DialogGwAuthCreateComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }
  ngOnInit(): void {
  }

  newKeyData= {
    name:"",
    type:"api_key",
  }

  createAuth() {
    this.gwapi
      .createAuth(this.newKeyData.name,this.newKeyData.type)
      .subscribe((data: any) => {
        this.spinner.hide();

        this.closeDialogMsg("success");
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }

}
