import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

import { BasicNetworkData } from "./BasicNetworkData";

@Component({
  selector: 'app-dialog-project-create',
  templateUrl: './dialog-project-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogProjectCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogProjectCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
 
  resource: any = [];

  project_modal_name: string = "";
  project_modal_description: string = "";


  isCreateNetwork: boolean = false;
  isCreateSubnet: boolean = false;

  networkData = new BasicNetworkData();
  subnetData = new BasicNetworkData();

  
  network_cidr_validate = true;
  network_cidr_not_used = true;
  private_network_cidr_validate=true;

  ngOnInit(): void {
    this.project_modal_name = "";
    this.project_modal_description = "";

    
    this.getNetworkResourceList();

  }

  onProjectModalNameChange(name) {
    /**
     * 20200812 cbkim 
     * 유효성 검사 추가
     * 
     * 20200924 cbkim
     * 12자리에서 -> 14자리로 변경
     */

    this.project_modal_name = name;
  }

  onProjectModalDescChange(desc) {
    this.project_modal_description = desc;
  }
  
  
  createProject() {
    if (this.project_modal_name == "" || this.project_modal_name == null ) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "프로젝트 이름을 입력해주세요."
        }
      });
      return;
    }
  
    if(this.isCreateNetwork){
      if (!this.network_cidr_validate || this.networkData.cidr == null) {
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'warning',
            title: '경고',
            message: '유효하지 않은 CIDR입니다.'
          }
        });
        return;
      }
      if ( this.networkData.name =="net_str" || this.networkData.name =="net_ext" || this.networkData.name =="net_pri") {
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'warning',
            title: '경고',
            message: "'net_str', 'net_ext', 'net_pri'는 사용불가능한 이름입니다."
          }
        });
        return;
      }
    }
    
    if(this.isCreateSubnet){
      if (!this.subnet_cidr_validate || this.subnetData.cidr == null) {
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'warning',
            title: '경고',
            message: '유효하지 않은 CIDR입니다.'
          }
        });
        return;
      }
    }
    
    this.spinner.show();




    var param: any = {}

    param.isCreateNetwork = this.isCreateNetwork;
    param.isCreateSubnet = this.isCreateSubnet;
    param.project = {
      name: this.project_modal_name,
      description: this.project_modal_description
    }

    if (this.isCreateNetwork) {
      param.network = {
        name: this.networkData.name,
        cidr: this.networkData.cidr
      }
    }

    if (this.isCreateSubnet) {
      param.subnet = {
        name: this.subnetData.name,
        cidr: this.subnetData.cidr
      }
    }

    try {
      this.osapi.createProjectParam(param).subscribe(
        (data) => {

          if (data != null) {
            this.spinner.hide();
            this.closeDialogMsg("success");

            if(this.router.url=='/console/project'){
              this.router.navigateByUrl('/console', {
                skipLocationChange: true,
              }).then(
                () => {
                  this.router.navigate(['/console/project']);
                }
              );
            }else{
              this.router.navigate(['/console/project']);
            }

            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'success',
                title: '프로젝트 생성',
                message: `프로젝트 ${this.project_modal_name} 생성이 완료되었습니다.`
              }
            });
          }
        }
      ), (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '프로젝트 생성 중 오류가 발생했습니다. 다시 시도해주세요.'
          }
        });
      }
    } catch (e) {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '프로젝트 생성 중 오류가 발생했습니다. 다시 시도해주세요.'
        }
      });
    }

    // try {
    //   this.osapi
    //     .createProject(this.project_modal_name, this.project_modal_description)
    //     .subscribe((data) => {

    //       if (data == null) {

    //         throw 'osapi.createProject Fail';
    //       }
    //       this.projectList.push(data);
    //       this.spinner.hide();
    //       //
    //     }, 
    //     (error) => {

    //       this.spinner.hide();
    //       this.frontapi.changeMessage({
    //     type:'toast',
    //     body:{
    //       type:'error',
    //       title:'오류 알림',
    //       message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
    //     }
    //   });
    //     });
    // } catch (err) {
    //   this.spinner.hide();

    //   alert(err.error);

    // }
  }

  
  getNetworkResourceList() {

    this.osapi.getResourceAll().subscribe(
      (data: any[]) => {
        this.resource = data;

      },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  
  setNetworkState(state) {
    this.isCreateNetwork = state;
  }

  chageNetworkName(name) {
    /**
     * 영문 숫자 _ 만 허용
     */
    var reg = /^[a-zA-Z0-9_\-+]*$/;
    if (reg.test(this.networkData.name)) {

    } else {
      this.networkData.name = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '영문자, _(언더바), -(하이픈) 만 사용 가능합니다.'
        }
      });

    } 
  }

  chageNetworkCidr(cidr) {

    this.subnetData = new BasicNetworkData();
    //CIDR 체크  masking 비트가 포함된 IPv4 주소 문자열 true
    var hard_cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
    if (hard_cidr_filter.test(cidr)) {
      this.network_cidr_validate = true;
    } else {

      this.network_cidr_validate = false;
      this.network_cidr_not_used = true;
      this.private_network_cidr_validate = true;
      return;
    }
    if (this.isAlreadyUsedNetworkCidr(cidr)) {

      this.network_cidr_validate = true;
      this.network_cidr_not_used = false;
      this.private_network_cidr_validate = true;
    } else {
      this.network_cidr_not_used = true;
    }
    this.private_network_cidr_validate = this.validPrivateNetworkCidr(this.networkData.cidr);
  }

  validPrivateNetworkCidr(cidr) {
    var ip192 = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}/;
    var ip172 = /(172)\.(1[6-9]|2[0-9]|3[0-2])(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}/;
    var ip10 = /(10)(\.([2]([0-5][0-5]|[01234][6-9])|[1][0-9][0-9]|[1-9][0-9]|[0-9])){3}/;

    const ip_addr = cidr.split('/')[0];
    const masking_bit = cidr.split('/')[1];
    if (ip192.test(ip_addr)) {
      if (parseInt(masking_bit) < 16 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }
    else if (ip172.test(ip_addr)) {
      if (parseInt(masking_bit) < 12 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }
    else if (ip10.test(ip_addr)) {
      if (parseInt(masking_bit) < 8 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }

  setSubnetState(state) {
    this.isCreateSubnet = state;
  }

  chageSubnetName(name) {
    /**
     * 영문 숫자 _ 만 허용
     */
    var reg = /^[a-zA-Z0-9_\-+]*$/;
    if (reg.test(this.subnetData.name)) {

    } else {
      this.subnetData.name = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '영문자, _(언더바), -(하이픈) 만 사용 가능합니다.'
        }
      });

    } 

  }

  subnet_cidr_validate = true;
  subnet_cidr_is_include = true;
  subnet_cidr_not_used = true;
  
  chageSubnetCidr(cidr) {

    //CIDR 체크  masking 비트가 포함된 IPv4 주소 문자열 true
    var hard_cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/


    if (hard_cidr_filter.test(cidr) == false) {
      this.subnet_cidr_validate = false;
      this.subnet_cidr_is_include = true;
      this.subnet_cidr_not_used = true;
      return;
    } else {
      this.subnet_cidr_validate = true;
    }

    if (!this.isIncludedCidr(this.networkData.cidr, cidr)) {
      this.subnet_cidr_is_include = false;
      this.subnet_cidr_not_used = true;
      return;
    } else {
      this.subnet_cidr_is_include = true;
    }
    if (this.isAlreadyUsedSubnetCidr(cidr)) {
      this.subnet_cidr_not_used = false;
      return;
    } else {
      this.subnet_cidr_not_used = true;
    }
    // TODO 임동현 서브넷 게이트웨이 확인
    // this.subnetData.gateway_ip = this.getGatewayIP(cidr);

  }
  
  validate_false_by_blsnet=false;
  isAlreadyUsedNetworkCidr(new_cidr) {
    //리소스 테이블에서 해당 유저가 사용중인 네트워크 CIDR 을 불러와 중복 체크 수행
    let chk_flag = false;
    const bls_network_list=[
      {addr:"10.21.1.0/24"},
      {addr:"10.250.0.0/16"},
      {addr:"10.251.0.0/16"},
      {addr:"10.252.0.0/16"},
      {addr:"10.253.0.0/16"},
    ];
    for(let blsnet of bls_network_list){
      if (!this.compareCidr(new_cidr, blsnet.addr)) {
        chk_flag = true;
        this.validate_false_by_blsnet=true;
      }
    }

    for (let rsc of this.resource) {
      if (rsc.resourceType == "network") {

        if (!this.compareCidr(new_cidr, rsc.value)) {
          chk_flag = true;
        }
      }
    }
    return chk_flag;
  }

  isAlreadyUsedSubnetCidr(new_cidr) {
    //리소스 테이블에서 해당 유저가 사용중인 서브넷 CIDR 을 불러와 중복 체크 수행
    let chk_flag = false;

    for (let rsc of this.resource) {
      if (rsc.resourceType == "subnet") {

        if (!this.compareCidr(new_cidr, rsc.description)) {
          chk_flag = true;
        }
      }
    }
    return chk_flag;
  }

  compareCidr(cidr, cidr2) {
    var split_masking_bit = cidr.split('/');
    var split_masking_bit2 = cidr2.split('/');
    var splited = split_masking_bit[0].split('.');
    var splited2 = split_masking_bit2[0].split('.');

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    let original_bit2 = '';
    for (var i of splited2) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit2 += bit;
    }

    let chk_flag = false;
    for (var i: any = 0; i < split_masking_bit[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag = true;
    }
    let chk_flag2 = false;
    for (var i: any = 0; i < split_masking_bit2[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag2 = true;
    }
    return chk_flag && chk_flag2;
  }
  isIncludedCidr(network_cidr, subnet_cidr) {
    var split_masking_bit = network_cidr.split('/');
    var split_masking_bit2 = subnet_cidr.split('/');
    var splited = split_masking_bit[0].split('.');
    var splited2 = split_masking_bit2[0].split('.');

    if (split_masking_bit[1] > split_masking_bit2[1]) {
      return false
    }
    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    let original_bit2 = '';
    for (var i of splited2) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit2 += bit;
    }

    let chk_flag = true;
    for (var i: any = 0; i < split_masking_bit[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag = false;
    }
    return chk_flag;
  }
  getGatewayIP(cidr) {
    var split_masking_bit = cidr.split('/');
    var splited = split_masking_bit[0].split('.');

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    const gateway_ip_bit = original_bit.substring(0, original_bit.length - 1) + "1";
    const gateway_ip_bit_1 = gateway_ip_bit.substring(0, 8);
    const gateway_ip_bit_2 = gateway_ip_bit.substring(8, 16);
    const gateway_ip_bit_3 = gateway_ip_bit.substring(16, 24);
    const gateway_ip_bit_4 = gateway_ip_bit.substring(24, 32);

    const gateway_ip = parseInt(gateway_ip_bit_1, 2) + "." + parseInt(gateway_ip_bit_2, 2) + "." + parseInt(gateway_ip_bit_3, 2) + "." + parseInt(gateway_ip_bit_4, 2);

    var filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    if (filter.test(gateway_ip) == false) {
      console.error("get gateway ip fail");
      return null;
    }
    return gateway_ip;
  }
  
}
