

<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>게시판관리</h2>
		<div class="lineMap">
			게시판관리 /  <span>자료실</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<div class="lyBox">								
					<div class="contArea" style="display:block"> 
						<div class="boardBox">
							<table class="tbl_row_tp1">
								<colgroup>
									<col style="width:20%">
									<col style="width:80%">
								</colgroup>
								<tbody>                                    
									<tr>
										<th scope="row">자료파일 생성</th>
										<td>
											<a class="btn mid orange mg_r4" onclick="fn_modalOpen('material_insert')">자료파일 생성</a>
										</td>
									</tr>                                                
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>자료실</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" (keydown.enter)="fn_materialSearch($event.target.value)" [value]="searchTitle" (change)="onSearchTitleChange($event.target.value)" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid dBlue" (click)="fn_materialSearch(searchTitle)"><i class="ico ico_search_white"></i>검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block"> 						
						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2 dropmenu">
								<colgroup>
									<col style="width:5%">
									<col style="width:20%">
									<col style="width:60%">
									<col style="width:15%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">카테고리</th>
										<th scope="col">제 목</th>
										<th scope="col">등록일</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let material of materialList; index as i;" (contextmenu)="onClickList(material)" (click)="getMaterialDetail(material)">
										<td>{{10*curPage+i+1}}</td>
										<td>{{ material.category }}</td>
										<td style="text-align: left;">
											<a>{{ material.title }}</a>
										</td>
										<td >{{ material.createdDate.substring(0, 10) }}</td>
									</tr>
								</tbody>
							</table>

							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
	
						</div>
					</div>
				</div>
				<!-- //lyBox -->

				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->
<div class="dropdown">
	<div id="myDropdown" class="dropdown-content">
		<a onclick="fn_modalMsg('삭제하시겠습니까?')">자료파일 삭제</a>
	</div>
</div>
<!-- Modal : 자료실 추가모달 -->
<div id="material_insert" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>자료파일 생성</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<form [formGroup]="materialForm" (ngSubmit)="onSubmit(fileInput.files)">
			<input type="hidden" formControlName="boardId" value="3" >
			<div class="boardBox">
				<table class="tbl_row_tp1">
					<colgroup>
						<col style="width:30%">
						<col style="width:70%">
					</colgroup>
					<tbody>
						<tr class="required">
							<th><span>카테고리</span></th>
							<td>
								<select formControlName="category" >
									<option value="요금" selected>요금</option>
									<option value="이벤트">이벤트</option>
									<option value="사이트">사이트</option>
								</select>
							</td>
						</tr>
						<tr class="required">
							<th><span>제목</span></th>
							<td><input type="text" formControlName="title" placeholder="제목을 입력하세요."></td>
						</tr>
						<tr class="required">
							<th><span>내용</span></th>
							<td >
								<textarea rows="5" formControlName="contents" placeholder="내용을 입력하세요."></textarea>
							</td>
						</tr>
						<tr class="required">
							<th><span>첨부파일</span></th>
							<td>
								<input type="file" class="input-file" id="input-file" name="" #fileInput>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="btnArea tp1">
				<button  type="submit" class="btn big orange mg_r4" [disabled]='!materialForm.valid'>저장</button>
				<a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn">닫기</a>
			</div>
		</form>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->


<!-- Modal : 자료실 수정모달 -->
<div id="material_detail" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>자료파일 수정</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
					<tr class="required">
						<th><span>카테고리</span></th>
						<td>
							<select [value]="selected_material.category" (change)="onCategoryChange($event.target.value)">
								<option value="요금" selected>요금</option>
								<option value="이벤트">이벤트</option>
								<option value="사이트">사이트</option>
							</select>
						</td>
					</tr>
                    <tr class="required">
						<th><span>제목</span></th>
						<td><input type="text" [value]="selected_material.title" maxlength="60" (change)="onTitleChange($event.target.value)"></td>
                    </tr>
                    <tr class="required">
						<th><span>내용</span></th>
						<td >
							<textarea rows="5" maxlength="250" (change)="onContentsChange($event.target.value)">{{selected_material.contents}}</textarea>
						</td>
					</tr>

					<tr class="required">
						<th><span>첨부파일</span></th>
						<td *ngIf="selected_material.originalFileName">
							<a class="btn sml dGray" href="javascript:void(0);" (click)="downloadFile()">다운로드</a>
							{{selected_material.originalFileName}}
						</td>
						<td *ngIf="selected_material.attachedFilePath ==null || selected_material.attachedFilePath ==''">
							<input type="file" class="input-file" id="input-file" name="" (change)="onFileChanged($event)">
						</td>
					</tr>
					<tr *ngIf="selected_material.attachedFilePath !=null && selected_material.attachedFilePath !=''">
						<th>첨부파일 변경</th>
						<td>
							<input type="file" class="input-file" id="input-file" name="" (change)="onFileChanged($event)">
						</td>
					</tr>

                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
			<a class="btn big orange mg_r4" (click)="updateMaterial()">수정</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn2">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->
<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>삭제확인</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<div class="msg"></div>
		<div class="btnArea">
				<a class="btn big orange mg_r4" href="#close-modal" (click)="deleteMaterial()"  rel="modal:close">삭제</a>
			<a class="btn big orange" href="#close-modal" rel="modal:close" >취소</a>
		</div>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal --> 
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>    