import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import {HealthMonitorData} from '../../console-load-balancer-setting/HealthMonitorData';


@Component({
  selector: 'app-dialog-loadbalancer-monitor-update',
  templateUrl: './dialog-loadbalancer-monitor-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogLoadbalancerMonitorUpdateComponent extends DialogCommon implements OnInit {
  selected_loadbalancer;
  lbmonitor = new HealthMonitorData();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogLoadbalancerMonitorUpdateComponent>,
    ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }

  ngOnInit(): void {
    this.selected_loadbalancer = this.dialogData.selected_loadbalancer;

    this.lbmonitor.type = this.selected_loadbalancer.healthmonitor.type;
    this.lbmonitor.timeout = this.selected_loadbalancer.healthmonitor.timeout;
    this.lbmonitor.delay = this.selected_loadbalancer.healthmonitor.delay;
    this.lbmonitor.url_path = this.selected_loadbalancer.healthmonitor.url_path;
    this.lbmonitor.expected_codes = this.selected_loadbalancer.healthmonitor.expected_codes;
    this.lbmonitor.max_retries = this.selected_loadbalancer.healthmonitor.max_retries;
    this.lbmonitor.max_retries_down = this.selected_loadbalancer.healthmonitor.max_retries_down;
    this.lbmonitor.http_method = this.selected_loadbalancer.healthmonitor.http_method;
  }

  updateMonitor(){
    var monitors = JSON.parse(JSON.stringify(this.lbmonitor));
    
    var id = this.selected_loadbalancer.healthmonitor.id;
    monitors.id = id;
    this.osapi.updateHealthMonitor(id,{monitor:monitors}).subscribe(
      (data)=>{

        this.closeDialogMsg("success");
      }
    )
  }
}