<table style="margin-bottom:10px;">
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">인스턴스</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{serverList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <th></th>
                    <th>TOTAL</th>
                    <th>:</th>
                    <th>{{serverList.length}}</th>
                    <tr>
                        <td><label class="dot green"></label></td>
                        <td>ACTIVE</td>
                        <td>:</td>
                        <td>{{serverActive}}</td>
                    </tr>
                    <tr>
                        <td><label class="dot red"></label></td>
                        <td>STOP</td>
                        <td>:</td>
                        <td>{{serverList.length-serverActive}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">이미지</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{imageList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <th></th>
                    <th>TOTAL</th>
                    <th>:</th>
                    <th>{{imageList.length}}</th>
                    <tr>
                        <td><label class="dot green"></label></td>
                        <td>ACTIVE</td>
                        <td>:</td>
                        <td>{{imageActive}}</td>
                    </tr>
                    <tr>
                        <td><label class="dot red"></label></td>
                        <td>STOP</td>
                        <td>:</td>
                        <td>{{imageList.length-imageActive}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">인증키</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{keypairList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <th></th>
                    <th>TOTAL</th>
                    <th>:</th>
                    <th>{{keypairList.length}}</th>
                    <!-- <tr *ngFor="let key of keypairList">
                        <td colspan="4">
                        {{key.keypair.name}}
                        </td>
                    </tr> -->
                </table>
            </div>
        </div>
    </div>
</table>