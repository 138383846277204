<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>이미지</h2>
        <div class="lineMap">
            Server / <span>Image</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- <form id="frm"> -->
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="title_wrap">
                        <div class="contArea">
                            <div class="tit">
                                <app-console-projects></app-console-projects>
                            </div>
                            <div class="cont">
                                <app-console-server-status></app-console-server-status>
                            </div>
                            <div class="contSub">
                                <button class="btn mid blue mg_r4" (click)="openCreateImageModal()"><i class="ico ico_register_white"></i>서버 이미지 생성</button>
                                <!-- <button class="btn mid red mg_r4" [disabled]="!isActived" (click)="openDeleteImageModal()"><i class="ico ico_cancel_white"></i>이미지 삭제</button> -->
                                <button class="btn mid ciblue mg_r4" [disabled]="!isActived||selected_image.status.toUpperCase()!='ACTIVE'" (click)="restoreImage()"><i class="ico ico_register_white"></i>이미지 복구</button>
                                <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openModifyImageModal()"><i class="ico ico_register_white"></i>기본정보 수정</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- lyBox -->
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>서버 이미지 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" id="search" value="" [(ngModel)]="filterText" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                </div>
                                <!-- 접기 버튼 -->
                                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                            </div>
                        </div>
                    </div>
                    <div class="contArea" style="display:block">
                        <div class="boardBox">
                                <mat-accordion>
                                <table class="tbl_dtl_tp1 dropmenu">
                                        <colgroup>
                                            <col style="width: 5%;">
                                            <col style="width: 19%;">
                                            <col style="width: 19%;">
                                            <col style="width: 19%;">
                                            <col style="width: 19%;">
                                            <col style="width: 19%;">
                                        </colgroup>
                                        <thead>
                                            <th scope="col">
                                              <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                            </th>
                                            <th scope="col">이미지 이름</th>
                                            <th scope="col">설명</th>
                                            <th scope="col">크기(GB)</th>
                                            <th scope="col">상태</th>
                                            <!-- <th scope="col">크기</th> -->
                                            <th scope="col">생성일</th>
                                            <!-- <th scope="col">상세</th> -->
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let image of imageList | tableFilter: filterText" [attr.data-id]="image.image_id" (contextmenu)="onClickList(image)">
                                                    <td colspan="7" style="padding:0;">
                                                            
                                                                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="auth_theme">
                                                                    <mat-expansion-panel-header>
                                                                        <table class="  dropmenu">
                                                                            <colgroup>
                                                                                <col style="width: 5%;">
                                                                                <col style="width: 19%;">
                                                                                <col style="width: 19%;">
                                                                                <col style="width: 19%;">
                                                                                <col style="width: 19%;">
                                                                                <col style="width: 19%;">
                                                                            </colgroup>
                                                                            <tr class="btn_expand">
                                                                            <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck(image)" [attr.data-id]="image.image_id"></td>
                                                                            <td>{{image.name}}</td>
                                                                            <!-- <td *ngIf="image.properties.OS != null; else osNull;">{{image.properties.OS}}</td>
                                                                            <ng-template #osNull>
                                                                                <td>없음</td>
                                                                            </ng-template> -->
                                                                            <td *ngIf="image.description != ''; else osNull">{{image.description}}</td>
                                                                            <ng-template #osNull>
                                                                                <td>없음</td>
                                                                            </ng-template>
                                                                            <td>{{image.size}}</td>
                                                                            <td><label [class]="image.status == 'active' ? 'dot green' : 'dot red'"></label>{{image.status | uppercase}}</td>
                                                                            <!-- <td>{{(image.size/1024/1024).toFixed(2)}} MB</td> -->
                                                                            <td>{{image.created_at.substring(0, 10)}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </mat-expansion-panel-header>
                                                                <div class="result-tbl" class="auth_theme">
                                                                    <table class="tbl_dtl_tp2">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>이미지 이름</th>
                                                                                <td>{{image.name}}</td>
                                                                                <th>상태</th>
                                                                                <td>{{image.status | uppercase}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>크기</th>
                                                                                <td>{{image.size}}</td>
                                                                                <th>타입</th>
                                                                                <td>{{image.disk_format | uppercase}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>생성일시</th>
                                                                                <td>{{image.updated_at.substring(0, 10)}}</td>
                                                                                <th>수정일시</th>
                                                                                <td>{{image.updated_at.substring(0, 10)}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                            <th>설명</th>
                                                                            <td colspan="3">{{image.description}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </mat-expansion-panel>
                                                    
                                                </td>
                                            </tr>
                                            
                                </tbody>
                            </table>
                        </mat-accordion>
                        </div>
                        <!-- <div class="btn_wrap tp1"></div> -->
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->

        <!-- </form> -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
    <div id="myDropdown" class="dropdown-content">
        <!-- <a onclick="fn_modalOpen('image_create')">이미지 생성</a> -->
        <ng-template [ngIf]="selected_image">
            <ng-template [ngIf]="selected_image.status && selected_image.status.toUpperCase()!='ACTIVE'">
                <a disabled>이미지 복구</a>
            </ng-template>
            <ng-template [ngIf]="selected_image.status && selected_image.status.toUpperCase()=='ACTIVE'">
                <a (click)="restoreImage()">이미지 복구</a>
            </ng-template>
        </ng-template>
        <a  (click)="openModifyImageModal()">기본정보 수정</a>
        <a (click)="openDeleteImageModal()">이미지 삭제</a>
        <!-- <a >이미지 공유</a> -->
    </div>
</div>
