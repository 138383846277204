import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OsapiService } from '../../osapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';

@Component({
  selector: 'app-console-db-bls',
  templateUrl: './console-db-bls.component.html',
})
export class ConsoleDbBlsComponent implements OnInit {
  engineVersions: any = [];
  newDB: any = {
    type: '',
    version: '',
    dbName: '',
    dbUsername: '',
    accessIP: '',
    password1: '',
    password2: '',
  };
  // 추후에 BLSDB 서비스가 만들어지면 엔진 정보는 Service 테이블에서 가져올 예정
  // Service 테이블
  // category_main: BLSDB
  // category_sub: MySQL | MariaDB | PostgreSQL
  // category_specific: 각 엔진별 버전
  engines: any = [
    {
      type: 'MySQL',
      versions: ['5.7.19', '5.7.25', '5.7.29', '8.0.18'],
    },
    {
      type: 'MariaDB',
      versions: ['10.0.35', '10.1.34', '10.2.21', '10.3.23', '10.4.13'],
    },
    {
      type: 'PostgreSQL',
      versions: ['9.6.18', '10.13', '11.8', '12.3'],
    },
  ];

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    public global: GlobalService,
  ) {}

  ngOnInit(): void {}

  onChange(value) {
    if (value) {
      this.engineVersions = this.engines.find(
        (engine) => engine.type === value
      ).versions;
    }
  }

  createBLSDB() {
    try {

    } catch (e) {
    }
  }
}
