
<!-- Modal : 서브계정 수정 모달 -->
<div id="user_modify" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        서브계정 수정
        <p class="sub-txt">| 서브계정 수정 설명입니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
            <col style="width: 10%; min-width: 100px;" />
          </colgroup>
          <tbody>
            <tr>
              <th>프로젝트 관리</th>
              <td>
                <div class="input-group" *ngFor="let project of projectsOfSubUser" style="margin-bottom: 8px;">
                  <input type="text" [attr.value]="project.resource_data.value" readonly />
                  <div class="input-group-btn" (click)="deleteRoleBind(project.id)">
                    <button class="btn mid dGray on">
                      <i class="ico ico_moreTp1"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>프로젝트 추가</th>
              <td>
                <div class="input-group">
                  <select (change)="onChangeProjectIdToAdd($event.target.value)">
                    <option value="{{ project.id }}" *ngFor="let project of restProjects">
                      {{ project.resource_data.value }}
                    </option>
                  </select>
                  <div class="input-group-btn">
                    <button class="btn mid dGray" (click)="createRoleBind()">
                      <i class="ico ico_moreTp1"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>유형 변경</th>
              <td>
                <div class="input-group no-btn">
                  <select [(ngModel)]="updateData.selected_type">
                      <option value="{{ ut.type }}" *ngFor="let ut of usertype">
                          {{ ut.name }}
                        </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th>비밀번호 변경</th>
              <td>
                <div class="input-group">
                  <input type="password" name="" id="search" (change)="changePassword()" [(ngModel)]="updateData.password" maxlength="20" placeholder="비밀번호를 입력해주세요" class="form-control">
                </div>
                <div *ngIf="!updateData.password_check" class="invalid-feedback">
                  <div>영문 대문자, 숫자, 특수문자를 1개씩 이상 포함하여 8자리 이상 입력해주세요. (최대 20자)</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="updateSubUser()" class="btn big ciblue mg_r4">수정</a>
        <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->