<!-- Container -->
<div id="Container" class="inquiry">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>1:1 문의 하기</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <!--div class="sec_header">
                    <h3>1:1 문의</h3>
                    <div class="subTit">
                        문의하신 결과를 조회 할 수 있습니다.
                    </div>
                </div-->
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <!-- searchArea -->
                    <div class="searchArea">
                        <div class="inp inpSelect">
                            <select name="" [value]="searchCate" (change)="onSearchCateChange($event.target.value)">
                                <option value="">전체</option>
                                <option value="요금">요금</option>
                                <option value="고객지원">고객지원</option>
                                <option value="서비스">서비스</option>
                            </select>
                        </div>
                        <div class="inp inpSearch">
                            <input type="text" (keydown.enter)="fn_inquirySearch($event.target.value)" [value]="searchTitle" (change)="onSearchTitleChange($event.target.value)">
                            <button class="inpSearch-btn" (click)="fn_inquirySearch(searchTitle)"></button>
                        </div>
                    </div>
                    <!-- //searchArea -->

                    <div class="boardBox tp2">
                        <table class="tbl_col_tp1 pdTp2">
                            <colgroup>
                                <col style="width:8%">
                                <col style="width:12%">
                                <col >
                                <col style="width:12%">
                                <col style="width:12%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">번호</th>
                                    <th scope="col">카테고리</th>
                                    <th scope="col">제목</th>
                                    <th scope="col">등록일</th>
                                    <th scope="col">상태</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr *ngFor="let inquiry of inquiryList; index as i;" (click)="fn_detailInquiry(inquiry.issueId)">
                                    <td>{{10*curPage+i+1}}</td>
                                    <td>{{ inquiry.category }}</td>
                                    <td class="l"><a href="javascript:void(0)">{{ inquiry.title }}</a></td>
                                    <td>{{ inquiry.createdDate.substring(0, 10) }}</td>
                                    <td>
                                        <label [class]="inquiry.completed ? 'btn tiny red radius' : 'btn tiny blue radius'">
                                            {{ inquiry.completed ? "처리완료" : "처리중" }}
                                        </label>
                                    </td>
                                </tr>
                                <tr *ngIf="totalCount < 1">
                                    <td colspan="5">조회된 내역이 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>                        
                        
                        <div class="btn_wrap mg_t10">
                            <a routerLink="/web/mypage/inquriyRegister" class="btn mid black fr" style="margin-right: 1%">글쓰기</a>
                        </div>

                        <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    </ngx-spinner>  