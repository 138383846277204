import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../webapi.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

declare function setCookie(cookieName, value, exdays)
declare function deleteCookie(cookieName)
declare function getCookie(cookieName)
declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  result: object;
  submitted = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private spiner: NgxSpinnerService,
    private webapi: WebApiService,
    private router: Router,
    private renderer : Renderer2
  ) { }

  loginForm = new FormGroup({
    userId: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      Validators.minLength(4),
    ]),
    password: new FormControl('', [
      Validators.required,
    ]),
    loginSave: new FormControl(false),
  });
  get login() {
    return this.loginForm.controls;
  }

  ngOnInit(): void { 
    var cookieLoginId = getCookie("saveLoginId");
    if(cookieLoginId !== ''){
      this.loginForm.patchValue({
        userId: cookieLoginId,
        loginSave : true
      })
      this.renderer.addClass(this.document.getElementById('saveChkIdLabel'),'active');
    }else{
      this.renderer.removeClass(this.document.getElementById('saveChkIdLabel'),'active');
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      const invalid = [];
      const controls = this.loginForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        } else {
          alert("아이디 또는 비밀번호를 확인해주세요.");
          return;
        }
      }
    }
    this.spiner.show();
    this.webapi.blsLogin(this.loginForm.value).toPromise().then(
      (response) => {
        localStorage.setItem('userInfo', JSON.stringify(response.body));
        
        //var companyId = response.body['registrationNumber'] + response.body['regstrationSub']

        var num = response.body['registrationNumber'];
        var sub = response.body['registrationNumberSub'];
        this.webapi.getCompanyByRegistration({
          registrationNumber:num,
          registrationNumberSub:sub
        }).subscribe(
          (response:any)=>{
            localStorage.setItem('companyInfo', JSON.stringify(response))
            this.router.navigate(['/']);
            this.spiner.hide();
          }
        );

        // this.webapi.getCompany(response.body['companyId']).subscribe(  
        //   (response) => {
        //     localStorage.setItem('companyInfo', JSON.stringify(response.body))
        //     this.router.navigate(['/']);
        //     this.spiner.hide();
        //   }, (err) => {

        //   }
        // )

        if($("#saveChkId").is(":checked")){
          var login_Id = this.loginForm.controls.userId.value;
          setCookie("saveLoginId", login_Id, 365)
        }else {
          deleteCookie("saveLoginId");
        }
      }, (error) => {
        if (error.status === 401) {
          alert('로그인 아이디나 비밀번호가 일치하지 않습니다.')
          this.loginForm.controls.password.reset();
        } else {
          alert(error.error.message);
        }
        this.spiner.hide();
        // this.loginForm.reset();
      }
    )
    this.submitted = false
  }
  routerHome(){
    this.router.navigate(['/']);
  }
}
