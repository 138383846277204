import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-claim-claimPay',
  templateUrl: './admin-claim-claimPay.component.html',
})
export class AdminClaimClaimPayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
}
