import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-server-update',
  templateUrl: './dialog-server-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  //기본정보 수정
  edit_server_data: any = { name: '', description: '' };

  selected_server: any = {
    image_snapshot_name: '',
    image_snapshot_description: '',
  };
  ngOnInit(): void {

    this.selected_server = this.dialogData.selected_server;
    
    this.edit_server_data.name = this.selected_server.server_name;
    this.edit_server_data.description = this.selected_server.description;
  }

  
  updateServerInfo() {
    
    if(this.edit_server_data.name == null ||this.edit_server_data.name == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '인스턴스의 이름을 입력해주세요.'
        }
      });
      return;
    }
    const name_filter = /^[0-9a-zA-Z\-_]*$/
    if(this.edit_server_data.name.length>20 || name_filter.test(this.edit_server_data.name)==false ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "20자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다."
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi
      .updateServerInfo(
        this.selected_server.server_id,
        this.edit_server_data.name,
        this.edit_server_data.description
      )
      .subscribe((data: {}) => {
        this.spinner.hide();

        this.closeDialogMsg("success");
        if(this.router.url=='/console/server'){
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/server']);
            }
          );
        }else{
          this.router.navigate(['/console/server']);
        }
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }
  
}
