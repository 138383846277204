import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';

declare function fn_modalOpen(id: any):any;

@Component({
  selector: 'app-admin-send-sms',
  templateUrl: './admin-send-sms.component.html',
})
export class AdminSendSmsComponent implements OnInit {

  constructor(private webapi: WebApiService) { }

  userList: any = [];
  tranPhone: any;

  maxByte = 80;
  msgType = 0;

  sbjChk = false;
  byteChk = false;
  submitted = false;

  sendForm = new FormGroup({
    msgType: new FormControl('0', [
      Validators.required,
    ]),
    tranCallback: new FormControl('07075001290', [
      Validators.required,
    ]),
    tranPhone: new FormControl('', [
      Validators.required,
    ]),
    tranDate: new FormControl(''),
    subject: new FormControl(''),
    msgBody: new FormControl('', [
      Validators.required,
    ])
  });
  get sendChk() { return this.sendForm.controls; }

  //// List Filter
  _listFilter = '';
  get listFilter(): string {
      return this._listFilter;
  }
  userListFilter:any
  set listFilter(value: string) {
    this._listFilter = value;
    this.userListFilter = this.listFilter ? this.doFilter(this.listFilter) : this.userList;
  }
  doFilter(filterBy: string) {
    return this.userList.filter(userList =>
        userList.name.indexOf(filterBy) !== -1);
  }
  //// List Filter

  ngOnInit(): void {
    this.getUserList();
    this.listFilter = '';
  }

  getUserList() {
    this.webapi.getUserListWithStatus(2).subscribe((data: {}) => {
      this.userList = data['result'];
      this.userListFilter = data['result'];

    });
  }

  fn_getAdress(){
    fn_modalOpen('smsModal');
  }

  fn_getAdressReceiver(){    
    this.tranPhone = '';
    let chkReceiver = document.getElementsByName('userReceiver');


    for(let i=0; i < chkReceiver.length; i++){
      if((<HTMLInputElement>chkReceiver[i]).checked){
        this.tranPhone += (<HTMLInputElement>chkReceiver[i]).value + ',';
      }
    }
    this.tranPhone = this.tranPhone.substring(0, this.tranPhone.length - 1);
    this.sendForm.controls.tranPhone.setValue(this.tranPhone);


    (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
  }

  onSubmit(){
    this.submitted = true;

    
    this.webapi.sendSms(this.sendForm.value).subscribe((data: {}) => {

      alert("발송되었습니다.");
      this.formInit();
      //location.reload();
    });
  }

  formInit(){
    this.sbjChk = false;
    this.byteChk = false;
    this.submitted = false;

    this.sendForm.reset({msgType: this.msgType, tranCallback: '07075001290'});
  }

  changeType(e) {
    // init
    this.formInit();

    this.byteChk = false;
    const selectType = e.target.id;


    if (selectType === 'sms'){
      this.msgType = 0;
      this.maxByte = 80;
      this.sendForm.controls.msgType.setValue(this.msgType);
      this.sbjChk = false;
    }else{
      this.msgType = 5;
      this.maxByte = 2000;
      this.sendForm.controls.msgType.setValue(this.msgType);
    }

  }

  titleHandler(e){
    const sbjMaxByte = 64;
    const s = e.target.value;
    const strsize = this.getByteLength(s);


    if (strsize > sbjMaxByte){
      this.sbjChk = true;
      return;
    }

    this.sbjChk = false;
  }

  bytesHandler(e){
    const s = e.target.value;
    const strsize = this.getByteLength(s);
    



    if (strsize > this.maxByte){
      // this.msgBody = text.substring(0, text.length - 1);
      this.byteChk = true;
      return;
    }

    this.byteChk = false;
  }

  
  // 입력된 글자 전체의 byte calculate
  getByteLength(s){
    if (s == null || s.length === 0) {
      return 0;
    }
    let size = 0;
    for ( let i = 0; i < s.length; i++) {
      size += this.charByteSize(s.charAt(i));
    }
    return size;
  }

  // 원하는 byte 만큼 글자를 잘라서 반환
  cutByteLength(s, len) {
    if (s == null || s.length === 0) {
      return 0;
    }
    let size = 0;
    let rIndex = s.length;
    for ( let i = 0; i < s.length; i++) {
      size += this.charByteSize(s.charAt(i));
      if ( size === len ) {
        rIndex = i + 1;
        break;
      } else if ( size > len ) {
        rIndex = i;
        break;
      }
    }
    return s.substring(0, rIndex);
  }

  // 한글자에 대한 byte calculate
  charByteSize(ch: string) {
    if (ch == null || ch.length === 0) {
      return 0;
    }
    const charCode = ch.charCodeAt(0);
    if (charCode <= 0x00007F) {
      return 1;
    } else if (charCode <= 0x0007FF) {
      return 2;
    } else if (charCode <= 0x00FFFF) {
      return 3;
    } else {
      return 4;
    }
  }
}
