import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';

declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-admin-claim-credit',
  templateUrl: './admin-claim-credit.component.html',
})
export class AdminCreditComponent implements OnInit {

  constructor(private webapi: WebApiService) { }
  userList: any = [];
  selected_user:any={name:"",userId:""};
  
  creditForm = new FormGroup({
    registrationNumber: new FormControl('', [
      Validators.required,
    ]),
    registrationNumberSub: new FormControl('', [
      Validators.required,
    ]),
    credit: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    sign: new FormControl(1),
    description: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
    ]),
  });
  get creditChk() { return this.creditForm.controls; }

  //// List Filter
  _listFilter = '';
  get listFilter(): string {
      return this._listFilter;
  }
  userListFilter:any
  set listFilter(value: string) {
    this._listFilter = value;
    this.userListFilter = this.listFilter ? this.doFilter(this.listFilter) : this.userList;
  }
  doFilter(filterBy: string) {

    return this.userList.filter(userList =>
      userList.name.indexOf(filterBy) !== -1);
  }
  //// List Filter

  ngOnInit(): void {    
    this.getUserList();    
    this.listFilter = '';
  }

  getUserList() {
    this.webapi.getUserList().subscribe((data: {}) => {
      this.userList = data['result'];
      this.userListFilter = data['result'];

    });
  }

  clickUserCheck(user,target){
    this.selected_user = user;
    this.creditForm.controls.registrationNumber.setValue(user.registrationNumber);
    this.creditForm.controls.registrationNumberSub.setValue(user.registrationNumberSub);
    
    let chkReceiver = document.getElementsByName('userReceiver');
    for(var i=0;i<chkReceiver.length;i++){
      if((<HTMLInputElement>chkReceiver[i]).checked){
        if(target == chkReceiver[i]){
        }else{
          (<HTMLInputElement>chkReceiver[i]).checked =false;
        }
      }
    }
  }
  onSubmit(){
    this.webapi.createCreditHistory(this.creditForm.value).subscribe((data) => {      
      if(data){

        this.creditForm.reset();
        this.creditForm.controls.registrationNumber.setValue(this.selected_user.registrationNumber);
        this.creditForm.controls.registrationNumberSub.setValue(this.selected_user.registrationNumberSub);
        this.creditForm.controls.sign.setValue(1);
        alert("크레딧 지급 완료")
      }
    });
  }
}
