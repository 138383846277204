import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import {ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-inquiry-view',
  templateUrl: './inquiry-view.component.html',
})
export class InquiryViewComponent implements OnInit {

  inquiryId:String = "";
  inquiryDetail: any = [];  
  inquiryComment: any = [];  

  constructor(
    private webapi: WebApiService, 
    private activatedRoute: ActivatedRoute,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {

      this.inquiryId = params['inquiryId'];
    });
  }


  ngOnInit(): void {
    this.getInquiryDetail(); 
  }

  getInquiryDetail(){
    this.spiner.show();
    this.webapi.getInquiryDetail(this.inquiryId).subscribe(
      (response) => {
       const data = response.body
       this.inquiryDetail = data['result'][0];
       this.inquiryDetail['createdDate'] =  this.inquiryDetail['createdDate'].split("T")[0]
      if(this.inquiryDetail.completed){
        this.getInquiryComment();
      }
    }, (err) => {

    }
    );
    this.spiner.hide()
  }

  getInquiryComment(){
    this.spiner.show();
    this.webapi.getInquiryComment(this.inquiryId).subscribe(
      (response) => {
        const data = response.body
        this.inquiryComment = data['result'][0];

      },(err) => {

      }
    );
    this.spiner.hide();
  }
}
