
<!-- Modal : 스토리지 기본정보 수정 모달 -->
<div id="storage_edit_memo" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>스토리지
                <p class="sub-txt"> | 스토리지 정보를 변경합니다.</p>
            </h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width: 20%;" />
                        <col style="width: 80%;" />
                    </colgroup>
                    <tbody *ngIf="selected_volume">
                        <tr>
                            <th scope="row">스토리지 이름</th>
                            <td>
                                <input type="text" name="" id="secureNm" [(ngModel)]="updateStorageData.name" maxlength="60"/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">설명</th>
                            <td>
                                <textarea name="" id="editVolumeMemo" rows="5" placeholder="내용을 입력해주세요" maxlength="255"
                                    [(ngModel)]="updateStorageData.description"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp1">
                <a class="btn big ciblue mg_r4" (click)="updateVolume()">수정</a>
                <a class="btn big bor_gray" (click)="closeDialog()">닫기</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal -->