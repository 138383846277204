import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WebApiService } from '../../webapi.service';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

/**
 * 20200813 cbkim
 * 다국어 지원
 */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-web-nav',
  templateUrl: './web-navigation.component.html',
})
export class WebNavigationComponent implements OnInit {
  blsAdmincheck = false;
  loginCheck = false;
  memberStatus = '';
  memberPosition = '';

  userInfoList: any = [];
  companyInfoList: any = [];

  /**
 * 20200813 cbkim
 * 다국어 지원
 */
  private translate: TranslateService;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private spiner: NgxSpinnerService,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private router: Router,
   /**
   * 20200813 cbkim
   * 다국어 지원
   */
    translate: TranslateService
  ) {
    this.translate = translate;
  }

  ngOnInit(): void {
    /**
   * 20200813 cbkim
   * 다국어 지원
   */
    this.translate.setDefaultLang('ko');


    try {
      this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    } catch (e) {
      this.userInfoList = null;
    }
    try {
      this.companyInfoList = JSON.parse(localStorage.getItem('companyInfo'));
    } catch (e) {
      this.companyInfoList = null;
    }

    if (this.userInfoList !== null) {
      this.memberPosition = this.userInfoList['type']
    }
    if (this.companyInfoList !== null) {
      this.memberStatus = this.companyInfoList['status'];
    }

    this.renderer.removeClass(this.document.body, 'console');
    this.isLogin();
    this.isMobile();
  }
  onActivate(event) {
    if(!matchMedia("screen and (min-width: 960px)").matches) {
      document.getElementById("side_nav").style.width = "0";
      document.getElementById("side_nav").style.display = "none";
    }
    window.scroll(0, 0);
  }

  isLogin() {
    this.spiner.show();
    this.webapi.blsLoginCheck().subscribe((data) => {
      this.loginCheck = data.isAuthenticated;
      if (this.loginCheck === true) {
        this.blsAdmin();
      } else {
        localStorage.clear();
      }
    });
    this.spiner.hide();
  }
  mobileCheck:boolean = false;
  isMobile(){
    if(navigator.userAgent.toLowerCase().match(/mobi/i)){      
      this.mobileCheck = true;
    }else if(navigator.userAgent.toLowerCase().match(/iphone|ipad|ipod/i)){  
      this.mobileCheck = true;
    }else {
      this.mobileCheck = false;
    }
    
  }
  blsAdmin() {
    this.spiner.show();
    this.webapi.blsAdminCheck().subscribe((data) => {
      this.blsAdmincheck = data.result;
    });
    this.spiner.hide();
  }

  logOut() {
    localStorage.clear();
    this.webapi.blsLogout().subscribe((data: {}) => {
      this.blsAdmincheck = false;
      this.memberStatus = '';
      this.memberPosition = '';
      //location.reload();
      location.href = '/';
    });
  }

  checkMypage() {
    /**
     *  memType 0: 기업 1:개인
     *  isSub true: 서브계정 false: 기업관리자
     */
    this.webapi.getMemType().subscribe((data) => {
      if (data.memType == 0) {
        // 기업회원        
        if (data.isSub) {
          // 서브계정
          this.router.navigate(['/web/mypage/member'])
        } else {
          // 기업 관리자
          this.router.navigate(['/web/mypage/company']);
        }
      } else {
        // 개인회원        
        this.router.navigate(['/web/mypage/member'])
      }
    });
  }

  routerHome(): void {
    this.router.navigate(['/']);
  }

  menuListOpen() {
    if(!matchMedia("screen and (min-width: 960px)").matches) {
      if(this.document.getElementById("side_nav").style.display == "block") {
        document.getElementById("side_nav").style.width = "0";
        document.getElementById("side_nav").style.display = "none";
      } else {
        document.getElementById("side_nav").style.width = "200px";
        document.getElementById("side_nav").style.display = "block";
      }
    }
  }
  
  // lang(data) {
  //   if(data == 1) {
  //     this.translate.setDefaultLang('ko');
  //   } else if(data == 2) {
  //     this.translate.setDefaultLang('en');
  //   } else {
  //     this.translate.setDefaultLang('ko');
  //   }
  // }
}
