import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-mobile-console-loadbalancer-list',
  templateUrl: './mobile-console-loadbalancer-list.component.html',
  styleUrls: ['./mobile-console-loadbalancer-list.component.css']
})
export class MobileConsoleLoadbalancerListComponent implements OnInit {
  loadbalancerLists: any = [];
  _blank = true;
 
  constructor(
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    private spinner: NgxSpinnerService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getLoadbalancerList();
    this.getLoadbalancer();
  }

  getLoadbalancerList(){
    this.loadbalancerLists = [];
    this.osapi.getLoadbalancerList().subscribe(
      (data:any)=>{
        this.loadbalancerLists = data.items;
        this.loadbalancerLists = this.loadbalancerLists.filter(lb => lb.provisioning_status != "PENDING_DELETE");
        this._blank = ((this.loadbalancerLists != 0) ? true : false);
        this.spinner.hide();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      }
    )
  }
  
  currentTimer;
  async getLoadbalancer(){
    if(this.router.url != "/m/loadbalancer"){
      clearTimeout(this.currentTimer);
      return;
    }
    if(this.loadbalancerLists != undefined){
      for(let lb of this.loadbalancerLists){
        let data:any = await this.osapi.getLoadbalancerFull(lb.id).toPromise();
        if(data.floating_ip.hasOwnProperty('ip')){
          lb.floating_ip = data.floating_ip;
          lb.is_floating_ip = true;
        }
        lb.provisioning_status = data.provisioning_status;
        lb.provisioning_status_ko = this.getStatusKo(lb.provisioning_status);
        lb.operating_status = data.operating_status;
        lb.operating_status_ko = this.getStatusKo(lb.operating_status);
        lb.listeners = data.listeners;
        var newListener=[];
        let memberCnt = 0;
        for(let listener of lb.listeners){
		      let listener_data:any = await this.osapi.getLoadbalancerListener(listener.id).toPromise();
          listener_data.operating_status_ko = this.getStatusKo(listener_data.operating_status);
          /** pool 이 존재하면 */
            if(listener_data.default_pool_id != null){
              let pool_data:any = await this.osapi.getPools(listener_data.default_pool_id).toPromise();
              pool_data.operating_status_ko = this.getStatusKo(pool_data.operating_status);
              let members:any = await this.osapi.getPoolMembers(listener_data.default_pool_id).toPromise();
              members = members.items;
              for(let member of members){
                member.operating_status_ko = this.getStatusKo(member.operating_status);
				        memberCnt++;
              }
              pool_data.members = members;
              listener_data.pool = pool_data;
            }
          newListener.push(listener_data);
        }
        if(memberCnt == 0){
          lb.operating_status = "EMPTY"
          lb.operating_status_ko = "EMPTY"
        }
        lb.listener = newListener;
      }
    }
    this.currentTimer = setTimeout(()=>{this.getLoadbalancer()},30000);
  }

  getStatusKo(status){
    var status_ko = status;
    switch (status) {
      case "ONLINE":
          status_ko = "ONLINE"
        break;
      case "DRAINING":
          status_ko = "New connection failed"
        break;
      case "OFFLINE":
          status_ko = "OFFLINE"
        break;
      case "DEGRADED":
          status_ko = "WARNING"
        break;
      case "ERROR":
          status_ko = "ERROR"
        break;
      case "NO_MONITOR":
          status_ko = "NO_MONITOR"
        break;
    }
    return status_ko;
  }

}
