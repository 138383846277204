<!-- Container -->
<div id="Container" class="faq">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>자주하는 질문</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <!-- <div class="sec_header">
                    <h3>자주하는 질문</h3>
                    <div class="subTit">
                    </div>
                </div> -->
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">                   
                    <ul class="ls_faq">
                        <li *ngFor="let faq of faqList; index as i;">
                            <!-- titArea -->
                            <div class="titArea">
                                <a href="javascript:void(0)" class="tit">{{ faq.title }}</a>
                            </div>
                            <!-- //titArea -->

                            <!-- contArea -->
                            <div class="contArea">
                                <pre class="cont">{{ faq.contents }}</pre>
                            </div>
                            <!-- //contArea -->
                        </li>
                    </ul>
                    <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->        
    </div>
</div>
<!-- //Container -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
</ngx-spinner>  