
<!-- Modal : 서브넷 편집 모달 -->
<div id="subnet_update" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>
                서브넷 편집
                <!-- <p class="sub-txt"> | 서브넷 편집 설명글 입니다.</p> -->
            </h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width:30%">
                        <col style="width:70%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>서브넷 이름</th>
                            <td><input type="text" placeholder="이름을 입력해주세요." [(ngModel)]="updateSubnetData.name" maxlength="60"></td>
                        </tr>
                        <tr>
                            <th>네트워크 주소(CIDR)</th>
                            <td><input type="text" [value]="selected_subnet.cidr" placeholder="" readonly></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp1">
                <a (click)="updateSubnet()" class="btn big ciblue mg_r4">변경</a>
                <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal -->