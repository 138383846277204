
<!-- Modal : 이미지 기본정보 수정 모달 -->
<div id="image_edit_memo" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
          <h2>이미지
              <p class="sub-txt"> | VM 이미지의 정보를 수정합니다.</p>
          </h2>
        </div>
        <!-- //modal_header -->
      
        <!-- modal_content -->
        <div class="modal_content">
          <div class="boardBox">
            <table class="tbl_row_tp1">
              <colgroup>
                <col style="width: 20%;" />
                <col style="width: 80%;" />
              </colgroup>
              <tbody *ngIf="selected_image">          
                <tr>
                  <th scope="row">이미지 이름</th>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="imageName"
                      [(ngModel)]="edit_image_data.name"
                      maxlength="255"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">설명</th>
                  <td>
                    <textarea
                      name=""
                      id="editVolumeMemo"
                      rows="5"
                      placeholder="내용을 입력해주세요"
                      [(ngModel)]="edit_image_data.description" 
                      maxlength="255"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btnArea tp1">
            <a (click)="updateImage()" class="btn big ciblue mg_r4">수정</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
          </div>
        </div>
        <!-- //modal_content -->
      </div>
      <!-- //Modal -->