<!-- Modal : 공인IP 연결 모달 -->
<div id="ip_connect" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            공인IP 연결
            <p class="sub-txt">공인IP를 서버 인스턴스에 할당합니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">공인IP</th>
                        <td>
                          <select name="" id="ip" [(ngModel)]="seleted_floatingip_id" required>
                            <option value="">선택</option>
                            <ng-container *ngFor="let fip of ipList">
                              <option *ngIf="fip.fixed_ip == null" [value]="fip.id">{{
                                fip.floating_ip_address
                              }}</option>
                            </ng-container>
                          </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">네트워크 <br>인터페이스</th>
                        <td>
                            <select name="" id="netinf" [(ngModel)]="selected_port_id">
                                <option value="">선택</option>
                                <option *ngFor="let port of portList" [value]="port.port_id+'_'+port.address">
                                    {{port.server_name}}({{port.address}})</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="associateFloatingIp()" class="btn big ciblue mg_r4">연결</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->

