import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { MemberData } from '../../console-load-balancer-setting/MemberData';

@Component({
  selector: 'app-dialog-loadbalancer-member-update',
  templateUrl: './dialog-loadbalancer-member-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogLoadbalancerMemberUpdateComponent extends DialogCommon implements OnInit {
  selected_loadbalancer;

  lbmember: any = [];

  selected_protocol: string;
  subnetList: any = [];
  selected_flavor_id: string;
  selected_listener;

  serverList: any = [];

  servers: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogLoadbalancerMemberUpdateComponent>,
  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }

  ngOnInit(): void {
    this.selected_loadbalancer = this.dialogData.selected_loadbalancer;
    this.serverList = this.dialogData.serverList;
    this.selected_listener = this.dialogData.listener;

    for (let server of this.serverList) {
      server.backup = false;
    }

    for (let member of this.selected_listener.pool.members) {
      for (let server of this.serverList) {
        server.backup = false;
        server.member_id = null;
        for (let pip of server.ip_private) {
          if (member.address == pip) {
            server.backup = member.backup;
            server.member_id = member.id;
            server.protocol_port = member.protocol_port;
            this.addPreSubnet(server);
          }
        }
      }
    }
    /**
     * 20200928 cbkim
     * 기존 목록은 셋팅했고..서버리스트는 다시 초기화
     */
    for(let serverr of this.serverList){
      serverr.backup = false;
    }
  }

  addPreSubnet(server){
    var memberData = new MemberData();
    if (server.ip_private.length > 0) {
      memberData.name = server.server_name;
      memberData.address = server.ip_private[0] || '';
      memberData.backup = server.backup;
      if (server.member_id != null) {
        memberData.id = server.member_id;
      }
      if (server.protocol_port) {
        memberData.protocol_port = server.protocol_port;
      }
      this.lbmember.push(memberData)
    }
  }

  addSubnet(server) {
    var memberData = new MemberData();
    if (server.ip_private.length > 0) {
      memberData.name = server.server_name;
      memberData.address = server.ip_private[0] || '';
      memberData.backup = server.backup;
      console.log(server);
      
      this.lbmember.push(memberData)
    }
  }

  async delSubnet(index) {
    // var newServers = []
    // for (let item of this.lbmember) {
    //   if (item.address != server.address) {
    //     newServers.push(item);
    //   }
    // }
    // this.lbmember = newServers;
    this.lbmember.splice(index,1)
  }

  checkSubnet(server) {
    for (let intf of server.network_interfaces) {
      for (let port of intf.ports) {
        for (let fixed_ip of port.fixed_ips) {
          if (fixed_ip.subnet_id == this.selected_loadbalancer.vip_subnet_id) {
            return true
          }
        }
      }
    }
    return false;
  }

  changeMode(result,server) {
    if ("0" == result) {
      console.log('backup false');
      server.backup = false;
    } else {
      console.log('backup true');
      server.backup = true;
    }
  }

  changeProtocol() {

  }


  async updateMembers() {
    var cid = 0;
    for (let i = 0; i < this.lbmember.length; i++) {
      this.lbmember[i].id = i;
      this.lbmember[i].subnet_id = this.selected_loadbalancer.vip_subnet_id;
      if(this.lbmember[i].address.includes("(")){
        this.lbmember[i].address = this.lbmember[i].address.split(' ')[0];
      }
    }

    var members = JSON.parse(JSON.stringify(this.lbmember));

    var param = {
      loadbalancer_id: this.selected_loadbalancer.id,
      l7policy: { admin_state_up: true },
      l7rule: { admin_state_up: true, invert: false },
      members: members
    }

    this.spinner.show()
    var resultUpdated = this.osapi.updateMembers(this.selected_listener.pool.id, param).subscribe(
      (data) => {
        this.spinner.hide()
        this.closeDialogMsg("success");
      }
    )
  }
}
