<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Storage</li>
      <li class="active">Block Storage</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let volume of volumeLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons">select_all</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts" *ngIf="volume.name?.length > 10; else ltitle;">
                      {{volume.name.substring(0, 10)+"...."}}
                    </mat-panel-title>
                    <ng-template #ltitle>
                      <mat-panel-title class="mFonts">
                        {{volume.name}}
                      </mat-panel-title>
                    </ng-template>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                      <tbody>
                          <tr>
                            <th>이름</th>
                            <td>{{volume.name}}</td>
                          </tr>
                          <tr>
                            <th>설명</th>
                            <td *ngIf="volume.description != null && volume.description != ''">{{volume.description}} </td>
                            <td *ngIf="volume.description == null || volume.description == ''">없음</td>
                          </tr>
                          <tr>
                            <th>크기</th>
                            <td>{{volume.size}} GiB</td>
                          </tr>
                          <tr>
                            <th>상태</th>
                            <td>
                              <ng-template [ngIf]="volume.status == 'available'">AVAILABLE</ng-template>
                              <ng-template [ngIf]="volume.status == 'in-use'">IN-USE</ng-template>
                            </td>
                          </tr>
                          <tr>
                            <th>연결정보</th>
                            <td *ngIf="volume.server; else noneServer;">
                              {{volume.server.server_name}}:{{volume.attachments[0].device}}
                            </td>
                            <ng-template #noneServer>
                              <td>없음</td>
                            </ng-template>
                          </tr>
                        </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>