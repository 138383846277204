import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { GlobalService } from '../../global.service';
import { FrontApiService } from '../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-security-group-list',
  templateUrl: './console-security-group-list.component.html',
})
export class ConsoleSecurityGroupListComponent implements OnInit {

  // 검색 텍스트
  filterText: string;

  isActived: boolean = false;
  isChecked: boolean = false;

  securityGroupList: any = [];
  selected_securitygroup: any = {};

  constructor(private osapi: OsapiService,
    public global: GlobalService,
    private frontapi: FrontApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialogs:DialogSetService,
    private common: ConsoleNavComponent
    ) {
  }
  message: any = {};
  ngOnInit(): void {
    this.spinner.show();
    this.getSecurityGroupList();

    this.frontapi.currentMessage.subscribe((message: any) => {
      this.message = message;
      if (message.message_type == 'create security group') {

        this.securityGroupList.push(message.data_object);
      }
    },
      (error) => {

        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }
  ngOnDestroy() {
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);    
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;

    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;

  }

  onClickList(sg) {
    this.isActived = true;
    this.selected_securitygroup = sg;
  }

  onSGListCheck(dataId) {

  }
  getSecurityGroupList() {
    this.spinner.show();
    this.osapi.getSecurityGroupList().subscribe(
      (data: { items: [] }) => {
        this.spinner.hide();

        this.securityGroupList = data.items
        for(let sg of this.securityGroupList){
          if(sg.name == "default" ){
            sg.isDefault = true;
          }else{
            sg.isDefault = false;
          }
        }
        this.common.getThemeFont();
      },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  
  openNewSecurityGroupModal(){
    this.dialogs.openSecurityGroupCreateDialog({});
  }
  openDeleteSecurityGroupModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"보안그룹 삭제",
      content:"선택하신 보안그룹("+this.selected_securitygroup.name+")을 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteSecurityGroup();
        }
      });
  }
  deleteSecurityGroup() {
    this.spinner.show();
    this.osapi.deleteSecurityGroup(this.selected_securitygroup.id).subscribe(
      (data) => {
        this.spinner.hide();
        this.getSecurityGroupList();
      },
      (error) => {
        if(error.status == 409){
          // 연결 된 인터페이스 있음
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '보안그룹 삭제',
              message: '사용 중인 보안그룹은 삭제 할 수 없습니다.'
            }
          });
        }else{
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        }
        this.spinner.hide();
      });
  }
  openSecurityGroupRuleList() {
    // this.globalService.sendData(this.selected_securitygroup);
    this.router.navigate(['/console/securitygroup/ruleManage/' + this.selected_securitygroup.id]);
    // location.replace('/console/ruleManage/' + this.selected_securitygroup.id);
  }
}