import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';
declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-admin-board-ref',
  templateUrl: './admin-board-ref.component.html',
})
export class AdminBoardRefComponent implements OnInit {
  constructor(
    private webapi: WebApiService,
    private spiner: NgxSpinnerService,
    private common: AdminNavComponent
  ) { }

  userInfoList: any = [];

  materialList: any = [];
  selected_material: any = {};

  modal_comp_category: String = '요금';
  modal_comp_title: String = '';
  modal_comp_contents: String = '';

  modal_comp_file: any;

  searchCate: String = '';
  searchTitle: String = '';

  //페이징변수
  curPage: any;
  totalCount: any;

  materialForm = new FormGroup({
    boardId: new FormControl('3', []),
    userId: new FormControl('', []),
    enabled: new FormControl(true, []),
    commentEnabled: new FormControl(true, []),
    category: new FormControl('요금', []),
    title: new FormControl('', [Validators.required]),
    contents: new FormControl('', [Validators.required]),
    file: new FormControl('', []),
  });

  ngOnInit(): void {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    this.materialForm.patchValue({
      userId: this.userInfoList.userId
    })
    this.getMaterialList(0);
  }

  getMaterialList(page) {
    this.curPage = page;

    var searchQuerys = '&o=created_date.DESC&l=10&s=' + this.curPage * 10;
    if (this.searchTitle != '') {
      searchQuerys +=
        '&title=' + decodeURIComponent(this.searchTitle + '') + '&lk=title';
    }

    this.webapi.getMaterialList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.materialList = data['result'];
        this.totalCount = data['total'];
        this.common.getThemeFont();
      } , (err) => {

      }
    );
  }
  //페이징함수
  getMovePage(e) {
    this.getMaterialList(e.page);
  }

  getMaterialDetail(material: any) {

    this.selected_material = material;

    this.modal_comp_category = this.selected_material.category;
    this.modal_comp_title = this.selected_material.title;
    this.modal_comp_contents = this.selected_material.contents;
    this.modal_comp_file = new Array();

    fn_modalOpen('material_detail');
  }

  onCategoryChange(deviceValue) {
    this.modal_comp_category = deviceValue;
  }
  onTitleChange(deviceValue) {
    this.modal_comp_title = deviceValue;
  }
  onContentsChange(deviceValue) {
    this.modal_comp_contents = deviceValue;
  }
  onFileChanged(deviceValue) {
    this.modal_comp_file = deviceValue.target.files;
  }

  onClickList(material) {

    this.selected_material = material;
  }

  onSubmit(file) {
    this.materialForm.value.userId = this.userInfoList.userId;
    const formData = new FormData();
    formData.append('boardId', this.materialForm.controls.boardId.value);
    formData.append('userId', this.materialForm.controls.userId.value);
    formData.append('category', this.materialForm.controls.category.value);
    formData.append('title', this.materialForm.controls.title.value);
    formData.append('contents', this.materialForm.controls.contents.value);
    formData.append('enabled', this.materialForm.controls.enabled.value);
    formData.append('commentEnabled', this.materialForm.controls.commentEnabled.value);

    if (file.length > 0) {
      formData.append('file', file[0]);


      this.webapi.createArticles(formData).subscribe((data: {}) => {
        alert('저장되었습니다.');
        (<HTMLInputElement>document.getElementById('closeModalBtn')).click();

        this.submitFormReset();

        // 재검색
        this.getMaterialList(0);
      });
    } else {
      alert('자료파일을 넣어주십시요.');
    }
  }

  submitFormReset() {
    this.materialForm.setValue({
      boardId: '3',
      userId: this.userInfoList.userId,
      enabled: true,
      commentEnabled: true,
      category: '요금',
      title: '',
      contents: '',
      file: '',
    });
  }

  // 검색
  fn_materialSearch(title) {
    this.searchTitle = title;

    this.getMaterialList(0);
  }

  onSearchTitleChange(deviceValue) {
    this.searchTitle = deviceValue;
  }

  downloadFile() {
    location.href =
      `/webapi/articles/${ this.selected_material.articleId}/attachment`;
  }

  deleteMaterial() {
    this.webapi.deleteArticles(this.selected_material).subscribe(
      (response) => {
        alert('삭제되었습니다.');
        // 재검색
        this.getMaterialList(0);
      }, (err) => {

      }
    );
  }

  updateMaterial() {
    this.selected_material.category = this.modal_comp_category;
    this.selected_material.title = this.modal_comp_title;
    this.selected_material.contents = this.modal_comp_contents;

    const formData = new FormData();

    formData.append('articleId', this.selected_material.articleId);
    formData.append('boardId', this.selected_material.boardId);
    formData.append('userId', this.selected_material.userId);
    formData.append('category', this.selected_material.category);
    formData.append('title', this.selected_material.title);
    formData.append('contents', this.selected_material.contents);
    formData.append('enabled', this.selected_material.enabled);
    formData.append('commentEnabled', this.selected_material.commentEnabled);

    if (this.modal_comp_file.length > 0) {
      formData.append('file', this.modal_comp_file[0]);
    } else if (
      this.selected_material.attachedFilePath != null &&
      this.selected_material.attachedFilePath != ''
    ) {
      formData.append(
        'attachedFilePath',
        this.selected_material.attachedFilePath
      );

    }

    if (this.modal_comp_title.trim() == '') {
      alert('제목을 입력해 주십시요.');
    } else if (this.modal_comp_contents.trim() == '') {
      alert('내용을 입력해 주십시요.');
    } else {
      this.webapi.updateArticlesForm(formData).subscribe((data) => {
        alert('저장되었습니다.');
        (<HTMLInputElement>document.getElementById('closeModalBtn2')).click();
        this.getMaterialList(0);
      });
    }
  }
}
