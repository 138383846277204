import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-message-queue',
  templateUrl: './admin-message-queue.component.html',
})
export class AdminMessageQueueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  deleteService(){
      
  }

}
