
<!-- Modal : NAS 생성 모달 -->
<div id="nas_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        NAS 생성
        <p class="sub-txt">네트워크를 통해 사용가능한 공유 스토리지를 생성합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tbody>
            <tr>
              <th>NAS 이름</th>
              <td><input type="text" [(ngModel)]="newNas.name" placeholder="이름을 입력해주세요" maxlength="60"/></td>
            </tr>
  
            <tr>
              <th>설명</th>
              <td><input type="text" [(ngModel)]="newNas.description"  placeholder="설명을 입력해주세요" maxlength="255"/></td>
            </tr>
            <tr>
              <th>크기(GB)</th>
              <td>
                <select [(ngModel)]="newNas.size">
                  <option value="" selected>-선택-</option>
                  <option *ngFor="let size of nasSize" value="{{size}}">{{size}} GB</option>
                </select>
                <!-- <input type="number" [(ngModel)]="newNas.size" /> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="createNas()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->