import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import { Router, ActivatedRoute, NavigationExtras} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
})
export class NoticeListComponent implements OnInit {

  noticeList: any = [];

  //페이징변수
  curPage:any=0;
  totalCount:any;
  
  searchCate:String = "";
  searchTitle:String = "";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
        if(params['page']!=null && params['page']!='undefined'&& params['page']!=""){
          this.curPage = params['page'];
        }

        if(params['searchTitle']!=null && params['searchTitle']!=""){
          this.searchTitle = params['searchTitle'];
        }
        
        if(params['searchCate']!=null && params['searchCate']!=""){
          this.searchCate = params['searchCate'];
        }
    });
  }


  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.getNoticeList(this.curPage);    
  }


  getNoticeList(page){

    this.curPage = page;

    var searchQuerys = "";
    searchQuerys = "&l=10&s="+(this.curPage*10)+"&o=created_date.DESC";
    if(this.searchCate!=""){
      searchQuerys += "&category="+decodeURIComponent(this.searchCate+"");
    }
    if(this.searchTitle!=""){
      searchQuerys += "&title="+decodeURIComponent(this.searchTitle+"")+"&lk=title";
    }
    this.spiner.show();
    this.webapi.getNoticeList(searchQuerys).subscribe(
      (response) => {
        const data = response.body

        this.noticeList = data['result'];
        this.totalCount = data['total'];
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  getNoticeView(key){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        noticeId: key,        
      },
      skipLocationChange: true,
    };
    this.router.navigate(['/web/noticeView'], navigationExtras);    
  }

  //페이징함수
  getMovePage(e){
    this.getNoticeList(e.page)
  }
  
  // 검색
  fn_noticeSearch(title){
    this.searchTitle = title;
    this.getNoticeList(0);
  }

  onSearchCateChange(deviceValue){
    this.searchCate=deviceValue;
  }
  
  onSearchTitleChange(deviceValue){
    this.searchTitle=deviceValue;
  }
}
