import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrontApiService} from '../../frontapi.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { ToastComponent, ToastCloseArgs } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'app-console-test',
  templateUrl: './console-test.component.html',
  styleUrls: ['./console-test.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ConsoleTestComponent {

  @ViewChild('toasttype')
  public toastObj: ToastComponent;
  @ViewChild('infoToast') btninfo: ElementRef;
  @ViewChild('warningToast') btnwarn: ElementRef;
  @ViewChild('errorToast') btnerr: ElementRef;
  @ViewChild('successToast') btnsuccess: ElementRef;
  public position: object = { X: 'Right' };
  public toasts: { [key: string]: Object }[] = [
       { title: 'Success!', cssClass: 'e-toast-success', icon: 'fa fa-check', timeOut: 80000 },
  ];

  public counter = 0;
  constructor( ) {
      
  }
   public onCreate(): void {
    setTimeout(function(){
          const toast = this.toasts[0];
          toast.content = `${this.counter} success`;

          this.toastObj.show(toast);
      }.bind(this),200);
  }
  public successClick(e: Object): void {
        const toast = this.toasts[0];
        this.counter++;
        toast.content = `${this.counter} success`
       this.toastObj.show(toast);
  }
  public hideClick(e: Object): void {
     this.toastObj.hide('All');
  }
  public onclose(e: ToastCloseArgs): void {
      if (e.toastContainer.childElementCount === 0 ) {
          let hideBtn: HTMLElement = document.getElementById('hideTosat');
          hideBtn.style.display = 'none';
      }
  }
  public onBeforeOpen(): void {
      let hideBtn: HTMLElement = document.getElementById('hideTosat');
      hideBtn.style.display = 'inline-block';
  }
}
