<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>API 인증키 관리</h2>
        <div class="lineMap">
            Service / <span>API Gateway</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm" class="frmBox1">
            <!-- section -->
            <section class="sec col12">
                <div class="sec_content">
                    <!-- lyBox -->
                    <div class="lyBox">
                        <div class="title_wrap">
                            <div class="contArea">
                                <div class="tit">
                                    <app-console-projects></app-console-projects>
                                </div>
                                <div class="cont">
                                    <app-console-apigateway-status></app-console-apigateway-status>
                                </div>
                                <div class="contSub">
                                    <button class="btn mid blue mg_r4" (click)="openGwAuthCreateDialog()" ><i class="ico ico_register_white"></i>API 인증 생성</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                    <!-- lyBox -->
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>API Key</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                    <input type="search" class="form-control" name="" id="search" value=""
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="filterText"
                                        placeholder="검색어를 입력해주세요">
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display:block">
                            <div class="boardBox">
                                <table class="tbl_col_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;">
                                        <col style="width: 19%;">
                                        <col style="width: 19%;">
                                        <col style="width: 19%;">
                                        <col style="width: 19%;">
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                        </th>
                                        <th scope="col">이름</th>
                                        <th scope="col">인증방식</th>
                                        <th colspan="2" scope="col">값</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let auth of auth_list | tableFilter: filterText" (click)="onClickList(auth)" (contextmenu)="onClickList(auth)">
                                            <!-- <td><input type="checkbox" (change)="onIPListCheck($event.target.dataset.id)" [attr.data-id]="item.id"></td> -->
                                            <td><input type="checkbox" name="sel_chkbox" ></td>
                                            <td>{{auth.name}}</td>
                                            <td>{{auth.type}}</td>
                                            <td colspan="2">
                                                <button class="btn mid bor_gray mg_r4" style="width:100%" *ngIf="auth.hide" (click)="auth.hide=!auth.hide">
                                                    <i class="fa fa-eye fa-lg"></i>
                                                </button>
                                                <input *ngIf="!auth.hide" disabled type="text" [value]="auth.message">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1">
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a (click)="openGwAuthCreateDialog()">API KEY 생성</a>
        <a (click)="openDeleteAuthModal(selected_auth)">API KEY 삭제</a>
    </div>
</div>
