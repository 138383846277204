<!-- Contents -->
<div id="Contents" class="dashboard">
        <!-- content_header -->
        <div class="content_header col12">
            <h2>대시보드</h2>
            <div class="lineMap">
                Dashboard  /  <span>Main</span>
            </div>
        </div>
        <!-- //content_header -->
    
        <!-- content_body -->
        <div class="content_body">
            <!-- section -->
            <section class="sec">
                <div class="sec_content row">
                    <!-- lyBox -->
                    <div class="lyBox col col6 consoleSubBody">
                        <div class="titArea">
                            <h3>호스트 장비 목록</h3>
                        </div>
                        <div class="contArea"> 
                            <div class="boardBox">
                                <table class="tbl_row_tp1">
                                    <colgroup>
                                        <col style="width:30%">
                                        <col style="width:35%">
                                        <col style="width:35%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Server</th>
                                            <td>Instance</td>
                                            <td>4 대</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Storage</th>
                                            <td>Block Storage</td>
                                            <td>10 개</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Network</th>
                                            <td>Public IP</td>
                                            <td>1 개</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Network</th>
                                            <td>Network Traffic</td>
                                            <td>120 GB</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                    <!-- lyBox -->
                    <div class="lyBox col col6 consoleSubBody">
                        <div class="titArea">
                            <h3>이용자 요청 목록</h3>
                        </div>
                        <div class="contArea"> 
                            <div class="boardBox">
                                <table class="tbl_col_tp1 pdTp3">
                                    <colgroup>
                                        <col style="width:70%">
                                        <col style="width:30%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 클라우드 서비스 점검 안내 입니다</a></td>
                                            <td>2020-06-29</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 서비스 정산내역 관련 안내 입니다</a></td>
                                            <td>2020-06-29</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-25] 전체 공지사항 입니다. </a></td>
                                            <td>2020-06-25</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-25] 서비스 재점검 일시 재공지 입니다</a></td>
                                            <td>2020-06-25</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 서비스 정산내역 관련 안내 입니다</a></td>
                                            <td>2020-06-21</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 클라우드 서비스 점검 안내 입니다</a></td>
                                            <td>2020-06-21</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 클라우드 서비스 점검 안내 입니다</a></td>
                                            <td>2020-06-21</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 클라우드 서비스 점검 안내 입니다</a></td>
                                            <td>2020-06-21</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 클라우드 서비스 점검 안내 입니다</a></td>
                                            <td>2020-06-21</td>
                                        </tr>
                                        <tr>
                                            <td class="l"><a href="javascript:void(0)" class="link">[2020-06-29] BLS 클라우드 서비스 점검 안내 입니다</a></td>
                                            <td>2020-06-21</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                    <!-- lyBox -->
                    <div class="lyBox col col12 consoleMainBody">
                        <div class="titArea">
                            <h3>전체 리소스 사용량</h3>
                        </div>
                        <div class="contArea tpA"> 
                            <div class="imgArea col col4">
                                <img src="../../../assets/img/console/img_dashboard_03.jpg" alt="">
                            </div>
                            <div class="imgArea col col4">
                                <img src="../../../assets/img/console/img_dashboard_01.jpg" alt="">
                            </div>
                            <div class="imgArea col col4">
                                <img src="../../../assets/img/console/img_dashboard_02.jpg" alt="">
                            </div>
                            <div class="btnArea"></div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->
        </div>
        <!-- //content_body -->
    </div>
    <!-- //Contents -->