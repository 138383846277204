<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Proejct</li>
      <li class="active">Proejct List</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let project of projectLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons">settings</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{project.resource_data.value}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>프로젝트명</th>
                      <td *ngIf="project.resource_data">{{project.resource_data.value}}</td>
                    </tr>
                    <tr>
                      <th>설명</th>
                      <td *ngIf="project.resource_data">{{project.resource_data.description}}</td>
                    </tr>
                    <tr>
                      <th>생성일</th>
                      <td *ngIf="project.resource_data">{{project.resource_data.createdDate.substring(0, 10)}}</td>
                    </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>