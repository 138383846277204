import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { DomSanitizer } from '@angular/platform-browser';


import {MatDialog} from '@angular/material/dialog';
import {DialogKeypairCreateDetailComponent} from '../dialog-keypair-create-detail/dialog-keypair-create-detail.component';

@Component({
  selector: 'app-dialog-keypair-create',
  templateUrl: './dialog-keypair-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogKeypairCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogKeypairCreateComponent>,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  keyNameList=[];
  fileUrl;
  privatekey_name: string;
  new_keypair;

  newKeyPairData:{
    name:string,
    description:string
  }={name:"", description:""}

  ngOnInit(): void {

    this.osapi.getResourceAll().subscribe((data:Array<any>)=>{
      const keypairs = data.filter(function(rsc){
        return rsc.resourceType == 'keypair'
      });
      this.keyNameList = keypairs.map(function(key){
        return key.value;
      });
    });

  }
  createKeypair() {
    if(this.newKeyPairData.name.length<3){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:"인증키의 이름이 너무 짧습니다."
        }
      });
      return;
    }
    if(!this.flag_validate_name_rule){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:"인증키의 이름은 20자 이내 영문,숫자,-,_로 작성해야합니다."
        }
      });
      return;
    }
    if(!this.flag_validate_name_duplicated){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:"인증키의 이름은 중복해서 사용할 수 없습니다."
        }
      });
      return;
    }
    if(this.newKeyPairData.description.length>255){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:"설명은 255자 이내로 작성해야합니다."
        }
      });
      return;
    }
    this.spinner.show();
    let key_name = this.newKeyPairData.name;
    let key_type = 'ssh';
    let key_description = this.newKeyPairData.description;

    this.osapi
      .createKeyPair(key_name, key_type, key_description)
      .subscribe((data: any) => {
        this.spinner.hide();
        this.new_keypair = { keypair: data };

        this.setFileURL(data.private_key);
        this.privatekey_name = data.name + '.pem';
        data.description = key_description;

        let message ={
          message_type:'create keypair',
          data_object: data
        };
        this.frontapi.changeMessage(message);

        // fn_modalOpen('modal_authkey_detail');
        this.closeDialog();


        this.openKeypairCreateDetailDialog({new_keypair:this.new_keypair,privatekey_name:this.privatekey_name,fileUrl:this.fileUrl});
        
        // location.reload();
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
  }
  setFileURL(private_key) {
    const blob = new Blob([private_key], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }
  openKeypairCreateDetailDialog(data) {
    const dialogRef = this.dialog.open(DialogKeypairCreateDetailComponent, {
      width: '580px',maxHeight: '90%',disableClose:true,panelClass: 'console-modal-default',data: data
    });
  }

  flag_validate_name_rule=true;
  flag_validate_name_duplicated=true;
  onChangeKeypairName(){

    const name_filter = /^[0-9a-zA-Z\-_]*$/
    if(this.newKeyPairData.name.length>20 || name_filter.test(this.newKeyPairData.name)==false ){
      this.flag_validate_name_rule=false;
    }else{
      this.flag_validate_name_rule=true;
    }
    if(this.keyNameList.indexOf(this.newKeyPairData.name) == -1){
      this.flag_validate_name_duplicated=true;
    }else{
      this.flag_validate_name_duplicated=false;
    }

  }

}
