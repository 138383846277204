<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>인스턴스</h2>
    <div class="lineMap">Server / <span>Instances</span></div>
  </div>
  <!-- //content_header -->
  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="lyBox">
          <div class="title_wrap">
            <div class="contArea">
              <div class="tit">
                <app-console-projects></app-console-projects>
              </div>
              <div class="cont">
                <app-console-server-status></app-console-server-status>
              </div>
              <div class="contSub">
                <button routerLink="/console/server/create" routerLinkActive="active" class="btn mid blue mg_r4"><i
                    class="ico ico_register_white"></i>서버생성</button>
                <button class="btn mid ciblue mg_r4" (click)="startServer(selected_server.server_id)"
                  [disabled]="!isActived || selected_server.status != 'SHUTOFF'">시작</button>
                <button class="btn mid ciblue mg_r4" (click)="openServerStopModal()" 
                  [disabled]="!isActived || selected_server.status != 'ACTIVE'">정지</button>
                <button class="btn mid ciblue mg_r4" (click)="openServerRestartModal()"
                  [disabled]="!isActived || selected_server.status != 'ACTIVE' ">재시작</button>
                <button class="btn mid ciblue mg_r4" (click)="openIpConnectModal()">공인IP연결</button>
                <button class="btn mid ciblue mg_r4" (click)="openSecurityGroupModal()"
                  [disabled]="!isActived">보안그룹편집</button>
                <button class="btn mid ciblue mg_r4" (click)="openPortForwardingModal()">포트포워딩 관리</button>
              </div>
            </div>
          </div>
        </div>
        <div class="lyBox consoleRightBody">
          <div class="titArea">
            <h3>서버 목록</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요"
                  [(ngModel)]="filterText" />
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>

                </div>
                <!-- 접기 버튼 -->
                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <mat-accordion>
                <table class="tbl_dtl_tp1  dropmenu">
                  <colgroup>
                    <col style="width: 5%;" />
                    <col style="width: 12.8%" />
                    <col style="width: 12.8%" />
                    <col style="width: 12.8%" />
                    <col style="width: 12.8%" />
                    <col style="width: 12.8%" />
                    <col style="width: 12.8%" />
                    <col style="width: 12.8%" />
                  </colgroup>
                  <thead>
                    <th scope="col">
                      <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                    </th>
                    <th scope="col">서버명</th>
                    <th scope="col">이미지명</th>
                    <th scope="col">서버유형</th>
                    <th scope="col">상태</th>
                    <th scope="col">사설IP</th>
                    <th scope="col">공인IP</th>
                    <th scope="col">원격 접속 허용 (PORT)</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let serverList of serverLists | tableFilter: filterText"
                      (contextmenu)="onClickList(serverList)">
                      <td colspan="8" style="padding:0;">

                        <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                          (closed)="panelOpenState = false" class="auth_theme">
                          <mat-expansion-panel-header>
                            <table class="  dropmenu">
                              <colgroup>
                                <col style="width: 5%;" />
                                <col style="width: 12.8%" />
                                <col style="width: 12.8%" />
                                <col style="width: 12.8%" />
                                <col style="width: 12.8%" />
                                <col style="width: 12.8%" />
                                <col style="width: 12.8%" />
                                <col style="width: 12.8%" />
                              </colgroup>
                              <tr class="btn_expand" [attr.id]="serverList.ip_private"
                                [attr.data-pfip-id]="serverList.port_forwarding_id">
                                <td>
                                  <input (change)="onServerListCheck(serverList)" type="checkbox" name="sel_chkbox"
                                    [attr.data-id]="serverList.server_id" />
                                </td>
                                <td>{{ serverList.server_name }}</td>
                                <td>{{ serverList.image_name }}</td>
                                <td>{{ serverList.flavor_name }}</td>
                                <td>
                                  <label [class]="serverList.status == 'ACTIVE'? 'dot green': 'dot red'"></label>{{ serverList.status }}
                                </td>
                                <td>
                                  <ul>
                                    <li *ngFor="let item of serverList.ip_private">
                                      <p>{{ item.split(' ')[0] }}</p>
                                    </li>
                                  </ul>
                                </td>
                                <td *ngIf="serverList.ip_public != null; else emptyPublicIP">
                                  {{ serverList.ip_public }}
                                </td>
                                <td>
                                  <label [class]="serverList.is_remote_access?'dot green':'dot red'"></label>
                                  {{serverList.remote_status_msg}}
                                </td>
                              </tr>
                            </table>

                          </mat-expansion-panel-header>
                          <div class="result-tbl auth_theme">
                            <table class="tbl_dtl_tp2">
                              <colgroup>
                                <col style="width: 25%;" />
                                <col style="width: 25%" />
                                <col style="width: 25%" />
                                <col style="width: 25%" />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <th>서버명</th>
                                  <td>{{ serverList.server_name }}</td>
                                  <th>서버 식별자</th>
                                  <td>{{ serverList.server_id.substring(0, 24) + '************' }}</td>
                                </tr>
                                <tr>
                                  <th>상태</th>
                                  <td>{{ serverList.status }}</td>
                                  <th>이미지명</th>
                                  <td>{{ serverList.image_name }}</td>
                                </tr>
                                <tr>
                                  <th>스토리지</th>
                                  <td>
                                    <table>
                                      <tr *ngFor="let storage of serverList.volume_list">
                                        {{storage.name}}({{storage.size}}GB):
                                        {{storage.attachments[0].device}}
                                      </tr>
                                    </table>
                                  </td>
                                  <ng-template #emptyKeyPair>
                                    <td>없음</td>
                                  </ng-template>
                                  
                                  <th>OS</th>
                                  <td *ngIf="serverList.os_name; else emptyOS">
                                    {{ serverList.os_name }}
                                  </td>
                                  <ng-template #emptyOS>
                                    <td>---</td>
                                  </ng-template>
                                </tr>
                                <tr>
                                  <th>사설IP</th>
                                  <td>
                                    <li *ngFor="let item of serverList.ip_private">
                                      <p>{{ item }}</p>
                                    </li>
                                  </td>
                                  <th>인증키</th>
                                  <td *ngIf="serverList.key_name != null;else emptyKeyPair">
                                    {{ serverList.key_name }}
                                  </td>
                                </tr>
                                <tr>
                                  
                                  <th>공인IP</th>
                                  <td *ngIf="serverList.ip_public != null;else emptyPublicIP">
                                    {{ serverList.ip_public }}
                                  </td>
                                  
                                  <ng-template #emptyPublicIP>
                                    <td *ngIf="serverList.is_port_forwarding;else noPortFowarding">
                                      <ng-template [ngIf]= "serverList.port_forwarding_ip">
                                          포트포워딩
                                          <p>{{ serverList.port_forwarding_ip }}:{{ serverList.port_forwarding_port }}</p>
                                      </ng-template>
                                      <ng-template [ngIf]= "!serverList.port_forwarding_ip">
                                          로딩 중..
                                      </ng-template>
                                      
                                    </td>
                                    <ng-template #noPortFowarding>
                                      <td>할당되지 않음</td>
                                    </ng-template>
                                  </ng-template>
                                  <!-- <th>담당자</th> -->
                                  <!-- <td>---</td> -->
                                  <th>보안그룹</th>
                                  <td>
                                    <table>
                                      <tr *ngFor=" let security_group of serverList.security_groups ">
                                        <!-- <table> -->
                                          <td style="padding: 1px; margin: 1px;">
                                            {{ security_group.name }}
                                          </td>
                                          <td style="padding: 1px; margin: 1px;">
                                            <a [routerLink]="'/console/securitygroup/ruleManage/' + security_group.id"
                                              routerLinkActive="router-link-active" class="btn tiny dShow"></a>
                                          </td>
                                        <!-- </table> -->
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  
                                  <th>포트 포워딩</th>
                                  <td *ngIf="
                                                  serverList.is_port_forwarding;
                                                  else emptyPortFowardingIP
                                                ">
                                    접속IP :
                                    {{ serverList.port_forwarding_ip }} (port
                                    {{ serverList.port_forwarding_port }})
                                  </td>
                                  <ng-template #emptyPortFowardingIP>
                                    <td>할당되지 않음</td>
                                  </ng-template>
                                  <th>생성일시</th>
                                  <td>{{ serverList.created_at.substring(0, 10) }}</td>
                                  <!-- <th>네트워크 인터페이스</th> -->
                                  <!-- <td>---</td> -->
                                </tr>
                                <tr>
                                  <th>설명</th>
                                  <td>{{ serverList.description }}</td>
                                  <th>변경일시</th>
                                  <td>{{ serverList.modified_at.substring(0, 10) }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </mat-expansion-panel>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </mat-accordion>
            </div>
            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
        </div>
        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
  <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
  <div *ngIf="selected_server != null" id="myDropdown" class="dropdown-content">
    <a (click)="openServerUpdateModal()">기본정보 수정</a>
    <a (click)="openSecurityGroupModal()">보안그룹 편집</a>
    <ng-template [ngIf]="selected_server.status == 'ACTIVE'">
      <a (click)="openServerStopModal()">정지</a>
      <a disabled>시작</a>
      <a (click)="openServerRestartModal()">재시작</a>
      <!-- <a (click)="linkSecurityGroupRule()">보안그룹 규칙 보기</a> -->
      <!-- 팝업추가 -->
      <a *ngIf="selected_server.ip_public == null && selected_server.is_port_forwarding == false"
        (click)="openIpConnectModal()">공인IP 연결</a>
      <a *ngIf="selected_server.is_port_forwarding" disabled>공인 IP 연결</a>
      <a *ngIf="selected_server.ip_public != null" (click)="openIpDisassociateModal()">공인IP 연결해제</a>
      <a disabled>서버유형 변경</a>
    </ng-template>
    <ng-template [ngIf]="selected_server.status == 'SHUTOFF'">
      <a disabled>정지</a>
      <a (click)="startServer(selected_server.server_id)">시작</a>
      <a disabled>재시작</a>

      <a *ngIf="selected_server.ip_public == null" (click)="openIpConnectModal()">공인IP 연결</a>
      <a *ngIf="selected_server.ip_public != null" (click)="openIpDisassociateModal()">공인IP 연결해제</a>
      <a (click)="openServerResizeModal()">서버유형 변경</a>
    </ng-template>
    <ng-template [ngIf]="selected_server.status == 'ERROR'">
      <a disabled>정지</a>
      <a disabled>시작</a>
      <a disabled>재시작</a>
    </ng-template>
    <a (click)="openServerImageCreateModal()">이미지로 저장</a>
    <a (click)="openInterfaceModal()">인터페이스 관리</a>

    <a *ngIf="!selected_server.ip_public; else disabledPortfowarding" (click)="openPortForwardingModal()">포트포워딩 관리</a>
    <ng-template #disabledPortfowarding>
      <a disabled>포트포워딩 관리</a>
    </ng-template>

    <a *ngIf="selected_server.os_type == 'win'" (click)="openAdminPasswordModal()">
      관리자 비밀번호 확인
    </a>

    <ng-template [ngIf]="selected_server.status == 'ACTIVE'">
      <a disabled>삭제</a>
    </ng-template>
    <ng-template [ngIf]="selected_server.status != 'ACTIVE'">
      <a (click)="openServerDeleteModal()">삭제</a>
    </ng-template>
  </div>
</div>