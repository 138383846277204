
<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>API 게이트웨이</h2>
        <div class="lineMap">
            Service / <span>API Gateway</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="title_wrap">
                        <div class="contArea">
                            <div class="tit">
                                <app-console-projects></app-console-projects>
                            </div>
                            <div class="cont">
                                <app-console-apigateway-status></app-console-apigateway-status>
                            </div>
                            <div class="contSub">
                                <button class="btn mid blue mg_r4"  routerLink="/console/apigateway/api/create" routerLinkActive="router-link-active">
                                    <i class="ico ico_register_white"></i>API 등록
                                </button>
                                <button class="btn mid ciblue mg_r4" routerLink="/console/apigateway/api/auth" routerLinkActive="router-link-active">인증키 관리</button>
                                <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openGwApiUpdate(selected_api)">API 정보 편집</button>
                                <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openGwRouteCreateDialog(selected_api)">접근경로 추가</button>
                                <button class="btn mid ciblue mg_r4" routerLink="/console/apigateway/api/usage" routerLinkActive="router-link-active">사용량 조회</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->
                <!-- lyBox -->
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>API 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name=""  id="search" [(ngModel)]="filterText"
                                    value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i
                                            class="ico ico_search_white"></i>검색</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contArea" style="display:block">
                        <div class="boardBox">
                            <mat-accordion>
                                <table class="tbl_dtl_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;" />
                                        <col style="width: 19%;"/>
                                        <col style="width: 19%;" />
                                        <col style="width: 19%;" />
                                        <col style="width: 19%;" />
                                        <col style="width: 19%;" />
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                        </th>
                                        <th scope="col">이름</th>
                                        <th scope="col">API 호스트</th>
                                        <th scope="col">API 포트</th>
                                        <th scope="col">API 경로</th>
                                        <th scope="col">-</th>
                                    </thead>
                                    <tbody>
                                        <!-- <tr (click)="onClickList(loadbalancer)" (contextmenu)="onClickList(loadbalancer)"> -->
                                        <tr *ngFor="let api of api_list | tableFilter: filterText" (click)="onClickList(api)" (contextmenu)="onClickList(api)">
                                            <td colspan="7" style="padding:0;">

                                                <mat-expansion-panel hideToggle class="auth_theme">
                                                    <mat-expansion-panel-header>

                                                        <table class="  dropmenu">
                                                            <colgroup>
                                                                <col style="width: 5%;" />
                                                                <col style="width: 19%;" />
                                                                <col style="width: 19%;" />
                                                                <col style="width: 19%;" />
                                                                <col style="width: 19%;" />
                                                                <col style="width: 19%;" />
                                                            </colgroup>
                                                            <tr  class="btn_expand">
                                                                <td><input type="checkbox" name="sel_chkbox" [checked]="api == selected_api"></td>
                                                                <td>{{api.name}}</td>
                                                                <td>
                                                                    <div *ngFor="let endpoint of api.endpoints">{{endpoint.host}}</div>
                                                                </td>
                                                                <td>
                                                                    <div *ngFor="let endpoint of api.endpoints">{{endpoint.port}}</div>
                                                                </td>
                                                                <td>{{api.path}}</td>
                                                                <td>-</td>
                                                            </tr>
                                                        </table>
                                                    </mat-expansion-panel-header>
                                                    <div class="result-tbl" style="min-height:60px;" class="auth_theme">
                                                        <table class="tbl_dtl_tp2" *ngIf="api.routes.length==0">
                                                            <colgroup>
                                                                <col style="width: 80%;" />
                                                                <col style="width: 20%;" />
                                                            </colgroup>
                                                            <th>
                                                                <strong>등록 된 접근경로가 존재하지 않습니다.</strong>
                                                            </th>
                                                            <td >
                                                                <button style="float:right" class="btn mid ciblue mg_r4" (click)="openGwRouteCreateDialog(api)">접근경로 추가</button>
                                                            </td>
                                                        </table>
                                                        
                                                        <mat-tab-group animationDuration="0ms"  *ngIf="api.routes.length!=0">
                                                            
                                                            <mat-tab *ngFor="let route of api.routes" [label]="route.name?route.name:'접근경로'" >
                                                                    <table class="tbl_dtl_tp2">
                                                                            <colgroup>
                                                                                <col style="width: 10%;" />
                                                                                <col style="width: 23%;" />
                                                                                <col style="width: 10%;" />
                                                                                <col style="width: 23%;" />
                                                                                <col style="width: 10%;" />
                                                                                <col style="width: 23%;" />
                                                                                <!-- <col > -->
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th colspan="3">
                                                                                        * 접근경로
                                                                                    </th>
                                                                                    <td colspan="3">
                                                                                        <button  style="float:right" class="btn mid ciblue mg_r4" (click)="openGwRouteCreateDialog(api)">접근경로 추가</button>
                                                                                        <button (click)="openGwRouteUpdateDialog(route)" style="float:right" class="btn mid ciblue mg_r4">접근경로 편집</button>
                                                                                        <button (click)="openGwIpRestrictionDialog(route)" style="float:right" class="btn mid ciblue mg_r4">IP 차단정책</button>
                                                                                        <button (click)="openDeleteRouteModal(route)" style="float:right" class="btn mid bor_gray mg_r4" >접근경로 삭제</button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>이름</th>
                                                                                    <td>{{route.name}}</td>
                                                                                    <th>프로토콜</th>
                                                                                    <td>{{route.protocol}}</td>
                                                                                    <th>경로</th>
                                                                                    <td>{{route.path}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>CORS</th>
                                                                                    <td>{{route.is_cors?'활성화':'비활성화'}}</td>
                                                                                    <th>Request Size 제한</th>
                                                                                    <td>{{route.is_requestsizelimiting?route.request_size_limit.size +' '+ route.request_size_limit.size_unit:'비활성화'}}</td>
                                                                                    <th>IP 차단정책</th>
                                                                                    <td>{{route.is_iprestriction?'적용 중':'없음'}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>요청 URL</th>
                                                                                    <td colspan="5" style="user-select: text;">{{route.uri}}/추가경로</td>
                                                                                </tr>
                                                                                <tr >
                                                                                    <th>서비스 URL</th>
                                                                                    <td colspan="5">
                                                                                        <div *ngFor="let endpoint of api.endpoints" style="user-select: text;">
                                                                                            {{api.protocol}}://{{endpoint.host}}:{{endpoint.port}}{{api.path=='/'?'':api.path}}/추가경로
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th colspan="6"
                                                                                        style="border-top: solid 1px #e2e2e2;border-bottom: solid 1px #e2e2e2;">
                                                                                        * 접근가능한 API 인증 목록
                                                                                        <!-- <a class="btn tiny dShow"></a> -->
                                                                                    </th>
                                                                                </tr>
                                                                                <tr *ngIf="!route.is_keyauth">
                                                                                    <td colspan="5">인증 없이 접근 가능한 API 경로입니다.</td>
                                                                                </tr>
                                                                                <tr *ngIf="route.is_keyauth && !route.is_acl">
                                                                                    <td colspan="5">접근을 허용할 인증키를 추가해야합니다.</td>
                                                                                    
                                                                                </tr>
                                                                                <tr  *ngFor="let auth of route.auths">
                                                                                    <th>
                                                                                        {{auth.name}}
                                                                                    </th>
                                                                                    <td colspan="5">
                                                                                        <button class="btn mid bor_gray mg_r4" style="width:100%" *ngIf="auth.hide" (click)="auth.hide=!auth.hide">
                                                                                                <i class="fa fa-eye fa-lg"></i>
                                                                                        </button>
                                                                                        <input *ngIf="!auth.hide" disabled type="text" [value]="auth.message">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                            </mat-tab>

                                                        </mat-tab-group>

                                                    </div>
                                                </mat-expansion-panel>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-accordion>
                        </div>
                        <!-- <div class="btn_wrap tp1"></div> -->
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a routerLink="/console/apigateway/api/create" routerLinkActive="router-link-active">새 API 등록</a>
        <a (click)="openGwApiUpdate(selected_api)">API 정보 편집</a>
        <a (click)="openGwRouteCreateDialog(selected_api)">접근경로 추가</a>
        <a (click)="openDeleteAPIModal(selected_api)">API 삭제</a>
    </div>
</div>