<div id="Header">
  <div class="logo" style="cursor: pointer;" (click)="routerHome()">
    <a></a>
  </div>
</div>
<!-- Container -->
<div id="Container" class="login">
  <div class="findid">
    <!-- frm_content -->
    <div class="frm_content">
      <!-- logo -->
      <div class="logoArea">
        <div class="logo">
          <img src="../assets/img/member/logo_member.png" alt="" routerLink="/" style="cursor: pointer;" />
        </div>
        <h1>아이디 찾기</h1>
      </div>
      <!-- //logo -->

      <!-- form -->
      <!--form action="main.html" method="post"-->
      <form [formGroup]="findidForm" (ngSubmit)="onSubmit()">
        <!-- loginForm -->
        <fieldset class="loginForm">
          <legend>아이디 찾기</legend>
          <ul class="row_group">
            <li class="row">
              <div class="tit">이름</div>
              <div class="cont">
                <input type="text" id="userName" placeholder="이름을 입력해주세요." autocomplete="off" spellcheck="false"
                  formControlName="userName" required />
              </div>
              <div *ngIf="submitted && findForm.userName.errors" class="invalid-feedback tempError">
                <div *ngIf="findForm.userName.errors.required">
                  이름을 입력해주세요.
                </div>
                <div *ngIf="findForm.userName.errors.pattern">
                  한글만 입력해주세요.
                </div>
              </div>
            </li>

            <li class="row">
              <div class="tit">핸드폰번호</div>
              <div class="cont">
                <input type="text" id="mobile" placeholder="핸드폰번호를 입력해주세요." autocomplete="off" spellcheck="false"
                  formControlName="mobile" required maxlength="11" />
              </div>
              <div *ngIf="submitted && findForm.mobile.errors" class="invalid-feedback tempError">
                <div *ngIf="findForm.mobile.errors.required">
                  핸드폰번호를 입력해주세요.
                </div>
                <div *ngIf="
                    findForm.mobile.errors.pattern ||
                    findForm.mobile.errors.minlength
                  ">
                  10 ~ 11 자리 숫자만 입력해주세요.
                </div>
              </div>
            </li>
            <button type="submit" id="findpassword" class="btn_login">
              아이디 찾기
            </button>
            <li class="row" id="confirmCodeLi" style="display: none;">
              <div class="tit" id="timer">인증번호</div>
              <div class="cont">
                <div class="inpTp1">
                  <input type="text" id="confirmCode" spellcheck="false" autocomplete="off"
                    placeholder="인증코드" maxlength="6" style="width:40%;text-align: center;"/>
                  <a style="cursor: pointer; width: 25%; margin-left: 10px;" (click)="confirmCodeCheck()">확인</a>
                  <a style="cursor: pointer; width: 25%; margin-left: 10px;" (click)="reConfirmCode()">재전송</a>
                </div>
              </div>
            </li>
            <li class="row tit" style="color:white;font-size:13px;margin-bottom:18px;">
              '서브계정' 아이디는 해당 기업의 관리자에게 문의해주세요.
            </li>
          </ul>
          <div class="login_footer">
            <a href="/">HOME</a>
            <a routerLink="/member/find-password">비밀번호 찾기</a>
            <a routerLink="/member/signUp">회원가입</a>
          </div>
        </fieldset>
        <!-- //loginForm     -->
      </form>
      <!-- //form -->
    </div>
    <!-- //frm_content -->
  </div>
</div>