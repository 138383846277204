<!-- Container -->

<div id="Container">
        <div class="visual" style="height: 525px;">
            <ul class="mainVisual_slider">
                <li class="slm_A">
                    <div class="cont_inner">
                        <div class="subTit">합리적인 비용으로 쉽게 사용할 수 있는 </div>
                        <h2>올이음 클라우드 서비스 </h2>
                    </div>
                </li>
                <li class="slm_B">
                    <div class="cont_inner">
                        <div class="subTit">합리적인 비용으로 쉽게 사용할 수 있는 </div>
                        <h2>올이음 클라우드 서비스 </h2>
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <!-- section -->
            <section class="sec sec_1">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <h3><span>올이음</span> Advantages</h3>
                        <div class="subTit">
                            올이음은 안전하고 효율적인 IT 서비스 개발과 비즈니스를 연결할 수 있도록 클라우드 서비스를 제공합니다.<br>
                            웹 관리 콘솔을 이용하여 PC/모바일에서 올이음에서 제공하는 서비스를 클릭 몇 번으로 간단하게 구축이 가능합니다.
                        </div>
                    </div>
                    <!-- //sec_header -->
                    
                    <!-- sec_content -->
                    <div class="sec_content">
                        <ul class="step">
                            <li class="lm_1">
                                <p class="tit">합리적인 비용으로 <br>IT 인프라와 플랫폼 이용</p>
                            </li>
                            <li class="lm_2">
                                <p class="tit">쉽게 사용할 수 있는 <br>클라우드 서비스</p>
                            </li>
                            <li class="lm_3">
                                <p class="tit">비즈니스 변화에 <br>빠른 IT 인프라 대응</p>
                            </li>
                        </ul>
                    </div>
                    <!-- //sec_content -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->

            <!-- section -->
            <section class="sec sec_2">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <div class="tit">
                            올이음은 국내 최고의 물류 IT 전문기업인 케이엘넷의 <br>사내벤처로 분사한 클라우드 서비스를 제공하는 스타트업입니다.
                        </div>
                        <a href="/web/introduction" class="btn mid bor_white radius">about us</a>
                    </div>
                    <!-- //sec_header -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->

            <!-- section -->
            <section class="sec sec_3">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <h3><span>올이음</span> service</h3>
                        <div class="subTit">
                            올이음 클라우드의 다양한 서비스로 최적의 비즈니스 환경을 구성해 보세요.
                        </div>
                    </div>
                    <!-- //sec_header -->
                    
                    <!-- sec_content -->
                    <div class="sec_content">
                        <ul class="serviceStep">
                            <li class="fs">
                                <p class="tit"><i class="ico ico_server"></i>서버</p>
                                <p class="cont">비즈니스 환경에 맞춰 효율적으로 활용할 수 있는 서비스를 제공합니다.</p>
                                <ul class="ls_service">
                                    <li>
                                        <a style="cursor: pointer;"(click)="ServiceScrollRouter('map_server')">인스턴스</a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_server')">이미지</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p class="tit"><i class="ico ico_network"></i>네트워크</p>
                                <p class="cont">다양한 서비스유형에 대응할 수 있는 네트워크 자원들을 제공합니다.</p>
                                <ul class="ls_service">
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_network')">로드 밸런스</a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_network')">포트 포워딩</a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_network')">공인IP</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="fs">
                                <p class="tit"><i class="ico ico_storage"></i>스토리지</p>
                                <p class="cont">데이터를 인터넷에 저장하여 서비스로 관리하고 운영할 수 있습니다.</p>
                                <ul class="ls_service">
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_storage')">블록 스토리지</a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_storage')">오브젝트 스토리지</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p class="tit"><i class="ico ico_database"></i>데이터베이스</p>
                                <p class="cont">안정적인 데이터 관리를 위해 서비스 요구사항에 맞는 다양한 DBMS를 제공.</p>
                                <ul class="ls_service">
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_database')">PostgreSQL</a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_database')">MySQL</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p class="tit"><i class="ico ico_backup"></i>백업</p>
                                <p class="cont">서버 및 데이터베이스를 안전하게 보관하기 위한 별도 백업 솔루션 서비스.</p>
                                <ul class="ls_service">
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_backup')">데이터 백업</a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_backup')">DB 백업</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="fs">
                                <p class="tit"><i class="ico ico_monitoring"></i>실시간 모니터링</p>
                                <p class="cont">동작중인 VM 서버의 자원 및 프로세스에 대한 실시간 모니터링 서비스.</p>
                                <ul class="ls_service">
                                    <li>
                                        <a style="cursor: pointer;" (click)="ServiceScrollRouter('map_monitoring')">서버 모니터링</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- //sec_content -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->
            
            <!-- section -->
            <section class="sec sec_4">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <h3><span>올이음</span> 클라우드 고객사</h3>
                        <div class="subTit">
                            <!-- 고객사 소개문구 -->
                        </div>
                    </div>
                    <!-- //sec_header -->
                    
                    <!-- sec_content -->
                    <div class="sec_content">
                        <div class="serviceStep">
                            <a class="fs" href="https://www.klnet.co.kr/" title="케이엘넷 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/klnet.png" alt="케이엘넷"/>
                                </div>
                                <p class="cont pd_t10">바로가기 >></p>
                            </a>
                            <a href="http://www.yjit.co.kr/" title="양재IT 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/yjit.png" alt="양재IT"/>
                                </div>
                                <p class="cont pd_t10">바로가기 >></p>
                            </a>

                            <a class="fs" href="https://www.transhub.co.kr/" title="다이몬소프트 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/transhub.png" alt="다이몬소프트"/>
                                </div>
                                <p class="cont pd_t10">바로가기 >></p>
                            </a>
                            <a href="https://www.chassiradar.co.kr/" title="CnTR 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/CnTR.png" alt="CnTR"/>
                                </div>
                                <p class="cont pd_t10">바로가기 >></p>
                            </a>
                            <!-- <a href="https://www.plism.com/" title="PLISM 3.0 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/PLISM.jpg" alt="PLISM 3.0"/>
                                </div>
                                <p class="cont pd_t10">바로가기 >></p>
                            </a> -->
                            
                            <a href="https://www.logisone.co.kr/" title="로지스원 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/logisone.png" alt="로지스원"/>
                                </div>
                                <p class="cont pd_t10">바로가기 >></p>
                            </a>
                            <a href="https://www.panlogis.co.kr/" title="판로지스 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/panlogis.png" alt="판로지스"/>
                                </div>
                                <p class="cont pd_t10 ">바로가기 >></p>
                            </a>
                            <a class="fs" href="http://www.tjlogis.co.kr/" title="트윈스진테크 바로가기" target="_blank">
                                <div>
                                    <img class="cmncl" src="../../../assets/img/company/twinsjin.png" alt="트윈스진테크"/>
                                </div>
                                <p class="cont pd_t10 ">바로가기 >></p>
                            </a>
                        </div>
                    </div>
                    <!-- //sec_content -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->
        </div>
    </div>
    <!-- //Container -->
    <hr>