<!-- Modal : 외부연결관리 모달 -->
<div id="network_external" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        외부 연결 관리
        <p class="sub-txt">해당 네트워크의 외부 트래픽을 허용/차단 할 수 있습니다. 연결 된 자원 전체가 영향을 받습니다.</p>
        <p class="sub-txt">*외부 연결을 차단하면 공인IP 및 포트포워딩으로도 접속 할 수 없습니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th>네트워크 이름</th>
              <td><input type="text" value="" placeholder="{{selected_network.name}}" readonly></td>
            </tr>
            <tr>
              <th>CIDR</th>
              <td><input type="text" value="" placeholder="{{selected_network.cidr}}" readonly></td>
            </tr>
            <tr>
              <th>외부 연결</th>
              <td>
                <input type="radio" name="field_external_type" id="radio_external_type_able" checked
                  (click)="setAdminState(true)" /><label for="radio_external_type_able" class="mg_r8">
                  허용</label>
                <input type="radio" name="field_external_type" id="radio_external_type_disable"
                  (click)="setAdminState(false)" /><label for="radio_external_type_disable"> 차단</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp1">
        <a (click)="updateRouterState()" class="btn big ciblue mg_r4">적용</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->