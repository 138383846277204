<!-- Modal : 공인IP 연결해제 모달 -->
<div id="ip_disconnect" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>공인IP 연결해제</h2>
    </div>
    <!-- //modal_header -->
    <!-- modal_content -->
    <div class="modal_content" *ngIf="selected_floatingip.server != null">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">대상 IP</th>
                        <td>
                            <span> {{selected_floatingip.ip}}</span>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">서버정보</th>
                        <td>
                            <span>{{selected_floatingip.server.server_name}}({{selected_floatingip.fixed_ip}}) </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp2">
            <a (click)="disassociateFloatingIp()" class="btn big ciblue mg_r4">해제</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->