
<!-- Modal : 서브계정 생성 모달 -->
<div id="user_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        멤버 생성
        <p class="sub-txt">프로젝트를 공유할 멤버를 추가하고 사용할 프로젝트를 할당합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" name="userName" value="" placeholder="이름을 입력해주세요" spellcheck="false" autocomplete="off" maxlength="30" (change)="onChange($event.target)" >
                <div class="invalid-feedback" *ngIf="name_errMsg != ''">
                  <div>{{name_errMsg}}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>
                <input type="email" name="userId" value="" placeholder="아이디(이메일)을 입력해주세요" spellcheck="false" autocomplete="off"
                  (change)="onChange($event.target)" maxlength="127"/>
                <div class="invalid-feedback" *ngIf="id_errMsg != ''">
                  <div>{{id_errMsg}}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td>
                <input type="password" name="password" value="" placeholder="비밀번호를 입력해주세요" maxlength="20"
                  (change)="onChange($event.target)" />
                <div class="invalid-feedback" *ngIf="pw_errMsg != ''">
                  <div>{{pw_errMsg}}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>사용자 유형</th>
              <td>
                <div>
                  <label>
                    <input type="radio" name="type" value="0" (change)="onChange($event.target)" />
                    중간관리자
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="type" checked="checked" value="1" (change)="onChange($event.target)" />
                    운영담당자
                  </label>
                </div>
                <div>

                  <label>
                    <input type="radio" name="type" value="2" (change)="onChange($event.target)" />
                    회계담당자
                  </label>
                </div>
                
              </td>
            </tr>
            <tr>
              <th>프로젝트</th>
              <td>
                  <mat-selection-list #sl disableRipple [formControl]="selected_project_list">
                      <mat-list-option *ngFor="let project of projectList" [value]="project.id" style="font-size: 13px;height: 23px;"  >
                        <ng-template [ngIf]="project.resource_data">{{ project.resource_data.value }}</ng-template>
                        <ng-template [ngIf]="!project.resource_data">Loading...</ng-template>
                      </mat-list-option>
                    </mat-selection-list>

                  <!-- <mat-form-field appearance="outline" floatLabel="never">
                    
                    <mat-select #sl placeholder="프로젝트를 선택해주세요" [formControl]="selected_project_list" multiple disableRipple>
                      <button (click)="sl.close()">Apply</button>
                      <mat-option *ngFor="let project of projectList" [value]="project.id">{{ project.resource_data.value }}</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                    <!-- <li *ngFor="let project of projectList" [class.selected]="project === selected_project" (click)="onClickProject(project)">
                        {{ project.resource_data.value }}
                    </li> -->
                <!-- <select name="project" style="height: 100px;" (change)="onChange($event.target)">
                  <option *ngFor="let project of projectList" value="{{ project.id }}">
                    {{ project.resource_data.value }}
                  </option>
                </select> -->
                <div class="invalid-feedback" *ngIf="project_errMsg != ''">
                  <div>{{project_errMsg}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="createSubUser()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray" id="closeModalBtn">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->