<!-- Container -->
<div id="Container" class="policy">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>이용약관</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>Terms of service</h3>
                    <div class="subTit">
                        올이음 서비스 이용약관은 다음과 같은 내용을 담고 있습니다.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="policyInfo">
                        <dl class="dlTp1">
                            <dt>제1장 총칙</dt>
                            <dd>
                                <p class="tit">제1조 (목적)</p>
                                <ul class="lsTp3">
                                    본 약관은 주식회사 비즈니스링커시스템(이하 "회사"라 함)이 제공하는 올이음 클라우드 서비스 및 관련 제반 서비스(이하 "서비스"라 함)를 이용함에 있어서 회사와 서비스 이용자(이하 "사용자"라 함)간에 이용 조건 및 절차, 권리 및 의무 등에 관한 기본적인 사항에 관하여 규정함을 목적으로 합니다.
                                </ul>
                                <p class="tit">제2조 (약관의 효력)</p>
                                <ul class="lsTp3">
                                    <li>① 본 약관은 서비스가 이루어지는 주된 인터넷 홈페이지(http://www.bizlinkersys.com, 이하 "홈페이지"라 함)에 공시함으로써 그 효력을 발생합니다.</li>
                                    <li>② 회사는 필요에 따라 약관의 내용을 개정할 수 있으며, 약관이 개정된 경우에는 제①항과 같은 방법으로 적용일자와 개정사유를 포함하여 그 내용을 공시함으로써 그 효력을 발생합니다.</li>
                                    <li>③ 회사가 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여 현행 약관과 함께 홈페이지 초기 화면에 그 적용일자 7일 이전부터 공시합니다. 다만, 사용자에게 불리하거나 중대한 사항을 변경하는 경우에는 최소한 30일 이전부터 공시합니다.</li>
                                    <li>④ 개정된 약관의 효력발생일까지 사용자가 회사에 서면으로 이의를 제기하지 아니 한 때에는 개정된 약관을 승인한 것으로 봅니다.</li>
                                    <li>⑤ 본 약관에서 정하지 아니한 사항과 약관의 해석에 관한 사항은 전자문서 및 전자거래기본법, 전기통신사업법 등 관계 법령 및 업계의 상거래 관행에 따름을 원칙으로 합니다.</li>
                                </ul>
                                <p class="tit">제3조 (약관 적용 외의 준칙)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 서비스 별로 별도의 이용약관 및 운영정책(이하 “개별 약관 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별 약관 등이 우선하여 적용됩니다.</li>
                                    <li>② 본 약관에 명시되지 않은 사항은 개별 약관 등과 클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 독점규제 및 공정거래에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의합니다.</li>
                                    <li>③ 본 약관과 관련하여 회사의 운영정책 변경, 법령의 제/개정 또는 공공기관의 고시나 지침 등에 의하여 회사가 홈페이지의 공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.</li>
                                </ul>
                                <p class="tit">제4조 (용어의 정의)</p>
                                <ul class="lsTp3">
                                    이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                                    <li>① 서비스 : 회사가 사용자에게 제공하는 클라우드 기반 IT 리소스 및 플랫폼을 제공하는 클라우드 서비스와 클라우드 서비스 이용을 위한 기술지원 등 관련 제반 서비스</li>
                                    <li>② 사용자 : 회사의 서비스 이용약관에 동의하고 회사가 요구하는 소정의 정보를 제공하여 회원등록을 한 자</li>
                                    <li>③ 아이디(ID) : 사용자의 식별과 서비스 이용을 위하여 회사가 부여 또는 사용자의 선택에 의하여 회사가 승인하는 영문자와 숫자 등의 조합</li>
                                        <p class="pd_l30">1. 기업관리자 아이디(ID) : 사용자를 대표하여 최초로 가입하는 자연인으로서, 업무담당자의 권한 등을 관리하는 모든 권한과 책임을 부여받는 자의 ID</p>
                                        <p class="pd_l30">2. 업무담당자 아이디(ID) : 서비스 이용을 신청하여 기업관리자로부터 허락받은 자연인의 ID</p>
                                    <li>④ 비밀번호 : 승인받은 사용자 ID를 사용할 권리를 가진 자임을 확인하고, 사용자 자신의 비밀 보호를 위하여 사용자가 선정한 영문자와 숫자 등의 조합</li>
                                    <li>⑤ 이용요금 : 서비스를 이용한 대가로 회사의 별도 부과기준 및 체계에 따라 산정되어 부과되는 금액</li>
                                    <li>⑥ 크레딧 : 회사가 프로모션 등을 위하여 조건을 정해 서비스의 사용에 적용할 수 있도록 하는 마일리지 제도</li>
                                </ul>
                            </dd>

                            <dt>제2장 서비스 이용 계약	</dt>
                            <dd>
                                <p class="tit">제5조 (사용자의 자격)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자는 대한민국 국민이거나 대한민국에 사업장을 두고 있는 법인 또는 개인사업자로서, 회사가 정한 정보를 제공할 수 있어야 합니다.</li>
                                    <li>② 다음 각 호에 해당하는 자는 사용자로 등록할 수 없습니다.</li>
                                        <p class="pd_l30">1. 가입신청자가 본 약관 제14조에 의하여 사용자 자격을 상실한 적이 있는 경우. 다만, 사용자 자격 상실 후 3년이 경과하거나, 회사에서 재가입의 승낙을 한 경우는 예외로 합니다.</p>
                                        <p class="pd_l30">2. 등록 내용을 허위로 기재한 경우</p>
                                </ul>
                                <p class="tit">제6조 (서비스 이용 신청)</p>
                                <ul class="lsTp3">
                                    <li>① 서비스 이용 신청은 홈페이지에 접속하여 내려 받은 회사 소정양식의 이용신청서에 제반 정보를 입력하여 사업자등록증 사본 등과 함께 신청합니다.</li>
                                    <li>② 사용자로 가입을 하고자 하는 자는 본 약관에 동의하고, 회사가 정한 이용신청서 양식에 따라 모든 내용들을 허위 없이 기재하여야 합니다.</li>
                                    <li>③ 회사는 가입 신청자가 제②항에 따라 모든 사항을 성실히 기재하여 이용신청을 완료하면 필요사항을 확인한 후, 서비스 이용을 위한 사용자 가입 승인 여부를 결정합니다.</li>
                                    <li>④ 회사는 승인 결과를 가입 신청자의 유선 또는 전자우편 등을 통해 통보하며, 가입 신청자는 홈페이지 접속을 통하여 승인 여부를 확인할 수 있습니다.</li>
                                    <li>⑤ 사용자 이용신청 양식에 기재하는 모든 정보는 모두 실제 데이터인 것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 가입 신청자는 법적인 보호를 받을 수 없으며, 서비스 이용을 제한받을 수 있습니다.</li>
                                    <li>⑥ 사용자는 회사에 제공한 정보의 변경이 있는 경우, 즉시 사용자 정보를 변경하여야 합니다.</li>
                                </ul>
                                <p class="tit">제7조 (이용 신청의 제한 및 승인 보류)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 다음의 각 호에 해당하는 사항을 인지하였을 경우, 해당 이용 신청에 대해서는 신청을 승낙하지 아니합니다.</li>
                                        <p class="pd_l30">1. 타인의 명의를 도용하였거나, 실명이 아닌 가명을 사용하여 신청하였을 때</p>
                                        <p class="pd_l30">2. 가입신청서의 내용을 허위로 기재하였거나, 허위 서류를 첨부하여 신청하였을 때</p>
                                        <p class="pd_l30">3. 기타 가입 신청자의 귀책사유로 가입 승인이 어려운 경우</p>
                                    <li>② 회사는 이용 신청이 다음의 각 호에 해당하는 경우에는, 해당 이용 신청에 대해서는 가입 승인 제한 사유가 해소될 때까지 이용 승인을 제한 및 보류할 수 있습니다.</li>
                                        <p class="pd_l30">1. 서비스 관련 설비의 용량이 부족한 경우</p>
                                        <p class="pd_l30">2. 서비스의 기술상 장애 사유가 발생한 경우 혹은 발생이 예상될 경우</p>
                                        <p class="pd_l30">3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스의 중지로 인한 서비스의 제공이 불가능한 경우</p>
                                        <p class="pd_l30">4. 기타 회사가 서비스를 위해서 필요하다고 인정되는 경우</p>
                                    <li>③ 회사는 제①항 및 제②항의 규정에 의해서 이용신청을 승인할 수 없거나, 승인을 제한하는 경우에는 이를 해당 가입 신청자에게 통지하여야 합니다. 단, 회사의 귀책사유가 아닌 사유로 인해 가입 신청자에게 통지할 수 없는 경우는 예외로서 인정합니다.</li>
                                </ul>
                            </dd>

                            <dt>제3장 서비스 제공 및 이용</dt>
                            <dd>
                                <p class="tit">제8조 (서비스의 이용 개시)</p>
                                <ul class="lsTp3">
                                    회사는 사용자의 이용 신청을 승낙한 때부터 서비스를 개시합니다.
                                </ul>
                                <p class="tit">제9조 (서비스의 제공 및 중단 등)</p>
                                <ul class="lsTp3">
                                    <li>① 서비스는 상품의 종류에 따라 각 개별 서비스로 구성되며, 사용자는 회사의 동의하에 개별 서비스를 선택하여 사용할 수 있습니다.</li>
                                    <li>② 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</li>
                                    <li>③ 회사는 사용자에 대하여 회사 정책에 따라 사용자를 등급별로 구분하여 이용 시간, 이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</li>
                                    <li>④ 다음 각 호의 어느 하나에 해당하는 경우에는 서비스가 제공되지 아니할 수 있으며, 회사는 서비스를 제공할 의무가 없습니다.</li>
                                        <p class="pd_l30">1. 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우</p>
                                        <p class="pd_l30">2. 서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우</p>
                                        <p class="pd_l30">3. 서비스 업그레이드 및 서비스 유지 보수 등을 위해 필요한 경우</p>
                                        <p class="pd_l30">4. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</p>
                                        <p class="pd_l30">5. 인터넷망이나 통신망의 장애</p>
                                        <p class="pd_l30">6. 회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한 경우</p>
                                        <p class="pd_l30">7. 천재지변, 국가 비상사태 등 불가항력적 사유가 있는 경우</p>
                                        <p class="pd_l30">8. 그 밖에 위 각 호에 준하는 사유가 있는 경우</p>
                                    <li>⑤ 회사는 제④항의 경우에는 제11조에서 정한 방법으로 사용자에게 그 사실을 사전에 통지합니다. 단, 회사의 고의, 과실이 없는 해킹, 사고 등 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지 할 수 있습니다.</li>
                                    <li>⑥ 회사는 서비스 제공시 저장되는 개인정보가 아닌 정보를 서비스 제공 이외의 마케팅 활용 목적 등으로 이용할 수 있습니다.</li>
                                </ul>
                                <p class="tit">제10조 (서비스 내용 변경)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 운영상, 경영상, 기술상의 필요에 따라 서비스의 전부 또는 일부 내용을 변경하거나 서비스를 종료할 수 있으며, 서비스의 내용, 이용 방법 등에 변경이 있는 경우에는 변경사유, 변경내용 및 제공일자 등을 변경 7일 전에, 서비스를 종료하는 경우에는 30일 전에 홈페이지에 해당 사실을 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우에는 사후에 지체 없이 이를 공지할 수 있습니다.</li>
                                    <li>② 회사는 본 약관 및 관련 법령에 특별한 규정이 없는 한 서비스의 변경, 내용 수정으로 인하여 사용자가 입은 손해에 대해 어떠한 책임도 지지 않습니다.</li>
                                </ul>
                                <p class="tit">제11조 (정보 제공, 광고 게재)</p>
                                <ul class="lsTp3">
                                        <p class="pd_l30">① 회사는 사용자가 서비스 이용 중 필요하다고 인정되는 거래 관련 정보, 사용자 문의 등에 대한 회신 등의 정보를 공지사항, 전자우편, SMS 등의 방법으로 사용자에게 제공할 수 있습니다. 다만, 사용자는 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.</p>
                                        <p class="pd_l30">② 회사는 관계법령에 따라 적법한 절차를 통해 사용자에게 광고를 제공할 수 있습니다.</p>
                                </ul>
                                <p class="tit">제12조 (게시물 관리)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관계 법령에 위반되는 내용을 포함하는 경우, 권리자는 관계 법령이 정한 절차에 따라 해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며, 회사는 관계 법령에 따라 조치를 취하여야 합니다.</li>
                                    <li>② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관계 법령에 위반되는 경우에는 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.</li>
                                    <li>③ 본 조에 따른 세부절차는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법이 규정한 내용에 따릅니다.</li>
                                </ul>
                                <p class="tit">제13조 (이용계약의 해지)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자가 서비스 이용계약을 해지할 경우에는 해지하고자 하는 날의 5일 전까지 "서비스 해지 신청서"를 회사에 제출하여야 합니다.</li>
                                    <li>② 회사는 다음 각 호의 1에 해당하는 사유가 발생한 때에는 이용계약을 해지 할 수 있으며 일정 기간 내에 재가입을 제한할 수 있습니다.</li>
                                        <p class="pd_l30"> 1. 서비스 이용요금을 정한 기일 내에 납부하지 아니한 경우</p>
                                        <p class="pd_l30"> 2. 관계 법령 및 약관을 위반하여 1회 이상의 시정요구를 받고 이에 불응하여 회사의 업무 및 기술상 막대한 지장을 초래한 경우</p>
                                        <p class="pd_l30"> 3. 기타 회사가 사용자로써 부적당하다고 판단한 경우</p>
                                </ul>
                                <p class="tit">제14조 (서비스 이용 정지)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 사용자의 서비스 이용 내용이 다음 각 호의 어느 하나에 해당하는 경우 사전 통지 없이 사용자의 서비스에 대한 이용을 정지할 수 있습니다.</li>
                                        <p class="pd_l30">1. 요금납기월 14일까지 요금을 납입하지 아니한 경우</p>
                                        <p class="pd_l30">2. 사용자의 가상머신의 과다 트래픽 발생이나 브로드캐스팅으로 서비스 중인 네트워크에 장애 발생이 우려되는 경우</p>
                                        <p class="pd_l30">3. 사용자의 가상머신이 DDoS 공격에 노출 또는 해킹 및 바이러스에 감염이 의심되는 경우</p>
                                        <p class="pd_l30">4. 사용자의 가상머신 이미지(가상머신 생성시 필요한 기본 Metadata와 데이터를 담고 있는 이미지 파일) 서비스를 통해 공유한 이미지에 악성 프로그램이 설치되어 다른 사용자의 개인 정보 유출 등 보안상 위험이 있는 경우 또는 공유된 이미지에 정당한 사용권한이 없는 상용 소프트웨어가 설치되어 있는 등 제3자의 지적 재산권 침해와 관련한 분쟁이 발생할 우려가 있는 경우</p>
                                        <p class="pd_l30">5. 본 약관 제 19조에 규정된 사용자의 의무를 위반한 경우</p>
                                        <p class="pd_l30">6. 회사의 정상적인 서비스 운영을 방해한 경우</p>
                                        <p class="pd_l30">7. 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관계 법령을 위반한 경우</p>
                                        <p class="pd_l30">8. 불법프로그램 사용자 등 위법행위자로 합리적 의심이 되는 경우</p>
                                </ul>
                                <p class="tit">제15조 (서비스 이용정지에 대한 이의 신청)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자가 회사의 이용정지 및 이용제한에 불복하고자 할 때에는 이에 불복하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.</li>
                                    <li>② 본 조 제①항의 이의신청서를 접수한 회사는 합리적인 기간 내에 사용자의 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 회사는 처리에 장기간이 소요되는 경우에는 그 사유와 처리일정을 서비스에 공지하거나 통보합니다.</li>
                                </ul>
                            </dd>

                            <dt>제4장 권리와 의무</dt>
                            <dd>
                                <p class="tit">제16조 (양도 등의 금지)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자가 서비스를 제공받는 권리는 제17조의 규정에 의해 승계되는 경우를 제외하고는 이를 양도하거나 증여, 처분할 수 없습니다.</li>
                                    <li>② 사용자는 회사의 사전 동의 없이 제3자로 하여금 서비스를 이용하게 해서는 안 됩니다.</li>
                                </ul>
                                <p class="tit">제17조 (사용자의 지위 승계)</p>
                                <ul class="lsTp3">
                                    <li>① 다음 각 호의 사유가 발생한 경우에는 사용자의 지위를 승계합니다.</li>
                                        <p class="pd_l30">1. 법인이 다른 법인을 흡수 합병하여 존속하는 법인이 그 지위를 승계한 경우</p>
                                        <p class="pd_l30">2. 둘 이상의 법인이 하나의 법인으로 신설합병하여 그 새로운 법인이 지위를 승계한 경우</p>
                                        <p class="pd_l30">3. 타인의 영업을 양수하여 그 영업을 승계하는 경우</p>
                                    <li>② 제①항의 규정에 의하여 사용자의 지위를 승계한 자는 승계일로부터 30일 이내에 사용자의 지위 승계 사실을 증명하는 서류를 첨부하여 회사에 제출하여야 합니다.</li>
                                </ul>
                                <p class="tit">제18조 (회사의 의무)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적으로 서비스를 제공할 의무가 있습니다.</li>
                                    <li>② 회사는 서비스 제공 및 청약과 관련하여 알게 된 사용자의 신상 정보나 중요한 사업내용에 대해 본인의 승낙 없이 제3자에게 누설, 배포하여서는 안 됩니다. 다만, 전기통신기본법 등 관계법령의 규정에 의하여 관계 국가기관의 요구가 있는 경우에는 그러하지 않습니다.</li>
                                </ul>
                                <p class="tit">제19조 (사용자의 의무)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자는 서비스 이용에 대한 대가로서 이 서비스에서 정한 요금 등을 요금 납부책임자와 연대하여 납입하여야 합니다.</li>
                                    <li>② 사용자는 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 사용자의 이용 이외의 목적으로 복제하거나 제3자에게 제공하여서는 아니 됩니다.</li>
                                    <li>③ 사용자는 회사의 사전 서면 승낙 없이 로봇, 스파이더, 기타 자동화 프로그램 등을 회사의 웹 페이지 또는 웹 페이지에 있는 내용을 감시, 복제 또는 다운로드하기 위하여 사용하여서는 아니 되며, 그러한 사용으로 회사에 직·간접적인 손해를 입힌 경우 이를 배상하여야 합니다.</li>
                                    <li>④ 사용자는 회사가 제공하는 서비스 이용을 위하여 제공된 자료 및 정보 일체를 회사의 동의 없이 제3자에게 제공할 수 없습니다.</li>
                                    <li>⑤ 사용자는 제①항, 제②항, 제③항 및 제④항 이외에도 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.</li>
                                </ul>
                            </dd>

                            <dt>제5장 서비스 이용요금</dt>
                            <dd>
                                <p class="tit">제20조 (서비스 이용요금)</p>
                                <ul class="lsTp3">
                                    <li>① 서비스의 이용요금은 홈페이지의 ‘이용요금’에 게시된 바와 같습니다. 단, 회사와 별도 계약을 체결한 경우에는 별도로 약정한 바에 따릅니다.</li>
                                    <li>② 서비스 이용요금은 월 단위로 부과합니다. 단, 별도의 약정이 있을 경우에는 과금방식을 달리할 수 있습니다.</li>
                                    <li>③ 월 정액제 요금은 사용량 유무와 무관하게 해지한 해당 월까지의 요금을 부과합니다.</li>
                                    <li>④ 이용요금은 사용자(기업/개인)별로 부과함을 원칙으로 합니다.</li>
                                    <li>⑤ 사용자는 서비스 이용요금에 부과되는 부가가치세를 별도로 부담하여야 합니다.</li>
                                </ul>
                                <p class="tit">제21조 (요금납입책임자)</p>
                                <ul class="lsTp3">
                                    <li>① 요금납입책임자는 서비스 사용자를 원칙으로 합니다. 단, 회사가 인정하는 경우에는 타인을 요금납입책임자로 지정할 수 있습니다.</li>
                                    <li>② 제①항의 규정에 의한 요금납입책임자는 사용자가 회사에 대하여 부담하는 서비스 이용 요금 등 약관에 따른 모든 채무를 사용자와 연대하여 회사에 납부할 책임이 있습니다.</li>
                                </ul>
                                <p class="tit">제22조 (요금부과 및 납부)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 전월 1일부터 전월 말일까지 발생한 서비스 이용요금을 당월 5일에 청구합니다.</li>
                                    <li>② 회사는 서비스요금 청구서를 납입기일 7일전까지 회사가 정한 방법으로 요금납입책임자에게 도달하도록 발송해야 합니다. 다만, 요금납입책임자가 자동이체, 신용카드 등 자동납부방식으로 요금 등을 납부하기로 한 경우에는 그러하지 않습니다.</li>
                                    <li>③ 사용자는 요금청구서에 기재된 기일까지 회사가 지정하는 장소에 지정하는 방법으로 요금을 납입해야 합니다.</li>
                                    <li>④ 사용자는 요금과 관련하여 청구서 등의 정확한 수령을 위해 청구서 발송과 관련된 변경사항을 회사에 통보하여야 하며, 사용자의 통보누락 등으로 인하여 발생한 불이익에 대해 회사는 책임지지 않습니다.</li>
                                </ul>
                                <p class="tit">제23조 (연체료의 부과)</p>
                                <ul class="lsTp3">
                                    <li>① 요금납입책임자가 지정된 기일까지 요금을 납부하지 아니한 때에는 그 체납된 요금의 100분의 2에 상당하는 금액을 가산금으로 납부하여야 합니다.</li>
                                    <li>② 제①항의 규정에 의한 가산금은 요금 납부 만료일의 익일을 기준으로 하여 체납된 요금에 가산하여 청구하며, 체납요금 및 연체료는 익월 요금청구서에 포함하여 청구합니다.</li>
                                    <li>③ 사용자의 서비스 이용요금이 7일 이상 미납 될 경우 회사는 사전 통보 없이 사용자가 사용하는 서비스에 대해 이용 정지할 수 있고, 1개월 이상 미납 될 경우 회사는 약관 및 홈페이지에 미리 공지한 절차와 방식에 따라 서비스를 해지할 수 있으며, 이에 따른 사용자의 손해에 대해서 회사는 어떠한 책임도 지지 않습니다.</li>
                                </ul>
                                <p class="tit">제24조 (요금의 반환)</p>
                                <ul class="lsTp3">
                                    <li>① 요금의 과오납입액이 있는 때에는 회사는 해당 사용자의 청구에 따라 다음 각 호의 1에 해당하는 조치를 합니다.</li>
                                        <p class="pd_l30">1. 과납청구한 요금 등에 대하여는 그 금액을 감액합니다.</p>
                                        <p class="pd_l30">2. 과납입한 요금 등에 대하여는 그 금액을 반환하거나 다음 달에 청구할 요금에서 상계합니다.</p>
                                        <p class="pd_l30">3. 요금 등을 반환받아야 할 사용자의 요금 등의 체납이 있는 경우, 회사는 반환해야 할 요금 등에서 이를 우선 공제하고 반환할 수 있습니다.</p>
                                        <p class="pd_l30">4. 반환받을 요금 등의 수령권자가 2인 이상일 경우에는 그 중 1인에게 이를 반환합니다. 이 때, 회사는 요금을 반환받을 자를 임의로 결정할 수 있으며, 요금을 반환받지 못한 수령권자는 회사에 대하여 이의를 제기할 수 없습니다.</p>
                                    <li>② 제①항의 규정에 의한 요금반환 청구는 그 사유가 발생한 날로부터 기산하여 6개월을 경과 한 때에는 청구 할 수 없습니다.</li>
                                    <li>③ 회사는 과소청구액에 대해서는 익월에 합산하여 청구합니다.	</li>
                                </ul>
                                <p class="tit">제25조 (크레딧)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 회사의 정책에 따라 사용자에게 서비스에서 사용할 수 있는 크레딧을 지급할 수 있습니다.</li>
                                    <li>② 크레딧의 세부 구분, 이용금액, 사용방법, 사용기간 및 제한에 대한 사항은 크레딧 또는 서비스 화면에 표시됩니다. 크레딧의 종류 및 내용에 대해서는 회사에서 정할 수 있습니다.</li>
                                    <li>③ 크레딧은 현금으로 출금 및 환금될 수 없으며, 크레딧에 표시된 유효기간이 만료되거나 이용계약이 종료되면 소멸합니다.</li>
                                    <li>④ 크레딧은 서비스 화면에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 부정한 목적이나 부정한 용도로 사용할 수 없습니다.</li>
                                    <li>⑤ 사용자가 부당 또는 부정하게 크레딧을 취득한 경우, 크레딧을 사용할 수 없으며, 회사는 이를 회수할 수 있습니다.</li>
                                </ul>
                            </dd>

                            <dt>제6장 사용자 정보 보호정책	</dt>
                            <dd>
                                <p class="tit">제26조 (사용자 정보의 보호)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 수집된 사용자 정보가 분실·도난·누출·변조 또는 훼손되지 아니하도록 보안 및 안전성 확보에 필요한 기술적인 조치 등을 취하여야 합니다.</li>
                                    <li>② 회사는 서비스와 관련하여 취득한 사용자의 정보 및 사용자의 개인정보를 본인의 사전 승낙 없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스 관련 업무이외의 상업적 목적으로 사용할 수 없습니다. 다만, 다음 각 항에 해당하는 경우에는 그러하지 아니합니다.</li>
                                        <p class="pd_l30">1. 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구받은 경우</p>
                                        <p class="pd_l30">2. 방송통신심의위원회의 요청이 있는 경우</p>
                                        <p class="pd_l30">3. 서비스 제공에 따른 이용요금의 정산을 위하여 필요한 경우</p>
                                        <p class="pd_l30">4. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서, 특정 개인을 식별할 수 없는 형태로 제공하는 경우</p>
                                        <p class="pd_l30">5. 기타 관계 법령에 의해 필요한 경우</p>
                                </ul>
                                <p class="tit">제27조 (사용자 정보의 열람 및 정정)</p>
                                <ul class="lsTp3">
                                    사용자는 언제든지 회사의 홈페이지에서 본인의 개인정보를 열람 및 정정할 수 있습니다. 단, 사용자는 서비스의 특성상 사업자등록증상의 정보는 임의대로 변경할 수 없으며, 이 경우 정보정정을 증빙할 수 있는 관련서류(변경된 사업자등록증 사본 등)를 회사에 송부하여, 소정의 확인절차를 거쳐 회사에서 처리함을 원칙으로 합니다.
                                </ul>
                            </dd>

                            <dt>제7장 기타</dt>
                            <dd>
                                <p class="tit">제28조 (기밀유지)</p>
                                <ul class="lsTp3">
                                    사용자와 회사는 본 계약과 관련하여 취득한 상대방의 영업상 또는 기술상의 비밀이나 정보를 제3자에게 누출하거나 유출할 수 없습니다.
                                </ul>
                                <p class="tit">제29조 (손해배상)</p>
                                <ul class="lsTp3">
                                    <li>① 사용자는 제19조 및 기타 본 약관의 규정을 위반함으로 인하여 회사가 다른 사용자 또는 제3자에 대하여 책임을 부담하게 되고 이로 인해 회사가 손해를 입게 되는 경우, 사용자는 회사가 입은 일체의 손해에 대하여 배상하여야 합니다.</li>
                                    <li>② 회사는 서비스 제공과 관련하여 다음 각 호의 사유로 사용자에게 어떠한 손해가 발생한 경우 회사는 이에 대하여 책임지지 아니합니다.</li>
                                        <p class="pd_l30">1. 천재지변, 제3자의 고의적 서비스 방해 및 기타 불가항력적인 사유로 인해 서비스를 제공하지 못한 경우</p>
                                        <p class="pd_l30">2. 사용자가 제공하는 자료의 오류로 인하여 사용자에게 손해가 발생한 경우</p>
                                        <p class="pd_l30">3. 통신회선 등 회사 이외의 전산 시스템 장애에 의하여 서비스를 제공하지 못한 경우</p>
                                        <p class="pd_l30">4. 바이러스 침투, 불법 소프트웨어 설치 등 관리 소홀 및 부주의 등 사용자의 귀책사유로 인한 서비스 이용 장애의 경우</p>
                                        <p class="pd_l30">5. 기타 회사의 과실 없는 사유로 인하여 사용자에게 손해가 발생한 경우</p>
                                </ul>
                                <p class="tit">제30조 (회사의 손해배상)</p>
                                <ul class="lsTp3">
                                    <li>① 회사는 회사의 책임 있는 사유로 장애가 발생하여 아래 정의한 월 가용성 구간 미만 제공으로 사용자가 손해를 입은 경우 사용자의 청구에 의해서 손해를 배상합니다.</li>
                                        <p class="pd_l30">1. 월 가용성(%) = 100 * ｛1-(서비스를 이용한 1개월 동안 회사의 책임 있는 사유로 인한 장애로 서비스를 이용하지 못한 장애시간(시간단위 분)의 합 / 서비스를 이용할 수 있는 기간 1개월 동안의 시간단위 분)｝</p>
                                        <p class="pd_l30">2. 장애는 서비스를 이용하지 못한 사실을 사용자가 회사에 통지한 때(사용자의 통지 전에 회사가 그 사실을 알 경우는 회사가 그 사실을 알게 된 때)부터 장애시간은 측정됩니다.</p>
                                    <li>② 본 조 제①항의 손해배상 금액은 아래의 금액을 기준으로 하여 결정합니다. 단, 회사가 서비스 별 특성에 따라 별도의 손해배상 기준을 마련하여 사용자에게 운영정책으로 안내하거나 홈페이지에 공지하는 경우에는 손해배상 금액의 산정 기준이 다르게 적용 될 수 있습니다.</li>
                                        <p class="pd_l30">1. 월 가용성 99.0% 이상 ~ 99.9% 미만 : 3개월 월 평균 사용금액의 10%에 해당하는 금액</p>
                                        <p class="pd_l30">2. 월 가용성 95.0% 이상 ~ 99.0% 미만 : 3개월 월 평균 사용금액의 25%에 해당하는 금액</p>
                                        <p class="pd_l30">3. 월 가용성 95.0% 미만 : 3개월 월 평균 사용금액의 50%에 해당하는 금액</p>
                                    <li>③ 사용자가 본 조 제①항에 따라 회사에 손해배상을 청구하고자 하는 경우 이에 대한 사유, 청구액 및 산출 근거, 장애에 대한 상세 내용을 기재하여 서면으로 신청하여야 합니다.</li>
                                    <li>④ 본 조에 따른 손해배상액은 사용자의 선택에 따라 다른 서비스 구매나 해당 서비스의 월 이용 요금으로 사용될 수 있습니다.</li>
                                    <li>⑤ 사용자가 서비스를 이용하지 못함으로 인한 회사의 배상책임은 제①항에 의한 손해배상에 한정되며, 이를 제외한 회사의 추가적인 손해배상은 없습니다.</li>
                                    <li>⑥ 회사는 회사가 제공하는 무료서비스 이용과 관련하여 사용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.</li>
                                    <li>⑦ 본 약관 하에서 회사의 사용자에 대한 총 배상책임은 사용자가 회사에게 지불한 금액으로 제한됩니다.</li>
                                </ul>
                                <p class="tit">제31조 (분쟁의 해결 및 관할법원)</p>
                                <ul class="lsTp3">
                                    <li>① 서비스와 관련하여 분쟁이 발생한 경우 관련법규가 있으면 그 관련법규를 따르고 관련법규가 없으면 관습 및 신의성실의 원칙에 입각, 상호 협의하여 해결합니다.</li>
                                    <li>② 서비스와 관련하여 발생한 분쟁이 제①항에 따라 원만하게 해결되지 아니할 경우, 이와 관련된 소송의 관할법원은 회사의 본점 소재지를 관할하는 법원으로 합니다.</li>
                                </ul>
                            </dd>
                            <!-- <dd>                                
                            </dd> -->

                            <dt>부칙</dt>
                            <dd>
                                <p class="tit">제1조 (시행일)</p>
                                <ul class="lsTp3">
                                    이 약관은 2020년 8월 3일부터 시행합니다.
                                </ul>
                            </dd>
                        </dl>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->        
    </div>
</div>
<!-- //Container -->
<hr>