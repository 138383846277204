import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WebApiService } from '../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-mypage-withdrawal',
  templateUrl: './mypage-withdrawal.component.html',
})
export class MypageWithdrawalComponent implements OnInit {
  userId = ""
  registrationNumber = '';
  registrationNumberSub ='';
  submitted = false;

  constructor(
    private webapi: WebApiService,
    private router: Router,
    private route: ActivatedRoute,
    private spiner: NgxSpinnerService
  ) { }

  withdrawalForm = new FormGroup(
    {
      reason: new FormControl('', [
        Validators.required
      ])
    }
  )

  get wirhdawal() {
    return this.withdrawalForm.controls
  }

  ngOnInit(): void {
    this.spiner.show();
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.userId = params['userId']
      this.registrationNumber = params['registrationNumber']
      this.registrationNumberSub = params['registrationNumberSub']
    });
    this.spiner.hide();
  }

  onSubmit(): void {
    this.submitted = true
    if (this.withdrawalForm.invalid) {
      const invalid = [];
      const controls = this.withdrawalForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      alert('탈퇴 사유를 입력해주세요.')
      return;
    }
    this.spiner.show();
    this.webapi.postWithdrawals({ ...this.withdrawalForm.value, userId: this.userId, registrationNumber: this.registrationNumber, registrationNumberSub: this.registrationNumberSub }).subscribe(
      (response) => {

        alert('회원 탈퇴 신청이 완료 되었습니다.');
        this.router.navigate(['/web']);
      }
    ), (err) => {

      if (err.status === 400) {
        alert("이미 탈퇴신청 하셨습니다")
      }

    }
    this.spiner.hide();
  }
}
