<!-- Container -->
<div id="Container" class="mypage">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>크레딧</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>크레딧</h3>
                    <div class="subTit">
                        이곳에서 회원님의 크레딧 정보를 보실 수 있습니다.
                    </div>
                    <!-- sec_content_결제정보 존재 시 -->
                    <div class="sec_content" style="padding-bottom: 30px;">
                        <div class="rowGroup">
                            <div class="row">
                                <p class="tit"><B>사용 가능한 크레딧</B></p>
                                <p class="cont">
                                    <span>{{availableCredit | number }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- //sec_header -->                                   
                <div class="contArea">
                    <div class="boardBox">
                        <table class="tbl_col_tp2 colorTpA">
                            <colgroup>
                                <col style="width: 60px;">
                                <col style="width: 100px;">
                                <col style="width: 100px;">
                                <col style="width: 100px;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">번호</th>
                                    <th scope="col">크레딧</th>
                                    <th scope="col">증감(+/-)</th>
                                    <th scope="col">내역</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="totalCount > 0">                                    
                                <tr *ngFor="let credit of creditHistoryInfo; index as i">                                        
                                    <td>{{ 10 * curPage + (i + 1) }}</td>
                                    <td>{{ credit.credit | number }}</td>
                                    <td>
                                        <label [class]="credit.sign === 0 ? 'dot red' : 'dot green'"></label>
                                        {{ credit.sign === 0 ? '감소(-)' : '증가(+)' }}
                                    </td>
                                    <td>{{ credit.createdDate | date:'yyyy-MM-dd HH:mm:ss' }}</td>                                                                                
                                </tr>
                            </tbody>
                            <tbody *ngIf="totalCount < 1">
                                <tr><td colspan="4">조회된 내역이 없습니다</td></tr>
                            </tbody>
                        </table>
                        <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
                    </div>
                    <ul class="lsTp5">
                        <li>문의사항은 고객센터로 문의해주시기 바랍니다.</li>
                    </ul>
                </div>
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->

        
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>