<div class="board_pager_wrap tp1">
    <div class="board_pager">
        <ul>
            <a class="btn_first" href="javascript:void(0)" *ngIf="curRange>0 && curRange!=1" (click)="chagnePage(0)">[<<]</a>
            <a class="btn_prev" href="javascript:void(0)" *ngIf="curPage!=0" (click)="chagnePage(prevPage)">[<]</a>
            <span *ngFor="let i of pageArr | slice:startPage-1:endPage;">
                <strong class="boardPage" *ngIf="curPage==i" >{{i+1}}</strong>
                <a class="boardPage" href="javascript:void(0)" *ngIf="curPage!=i" (click)="chagnePage(i)">{{i+1}}</a>
            </span>
            <a class="btn_next" href="javascript:void(0)" *ngIf="curPage!=(totalPageCount-1) && totalPageCount>1 " (click)="chagnePage(nextPage)">[>]</a>
            <a class="btn_last" href="javascript:void(0)" *ngIf="curRange!=totalRangeCount && totalRangeCount>0" (click)="chagnePage(totalPageCount-1)">[>>]</a>
        </ul>
    </div>
</div>

<!--    
부모창에서 해야할 것

처음 this.curPage는 0으로...

서치 param  l=10  <-rowCount
            s="+(this.curPage*[rowCount]])

서치 후 this.totalCount = data['total'];
-->