import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-nas-expansion',
  templateUrl: './dialog-nas-expansion.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNasExpansionComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNasExpansionComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  newSize: number;

  selected_nas: any = {};

  ngOnInit(): void {

    this.newSize=null;
    this.selected_nas=this.dialogData.selected_nas;
  }

  extendNas() {
    if (!this.newSize || this.newSize < this.selected_nas.size) {
      alert('현재 크기보다 큰 새로운 크기를 입력해주세요.');
      return;
    } else {
      try {
        this.spinner.show();
        this.osapi
          .extendNas(this.selected_nas.id, this.newSize)
          .subscribe(() => {
            this.spinner.hide();
            this.selected_nas.size = this.newSize;
            this.closeDialogMsg({code:"success",data:this.selected_nas});
          }, 
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
          });
      } catch (e) {

      }
    }
  }

  
}
