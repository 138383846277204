<!-- Modal : Bucket 생성 모달 -->
<div id="folder_create" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      오브젝트 업로드
      <!-- <p class="sub-txt">Object Storage Bucket을 생성합니다.</p> -->
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <div class="uploadfilecontainer" (click)="fileInput.click()" appUploadFile (onFileDropped)="uploadFile($event)" [style.display]="sending_files?'none':'block'">
        <input hidden type="file" #fileInput (change)="uploadFile($event)" multiple>
        <!-- <input hidden type="file" #fileInput (change)="uploadFile(fileInput.files)" multiple> -->
      </div>
      <div class="files-list" *ngFor="let file of newObjects;let i= index">
        <p> {{ file }} </p>
        <button *ngIf="!sending_files" class="delete-file" (click)="deleteAttachment(i)">
          <i class="fa fa-trash"></i>
        </button>
        <div *ngIf="sending_files" class="file_progress" [attr.id]="file+'_progress'"></div>
      </div>
    </div>
    <div class="btnArea tp2">
      <button (click)="uploadObject()" class="btn big ciblue mg_r4" [disabled]="sending_files">업로드</button>
      <button (click)="closeUploadDialog()" class="btn big bor_gray">닫기</button>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->