<!-- Container -->
<div id="Container" class="notice">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>자료실</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>자료실</h3>
                    <div class="subTit">
                        올이음 클라우드 서비스를 사용하기 위해 필요한 자료들 입니다.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="boardBox">
                        <div class="noticeDetail">
                            <div class="titArea">
                                <p class="tit">{{materialView.title}}</p>
                                <p class="date">{{materialView.createdDate}}</p>
                            </div>
                            <div class="contArea">
                                <div class="r" *ngIf="materialView.originalFileName">
                                    <a href="javascript:void(0)" (click)="downloadFile()">첨부파일 다운로드</a>
                                </div>
                            </div>
                            <div class="contArea">
                                <div class="cont">
                                    <pre>{{materialView.contents}}</pre>
                                </div>
                            </div>
                        </div>

                        <div class="btn_wrap tp1">
                            <a routerLink="/web/materialList" class="btn big black">목록</a>
                        </div>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>