import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-network-list',
  templateUrl: './mobile-console-network-list.component.html',
  styleUrls: ['./mobile-console-network-list.component.css']
})
export class MobileConsoleNetworkListComponent implements OnInit {
  resource: any = [];
  networkLists: any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getNetworkList();
  }

  getNetworkList() {
    this.osapi.getResourceAll().subscribe((data: any[]) => {
      this.resource = data;

      this.osapi.getNetworkList().subscribe((data:any) => {
        this.networkLists = [];
        // 외부 네트워크만 목록에 담기
        for(let network of data){
          console.log(network);
          if(network.network_type == "ext"){
            this.networkLists.push(network);
          }
          // Default Network 찾기
          network.isDefaultNetwork = false;
          if(network.name == 'Default Network' && network.cidr == "192.168.0.0/16"){
            network.isDefaultNetwork = true;
          }
        }
        //this.networkList = data;
        // 초기화
        for (let item of this.networkLists) {
          item.external_gateway_info = {}
          item.external_gateway_info.enable_snat = true;
        }
        // cidr 찾기
        for (let r of this.resource) {
          for (let n of this.networkLists) {
            if (r.objectId == n.id) {
              n.cidr = r.value;
              // this.usedCidrList.push(r.value);
            }
          }
        }
        // 라우터 찾기 admin_state 값 UP/DOWN 외부 연결 허용/차단
        // 라우터 ID 가져오기
        for (let r of this.resource) {
          for (let n of this.networkLists) {
            if (r.resourceType === 'router') {
              if (n.project_id == r.projectId) {
                if (r.value === n.cidr) {
                  n.routerId = r.objectId;
                  var routerId = r.objectId;
                  this.osapi.getRouters(routerId).subscribe((data: any) => {
                    n.router_state = data.admin_state_up;
                    n.router_id = data.id;
                    n.external_gateway_info = data.external_gateway_info;
                  },(error) => {
                      this.spinner.hide();
                      this.frontapi.changeMessage({
                        type: 'toast',
                        body: {
                          type: 'error',
                          title: '오류 알림',
                          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
                        }
                      });
                    });
                }
              }
            }
          }
        }
        this._blank = ((this.networkLists != 0) ? true : false);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
    },
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
