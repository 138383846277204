import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-private-network-create',
  templateUrl: './dialog-private-network-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogPrivateNetworkCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogPrivateNetworkCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  networkModel: any;

  resource: any = [];

  network_cidr_validate= true;
  network_cidr_not_used= true;
  private_network_cidr_validate=true;

  ngOnInit(): void {

    this.networkModel = this.dialogData.networkModel;
    this.resource = this.dialogData.resource;
  }


  createNetwork() {

    if (this.networkModel.name == ''|| this.networkModel.name == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `이름을 입력해주세요.`
        }
      });
      return;
    }
    if (this.isAlreadyUsedCidr(this.networkModel.cidr)) {
      this.networkModel.cidr = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `이미 사용중인 네트워크 대역입니다.`
        }
      });
      return;
    }
    if (this.network_cidr_validate == false|| this.private_network_cidr_validate == false) {
      this.networkModel.cidr = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `유효하지 않은 CIDR 입니다.`
        }
      });
      return;
    }
    if ( this.networkModel.name =="net_str" || this.networkModel.name =="net_ext" || this.networkModel.name =="net_pri") {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "'net_str', 'net_ext', 'net_pri'는 사용불가능한 이름입니다."
        }
      });
      return;
    }
    this.spinner.show();
    let name = this.networkModel.name;
    let cidr = this.networkModel.cidr;
    let type = this.networkModel.type;

      /**
       * 내부 네트워크 생성
       * 1. 네트워크 생성
       * 2. Resource 등록
       */

      // 프로젝트가 여러개면?
      // session 정보에서 현재 프로젝트 정보 가져옴(BACK단)
      // let project;
      // for(let item of this.resource){
      //   if(item.resourceType == "project"){
      //     project = item;
      //     break;
      //   }
      // }

      this.osapi.createNetwork(true, name, false).subscribe((data: any) => {



        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'success',
            title: '네트워크 생성',
            message: `내부 네트워크 ${name} 생성이 완료되었습니다.`
          }
        });

        const params = {
          resourceType: 'network',
          value: cidr,
          objectId: data.id,
          description:"int",
          status: "CREATING"
        };
        this.osapi.blsSetResource(params).subscribe((data: any) => {

          this.closeDialogMsg("success");
          
          if(this.router.url=='/console/privateNetwork'){
            this.router.navigateByUrl('/console', {
              skipLocationChange: true,
            }).then(
              () => {
                this.router.navigate(['/console/privateNetwork']);
              }
            );
          }else{
            this.router.navigate(['/console/privateNetwork']);
          }
        },
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
        // setTimeout(() => {
        //   this.getNetworkList();
        // }, (2000));

        //this.getResource();
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    
  }
  
  isAlreadyUsedCidr(new_cidr) {
    //리소스 테이블에서 해당 유저가 사용중인 네트워크 CIDR 을 불러와 중복 체크 수행
    let chk_flag = false;
    for(let rsc of this.resource){
      if(rsc.resourceType == "network"){

        if (!this.compareCidr(new_cidr, rsc.value)) {
          chk_flag = true;
        }
      }
    }
    return chk_flag;
  }
  
  validNetworkName() {
    /**
     * 영문 숫자 _ 만 허용
     */
    var reg = /^[a-zA-Z0-9_\-+]*$/;
    if (reg.test(this.networkModel.name)) {

    } else {
      this.networkModel.name = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '영문자, _(언더바), -(하이픈) 만 사용 가능합니다.'
        }
      });

    }
  }
  validNetworkCidr() {

    //CIDR 체크  masking 비트가 포함된 IPv4 주소 문자열 true
    var hard_cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
   
    if(hard_cidr_filter.test(this.networkModel.cidr) == false){
      this.network_cidr_validate = false;
      this.network_cidr_not_used = true;
      this.private_network_cidr_validate = true;
      return;
    }else{
      this.network_cidr_validate = true;
    }

    //200812 임동현 주석처리 - CIDR 일괄체크

    // 사용 중인지 대역인지 유효성 검사
    if (this.isAlreadyUsedCidr(this.networkModel.cidr)) {
      this.network_cidr_not_used = false;
      this.network_cidr_validate = true;
      this.private_network_cidr_validate = true;
      return;
    }else{
      this.network_cidr_not_used = true;
    }

    this.private_network_cidr_validate = this.validPrivateNetworkCidr(this.networkModel.cidr);
  }
  
  validPrivateNetworkCidr(cidr) {
    var ip192 = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}/;
    var ip172 = /(172)\.(1[6-9]|2[0-9]|3[0-2])(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}/;
    var ip10 = /(10)(\.([2]([0-5][0-5]|[01234][6-9])|[1][0-9][0-9]|[1-9][0-9]|[0-9])){3}/;

    const ip_addr = cidr.split('/')[0];
    const masking_bit = cidr.split('/')[1];
    if (ip192.test(ip_addr)) {
      if (parseInt(masking_bit) < 16 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }
    else if (ip172.test(ip_addr)) {
      if (parseInt(masking_bit) < 12 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }
    else if (ip10.test(ip_addr)) {
      if (parseInt(masking_bit) < 8 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }

  compareCidr(cidr, cidr2) {
    var split_masking_bit = cidr.split('/');
    var split_masking_bit2 = cidr2.split('/');
    var splited = split_masking_bit[0].split('.');
    var splited2 = split_masking_bit2[0].split('.');

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    let original_bit2 = '';
    for (var i of splited2) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit2 += bit;
    }

    let chk_flag = false;
    for (var i: any = 0; i < split_masking_bit[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag = true;
    }
    let chk_flag2 = false;
    for (var i: any = 0; i < split_masking_bit2[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag2 = true;
    }
    return chk_flag && chk_flag2;
  }

}
