<!-- Modal : 공인IP 생성 모달 -->
<div id="ip_create_test" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>공인IP 생성
        <p class="sub-txt">서버로부터 사용가능한 공인 IP 1개를 생성합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1" style="min-height: 100px;">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">설명</th>
              <td>
                <textarea [(ngModel)]="newFloatingIpData.description" name="" rows="5" placeholder="내용을 입력해주세요" maxlength="255"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="createFloatingIp()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->