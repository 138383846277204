<div id="Header">
	<div class="logo"style="cursor: pointer;" (click)="routerHome()">
		<a></a>
	</div>
</div>
<body class="signUp">
	<!-- wrap -->
	<div id="Wrap">
		<!-- frm_content -->
		<div style="padding: 25px;">

		</div>
		<div class="frm_content">
			<!-- <div class="tag">login</div> -->
			<!-- logo -->
			<div class="logoArea" routerLink="/web">
				<div class="logo">
					<!-- <img src="../assets/img/member/bls_black.png" alt=""  style="cursor: pointer;"> -->
					<img src="../assets/img/member/logo_member.png" alt=""  style="cursor: pointer;">
				</div>
				<h1>올이음 클라우드 회원가입</h1>
			</div>
			<!-- //logo -->

			<div class="tab_wrap" id="tabFlag">
				<fieldset class="signUpForm">
					<legend>회원가입</legend>
					<!-- <ul class="tabMenu">
						<li class="on"><a>기업</a></li>
						<li><a>개인</a></li>
					</ul> -->
					<div class="tab_content">
						<div class="contArea">
							<p class="infoTxt">
								<span>
									<h3>올이음에 오신 것을 환영합니다.</h3>
								</span>
								<span class="txt">
									<b class="txt_blue_tp4">기업회원</b> 가입 페이지입니다.
									<br>
									지금 회원가입을 하신 후 <br> 
									올이음의 다양한 서비스를 만나보세요.
								</span>
							</p>

							<button type="submit" id="" class="btn_signUp" (click)="setRegisterType('company');">가입하기</button>
						</div>
					</div>
					<div class="tab_content" style="display:none">
						<div class="contArea">
							<p class="infoTxt">
								<span>
									<h1>올이음에 오신 것을 환영합니다.</h1>
								</span>
								<span class="txt">지금 회원가입을 하신 후 <br> 올이음의 다양한 서비스를 만나보세요.</span>
							</p>

							<button type="submit" id="" class="btn_signUp" (click)="setRegisterType('user');">가입하기</button>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="tab_wrap">
				<div class="tab_content" style="display: none;" id="tab_content_company">
					<!-- form -->
					<form [formGroup]="profileCompanyForm" (ngSubmit)="companySubmit(fileInput.files)">
						<!-- loginForm -->
						<fieldset class="signUpForm">
							<legend>회원가입</legend>
							<ul class="row_group">
								<li class="row">
									<div class="tit required">* 로그인 아이디</div>
									<div class="cont">
										<div class="inpTp1">											
											<ng-template [ngIf]="isuniqueidCompanyCheck">
												<input type="email" formControlName="userId" readonly>
												<a style="cursor: default;background-color: #a0a0a0; ">
													이메일 인증
												</a>
											</ng-template>
											<ng-template [ngIf]="isuniqueidCompanyCheck == false">
												<input type="email" id="companyUserId" spellcheck="false" autocomplete="off"
													formControlName="userId" required autocomplete="off" placeholder="이메일을 입력해 주세요.">
												<a (click)="isuniqueidCompany(companyForm.userId.value)" id="companyCountdown"
													style="cursor: pointer;">이메일 인증</a>
											</ng-template>
										</div>
										<div *ngIf="isuniqueidCompanyCheck; else notIsuniqueidCompanyCheck" class="invalid-feedback_">
											<div>이메일 인증되었습니다.</div>
										</div>
										<ng-template #notIsuniqueidCompanyCheck>
											<div *ngIf="submitted" class="invalid-feedback tempError">
												이메일 인증을 해주시기 바랍니다.
											</div>
										</ng-template>
									</div>
								</li>
								<li class="row" id="confirmCompanyCodeLi" style="display: none;">
									<div class="tit" id="timerCompany">
										인증코드
									</div>
									<div class="cont">
										<div class="inpTp1">
											<input type="text" id="confirmCompanyCode" spellcheck="false" autocomplete="off"
												placeholder="인증코드" maxlength="6" style="width: 40%; text-align: center;">
											<a style="cursor: pointer; width: 25%; margin-right: 10px;"
												(click)="confirmCompanyCodeCheck()">확인</a>
											<a style="cursor: pointer; width: 25%; margin-right: 10px;"
												(click)="confirmCompanyCodeCall(companyForm.userId.value)">재전송</a>
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 비밀번호</div>
									<div class="cont">
										<input type="password" id="companyPassword" name="" autocomplete="off"
											placeholder="영문 대소문자, 숫자, 특수문자를 포함 (8자 ~ 20자)" required formControlName="password" maxlength="20">
									</div>
									<div *ngIf="submitted && companyForm.password.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.password.errors.required">비밀번호를 입력해 주세요.</div>
										<div *ngIf="companyForm.password.errors.pattern || companyForm.password.errors.minlength">영문 대소문자, 숫자,
											특수문자를 포함하여 8자리 이상 입력해 주세요. (최대 20자)</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 비밀번호 확인</div>
									<div class="cont">
										<input type="password" id="companyConfirmPassword" autocomplete="off" name=""
											placeholder="비밀번호를 입력해 주세요." required formControlName="confirmPassword" id="confirmPassword">
									</div>
									<div *ngIf="submitted && companyForm.confirmPassword.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.confirmPassword.errors.required">비밀번호를 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 관리자 이름</div>
									<div class="cont">
										<input type="text" formControlName="name" required autocomplete="off" placeholder="관리자 이름을 입력해 주세요.">
										<div *ngIf="submitted && companyForm.name.errors" class="invalid-feedback tempError">
											<div *ngIf="companyForm.name.errors.required">관리자 이름을 입력해 주세요.</div>
											<div *ngIf="companyForm.name.errors.pattern">한글만 입력해 주세요.</div>
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 핸드폰번호</div>
									<div class="cont">
										<div class="inpTp1">
											<ng-template [ngIf]="isuniquePhoneCompanyCheck">
												<input type="text" formControlName="mobile" readonly>
												<a style="cursor: default; background-color: #a0a0a0;" >
													번호인증
												</a>
											</ng-template>
											<ng-template [ngIf]="isuniquePhoneCompanyCheck == false">
												<input type="text" required autocomplete="off" placeholder="숫자만 입력해 주세요." (input)="changePhoneNumber(companyForm.mobile,0)" formControlName="mobile" id="adminMobile" maxlength="13">
												<a  style="cursor: pointer;"  (click)="isuniquePhoneCompany(companyForm.mobile.value)" id="phoneCompanyCountDown">번호인증</a>
											</ng-template>											
										</div>
										<div *ngIf="isuniquePhoneCompanyCheck; else notIsuniquePhoneCompanyCheck" class="invalid-feedback_">
											<div>인증되었습니다.</div>
										</div>
										<ng-template #notIsuniquePhoneCompanyCheck>
											<div *ngIf="submitted" class="invalid-feedback tempError">
												핸드폰번호 인증을 해주시기 바랍니다.
											</div>
										</ng-template>
									</div>
								</li>
								<li class="row" id="confirmCompanyPhoneLi" style="display: none;">
									<div class="tit" id="timerCompanyPhone">
										인증코드
									</div>
									<div class="cont">
										<div class="inpTp1">
											<input type="text" id="confirmCompanyPhoneCode" spellcheck="false" autocomplete="off"
												placeholder="인증코드" maxlength="6" style="width: 40%; text-align: center;">
											<a style="cursor: pointer; width: 25%; margin-right: 10px;" (click)="confirmCompanyPhoneCodeCheck()">확인</a>
											<a style="cursor: pointer; width: 25%; margin-right: 10px;" (click)="isuniquePhoneCompany(companyForm.mobile.value)">재전송</a>
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 기업명</div>
									<div class="cont">
										<input type="text" name="" autocomplete="off" placeholder="기업 명을 입력해 주세요." required
											formControlName="companyName" id="companyName">
									</div>
									<div *ngIf="submitted && companyForm.companyName.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.companyName.errors.required">기업 명을 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 대표자 이름</div>
									<div class="cont">
										<input type="text" name="" autocomplete="off" placeholder="대표자 이름을 입력해 주세요." required
											id="registrationName" formControlName="registrationName" required>
									</div>
									<div *ngIf="submitted  && companyForm.registrationName.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.registrationName.errors.required">대표자 이름을 입력해 주세요.</div>
										<div *ngIf="companyForm.registrationName.errors.pattern">한글만 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 대표 식별 ID</div>
									<div class="cont">
										<input type="text" name="" autocomplete="off" placeholder="식별 ID를 입력해 주세요." required
											id="companyDomainId" formControlName="companyDomainId" required>
									</div>
									<div *ngIf="submitted  && companyForm.companyDomainId.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.companyDomainId.errors.required">대표 식별 ID를 입력해 주세요.</div>
										<div
											*ngIf="companyForm.companyDomainId.errors.pattern || companyForm.companyDomainId.errors.minlength">
											영문 대소문자, 숫자를 포함하여 3자리 이상 입력해 주세요. (최대 10자)
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">
											* 사업자등록번호
										<label for="registrationNumberSub" style="padding-left: 60px;">
											<input type="checkbox" id="registrationNumberSub"
											    
												(click)="registrationNumberSubCheck()" />종사업자등록번호
										</label>
									</div>
									<div class="cont ">
										<div class="inpTp1">
											<input type="text" name="" autocomplete="off" placeholder="숫자만 입력해 주세요." required
												id="registrationNumber" formControlName="registrationNumber" maxlength="12"
												(input)="changeRegistrationNumber(companyForm.registrationNumber)">
											<!-- <a *ngIf="isRegNumCheck==false" (click)="chkRegistrationNum(companyForm.registrationNumber.value)" id="btnBsno"
												style="cursor: pointer;">국세청 인증</a>
											<a *ngIf="isRegNumCheck==true" (click)="rewriteRegNum()" id="rewriteRegNum"
												style="cursor: pointer;">다시 입력</a> -->
										</div>
										<!-- <div *ngIf="isRegNumCheck" class="invalid-feedback_">
											<div>인증되었습니다.</div>
										</div>	 -->
									</div>
									<div *ngIf="submitted  && companyForm.registrationNumber.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.registrationNumber.errors.required">사업자등록번호를 입력해 주세요.</div>
										<div
											*ngIf="companyForm.registrationNumber.errors.pattern || companyForm.registrationNumber.errors.minlength">
											10자리 숫자만 입력해 주세요.</div>
									</div>
								</li>
								<li class="row" id="registrationNumberSubLi" style="display: none;">
									<div class="tit">
										* 종사업자번호
									</div>
									<div class="cont">
										<input type="text" id="registrationNumberSubId" name="" autocomplete="off"
											placeholder="종사업자번호(숫자만)를 입력해 주세요." formControlName="registrationNumberSub" maxlength="12">
									</div>
									<div *ngIf="submitted && companyForm.registrationNumberSub.errors" class="invalid-feedback tempError">
										<div
											*ngIf="companyForm.registrationNumberSub.errors.pattern || companyForm.registrationNumberSub.errors.minlength">
											숫자만 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 기업 전화번호</div>
									<div class="cont">
										<input type="text" name="" autocomplete="off" placeholder="숫자만 입력해 주세요." id="telephone"
											formControlName="telephone" required maxlength="11">
									</div>
									<div *ngIf="submitted  && companyForm.telephone.errors" class="invalid-feedback tempError">
										<div *ngIf="companyForm.telephone.errors.required">기업 전화번호를 입력해 주세요.</div>
										<div *ngIf="companyForm.telephone.errors.pattern || companyForm.telephone.errors.minlength">10 ~
											11숫자만 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 주소</div>
									<div class="cont">
										<div class="inpTp2">
											<input type="text" class="w18p" formControlName="zipCode" placeholder="우편번호" readonly required>
											<input type="text" class="w58p" formControlName="address" placeholder="기본주소" readonly required>
											<a (click)="openDaumPopup('conmany')">주소검색</a>
										</div>
										<div class="inp">
											<input type="text" formControlName="addressDetail" placeholder="상세주소를 입력해 주세요." required>
										</div>
										<div
											*ngIf="submitted && (companyForm.addressDetail.errors || companyForm.zipCode.errors || companyForm.address.errors)"
											class="invalid-feedback tempError">
											<div>주소를 입력해 주세요.</div>
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 증빙서류 업로드</div>
									<div class="cont">
										<div class="inpTp1">
											<input type="text" class="upload-name" id="" name="" readonly placeholder="증빙서류를 업로드해 주세요.">
											<label for="input-file">파일찾기</label>
											<input type="file" class="input-file" id="input-file" name="" formControlName="file" #fileInput>
										</div>
										<div *ngIf="submitted  && companyForm.file.errors" class="invalid-feedback tempError">
											<div *ngIf="companyForm.file.errors.required">증빙서류를 넣어주세요.</div>
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">가입경로</div>
									<div class="cont selCont">
										<select name="" id="companyReson" (change)="changeCompanyReson($event)">
											<option value="" selected="selected">- 가입경로 -</option>
											<option value="검색사이트 검색">검색사이트 검색</option>
											<option value="뉴스레터 및 이메일 홍보매체">뉴스레터 및 이메일 홍보매체</option>
											<option value="기타">기타</option>
										</select>
									</div>
								</li>
							</ul>
							<ul class="btnMenu">
								<li class="on"><button type="button" (click)="preStep()">이전단계</button></li>
								<li><button type="submit" id="submitCompany">회원가입</button></li>
							</ul>
						</fieldset>
						<!-- //loginForm -->
					</form>
					<!-- //form -->
				</div>
				<!-- //tab_content -->

				<!-- tab_content : 개인 -->
				<div class="tab_content" style="display:none" id="tab_content_user">
					<!-- form -->
					<form [formGroup]="profileUserForm" (ngSubmit)="userSubmit()">
						<!-- loginForm -->
						<fieldset class="signUpForm">
							<ul class="row_group">
								<li class="row">
									<div class="tit">* 이름</div>
									<div class="cont">
										<input type="text" formControlName="name" autocomplete="off" placeholder="이름을 입력해 주세요." required>
									</div>
									<div *ngIf="submitted && userForm.name.errors" class="invalid-feedback tempError">
										<div *ngIf="userForm.name.errors.required">이름을 입력해 주세요.</div>
										<div *ngIf="userForm.name.errors.pattern">한글을 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 로그인 아이디</div>
									<div class="cont">
										<div class="inpTp1">
											<ng-template [ngIf]="isuniqueidUserCheck">
												<input type="email" formControlName="userId" readonly>
												<a style="cursor: default; background-color: #a0a0a0;">이메일 인증</a>
											</ng-template>
											<ng-template [ngIf]="isuniqueidUserCheck == false">
												<input type="email" id="userUserId" spellcheck="false" autocomplete="off" formControlName="userId" autocomplete="off" placeholder="이메일을 입력해 주세요." required>
												<a (click)="isuniqueidUser(userForm.userId.value)" id="userCountdown" style="cursor: pointer;">이메일 인증</a>
											</ng-template>
										</div>
										<div *ngIf="isuniqueidUserCheck; else notIsuniqueidUserCheck" class="invalid-feedback_">
											<div>이메일 인증되었습니다.</div>
										</div>
										<ng-template #notIsuniqueidUserCheck>
											<div *ngIf="submitted" class="invalid-feedback tempError">
												이메일 인증을 해주시기 바랍니다.
											</div>
										</ng-template>
									</div>									
								</li>
								<li class="row" id="confirmUserCodeLi" style="display: none;">
									<div class="tit" id="timerUser">
										인증코드
									</div>
									<div class="cont">
										<div class="inpTp1">
											<input type="text" id="confirmUserCode" spellcheck="false" autocomplete="off" placeholder="인증코드"
												maxlength="6" style="width:40%;text-align: center;">
											<a style="cursor: pointer; width: 25%; margin-right: 10px;"
												(click)="confirmUserCodeCheck()">확인</a>
											<a style="cursor: pointer; width: 25%; margin-right: 10px;"
												(click)="confirmUserCodeCall(userForm.userId.value)">재전송</a>
										</div>
									</div>
								</li> 
								<li class="row">
									<div class="tit">* 비밀번호</div>
									<div class="cont">
										<input type="password" id="userPassword" spellcheck="false" autocomplete="off" name=""
											placeholder="영문 대소문자, 숫자, 특수문자를 포함 (8자 ~ 20자)" formControlName="password" required maxlength="20">
									</div>
									<div *ngIf="submitted && userForm.password.errors" class="invalid-feedback tempError">
										<div *ngIf="userForm.password.errors.required">비밀번호를 입력해 주세요.</div>
										<div *ngIf="userForm.password.errors.pattern || userForm.password.errors.minlength">영문 대소문자, 숫자, 특수문자를
											포함하여 8자리 이상 입력해 주세요. (최대 20자)</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 비밀번호 확인</div>
									<div class="cont">
										<input type="password" id="userConfirmPassword" name="" placeholder="비밀번호를 입력해 주세요."
											formControlName="confirmPassword" required>
									</div>
									<div *ngIf="submitted  && userForm.confirmPassword.errors" class="invalid-feedback tempError">
										<div *ngIf="userForm.confirmPassword.errors.required">비밀번호를 입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 전화번호</div>
									<div class="cont">
										<input type="text" autocomplete="off" id="telephone" formControlName="telephone"
											placeholder="숫자만 입력해 주세요." maxlength="11">
									</div>
									<div *ngIf="submitted && userForm.telephone.errors" class="invalid-feedback tempError">
										<div *ngIf="userForm.telephone.errors.pattern || userForm.telephone.errors.minlength">10 ~ 11자리 숫자만
											입력해 주세요.</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 핸드폰번호</div>
									<div class="cont">
										<div class="inpTp1">
											<ng-template [ngIf]="isuniquePhoneUserCheck">
												<input type="text" formControlName="mobile" readonly>
												<a style="cursor: default; background-color: #a0a0a0;" >번호인증</a>
											</ng-template>
											<ng-template [ngIf]="isuniquePhoneUserCheck == false">
												<input type="text" autocomplete="off" formControlName="mobile" required placeholder="숫자만 입력해 주세요." id="UserMobile" maxlength="13"
												(input)="changePhoneNumber(userForm.mobile,1)"
												>
												<a id="userPhoneCountdown" style="cursor: pointer;" (click)="isuniquePhoneUser(userForm.mobile.value)">번호인증</a>
											</ng-template>
										</div>
									</div>
									<div *ngIf="isuniquePhoneUserCheck; else notIsuniquePhoneUserCheck" class="invalid-feedback_">
										<div>인증되었습니다</div>
									</div>
									<ng-template #notIsuniquePhoneUserCheck>
										<div *ngIf="submitted" class="invalid-feedback tempError">
											핸드폰번호 인증을 해주시기 바랍니다.
										</div>
									</ng-template>
								</li>
								<li class="row" id="confirmUserPhoneLi" style="display: none;">
									<div class="tit" id="timerUserPhone">
										인증코드
									</div>
									<div class="cont">
										<div class="inpTp1">
											<input type="text" id="confirmUserPhoneCode" spellcheck="false" autocomplete="off"
												placeholder="인증코드" maxlength="6" style="width: 40%; text-align: center;">
											<a style="cursor: pointer; width: 25%; margin-right: 10px;" (click)=confirmUserPhoneCodeCheck()>확인</a>
											<a style="cursor: pointer; width: 25%; margin-right: 10px;" (click)="isuniquePhoneUser(userForm.mobile.value)">재전송</a>
										</div>
									</div>
								</li>
								<li class="row">
									<div class="tit">* 주소</div>
									<div class="cont">
										<div class="inpTp2">
											<input type="text" class="w18p" placeholder="우편번호" readonly formControlName="zipCode" required>
											<input type="text" class="w58p" placeholder="기본주소" readonly formControlName="address" required>
											<a (click)="openDaumPopup('user')">주소검색</a>
										</div>
										<div class="inp">
											<input type="text" placeholder="상세주소를 입력해 주세요." formControlName="addressDetail" required>
										</div>
										<div
											*ngIf="submitted && (userForm.addressDetail.errors || userForm.zipCode.errors || userForm.address.errors)"
											class="invalid-feedback tempError">
											<div>주소를 입력해 주세요.</div>
										</div>
									</div>
								</li>

								<li class="row">
									<div class="tit">가입경로</div>
									<div class="cont selCont">
										<select name="" id="userReson" (change)="changeUserReson($event)">
											<option value="" selected="selected">- 가입경로 -</option>
											<option value="검색사이트 검색">검색사이트 검색</option>
											<option value="뉴스레터 및 이메일 홍보매체">뉴스레터 및 이메일 홍보매체</option>
											<option value="기타">기타</option>
										</select>
									</div>
								</li>

							</ul>

							<ul class="btnMenu">
								<li class="on"><button type="button" (click)="preStep()">이전단계</button></li>
								<li>
									<button type="submit" id="submitUser" (click)="confirmMail(userForm.userId.value)">
										회원가입
									</button>
								</li>
							</ul>
						</fieldset>
						<!-- //loginForm -->
					</form>
					<!-- //form -->
				</div>
				<!-- //tab_content -->
			</div>

			<div class="tab_wrap">
				<div class="tab_content" style="display: none;" id="terms_form_companydiv">
					<form class="f">
						<fieldset class="signUpForm">
							<legend>기업 이용약관</legend>
							<ul class="row_group">
								<li class="row">
									<div class="tit">
										<!-- <label style="cursor: none;line-height:150%">비즈니스링커시스템(이하 ‘올이음’) 클라우드 서비스를 이용해 주셔서 감사드립니다. 다음의 약관들에는 여러분의 올이음 서비스 이용에 있어서 반드시 확인하여야 할 정보를 포함하고 있습니다. 올이음 서비스를 이용하시거나 올이음 서비스 회원으로 가입하실 경우 여러분은 본 ‘서비스 이용약관’ 및 ‘개인정보 처리방침’을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기 바랍니다.</label> -->
										<label style="cursor: none; line-height:150%">올이음 클라우드 서비스 이용약관과 개인정보 처리방침에 동의를 하셔야 회원가입이 가능합니다.</label>
									</div>
								</li>
							</ul>
							<p class="form tm_all">
								<label for="check_companyAllcheck">
									<input type="checkbox" id="check_companyAllcheck" (click)="companyAllcheck()"> 전체 동의
								</label>
							</p>
							<ul class="row_group">								
								<li class="row">
									<div class="tit">
										<label><input type="checkbox" id="check_company1" name="company" (click)="companyCheck()" /> 서비스
											이용약관</label>
									</div>
									<div class="cont">
										<textarea name="" rows="5" readonly>제1장 총칙
제1조 (목적)
본 약관은 주식회사 비즈니스링커시스템(이하 "회사"라 함)이 제공하는 올이음 클라우드 서비스 및 관련 제반 서비스(이하 "서비스"라 함)를 이용함에 있어서 회사와 서비스 이용자(이하 "사용자"라 함)간에 이용 조건 및 절차, 권리 및 의무 등에 관한 기본적인 사항에 관하여 규정함을 목적으로 합니다.
제2조 (약관의 효력)
① 본 약관은 서비스가 이루어지는 주된 인터넷 홈페이지(http://www.bizlinkersys.com, 이하 "홈페이지"라 함)에 공시함으로써 그 효력을 발생합니다.
② 회사는 필요에 따라 약관의 내용을 개정할 수 있으며, 약관이 개정된 경우에는 제①항과 같은 방법으로 적용일자와 개정사유를 포함하여 그 내용을 공시함으로써 그 효력을 발생합니다.
③ 회사가 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여 현행 약관과 함께 홈페이지 초기 화면에 그 적용일자 7일 이전부터 공시합니다. 다만, 사용자에게 불리하거나 중대한 사항을 변경하는 경우에는 최소한 30일 이전부터 공시합니다.
④ 개정된 약관의 효력발생일까지 사용자가 회사에 서면으로 이의를 제기하지 아니 한 때에는 개정된 약관을 승인한 것으로 봅니다.
⑤ 본 약관에서 정하지 아니한 사항과 약관의 해석에 관한 사항은 전자문서 및 전자거래기본법, 전기통신사업법 등 관계 법령 및 업계의 상거래 관행에 따름을 원칙으로 합니다.
제3조 (약관 적용 외의 준칙)
① 회사는 서비스 별로 별도의 이용약관 및 운영정책(이하 “개별 약관 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별 약관 등이 우선하여 적용됩니다.
② 본 약관에 명시되지 않은 사항은 개별 약관 등과 클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 독점규제 및 공정거래에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의합니다.
③ 본 약관과 관련하여 회사의 운영정책 변경, 법령의 제/개정 또는 공공기관의 고시나 지침 등에 의하여 회사가 홈페이지의 공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
제4조 (용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
① 서비스 : 회사가 사용자에게 제공하는 클라우드 기반 IT 리소스 및 플랫폼을 제공하는 클라우드 서비스와 클라우드 서비스 이용을 위한 기술지원 등 관련 제반 서비스
② 사용자 : 회사의 서비스 이용약관에 동의하고 회사가 요구하는 소정의 정보를 제공하여 회원등록을 한 자
③ 아이디(ID) : 사용자의 식별과 서비스 이용을 위하여 회사가 부여 또는 사용자의 선택에 의하여 회사가 승인하는 영문자와 숫자 등의 조합
1. 기업관리자 아이디(ID) : 사용자를 대표하여 최초로 가입하는 자연인으로서, 업무담당자의 권한 등을 관리하는 모든 권한과 책임을 부여받는 자의 ID
2. 업무담당자 아이디(ID) : 서비스 이용을 신청하여 기업관리자로부터 허락받은 자연인의 ID
④ 비밀번호 : 승인받은 사용자 ID를 사용할 권리를 가진 자임을 확인하고, 사용자 자신의 비밀 보호를 위하여 사용자가 선정한 영문자와 숫자 등의 조합
⑤ 이용요금 : 서비스를 이용한 대가로 회사의 별도 부과기준 및 체계에 따라 산정되어 부과되는 금액
⑥ 크레딧 : 회사가 프로모션 등을 위하여 조건을 정해 서비스의 사용에 적용할 수 있도록 하는 마일리지 제도
제2장 서비스 이용 계약	
제5조 (사용자의 자격)
① 사용자는 대한민국 국민이거나 대한민국에 사업장을 두고 있는 법인 또는 개인사업자로서, 회사가 정한 정보를 제공할 수 있어야 합니다.
② 다음 각 호에 해당하는 자는 사용자로 등록할 수 없습니다.
1. 가입신청자가 본 약관 제14조에 의하여 사용자 자격을 상실한 적이 있는 경우. 다만, 사용자 자격 상실 후 3년이 경과하거나, 회사에서 재가입의 승낙을 한 경우는 예외로 합니다.
2. 등록 내용을 허위로 기재한 경우
제6조 (서비스 이용 신청)
① 서비스 이용 신청은 홈페이지에 접속하여 내려 받은 회사 소정양식의 이용신청서에 제반 정보를 입력하여 사업자등록증 사본 등과 함께 신청합니다.
② 사용자로 가입을 하고자 하는 자는 본 약관에 동의하고, 회사가 정한 이용신청서 양식에 따라 모든 내용들을 허위 없이 기재하여야 합니다.
③ 회사는 가입 신청자가 제②항에 따라 모든 사항을 성실히 기재하여 이용신청을 완료하면 필요사항을 확인한 후, 서비스 이용을 위한 사용자 가입 승인 여부를 결정합니다.
④ 회사는 승인 결과를 가입 신청자의 유선 또는 전자우편 등을 통해 통보하며, 가입 신청자는 홈페이지 접속을 통하여 승인 여부를 확인할 수 있습니다.
⑤ 사용자 이용신청 양식에 기재하는 모든 정보는 모두 실제 데이터인 것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 가입 신청자는 법적인 보호를 받을 수 없으며, 서비스 이용을 제한받을 수 있습니다.
⑥ 사용자는 회사에 제공한 정보의 변경이 있는 경우, 즉시 사용자 정보를 변경하여야 합니다.
제7조 (이용 신청의 제한 및 승인 보류)
① 회사는 다음의 각 호에 해당하는 사항을 인지하였을 경우, 해당 이용 신청에 대해서는 신청을 승낙하지 아니합니다.
1. 타인의 명의를 도용하였거나, 실명이 아닌 가명을 사용하여 신청하였을 때
2. 가입신청서의 내용을 허위로 기재하였거나, 허위 서류를 첨부하여 신청하였을 때
3. 기타 가입 신청자의 귀책사유로 가입 승인이 어려운 경우
② 회사는 이용 신청이 다음의 각 호에 해당하는 경우에는, 해당 이용 신청에 대해서는 가입 승인 제한 사유가 해소될 때까지 이용 승인을 제한 및 보류할 수 있습니다.
1. 서비스 관련 설비의 용량이 부족한 경우
2. 서비스의 기술상 장애 사유가 발생한 경우 혹은 발생이 예상될 경우
3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스의 중지로 인한 서비스의 제공이 불가능한 경우
4. 기타 회사가 서비스를 위해서 필요하다고 인정되는 경우
③ 회사는 제①항 및 제②항의 규정에 의해서 이용신청을 승인할 수 없거나, 승인을 제한하는 경우에는 이를 해당 가입 신청자에게 통지하여야 합니다. 단, 회사의 귀책사유가 아닌 사유로 인해 가입 신청자에게 통지할 수 없는 경우는 예외로서 인정합니다.
제3장 서비스 제공 및 이용
제8조 (서비스의 이용 개시)
회사는 사용자의 이용 신청을 승낙한 때부터 서비스를 개시합니다.
제9조 (서비스의 제공 및 중단 등)
① 서비스는 상품의 종류에 따라 각 개별 서비스로 구성되며, 사용자는 회사의 동의하에 개별 서비스를 선택하여 사용할 수 있습니다.
② 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
③ 회사는 사용자에 대하여 회사 정책에 따라 사용자를 등급별로 구분하여 이용 시간, 이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
④ 다음 각 호의 어느 하나에 해당하는 경우에는 서비스가 제공되지 아니할 수 있으며, 회사는 서비스를 제공할 의무가 없습니다.
1. 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우
2. 서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우
3. 서비스 업그레이드 및 서비스 유지 보수 등을 위해 필요한 경우
4. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
5. 인터넷망이나 통신망의 장애
6. 회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한 경우
7. 천재지변, 국가 비상사태 등 불가항력적 사유가 있는 경우
8. 그 밖에 위 각 호에 준하는 사유가 있는 경우
⑤ 회사는 제④항의 경우에는 제11조에서 정한 방법으로 사용자에게 그 사실을 사전에 통지합니다. 단, 회사의 고의, 과실이 없는 해킹, 사고 등 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지 할 수 있습니다.
⑥ 회사는 서비스 제공시 저장되는 개인정보가 아닌 정보를 서비스 제공 이외의 마케팅 활용 목적 등으로 이용할 수 있습니다.
제10조 (서비스 내용 변경)
① 회사는 운영상, 경영상, 기술상의 필요에 따라 서비스의 전부 또는 일부 내용을 변경하거나 서비스를 종료할 수 있으며, 서비스의 내용, 이용 방법 등에 변경이 있는 경우에는 변경사유, 변경내용 및 제공일자 등을 변경 7일 전에, 서비스를 종료하는 경우에는 30일 전에 홈페이지에 해당 사실을 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우에는 사후에 지체 없이 이를 공지할 수 있습니다.
② 회사는 본 약관 및 관련 법령에 특별한 규정이 없는 한 서비스의 변경, 내용 수정으로 인하여 사용자가 입은 손해에 대해 어떠한 책임도 지지 않습니다.
제11조 (정보 제공, 광고 게재)
① 회사는 사용자가 서비스 이용 중 필요하다고 인정되는 거래 관련 정보, 사용자 문의 등에 대한 회신 등의 정보를 공지사항, 전자우편, SMS 등의 방법으로 사용자에게 제공할 수 있습니다. 다만, 사용자는 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.
② 회사는 관계법령에 따라 적법한 절차를 통해 사용자에게 광고를 제공할 수 있습니다.
제12조 (게시물 관리)
① 사용자의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관계 법령에 위반되는 내용을 포함하는 경우, 권리자는 관계 법령이 정한 절차에 따라 해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며, 회사는 관계 법령에 따라 조치를 취하여야 합니다.
② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관계 법령에 위반되는 경우에는 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
③ 본 조에 따른 세부절차는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법이 규정한 내용에 따릅니다.
제13조 (이용계약의 해지)
① 사용자가 서비스 이용계약을 해지할 경우에는 해지하고자 하는 날의 5일 전까지 "서비스 해지 신청서"를 회사에 제출하여야 합니다.
② 회사는 다음 각 호의 1에 해당하는 사유가 발생한 때에는 이용계약을 해지 할 수 있으며 일정 기간 내에 재가입을 제한할 수 있습니다.
1. 서비스 이용요금을 정한 기일 내에 납부하지 아니한 경우
2. 관계 법령 및 약관을 위반하여 1회 이상의 시정요구를 받고 이에 불응하여 회사의 업무 및 기술상 막대한 지장을 초래한 경우
3. 기타 회사가 사용자로써 부적당하다고 판단한 경우
제14조 (서비스 이용 정지)
① 회사는 사용자의 서비스 이용 내용이 다음 각 호의 어느 하나에 해당하는 경우 사전 통지 없이 사용자의 서비스에 대한 이용을 정지할 수 있습니다.
1. 요금납기월 14일까지 요금을 납입하지 아니한 경우
2. 사용자의 가상머신의 과다 트래픽 발생이나 브로드캐스팅으로 서비스 중인 네트워크에 장애 발생이 우려되는 경우
3. 사용자의 가상머신이 DDoS 공격에 노출 또는 해킹 및 바이러스에 감염이 의심되는 경우
4. 사용자의 가상머신 이미지(가상머신 생성시 필요한 기본 Metadata와 데이터를 담고 있는 이미지 파일) 서비스를 통해 공유한 이미지에 악성 프로그램이 설치되어 다른 사용자의 개인 정보 유출 등 보안상 위험이 있는 경우 또는 공유된 이미지에 정당한 사용권한이 없는 상용 소프트웨어가 설치되어 있는 등 제3자의 지적 재산권 침해와 관련한 분쟁이 발생할 우려가 있는 경우
5. 본 약관 제 19조에 규정된 사용자의 의무를 위반한 경우
6. 회사의 정상적인 서비스 운영을 방해한 경우
7. 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관계 법령을 위반한 경우
8. 불법프로그램 사용자 등 위법행위자로 합리적 의심이 되는 경우
제15조 (서비스 이용정지에 대한 이의 신청)
① 사용자가 회사의 이용정지 및 이용제한에 불복하고자 할 때에는 이에 불복하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
② 본 조 제①항의 이의신청서를 접수한 회사는 합리적인 기간 내에 사용자의 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 회사는 처리에 장기간이 소요되는 경우에는 그 사유와 처리일정을 서비스에 공지하거나 통보합니다.
제4장 권리와 의무
제16조 (양도 등의 금지)
① 사용자가 서비스를 제공받는 권리는 제17조의 규정에 의해 승계되는 경우를 제외하고는 이를 양도하거나 증여, 처분할 수 없습니다.
② 사용자는 회사의 사전 동의 없이 제3자로 하여금 서비스를 이용하게 해서는 안 됩니다.
제17조 (사용자의 지위 승계)
① 다음 각 호의 사유가 발생한 경우에는 사용자의 지위를 승계합니다.
1. 법인이 다른 법인을 흡수 합병하여 존속하는 법인이 그 지위를 승계한 경우
2. 둘 이상의 법인이 하나의 법인으로 신설합병하여 그 새로운 법인이 지위를 승계한 경우
3. 타인의 영업을 양수하여 그 영업을 승계하는 경우
② 제①항의 규정에 의하여 사용자의 지위를 승계한 자는 승계일로부터 30일 이내에 사용자의 지위 승계 사실을 증명하는 서류를 첨부하여 회사에 제출하여야 합니다.
제18조 (회사의 의무)
① 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적으로 서비스를 제공할 의무가 있습니다.
② 회사는 서비스 제공 및 청약과 관련하여 알게 된 사용자의 신상 정보나 중요한 사업내용에 대해 본인의 승낙 없이 제3자에게 누설, 배포하여서는 안 됩니다. 다만, 전기통신기본법 등 관계법령의 규정에 의하여 관계 국가기관의 요구가 있는 경우에는 그러하지 않습니다.
제19조 (사용자의 의무)
① 사용자는 서비스 이용에 대한 대가로서 이 서비스에서 정한 요금 등을 요금 납부책임자와 연대하여 납입하여야 합니다.
② 사용자는 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 사용자의 이용 이외의 목적으로 복제하거나 제3자에게 제공하여서는 아니 됩니다.
③ 사용자는 회사의 사전 서면 승낙 없이 로봇, 스파이더, 기타 자동화 프로그램 등을 회사의 웹 페이지 또는 웹 페이지에 있는 내용을 감시, 복제 또는 다운로드하기 위하여 사용하여서는 아니 되며, 그러한 사용으로 회사에 직·간접적인 손해를 입힌 경우 이를 배상하여야 합니다.
④ 사용자는 회사가 제공하는 서비스 이용을 위하여 제공된 자료 및 정보 일체를 회사의 동의 없이 제3자에게 제공할 수 없습니다.
⑤ 사용자는 제①항, 제②항, 제③항 및 제④항 이외에도 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.
제5장 서비스 이용요금
제20조 (서비스 이용요금)
① 서비스의 이용요금은 홈페이지의 ‘이용요금’에 게시된 바와 같습니다. 단, 회사와 별도 계약을 체결한 경우에는 별도로 약정한 바에 따릅니다.
② 서비스 이용요금은 월 단위로 부과합니다. 단, 별도의 약정이 있을 경우에는 과금방식을 달리할 수 있습니다.
③ 월 정액제 요금은 사용량 유무와 무관하게 해지한 해당 월까지의 요금을 부과합니다.
④ 이용요금은 사용자(기업/개인)별로 부과함을 원칙으로 합니다.
⑤ 사용자는 서비스 이용요금에 부과되는 부가가치세를 별도로 부담하여야 합니다.
제21조 (요금납입책임자)
① 요금납입책임자는 서비스 사용자를 원칙으로 합니다. 단, 회사가 인정하는 경우에는 타인을 요금납입책임자로 지정할 수 있습니다.
② 제①항의 규정에 의한 요금납입책임자는 사용자가 회사에 대하여 부담하는 서비스 이용 요금 등 약관에 따른 모든 채무를 사용자와 연대하여 회사에 납부할 책임이 있습니다.
제22조 (요금부과 및 납부)
① 회사는 전월 1일부터 전월 말일까지 발생한 서비스 이용요금을 당월 5일에 청구합니다.
② 회사는 서비스요금 청구서를 납입기일 7일전까지 회사가 정한 방법으로 요금납입책임자에게 도달하도록 발송해야 합니다. 다만, 요금납입책임자가 자동이체, 신용카드 등 자동납부방식으로 요금 등을 납부하기로 한 경우에는 그러하지 않습니다.
③ 사용자는 요금청구서에 기재된 기일까지 회사가 지정하는 장소에 지정하는 방법으로 요금을 납입해야 합니다.
④ 사용자는 요금과 관련하여 청구서 등의 정확한 수령을 위해 청구서 발송과 관련된 변경사항을 회사에 통보하여야 하며, 사용자의 통보누락 등으로 인하여 발생한 불이익에 대해 회사는 책임지지 않습니다.
제23조 (연체료의 부과)
① 요금납입책임자가 지정된 기일까지 요금을 납부하지 아니한 때에는 그 체납된 요금의 100분의 2에 상당하는 금액을 가산금으로 납부하여야 합니다.
② 제①항의 규정에 의한 가산금은 요금 납부 만료일의 익일을 기준으로 하여 체납된 요금에 가산하여 청구하며, 체납요금 및 연체료는 익월 요금청구서에 포함하여 청구합니다.
③ 사용자의 서비스 이용요금이 7일 이상 미납 될 경우 회사는 사전 통보 없이 사용자가 사용하는 서비스에 대해 이용 정지할 수 있고, 1개월 이상 미납 될 경우 회사는 약관 및 홈페이지에 미리 공지한 절차와 방식에 따라 서비스를 해지할 수 있으며, 이에 따른 사용자의 손해에 대해서 회사는 어떠한 책임도 지지 않습니다.
제24조 (요금의 반환)
① 요금의 과오납입액이 있는 때에는 회사는 해당 사용자의 청구에 따라 다음 각 호의 1에 해당하는 조치를 합니다.
1. 과납청구한 요금 등에 대하여는 그 금액을 감액합니다.
2. 과납입한 요금 등에 대하여는 그 금액을 반환하거나 다음 달에 청구할 요금에서 상계합니다.
3. 요금 등을 반환받아야 할 사용자의 요금 등의 체납이 있는 경우, 회사는 반환해야 할 요금 등에서 이를 우선 공제하고 반환할 수 있습니다.
4. 반환받을 요금 등의 수령권자가 2인 이상일 경우에는 그 중 1인에게 이를 반환합니다. 이 때, 회사는 요금을 반환받을 자를 임의로 결정할 수 있으며, 요금을 반환받지 못한 수령권자는 회사에 대하여 이의를 제기할 수 없습니다.
② 제①항의 규정에 의한 요금반환 청구는 그 사유가 발생한 날로부터 기산하여 6개월을 경과 한 때에는 청구 할 수 없습니다.
③ 회사는 과소청구액에 대해서는 익월에 합산하여 청구합니다.	
제25조 (크레딧)
① 회사는 회사의 정책에 따라 사용자에게 서비스에서 사용할 수 있는 크레딧을 지급할 수 있습니다.
② 크레딧의 세부 구분, 이용금액, 사용방법, 사용기간 및 제한에 대한 사항은 크레딧 또는 서비스 화면에 표시됩니다. 크레딧의 종류 및 내용에 대해서는 회사에서 정할 수 있습니다.
③ 크레딧은 현금으로 출금 및 환금될 수 없으며, 크레딧에 표시된 유효기간이 만료되거나 이용계약이 종료되면 소멸합니다.
④ 크레딧은 서비스 화면에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 부정한 목적이나 부정한 용도로 사용할 수 없습니다.
⑤ 사용자가 부당 또는 부정하게 크레딧을 취득한 경우, 크레딧을 사용할 수 없으며, 회사는 이를 회수할 수 있습니다.
제6장 사용자 정보 보호정책	
제26조 (사용자 정보의 보호)
① 회사는 수집된 사용자 정보가 분실·도난·누출·변조 또는 훼손되지 아니하도록 보안 및 안전성 확보에 필요한 기술적인 조치 등을 취하여야 합니다.
② 회사는 서비스와 관련하여 취득한 사용자의 정보 및 사용자의 개인정보를 본인의 사전 승낙 없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스 관련 업무이외의 상업적 목적으로 사용할 수 없습니다. 다만, 다음 각 항에 해당하는 경우에는 그러하지 아니합니다.
1. 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구받은 경우
2. 방송통신심의위원회의 요청이 있는 경우
3. 서비스 제공에 따른 이용요금의 정산을 위하여 필요한 경우
4. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서, 특정 개인을 식별할 수 없는 형태로 제공하는 경우
5. 기타 관계 법령에 의해 필요한 경우
제27조 (사용자 정보의 열람 및 정정)
사용자는 언제든지 회사의 홈페이지에서 본인의 개인정보를 열람 및 정정할 수 있습니다. 단, 사용자는 서비스의 특성상 사업자등록증상의 정보는 임의대로 변경할 수 없으며, 이 경우 정보정정을 증빙할 수 있는 관련서류(변경된 사업자등록증 사본 등)를 회사에 송부하여, 소정의 확인절차를 거쳐 회사에서 처리함을 원칙으로 합니다.
제7장 기타
제28조 (기밀유지)
사용자와 회사는 본 계약과 관련하여 취득한 상대방의 영업상 또는 기술상의 비밀이나 정보를 제3자에게 누출하거나 유출할 수 없습니다.
제29조 (손해배상)
① 사용자는 제19조 및 기타 본 약관의 규정을 위반함으로 인하여 회사가 다른 사용자 또는 제3자에 대하여 책임을 부담하게 되고 이로 인해 회사가 손해를 입게 되는 경우, 사용자는 회사가 입은 일체의 손해에 대하여 배상하여야 합니다.
② 회사는 서비스 제공과 관련하여 다음 각 호의 사유로 사용자에게 어떠한 손해가 발생한 경우 회사는 이에 대하여 책임지지 아니합니다.
1. 천재지변, 제3자의 고의적 서비스 방해 및 기타 불가항력적인 사유로 인해 서비스를 제공하지 못한 경우
2. 사용자가 제공하는 자료의 오류로 인하여 사용자에게 손해가 발생한 경우
3. 통신회선 등 회사 이외의 전산 시스템 장애에 의하여 서비스를 제공하지 못한 경우
4. 바이러스 침투, 불법 소프트웨어 설치 등 관리 소홀 및 부주의 등 사용자의 귀책사유로 인한 서비스 이용 장애의 경우
5. 기타 회사의 과실 없는 사유로 인하여 사용자에게 손해가 발생한 경우
제30조 (회사의 손해배상)
① 회사는 회사의 책임 있는 사유로 장애가 발생하여 아래 정의한 월 가용성 구간 미만 제공으로 사용자가 손해를 입은 경우 사용자의 청구에 의해서 손해를 배상합니다.
1. 월 가용성(%) = 100 * ｛1-(서비스를 이용한 1개월 동안 회사의 책임 있는 사유로 인한 장애로 서비스를 이용하지 못한 장애시간(시간단위 분)의 합 / 서비스를 이용할 수 있는 기간 1개월 동안의 시간단위 분)｝
2. 장애는 서비스를 이용하지 못한 사실을 사용자가 회사에 통지한 때(사용자의 통지 전에 회사가 그 사실을 알 경우는 회사가 그 사실을 알게 된 때)부터 장애시간은 측정됩니다.
② 본 조 제①항의 손해배상 금액은 아래의 금액을 기준으로 하여 결정합니다. 단, 회사가 서비스 별 특성에 따라 별도의 손해배상 기준을 마련하여 사용자에게 운영정책으로 안내하거나 홈페이지에 공지하는 경우에는 손해배상 금액의 산정 기준이 다르게 적용 될 수 있습니다.
1. 월 가용성 99.0% 이상 ~ 99.9% 미만 : 3개월 월 평균 사용금액의 10%에 해당하는 금액
2. 월 가용성 95.0% 이상 ~ 99.0% 미만 : 3개월 월 평균 사용금액의 25%에 해당하는 금액
3. 월 가용성 95.0% 미만 : 3개월 월 평균 사용금액의 50%에 해당하는 금액
③ 사용자가 본 조 제①항에 따라 회사에 손해배상을 청구하고자 하는 경우 이에 대한 사유, 청구액 및 산출 근거, 장애에 대한 상세 내용을 기재하여 서면으로 신청하여야 합니다.
④ 본 조에 따른 손해배상액은 사용자의 선택에 따라 다른 서비스 구매나 해당 서비스의 월 이용 요금으로 사용될 수 있습니다.
⑤ 사용자가 서비스를 이용하지 못함으로 인한 회사의 배상책임은 제①항에 의한 손해배상에 한정되며, 이를 제외한 회사의 추가적인 손해배상은 없습니다.
⑥ 회사는 회사가 제공하는 무료서비스 이용과 관련하여 사용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.
⑦ 본 약관 하에서 회사의 사용자에 대한 총 배상책임은 사용자가 회사에게 지불한 금액으로 제한됩니다.
제31조 (분쟁의 해결 및 관할법원)
① 서비스와 관련하여 분쟁이 발생한 경우 관련법규가 있으면 그 관련법규를 따르고 관련법규가 없으면 관습 및 신의성실의 원칙에 입각, 상호 협의하여 해결합니다.
② 서비스와 관련하여 발생한 분쟁이 제①항에 따라 원만하게 해결되지 아니할 경우, 이와 관련된 소송의 관할법원은 회사의 본점 소재지를 관할하는 법원으로 합니다.

부칙
제1조 (시행일)
이 약관은 2020년 8월 3일부터 시행합니다.
										</textarea>
									</div>
								</li>
								<li class="row">
									<div class="tit">
										<label><input type="checkbox" id="check_company2" name="company" (click)="companyCheck()" /> 개인정보
											처리방침</label>
									</div>
									<div class="cont">
										<textarea name="" rows="5" readonly style="overflow-x:hidden;overflow-y:auto">[비즈니스링커시스템 개인정보처리방침]
주식회사 비즈니스링커시스템(이하 '회사'라 합니다.)은 “정보통신망 이용촉진 및 정보보호에 관한 법률”, “개인정보보호법”, “전기통신사업법”, “전자거래기본법”, “위치정보의 보호 및 이용 등에 관한 법률” 등 개인정보와 관련된 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을 경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.
회사는 개인정보처리방침을 통해 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고 있음을 알려드리고자 합니다.

제 1 조 (수집하는 개인정보의 항목 및 수집방법) ① 회사는 회원가입, 원활한 고객상담, 각종 서비스 등 기본적인 서비스 제공을 위한 회원 가입시 아래와 같은 개인정보를 수집하고 있습니다.
1. 수집항목
가. 필수항목 : 이름, 아이디(이메일 ID), 비밀번호, 휴대폰 번호, 전화번호, 이메일, 회사명, 사업자등록번호, 대표자명, 본인확인(생년월일, 성별, 본인확인값(CI, DI), 이동통신사, 아이핀 정보(아이핀 확인 시), 내/외국인 여부), 주소
나. 선택항목 : 담당업무, 팩스번호, 이름, 휴대폰번호
② 또한, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
1. 이용자의 브라우저 종류 및 OS, 검색어, IP Address, 쿠키
2. 방문 일시, 서비스이용기록, 불량이용기록, 결제기록
③ 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1. 서비스 이용가입, 서면양식, 팩스, 전화, 상담게시판, 이메일, 고객센터 문의하기, 이벤트 응모
2. 협력회사 또는 제휴사로부터의 제공
3. 생성정보 수집 툴을 통한 수집

제 2 조 (개인정보의 수집 및 이용목적) 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.
1. 회원 관리
회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확���, 분쟁���정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
2. 서비스 제공에 관한 계약이행 및 서비스 제공에 따른 요금정산, 특정 맞춤서비스 제공, 청구서 등 발송, 본인인증, 요금결제 및 요금추심
3. 서비스 이용 고객간의 전자적인 업무 처리 지원
4. 신규 서비스 개발 및 마케팅·광고에 활용
신규 서비스(제품) 개발, 통계학적 특성에 따른 서비스 제공, 특화, 이벤트 등 광고성 정보전달 및 참여기회 제공, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계

제 3 조 (개인정보의 공유 및 제공) 회사는 이용자들의 개인정보를 상기 제2조 “개인정보의 수집 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
1. 이용자들이 사전에 공개에 동의한 경우
2. 서비스 이용자가 전자적인 업무 처리를 위해 상대업체에게 정보를 제공하는 경우
3. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
4. 정보 주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
5. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아 볼 수 없는 형태로 개인정보를 제공하는 경우

제 4조 (개인정보의 처리위탁) 회사는 서비스 제공에 관한 계약의 이행을 위해 외부 전문업체에 위탁하여 운영할 수 있으며, 위탁업무에 필요한 범위 내에서 정보를 제공할 수 있습니다. 또한 위탁계약 시 개인정보보호 관련 지시 엄수, 개인정보에 관한 금지 및 사고시의 책임부담 등을 규정하도록 합니다. 위탁 운영시, 해당 내용에 대해서는 본 개인정보처리 지침에 고지하도록 합니다.

제 5 조 (개인정보의 보유 및 이용기간) ① 회사는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다.
② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
1. 계약 또는 청약철회 등에 관한 기록
- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
- 보존 기간 : 5년
2. 대금결제 및 재화 등의 공급에 관한 기록
- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
- 보존 기간 : 5년
3. 소비자의 불만 또는 분쟁처리에 관한 기록
- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
- 보존 기간 : 3년
4. 본인확인에 관한 기록 : 6개월
- 보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
- 보존 기간 : 6개월
5. 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
- 보존 근거: 통신비밀보호법
- 보존 기간: 3개월

제 6 조 (개인정보의 파기절차 및 방법) 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.											
1. 파기절차
이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성되거나 또는 1년간 서비스를 이용하지 않는 이용자의 정보는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.											
2. 파기방법
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법) ① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는, 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 제5조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
⑤ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을 제출하셔야 합니다.
제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항) 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
1. 쿠키의 사용목적
이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟마케팅 및 개인 맞춤 서비스를 제공하기 위해 사용됩니다.
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
2. 쿠키의 거부방법
이 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키 설치를 거부하였을 경우 로그���이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
▶ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브�������우저 상단의 도구 > 인�������넷 옵션 > 개인정보

제 9 조 (개인정보의 기술적/관리적 보호 대책) 회사는 이용자들��� 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
1. 비밀번호 암호화
사용자 아이디(ID)의 비밀번호는 암호화되������� 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비���번호를 알고 있는 본인에 의해서만 가능���니다.
2. 해킹 등에 대비한 기술적 대책
회사��� 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 침입 차단장치 이용 및 침입탐지시스템을 설치하여 24시간 감시하고 있습니다. 그리고 백신 프로그램을 주기적으로 업데이트하며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다. 또한 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송 할 수 있도록 하고 있습니다.
3. 개인정보처리시스템 접근 제한
회사는 개인정보를 처리할 수 있도록 체계적으로 구성한 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을 수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타 개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
4. 개인정보 처리직원의 최소화 및 교육
개인정보관련 처리 직원은 담당자에 한정시켜 최소화 하고 새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의 비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.
5. 개인정보보호 활동
회사는 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 아이디(ID), 비밀번호, 전화번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
											
제 10 조 (개인정보보호책임자 및 담당자의 연락처) ① 이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보 관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
▶ 개인정보보호책임자
성명 : 박상���
직책 : 사업팀장
연락처 : 070-7500-1290,  dorans@bizlinkersys.com
② ���타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
▶ 개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
▶ 개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)
▶ 대검찰청 사이버수사과 (http://spo.go.kr / 국번없이 1301)
▶ 경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 국번없이 182)

제 11 조 (기타) 회사에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "주식회사 비즈니스 링커시스템 개인정보처리방침"이 적용되지 않음을 알려드립니다.

제 12 조 (고지의 의무) 현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.
▶ 최초공고일자 : 2020년 8월 3일
▶ 시행일자 : 2020년 8월 3일 부터 시행됩니다.
										</textarea>
									</div>
								</li>
							</ul>

							<ul class="btnMenu">
								<li class="on"><button type="button" (click)="preStep()">이전단계</button></li>
								<li><button type="button" id="nextBtn"
										(click)="nextStepCompany(); profileCompanyForm.reset(); submitted=false">다음단계</button></li>
							</ul>
						</fieldset>
					</form>
				</div>

				<div class="tab_content" style="display: none;" id="terms_form_userdiv">
					<form class="f">
						<fieldset class="signUpForm">
							<legend>개인 이용약관</legend>
							<ul class="row_group">
								<li class="row">
									<div class="tit">
										<!-- <label style="cursor: none; line-height:150%">비즈니스링커시스템(이하 ‘올이음’) 클라우드 서비스를 이용해 주셔서 감사드립니다. 다음의 약관들에는 여러분의 올이음 서비스 이용에 있어서 반드시 확인하여야 할 정보를 포함하고 있습니다. 올이음 서비스를 이용하시거나 올이음 서비스 회원으로 가입하실 경우 여러분은 본 ‘서비스 이용약관’ 및 ‘개인정보 처리방침’을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기 바랍니다.</label> -->
										<label style="cursor: none; line-height:150%">비즈니스링커시스템 클라우드 서비스 이용약관과 개인정보 처리방침에 동의를 하셔야 회원가입이 가능합니다.</label>
									</div>
								</li>
							</ul>
							<p class="form tm_all">
								<label for="check_userAllcheck">
									<input type="checkbox" id="check_userAllcheck" (click)="userAllcheck()"> 전체 동의
								</label>
							</p>
							<ul class="row_group">								
								<li class="row">
									<div class="tit"><label><input type="checkbox" id="check_user1" name="user" (click)="userCheck()" />
											서비스 이용약관</label></div>
									<div class="cont">
										<textarea name="" rows="5" readonly>제1장 총칙
제1조 (목적)
본 약관은 주식회사 비즈니스링커시스템(이하 "회사"라 함)이 제공하는 올이음 클라우드 서비스 및 관련 제반 서비스(이하 "서비스"라 함)를 이용함에 있어서 회사와 서비스 이용자(이하 "사용자"라 함)간에 이용 조건 및 절차, 권리 및 의무 등에 관한 기본적인 사항에 관하여 규정함을 목적으로 합니다.
제2조 (약관의 효력)
① 본 약관은 서비스가 이루어지는 주된 인터넷 홈페이지(http://www.bizlinkersys.com, 이하 "홈페이지"라 함)에 공시함으로써 그 효력을 발생합니다.
② 회사는 필요에 따라 약관의 내용을 개정할 수 있으며, 약관이 개정된 경우에는 제①항과 같은 방법으로 적용일자와 개정사유를 포함하여 그 내용을 공시함으로써 그 효력을 발생합니다.
③ 회사가 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여 현행 약관과 함께 홈페이지 초기 화면에 그 적용일자 7일 이전부터 공시합니다. 다만, 사용자에게 불리하거나 중대한 사항을 변경하는 경우에는 최소한 30일 이전부터 공시합니다.
④ 개정된 약관의 효력발생일까지 사용자가 회사에 서면으로 이의를 제기하지 아니 한 때에는 개정된 약관을 승인한 것으로 봅니다.
⑤ 본 약관에서 정하지 아니한 사항과 약관의 해석에 관한 사항은 전자문서 및 전자거래기본법, 전기통신사업법 등 관계 법령 및 업계의 상거래 관행에 따름을 원칙으로 합니다.
제3조 (약관 적용 외의 준칙)
① 회사는 서비스 별로 별도의 이용약관 및 운영정책(이하 “개별 약관 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별 약관 등이 우선하여 적용됩니다.
② 본 약관에 명시되지 않은 사항은 개별 약관 등과 클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 독점규제 및 공정거래에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의합니다.
③ 본 약관과 관련하여 회사의 운영정책 변경, 법령의 제/개정 또는 공공기관의 고시나 지침 등에 의하여 회사가 홈페이지의 공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
제4조 (용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
① 서비스 : 회사가 사용자에게 제공하는 클라우드 기반 IT 리소스 및 플랫폼을 제공하는 클라우드 서비스와 클라우드 서비스 이용을 위한 기술지원 등 관련 제반 서비스
② 사용자 : 회사의 서비스 이용약관에 동의하고 회사가 요구하는 소정의 정보를 제공하여 회원등록을 한 자
③ 아이디(ID) : 사용자의 식별과 서비스 이용을 위하여 회사가 부여 또는 사용자의 선택에 의하여 회사가 승인하는 영문자와 숫자 등의 조합
1. 기업관리자 아이디(ID) : 사용자를 대표하여 최초로 가입하는 자연인으로서, 업무담당자의 권한 등을 관리하는 모든 권한과 책임을 부여받는 자의 ID
2. 업무담당자 아이디(ID) : 서비스 이용을 신청하여 기업관리자로부터 허락받은 자연인의 ID
④ 비밀번호 : 승인받은 사용자 ID를 사용할 권리를 가진 자임을 확인하고, 사용자 자신의 비밀 보호를 위하여 사용자가 선정한 영문자와 숫자 등의 조합
⑤ 이용요금 : 서비스를 이용한 대가로 회사의 별도 부과기준 및 체계에 따라 산정되어 부과되는 금액
⑥ 크레딧 : 회사가 프로모션 등을 위하여 조건을 정해 서비스의 사용에 적용할 수 있도록 하는 마일리지 제도
제2장 서비스 이용 계약	
제5조 (사용자의 자격)
① 사용자는 대한민국 국민이거나 대한민국에 사업장을 두고 있는 법인 또는 개인사업자로서, 회사가 정한 정보를 제공할 수 있어야 합니다.
② 다음 각 호에 해당하는 자는 사용자로 등록할 수 없습니다.
1. 가입신청자가 본 약관 제14조에 의하여 사용자 자격을 상실한 적이 있는 경우. 다만, 사용자 자격 상실 후 3년이 경과하거나, 회사에서 재가입의 승낙을 한 경우는 예외로 합니다.
2. 등록 내용을 허위로 기재한 경우
제6조 (서비스 이용 신청)
① 서비스 이용 신청은 홈페이지에 접속하여 내려 받은 회사 소정양식의 이용신청서에 제반 정보를 입력하여 사업자등록증 사본 등과 함께 신청합니다.
② 사용자로 가입을 하고자 하는 자는 본 약관에 동의하고, 회사가 정한 이용신청서 양식에 따라 모든 내용들을 허위 없이 기재하여야 합니다.
③ 회사는 가입 신청자가 제②항에 따라 모든 사항을 성실히 기재하여 이용신청을 완료하면 필요사항을 확인한 후, 서비스 이용을 위한 사용자 가입 승인 여부를 결정합니다.
④ 회사는 승인 결과를 가입 신청자의 유선 또는 전자우편 등을 통해 통보하며, 가입 신청자는 홈페이지 접속을 통하여 승인 여부를 확인할 수 있습니다.
⑤ 사용자 이용신청 양식에 기재하는 모든 정보는 모두 실제 데이터인 것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 가입 신청자는 법적인 보호를 받을 수 없으며, 서비스 이용을 제한받을 수 있습니다.
⑥ 사용자는 회사에 제공한 정보의 변경이 있는 경우, 즉시 사용자 정보를 변경하여야 합니다.
제7조 (이용 신청의 제한 및 승인 보류)
① 회사는 다음의 각 호에 해당하는 사항을 인지하였을 경우, 해당 이용 신청에 대해서는 신청을 승낙하지 아니합니다.
1. 타인의 명의를 도용하였거나, 실명이 아닌 가명을 사용하여 신청하였을 때
2. 가입신청서의 내용을 허위로 기재하였거나, 허위 서류를 첨부하여 신청하였을 때
3. 기타 가입 신청자의 귀책사유로 가입 승인이 어려운 경우
② 회사는 이용 신청이 다음의 각 호에 해당하는 경우에는, 해당 이용 신청에 대해서는 가입 승인 제한 사유가 해소될 때까지 이용 승인을 제한 및 보류할 수 있습니다.
1. 서비스 관련 설비의 용량이 부족한 경우
2. 서비스의 기술상 장애 사유가 발생한 경우 혹은 발생이 예상될 경우
3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스의 중지로 인한 서비스의 제공이 불가능한 경우
4. 기타 회사가 서비스를 위해서 필요하다고 인정되는 경우
③ 회사는 제①항 및 제②항의 규정에 의해서 이용신청을 승인할 수 없거나, 승인을 제한하는 경우에는 이를 해당 가입 신청자에게 통지하여야 합니다. 단, 회사의 귀책사유가 아닌 사유로 인해 가입 신청자에게 통지할 수 없는 경우는 예외로서 인정합니다.
제3장 서비스 제공 및 이용
제8조 (서비스의 이용 개시)
회사는 사용자의 이용 신청을 승낙한 때부터 서비스를 개시합니다.
제9조 (서비스의 제공 및 중단 등)
① 서비스는 상품의 종류에 따라 각 개별 서비스로 구성되며, 사용자는 회사의 동의하에 개별 서비스를 선택하여 사용할 수 있습니다.
② 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
③ 회사는 사용자에 대하여 회사 정책에 따라 사용자를 등급별로 구분하여 이용 시간, 이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
④ 다음 각 호의 어느 하나에 해당하는 경우에는 서비스가 제공되지 아니할 수 있으며, 회사는 서비스를 제공할 의무가 없습니다.
1. 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우
2. 서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우
3. 서비스 업그레이드 및 서비스 유지 보수 등을 위해 필요한 경우
4. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
5. 인터넷망이나 통신망의 장애
6. 회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한 경우
7. 천재지변, 국가 비상사태 등 불가항력적 사유가 있는 경우
8. 그 밖에 위 각 호에 준하는 사유가 있는 경우
⑤ 회사는 제④항의 경우에는 제11조에서 정한 방법으로 사용자에게 그 사실을 사전에 통지합니다. 단, 회사의 고의, 과실이 없는 해킹, 사고 등 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지 할 수 있습니다.
⑥ 회사는 서비스 제공시 저장되는 개인정보가 아닌 정보를 서비스 제공 이외의 마케팅 활용 목적 등으로 이용할 수 있습니다.
제10조 (서비스 내용 변경)
① 회사는 운영상, 경영상, 기술상의 필요에 따라 서비스의 전부 또는 일부 내용을 변경하거나 서비스를 종료할 수 있으며, 서비스의 내용, 이용 방법 등에 변경이 있는 경우에는 변경사유, 변경내용 및 제공일자 등을 변경 7일 전에, 서비스를 종료하는 경우에는 30일 전에 홈페이지에 해당 사실을 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우에는 사후에 지체 없이 이를 공지할 수 있습니다.
② 회사는 본 약관 및 관련 법령에 특별한 규정이 없는 한 서비스의 변경, 내용 수정으로 인하여 사용자가 입은 손해에 대해 어떠한 책임도 지지 않습니다.
제11조 (정보 제공, 광고 게재)
① 회사는 사용자가 서비스 이용 중 필요하다고 인정되는 거래 관련 정보, 사용자 문의 등에 대한 회신 등의 정보를 공지사항, 전자우편, SMS 등의 방법으로 사용자에게 제공할 수 있습니다. 다만, 사용자는 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.
② 회사는 관계법령에 따라 적법한 절차를 통해 사용자에게 광고를 제공할 수 있습니다.
제12조 (게시물 관리)
① 사용자의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관계 법령에 위반되는 내용을 포함하는 경우, 권리자는 관계 법령이 정한 절차에 따라 해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며, 회사는 관계 법령에 따라 조치를 취하여야 합니다.
② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관계 법령에 위반되는 경우에는 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
③ 본 조에 따른 세부절차는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법이 규정한 내용에 따릅니다.
제13조 (이용계약의 해지)
① 사용자가 서비스 이용계약을 해지할 경우에는 해지하고자 하는 날의 5일 전까지 "서비스 해지 신청서"를 회사에 제출하여야 합니다.
② 회사는 다음 각 호의 1에 해당하는 사유가 발생한 때에는 이용계약을 해지 할 수 있으며 일정 기간 내에 재가입을 제한할 수 있습니다.
1. 서비스 이용요금을 정한 기일 내에 납부하지 아니한 경우
2. 관계 법령 및 약관을 위반하여 1회 이상의 시정요구를 받고 이에 불응하여 회사의 업무 및 기술상 막대한 지장을 초래한 경우
3. 기타 회사가 사용자로써 부적당하다고 판단한 경우
제14조 (서비스 이용 정지)
① 회사는 사용자의 서비스 이용 내용이 다음 각 호의 어느 하나에 해당하는 경우 사전 통지 없이 사용자의 서비스에 대한 이용을 정지할 수 있습니다.
1. 요금납기월 14일까지 요금을 납입하지 아니한 경우
2. 사용자의 가상머신의 과다 트래픽 발생이나 브로드캐스팅으로 서비스 중인 네트워크에 장애 발생이 우려되는 경우
3. 사용자의 가상머신이 DDoS 공격에 노출 또는 해킹 및 바이러스에 감염이 의심되는 경우
4. 사용자의 가상머신 이미지(가상머신 생성시 필요한 기본 Metadata와 데이터를 담고 있는 이미지 파일) 서비스를 통해 공유한 이미지에 악성 프로그램이 설치되어 다른 사용자의 개인 정보 유출 등 보안상 위험이 있는 경우 또는 공유된 이미지에 정당한 사용권한이 없는 상용 소프트웨어가 설치되어 있는 등 제3자의 지적 재산권 침해와 관련한 분쟁이 발생할 우려가 있는 경우
5. 본 약관 제 19조에 규정된 사용자의 의무를 위반한 경우
6. 회사의 정상적인 서비스 운영을 방해한 경우
7. 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관계 법령을 위반한 경우
8. 불법프로그램 사용자 등 위법행위자로 합리적 의심이 되는 경우
제15조 (서비스 이용정지에 대한 이의 신청)
① 사용자가 회사의 이용정지 및 이용제한에 불복하고자 할 때에는 이에 불복하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
② 본 조 제①항의 이의신청서를 접수한 회사는 합리적인 기간 내에 사용자의 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 회사는 처리에 장기간이 소요되는 경우에는 그 사유와 처리일정을 서비스에 공지하거나 통보합니다.
제4장 권리와 의무
제16조 (양도 등의 금지)
① 사용자가 서비스를 제공받는 권리는 제17조의 규정에 의해 승계되는 경우를 제외하고는 이를 양도하거나 증여, 처분할 수 없습니다.
② 사용자는 회사의 사전 동의 없이 제3자로 하여금 서비스를 이용하게 해서는 안 됩니다.
제17조 (사용자의 지위 승계)
① 다음 각 호의 사유가 발생한 경우에는 사용자의 지위를 승계합니다.
1. 법인이 다른 법인을 흡수 합병하여 존속하는 법인이 그 지위를 승계한 경우
2. 둘 이상의 법인이 하나의 법인으로 신설합병하여 그 새로운 법인이 지위를 승계한 경우
3. 타인의 영업을 양수하여 그 영업을 승계하는 경우
② 제①항의 규정에 의하여 사용자의 지위를 승계한 자는 승계일로부터 30일 이내에 사용자의 지위 승계 사실을 증명하는 서류를 첨부하여 회사에 제출하여야 합니다.
제18조 (회사의 의무)
① 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적으로 서비스를 제공할 의무가 있습니다.
② 회사는 서비스 제공 및 청약과 관련하여 알게 된 사용자의 신상 정보나 중요한 사업내용에 대해 본인의 승낙 없이 제3자에게 누설, 배포하여서는 안 됩니다. 다만, 전기통신기본법 등 관계법령의 규정에 의하여 관계 국가기관의 요구가 있는 경우에는 그러하지 않습니다.
제19조 (사용자의 의무)
① 사용자는 서비스 이용에 대한 대가로서 이 서비스에서 정한 요금 등을 요금 납부책임자와 연대하여 납입하여야 합니다.
② 사용자는 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 사용자의 이용 이외의 목적으로 복제하거나 제3자에게 제공하여서는 아니 됩니다.
③ 사용자는 회사의 사전 서면 승낙 없이 로봇, 스파이더, 기타 자동화 프로그램 등을 회사의 웹 페이지 또는 웹 페이지에 있는 내용을 감시, 복제 또는 다운로드하기 위하여 사용하여서는 아니 되며, 그러한 사용으로 회사에 직·간접적인 손해를 입힌 경우 이를 배상하여야 합니다.
④ 사용자는 회사가 제공하는 서비스 이용을 위하여 제공된 자료 및 정보 일체를 회사의 동의 없이 제3자에게 제공할 수 없습니다.
⑤ 사용자는 제①항, 제②항, 제③항 및 제④항 이외에도 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.
제5장 서비스 이용요금
제20조 (서비스 이용요금)
① 서비스의 이용요금은 홈페이지의 ‘이용요금’에 게시된 바와 같습니다. 단, 회사와 별도 계약을 체결한 경우에는 별도로 약정한 바에 따릅니다.
② 서비스 이용요금은 월 단위로 부과합니다. 단, 별도의 약정이 있을 경우에는 과금방식을 달리할 수 있습니다.
③ 월 정액제 요금은 사용량 유무와 무관하게 해지한 해당 월까지의 요금을 부과합니다.
④ 이용요금은 사용자(기업/개인)별로 부과함을 원칙으로 합니다.
⑤ 사용자는 서비스 이용요금에 부과되는 부가가치세를 별도로 부담하여야 합니다.
제21조 (요금납입책임자)
① 요금납입책임자는 서비스 사용자를 원칙으로 합니다. 단, 회사가 인정하는 경우에는 타인을 요금납입책임자로 지정할 수 있습니다.
② 제①항의 규정에 의한 요금납입책임자는 사용자가 회사에 대하여 부담하는 서비스 이용 요금 등 약관에 따른 모든 채무를 사용자와 연대하여 회사에 납부할 책임이 있습니다.
제22조 (요금부과 및 납부)
① 회사는 전월 1일부터 전월 말일까지 발생한 서비스 이용요금을 당월 5일에 청구합니다.
② 회사는 서비스요금 청구서를 납입기일 7일전까지 회사가 정한 방법으로 요금납입책임자에게 도달하도록 발송해야 합니다. 다만, 요금납입책임자가 자동이체, 신용카드 등 자동납부방식으로 요금 등을 납부하기로 한 경우에는 그러하지 않습니다.
③ 사용자는 요금청구서에 기재된 기일까지 회사가 지정하는 장소에 지정하는 방법으로 요금을 납입해야 합니다.
④ 사용자는 요금과 관련하여 청구서 등의 정확한 수령을 위해 청구서 발송과 관련된 변경사항을 회사에 통보하여야 하며, 사용자의 통보누락 등으로 인하여 발생한 불이익에 대해 회사는 책임지지 않습니다.
제23조 (연체료의 부과)
① 요금납입책임자가 지정된 기일까지 요금을 납부하지 아니한 때에는 그 체납된 요금의 100분의 2에 상당하는 금액을 가산금으로 납부하여야 합니다.
② 제①항의 규정에 의한 가산금은 요금 납부 만료일의 익일을 기준으로 하여 체납된 요금에 가산하여 청구하며, 체납요금 및 연체료는 익월 요금청구서에 포함하여 청구합니다.
③ 사용자의 서비스 이용요금이 7일 이상 미납 될 경우 회사는 사전 통보 없이 사용자가 사용하는 서비스에 대해 이용 정지할 수 있고, 1개월 이상 미납 될 경우 회사는 약관 및 홈페이지에 미리 공지한 절차와 방식에 따라 서비스를 해지할 수 있으며, 이에 따른 사용자의 손해에 대해서 회사는 어떠한 책임도 지지 않습니다.
제24조 (요금의 반환)
① 요금의 과오납입액이 있는 때에는 회사는 해당 사용자의 청구에 따라 다음 각 호의 1에 해당하는 조치를 합니다.
1. 과납청구한 요금 등에 대하여는 그 금액을 감액합니다.
2. 과납입한 요금 등에 대하여는 그 금액을 반환하거나 다음 달에 청구할 요금에서 상계합니다.
3. 요금 등을 반환받아야 할 사용자의 요금 등의 체납이 있는 경우, 회사는 반환해야 할 요금 등에서 이를 우선 공제하고 반환할 수 있습니다.
4. 반환받을 요금 등의 수령권자가 2인 이상일 경우에는 그 중 1인에게 이를 반환합니다. 이 때, 회사는 요금을 반환받을 자를 임의로 결정할 수 있으며, 요금을 반환받지 못한 수령권자는 회사에 대하여 이의를 제기할 수 없습니다.
② 제①항의 규정에 의한 요금반환 청구는 그 사유가 발생한 날로부터 기산하여 6개월을 경과 한 때에는 청구 할 수 없습니다.
③ 회사는 과소청구액에 대해서는 익월에 합산하여 청구합니다.	
제25조 (크레딧)
① 회사는 회사의 정책에 따라 사용자에게 서비스에서 사용할 수 있는 크레딧을 지급할 수 있습니다.
② 크레딧의 세부 구분, 이용금액, 사용방법, 사용기간 및 제한에 대한 사항은 크레딧 또는 서비스 화면에 표시됩니다. 크레딧의 종류 및 내용에 대해서는 회사에서 정할 수 있습니다.
③ 크레딧은 현금으로 출금 및 환금될 수 없으며, 크레딧에 표시된 유효기간이 만료되거나 이용계약이 종료되면 소멸합니다.
④ 크레딧은 서비스 화면에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 부정한 목적이나 부정한 용도로 사용할 수 없습니다.
⑤ 사용자가 부당 또는 부정하게 크레딧을 취득한 경우, 크레딧을 사용할 수 없으며, 회사는 이를 회수할 수 있습니다.
제6장 사용자 정보 보호정책	
제26조 (사용자 정보의 보호)
① 회사는 수집된 사용자 정보가 분실·도난·누출·변조 또는 훼손되지 아니하도록 보안 및 안전성 확보에 필요한 기술적인 조치 등을 취하여야 합니다.
② 회사는 서비스와 관련하여 취득한 사용자의 정보 및 사용자의 개인정보를 본인의 사전 승낙 없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스 관련 업무이외의 상업적 목적으로 사용할 수 없습니다. 다만, 다음 각 항에 해당하는 경우에는 그러하지 아니합니다.
1. 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구받은 경우
2. 방송통신심의위원회의 요청이 있는 경우
3. 서비스 제공에 따른 이용요금의 정산을 위하여 필요한 경우
4. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서, 특정 개인을 식별할 수 없는 형태로 제공하는 경우
5. 기타 관계 법령에 의해 필요한 경우
제27조 (사용자 정보의 열람 및 정정)
사용자는 언제든지 회사의 홈페이지에서 본인의 개인정보를 열람 및 정정할 수 있습니다. 단, 사용자는 서비스의 특성상 사업자등록증상의 정보는 임의대로 변경할 수 없으며, 이 경우 정보정정을 증빙할 수 있는 관련서류(변경된 사업자등록증 사본 등)를 회사에 송부하여, 소정의 확인절차를 거쳐 회사에서 처리함을 원칙으로 합니다.
제7장 기타
제28조 (기밀유지)
사용자와 회사는 본 계약과 관련하여 취득한 상대방의 영업상 또는 기술상의 비밀이나 정보를 제3자에게 누출하거나 유출할 수 없습니다.
제29조 (손해배상)
① 사용자는 제19조 및 기타 본 약관의 규정을 위반함으로 인하여 회사가 다른 사용자 또는 제3자에 대하여 책임을 부담하게 되고 이로 인해 회사가 손해를 입게 되는 경우, 사용자는 회사가 입은 일체의 손해에 대하여 배상하여야 합니다.
② 회사는 서비스 제공과 관련하여 다음 각 호의 사유로 사용자에게 어떠한 손해가 발생한 경우 회사는 이에 대하여 책임지지 아니합니다.
1. 천재지변, 제3자의 고의적 서비스 방해 및 기타 불가항력적인 사유로 인해 서비스를 제공하지 못한 경우
2. 사용자가 제공하는 자료의 오류로 인하여 사용자에게 손해가 발생한 경우
3. 통신회선 등 회사 이외의 전산 시스템 장애에 의하여 서비스를 제공하지 못한 경우
4. 바이러스 침투, 불법 소프트웨어 설치 등 관리 소홀 및 부주의 등 사용자의 귀책사유로 인한 서비스 이용 장애의 경우
5. 기타 회사의 과실 없는 사유로 인하여 사용자에게 손해가 발생한 경우
제30조 (회사의 손해배상)
① 회사는 회사의 책임 있는 사유로 장애가 발생하여 아래 정의한 월 가용성 구간 미만 제공으로 사용자가 손해를 입은 경우 사용자의 청구에 의해서 손해를 배상합니다.
1. 월 가용성(%) = 100 * ｛1-(서비스를 이용한 1개월 동안 회사의 책임 있는 사유로 인한 장애로 서비스를 이용하지 못한 장애시간(시간단위 분)의 합 / 서비스를 이용할 수 있는 기간 1개월 동안의 시간단위 분)｝
2. 장애는 서비스를 이용하지 못한 사실을 사용자가 회사에 통지한 때(사용자의 통지 전에 회사가 그 사실을 알 경우는 회사가 그 사실을 알게 된 때)부터 장애시간은 측정됩니다.
② 본 조 제①항의 손해배상 금액은 아래의 금액을 기준으로 하여 결정합니다. 단, 회사가 서비스 별 특성에 따라 별도의 손해배상 기준을 마련하여 사용자에게 운영정책으로 안내하거나 홈페이지에 공지하는 경우에는 손해배상 금액의 산정 기준이 다르게 적용 될 수 있습니다.
1. 월 가용성 99.0% 이상 ~ 99.9% 미만 : 3개월 월 평균 사용금액의 10%에 해당하는 금액
2. 월 가용성 95.0% 이상 ~ 99.0% 미만 : 3개월 월 평균 사용금액의 25%에 해당하는 금액
3. 월 가용성 95.0% 미만 : 3개월 월 평균 사용금액의 50%에 해당하는 금액
③ 사용자가 본 조 제①항에 따라 회사에 손해배상을 청구하고자 하는 경우 이에 대한 사유, 청구액 및 산출 근거, 장애에 대한 상세 내용을 기재하여 서면으로 신청하여야 합니다.
④ 본 조에 따른 손해배상액은 사용자의 선택에 따라 다른 서비스 구매나 해당 서비스의 월 이용 요금으로 사용될 수 있습니다.
⑤ 사용자가 서비스를 이용하지 못함으로 인한 회사의 배상책임은 제①항에 의한 손해배상에 한정되며, 이를 제외한 회사의 추가적인 손해배상은 없습니다.
⑥ 회사는 회사가 제공하는 무료서비스 이용과 관련하여 사용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.
⑦ 본 약관 하에서 회사의 사용자에 대한 총 배상책임은 사용자가 회사에게 지불한 금액으로 제한됩니다.
제31조 (분쟁의 해결 및 관할법원)
① 서비스와 관련하여 분쟁이 발생한 경우 관련법규가 있으면 그 관련법규를 따르고 관련법규가 없으면 관습 및 신의성실의 원칙에 입각, 상호 협의하여 해결합니다.
② 서비스와 관련하여 발생한 분쟁이 제①항에 따라 원만하게 해결되지 아니할 경우, 이와 관련된 소송의 관할법원은 회사의 본점 소재지를 관할하는 법원으로 합니다.

부칙
제1조 (시행일)
이 약관은 2020년 8월 3일부터 시행합니다.
								</textarea>
									</div>
								</li>
								<li class="row">
									<div class="tit"><label><input type="checkbox" id="check_user2" name="user" (click)="userCheck()" />
											개인정보 처리방침</label></div>
									<div class="cont">
										<textarea name="" rows="5" readonly style="overflow-x:hidden;overflow-y:auto">[비즈니스링커시스템 개인정보처리방침]
주식회사 비즈니스링커시스템(이하 '회사'라 합니다.)은 “정보통신망 이용촉진 및 정보보호에 관한 법률”, “개인정보보호법”, “전기통신사업법”, “전자거래기본법”, “위치정보의 보호 및 이용 등에 관한 법률” 등 개인정보와 관련된 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을 경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.
회사는 개인정보처리방침을 통해 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고 있음을 알려드리고자 합니다.

제 1 조 (수집하는 개인정보의 항목 및 수집방법) ① 회사는 회원가입, 원활한 고객상담, 각종 서비스 등 기본적인 서비스 제공을 위한 회원 가입시 아래와 같은 개인정보를 수집하고 있습니다.
1. 수집항목
가. 필수항목 : 이름, 아이디(이메일 ID), 비밀번호, 휴대폰 번호, 전화번호, 이메일, 회사명, 사업자등록번호, 대표자명, 본인확인(생년월일, 성별, 본인확인값(CI, DI), 이동통신사, 아이핀 정보(아이핀 확인 시), 내/외국인 여부), 주소
나. 선택항목 : 담당업무, 팩스번호, 이름, 휴대폰번호
② 또한, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
 1. 이용자의 브라우저 종류 및 OS, 검색어, IP Address, 쿠키
 2. 방문 일시, 서비스이용기록, 불량이용기록, 결제기록
③ 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
 1. 서비스 이용가입, 서면양식, 팩스, 전화, 상담게시판, 이메일, 고객센터 문의하기, 이벤트 응모
 2. 협력회사 또는 제휴사로부터의 제공
 3. 생성정보 수집 툴을 통한 수집

제 2 조 (개인정보의 수집 및 이용목적) 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.
1. 회원 관리
회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 분쟁조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
2. 서비스 제공에 관한 계약이행 및 서비스 제공에 따른 요금정산, 특정 맞춤서비스 제공, 청구서 등 발송, 본인인증, 요금결제 및 요금추심
3. 서비스 이용 고객간의 전자적인 업무 처리 지원
4. 신규 서비스 개발 및 마케팅·광고에 활용
신규 서비스(제품) 개발, 통계학적 특성에 따른 서비스 제공, 특화, 이벤트 등 광고성 정보전달 및 참여기회 제공, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계

제 3 조 (개인정보의 공유 및 제공) 회사는 이용자들의 개인정보를 상기 제2조 “개인정보의 수집 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
1. 이용자들이 사전에 공개에 동의한 경우
2. 서비스 이용자가 전자적인 업무 처리를 위해 상대업체에게 정보를 제공하는 경우
3. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
4. 정보 주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
5. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아 볼 수 없는 형태로 개인정보를 제공하는 경우

제 4조 (개인정보의 처리위탁) 회사는 서비스 제공에 관한 계약의 이행을 위해 외부 전문업체에 위탁하여 운영할 수 있으며, 위탁업무에 필요한 범위 내에서 정보를 제공할 수 있습니다. 또한 위탁계약 시 개인정보보호 관련 지시 엄수, 개인정보에 관한 금지 및 사고시의 책임부담 등을 규정하도록 합니다. 위탁 운영시, 해당 내용에 대해서는 본 개인정보처리 지침에 고지하도록 합니다.

제 5 조 (개인정보의 보유 및 이용기간) ① 회사는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다.
② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
1. 계약 또는 청약철회 등에 관한 기록
- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
- 보존 기간 : 5년
2. 대금결제 및 재화 등의 공급에 관한 기록
- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
- 보존 기간 : 5년
3. 소비자의 불만 또는 분쟁처리에 관한 기록
- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
- 보존 기간 : 3년
4. 본인확인에 관한 기록 : 6개월
- 보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
- 보존 기간 : 6개월
5. 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
- 보존 근거: 통신비밀보호법
- 보존 기간: 3개월

제 6 조 (개인정보의 파기절차 및 방법) 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
1. 파기절차
이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성되거나 또는 1년간 서비스를 이용하지 않는 이용자의 정보는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
2. 파기방법
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법) ① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는, 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 제5조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
⑤ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을 제출하셔야 합니다.

제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항) 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
1. 쿠키의 사용목적
이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟마케팅 및 개인 맞춤 서비스를 제공하기 위해 사용됩니다.
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.												
2. 쿠키의 거부방법
이 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.												
▶ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보

제 9 조 (개인정보의 기술적/관리적 보호 대책) 회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.												
1. 비밀번호 암호화
사용자 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.												
2. 해킹 등에 대비한 기술적 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 침입 차단장치 이용 및 침입탐지시스템을 설치하여 24시간 감시하고 있습니다. 그리고 백신 프로그램을 주기적으로 업데이트하며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다. 또한 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송 할 수 있도록 하고 있습니다.												
3. 개인정보처리시스템 접근 제한
회사는 개인정보를 처리할 수 있도록 체계적으로 구성한 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을 수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타 개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
4. 개인정보 처리직원의 최소화 및 교육
개인정보관련 처리 직원은 담당자에 한정시켜 최소화 하고 새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의 비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.
5. 개인정보보호 활동
회사는 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 아이디(ID), 비밀번호, 전화번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제 10 조 (개인정보보호책임자 및 담당자의 연락처) ① 이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보 관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
▶ 개인정보보호책임자
성명 : 박상필
직책 : 사업팀장
연락처 : 070-7500-1290,  dorans@bizlinkersys.com
② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
▶ 개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
▶ 개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)
▶ 대검찰청 사이버수사과 (http://spo.go.kr / 국번없이 1301)
▶ 경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 국번없이 182)

제 11 조 (기타) 회사에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "주식회사 비즈니스 링커시스템 개인정보처리방침"이 적용되지 않음을 알려드립니다.

제 12 조 (고지의 의무) 현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.
▶ 최초공고일자 : 2020년 8월 3일
▶ 시행일자 : 2020년 8월 3일 부터 시행됩니다.
										</textarea>
									</div>
								</li>
							</ul>

							<ul class="btnMenu">
								<li class="on"><button type="button" id="preBtnUser" (click)="preStep()">이전단계</button></li>
								<li><button type="button" id="nextBtnUser"
										(click)="nextStepUser(); profileUserForm.reset(); submitted=false">다음단계</button></li>
							</ul>
						</fieldset>
					</form>
				</div>
			</div>
			<div class="signUp_footer">
				<div class="link">
					<a routerLink="/web/policy">이용약관</a>
					<a routerLink="/web/privacy">개인정보처리방침</a>
					<a routerLink="/web/term-email">이메일무단수집거부</a>
				</div>
				<p class="copyright">Copyright © 2020 올이음. All rights reserved.</p>

			</div>
		</div>
		<!-- //frm_content -->
	</div>
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>