<!-- Container -->
<div id="Container" class="service">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>서비스 소개</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>service</h3>
                    <div class="subTit">
                        올이음 클라우드의 다양한 서비스로 최적의 비즈니스 환경을 구성해 보세요.<br>
                        각 서비스는 활용목적 및 정보의 성격에 따라 다양한 형태로 구성할 수 있습니다.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="serviceInfo">
                        <ul class="serviceList">
                            <li id="map_server">
                                <p class="tit"><i class="ico ico_server"></i>서버</p>
                                <p class="cont">
                                    비즈니스 환경에 맞춰 원하는 컴퓨팅 리소스를 빠르게 생성하여 효율적으로 활용할 수 있는 서비스를 제공합니다.<br>
                                    애플리케이션이나 웹 사이트를 구축하는데 필요한 서버를 수 분만에 생성하여 운영할 수 있습니다.
                                </p>
                                <a (click)="ServicePriceRouter('map_server')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li>
                            <li id="map_storage">
                                <p class="tit"><i class="ico ico_storage"></i>스토리지</p>
                                <p class="cont">
                                    데이터를 인터넷에 저장하여 서비스로 관리하고 운영할 수 있습니다. <br>
                                    용량 및 비용을 온디맨드로 제공하여 자체 데이터 스토리지 인프라를 구매하거나 관리할 필요가 없습니다.<br>
                                    언제 어디서든 데이터에 액세스할 수 있습니다.
                                </p>
                                <a (click)="ServicePriceRouter('map_storage')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li>

                            <li id="map_network">
                                <p class="tit"><i class="ico ico_network"></i>네트워크</p>
                                <div class="cont">
                                    <span class="txt_blue_tp4">
                                        사용자가 정의한 네트워크를 기반으로 컴퓨팅 리소스를 배치할 수 있도록하여 사용자의 편의를 높였습니다.<br>
                                        온프레미스(IDC)에서 사용하던 네트워크 구성을 그대로 구현하여 사용할 수 있습니다.
                                    </span>
                                    <ul class="lsTp2">
                                        <li>Public IP : 고객이 보유한 VM을 외부에서 접근가능하도록 고정된 IP를 제공하는 서비스</li>
                                        <li>Load Balancer : 서버의 부하량을 고려하여 네트워크 트래픽을 다수의 서버로 분산시켜주는 가상 로드밸런서 제공 서비스</li>
                                        <li>FireWall(Security Group) : 각 서버들의 보안 정책을 일괄적으로 관리할 수 있도록 제공되는 네트워크 접근 제어 서비스</li>
                                        <li>PortFowarding : 하나의 공인 IP 를 사용해 여러 서버를 관리하기 위한 포트포워딩 기능 제공</li>
                                        <li>DNS : 인터넷상의 도메인 이름을 실제로 접속 가능한 주소로 식별해서 찾을 수 있도록 하는 서비스</li>
                                    </ul>
                                </div>
                                <a (click)="ServicePriceRouter('map_network')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li>
                            <li id="map_gw">
                                <p class="tit"><i class="ico ico_gw"></i>API 게이트웨이</p>
                                <div class="cont">
                                    <span class="txt_blue_tp4">
                                        안정적인 API 호출을 돕는 다양한 관리 기능을 제공합니다.
                                    </span>
                                    <ul class="lsTp2">
                                        <li>API 호출과 관련된 모든 작업들을 편리하게 진행할 수 있습니다.</li>
                                        <li>요청/응답의 크기를 제한하여 백엔드 서비스로 인입되는 트래픽을 제어할 수 있습니다.</li>
                                        <li>API Key와 IP 기반 접근제어를 이용하여 사용자의 액세스를 제어할 수 있습니다.</li>
                                    </ul>
                                </div>
                                <a (click)="ServicePriceRouter('map_gw')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li>

                            <li id="map_storage">
                                <p class="tit"><i class="ico ico_backup"></i>데이터 백업</p>
                                <div class="cont">
                                    <span class="txt_blue_tp4">
                                        서버 및 데이터베이스의 데이터를 안전하게 보관하기 위한 별도 백업 솔루션 서비스 입니다.
                                    </span>
                                    <ul class="lsTp2">
                                        <li>Server Back up,  DB Back up </li>
                                        <li>검증된 백업 솔루션을 사용해 서버의 데이터를 정기적으로 백업하고 보관하는 서비스입니다. </li>
                                        <li>유사 시 데이터 복구가 가능해 고객의 비즈니스 연속성을 보장합니다. 중요한 데이터를 안전하게 보호할 수 있도록 높은 안정성과 가용성을 기반으로 제공됩니다. </li>
                                    </ul>
                                </div>
                                <a (click)="ServicePriceRouter('map_storage')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li>

                            <!-- <li id="map_database">
                                <p class="tit"><i class="ico ico_database"></i>데이터베이스</p>
                                <div class="cont">
                                    <span class="txt_blue_tp4">
                                        고객 서비스 요구사항에 맞는 다양한 데이터베이스 플랫폼을 제공합니다.
                                    </span>
                                    <ul class="lsTp2">
                                        <li>
                                            Tibero : 차별화된 아키텍쳐와 다양한 기능이 반영된 DBMS인 Tibero를 Server 설치형으로 제공합니다.
                                        </li>
                                    </ul>
                                </div>
                                <a (click)="ServicePriceRouter('map_database')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li> -->

                            <!-- <li id="map_security">
                                <p class="tit"><i class="ico ico_security"></i>보안</p>
                                <div class="cont">
                                    <span class="txt_blue_tp4">
                                        클라우드 영역에 방화벽 및 VPN을 제공하여, 가상 네트워크 환경에서 보안을 제공 합니다.
                                    </span>
                                    <ul class="lsTp2">
                                        <li>Tibero</li>
                                    </ul>
                                </div>
                                <a (click)="ServicePriceRouter('map_security')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a>
                            </li> -->

                            <li id="map_monitoring">
                                <p class="tit"><i class="ico ico_monitoring"></i>실시간 모니터링</p>
                                <div class="cont">
                                    <span class="txt_blue_tp4">
                                        모니터링 기능을 통해 CPU 사용률, 디스크 사용률, 메모리 사용률 등 시스템 관련 지표를 확인할 수 있어 신속한 대응이 가능하도록 합니다.
                                    </span>
                                    <ul class="lsTp2">
                                        <li>Server Monitoring : 동작중인 VM 서버의 자원 및 프로세스를 실시간으로 모니터링할 수 있게 제공되는 서비스</li>
                                        <li>DB Monitoring : 동작중인 DBMS 서버의 자원 및 프로세스를 실시간으로 모니터링할 수 있게 제공되는 서비스</li>
                                    </ul>
                                </div>
                                <!-- <a (click)="ServicePriceRouter('map_monitoring')" class="btn_more btn midTp1 blue map_move"><i class="ico ico_price"></i>요금표</a> -->
                            </li>

                        </ul>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->

        
    </div>
</div>
<!-- //Container -->
<hr>