<table style="margin-bottom:10px;">
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">블록 스토리지</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{volumeList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL (GB)</th>
                        <td>:</td>
                        <td>{{blockData.totalSize}}</td>
                    </tr>
                    <tr>
                        <th>In-use</th>
                        <td>:</td>
                        <td>{{blockData.inuseCount}}</td>
                    </tr>
                    <tr>
                        <th>Available</th>
                        <td>:</td>
                        <td>{{blockData.availableCount}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">스냅샷</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{snapshotList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL (GB)</th>
                        <td>:</td>
                        <td>{{snapshotData.totalSize}}</td>
                    </tr>
                    <tr>
                        <th>In-use</th>
                        <td>:</td>
                        <td>{{snapshotData.inuseCount}}</td>
                    </tr>
                    <tr>
                        <th>Available</th>
                        <td>:</td>
                        <td>{{snapshotData.availableCount}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">NAS</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{nasList.length}}</span>
            <div class="tooltip-content">
                <table>
                <tr>
                    <th>TOTAL (GB)</th>
                    <td>:</td>
                    <td>{{nasData.totalSize}}</td>
                </tr>
                <tr>
                    <th>In-use</th>
                    <td>:</td>
                    <td>{{nasData.inuseCount}}</td>
                </tr>
                <tr>
                    <th>Available</th>
                    <td>:</td>
                    <td>{{nasData.availableCount}}</td>
                </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
            <i class="mg_r4 stFont">오브젝트 스토리지</i>
            <div class="tooltip">
                <span class="btn mid status mg_r4 tooltip-head">{{objectStorageData.totalBuckets}}</span>
                <div class="tooltip-content" style="width:200px;">
                    <table>
                    <tr>
                        <th>TOTAL OBJECTS</th>
                        <td>:</td>
                        <td>{{objectStorageData.totalObjects}}</td>
                    </tr>
                    <tr>
                        <th>TOTAL FILES</th>
                        <td>:</td>
                        <td>{{objectStorageData.totalFile}}</td>
                    </tr>
                    <tr>
                        <th>TOTAL FOLDERS</th>
                        <td>:</td>
                        <td>{{objectStorageData.totalFolder}}</td>
                    </tr>
                    <tr>
                        <th>TOTAL SIZE</th>
                        <td>:</td>
                        <td>{{objectStorageData.totalBytes}}</td>
                    </tr>
                    <tr>
                        <th>TOTAL SIZE(ROUNDED)</th>
                        <td>:</td>
                        <td>{{objectStorageData.totalBytesRounded}}</td>
                    </tr>
                    </table>
                </div>
            </div>
        </div>
</table>