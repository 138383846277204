import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { thistle } from 'color-name';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

import { DialogSetService } from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';
declare function fn_modalOpen(msg): any;

@Component({
  selector: 'app-console-projects',
  templateUrl: './console-projects.component.html',
})
export class ConsoleProjectsComponent implements OnInit {
  titleString: any = {};

  projectList: any = [];
  // subnetList: any = [];
  // networkList: any = [];
  companyId: any = {};
  resource: any = [];
  // userInfo: { projectId: string; userId: string; companyId: number };
  userInfo: any;
  // Test
  projectInfo: any;
  // 삭제 예정, resource로 통합
  resource2: any = {};
  pfip: string;
  pfid: string;
  emptyProject: boolean = false;

  currentProjectName: string = "";

  is_project_list_loaded = false;

  message: any = {};
  routeAction: string = "";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private frontapi: FrontApiService,
    private osapi: OsapiService,
    public global: GlobalService,
    private spinner: NgxSpinnerService,
    private dialogs: DialogSetService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private common: ConsoleNavComponent
  ) {
    this.routeAction = this.actRoute.snapshot.paramMap.get('action');
  }

  ngOnInit(): void {

    this.getUserInfo();
    
    this.frontapi.currentMessage.subscribe(message => {
      this.message = message;
      if (this.message.type == 'ContentsHeader') {
        this.titleString = this.message.body;
      }
    },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });

    if (this.routeAction) {
      if (this.routeAction == 'create_project') {
        this.openCreateProjectModal()
      }
    }
  }

  /** START Project 관리 */
  onProjectChange(project_id) {
    try {
      this.spinner.show();
      this.osapi.switchProject(project_id).subscribe((data) => {
        this.spinner.hide();
        setTimeout(() => {
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'info',
              title: '프로젝트 변경',
              message: '프로젝트가 변경되었습니다.'
            }
          });
        }, 5000);

        location.reload();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
      this.emptyProject = true;
      this.spinner.hide();
    }
  }

  openCreateProjectModal() {
    if (this.is_project_list_loaded) {
      this.dialogs.openProjectCreateDialog({ projectList: this.projectList });
    } else {
      setTimeout(() => { this.openCreateProjectModal(); }, 500);
    }
  }
  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        this.is_project_list_loaded = true;
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        }
        this.projectList = data.items;
        if (this.emptyProject) {
          this.onProjectChange(this.projectList[0].id);
          this.userInfo.projectId = this.projectList[0].id;
          this.emptyProject = false;
        }
        for (const project of this.projectList) {

          if (project.id == this.userInfo.projectId) {
            if (project.resource_data == null) {
              this.currentProjectName = project.name;
            } else {
              this.currentProjectName = project.resource_data.value;
            }
          }
        }
      },
        (error) => {
          this.currentProjectName = "점검중입니다.";
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }


  getUserInfo() {
    try {
      this.osapi.getUserInfo().subscribe((data) => {
        this.userInfo = data;
        this.common.getThemeFont();
        this.getProjectList();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }
  /** END Project 관리 */
  /** END */
}