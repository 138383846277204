import { Component, OnInit } from '@angular/core';

import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';
declare function fn_modalOpen(msg):any;
declare function valiAllChk(params:any);

@Component({
  selector: 'app-console-snapShot-list',
  templateUrl: './console-snapShotList.component.html',
})

export class ConsoleSnapShotListComponent implements OnInit {
  snapShotLists: any=[];
  imageLists: any=[];
  volumeList:any=[];
  volume_list_loading_flag=false;
  selected_snapshot:any;
  // imageList: any=[];

  // 검색 텍스트
  filterText: string;

  isActived: boolean = false;
  isChecked: boolean = false;

  newStorageData: {
    name:string,
    description:string,
    availability_zone:string,
    size:number,
    volume_type:string,
    metadata:{},
    // group:string,
    source_type:string,
    snapshot_id:string,
    source_volid:string,
    image_id:string
  };

  constructor(
    // @Inject(DOCUMENT) private document: Document,
    // private renderer: Renderer2,
    // private http: HttpClient,
    private osapi:OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private router:Router,
    public global: GlobalService,
    private dialogs:DialogSetService,
    private common:ConsoleNavComponent
    ) { }

  initializeData(){
    this.newStorageData={
      name:"",
      description:"",
      availability_zone:"nova",
      size:0,
      volume_type:"__DEFAULT__",
      metadata:{},
      // group:string,
      source_type:"",
      snapshot_id:null,
      source_volid:null,
      image_id:null
    }
  }
  ngOnInit(): void {
    // this.renderer.addClass(this.document.body, 'bg_blue');
    this.getVolumeSnapshotList()
    this.getVolumeList();

    this.initializeData();
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);    
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }
  
  onClickList(snapshot){
    console.log(snapshot)
    this.isActived = true;
    this.selected_snapshot = snapshot;
  }

  getVolumeList(){    
    this.osapi.getVolumeList().subscribe(
      (data:{items:[]}) => {
        this.spinner.hide();
      this.volumeList= data.items;
      this.volume_list_loading_flag=true;
    }, 
    (error) => {

      this.spinner.hide();
      this.volume_list_loading_flag=true;
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
  getVolumeSnapshotList(){    
    this.spinner.show();
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:{items:[]}) => {
        
      this.osapi.getCurrentProjectImageList().subscribe(
        (res: any) => {
          this.common.getThemeFont();
          this.spinner.hide();
          this.snapShotLists= data.items
          // console.log(this.snapShotLists);
          if(this.snapShotLists.length>0){
            this.snapShotLists.forEach(snapshot => {
            this.osapi.getVolume(snapshot.volume_id).subscribe(
                (volume:{name:any}) => {
                  // console.log(volume);
                  snapshot.volume_name = volume.name;
                });
            });
            this.imageLists = res.body;

            for(let image of this.imageLists){
              if(image.properties.block_device_mapping){
                let image_block_devices = JSON.parse(image.properties.block_device_mapping);
                let snapshot_id="";
                if(Array.isArray(image_block_devices)){
                  const image_block_device = image_block_devices.find(e=> e.device_name=="/dev/vda");
                  if(image_block_device){
                    snapshot_id = image_block_device.snapshot_id;
                  }
                }
                if(snapshot_id){
                  // console.log(snapshot_id)
                  // let imaged_snapshot = this.snapShotLists.find(e=>e.id==snapshot_id);
                  const idx = this.snapShotLists.findIndex(e=>e.id==snapshot_id);
                  if (idx > -1) this.snapShotLists.splice(idx, 1)
                  // console.log(imaged_snapshot)
                  // if(imaged_snapshot){
                  //   imaged_snapshot.image_flag = true
                  // }
                  // imaged_snapshot.status = "true";
                }

              }
            }
            this.reloadSnapshotStatusCheck(10);
          }
        },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        }
      );
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });   
  }
  

  //Status 자동갱신
  reloadSnapshotStatusCheck(count) {
    if(count < 1) return ;
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:any) => {
        let tmp_snapShotLists= data.items;
        let reload_flag = false;

        for( let snapshot of this.snapShotLists){

          if(snapshot.status != "available" ||snapshot.status != "error" ){
            tmp_snapShotLists.forEach(tmp => {
              if(snapshot.id == tmp.id){
                snapshot.status = tmp.status;
                reload_flag = true;
              }
            });
          }
        }
        if(reload_flag){
          this.reloadSnapshotStatusCheck(count-1);
        }
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
  }
  deleteVolumeSnapshot(){    
    this.spinner.show();
    this.osapi.deleteVolumeSnapshot(this.selected_snapshot.id).subscribe(
      (data:{}) => {
        this.spinner.hide();
        this.getVolumeSnapshotList();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }

  
  openNewSnapshotModal(){
    if(this.volume_list_loading_flag){
      this.dialogs.openStorageSnapshotCreateDialog({selected_volume:{id:""},volumeList:this.volumeList});
    }else{
      this.spinner.show();
      setTimeout(() =>  { this.openNewSnapshotModal();  }, 500);
    }
  }
  openUpdateSnapshotModal(){
    this.dialogs.openStorageSnapshotUpdateDialog({selected_snapshot:this.selected_snapshot});
  }
  openNewStorageModal(){
    this.dialogs.openStorageCreateDialog({selected_snapshot:this.selected_snapshot});
  }

  openDeleteSnapshotModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"스토리지 스냅샷 삭제",
      content:"선택하신 스토리지 스냅샷("+this.selected_snapshot.name+")을 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteVolumeSnapshot();
        }
      });
  }

  onNewStorageNameChange(deviceValue){
    this.newStorageData.name = deviceValue;
  }
  onNewStorageDescChange(deviceValue){
    this.newStorageData.description = deviceValue;
  }
  onNewStorageAvailZoneChange(deviceValue){
    this.newStorageData.availability_zone = deviceValue;

  }
  onNewStorageSizeChange(deviceValue){
    if(deviceValue < this.selected_snapshot.size){
      this.newStorageData.size = this.selected_snapshot.size
    }else{
      this.newStorageData.size = deviceValue;
    }
  }
  onNewStorageTypeChange(deviceValue){
    this.newStorageData.volume_type = deviceValue;
  }
  
  createVolume(){
    //name,description,availability_zone,size,volume_type,metadata,image_id,source_volid,snapshot_id
    this.spinner.show();
    if(this.newStorageData.name == null || this.newStorageData.name ==''){
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'스토리지의 이름을 입력하지 않았습니다.'
        }
      });
      return;
    }
    this.osapi.createVolume(this.newStorageData.name,this.newStorageData.description,
      this.newStorageData.availability_zone,this.newStorageData.size,
      this.newStorageData.volume_type = "__DEFAULT__",this.newStorageData.metadata,
      this.newStorageData.image_id,this.newStorageData.source_volid,this.newStorageData.snapshot_id).subscribe(
      (data:{}) => {
        this.router.navigate(['/console/storage']);
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }); 
  }
}
