import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { WebApiService } from '../../webapi.service';
import { Router } from '@angular/router';
declare const $: any;
declare function sendAuthNum(flag);

@Component({
  selector: 'app-find-password',
  templateUrl: './find-password.component.html',
})
export class FindPassWordComponent implements OnInit {
  submitted = false;
  confirmCompanyCode = '';
  findPasswordType = "";

  constructor(
    private webapi: WebApiService,
    private router: Router
  ) { }

  findpwdEmailForm = new FormGroup({
    userId: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$'),
    ]),
  });

  get findpwdEmail() {
    return this.findpwdEmailForm.controls
  }

  findpwdPhoneForm = new FormGroup({
    userId: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$')
    ]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10)
    ])
  })

  get findpwdPhone() {
    return this.findpwdPhoneForm.controls
  }

  ngOnInit(): void {
    this.findPasswordType = 'email'
  }

  changePsswordType(e) {
    this.findPasswordType = e.target.value
    this.findpwdEmailForm.reset()
    this.findpwdPhoneForm.reset()
    this.submitted = false
  }

  onSubmitEmail(): void {
    this.submitted = true
    if (this.findpwdEmailForm.invalid) {
      const invalid = [];
      const controls = this.findpwdEmailForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      return;
    }
    this.makePasswordResetCodeByEmail()
  }

  makePasswordResetCodeByEmail() {
    this.webapi.makePasswordResetCode(this.findpwdEmailForm.value).subscribe(
      (response) => {
        alert('인증코드를 해당 이메일로 보냈습니다.');
        sendAuthNum(false);
        $('#confirmCodeLi').show();
        $('#findpassword').hide();
        $('#codeCheck').show();
        $(':radio:not(:checked)').attr('disabled', true);
        $("input[name=input]").attr('readonly', true);
      },
      (error) => {

        switch(error.status) {
          case 404:
            alert("이메일이 없습니다.")
            break;
          case 400:
            alert("전송 형식이 맞지 않습니다.")
            break;
        }
      }
    )
  }

  onSubmitPhone(): void {
    this.submitted = true
    if (this.findpwdPhoneForm.invalid) {
      const invalid = [];
      const controls = this.findpwdPhoneForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      return;
    }
    this.makePasswordResetCodeByPhone()
  }
  makePasswordResetCodeByPhone() {
    this.webapi.makePasswordResetCode(this.findpwdPhoneForm.value).subscribe(
      (response) => {
        alert('인증번호를 보냈습니다.');
        sendAuthNum(false);
        $('#confirmCodeLi').show();
        $('#findpassword').hide();
        $('#codeCheck').show();
        $(':radio:not(:checked)').attr('disabled', true);
        $("input[name=input]").attr('readonly', true);
      },
      (error) => {

        switch(error.status) {
          case 401:
            alert("저장된 휴대폰 번호와 일치하지 않습니다.")
            break;
          case 404:
            alert("전화번호나 아이디가 없습니다.")
            break;
          case 400:
            alert("전송 형식이 맞지 않습니다.")
            break;
        }
      }
    )
  }
  confirmCodeCheck() {
    const code = $('#confirmCode').val()
    if (code === null || code === undefined || code === '') {
      alert('인증번호를 입력해 주세요.');
      return;
    }
    this.webapi.confirmPasswordResetCode({ code }).subscribe(
      (response) => {
        alert('인증번호가 일치 합니다. 비밀번호 변경 페이지로 이동합니다.')
        this.router.navigate(['/member/reset-password-by-code']);
      }, (err) => {

        alert('인증번호가 일지 하지 않습니다.')
      });
  }

  reConfirmCode() {
    if (this.findPasswordType === 'email') {
      this.makePasswordResetCodeByEmail()
    } else {
      this.makePasswordResetCodeByPhone()
    }
  }

  routerHome(){
    this.router.navigate(['/']);
  }
}
