<!-- wrap -->
<div id="Wrap">
    <!-- allMenuBox -->
    <!-- Header -->
    <div id="Header">
        <a href="/" class="logo"></a>
    </div>
    <!-- //Header -->
    
    <!-- Container -->
    <div id="Container">
        <!-- leftMenu -->
        <div class="leftMenu">
            <!-- menu_inner -->
            <div class="menu_inner">
                <!-- gnb_wrap -->
                <div class="gnb_wrap">
                    <!-- <a class="btn mid_fix btn_allMenu">
                        <i class="ico ico_allMenu"></i>
                    </a> -->
                    <ul class="gnb">
                        <li>
                            <!-- <a class="btn mid_fix dBlue btn_myMenu"><i class="ico ico_member_white"></i></a> -->
                        </li>
                    </ul>
                </div>
                <!-- tab_wrap -->
                <div class="tab_wrap">
                    <div class="tab_header">
                        <ul class="tabMenu tp1 colCnt2">
                            <li class="on"><a href="javascript:void(0);" class="leftFix"><span>관리자메뉴</span></a></li>
                            <li disabled><a href="javascript:void(0);" class="leftFix" disabled><span>&nbsp;&nbsp;</span></a></li>
                        </ul>
                    </div>
                    <div class="tab_content">
                        <!-- lnb_wrap -->
                        <div class="lnb_wrap">
                            <ul class="lnb">
                                <li [class]="admin.isActive || equipment.isActive || monitoring.isActive ? 'depth1 on' : 'depth1'">
                                    <a href="javascript:void(0);">DASHBOARD</a> 
                                    <ul class="subMenu" [style.display]="admin.isActive || equipment.isActive || monitoring.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" [routerLinkActiveOptions]="{exact: true}" #admin="routerLinkActive">
                                            <a routerLink="/admin">Dashboard(준비중)</a>
                                        </li>
                                        <li routerLinkActive="on" #equipment="routerLinkActive">
                                            <a routerLink="equipment">장비 목록(준비중)</a>
                                        </li>
                                        <li routerLinkActive="on" #monitoring="routerLinkActive">
                                            <a routerLink="monitoring">모니터링(준비중)</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="depth1" routerLinkActive="on">
                                    <a href="javascript:void(0);">회원 관리</a>
                                    <ul class="subMenu" [style.display]="user.isActive || subuser.isActive || withdrawal.isActive || userlog.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" #user="routerLinkActive">
                                            <a routerLink="user">회원 목록</a>
                                        </li>
                                        <li routerLinkActive="on" #subuser="routerLinkActive">
                                            <a routerLink="subuser">서브 계정 목록</a>
                                        </li>
                                        <li routerLinkActive="on" #withdrawal="routerLinkActive">
                                            <a routerLink="withdrawal-list">회원 탈퇴 관리</a>
                                        </li>
                                        <li routerLinkActive="on" #userlog="routerLinkActive">
                                            <a routerLink="user-log">회원 로그</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="depth1" routerLinkActive="on">
                                    <a href="javascript:void(0);">게시판 관리</a>
                                    <ul class="subMenu" [style.display]="notice.isActive || faq.isActive || qna.isActive || ref.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" #notice="routerLinkActive">
                                            <a routerLink="boardNotice">공지사항 </a>
                                        </li>
                                        <li routerLinkActive="on" #faq="routerLinkActive">
                                            <a routerLink="boardFaq">FAQ</a>
                                        </li>
                                        <li routerLinkActive="on" #qna="routerLinkActive">
                                            <a routerLink="boardQna">1:1문의</a>
                                        </li>
                                        <li routerLinkActive="on" #ref="routerLinkActive">
                                            <a routerLink="boardRef">자료실</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="depth1" routerLinkActive="on">
                                    <a href="javascript:void(0)">청구/정산 관리</a>
                                    <ul class="subMenu" [style.display]="claimList.isActive ||claim.isActive || payBoard.isActive 
                                        || credit.isActive || creditlist.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" #claimList="routerLinkActive">
                                            <a routerLink="claimList">청구 내역</a>
                                        </li>
                                        <li routerLinkActive="on" #claim="routerLinkActive">
                                            <a routerLink="claim">정산 내역</a>
                                        </li>
                                        <!--li>
                                            <a routerLink="claimPay">청구 요금 통계(작업중)</a>
                                        </li-->
                                        <li routerLinkActive="on" #payBoard="routerLinkActive">
                                            <a routerLink="payBoard">서비스 관리</a>
                                        </li>
                                        <li routerLinkActive="on" #credit="routerLinkActive">
                                            <a routerLink="credit">크레딧 부여</a>
                                        </li>
                                        <li routerLinkActive="on" #creditlist="routerLinkActive">
                                            <a routerLink="creditlist">크레딧 사용 내역</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="depth1" routerLinkActive="on">
                                    <a href="javascript:void(0)">메일/문자 발송</a>
                                    <ul class="subMenu" [style.display]="mail.isActive || mailList.isActive || sms.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" #mail="routerLinkActive">
                                            <a routerLink="sendMail">메일 발송</a>
                                        </li>
                                        <li routerLinkActive="on" #mailList="routerLinkActive">
                                            <a routerLink="sendMail_list">메일 발송 내역</a>
                                        </li>
                                        <li routerLinkActive="on" #sms="routerLinkActive">
                                            <a routerLink="sendSms">SMS 발송</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="depth1" routerLinkActive="on">
                                    <a href="javascript:void(0)">통합 신청 관리</a>
                                    <ul class="subMenu" [style.display]="dns.isActive || serverbak.isActive || dbbak.isActive 
                                        || dbms.isActive || baremetal.isActive || queue.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" #dns="routerLinkActive">
                                            <a routerLink="dns">DNS 신청관리</a>
                                        </li>
                                        <li routerLinkActive="on" #serverbak="routerLinkActive">
                                            <a routerLink="serverbackup">서버백업 신청관리</a>
                                        </li>
                                        <li routerLinkActive="on" #dbbak="routerLinkActive">
                                            <a routerLink="dbbackup">DB백업 신청관리</a>
                                        </li>
                                        <li routerLinkActive="on" #dbms="routerLinkActive">
                                            <a routerLink="dbms">DBMS 제공 관리</a>
                                        </li>
                                        <li routerLinkActive="on" #baremetal="routerLinkActive">
                                            <a routerLink="baremetal">베어메탈 장비제공 관리</a>
                                        </li>
                                        <li routerLinkActive="on" #queue="routerLinkActive">
                                            <a routerLink="messagequeue">메세지큐 신청관리</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="depth1" routerLinkActive="on">
                                    <a href="javascript:void(0)">COMPUTE</a>
                                    <ul class="subMenu" [style.display]="flavor.isActive || flavor.isActive ? 'block' : 'none'">
                                        <li routerLinkActive="on" #flavor="routerLinkActive">
                                            <a routerLink="flavor">flavor 관리</a>
                                        </li>
                                        <li routerLinkActive="on" #image="routerLinkActive">
                                            <a routerLink="image">이미지 관리</a>
                                        </li>
                                    </ul>
                                </li>
                                <li routerLinkActive="on" #image="routerLinkActive">
                                    <a routerLink="setting">설정</a>
                                </li>
                            </ul>
                        </div>
                        <!-- //lnb_wrap -->
                    </div>
                </div>
                <!-- //tab_wrap -->
            </div>
            <!-- //menu_inner -->
        </div>
        <!-- //leftMenu -->
        <router-outlet></router-outlet>
    </div>
    <!-- //Container -->
</div>
<!-- //wrap -->

<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  [fullScreen]="true"
  ><p style="color: white;">Loading...</p></ngx-spinner
> -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="medium" color="#fff" type="line-spin-fade-rotating" [fullScreen]="false">
    <p style="color: black;">Loading...</p>
  </ngx-spinner>