<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>서버 생성</h2>
    <div class="lineMap">Server / <span>Instances</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- <form [formGroup]="serverFrm" (ngSubmit)="onSubmit('modal_comfirm')"> -->
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="createBox">
          <div #step1 class="titArea">
            <h3>Step 1. 서버설정</h3>
          </div>
          <div class="titArea tpA">
            <small>서버의 기본적인 설정을 입력해주세요.</small>
          </div>
          <div class="contArea" style="display: block;" id="contMain">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <!-- <tr class="required">
                        <th scope="row">Zone 선택</th>
                        <td>
                          <select name="zone_val" id="sZone" required>
                            <option
                              *ngFor="let zone of availZoneLists"
                              [attr.data-id]="zone.zoneName"
                            >
                              {{ zone.zoneName }}</option
                            >
                          </select>
                        </td>
                      </tr> -->
                  <tr class="required">
                    <th scope="row">서버명</th>
                    <td>
                      <input type="text" name="sel_name" id="sName" (change)="onNameChange($event.target.value)"
                        placeholder="20자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다."
                        title="20자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다." required maxlength="20"/>
                      <!-- [class]="serverChk.sel_name.invalid && (submitted || serverChk.sel_name.touched || serverChk.sel_name.dirty) ? 'error-invalid' : ''"  -->
                      <!-- <div *ngIf="serverChk.sel_name.invalid && (submitted || serverChk.sel_name.touched || serverChk.sel_name.dirty)">
                              <div class="error-invalid-feedback" *ngIf="serverChk.sel_name.errors.required">
                                  서버명은 필수 값입니다. 20자 이내로 작성해주세요.
                              </div>
                              <div class="error-invalid-feedback" *ngIf="serverChk.sel_name.errors.pattern">
                                  영문자와 숫자, '-', '.'만 입력 가능합니다.
                              </div>
                          </div> -->
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">설명</th>
                    <td>
                      <textarea name="memo_val" id="sMemo" (change)="onDescriptChange($event.target.value)" rows="5"
                        placeholder="내용을 입력해주세요" maxlength="255"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
          <!-- //lyBox -->

          <!-- lyBox -->
          <div #step2 class="titArea">
            <h3>Step 2. 상세설정</h3>
          </div>
          <div class="titArea tpA">
            <small>서버에 사용할 이미지와 유형을 선택해주세요. </small>
          </div>
          <div class="contArea" style="display: block;" id="contDetail">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <!-- <tr>
                          <th>이미지 타입</th>
                          <td>
                            <input type="radio" name="field_name3" id="radio1" checked (click)="setImageType('image')"/><label for="radio1" class="mg_r8"> 이미지</label>
                            <input type="radio" name="field_name3" id="radio2" (click)="setImageType('volume')"/><label for="radio2"> 볼륨</label>
                          </td>
                        </tr> -->
                  <tr *ngIf="imagetype==='image'" class="required">
                    <th scope="row">이미지 선택</th>
                    <td>
                      <select name="sel_image" id="sImage" [(ngModel)]="sel_image"
                        (change)="onImageChange($event.target.value)" class="size435">
                        <!-- required [class]="serverChk.sel_image.invalid && (submitted || serverChk.sel_image.touched || serverChk.sel_image.dirty) ? 'error-invalid' : ''"  -->
                        <option value="">-선택-</option>
                        <option *ngFor="let image of imageLists" [value]="image.image_id">
                          <ng-template [ngIf]="image.my_image">[개인]</ng-template>
                          {{ image.name }}
                        </option>
                      </select>

                      <!-- <div *ngIf="serverChk.sel_image.invalid && (submitted || serverChk.sel_image.touched || serverChk.sel_image.dirty)">
                            <div class="error-invalid-feedback" *ngIf="serverChk.sel_image.errors.required">
                              이미지는 필수 값입니다.
                            </div>
                          </div> -->
                    </td>
                  </tr>
                  <tr *ngIf="imagetype==='volume'" class="required">
                    <th scope="row">볼륨 선택</th>
                    <td>
                      <select name="sel_image" id="sImage" [ngModel]="restore_image.image_id"
                        (change)="onVolumeChange($event.target.value)" class="size435">
                        <!-- required [class]="serverChk.sel_image.invalid && (submitted || serverChk.sel_image.touched || serverChk.sel_image.dirty) ? 'error-invalid' : ''"  -->
                        <option value="">-선택-</option>
                        <option *ngFor="let volume of volumeList" [value]="volume.id">{{ volume.name }}</option>
                      </select>

                      <!-- <div *ngIf="serverChk.sel_image.invalid && (submitted || serverChk.sel_image.touched || serverChk.sel_image.dirty)">
                            <div class="error-invalid-feedback" *ngIf="serverChk.sel_image.errors.required">
                              이미지는 필수 값입니다.
                            </div>
                          </div> -->
                    </td>
                  </tr>
                  <tr class="required">
                    <th scope="row">서버유형 선택</th>
                    <td>
                      <select name="server_val" id="sOption" (change)="onFlavorChange($event.target.value)" required
                        class="size435">
                        <option value="">-선택-</option>
                        <option *ngFor="let flavor of flavorLists" [value]="flavor.id" [innerHTML]="flavor.flavor_text">

                          <!-- <li style="width:30%">{{ flavor.name }}</li> -->
                          <!-- <span style="padding-left:15px;padding-right:15px;">vCPU {{ flavor.vcpus }}개, 메모리</span>
                              <span style="padding-left:15px;padding-right:15px;">{{ flavor.ram / 1024 }}GB, </span>
                              <span style="padding-left:15px;padding-right:15px;"> 디스크 {{ flavor.disk }}GB </span> -->

                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr class="">
                    <th scope="row">추가 볼륨 선택</th>
                    <td>
                      <input type="radio" name="field_name3" id="create-volume-radio1" (click)="setVolumeState(true)"/><label for="create-volume-radio1" class="mg_r8"> 사용</label>
                      <input type="radio" name="field_name3" id="create-volume-radio2" (click)="setVolumeState(false)" checked /><label for="create-volume-radio2"> 사용 안함</label>
                    </td>
                  </tr>
                  <tr *ngIf="isVolumeCreate">
                    <th scope="row">볼륨 이름</th>
                    <td>
                      <input class="size435" type="text" [(ngModel)]="extendVolumeName" placeholder="추가 볼륨 이름을 입력해주세요.">
                    </td>
                  </tr>
                  <tr *ngIf="isVolumeCreate">
                    <th scope="row">볼륨 크기</th>
                    <td>
                      <input class="size435" id="extendVolume" [(ngModel)]="extendVolumeSize" type="number" placeholder="볼륨 크기를 입력해주세요.단위는 GB입니다."><label class="mg_l5" for="extendVolume"> GB</label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
          <!-- //lyBox -->

          <!-- lyBox -->
          <div #step3 class="titArea">
            <h3>Step 3. 네트워크 설정</h3>
          </div>
          <div class="titArea tpA">
            <small>서버에 사용할 네트워크를 선택해주세요. </small>
          </div>
          <div class="contArea" style="display: block;" id="contDetail">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <tr class="required">
                    <th scope="row">네트워크 선택</th>
                    <td>
                      <select (change)="onSubnetChange($event.target.value)" class="size435">
                        <option value="">-선택-</option>
                        <option *ngFor="let subnet of subnetList" [value]="subnet.id">
                          {{subnet.name}}[{{subnet.cidr}}]
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
          <!-- //lyBox -->

          <!-- lyBox -->
          <div #step4 class="titArea">
            <h3>Step 4. 보안그룹설정</h3>
          </div>
          <div class="titArea tpA">
            <small>보유하고 있는 보안그룹을 선택하거나 새로운 보안그룹을 생성해주세요. </small>
          </div>
          <div class="contArea" style="display: block;" id="contSecure">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">보안그룹 생성</th>
                    <td>
                      <a class="btn mid ciblue" (click)="openNewSecurityGroupModal()"><i
                          class="ico ico_register_white"></i>신규생성</a>
                    </td>
                  </tr>
                  <tr class="required">
                    <th scope="row">보안그룹 선택</th>
                    <td>
                      <div>
                        <select name="group_value" id="sGroup" [(ngModel)]="sel_sg"
                          (change)="onSGChange($event.target.value)" class="size435">
                          <option value="">-선택-</option>
                          <option *ngFor="let group of securityGroupList" [value]="group.id">
                            {{ group.name }}</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
          <!-- //lyBox -->
          <!-- lyBox -->
          <div #step5 class="titArea">
            <h3>Step 5. 인증키설정</h3>
          </div>
          <div class="titArea tpA">
            <small>보유하고 있는 인증키를 선택하거나 새로운 인증키를 생성하세요. 인증키는 서버에 접근하는데 사용합니다. </small>
          </div>
          <div class="contArea" style="display: block;" id="contAuthkey">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">인증키 생성</th>
                    <td>
                      <a class="btn mid ciblue" (click)="openNewKeypairModal()"><i
                          class="ico ico_register_white"></i>신규생성</a>
                    </td>
                  </tr>
                  <tr class="required">
                    <th scope="row">인증키 선택</th>
                    <td>
                      <select name="key_val" id="sKey" [(ngModel)]="sel_keypair"
                        (change)="onKeyPairChange($event.target.value)" class="size435">
                        <option value="">-선택-</option>
                        <option *ngFor="let keypair of keyPairList" [value]="keypair.keypair.name">
                          {{ keypair.keypair.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="btn_wrap tp1">
              <button type="submit" class="btn big blue" (click)="openConfirmModal()"><i
                  class="ico ico_check_white"></i>서버생성</button>
            </div>
          </div>
        </div>
        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
    <!-- </form> -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->