import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import { Router, ActivatedRoute, NavigationExtras} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.component.html',
})
export class MaterialListComponent implements OnInit {

  // 페이징 변수
  curPage:any=0;
  totalCount:any;

  materialList: any = [];
  searchCate: string = '';
  searchTitle: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
        if(params['page']!=null && params['page']!='undefined'&& params['page']!=""){
          this.curPage = params['page'];
        }

        if(params['searchTitle']!=null && params['searchTitle']!=""){
          this.searchTitle = params['searchTitle'];
        }
        
        if(params['searchCate']!=null && params['searchCate']!=""){
          this.searchCate = params['searchCate'];
        }
    });
  }


  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.getMaterialList(this.curPage);
  }


  getMaterialList(page){
    this.curPage = page;
    var searchQuerys = "&l=10&s="+(this.curPage*10)+"&o=created_date.DESC";
    if(this.searchCate!=""){
      searchQuerys += "&category="+decodeURIComponent(this.searchCate+"");
    }
    if(this.searchTitle!=""){
      searchQuerys += "&title="+decodeURIComponent(this.searchTitle+"")+"&lk=title";
    }
    this.spiner.show();
    this.webapi.getMaterialList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.materialList = data['result'];
        this.totalCount = data['total'];
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  getMaterialView(key){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        materialId: key,
      },
      skipLocationChange: true,
    };
    this.router.navigate(['/web/materialView'], navigationExtras);
  }

  //페이징함수
  getMovePage(e){
    this.getMaterialList(e.page)
  }
  
  // 검색
  fn_materialSearch(title){
    this.searchTitle = title;
    this.getMaterialList(0);
  }

  onSearchCateChange(deviceValue){
    this.searchCate=deviceValue;
  }
  
  onSearchTitleChange(deviceValue){
    this.searchTitle=deviceValue;
  }
}
