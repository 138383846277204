import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WebApiService } from '../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare const $: any;
@Component({
  selector: 'app-mypage-credit',
  templateUrl: './mypage-credit.component.html',
})
export class MypageCreditComponent implements OnInit {
  userInfoList: any = [];
  availableCredit = 0;
  creditHistoryInfo : any = [];
  

  // 페이징 변수
  curPage: any;
  totalCount: any;
  searchText: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private spiner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    this.getMaxCreditInfo();
    this.getCreditHistory(0);
  }

  getMaxCreditInfo(){
    this.spiner.show()
    this.webapi.getAvailableCredit(this.userInfoList.registrationNumber,this.userInfoList.registrationNumberSub).subscribe(
      (response) => {
        const data = response.body
        this.availableCredit = data['sum'];
      }, (err) => {

      }
    );
    this.spiner.hide();
  }
  
  getCreditHistory(page){
    this.curPage = page;
    let searchQueries = `o=credit_history_id.DESC&l=10&s=${this.curPage * 10}&registration_number=${this.userInfoList.registrationNumber}&registration_number_sub=${this.userInfoList.registrationNumberSub}`;
    this.spiner.show();
    this.webapi.getCreditHistory(searchQueries).subscribe(
      (response) => {
        const data = response.body
        this.creditHistoryInfo = data['result'];
        this.totalCount = data['total'];      
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  //페이징함수
  getMovePage(e){

    this.getCreditHistory(e.page)
  }

}
