<!-- Modal : Bucket 생성 모달 -->
<div id="folder_create" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      {{ title }}
      <!-- <p class="sub-txt">액세스 키를 사용하여 S3 SDK 또는 직접 S3 API 호출을 통해 오브젝트 스토리지를 프로그래밍 방식으로 호출합니다.</p> -->
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <tbody>
          <tr>
            <td><input type='text' [(ngModel)]="currentPath" readonly></td>
          </tr>
          <tr>
            <td>
                <div style="height: 340px; width: 550px; overflow: hidden;">
                    <tree-root
                      #tree
                      [nodes]="nodes"
                      [options]="options"
                      [focused]="true"
                      (activate)="onEvent($event)"
                    >
                    </tree-root>
                  </div>
              <!-- <tree-root [nodes]="nodes" [options]="options" (activate)="onEvent($event)"></tree-root> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <a (click)="clickCopyMove()" class="btn big ciblue mg_r4">{{ type == 'copy' ? '복사' : '이동' }}</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->