import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OsapiService } from '../../osapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';

@Component({
  selector: 'app-console-db-instance',
  templateUrl: './console-db-instance.component.html',
})
export class ConsoleDbInstanceComponent implements OnInit {
  
  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    public global: GlobalService,
  ) {}

  ngOnInit(): void {}

}
