import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NetworkData } from './NetworkData';
import { CidrData } from './CidrData';
import { FrontApiService } from '../../frontapi.service';
import { TranslateService } from '@ngx-translate/core';
import { $ } from 'protractor';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import { DialogSetService } from '../dialog/dialog-set.service';
import { SubnetData } from "./subnetdata";
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function valiAllChk(params: any);
declare function fn_modalOpen(params: any);

@Component({
  selector: 'app-console-pivate-network-list',
  templateUrl: './console-private-network-list.component.html',
})
export class ConsolePrivateNetworkListComponent implements OnInit {

  networkList: any = [];
  availableNetworkList: any=[];
  resource: any = [];
  selected_network: any = {};

  // 검색 텍스트
  filterText: string;

  checkAdminState: boolean = true;
  checkSnatState: boolean = false;
  // usedCidrList: any = [];

  subnetModel = new SubnetData('', '', '', '');

  // 생성 시 validation 체크
  network_cidr_is_include = true;
  network_cidr_validate = true;
  network_cidr_not_used = true;
  /**
   * Network 생성 모델
   * default true = 내부네트워크
   */
  networkModel = new NetworkData('', '', true);
  cidrs: any = [];

  message: any = {};

  isActived: boolean = false;
  isChecked: boolean = false;

  constructor(
    private osapi: OsapiService,
    private webapi: WebApiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    public global: GlobalService,
    private dialogs: DialogSetService,
    private router: Router,
    private common: ConsoleNavComponent
  ) { }

  ngOnInit(): void {
    this.getNetworkList();

    this.frontapi.currentMessage.subscribe((message) => {
      this.message = message;
    }, (error) => {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e) {
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }


  onClickList(network) {
    this.isActived = true;
    this.selected_network = network;
  }

  getResource() {
    this.osapi.getResource('network').subscribe((data: any[]) => {
      this.resource = data;
    },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  getNetworkList() {
    this.spinner.show();
    this.osapi.getNetworkList().subscribe(
      (data: any) => {
        this.networkList = [];
        this.availableNetworkList = [];

        for (let network of data) {
          if (network.network_type == 'int') {
            this.networkList.push(network);
            if(network.isNetworkOwner){
              this.availableNetworkList.push(network);
            }
          }
        }
        this.common.getThemeFont();
        this.spinner.hide();
      }
    ), (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }

  
  openNewNetworkModal() {
    this.networkModel = new NetworkData('', '', true);
    this.dialogs.openPrivateNetworkCreateDialog({networkModel:this.networkModel,resource:this.resource});
  }
  openShareModal() {
    // this.getProjectList();
    this.dialogs.openPrivateNetworkShareManageDialog({selected_network:this.selected_network});
  }
  openCreateSubnetModal() {
    this.subnetModel = new SubnetData('', '', '', '');
    // fn_modalOpen('subnet_create')
    this.dialogs.openSubnetCreateDialog({subnetModel:this.subnetModel,networkList:this.networkList,resource:this.resource});
  }
  openDeleteNetworkModal() {
    this.dialogs.openDefaultDeleteDialog({
      title:"네트워크 삭제",
      content:"선택하신 네트워크("+this.selected_network.name+")를 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteNetwork(this.selected_network);
        }
      });
  }

  deleteNetwork(network) {
    this.spinner.show();
    if (network.existHostPort) {
      this.spinner.hide();
      // 호스트 연결되어있음
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'연결 된 인스턴스가 존재합니다.'
        }
      });
    } else {
      this.osapi.deleteNetwork(network.id).subscribe(
        (data: any) => {
          this.spinner.hide();
          if(this.router.url=='/console/privateNetwork'){
            this.router.navigateByUrl('/console', {
              skipLocationChange: true,
            }).then(
              () => {
                this.router.navigate(['/console/privateNetwork']);
              }
            );
          }else{
            this.router.navigate(['/console/privateNetwork']);
          }
          // Resource 삭제
          this.osapi.blsDestroyResource({ objectId: network.id, status: "DELETED" }).subscribe(
            (data: any) => {
            }
          )
          // PFIP 리소스 삭제

        },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        }
      );
    }
  }
}
