<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>setting 목록</h2>
        <div class="lineMap">
            Admin  /  <span>setting</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="consoleRightBody lyBox">
                    <div class="titArea">
                        <h3>setting</h3>
                    </div>
                    <div class="titArea tpA">
                        <small>화면 설정</small>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardCreateBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:220px;" />
                                    <col style="width:auto" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td scope="row">로고 변경</td>
                                        <td>
                                            <input name="image-file" type="file"/>
                                            <input type="submit" (click)="uploadFile()"/>
                                        </td>
                                    </tr>
                                    <tr class="">
                                        <th scope="row">테마 선택</th>
                                        <td>
                                            <mat-form-field appearance="legacy">
                                                <select [(ngModel)]="themeSelect" matNativeControl (change)="changeTheme($event.target.value)" id="selectBox">
                                                    <option value="1">Default</option>
                                                    <option value="2">Dark</option>
                                                    <option value="3">Cosmic</option>
                                                </select>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->