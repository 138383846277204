import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-private-network-share-manage',
  templateUrl: './dialog-private-network-share-manage.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogPrivateNetworkShareManageComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogPrivateNetworkShareManageComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_network: any = {};
  rbacList: any = [];
  projectList: any = [];
  userInfo: any = [];
  portList:any=[];

  ngOnInit(): void {

    this.selected_network = this.dialogData.selected_network;
    this.getProjectList();
    this.getUserInfo();
  }

  /**
   *  20200901 cbkim RBAC 추가
   */

  getRBACList() {

    this.osapi.getRBACList().subscribe(
      (data: any) => {
        


        this.rbacList = [];
        this.rbacList = data.rbac_policies;

        var sharedList = [];
        var newRbacList = [];
        for (let rbac of this.rbacList) {
          let i = 0;
          for (let project of this.projectList) {
            if (project.id === rbac.target_tenant) {           
              if(rbac.object_id === this.selected_network.id){
                rbac.project_name = project.name;
                newRbacList.push(rbac);
                sharedList.push(project.id);  
              }   
            }
            i++;
          }
        }
        this.rbacList = newRbacList;




        var newProjectList = [];
        for (let project of this.projectList) {
          let check = false;
          for (let exist of sharedList) {
            if (project.id == exist) {
              check = true;
              break;
            }
          }
          if (!check) {
            newProjectList.push(project);
          }
        }

        this.projectList = newProjectList;

        this.spinner.hide();
      }
    )
  }

  createRBAC(project) {
    const param = {
      object_id: this.selected_network.id,
      target_tenant: project.id
    }
    
    this.spinner.show();
    this.osapi.createRBAC(param).subscribe(
      (data) => {

        
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'success',
            title: '네트워크 공유',
            message: `네트워크 공유가 추가되었습니다.`
          }
        });
        
        this.getProjectList();
      },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  deleteRBAC(rbac) {
    
    this.spinner.show();
    this.osapi.deleteRBAC(rbac.id).subscribe(
      (data) => {

        // this.getRBACList();
        
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'success',
            title: '네트워크 공유',
            message: '네트워크 공유가 삭제되었습니다.'
          }
        });

        // 타이밍에 문제가있음..........
        setTimeout(()=>{
          this.getProjectList();
        },500)
        
      }
    ),(error)=>{
      this.spinner.hide();

      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }


  getUserInfo() {
    try {
      this.osapi.getUserInfo().subscribe((data) => {

        this.userInfo = data;
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      console.error(err);
    }
  }


  getProjectList() {
    // this.spinner.show();
    this.osapi.getCurrentProject().subscribe(
      (projectId) => {

        this.osapi.getProjectList().subscribe((data: any) => {
          this.projectList = [];

          for (let project of data.items) {
            if (project.id != projectId) {
              project.name = project.resource_data.value;
              this.projectList.push(project);
            }
          }

          this.getRBACList();


        }, (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
      }, (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  detachInterface(port) {
    for(let rbac of this.rbacList){
      let newPortArr = [];
      for(let i=0;i<rbac.ports.length;i++){
        if(rbac.ports[i].id != port.id){
          newPortArr.push(rbac.ports[i]);
          break;
        }
      }
      rbac.ports = newPortArr;
    }
    this.spinner.show();
    this.osapi.serverInfDetach(port.device_id, port.id).subscribe((data: any) => {

      setTimeout(()=>{
        this.getProjectList();
      },100);
      this.spinner.hide();
    }, (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  /** END CLASS */
}
