import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-backup-server',
  templateUrl: './admin-backup-server.component.html',
})
export class AdminBackupServerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  deleteService(){

  }
}
