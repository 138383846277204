
<!-- Modal : 최종 확인 -->
<div id="modal_comfirm" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>최종 확인</h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="msg">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tbody>
            <tr>
              <th>서버명</th>
              <td>{{ confirmData.sel_name }}</td>
            </tr>
            <tr>
              <th>서버설명</th>
              <td>{{ confirmData.sel_description }}</td>
            </tr>
            <tr *ngIf="confirmData.imagetype === 'image'">
              <th>이미지명</th>
              <td>{{ confirmData.sel_image_object.name }}</td>
            </tr>
            <tr *ngIf="confirmData.imagetype === 'volume'">
              <th>볼륨명</th>
              <td>{{ confirmData.sel_volume_object.name }}</td>
            </tr>
            
            <tr>
              <th>서버유형</th>
              <td > <p *ngIf="confirmData.sel_flavor_object.name !=''">[{{ confirmData.sel_flavor_object.name }}] vCPU {{ confirmData.sel_flavor_object.vcpus }}개, 메모리
                {{ confirmData.sel_flavor_object.ram / 1024 }}GB, 디스크
                {{ confirmData.sel_flavor_object.disk }}GB </p> </td>
            </tr>
            <tr>
              <th>보안그룹</th>
              <td>{{ confirmData.sel_sg_object.name }}</td>
            </tr>
            <tr>
              <th>인증키명</th>
              <td>{{ confirmData.sel_keypair }}</td>
            </tr>
            <tr *ngIf="confirmData.isVolumeCreate">
              <th>추가 볼륨</th>
              <td>{{confirmData.extendVolumeName}}, {{confirmData.extendVolumeSize}} GB</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="btnArea">
      <a (click)="createServer()" class="btn big ciblue mg_r4">확인</a>
      <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->