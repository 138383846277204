import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, RouterLink } from '@angular/router';
import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { FrontApiService } from '../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from '@angular/common/http';
import { Data } from './Data';
import {ChartDatas} from './ChartDatas';
import { IAccTextRenderEventArgs } from '@syncfusion/ej2-charts';
import { GlobalService } from '../../global.service';
import {DialogSetService} from '../dialog/dialog-set.service';

/** 20200817 cbkim charts */
import { IAccLoadedEventArgs, AccumulationChartComponent, AccumulationTheme, ChartComponent } from '@syncfusion/ej2-angular-charts';
import { EmptyPointMode, AccumulationChart } from '@syncfusion/ej2-charts';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-console-dashboard',
  templateUrl: './console-dashboard.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ConsoleDashboardComponent implements OnInit, AfterViewInit  {
  usingService: any = {
    project: 0,
    server_instance: 0,
    server_image: 0,
    storage_block: 0,
    storage_snapshot: 0,
    network_public_ip: 0 
  }

  noticeList: Array<any> = [];

  ad_panel_status = 0;

  /**
   * 20200814 cbkim
   * charts 
   */
  cpuData:Object[] = [];
  memData:Object[] = [];
  diskData:Object[] = [];
  fipData:Object[] = [];

  public map: Object = 'fill';
  /**
   * 20200816 cbkim
   * server 리스트 가져와서 usage 저장하기
   */
   serverList:any = [];

   /**
    * 20201005 cbkim
    * network traffic
    */
   public primaryXAxis: Object;
   public networkChartData: Object[];
   public title: string;
   public primaryYAxis: Object;
  @ViewChild('chartNetwork')
  public networkChart: ChartComponent;
  chartTitleNetwork = "Network Traffic"

  constructor(
    private osapi: OsapiService,
    private webapi: WebApiService,
    private frontapi: FrontApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    public global: GlobalService,
    private common: ConsoleNavComponent
  ) {}


  /** chart element */
  @ViewChild('chart')
  public chart: AccumulationChartComponent;
  @ViewChild('memchart')
  public memchart: AccumulationChartComponent;
  @ViewChild('diskchart')
  public diskchart: AccumulationChartComponent;
  @ViewChild('ipchart')
  public ipchart: AccumulationChartComponent;

    /** sample chart data */
    public data: Object[] = [
      { x: 'Rice', y: 80 }, { x: 'Wheat', y: null }, { x: 'Oil', y: 70 },
      { x: 'Corn', y: 60 }, { x: 'Gram', y: null },
      { x: 'Milk', y: 70 }, { x: 'Peas', y: 80 },
      { x: 'Fruit', y: 60 }, { x: 'Butter', y: null }
    ];

  loading_flag: number;

  ngOnInit(): void {
    this.loading_flag = 0;
    this.spinner.show();
    this.getServiceResourceList();
    this.getNoticeList();
    this.getQuota();
    // this.getQuotaEditable();
    this.getServerList();

    this.cpuData.push({x:"vCPU",y:null,fill:'#31a3cb'});
    this.cpuData.push({x:"Available vCPU",y:100,fill:'#3d4977'});
    this.memData.push({x:"MEM",y:null,fill:'#31a3cb'});
    this.memData.push({x:"Available MEM",y:1024,fill:'#3d4977'});
    this.diskData.push({x:"DISK",y:null,fill:'#31a3cb'});
    this.diskData.push({x:"Available DISK",y:10240,fill:'#3d4977'});
    this.fipData.push({x:"IPs",y:null,fill:'#31a3cb'});
    this.fipData.push({x:"Available IPs",y:50,fill:'#3d4977'});

    /**
     * 20201005 cbkim
     * area charts
     */
    this.primaryXAxis = {
      title: 'Timestamp',
      minimum: 1900, maximum: 2000, interval: 10,
      edgeLabelPlacement: 'Shift'
    };
    this.primaryYAxis = {
      minimum: 2, maximum: 5, interval: 0.5,
      title: 'Bytes'
    };


    this.frontapi.currentMessage.subscribe((message:any) => {
      if (message.type == "toast") {
        if (message.body.title == "프로젝트 변경") {
          this.getServerList();
        }
      }
    }, (error) => {

    });
  }

  

  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };

  // custom code end
  //Initializing Tooltip
  public tooltip: Object = {
    enable: false, format: '${point.x} : <b>${point.y}</b>  ',
    fill: '#7bb4eb',
    border: {
      width: 2,
      color: 'grey'
    }
  };
  //Initializing DataLabel
  public dataLabel: Object = {
    visible: true, position: 'Inside', font: {
      fontWeight: '600',
      color: '#ffffff'
    }
  };
  public onCpuRender: Function = (args: IAccTextRenderEventArgs) => {
    args.text = args.text +'(core)'
  };
  public onDiskRender: Function = (args: IAccTextRenderEventArgs) => {
    args.text = args.text +'(GB)'
  };
  public onMemRender: Function = (args: IAccTextRenderEventArgs) => {
    args.text = args.text +'(GB)'
  };

  public onIpRender: Function = (args: IAccTextRenderEventArgs) => {
    args.text = args.text +''
  };
  
  public chartTitlevCPU: string = 'vCPU 할당량';
  public chartTitleDISK: string = 'DISK 할당량';
  public chartTitleRAM: string = 'RAM 할당량';
  public chartTitleIp: string = '공인IP 할당량';

  public legend: Object = { visible: true, position:'Bottom' ,alignment:'center',width: '150', height: '70' };

  public emptyPointSettings: Object = {
    fill: '#e6e6e6',
  };

  getServerList(){
    this.osapi.getServerList().subscribe(
      (data)=>{
        this.serverList = data;
        var cpuCount = 0;
        var memCount = 0;
        var diskCount = 0;

        if(this.serverList.length == 0){
          this.cpuData = [];
          this.memData = [];
          this.diskData = [];
          this.cpuData.push({x:"vCPU",y:null,fill:'#31a3cb'});
          this.cpuData.push({x:"Available vCPU",y:100,fill:'#3d4977'});
          this.memData.push({x:"MEM",y:null,fill:'#31a3cb'});
          this.memData.push({x:"Available MEM",y:204,fill:'#3d4977'});
          this.diskData.push({x:"DISK",y:null,fill:'#31a3cb'});
          this.diskData.push({x:"Available DISK",y:10240,fill:'#3d4977'});

          setTimeout(()=>{
            this.chart.refresh();
            this.memchart.refresh();
            this.diskchart.refresh();
            //this.chartRefresh();
          },500);
        }

        
        this.osapi.getResource('floating ip').subscribe(
          (fip:any)=>{
            this.fipData = [];
            this.fipData.push({x:"IPs",y:fip.length,fill:'#31a3cb'});
            this.fipData.push({x:"Available IPs",y:50-fip.length,fill:'#3d4977'});
            setTimeout(()=>{
              this.ipchart.refresh();
            },500);
          }
        )

        for(let i=0;i<this.serverList.length;i++){
          this.osapi.getFlavor(this.serverList[i].flavor_id).subscribe(
            (data:any)=>{
              cpuCount += parseInt(data.vcpus);
              memCount += parseInt(data.ram)/1024;
              diskCount += parseInt(data.disk);
              
              this.cpuData = [];
              this.cpuData.push({x:"vCPU",y:cpuCount,fill:'#31a3cb'});
              this.cpuData.push({x:"Available vCPU",y:400-cpuCount,fill:'#3d4977'});
              //this.chart.center.x = '33%';

              this.memData = [];
              this.memData.push({x:"MEM",y:memCount,fill:'#31a3cb'});
              this.memData.push({x:"Available MEM",y:600-memCount,fill:'#3d4977'});
              //this.memchart.center.x = '66%';
              
              this.diskData = [];
              this.diskData.push({x:"DISK",y:diskCount,fill:'#31a3cb'});
              this.diskData.push({x:"Available DISK",y:10240-diskCount,fill:'#3d4977'});
              //this.chart.center.x = '99%';

              setTimeout(()=>{
                this.chart.refresh();
                this.memchart.refresh();
                this.diskchart.refresh();
                //this.chartRefresh();
              },500);
            }
          )
          //this.chart.refresh();
        }
        // setTimeout(() => {
        //   this.chart.refresh();
        //   this.memchart.refresh();
        //   this.diskchart.refresh();
        // }, 5000);
      }
    )
  }

  

  getQuotaEditable() {
    this.osapi.getNovaQuotaEditable().subscribe(
      (data) => {      }
    )
  }
  getQuota() {
    this.osapi.getNovaQuota().subscribe(
      (data) => {
      }
    )


    this.osapi.getCinderQuota().subscribe(
      (data) => {
      }
    )
  }

  ngAfterViewInit() {
    $('.bannerSlider').bxSlider({
      auto: true,
      pause: 6000,
      speed: 1600,
      mode: 'fade',
      onSlideBefore: function ($slideElement, oldIndex, currentIndex) {
      }
    });

    // this.chart.center.y = '33%';
    // this.memchart.center.y = '33%';
    // this.diskchart.center.y = '33%';
  }

  chartRefresh(){
    this.getServerList();
    // this.chart.refresh();
    // this.memchart.refresh();
    // this.diskchart.refresh();
  }

  getServiceResourceList() {
    this.webapi.getServiceResourceList().subscribe((data) => {      data.result.forEach(resource => {
        if (resource.resourceType == 'project') {
          this.usingService.project++;
        }
        if (resource.resourceType == 'instance') {
          this.usingService.server_instance++;
        }
        if (resource.resourceType == 'image') {
          this.usingService.server_image++;
        }
        if (resource.resourceType == 'volume') {
          this.usingService.storage_block++;
        }
        if (resource.resourceType == 'snapshot') {
          this.usingService.storage_snapshot++;
        }
        if (resource.resourceType == 'floating ip') {
          this.usingService.network_public_ip++;
        }
        //나스
        //네트워크

      });
    },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  getNoticeList() {
    let searchQuerys = `&o=created_date.DESC`;

    this.webapi.getNoticeList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.noticeList = data['result'];
        this.spinner.hide();
        this.common.getThemeFont();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  linkNotice(key) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        noticeId: key,
      },
      skipLocationChange: false,
    };
    this.router.navigate(['/web/noticeView'], navigationExtras);
    // location.replace('/web/noticeView?id='+key+'&page=&searchTitle=&searchCate=');
  }

  linkServerCreate(e) {
    this.router.navigate(['/console/server/create']);
    // location.replace('/console/server/create');
  }
}
