import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrontApiService } from '../../frontapi.service';
import { BasicNetworkData } from "./BasicNetworkData";
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function fn_modalOpen(msg): any;
declare function valiAllChk(params: any);

@Component({
  selector: 'app-console-project-list',
  templateUrl: './console-project-list.component.html',
})
/**
 * 20200826 cbkim 프로젝트 목록 컴포넌트
 */
export class ConsoleProjectListComponent implements OnInit {
  /** 검색 텍스트 */
  filterText: string;

  /** START Project 관리 */
  projectList: any = [];
  companyId: any = {};
  resource: any = [];
  // userInfo: { projectId: string; userId: string; companyId: number };
  userInfo: any;
  // Test
  projectInfo: any;
  pfip: string;
  pfid: string;
  emptyProject: boolean = false;

  // currentPorjectName: string;

  selected_project: any = {};

  isActived: boolean = false;
  isChecked: boolean = false;

  /** END Project 관리 */

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    public global: GlobalService,
    private router: Router,
    private dialogs: DialogSetService,
    private common: ConsoleNavComponent
  ) { }

  ngOnInit(): void {
    this.getProjectList();
    this.getNetworkResourceList();
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e) {
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    this.isActived=true;
    // const result = valiAllChk($_CHKEDLIST);
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }

  onClickList(item) {
    this.isActived = true;
    this.selected_project = item;
  }

  /** START Project 관리 */
  onProjectChange(project_id) {
    try {
      this.spinner.show();
      this.osapi.switchProject(project_id).subscribe((data) => {
        this.spinner.hide();
        setTimeout(() => {
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'info',
              title: '프로젝트 변경',
              message: '프로젝트가 변경되었습니다.'
            }
          });
        }, 1000);

        //location.reload();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
      this.emptyProject = true;
      this.spinner.hide();
    }
  }

  openCreateProjectModal() {
    this.dialogs.openProjectCreateDialog({ });
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){

        this.frontapi.changeMessage({
          type: 'console-nav',
          body:{
            type:'change project'
          }
        })
      }

      //this.getProjectList();
    })
  }

  /**
   * 20200827 cbkim
   * 프로젝트 자원 현황
   */
  openDeleteProjectModal() {
    this.dialogs.openProjectDeleteDialog({selected_project:this.selected_project,resource:this.resource,});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        /** 
           * 20200831 cbkim 프로젝트 변경
           * navigation project list 리프레시 요청 
          */
         this.frontapi.changeMessage({
          type: 'console-nav',
          body:{
            type:'change project'
          }
        })
        /**
         * check point
         * project list가 없다며면?
         * 
         * 20200831 cbkim
         * 기본 프로젝트로 스위칭 하도록 변경
         */
        this.emptyProject=true;
        this.getProjectList();
      }else if(result == "fail"){
        this.getProjectList();
        this.onProjectChange(this.projectList[0].id);
      }
    });
  }

  openUpdateProjectModal() {
    this.dialogs.openProjectUpdateDialog({selected_project:this.selected_project});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
         this.frontapi.changeMessage({
          type: 'console-nav',
          body:{
            type:'change project'
          }
        })
        this.getProjectList();
      }else if(result == "fail"){
        this.getProjectList();
        this.onProjectChange(this.projectList[0].id);
      }
    });

  }

  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        }
        this.projectList = data.items;

        /**
         * 202008313 cbkim
         * 기본 프로젝트가 삭제 불가능 하므로 길이가 0일 수 없으나
         * 테스트 간 기본 프로젝트를 삭제하는 경우가 있음
         * 예외처리용
         */
        if(this.projectList.length == 0){
          return;
        }

        if (this.emptyProject) {
          this.onProjectChange(this.projectList[0].id);
          //this.userInfo.projectId = this.projectList[0].id;
          this.emptyProject = false;
        }

        /**
         * 20200831 cbkim
         * 기본 프로젝트 삭제 금지
         * 
         * 20200917 cbkim
         * 하나 이상의 다른 프로젝트가 있을 경우 삭제 가능
         */

        for (let project of this.projectList) {
          if(typeof project.resource_data == 'object'){
            if (project.resource_data.projectName === "DEFAULT PROJECT") {
              project.isDefault = true;
            } else {
              project.isDefault = false;
            }
          }
        }
        this.common.getThemeFont();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      console.error(err);
    }
  }

  getNetworkResourceList() {
    this.spinner.show();

    this.osapi.getResourceAll().subscribe(
      (data: any[]) => {
        this.spinner.hide();
        this.resource = data;
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }



  /** END Project 관리 */

}

