<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>멤버 관리</h2>
    <div class="lineMap">
      프로젝트 / <span>멤버 관리</span>
    </div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
				<div class="lyBox">
          <div class="title_wrap">
            <div class="contArea">
              <div class="tit">
                  <!-- 서브 계정 -->
								<app-console-projects></app-console-projects>
              </div>
              <div class="cont">
                <app-console-project-status></app-console-project-status>
              </div>
              <div class="contSub">
                  <a class="btn mid blue mg_r4" (click)="openSubuserCreateModal()"><i class="ico ico_register_white"></i>멤버 추가</a>
                  <!-- <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openSubuserDeleteModal()"><i class="ico ico_cancel_white"></i>계정삭제</button> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="lyBox">
          <div class="title_wrap" style="height:92px;"> -->
            <!-- <div class="titArea">
              <div class="tit">서브 계정</div>
            </div> -->
            <!-- <div class="contArea">
              <div class="tit">서브 계정</div>
              <div class="cont">
                  <a class="btn mid blue mg_r4" (click)="openSubuserCreateModal()"><i class="ico ico_register_white"></i>신규생성</a>
                  <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openSubuserDeleteModal()"><i class="ico ico_cancel_white"></i>계정삭제</button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- //lyBox -->
        <!-- lyBox -->
        <div class="lyBox consoleRightBody">
          <div class="titArea">
            <h3>멤버 관리</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <input type="search" class="form-control" name="" id="search" value=""    [(ngModel)]="filterText" placeholder="검색어를 입력해주세요" spellcheck="false" autocomplete="off"/>
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                </div>
                <!-- 접기 버튼 -->
                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <table class="tbl_col_tp1 dropmenu pdTp2">
                <colgroup>
                  <col style="width: 5%;" />
                  <col style="width: 7%;" />
                  <col style="width: 23%;" />
                  <col style="width: 20%;" />
                  <col style="width: 23%;" />
                  <col style="width: 10%;" />
                  <col style="width: 12%;" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                    </th>
                    <th scope="col">번호</th>
                    <th scope="col">ID</th>
                    <th scope="col">이름</th>
                    <th scope="col">담당 프로젝트</th>
                    <th scope="col">사용자 유형</th>
                    <th scope="col">생성일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subUser of subUsers  | tableFilter: filterText; index as i" (contextmenu)="onClickList(subUser)">
                    <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)"/></td>
                    <td>{{ i + 1 }}</td>
                    <td>
                      <a>{{
                        subUser.userId
                      }}</a>
                      <input type="hidden" value="user01" />
                    </td>
                    <td>{{subUser.name}}</td>
                    <td><p *ngFor="let project of subUser.projects">
                      <ng-template [ngIf]="project.resource_data">
                          {{project.resource_data.value}}
                    </ng-template></p></td>
                    <td>
                      <span *ngIf="subUser.userId==subUser.Company.adminId && subUser.type==0">대표관리자</span>
                      <span *ngIf="subUser.userId!=subUser.Company.adminId && subUser.type==0">중간관리자</span>
                      <span *ngIf="subUser.type==1">운영담당자</span>
                      <span *ngIf="subUser.type==2">회계담당자</span>
                    </td>
                    <td>{{ subUser.createdDate.split("T")[0] }}</td>
                  </tr>
                </tbody>
              </table>
              
              <div class="btn_wrap tp1"></div>
            </div>
          </div>
        </div>
        <!-- //lyBox -->

        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
  <div id="myDropdown" class="dropdown-content">
    <ng-template [ngIf]="selected_subUser && selected_subUser.type==0 && selected_subUser.userId==selected_subUser.Company.adminId" [ngIfElse]="noAdmin">
        <a disabled>멤버 수정</a>
        <a disabled>멤버 삭제</a>
    </ng-template>
    <ng-template #noAdmin>
        <a (click)="openSubuserUpdateModal()">멤버 수정</a>
        <a (click)="openSubuserDeleteModal()">멤버 삭제</a>
        <a *ngIf="isMasterAdmin" (click)="openMasterDelegateModal()">최고관리자 위임</a>
    </ng-template>
  </div>
</div>




<app-console-common-modal (modalMsg)="deleteSubUser()"></app-console-common-modal>