export class HealthMonitorData {
    public type: string;
    public delay: number;
    public max_retries: number;
    public timeout: number;
    public url_path:string;
    public http_method:string;
    public expected_codes:string;
    public admin_state_up:boolean;
    public max_retries_down:number;
    //public name:string;

    constructor() {
        this.type = "HTTP";
        this.delay = 3;
        this.max_retries = 5;
        this.timeout = 1;
        this.url_path="/";
        this.http_method = "GET";
        this.expected_codes="200";
        this.admin_state_up = true;
        this.max_retries_down = 3;
        //this.name="default_monitor"
    }
}