<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>프로젝트 목록</h2>
    <div class="lineMap">
      프로젝트 목록 / <span>프로젝트 관리</span>
    </div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
				<div class="lyBox">
          <div class="title_wrap">
            <div class="contArea">
              <div class="tit">
                <app-console-projects></app-console-projects>
              </div>
              <div class="cont">
                <app-console-project-status></app-console-project-status>
              </div>
              <div class="contSub">
                  <a class="btn mid blue mg_r4" (click)="openCreateProjectModal()"><i class="ico ico_register_white"></i>프로젝트 생성</a>
                  <!-- <button class="btn mid red mg_r4" [disabled]="!isActived" (click)="openDeleteProjectModal()"><i class="ico ico_cancel_white"></i>프로젝트 삭제</button> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="lyBox">
          <div class="title_wrap">
            <div class="contArea">
              <div class="tit">
                  <app-console-projects></app-console-projects>
              </div>
              <div class="cont">
                  <a class="btn mid blue mg_r4" (click)="openCreateProjectModal()"><i class="ico ico_register_white"></i>프로젝트 생성</a> -->
                  <!-- <button class="btn mid red mg_r4" [disabled]="!isActived"
                  (click)="openDeleteProjectModal()"><i class="ico ico_cancel_white"></i>프로젝트 삭제</button> -->
              <!-- </div>
            </div>
          </div>
        </div> -->
        <!-- //lyBox -->
        <!-- lyBox -->
        <div class="lyBox consoleRightBody">
          <div class="titArea">
            <h3>프로젝트 목록</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <input type="search" class="form-control" name="" id="search" value="" [(ngModel)]="filterText" placeholder="검색어를 입력해주세요" spellcheck="false" autocomplete="off"/>
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                </div>
                <!-- 접기 버튼 -->
                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <table class="tbl_col_tp1 dropmenu pdTp2">
                <colgroup>
                  <col style="width: 5%;" />
                  <col style="width: 25%;" />
                  <col style="width: 30%;" />
                  <col style="width: 20%;" />
                  <col style="width: 20%;" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                    </th>
                    <th scope="col">프로젝트명</th>
                    <th scope="col">설명</th>
                    <th scope="col">담당자</th>
                    <th scope="col">생성일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let project of projectList  | tableFilter: filterText; index as i" (contextmenu)="onClickList(project)" >
                    <td *ngIf="project.resource_data"><input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)" /></td>
                    <td *ngIf="project.resource_data">{{project.resource_data.value}}</td>
                    <td *ngIf="project.resource_data">{{project.resource_data.description}}</td>
                    <td *ngIf="project.resource_data">{{project.resource_data.projectManager}}</td>
                    <td *ngIf="project.resource_data">{{project.resource_data.createdDate.substring(0, 10)}}</td>
                  </tr>
                </tbody>
              </table>
              
              <div class="btn_wrap tp1"></div>
            </div>
          </div>
        </div>
        <!-- //lyBox -->

        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
  <div id="myDropdown" class="dropdown-content">
    <!-- <a (click)="openCreateProjectModal()">프로젝트 생성</a> -->
    <a (click)="openUpdateProjectModal()">프로젝트 수정</a>
    <ng-template [ngIf]="!selected_project.isDefault">
      <a (click)="openDeleteProjectModal()">프로젝트 삭제</a>
    </ng-template>
    <ng-template [ngIf]="selected_project.isDefault">
      <a disabled>프로젝트 삭제</a>
    </ng-template>
  </div>
</div>
<!--// dropdown menu -->
