import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-detach',
  templateUrl: './dialog-storage-detach.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageDetachComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageDetachComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_volume;

  ngOnInit(): void {

    this.selected_volume = this.dialogData.selected_volume;
  }

  
  detachVolume(server_id,volume_id){  
    this.spinner.show();  
    this.osapi.detachVolume(server_id,volume_id).subscribe(
      (data:{}) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
        // if(this.router.url=='/console/storage'){
        //   this.router.navigateByUrl('/console', {
        //     skipLocationChange: true,
        //   }).then(
        //     () => {
        //       this.router.navigate(['/console/storage']);
        //     }
        //   );
        // }else{
        //   this.router.navigate(['/console/storage']);
        // }
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
}
