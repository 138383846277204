import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { OsapiService } from '../../osapi.service';
import { Item } from '@syncfusion/ej2-navigations';

@Component({
  selector: 'app-console-network-status',
  templateUrl: './console-network-status.component.html',
})
export class ConsoleNetworkStatusComponent implements OnInit {

  @Input() message: string;
  @Output() modalMsg = new EventEmitter();

  resource:any =[];
  networkExtList:any=[];
  networkList:any=[];

  networkActive:number;
  netwrokExtActive:number;

  subnetList:any=[];
  subnetActive:number=0;

  fipList:any=[];
  pfList:any=[];

  sgList:any=[];

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,
    private osapi:OsapiService
  ) {
    
  }

  ngOnInit(): void {
    this.getExtNetworkList();
    this.getSubnetList();
    this.getFloatingIpList();
    this.getSecurityGroupList();
  }

  getSecurityGroupList(){
    this.osapi.getSecurityGroupList().subscribe(
      (data:any)=>{
        this.sgList = data.items;
      }
    )
  }

  getFloatingIpList(){
    this.osapi.getFloatingipList().subscribe(
      (data:any)=>{
        data.items.forEach(item => {
          if(item.is_port_forwarding){
            this.pfList.push(item);
          }else{
            this.fipList.push(item);
          }
        });
      }
    )
  }

  getSubnetList(){
    this.osapi.getSubnetList().subscribe(
      (data)=>{
        this.subnetList = data;
        for(let subnet of this.subnetList){
          if(subnet.status == "ACTIVE"){
            this.subnetActive++;
          }
        }
      }
    )
  }

  getExtNetworkList() {
    this.osapi.getResourceAll().subscribe((data: any[]) => {
      this.resource = data;

      this.osapi.getNetworkList().subscribe((data:any) => {
        this.networkList = [];
        this.spinner.hide();

        // 외부 네트워크만 목록에 담기
        for(let network of data){
          if(network.network_type == "ext"){
            this.networkExtList.push(network);
          }else{
            this.networkList.push(network);
          }
        }

        // 초기화
        for (let item of this.networkExtList) {
          item.external_gateway_info = {}
          item.external_gateway_info.enable_snat = true;
        }
        // cidr 찾기
        for (let r of this.resource) {
          for (let n of this.networkExtList) {
            if (r.objectId == n.id) {
              n.cidr = r.value;
              // this.usedCidrList.push(r.value);
            }
          }
        }

        // 라우터 찾기 admin_state 값 UP/DOWN 외부 연결 허용/차단
        // 라우터 ID 가져오기
        for (let r of this.resource) {
          for (let n of this.networkExtList) {
            if (r.resourceType === 'router') {
              /** 20200904 cbkim 프로젝트 아이디 확인 */
              if (n.project_id == r.projectId) {
                if (r.value === n.cidr) {
                  n.routerId = r.objectId;
                  var routerId = r.objectId;
                  this.osapi.getRouters(routerId).subscribe((data: any) => {
                    n.router_state = data.admin_state_up;
                    n.router_id = data.id;
                    n.external_gateway_info = data.external_gateway_info;
                  });
                }
              }
            }
          }
        }
      });
    });
  }

}
