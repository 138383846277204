<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>청구관리</h2>
		<div class="lineMap">
			청구관리 /  <span>청구금액 통계</span>
		</div>
	</div>
	<!-- //content_header -->
	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
				<div class="lyBox">
					<div class="titArea">
						<h3>청구금액 통계</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<select name="" id="search_sel" required>
									<option value="">전체</option>
									<option value="1">자원별</option>
									<option value="2">상품별</option>
								</select>
							</div>
							<div class="input-group fl">
								<input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
								<button type="submit" class="btn mid dBlue"><i class="ico ico_search_white"></i>검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
							
					</div>
					<div class="contArea" style="display:block"> 

						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
								<colgroup>
									<col style="width:5%">
									<col style="width:10%">
									<col style="width:50%">
									<col style="width:20%">
									<col style="width:20%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">ID</th>
										<th scope="col">청구내용</th>
										<th scope="col">청구상태</th>
										<th scope="col">청구일</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td style="text-align: left;">jaang0219</td>
										<td style="text-align: left;">청구내용청구내용청구내용청구용청구내용청구내용청구내용청구내용청구내용</td>
										<td >완료</td>
										<td >2020-06-16</td>
									</tr>
									<tr>
										<td>2</td>
										<td style="text-align: left;">jaa219</td>
										<td style="text-align: left;">청구내용청구내용청용청구내용청구내용청구용청구내용청구내용청구구내구내용청구내용청구내용구내용</td>
										<td >완료</td>
										<td >2020-06-16</td>
									</tr>
									<tr>
										<td>3</td>
										<td style="text-align: left;">jaa2aaaa19</td>
										<td style="text-align: left;">청구내용내구내용용청구내용청구내용청구청구내용청구내용구내용</td>
										<td >완료</td>
										<td >2020-06-16</td>
									</tr>
									<tr>
										<td>4</td>
										<td style="text-align: left;">jaa212319</td>
										<td style="text-align: left;">청구내용용용청구내용청구내용청구청구내구내용청구내용청구내용구내용</td>
										<td >완료</td>
										<td >2020-06-16</td>
									</tr>
								</tbody>
							</table>
	
							<div class="board_pager_wrap tp1">
								<div class="board_pager">
									<strong class="boardPage">1</strong>
									<a href="javascript:void(0);" class="boardPage">2</a>
									<a href="javascript:void(0);" class="boardPage">3</a>
									<a href="javascript:void(0);" class="boardPage">4</a>
									<a href="javascript:void(0);" class="boardPage">5</a>
								</div>
							</div>
						</div>

				

					</div>
				</div>
				<!-- //lyBox -->

				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->