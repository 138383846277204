
<!-- Modal : 공인IP 할당 모달 -->
<div id="modal_publicip" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            공인IP 할당
            <p class="sub-txt">공인IP 할당 설명입니다</p>
        </h2>
    </div>
    <!-- //modal_header -->


    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">공인IP</th>
                        <td>
                            <select (change)="changeFip($event.target.value)" required>
                                <option value="false">-- 선택하세요 --</option>
                                <option *ngFor="let fip of floatingIpList" [value]="fip.id">{{fip.ip}}</option>
                            </select>
                        </td>
                    </tr>
                    <!-- <tr>
                            <th scope="row">Pool</th>
                            <td>
                                <select name="" id="" required>
                                    <option value="">106.10.69.x</option>
                                </select>
                            </td>
                        </tr> -->
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="patchFloatingIp()" class="btn big ciblue mg_r4">할당</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->