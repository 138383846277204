<!-- Container -->
<div id="Container" class="login">
    <div id="frm_contents">
        <div class="findpw">
            <!-- frm_content -->
            <div class="frm_content">
                <!-- logo -->
                <div class="logoArea">
                    <div class="logo">
                        <img src="../assets/img/member/logo_member.png" alt="">
                    </div>
                    <h1>비밀번호 변경</h1>
                </div>
                <!-- //logo -->                
                <!-- form -->
                <form [formGroup]="changePassForm" (ngSubmit)="onSubmit()"> 
                    <!-- loginForm -->
                    <fieldset class="loginForm">
                        <legend>비밀번호 변경</legend>
                        <ul class="row_group">
                            <li class="row">
                                <div class="tit">비밀번호</div>
                                <div class="cont">
                                    <input type="password" id="password" name="" spellcheck="false" autocomplete="off" placeholder="비밀번호를 입력해주세요." formControlName="password" required maxlength="20">
                                </div>
                                <div *ngIf="submitted && passForm.password.errors" class="invalid-feedback">
                                    <div *ngIf="passForm.password.errors.required">비밀번호를 입력해주세요.</div>
                                    <div *ngIf="passForm.password.errors.pattern || passForm.password.errors.minlength">영문 대문자, 숫자, 특수문자를 1개씩 이상 포함하여 8자리 이상 입력해주세요. (최대 20자)</div>
                                </div>                               
                            </li>
                            <li class="row">
                                <div class="tit">비밀번호 확인</div>
                                <div class="cont">
                                    <input type="password" id="confirmPassword" name="" spellcheck="false" autocomplete="off" placeholder="비밀번호를 입력해주세요." formControlName="confirmPassword" required>
                                </div>									
                                <div *ngIf="submitted && passForm.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="passForm.confirmPassword.errors.required">비밀번호를 입력해주세요.</div>
                                </div>
                            </li>
                        </ul>                                                                                
                        <button type="submit" id="idCheck" class="btn_login">비밀번호 변경</button>                     
                    </fieldset>
                    <!-- //loginForm -->
                </form>
                <!-- //form -->
            </div>				
            <!-- //frm_content -->
        </div>
    </div>
</div>
<!-- //ROUTER -->