import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-loadbalancer-disassociate-ip',
  templateUrl: './dialog-loadbalancer-disassociate-ip.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogLoadbalancerDisassociateIpComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogLoadbalancerDisassociateIpComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_loadbalancer:any={};


  ngOnInit(): void {

    this.selected_loadbalancer= this.dialogData.selected_loadbalancer;
  }


  disassociateFloatingIp(id){
    this.spinner.show();
    this.osapi.disassociateFloatingIp(id).subscribe(
      (data)=>{

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'success',
            title: '공인IP 해제',
            message: '공인IP가 해제되었습니다.'
          }
        })
        this.closeDialogMsg("success");
      },
      (error)=>{
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '로드밸런서 공인IP 해제에 실패하였습니다.'
          }
        })
      }
    )
  }

}
