import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { UploaderModule  } from '@syncfusion/ej2-angular-inputs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import { NgxSpinnerModule } from "ngx-spinner";

import { AuthGuard} from '../auth.guards';

/** i18 */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function createTranslateLoader(http: HttpClient) { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); }

/** 
 * 20200817 cbkim 
 * charts
 */
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';
import { ClipboardModule } from 'ngx-clipboard';



import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { AreaSeriesService, RangeAreaSeriesService, StepAreaSeriesService, StackingAreaSeriesService, 
  DateTimeService, CategoryService, MultiColoredAreaSeriesService } from '@syncfusion/ej2-angular-charts';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { MobileConsoleDashboardComponent } from './mobile-console-dashboard/mobile-console-dashboard.component';
import { MobileConsoleServerListComponent } from './mobile-console-server-list/mobile-console-server-list.component';
import { DialogConsoleServerListComponent } from './mobile-console-server-list/mobile-console-server-list.component';
import { MobileConsoleImageListComponent } from './mobile-console-image-list/mobile-console-image-list.component';
import { MobileConsoleKeypairListComponent } from './mobile-console-keypair-list/mobile-console-keypair-list.component';
import { MobileConsoleNetworkListComponent } from './mobile-console-network-list/mobile-console-network-list.component';
import { MobileConsoleSubnetListComponent } from './mobile-console-subnet-list/mobile-console-subnet-list.component';
import { MobileConsoleLoadbalancerListComponent } from './mobile-console-loadbalancer-list/mobile-console-loadbalancer-list.component';
import { MobileConsoleSecurityGroupListComponent } from './mobile-console-security-group-list/mobile-console-security-group-list.component';
import { MobileConsoleIpListComponent } from './mobile-console-ip-list/mobile-console-ip-list.component';
import { MobileConsolePrivateNetworkListComponent } from './mobile-console-private-network-list/mobile-console-private-network-list.component';
import { MobileConsoleStorageListComponent } from './mobile-console-storage-list/mobile-console-storage-list.component';
import { MobileConsoleSnapShotListComponent } from './mobile-console-snapshot-list/mobile-console-snapshot-list.component';
import { MobileConsoleNasListComponent } from './mobile-console-nas-list/mobile-console-nas-list.component';
import { MobileConsoleProjectListComponent } from './mobile-console-project-list/mobile-console-project-list.component';
import { MobileConsoleSubuserListComponent } from './mobile-console-subuser-list/mobile-console-subuser-list.component';
import { MobileConsoleRuleManageListComponent} from './mobile-console-rule-manage-list/mobile-console-rule-manage-list.component';
import { DialogConsoleRuleManagerCreateComponent} from './mobile-console-rule-manage-list/mobile-console-rule-manage-list.component';
import { DialogConsoleRuleManagerUpdateComponent} from './mobile-console-rule-manage-list/mobile-console-rule-manage-list.component';
import { DialogConsoleRuleManagerDeleteComponent} from './mobile-console-rule-manage-list/mobile-console-rule-manage-list.component';
import {MobileRoutingModule} from './mobile-routing.module';

@NgModule({
  declarations: [
    MobileNavComponent,
    MobileConsoleDashboardComponent,
    MobileConsoleServerListComponent,
    DialogConsoleServerListComponent,
    MobileConsoleImageListComponent,
    MobileConsoleKeypairListComponent,
    MobileConsoleNetworkListComponent,
    MobileConsoleSubnetListComponent,
    MobileConsoleLoadbalancerListComponent,
    MobileConsoleSecurityGroupListComponent,
    MobileConsoleIpListComponent,
    MobileConsolePrivateNetworkListComponent,
    MobileConsoleStorageListComponent,
    MobileConsoleSnapShotListComponent,
    MobileConsoleNasListComponent,
    MobileConsoleProjectListComponent,
    MobileConsoleSubuserListComponent,
    MobileConsoleRuleManageListComponent,
    DialogConsoleRuleManagerCreateComponent,
    DialogConsoleRuleManagerUpdateComponent,
    DialogConsoleRuleManagerDeleteComponent
  ],
  
  imports: [    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MobileRoutingModule,
    BrowserAnimationsModule,
    ToastModule,UploaderModule,
    /** 20200813 cbkim 다국어지원 */
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient] } }),
    /** 20200817 cbkim charts */
    ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule,
    /** 20200819 cbkim clipboard */
    ClipboardModule,
    MatExpansionModule,
    MatTabsModule,
    MatSidenavModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    NgxSpinnerModule,
    ChartModule
  ],
  exports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers:[
    AuthGuard,
    AreaSeriesService, RangeAreaSeriesService, StepAreaSeriesService, StackingAreaSeriesService, 
    DateTimeService, CategoryService, MultiColoredAreaSeriesService
  ]
})

export class MobileModule { }