<!-- Container -->
<div id="Container" class="inquiry">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>1:1 문의 하기</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>1:1 문의</h3>
                    <div class="subTit">
                        <!-- 서비스 안내와 공지, 신규 서비스에 대한 소식을 전해드립니다. -->
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="boardBox">
                        <div class="noticeDetail">
                            <div class="titArea">
                                <p class="tit">
                                    <span>{{inquiryDetail.title}}</span>
                                    <a *ngIf="!inquiryDetail.completed;" class="btn tiny dGray radius">처리중</a>
                                    <a *ngIf="inquiryDetail.completed;" class="btn tiny red radius">처리완료</a>
                                </p>
                                <p class="date">{{inquiryDetail.createdDate}}</p>
                            </div>
                            <div class="contArea">
                                <p class="cont">
                                    {{inquiryDetail.contents}}
                                </p>
                            </div>
                            <div class="contArea" *ngIf="inquiryDetail.completed;">
                                <p class="cont">
                                    답변 : {{inquiryComment.contents}}
                                </p>
                            </div>
                        </div>

                        <div class="btn_wrap tp1">
                            <a routerLink="/web/mypage/inquriyList" class="btn mid black">목록</a>
                        </div>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>