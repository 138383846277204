import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { postcode } from 'src/assets/js/util.js';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { ToastComponent, ToastCloseArgs } from '@syncfusion/ej2-angular-notifications';
import {OsapiService} from '../osapi.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TestComponent implements OnInit {
  ngOnInit(): void {
  }

  @ViewChild('toasttype')
  public toastObj: ToastComponent;
  @ViewChild('infoToast') btninfo: ElementRef;
  @ViewChild('warningToast') btnwarn: ElementRef;
  @ViewChild('errorToast') btnerr: ElementRef;
  @ViewChild('successToast') btnsuccess: ElementRef;
  public position: object = { X: 'Right' };
  public toasts: { [key: string]: Object }[] = [
    { title: 'Success!', cssClass: 'e-toast-success', timeOut: 80000 },
  ];

  public counter = 0;
  constructor(
    private osapi:OsapiService
  ) {

  }
  public onCreate(): void {
    // setTimeout(function () {
    //   const toast = this.toasts[0];
    //   toast.content = `${this.counter} success`;

    //   this.toastObj.show(toast);
    // }.bind(this), 200);
  }
  public successClick(e: Object): void {
    const toast = this.toasts[0];
    this.counter++;
    toast.content = `${this.counter} success`
    this.toastObj.show(toast);
  }
  public hideClick(e: Object): void {
    this.toastObj.hide('All');
  }
  public onclose(e: ToastCloseArgs): void {
    if (e.toastContainer.childElementCount === 0) {
      let hideBtn: HTMLElement = document.getElementById('hideTosat');
      hideBtn.style.display = 'none';
    }
  }
  public onBeforeOpen(): void {
    let hideBtn: HTMLElement = document.getElementById('hideTosat');
    hideBtn.style.display = 'inline-block';
  }

  fipTest(){
    this.osapi
      .associateFloatingIp('2f8606b8-fcfe-4b1b-809e-29b964d090d3', 'b1d3cae5-d629-43a8-87a9-ee786618aa8f_172.22.0.217')
      .subscribe((data: {}) => {

      }, 
      (error) => {

      });
  }

  setRawDataFlavor(){
    this.osapi.setRawDataFlavor().subscribe(
      (data)=>{
        console.log(data);
      }
    )
  }
}
