<!-- Container -->
<div id="Container" class="inquiry">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>1:1 문의 하기</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <!--h3>1:1 문의</h3-->
                    <div class="subTit">
                        맞춤 전문가의 빠른 답변을 볼 수 있습니다.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="boardBox tp3">
                        <table class="tbl_row_tp1">
                            <colgroup>
                                <col style="width:20%">
                                <col style="width:80%">
                            </colgroup>

                            <tbody>
                                <tr>
                                    <th scope="row">문의 구분</th>
                                    <td class="inpSelect">
                                        <select name="category" id="category" (change)="onCategoryChange($event.target.value)">
                                            <option value="요금">요금</option>
                                            <option value="고객지원">고객지원</option>
                                            <option value="서비스">서비스</option>                                            
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">제목</th>
                                    <td>
                                        <input type="text" name="title" id="title" maxlength="60" (change)="onTitleChange($event.target.value)">
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">내용</th>
                                    <td>
                                        <textarea name="contents" id="contents" cols="30" rows="10" maxlength="250" (change)="onContentsChange($event.target.value)"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="btn_wrap tp1">
                        <a (click)="fn_insertInquiry()" class="btn big black">등록하기</a>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>