<!-- Modal : 스토리지 생성 모달 -->
<div id="storage_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        스토리지 생성
        <p class="sub-txt">블록 스토리지를 생성합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">스토리지 이름</th>
              <td>
                <input type="text" name="" id="" [(ngModel)]="this.newStorageData.name" maxlength="60" placeholder="60자 이내로 작성해주세요."
                  (change)="onNewStorageNameChange($event.target.value)" required />
              </td>
            </tr>
            <tr>
              <th scope="row">설명</th>
              <td>
                <textarea name="" id="" [(ngModel)]="this.newStorageData.description" rows="5" placeholder="255자 이내로 작성해주세요."
                  (change)="onNewStorageDescChange($event.target.value)" maxlength="255"></textarea>
              </td>
            </tr>
            <tr>
              <th scope="row">스토리지 타입</th>
              <td>
                <select name="" [(ngModel)]="this.newStorageData.source_type" (change)="onNewStorageSourceChange($event.target.value)" required>
                  <option value="empty">빈 볼륨</option>
                  <option value="image">인스턴스 이미지</option>
                  <option value="snapshot">스토리지 스냅샷</option>
                  <option value="storage">스토리지 복제</option>
                </select>
              </td>
            </tr>
            <!-- 이미지 일 경우 -->
            <tr *ngIf="newStorageData.source_type == 'image'">
              <th scope="row">이미지 선택</th>
              <td>
                <select name="" (change)="onNewStorageImageChange($event.target.value)" required>
                  <option value="">선택</option>
                  <option *ngFor="let image of imageLists" [value]="image.image_id">{{ image.name }}</option>
                </select>
              </td>
            </tr>
            <!-- 스냅샷 일 경우 -->
            <tr *ngIf="newStorageData.source_type == 'snapshot'">
              <th scope="row">스냅샷 선택</th>
              <td>
                <select name="" [(ngModel)]="this.newStorageData.snapshot_id" (change)="onNewStorageSnapshotChange($event.target.value)" required>
                  <option [value]="null">선택</option>
                  <option *ngFor="let snapshot of snapshotLists" [value]="snapshot.id">{{ snapshot.name }}
                    ({{ snapshot.size }}GB)</option>
                </select>
              </td>
            </tr>
            <!-- 스토리지 일 경우 -->
            <tr *ngIf="newStorageData.source_type == 'storage'">
              <th scope="row">기존 볼륨 사용</th>
              <td>
                <select name="" (change)="onNewStorageVolumeChange($event.target.value)" required>
                  <option value="">선택</option>
                  <option *ngFor="let volume of volumeList" [value]="volume.id">{{ volume.name }}</option>
                </select>
              </td>
            </tr>

            <tr>
              <th scope="row">유형</th>
              <td>
                <select name="" required>
                  <option value="__DEFAULT__">Default</option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">크기(GiB)</th>
              <td>
                <input type="number" name="" id="" [(ngModel)]="newStorageData.size" placeholder="내용을 입력해주세요"
                  (change)="onNewStorageSizeChange($event.target.value)" required />
              </td>
            </tr>

            
            <tr *ngIf="serverList.length>0">
                <th scope="row">인스턴스에 바로 연결</th>
                <td>
                  <select name="" [(ngModel)]="attachServerId">
                    <option value="">연결없이 생성</option>
                    <option *ngFor="let server of serverList" [value]="server.server_id">{{ server.server_name }}</option>
                  </select>
                </td>
              </tr>
              
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="createVolume()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->