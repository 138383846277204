
<!-- Modal : 볼륨 연결해제 모달 -->
<div id="volume_cancel" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>스토리지 연결해제</h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width:30%">
                        <col style="width:70%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">연결된 인스턴스</th>
                            <td *ngIf="selected_volume != null && selected_volume.attachments!=null">
                                <span
                                    *ngIf="selected_volume.attachments.length != 0;else selectedNull">{{selected_volume.attachments[0].server_name}}
                                </span>
                                <ng-template #selectedNull>
                                    <span>없음</span>
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">마운트 정보</th>
                            <td *ngIf="selected_volume != null && selected_volume.attachments!=null">
                                <span
                                    *ngIf="selected_volume.attachments.length != 0;else selectedNull">{{selected_volume.attachments[0].device}}
                                </span>
                                <ng-template #selectedNull>
                                    <span>없음</span>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp1">
                <a class="btn big ciblue mg_r4" (click)="detachVolume(selected_volume.attachments[0].server_id,selected_volume.id)">연결해제</a>
                <a class="btn big bor_gray" (click)="closeDialog()">취소</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal -->