import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { WebApiService } from '../../webapi.service';
import {Router} from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-reset-password-by-code',
  templateUrl: './reset-password-by-code.component.html',
})
export class ResetPasswordByCodeComponent implements OnInit {
  result: object;
  submitted = false;

  constructor(private webapi: WebApiService, private router: Router) {}

  changePassForm = new FormGroup({
    password: new FormControl('',[
      Validators.required,
      Validators.pattern(/[A-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.pattern(/[!@#$%^&*()_\[+=\]{}~\?:;`|]/),
      Validators.minLength(8),
    ]),
    confirmPassword : new FormControl('')
  })

  get passForm() {
    return this.changePassForm.controls;
  }

  ngOnInit(): void {
    
  }
  
  onSubmit(): void {  
    this.submitted = true
    if(this.changePassForm.invalid){
      const invalid = [];
      const controls = this.changePassForm.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }
      return;
    }
    if(this.changePassForm.controls.password.value !== this.changePassForm.controls.confirmPassword.value){
      $('#confirmPassword').focus();
      alert('신규 비밀번호가 일치하지 않습니다.');
      return;
    }
    this.webapi.resetPaswordByCode(this.changePassForm.value).subscribe(
      (response)=>{
        alert('비밀번호 변경에 성공하였습니다.')
        this.router.navigate(['/member/login'])
      }
    ), (error) => {
        alert('비밀번호 변경에 성공하였습니다.')

    }
  }
 
}
