import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any): any;
declare const $: any;

@Component({
  selector: 'app-admin-claim-claim',
  templateUrl: './admin-claim-claim.component.html',
})
export class AdminClaimClaimComponent implements OnInit {
  userInfoList : any = [];
  claimList: any = [] ;
  submitted = false;

  //페이징변수
  curPage: any;
  totalCount: any;

  claimForm = new FormGroup({
    calculationId: new FormControl(''),
    claimNumber : new FormControl(''),
    companyName : new FormControl(''),
    registrationNumber : new FormControl(''),
    claimMonth : new FormControl(''),
    claimPrice : new FormControl(0),
    depositName : new FormControl('',[
      Validators.required
    ]),
    depositPrice : new FormControl('',[
      Validators.required,
      Validators.pattern(/[0-9]/),
    ]),
    description : new FormControl(''),
  })

  get claim() {
    return this.claimForm.controls
  }

  constructor(
    private webapi: WebApiService,
    private common: AdminNavComponent
  ) { }

  ngOnInit(): void {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'))    
    this.getClaimList(0);
  }

  getClaimList(page) {
    this.curPage = page
    var searchQuerys = "&o=created_date.DESC&l=10&s="+(this.curPage*10);

    this.webapi.getClaimList(searchQuerys).subscribe(
      (response) => {

        this.claimList = response.body['result'];
        this.totalCount ="1"
        this.common.getThemeFont();
      },(err) => {

      }
    )
  }

  getClaimSearch() {
    this.curPage = 0;
    var searchKeyword = $('#search').val();
    var searchQuerys = "o=created_date.DESC&l=10&s=" + (this.curPage * 10) + "&company_name=" + (searchKeyword) + "&lk=company_name"
    
    this.webapi.getClaimList(searchQuerys).subscribe(
      (response) => {
        this.claimList = response.body['result'];
      }, (err) => {

      }
    )
    
  }

  fn_detailCall(userData){

    this.claimForm.patchValue({
      calculationId: userData.calculationId,
      claimNumber : userData.TotalInvoice.totalInvoiceId,
      claimMonth : userData.TotalInvoice.totalInvoiceMonth,
      claimPrice : userData.TotalInvoice.realCost,
      companyName : userData.Company.companyName,
      depositPrice : userData.depositPrice,
      registrationNumber : userData.Company.registrationNumber,
      description : userData.description,
      depositName : userData.depositName
    })   
    fn_modalOpen('claim_confirm');
  }

  putClaim() {
    this.submitted = true;
    if (this.claimForm.invalid) {
        const invalid = [];
        const controls = this.claimForm.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }

        return;
    }




    this.webapi.updateClaim(this.claimForm.value).subscribe(
      (response) => {
        (<HTMLInputElement>document.getElementById('closeModalBtn2')).click();
        this.getClaimList(0);
      }, (err) => {

      }
    )
  }

  //페이징함수
  getMovePage(e){

    this.getClaimList(e.page)
  }
}
