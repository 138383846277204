import { Component, OnInit, Inject } from '@angular/core';
import { GWService } from '../../../gwapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-gw-route-create',
  templateUrl: './dialog-gw-route-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogGwRouteCreateComponent extends DialogCommon implements OnInit {

  // is_strip_path = true;
  // is_preserve_host = false;
  // is_keyauth = false;

  acl_auth_list = [];
  auth_list=[];
  selected_acl_auth;

  selected_api;
  new_route_object={
    name:"",
    path:"",
    protocol:["https"],
    methods:["GET"],
    is_strip_path:true,
    is_preserve_host:false,
    is_keyauth:false,
    is_acl:false,
    is_iprestriction:false,
    is_cors:false,
    is_requestsizelimiting:false,
    snis:[],
    acl_allow_groups:[],
    ip_restriction:{allow_ips:[],deny_ips:[]},
    cors_params_text:{
      origins:"*",
      methods:"GET,POST",
      headers:"Accept,Accept-Version,Content-Length,Content-MD5,Content-Type,Date,X-Auth-Token",
      exposed_headers:"",
      credentials:false,
      max_age:null
    },
    cors_params:{
      origins:[],
      methods:[],
      headers:[],
      exposed_headers:[],
      credentials:false,
      max_age:null
    },
    request_size_limit_size:128
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private gwapi: GWService,
    public dialogRef: MatDialogRef<DialogGwRouteCreateComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }
  ngOnInit(): void {
    this.getAuthList();
    this.selected_api = this.dialogData.selected_api;
  }
  getAuthList(){
    this.spinner.show();
    this.gwapi.getAuthList().subscribe((data: any) => {
      this.auth_list = data;
      console.log(data)
      this.spinner.hide();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }
  changeNewAcl(new_auth_id){
    const idx = this.auth_list.findIndex(e=>e.kong_consumer_id==new_auth_id);
    this.selected_acl_auth = this.auth_list[idx];
    console.log(this.auth_list[idx])
  }

  addAcl(){
    this.acl_auth_list.push(this.selected_acl_auth);
    const idx = this.auth_list.findIndex(e=>e.kong_consumer_id==this.selected_acl_auth.kong_consumer_id);
    if (idx > -1) this.auth_list.splice(idx, 1)
    this.selected_acl_auth ={}

  }
  removeAcl(auth){
    this.auth_list.push(auth);
    const idx = this.acl_auth_list.findIndex(e=>e.kong_consumer_id==auth.kong_consumer_id);
    if (idx > -1) this.acl_auth_list.splice(idx, 1)
  }
  createRoute(){
    if(this.acl_auth_list.length>0){
      this.new_route_object.is_acl = true;
      this.new_route_object.acl_allow_groups=this.acl_auth_list.map((item) => {
        return item.kong_group_name;
      });
    }
    if(this.new_route_object.request_size_limit_size){
      this.new_route_object.is_requestsizelimiting = this.new_route_object.request_size_limit_size>0
    }
    if(this.new_route_object.is_cors){
      this.new_route_object.cors_params.origins = this.new_route_object.cors_params_text.origins.split(',');
      this.new_route_object.cors_params.methods = this.new_route_object.cors_params_text.methods.split(',');
      this.new_route_object.cors_params.headers = this.new_route_object.cors_params_text.headers.split(',');
      if(this.new_route_object.cors_params_text.exposed_headers.length>0){
        this.new_route_object.cors_params.exposed_headers = this.new_route_object.cors_params_text.exposed_headers.split(',');
      }else{
        this.new_route_object.cors_params.exposed_headers = null;
      }
      this.new_route_object.cors_params.credentials = this.new_route_object.cors_params_text.credentials;
      this.new_route_object.cors_params.max_age = this.new_route_object.cors_params_text.max_age;
    }
    console.log(this.new_route_object.cors_params);
    this.spinner.show();
    this.gwapi.createRoute(
      this.selected_api.kong_service_id,
      this.new_route_object.name,
      this.new_route_object.path,
      this.new_route_object.protocol,
      this.new_route_object.methods,
      this.new_route_object.is_strip_path,
      this.new_route_object.is_preserve_host,
      this.new_route_object.is_keyauth,
      this.new_route_object.is_acl,
      this.new_route_object.is_iprestriction,
      this.new_route_object.is_cors,
      this.new_route_object.is_requestsizelimiting,
      this.new_route_object.snis,
      this.new_route_object.acl_allow_groups,
      this.new_route_object.ip_restriction,
      this.new_route_object.cors_params,
      this.new_route_object.request_size_limit_size

    ).subscribe((data: any) => {
      this.spinner.hide();
      this.closeDialogMsg("success");
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
}
