import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-snapshot-list',
  templateUrl: './mobile-console-snapshot-list.component.html',
  styleUrls: ['./mobile-console-snapshot-list.component.css']
})
export class MobileConsoleSnapShotListComponent implements OnInit {
  snapShotLists: any = [];
  imageLists: any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.getVolumeSnapshotList();
  }

  getVolumeSnapshotList(){
    this.spinner.show();
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:{items:[]}) => {
        
      this.osapi.getCurrentProjectImageList().subscribe(
        (res: any) => {
          this.snapShotLists= data.items
          if(this.snapShotLists.length>0){
            this.snapShotLists.forEach(snapshot => {
            this.osapi.getVolume(snapshot.volume_id).subscribe(
                (volume:{name:any}) => {
                  snapshot.volume_name = volume.name;
                });
            });
            this.imageLists = res.body;

            for(let image of this.imageLists){
              if(image.properties.block_device_mapping){
                let image_block_devices = JSON.parse(image.properties.block_device_mapping);
                let snapshot_id="";
                if(Array.isArray(image_block_devices)){
                  const image_block_device = image_block_devices.find(e=> e.device_name=="/dev/vda");
                  if(image_block_device){
                    snapshot_id = image_block_device.snapshot_id;
                  }
                }
                if(snapshot_id){
                  const idx = this.snapShotLists.findIndex(e=>e.id==snapshot_id);
                  if (idx > -1) this.snapShotLists.splice(idx, 1)
                }

              }
            }
            this._blank = ((this.snapShotLists != 0) ? true : false);
            this.reloadSnapshotStatusCheck(10);
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        }
      );
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });   
  }

  reloadSnapshotStatusCheck(count) {
    if(count < 1) return ;
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:any) => {
        let tmp_snapShotLists= data.items;
        let reload_flag = false;

        for( let snapshot of this.snapShotLists){

          if(snapshot.status != "available" ||snapshot.status != "error" ){
            tmp_snapShotLists.forEach(tmp => {
              if(snapshot.id == tmp.id){
                snapshot.status = tmp.status;
                reload_flag = true;
              }
            });
          }
        }
        if(reload_flag){
          this.reloadSnapshotStatusCheck(count-1);
        }
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
