import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WebApiService } from '../../webapi.service';
import { Router, ActivatedRoute, NavigationExtras} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-inquiry-list',
  templateUrl: './inquiry-list.component.html',
})
export class InquiryListComponent implements OnInit {

  userInfoList: any = [];
  inquiryList: any = [];

  //페이징변수
  curPage: any = 0;
  totalCount: any;


  searchCate: String = "";
  searchTitle: String = "";


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['page'] != null && params['page'] != 'undefined' && params['page'] != "") {
        this.curPage = params['page'];
      }

      if (params['searchTitle'] != null && params['searchTitle'] != "") {
        this.searchTitle = params['searchTitle'];
      }

      if (params['searchCate'] != null && params['searchCate'] != "") {
        this.searchCate = params['searchCate'];
      }

    });
  }
  
  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'))
    this.getInquiryList(this.curPage);
  }
  
  getInquiryList(page) {
    this.curPage = page;

    var searchQuerys = "l=10&s=" + (this.curPage * 10) + "&o=created_date.DESC&user_id=" + this.userInfoList.userId;
    if (this.searchCate != "") {
      searchQuerys += "&category=" + decodeURIComponent(this.searchCate + "");
    }
    if (this.searchTitle != "") {
      searchQuerys += "&title=" + decodeURIComponent(this.searchTitle + "") + "&lk=title";
    }
    this.spiner.show();
    this.webapi.getInquiryList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.inquiryList = data['result'];
        this.totalCount = data['total'];
      },(err) => {

      }
    );
    this.spiner.hide();
  }


  //페이징함수
  getMovePage(e) {
    this.getInquiryList(e.page)
  }


  // 검색
  fn_inquirySearch(title) {
    this.searchTitle = title;
    this.getInquiryList(0);
  }

  // 디테일이동
  fn_detailInquiry(key) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        inquiryId: key,
      },
      skipLocationChange: true,
    };
    this.router.navigate(['/web/mypage/inquriyView'], navigationExtras);
  }

  onSearchCateChange(deviceValue) {
    this.searchCate = deviceValue;
  }

  onSearchTitleChange(deviceValue) {
    this.searchTitle = deviceValue;
  }

}
