<!-- <div [style.display]="bannerClose?'none':'block'" style="color:white; font-weight: 500; width: 100%; position: relative; top: 0; left: 0; right: 0; background-color: #2c9cc6; height: 30px; text-align: center; padding: 7px;">
  <a href="http://service.bizlinkersys.com/" target="_blank">
    <div style="color:white; font-weight: 500; width: 99%;text-align: center; padding: 5px;">
      베타서비스가 오픈되었습니다. 이용 바랍니다.
    </div>
  </a>
  <button (click)="bannerClose=true" style="position: fixed;
  float: right;
  color: white;
  top: 7px;
  right: 5px;">X</button>
</div> -->
<div id="Wrap">
  <!-- myMenuBox -->
  <div class="myMenuBox" style="display: none;">
    <ul class="myMenu">
      <li>
        <a (click)="checkMypage()">
          <p class="tit">회원정보</p>
        </a>
      </li>
      <li>
        <a routerLink="/web/mypage/credit">
          <p class="tit">크레딧</p>
        </a>
      </li>
      <li>
        <a routerLink="/web/mypage/payment">
          <p class="tit">결제수단</p>
        </a>
      </li>
      <li>
        <a routerLink="/web/mypage/seviceHistory">
          <p class="tit">서비스이용내역</p>
        </a>
      </li>
      <li>
        <a routerLink="/web/mypage/inquriyList">
          <p class="tit">1:1문의</p>
        </a>
      </li>
    </ul>
  </div>
  <!-- //myMenuBox -->

  <!-- allMenuBox -->
  <div class="allMenuBox closeBox" style="display: none;">
    <a class="close_menu"></a>
    <ul class="allMenu">
      <li>
        <a href="#this">
          <p class="tit">COMPUTE</p>
          <p class="cont">Cloud compute service</p>
        </a>
      </li>
      <li>
        <a href="#this">
          <p class="tit">Load Balancer</p>
          <p class="cont">Load Balancer</p>
        </a>
      </li>
    </ul>
  </div>
  <!-- //allMenuBox -->

  <!-- Header -->
  <div id="Header" style="top:inherit;">
    <a href="/" class="logo"></a>
    <!-- <a class="btn_allMenu"><i class="ico ico_m_allMenu"></i></a> -->
  </div>
  <!-- //Header -->
  <!-- Container -->
  <div id="Container">
    <!-- leftMenu -->
    <div class="leftMenu">
      <!-- menu_inner -->
      <div class="menu_inner">

        <!-- gnb_wrap -->
        <div class="gnb_wrap">
          <!-- <a class="btn mid_fix btn_allMenu"><i class="ico ico_allMenu"></i></a> -->
          <a class="btn mid_fix btn_allMenu" style="cursor:auto"><i class="ico ico_allMenu"></i></a>
          <ul class="gnb">
            <li>
              <a class="btn mid_fix dGray btn_myMenu"><i class="ico ico_member_white"></i></a>
            </li>
          </ul>
        </div>
        <!-- //gnb_wrap -->

        <!-- gnb_wrap -->
        <div class="gnb_wrap tpA">
          <select name="zone_val" id="sZone" required *ngIf="userInfo" style="width:100%"
            (change)="onProjectChange($event.target.value)" [disabled]="changedProjectFlag">
            <option *ngFor="let project of projectList" [attr.value]="project.id"
              [selected]="project.id == userInfo.projectId">
              <!-- <ng-template [ngIf]="project.resource_data==null">{{ project.name }}</ng-template> -->
              <ng-template [ngIf]="isAdmin">{{ project.name }}</ng-template>
              <ng-template [ngIf]="project.resource_data!=null">{{ project.resource_data.value }}</ng-template>
            </option>
          </select>
        </div>
        <!-- //gnb_wrap -->

        <!-- tab_wrap -->
        <div class="tab_wrap">
          <div class="tab_header">
            <ul class="tabMenu tp1 colCnt2">
              <li class="on">
                <a class="leftFix"><span>서비스</span></a>
              </li>
              <li>
                <a class="leftFix"><span>퀵 메뉴</span></a>
              </li>
            </ul>
          </div>
          <div class="tab_content">
            <!-- lnb_wrap -->
            <div class="lnb_wrap active">
              <ul class="lnb">
                <!-- DASHBOARD -->
                <li class="depth1" routerLinkActive="on" [routerLinkActiveOptions]="{exact: true}">
                  <a><img src="../../../assets/img/common/console_dash_1.png">대시보드</a>
                  <ul class="subMenu" [style.display]="console.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" [routerLinkActiveOptions]="{exact: true}" #console="routerLinkActive">
                      <a routerLink="/console">대시보드</a>
                    </li>
                    <!-- <li routerLinkActive="on" #batch="routerLinkActive">
                      <a routerLink="batch">배치 작업</a>
                    </li> -->
                    <!--  || topology.isActive <li routerLinkActive="on" #topology="routerLinkActive">
                      <a routerLink="topology">토폴로지</a>
                    </li> -->
                  </ul>
                </li>
                <!-- SERVER -->
                <li class="depth1" routerLinkActive="on">
                  <a><img src="../../../assets/img/common/console_server.png" valign="absmiddle">서버</a>
                  <ul class="subMenu"
                    [style.display]="server.isActive || image.isActive || keypair.isActive  || monitor.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #server="routerLinkActive">
                      <a routerLink="server">인스턴스</a>
                    </li>
                    <li routerLinkActive="on" #image="routerLinkActive">
                      <a routerLink="image">이미지</a>
                    </li>
                    <li routerLinkActive="on" #keypair="routerLinkActive">
                      <a routerLink="keypair">인증키</a>
                    </li>
                    <li routerLinkActive="on" #monitor="routerLinkActive">
                      <a routerLink="server/monitor">모니터링</a>
                    </li>
                  </ul>
                </li>
                <!-- Network -->
                <li class="depth1" routerLinkActive="on">
                  <a><img src="../../../assets/img/common/console_net.png" valign="absmiddle">네트워크</a>
                  <ul class="subMenu" [style.display]="network.isActive || subnet.isActive 
                    || load.isActive || secure.isActive || ip.isActive || private.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #network="routerLinkActive">
                      <a routerLink="network">네트워크</a>
                    </li>
                    <li routerLinkActive="on" #subnet="routerLinkActive">
                      <a routerLink="subnet">서브넷</a>
                    </li>
                    <li routerLinkActive="on" #load="routerLinkActive">
                      <a routerLink="loadbalancer">로드 밸런서</a>
                    </li>
                    <li routerLinkActive="on" #secure="routerLinkActive">
                      <a routerLink="securitygroup">보안 그룹</a>
                    </li>
                    <li routerLinkActive="on" #ip="routerLinkActive">
                      <a routerLink="publicip">공인 IP</a>
                    </li>
                    <li routerLinkActive="on" #private="routerLinkActive">
                      <a routerLink="privateNetwork">사설 네트워크</a>
                    </li>
                  </ul>
                </li>
                <!-- STORAGE -->
                <li class="depth1" routerLinkActive="on">
                  <a><img src="../../../assets/img/common/console_storage.png">스토리지</a>
                  <ul class="subMenu"
                    [style.display]="storage.isActive || snapshot.isActive || nas.isActive || objectstorage.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #storage="routerLinkActive">
                      <a routerLink="storage">블록 스토리지</a>
                    </li>
                    <li routerLinkActive="on" #snapshot="routerLinkActive">
                      <a routerLink="snapshot">스냅샷</a>
                    </li>
                    <li routerLinkActive="on" #nas="routerLinkActive">
                      <a routerLink="nas">NAS</a>
                    </li>
                    <li routerLinkActive="on" #objectstorage="routerLinkActive">
                      <a routerLink="objectstorage">오브젝트 스토리지</a>
                    </li>
                    <!-- <li>
                      <a>Objcet Storage(준비중)</a>
                    </li> -->
                  </ul>
                </li>
                <li class="depth1" routerLinkActive="on">
                  <a><img src="../../../assets/img/common/console_apigw.png">어플리케이션</a>
                  <ul class="subMenu"
                    [style.display]="api.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #api="routerLinkActive">
                      <a routerLink="apigateway/api">API 게이트웨이</a>
                    </li>
                    <!-- <li routerLinkActive="on" #auth="routerLinkActive"> -->
                      <!-- <a routerLink="apigateway/auth">API KEY</a> -->
                    <!-- </li> -->
                    <!-- <li routerLinkActive="on" #usage="routerLinkActive"> -->
                      <!-- <a routerLink="apigateway/usage">사용량 조회</a> -->
                    <!-- </li> -->
                    <!-- <li>
                      <a>Objcet Storage(준비중)</a>
                    </li> -->
                  </ul>
                </li>
                <!-- DATABASE -->
                <!-- <li class="depth1" routerLinkActive="on">
                  <a>DATABASE</a>
                  <ul class="subMenu"
                    [style.display]="cloud.isActive || instance.isActive || blsdb.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #cloud="routerLinkActive">
                      <a routerLink="cloud">Cloud DB(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #instance="routerLinkActive">
                      <a routerLink="instance">Instance DB(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #blsdb="routerLinkActive">
                      <a routerLink="blsdb">BLS DB(준비중)</a>
                    </li>
                  </ul>
                </li> -->
                <!-- SECURITY -->
                <!-- <li class="depth1" routerLinkActive="on">
                  <a>SECURITY</a>
                  <ul class="subMenu" [style.display]="dns.isActive ? 'block' : 'none'">
                    <li>
                      <a>FireWall(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #dns="routerLinkActive">
                      <a routerLink="dns">DNS(준비중)</a>
                    </li>
                  </ul>
                </li> -->
                <!-- BACK UP -->
                <!-- <li class="depth1" routerLinkActive="on">
                  <a>BACK UP</a>
                  <ul class="subMenu" [style.display]="dbbak.isActive || serverbak.isActive ? 'block' : 'none'">
                    <li>
                      <a>Data Backup(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #dbbak="routerLinkActive">
                      <a routerLink="dbbackup">DB Backup(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #serverbak="routerLinkActive">
                      <a routerLink="serverbackup">Server Backup(준비중)</a>
                    </li>
                  </ul>
                </li> -->
                <!-- <li class="depth1" routerLinkActive="on">
                  <a>SERVICE</a>
                  <ul class="subMenu"
                    [style.display]="queue.isActive || dbms.isActive ||  baremetal.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #queue="routerLinkActive">
                      <a routerLink="messagequeue">메세지큐(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #dbms="routerLinkActive">
                      <a routerLink="dbms">상용DBMS(준비중)</a>
                    </li>
                    <li routerLinkActive="on" #baremetal="routerLinkActive">
                      <a routerLink="baremetal">베어메탈(준비중)</a>
                    </li>
                  </ul>
                </li> -->
                <!-- <ng-template [ngIf]="memtype == '0'"> -->
                <li class="depth1" routerLinkActive="on" *ngIf="isProjectAdmin">
                  <a><img src="../../../assets/img/common/console_project_2.png">프로젝트</a>
                  <ul class="subMenu" [style.display]="subuser.isActive || project.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #project="routerLinkActive">
                      <a routerLink="project">프로젝트 목록</a>
                    </li>

                    <li routerLinkActive="on" #subuser="routerLinkActive" >
                      <a routerLink="subuser">멤버 관리</a>
                    </li>
                  </ul>
                </li>
                <li class="depth1" routerLinkActive="on" *ngIf="!isProjectAdmin&&isSubAdmin">
                  <a><img src="../../../assets/img/common/console_project_2.png">프로젝트</a>
                  <ul class="subMenu" [style.display]="subuser.isActive ? 'block' : 'none'">
                    <li routerLinkActive="on" #subuser="routerLinkActive" >
                      <a routerLink="subuser">멤버 관리</a>
                    </li>
                  </ul>
                </li>
                <!-- </ng-template> -->
              </ul>
            </div>
            <!-- //lnb_wrap -->
          </div>
          <div class="tab_content" style="display: none;">
            <!-- lnb_wrap -->
            <div class="lnb_wrap2">
              <ul class="list">
                <li class="actionlist_head">
                  <ul>
                    <li>
                      <a class="actionlist_item" routerLink="/console/server/create" routerLinkActive="active">서버 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" *ngIf="global.selected_server_list.length > 0"
                        (click)="startServerNav()">서버 시작</a>
                    </li>
                    <li>
                      <a class="actionlist_item" *ngIf="global.selected_server_list.length > 0"
                        (click)="stopServerNav()">서버 정지</a>
                    </li>
                    <li>
                      <a class="actionlist_item" *ngIf="global.selected_server_list.length > 0"
                        (click)="softRebootServerNav()">서버 재시작</a>
                    </li>
                    <li>
                      <a class="actionlist_item" *ngIf="global.selected_server_list.length > 0"
                        (click)="deleteServerNav()">서버 삭제</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNewKeypairModal()">인증키 생성 </a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNewFloatingIpModal()">공인IP 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openPortForwardingModal()">포트포워딩 관리</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNewSecurityGroupModal()">보안그룹 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="manageSecurityGroup()">보안그룹 관리</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNewNetworkModal()">네트워크 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openSubnetCreateModal()">서브넷 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNewStorageModal()">스토리지 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNasCreateModal()">NAS 생성</a>
                    </li>
                    <li>
                      <a class="actionlist_item" (click)="openNewLB()">로드밸런서 생성</a>
                    </li>
                    <li *ngIf="isProjectAdmin">
                      <a class="actionlist_item" (click)="openNewProjectModal()">프로젝트 생성</a>
                    </li>




                  </ul>
                </li>
              </ul>
            </div>
            <!-- //lnb_wrap -->
          </div>
        </div>
        <!-- //tab_wrap -->
      </div>
      <!-- //menu_inner -->
    </div>
    <!-- //leftMenu -->

    <!-- <app-console-projects></app-console-projects> -->



    <router-outlet></router-outlet>



    <!-- Container -->
  </div>

  <!-- <app-console-projects></app-console-projects> -->

  <!-- line-spin-fade-rotating -->

  <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.0)" size="medium" color="#f6d860" type="pacman" [fullScreen]="false"> -->
  <!-- <div style="color: #64d6e2" class="la-square-jelly-box la-3x"> -->

  <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.0)" size="medium" color="black" type="line-spin-fade-rotating" [fullScreen]="false"> -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.0)" size="medium" color="#31a3cb" type="square-jelly-box" [fullScreen]="false">
    <p style="color: black;"></p>
  </ngx-spinner>

  <!-- <ejs-toast #defaulttoast id='defaulttoast' [position]="position" (created)="onCreate()" icon="e-meeting" >
    <ng-template #content>
        <div>{{toastMessage}}</div>
    </ng-template>
    <ng-template #title>
        <div>{{toastTitle}}</div>
    </ng-template>
</ejs-toast> -->

  <ejs-toast #toasttype id='toasttype' [position]="position"></ejs-toast>