import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../../../osapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

@Component({
  selector: 'app-admin-flavor-list',
  templateUrl: './admin-flavor-list.component.html',
})
export class AdminFlavorListComponent implements OnInit {

  flavorLists: any=[];
  selected_flavor:any={};
  constructor(private osapi:OsapiService,private common:AdminNavComponent) { }

  ngOnInit(): void {
    this.getFlavorList();
  }
  onClickList(image){

    this.selected_flavor = image;
  }

  getFlavorList(){
    this.osapi.getFlavorList().subscribe(
      (data:{items:[]}) =>{

        this.flavorLists= data.items
        this.common.getThemeFont();
      }
    )
  }
  deleteFlavor(){    

    this.osapi.deleteFlavor(this.selected_flavor.id).subscribe(
      (data:{}) => {

      location.reload();
    });    
  }
}
