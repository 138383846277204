import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../console/dialog/dialog-common';

@Component({
  selector: 'app-mobile-console-rule-manage-list',
  templateUrl: './mobile-console-rule-manage-list.component.html',
  styleUrls: ['./mobile-console-rule-manage-list.component.css']
})
export class MobileConsoleRuleManageListComponent implements OnInit {
  securityGroupRuleLists: any = [];
  security_group_id="";
  security_group;
  _blank = true;

  constructor(
    private osapi:OsapiService, 
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    public dialog: MatDialog,
    route:ActivatedRoute,
  ) { 
    this.security_group_id = route.snapshot.params['sgId'];
    this.getSecurityGroup(this.security_group_id);
  }

  ngOnInit(): void {
    this.getSecurityGroupRuleList(this.security_group_id);
  }

  getSecurityGroupRuleList(sg_id){
    this.spinner.show();
    this.osapi.getSecurityGroupRuleList(sg_id).subscribe(
      (data) => {
      this.securityGroupRuleLists = data;
      this._blank = ((this.securityGroupRuleLists != 0) ? true : false);
      this.spinner.hide();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }

  getSecurityGroup(sg_id){
    this.spinner.show();
    this.osapi.getSecurityGroup(sg_id).subscribe(
      (data) => {
        this.spinner.hide();
        this.security_group= data;
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }

  openDialogCreate() {
    const dialogRef = this.dialog.open(DialogConsoleRuleManagerCreateComponent, {
      width: '300px', data: this.security_group
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
        this.getSecurityGroupRuleList(this.security_group_id);
      }
    });
  }

  openDialogUpdate(item) {
    const dialogRef = this.dialog.open(DialogConsoleRuleManagerUpdateComponent, {
      width: '300px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getSecurityGroupRuleList(this.security_group_id);
      }
    });
  }

  openDialogDelete(item) {
    const dialogRef = this.dialog.open(DialogConsoleRuleManagerDeleteComponent, {
      width: '300px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getSecurityGroupRuleList(this.security_group_id);
      }
    });
  }

}

@Component({
  selector: 'app-mobile-console-rule-manage-dialog-create',
  templateUrl: './mobile-console-rule-manage-dialog-create.component.html',
  styleUrls: ['./mobile-console-rule-manage-list.component.css'],
  providers: [MobileConsoleRuleManageListComponent]
})

export class DialogConsoleRuleManagerCreateComponent extends DialogCommon {

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private frontapi: FrontApiService,
    public dialogRef: MatDialogRef<DialogConsoleRuleManagerCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      super();
      this.dialogReference = dialogRef;
    }

    security_group;

    newRuleData:{
      direction:string,
      ip_protocol:string,
      from_port:string,
      to_port:string,
      cidr:string,
      group_id:string,
      description:string,
    }={
      direction:"ingress",
      ip_protocol:null,
      from_port:null,
      to_port:null,
      cidr:"",
      group_id:null,
      description:""
    };
  
    cidr_validate = true;
    addRuleModalType:string="all";
    addRulePortType:string="one_port";
  
    ngOnInit(): void {
      console.log(this.data);
      this.security_group = this.data;
    }

  addRule(){
    if(this.addRulePortType == "all_icmp" ){
    }
    else if(this.addRulePortType == "one_icmp" &&  this.newRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP TYPE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.addRulePortType == "one_icmp" &&  this.newRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP CODE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.addRuleModalType!="all"&&this.addRulePortType != "all_port" &&  this.newRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    else if(this.addRuleModalType!="all"&&this.addRulePortType != "all_port" &&  this.newRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    if(!this.cidr_validate){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'유효하지 않은 CIDR입니다.'
        }
      });
      return;
    }
    if( this.newRuleData.cidr == "0.0.0.0/0"){
      const answer = confirm("CIDR을 '0.0.0.0/0'으로 설정하면 모든 사용자로부터 해당 보안그룹에 접근할 수 있습니다. 진행하시겠습니까?.");
      if(!answer){
        this.newRuleData = {
          direction:"ingress",
          ip_protocol:null,
          from_port:null,
          to_port:null,
          cidr:"",
          group_id:null,
          description:""
        };
        return;
      }
    }
    this.spinner.show();
    this.osapi.createSecurityGroupRule(this.security_group.id,
                      this.newRuleData.direction,this.newRuleData.ip_protocol,
                      this.newRuleData.from_port,this.newRuleData.to_port,
                      this.newRuleData.cidr,this.newRuleData.group_id,this.newRuleData.description).subscribe(
      (data) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
    }, 
    (error) => {
      if(error.status == 409){
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'동일한 규칙이 이미 존재합니다.'
          }
        });
        this.spinner.hide();
        return;
      }
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      this.spinner.hide();
    });
  }

  onRuleTypeChange(addRuleModalType){
    this.addRuleModalType = addRuleModalType;
    if(addRuleModalType == "all"){
      addRuleModalType = null;
    }
    if(addRuleModalType == "icmp"){
      this.addRulePortType = "all_icmp"
    }
    this.newRuleData.ip_protocol = addRuleModalType;

    if(addRuleModalType == "ssh"){
      this.newRuleData.ip_protocol = "tcp"
      this.newRuleData.from_port = '22'
      this.newRuleData.to_port = '22'
    }
    if(addRuleModalType == "http"){
      this.newRuleData.ip_protocol = "tcp"
      this.newRuleData.from_port = '80'
      this.newRuleData.to_port = '80'
    }
    if(addRuleModalType == "https"){
      this.newRuleData.ip_protocol = "tcp"
      this.newRuleData.from_port = '443'
      this.newRuleData.to_port = '443'
    }
  }
  onRuleDescriptionChange(addRuleModalDesc){
    console.log(addRuleModalDesc + "1");
    this.newRuleData.description = addRuleModalDesc;
  }
  onRuleDirectionChange(addRuleModalDirection){
    console.log(addRuleModalDirection + "2");
    this.newRuleData.direction = addRuleModalDirection;
  }
  onRulePortTypeChange(addRulePortType){
    console.log(addRulePortType + "3");
    this.addRulePortType = addRulePortType;
    this.newRuleData.from_port = null;
    this.newRuleData.to_port = null;
  }
  onRuleFromPortChange(addRuleModalFromPort){
    console.log(addRuleModalFromPort + "4");
    this.newRuleData.from_port = addRuleModalFromPort;
    if(parseInt(addRuleModalFromPort)>65535){
      this.newRuleData.from_port = '65535';
    }
    if(parseInt(addRuleModalFromPort)<1){
      this.newRuleData.from_port = '1';
    }
    if(parseInt(addRuleModalFromPort)>parseInt(this.newRuleData.to_port)){
      this.newRuleData.to_port = this.newRuleData.from_port;
    }
  }
  onRuleToPortChange(addRuleModalToPort){
    console.log(addRuleModalToPort + "5");
    this.newRuleData.to_port = addRuleModalToPort;
    if(parseInt(addRuleModalToPort)>65535){
      this.newRuleData.to_port = '65535';
    }
    if(parseInt(addRuleModalToPort)<1){
      this.newRuleData.to_port = '1';
    }
    if(parseInt(addRuleModalToPort)<parseInt(this.newRuleData.from_port)){
      this.newRuleData.from_port = this.newRuleData.to_port;
    }
  }
  onRuleCidrChange(addRuleModalCidr){
    console.log(addRuleModalCidr + "6");
    if(addRuleModalCidr == ""){
      addRuleModalCidr = "0.0.0.0/0"
    }
    //CIDR 체크  IPv4 주소 및 masking 비트가 포함된 문자열 true
    var cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
    if(!cidr_filter.test(addRuleModalCidr)){
      this.cidr_validate = false;
    }else{
      this.cidr_validate = true;
    }
    this.newRuleData.cidr = addRuleModalCidr;
  }
  
}

@Component({
  selector: 'app-mobile-console-rule-manage-dialog-update',
  templateUrl: './mobile-console-rule-manage-dialog-update.component.html',
  styleUrls: ['./mobile-console-rule-manage-list.component.css'],
  providers: [MobileConsoleRuleManageListComponent]
})

export class DialogConsoleRuleManagerUpdateComponent extends DialogCommon {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogConsoleRuleManagerUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  security_group;

  modifyRuleData:{
    direction:string,
    ip_protocol:string,
    from_port:string,
    to_port:string,
    cidr:string,
    group_id:string,
    description:string,
  }={
    direction:"ingress",
    ip_protocol:null,
    from_port:null,
    to_port:null,
    cidr:"",
    group_id:null,
    description:""
  };

  modRuleModalType:string="all";
  modRulePortType:string="one_port";
  mod_cidr_validate = true;

  ngOnInit(): void {
    console.log("1"+JSON.stringify(this.data));
    
    this.security_group = this.dialogData.security_group;

    this.mod_cidr_validate = true;
    this.modifyRuleData.description = this.data.description;
    this.modifyRuleData.direction = this.data.direction;
    this.modifyRuleData.from_port = this.data.from_port;
    this.modifyRuleData.to_port = this.data.to_port;
    this.modifyRuleData.cidr = this.data.ip_range.cidr;
    this.modifyRuleData.ip_protocol = this.data.ip_protocol;
    if(this.modifyRuleData.ip_protocol){
      this.modRuleModalType = this.modifyRuleData.ip_protocol;
    }else{
      this.modRuleModalType = "all";
    }
    if(this.modRuleModalType == "tcp"){
      if(this.modifyRuleData.from_port==null){
        this.modRulePortType = 'all_port';
      }else if(this.modifyRuleData.from_port == this.modifyRuleData.to_port){
        this.modRulePortType = 'one_port';
      }else{
        this.modRulePortType = 'many_port';
      }
    }else if(this.modRuleModalType == "udp"){
      if(this.modifyRuleData.from_port==null){
        this.modRulePortType = 'all_port';
      }else if(this.modifyRuleData.from_port == this.modifyRuleData.to_port){
        this.modRulePortType = 'one_port';
      }else{
        this.modRulePortType = 'many_port';
      }
    }else if(this.modRuleModalType == "icmp"){
      if(this.modifyRuleData.from_port==null && this.modifyRuleData.to_port==null){
        this.modRulePortType = 'all_icmp';
      }else{
        this.modRulePortType = 'one_icmp';
      }
    }else if(this.modRuleModalType == "all"){
      this.modifyRuleData.from_port=null;
      this.modifyRuleData.to_port=null;
    }
    console.log(this.modifyRuleData);
  }


  modifyRule(){
    if(this.modRulePortType == "all_icmp" ){
    }
    else if(this.modRulePortType == "one_icmp" &&  this.modifyRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP TYPE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.modRulePortType == "one_icmp" &&  this.modifyRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP CODE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.modRuleModalType!="all"&&this.modRulePortType != "all_port" &&  this.modifyRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    else if(this.modRuleModalType!="all"&&this.modRulePortType != "all_port" &&  this.modifyRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    if(!this.mod_cidr_validate){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'유효하지 않은 CIDR입니다.'
        }
      });
      return;
    }
    
    if( this.modifyRuleData.cidr == "0.0.0.0/0"){
      const answer = confirm("CIDR을 '0.0.0.0/0'으로 설정하면 모든 사용자로부터 해당 보안그룹에 접근할 수 있습니다. 진행하시겠습니까?.");
      if(!answer){
        this.modifyRuleData={
          direction:"ingress",
          ip_protocol:null,
          from_port:null,
          to_port:null,
          cidr:"",
          group_id:null,
          description:""
        };
        return;
      }
    }

    this.spinner.show();
    this.osapi.deleteSecurityGroupRule(this.data.id).subscribe(
    (data) => {
    this.osapi.createSecurityGroupRule(this.data.parent_group_id,
      this.modifyRuleData.direction,this.modifyRuleData.ip_protocol,
      this.modifyRuleData.from_port,this.modifyRuleData.to_port,
      this.modifyRuleData.cidr,this.modifyRuleData.group_id,this.modifyRuleData.description).subscribe(
        (data) => {
          this.spinner.hide();
          this.closeDialogMsg("success");
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type:'toast',
            body:{
              type:'error',
              title:'오류 알림',
              message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
    
  }

  onRuleTypeChangeMod(modRuleModalType){
    this.modRuleModalType = modRuleModalType;
    if(this.modRuleModalType == "all"){
      modRuleModalType = null;
    }
    this.modifyRuleData.ip_protocol = modRuleModalType;
    
    if(modRuleModalType == "ssh"){
      this.modifyRuleData.ip_protocol = "tcp"
      this.modifyRuleData.from_port = '22'
      this.modifyRuleData.to_port = '22'
    }
    if(modRuleModalType == "http"){
      this.modifyRuleData.ip_protocol = "tcp"
      this.modifyRuleData.from_port = '80'
      this.modifyRuleData.to_port = '80'
    }
    if(modRuleModalType == "https"){
      this.modifyRuleData.ip_protocol = "tcp"
      this.modifyRuleData.from_port = '443'
      this.modifyRuleData.to_port = '443'
    }
  }

  onRulePortTypeChangeMod(modRulePortType){
    this.modRulePortType = modRulePortType;
    this.modifyRuleData.from_port = null;
    this.modifyRuleData.to_port = null;
  }
  onRuleFromPortChangeMod(modRuleModalFromPort){
    this.modifyRuleData.from_port = modRuleModalFromPort;
    if(parseInt(modRuleModalFromPort)>65535){
      this.modifyRuleData.from_port = '65535';
    }
    if(parseInt(modRuleModalFromPort)<1){
      this.modifyRuleData.from_port = '1';
    }
    if(parseInt(modRuleModalFromPort)>parseInt(this.modifyRuleData.to_port)){
      this.modifyRuleData.to_port = this.modifyRuleData.from_port;
    }
  }
  onRuleToPortChangeMod(modRuleModalToPort){
    this.modifyRuleData.to_port = modRuleModalToPort;
    if(parseInt(modRuleModalToPort)>65535){
      this.modifyRuleData.to_port = '65535';
    }
    if(parseInt(modRuleModalToPort)<1){
      this.modifyRuleData.to_port = '1';
    }
    if(parseInt(modRuleModalToPort)<parseInt(this.modifyRuleData.from_port)){
      this.modifyRuleData.from_port = this.modifyRuleData.to_port;
    }
  }
  onRuleCidrChangeMod(modRuleModalCidr){
    if(modRuleModalCidr == ""){
      modRuleModalCidr = "0.0.0.0/0"
    }
    //CIDR 체크  IPv4 주소 및 masking 비트가 포함된 문자열 true
    var cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
    if(!cidr_filter.test(modRuleModalCidr)){
      this.mod_cidr_validate = false;
    }else{
      this.mod_cidr_validate = true;
    }
    this.modifyRuleData.cidr = modRuleModalCidr;
  }
  
}


@Component({
  selector: 'app-mobile-console-rule-manage-dialog-delete',
  templateUrl: './mobile-console-rule-manage-dialog-delete.component.html',
  styleUrls: ['./mobile-console-rule-manage-list.component.css'],
  providers: [MobileConsoleRuleManageListComponent]
})

export class DialogConsoleRuleManagerDeleteComponent extends DialogCommon {
  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private frontapi: FrontApiService,
    public dialogRef: MatDialogRef<DialogConsoleRuleManagerDeleteComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.dialogReference = dialogRef;
  }

  deleteRule(){
    console.log(this.data);
    this.spinner.show();
    this.osapi.deleteSecurityGroupRule(this.data.id).subscribe(
      (data) => {
      console.log(data);
      this.spinner.hide();
      }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}