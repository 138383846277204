import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { WebApiService } from '../../webapi.service';
import { Router } from '@angular/router';

declare const $: any;
declare function sendAuthNum(flag);

@Component({
  selector: 'app-find-id',
  templateUrl: './find-id.component.html',
})
export class FindIdComponent implements OnInit {
  result: object;
  submitted = false;


  constructor(private webapi: WebApiService, private router: Router) { }
  findidForm = new FormGroup({
    userName: new FormControl('', [
      Validators.required,
      Validators.pattern(/[가-힣]/)
    ]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern(/[0-9]/),
      Validators.minLength(10)
    ]),
  });

  get findForm() { return this.findidForm.controls; }



  ngOnInit(): void {
  }

  reConfirmCode() {
    this.webapi.makeFindIDCode(this.findidForm.value).subscribe(
      (response) => {
        alert('인증번호를 보냈습니다.');
        sendAuthNum(false);
      }, (err) => {

      }
    )
  }

  confirmCodeCheck() {
    const code = $('#confirmCode').val();
    if (code === null || code === undefined || code === '') {
      alert('인증번호를 입력해 주세요.');
      return;
    }

    this.webapi.confirmFindIDCode(code).subscribe(
      (response) => {

        alert(response.body.userId)
        this.router.navigate(['/member/login'])
      }, (err) => {

      }
    )
  }
  onSubmit(): void {
    this.submitted = true
    if (this.findidForm.invalid) {
      const invalid = [];
      const controls = this.findidForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      return;
    }

    this.webapi.makeFindIDCode(this.findidForm.value).subscribe(
      (response) => {

        $('#confirmCodeLi').show();
        $('#findpassword').hide();
        $('#userName').attr('disabled', true);
        $('#mobile').attr('disabled', true);
        alert('인증번호를 보냈습니다.');
        sendAuthNum(false);
      }, (err) => {
        alert('일치하는 가입 정보가 없습니다.')
        return;
      }
    )
  }
  routerHome(){
    this.router.navigate(['/']);
  }
}


