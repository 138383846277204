<div id="auth_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        API KEY 생성
        <p class="sub-txt">API 사용 시 사용자가 사용하기 위한 API Key를 발급합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">API KEY 이름</th>
              <td>
                <input type="text" maxlength="20" name="" id="secureNm" placeholder="20자 이내로 작성해주세요. 영문자와 숫자, '-', '_'만 입력 가능합니다."
                  [(ngModel)]="newKeyData.name"/>
                  
              </td>
            </tr>
            <tr>
                <th scope="row">인증방법</th>
                <td>
                    <select [(ngModel)]="newKeyData.type" name="" required>
                        <option value="api_key">API KEY</option>
                    </select>
                    <div class="sub-txt">
                      <div>API KEY 방식 인증은 HTTP 헤더에 'apikey:&lt;api_key_value&gt;' 형태로 사용됩니다.</div>
                    </div>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)= "createAuth()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->