import { PoolData } from "./PoolData";
import {HealthMonitorData} from "./HealthMonitorData";

export class ListenersData {
    public name: string;
    public protocol: string;
    public protocol_port: Number;
    // public default_pool: PoolData;
    public connection_limit:Number;
    public admin_state_up:boolean;
    public description:string;
    public insert_headers:any={};
    public timeout_client_data:number;
    public timeout_member_connect:number;
    public timeout_member_data:number;
    public timeout_tcp_inspect:number;

    constructor() {
        this.name = 'Listener-';
        this.protocol = 'HTTP';
        this.protocol_port = 80;
        // this.default_pool = null;
        this.connection_limit = -1;
        this.admin_state_up=true;
        this.description = '';
        this.insert_headers={};
        this.timeout_client_data=50000;
        this.timeout_member_connect=5000;
        this.timeout_member_data=50000;
        this.timeout_tcp_inspect=0;
    }
}