import { Component, OnInit, Inject } from '@angular/core';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';

@Component({
  selector: 'app-dialog-bucket-create',
  templateUrl: './dialog-bucket-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogBucketCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private cephapi: CephapiService,
    public dialogRef: MatDialogRef<DialogBucketCreateComponent>

  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  newBucket: any = { name: '',  description: ''};
  // companyDomainId: string = '';

  ngOnInit(): void {
    // this.companyDomainId = this.dialogData.companyDomainId;

  }


  createBucket() {
    if(this.newBucket.name == ''){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    if(!(/(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/.test(this.newBucket.name))) {
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:"입력하신 버킷명은 규칙에 맞지 않습니다.<br>다시 한번 확인바랍니다."
        }
      });
      return;
    }
    try {
      this.spinner.show();
      let bucketName = `${this.newBucket.name}`;
      // this.newBucket.name = bucketName;

      this.cephapi.createBucket(bucketName).subscribe((data) => {
        this.spinner.hide();
        this.closeDialogMsg({code:"success",data:data});
      },
      (error) => {
        let msg = "일시적으로 문제가 발생하였습니다. 새로고침해주세요.";
        console.log(error);
        if(409 == error.status) msg = "이미 존재하는 버킷 이름입니다.";
        else if(403 === error.status) msg = "입력하신 버킷명은 규칙에 맞지 않습니다.<br>다시 한번 확인바랍니다.";
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:msg
        }
      });
      });
    } catch (e) {
      this.spinner.hide();

    }
  }

}
