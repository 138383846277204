import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-snapshot-create',
  templateUrl: './dialog-storage-snapshot-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageSnapshotCreateComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageSnapshotCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_volume;

  volumeList:any=[];

  //스냅샷 생성 팝업
  newSnapshotData: {
    name:string,
    description:string,
    volume_id:string
  } ={
    name:"",
    description:"",
    volume_id:""
  };
  
  ngOnInit(): void {

    this.selected_volume = this.dialogData.selected_volume;
    this.newSnapshotData.volume_id = this.selected_volume.id
    this.volumeList = this.dialogData.volumeList;
  }


  createVolumeSnapshot(){
    this.spinner.show();  
    
    if(this.newSnapshotData.name == null || this.newSnapshotData.name ==''){
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'스냅샷의 이름을 입력하지 않았습니다.'
        }
      });
      return;
    }
    if(this.newSnapshotData.volume_id == null || this.newSnapshotData.volume_id ==''){
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'스냅샷을 생성할 대상 스토리지를 선택하지 않았습니다.'
        }
      });
      return;
    }
    this.osapi.createVolumeSnapshot(this.newSnapshotData.volume_id,this.newSnapshotData.name,this.newSnapshotData.description).subscribe(
      (data:{}) => {

      this.spinner.hide();
      this.closeDialogMsg("success");
      this.router.navigateByUrl('/console', {
        skipLocationChange: true,
      }).then(
        () => {
          this.router.navigate(['/console/snapshot']);
        }
      );
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }
  
  onChangeSnapshotModalName(name){
    this.newSnapshotData.name = name;
  }
  onChangeSnapshotModalDescription(desc){
    this.newSnapshotData.description = desc;
  }
}
