import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import {ListenersData} from '../../console-load-balancer-setting/ListenersData';

@Component({
  selector: 'app-dialog-loadbalancer-listener-update',
  templateUrl: './dialog-loadbalancer-listener-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogLoadbalancerListenerUpdateComponent extends DialogCommon implements OnInit {

  selected_loadbalancer;
  selected_protocol;
  lbListener = new ListenersData();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogLoadbalancerListenerUpdateComponent>,
  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
   }

  ngOnInit(): void {
    this.selected_loadbalancer = this.dialogData.selected_loadbalancer;
    this.lbListener.name = this.selected_loadbalancer.listener.name;
    this.lbListener.description = this.selected_loadbalancer.listener.description;
    this.lbListener.connection_limit = this.selected_loadbalancer.listener.connection_limit;
    this.lbListener.protocol = this.selected_loadbalancer.listener.protocol;
    this.lbListener.protocol_port = this.selected_loadbalancer.listener.protocol_port;
  }

  changeProtocol(protocol) {
    this.selected_protocol = protocol;
    this.lbListener.protocol = protocol;
  }

  updateLinster(){
    var param = {
      listener: JSON.parse(JSON.stringify(this.lbListener)),
      loadbalancer_id:this.selected_loadbalancer.id
    }
    
    param.listener.id = this.selected_loadbalancer.listener.id;


    this.osapi.updateListener(this.selected_loadbalancer.listener.id,param).subscribe(
      (data)=>{

        this.closeDialogMsg("success");
      }
    )
  }
}
