import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-image-create',
  templateUrl: './dialog-storage-image-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageImageCreateComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageImageCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_volume;

  
  newImageData:{
    name:string,
    format:string,
    forced:boolean
  } ={
    name:"",
    format:"",
    forced:true
  }
  
  ngOnInit(): void {

    this.selected_volume = this.dialogData.selected_volume;
  }


  createVolumeImage(){
    if(this.selected_volume.bootable == 'false'){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'부팅 볼륨이 아닌 경우 이미지를 생성할 수 없습니다.'
        }
      });
      this.closeDialogMsg("fail")
      return
    }
    if(this.newImageData.name == ""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return
    }
    if(this.newImageData.format == ""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'디스크포맷을 선택해주세요.'
        }
      });
      return
    }
    this.spinner.show();
    this.osapi.createVolumeImage(this.selected_volume.id,this.newImageData.name, this.newImageData.format, this.newImageData.forced).subscribe(
      (data:{}) => {

      this.spinner.hide();
      this.closeDialogMsg("success")
      this.router.navigate(['/console/image']);
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }

}
