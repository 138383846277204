<!-- Modal : Bucket 생성 모달 -->
<div id="folder_create" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      폴더 생성
      <!-- <p class="sub-txt">Object Storage Bucket을 생성합니다.</p> -->
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>폴더 명</th>
            <td><input type="text" [(ngModel)]="newFolder.name" placeholder="폴더명을 입력해주세요" /></td>
          </tr>
          <!-- <tr>
            <th>크기(GB)</th>
            <td>
              <select [(ngModel)]="newNas.size">
                <option value="" selected>-선택-</option>
                <option *ngFor="let size of nasSize" value="{{size}}">{{size}} GB</option>
              </select>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <a (click)="createFolder()" class="btn big ciblue mg_r4">생성</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->