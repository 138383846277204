import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-subuser-update',
  templateUrl: './dialog-subuser-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogSubuserUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSubuserUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_subUser: any = {};
  projectList: any = [];
  projectIdToAdd: any = {};
  
  projectsOfSubUser: any = []; // 제외 대상 프로젝트들 (서브 계정이 현재 소속된 프로젝트)
  restProjects: any = []; // 추가 대상 프로젝트들 (서브 계정이 추가 가능한 프로젝트)

  updateData:any={
    selected_type:0,
    password:"",
    password_check:true
  }

  usertype=[
    {type:0,name:"관리자"},
    {type:1,name:"운영담당자"},
    {type:2,name:"회계담당자"},
  ];
  ngOnInit(): void {

    this.selected_subUser = this.dialogData.selected_subUser
    this.projectList = this.dialogData.projectList
    this.updateData.selected_type = this.selected_subUser.type;
    this.getProjectsById(this.selected_subUser.horizonUid);
  }


  resetData() {
    this.projectsOfSubUser = [];
    this.restProjects = [];
  }
  updateSubUser(){
    if(!this.updateData.password_check){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'영문 대문자, 숫자, 특수문자를 1개씩 이상 포함하여 8자리 이상 입력해주세요. (최대 20자)'
        }
      });
      return;
    }
    try {
      const params = {user_id:this.selected_subUser.userId,password:this.updateData.password,type:this.updateData.selected_type};
      this.osapi.updateSubUser(params).subscribe((data:any) => {
        this.closeDialogMsg("success");
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
    } catch (e) {

    }

  }
  changePassword(){
    var passwordVal1 = /[A-Z]/;
    var passwordVal2 = /[0-9]/;
    var passwordVal3 = /[!@#$%^&*()_\[+=\]{}~\?:;`|]/;
    if(this.updateData.password.length>0){
      if (this.updateData.password.length < 8 || this.updateData.password.length > 20) {
        this.updateData.password_check =false;
      } else if (
        !passwordVal1.test(this.updateData.password) ||
        !passwordVal2.test(this.updateData.password) ||
        !passwordVal3.test(this.updateData.password)
      ){
        this.updateData.password_check =false;
      }
    }else{
      this.updateData.password_check =true;
    }
  }
  getProjectsById(horizonUid) {
    // alert(horizonUid);
    this.resetData();
    try {
      this.osapi.getProjectsById(horizonUid).subscribe((data: { items }) => {
        this.spinner.hide();

        this.projectsOfSubUser = data.items;

        this.restProjects = this.projectList.filter((p1) => {
          let flag = true;
          for (let p2 of this.projectsOfSubUser) {
            if (p1.id === p2.id) {
              flag = false;
              break;
            }
          }
          return flag;
        });
        if(this.restProjects.length > 0){
          this.projectIdToAdd = this.restProjects[0].id
        }else{
          this.projectIdToAdd = null
        }

      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
    } catch (e) {

    }
  }
  
  createRoleBind() {



    try {
      this.spinner.show();
      this.osapi
        .createRoleBind(this.projectIdToAdd, this.selected_subUser.horizonUid)
        .subscribe((data) => {
          this.spinner.hide();
          // projectsOfSubUser //추가
          let selectedProject = this.projectList.find(
            (project) => project.id === this.projectIdToAdd
          );
          this.projectsOfSubUser.push(selectedProject);

          // restProjects //제거

          this.restProjects = this.restProjects.filter(
            (project) => project.id !== selectedProject.id
          );
          if(this.restProjects.length > 0){
            this.projectIdToAdd = this.restProjects[0].id
          }else{
            this.projectIdToAdd = null
          }
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
        });
    } catch (e) {

    }
  }

  deleteRoleBind(projectId) {
    try {
      this.spinner.show();
      this.osapi
        .deleteRoleBind(projectId, this.selected_subUser.horizonUid)
        .subscribe((data) => {
        this.spinner.hide();
      // projectsOfSubUser  //제거
          let selectedProject = this.projectList.find(
            (project) => project.id === projectId
          );

          this.projectsOfSubUser = this.projectsOfSubUser.filter(
            (project) => project.id !== selectedProject.id
          );

          // restProjects //추가
          this.restProjects.push(selectedProject);
          if(this.restProjects.length > 0){
            this.projectIdToAdd = this.restProjects[0].id
          }else{
            this.projectIdToAdd = null
          }
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
        });
    } catch (e) {

    }
  }

  onChangeProjectIdToAdd(value) {
    this.projectIdToAdd = value;
  }
  
}
