<!-- Modal : 인증키 생성 모달 2 -->

<div id="modal_authkey_detail" class="modal2">
  <div class="modal_header">
    <h2>인증키 생성</h2>
  </div>
  <div *ngIf="new_keypair != null" class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 20%;" />
          <col style="width: 80%;" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">인증키 이름</th>
            <td>{{ new_keypair.keypair.name }}</td>
          </tr>
          <tr>
            <th scope="row">지문</th>
            <td>{{ new_keypair.keypair.fingerprint }}</td>
          </tr>
          <tr>
            <th scope="row">공개 키</th>
            <td>{{ new_keypair.keypair.public_key }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a class="btn big ciblue mg_r4" (click)="downloadKeyPair()">다운로드
      </a>
      <a id="privatekey_download" [href]="fileUrl" [download]="privatekey_name" style="display: none;">
      </a>
    </div>
  </div>
</div>
<!-- //Modal -->