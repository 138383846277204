import { Component, OnInit, Inject } from '@angular/core';
import { GWService } from '../../../gwapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-gw-iprestriction',
  templateUrl: './dialog-gw-iprestriction.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogGwIprestrictionComponent extends DialogCommon implements OnInit {

  target_route:any={};

  new_rule={
    cidr:"",
    type:"deny"
  }
  deny_list=[];
  allow_list=[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private gwapi: GWService,
    public dialogRef: MatDialogRef<DialogGwIprestrictionComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }
  ngOnInit(): void {
    this.target_route = this.dialogData.target_route;
    if(this.target_route.is_iprestriction){
      this.getPlugin(this.target_route.kong_plugin_iprestriction_id);
    }
  }
  getPlugin(plugin_id){
    this.gwapi.getPlugin(plugin_id).subscribe((data: any) => {
      console.log(data)
      this.allow_list = data.config.allow;
      this.deny_list = data.config.deny;
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  updateIprestriction(){
    this.gwapi
      .updateIpRestriction(this.target_route.kong_route_id,this.allow_list,this.deny_list)
      .subscribe((data: any) => {
        this.spinner.hide();

        this.closeDialogMsg("success");
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    
  }
  removeRule(type,object){
    
    if(type == 'deny'){
      const idx = this.deny_list.indexOf(object) 
      if (idx > -1) this.deny_list.splice(idx, 1)
    }
    if(type == 'allow'){
      const idx = this.allow_list.indexOf(object) 
      if (idx > -1) this.allow_list.splice(idx, 1)
    }

  }
  addRule(){
    if(this.new_rule.type == 'deny'){
      this.deny_list.push(this.new_rule.cidr);
    }
    if(this.new_rule.type == 'allow'){
      this.allow_list.push(this.new_rule.cidr);
    }
  }
}
