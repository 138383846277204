<!-- Modal : 네트워크 인터페이스 모달 -->
<div id="interface_binding" class="modal2">
    <div class="modal_header">
        <h2>인터페이스 연결</h2>
    </div>
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width: 20%;" />
                        <col style="width: 60%;" />
                        <col style="width: 20%;" />
                    </colgroup>
                <tbody>
                    <tr *ngFor="let ip of selected_server.ip_private_data;let i = index">
                        <ng-template [ngIf]="i==0">
                            <th [attr.rowspan]="selected_server.ip_private_data.length" scope="row">연결된 <br>인터페이스</th>
                        </ng-template>
                        <td>{{ip.name}} ({{ip.addr}})</td>
                        <td>                                
                            <button class="btn sml dGray mg_r4" (click)="detachInterface(ip.addr)">삭제</button>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">연결할 <br>네트워크</th>
                        <td>
                            <select (change)="selectSubnet($event.target.value)">
                                <option>선택하세요.</option>
                                <option *ngFor="let subnet of subnetList" [value]="subnet.cidr">
                                    {{subnet.name}} ({{subnet.cidr}})
                                </option>
                            </select>
                        </td>
                        <td>
                            <button class="btn sml ciblue mg_r4" (click)="addInterface()">추가</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
        </div>
    </div>
</div>