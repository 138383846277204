import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-update-ip',
  templateUrl: './dialog-update-ip.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogUpdateIpComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogUpdateIpComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_floatingip;
  edit_fip_description: string="";
  ngOnInit(): void {

    this.selected_floatingip = this.dialogData.selected_floatingip
    this.edit_fip_description = this.selected_floatingip.description;
  }
  updateFloatingIp() {
    // console.log(this.edit_fip_description);
    this.spinner.show();
    this.osapi.updateFloatingIp(this.selected_floatingip.id,this.edit_fip_description)
    .subscribe((data: {}) => {
      this.spinner.hide();
      this.closeDialogMsg("success");
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
 
}
