<!-- Contents -->
<div id="Contents">
        <!-- content_header -->
        <div class="content_header col12">
            <h2>메세지큐 신청 관리</h2>
            <div class="lineMap">
                Service  / <span>messagequeue</span>
            </div>
        </div>
        <!-- //content_header -->
    
        <!-- content_body -->
        <div class="content_body">
            <form id="frm">
            <!-- section -->
            <section class="sec col12">
                <div class="sec_content">
                    <!-- lyBox -->
                    <!-- <div class="lyBox">
                        <div class="contArea" style="display:block"> 
                            <div class="boardBox">
                                <table class="tbl_row_tp1">
                                    <colgroup>
                                        <col style="width:20%;">
                                        <col style="width:80%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th scope="row">서비스 신청</th>
                                            <td>
                                                <button class="btn mid orange mg_r4" onclick="fn_modalOpen('serberbak_create')">백업 신청</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                    <!-- //lyBox -->
    
                    <!-- lyBox -->
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>메세지큐 신청 목록</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                    <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid dBlue"><i class="ico ico_search_white"></i>검색</button>
                                    </div>
                                    <a href="javascript:void(0)" class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                                </div>
                            </div>									
                        </div>
                        <div class="contArea" style="display:block"> 
                            <div class="boardBox">
                                <table class="tbl_col_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width:60px;">
                                        <col style="width:200px;">
                                        <col style="width:200px;">
                                        <col style="width:120px;">
                                        <col style="width:120px;">
                                    </colgroup>
                                    <thead>
                                        <th scope="col"><input type="checkbox" name="" id="checkbox"></th>
                                        <th scope="col">요청자</th>
                                        <th scope="col">상세정보</th>
                                        <th scope="col">신청일시</th>
                                        <th scope="col">처리현황</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input class="mycheckbox" type="checkbox" name="" id=""></td>
                                            <td>bzlinkersys</td>
                                            <td>-</td>
                                            <td>2020-07-20</td>
                                            <td><label class="btn tiny red radius">처리완료</label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1"></div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->
    
            </form>
        </div>
        <!-- //content_body -->
    
    </div>
    <!-- //Contents -->
    
    <!-- dropdown menu -->
    <div class="dropdown">
        <div id="myDropdown" class="dropdown-content">
            <a onclick="fn_modalOpen('dbms_status')">신청 처리</a>
            <a onclick="fn_modalMsg('신청내역을 삭제하시겠습니까?')">신청 삭제</a>
        </div>
    </div>
    
    
    <!-- Modal : 신청처리 팝업 모달 -->
    <div id="dbms_status" class="modal">
        <div class="modal_header">
            <h2>신청 처리
                <p class="sub-txt"> | 신청처리 팝업 텍스트 설명입니다. </p>
            </h2>
        </div>
        
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width:20%">
                        <col style="width:80%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">신청상태</th>
                            <td>
                                <select required>
                                    <option value="">-선택-</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">설명</th>
                            <td>
                                <textarea placeholder="내용을 입력해주세요"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp1">
                <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">확인</a>
                <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
            </div>
        </div>
    </div>
    
    <!-- Modal : msg 공통 -->
    <div class="modal modalMsg">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>삭제확인</h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="msg"></div>
            <div class="btnArea">
                <a class="btn big orange mg_r4" href="#close-modal" (click)="deleteService()"  rel="modal:close">삭제</a>
                <a class="btn big orange" href="#close-modal" rel="modal:close" >취소</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal --> 