import { Component, ViewChild, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { GWService } from '../../gwapi.service';
import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { FrontApiService } from '../../frontapi.service';
import { thistle } from 'color-name';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastComponent, ToastCloseArgs, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';
//import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { BasicNetworkData } from "./BasicNetworkData";
import { $, promise } from 'protractor';
import { GlobalService } from '../../global.service';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { AdminService } from '../../admin.service';
import { CephapiService } from '../../cephapi.service';
import { DialogSetService } from '../dialog/dialog-set.service';

//declare const $: any;

@Component({
  selector: 'app-console-nav',
  templateUrl: './console-nav.component.html',
  styleUrls: ['./console-nav.css']
})
export class ConsoleNavComponent implements OnInit {
  isProjectAdmin: boolean = false;
  isSubAdmin: boolean = false;
  message: any = {};
  memtype = ''

  /** START Project 관리 */
  projectList: any = [];
  companyId: any = {};
  resource: any = {};
  // userInfo: { projectId: string; userId: string; companyId: number };
  userInfo: any;
  // Test
  projectInfo: any;
  // 삭제 예정, resource로 통합
  resource2: any = {};
  pfip: string;
  pfid: string;
  emptyProject: boolean = false;

  currentPorjectName: string;
  changedProjectFlag:boolean=false;

  project_modal_name: string;
  project_modal_description: string;

  isCreateNetwork: boolean = false;
  isCreateSubnet: boolean = false;

  networkData = new BasicNetworkData();
  subnetData = new BasicNetworkData();

  /** END Project 관리 */

  /**
   * 20200911 cbkim
   * 서버리스트 미리 불러오기
   */
  serverList:any=[];
  tempServerList:any=[];

  isObjectStorageJoined: boolean = false;

  /**
   * Toast
   */
  @ViewChild('toasttype')
  public toastObj: ToastComponent;

  public position: ToastPositionModel = { X: "Right" };

  public onCreate = (): void => {
    // setTimeout((): void => {
    //   this.toastObj.show();
    // }, 200);
  }

  public showToastError = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-danger',
        // icon: 'e-error toast-icons'
      }
    );
  }

  public showToastSuccess = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-success',
        // icon: 'e-success toast-icons'
        // icon: 'fa fa-check'
      }
    );
  }

  public showToastInfo = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-info',
        // icon: 'e-info toast-icons'
      }
    );
  }

  public showToastWarning = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-warning',
        // icon: 'e-warning toast-icons'
      }
    );
  }

  public hideToast = (): void => {
    this.toastObj.hide('All');
  }

  toastTitle: string;
  toastMessage: string;
  toastType: string;
  /** END Toast */

  routeEvent(router: Router){
    router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.project_modal_name = "";
        this.getProjectAdminStatus();
        this.getSubAdminStatus();
        this.checkMemtype();
        this.getUserInfo();
      }
    });
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private frontapi: FrontApiService,
    private osapi: OsapiService,
    private gwapi: GWService,
    public global: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private actRoute: ActivatedRoute,
    public dialogs: DialogSetService,
    private api: AdminService,
    private cephapi: CephapiService,
  ) { }

  ngOnInit(): void {
    
    this.changedProjectFlag=true;
    
    setTimeout(() => {
      this.changedProjectFlag=false;
    }, 5000);
    
    this.routeEvent(this.router);
    this.project_modal_name = "";
    this.renderer.addClass(this.document.body, 'console');
    this.getProjectAdminStatus();
    this.getSubAdminStatus();
    this.checkMemtype();
    this.getUserInfo();
    
    this.getNetworkResourceList();

    this.getServerList();
    
    this.getObjectStorageJoined();

    this.frontapi.currentMessage.subscribe(message => {
      this.message = message;
      if (this.message.type == "toast") {
        this.toastType = this.message.body.type;
        this.toastTitle = this.message.body.title;
        this.toastMessage = this.message.body.message;
        switch (this.toastType) {
          case "success":
            this.showToastSuccess();
            break;
          case "info":
            this.showToastInfo();
            break;
          case "warning":
            this.showToastWarning();
            break;
          case "error":
            this.showToastError();
            break;
        }
      } else if (this.message.type == 'console-nav') {
        if (this.message.body.type == 'change project') {
          this.getProjectList();
        }
      } else if (this.message.type == 'logout') {
        this.webapi.blsLogout().subscribe(
          (data) => {
            this.router.navigate(['/member/login']);
          }
        )
      }
    },
      (error) => {
        this.spinner.hide();
      });
    this.getThemes();
    this.frontapi.currentMessage.subscribe((message:any) => {
      // console.log(message);
      if (message.type == "theme") {
        this.getThemes();
      }
    }, (error) => {

    });
  }



  isLoadedServer = false;
  async getServerList() {
    this.isLoadedServer = false;
    try {
      var server_data = await this.osapi.getServerList().toPromise();
      this.isLoadedServer = true;
      this.serverList = server_data;
      return this.serverList;
    } catch (err) {
      this.serverList = [];
      this.isLoadedServer = false;
      console.error(err);
      return false;
    }
  }

  isLoadedObjectStorageJoined = false;
  // companyDomainId:string = '';
  async getObjectStorageJoined() {
    this.isLoadedObjectStorageJoined = false;
    try {
      var check_service:any = await this.cephapi.checkService().toPromise();

      this.isLoadedObjectStorageJoined = true;
      this.isObjectStorageJoined = check_service.check;
      // this.companyDomainId = check_service.companyDomainId;
      return this.isObjectStorageJoined;
    } catch (err) {
      this.isObjectStorageJoined = false;
      this.isLoadedObjectStorageJoined = false;
      return false;
    }
  }

  outSendServerList() {
    this.tempServerList = this.getServerList();
    return this.tempServerList;
  }

  checkMemtype() {
    this.webapi.getMemType().subscribe((data) => {
      this.memtype = data.memType;
    });
  }
  checkMypage() {
    /**
     *  memType 0: 기업 1:개인
     *  isSub true: 서브계정 false: 기업관리자
     */
    this.webapi.getMemType().subscribe((data) => {
      if (data.memType == 0) {
        // 기업회원        
        if (data.isSub) {
          // 서브계정
          this.router.navigate(['/web/mypage/member'])
        } else {
          // 기업 관리자
          this.router.navigate(['/web/mypage/company']);
        }
      } else {
        // 개인회원        
        this.router.navigate(['/web/mypage/member'])
      }
    });
  }

  getProjectAdminStatus() {
    this.webapi.isProjectAdmin().subscribe(data => {
      this.isProjectAdmin = data.result
    },
      (error) => {
        console.error(error.status);
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }
  getSubAdminStatus() {
    this.webapi.isSubAdmin().subscribe(data => {
      this.isSubAdmin = data.result
    },
      (error) => {
        console.error(error.status);
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }
  navSendComp(message) {
    this.frontapi.changeMessage(message);
  }

  startServerNav() {
    this.global.selected_server_list.forEach(server => {
      this.osapi.startServer(server.server_id)
        .subscribe({
          error: (error) => {
            console.error(error.status);
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          }
        });
    });
  }
  stopServerNav() {
    this.global.selected_server_list.forEach(server => {
      this.osapi.stopServer(server.server_id)
        .subscribe({
          error: (error) => {
            console.error(error.status);
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          }
        });
    });
  }
  softRebootServerNav() {
    this.global.selected_server_list.forEach(server => {
      this.osapi.softRebootServer(server.server_id)
        .subscribe({
          error: (error) => {
            console.error(error.status);
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          }
        });
    });
  }
  deleteServerNav() {
    this.spinner.show();
    this.global.selected_server_list.forEach(server => {
      this.osapi.deleteServer(server.server_id).subscribe((data: {}) => {
        this.spinner.hide();
      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    });
  }

  /** START Project 관리 */
  onProjectChange(project_id) {
    try {
      this.spinner.show();
      
      this.osapi.switchProject(project_id).subscribe((data) => {
        this.changedProjectFlag=true;
        this.spinner.hide();
        
        setTimeout(() => {
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'info',
              title: '프로젝트 변경',
              message: '프로젝트가 변경되었습니다.'
            }
          });
        }, 1000);
        /**
         * 20200912 cbkim
         * 프로젝트 변경 시 서버리스트 리로드
         * 아오
         */
        // this.isLoadedServer = false;
        // this.getServerList();
        // this.router.navigate(['/console']);

        location.reload();

      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
      this.emptyProject = true;
      this.spinner.hide();
    }
  }

  deleteBLSProject() {
    const answer = confirm(
      '정말 삭제하시겠습니까? 프로젝트 내의 모든 자원(서버, 네트워크, 스토리지 등)이 삭제됩니다.'
    );
    if (answer) {
      this.spinner.show();
      try {
        this.osapi.deleteBLSProject().subscribe((data) => {
          this.getProjectList();
          this.onProjectChange(this.projectList[0].id);
          this.userInfo.projectId = this.projectList[0].id;
          this.spinner.hide();
        },
          (error) => {
            console.error(error.status);
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
      } catch (e) {
        this.getProjectList();
        this.onProjectChange(this.projectList[0].id);
        this.userInfo.projectId = this.projectList[0].id;
        this.spinner.hide();
      }
    }
  }
  isAdmin = false;
  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        }
        this.projectList = data.items;

        if (this.emptyProject) {
          this.onProjectChange(this.projectList[0].id);
          this.userInfo.projectId = this.projectList[0].id;
          this.emptyProject = false;
        }
      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      console.error(err);
    }
  }

  getNetworkResourceList() {
    this.spinner.show();

    this.osapi.getResourceAll().subscribe(
      (data: any[]) => {
        this.spinner.hide();
        this.resource = data;
      },
      (error) => {
        console.error(error.status);
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  getUserInfo() {
    try {
      this.osapi.getUserInfo().subscribe((data) => {
        this.userInfo = data;
        if(this.userInfo.userId == "bls@bizlinkersys.com"){
          this.isAdmin = true;
        }else{
          this.isAdmin = false;
        }
        this.getProjectList();
      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
  gw_already_used_flag = true;

  getGwAlreadyUsed(){
    this.gwapi.getAlreadyUsed().subscribe((data: any) => {
      this.gw_already_used_flag = data.result
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }

  /** END Project 관리 */

  openNewFloatingIpModal() {
    this.dialogs.openCreateIpDialog({});
    // this.global.openNewFloatingIpModal();
  }
  openNewStorageModal() {
    this.dialogs.openStorageCreateDialog({});
  }
  openNewKeypairModal() {
    this.dialogs.openKeypairCreateDialog({});
  }
  openNewSecurityGroupModal() {
    this.dialogs.openSecurityGroupCreateDialog({});
  }
  manageSecurityGroup(){
    this.router.navigate(['/console/securitygroup']);
  }
  
  openPortForwardingModal() {

    if(this.router.url.split(';')[0] === '/console/server'){
      this.router.navigateByUrl('/console', {
        skipLocationChange: true,
      }).then(
        () => {
          this.router.navigate(['/console/server',{action:'portfowarding'}]);
        }
      );
    }else{
      this.router.navigate(['/console/server',{action:'portfowarding'}]);
    }
    // this.dialogs.openServerPortforwardingUpdateDialog({
    //   serverLists:this.serverLists,
    //   resource:this.resource,
    //   portForwardingLists:this.portForwardingLists
    // });
  }
  
  openNewNetworkModal(){
    let networkModel : {  name: string, cidr: string,  type: boolean} = {name:"",cidr:"",type:true};
    // fn_modalOpen('network_create');
    this.dialogs.openNetworkCreateDialog({networkModel:networkModel,resource:this.resource});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){

      }else if(result == "fail"){
      }
    });
  }
  
  openSubnetCreateModal(){
    
    if(this.router.url.split(';')[0] === '/console/subnet'){
      this.router.navigateByUrl('/console', {
        skipLocationChange: true,
      }).then(
        () => {
          this.router.navigate(['/console/subnet',{action:'create_subnet'}]);
        }
      );
    }else{
      this.router.navigate(['/console/subnet',{action:'create_subnet'}]);
    }
  }
  openNasCreateModal(){
    this.dialogs.openNasCreateDialog({});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result?result.code == "success":false){

        if(this.router.url.split(';')[0] === '/console/nas'){
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigateByUrl('/console/nas');
            }
          );
        }
      }
    });
  }
  openNewProjectModal(){
    
    if(this.router.url.split(';')[0] === '/console/project'){
      this.router.navigateByUrl('/console', {
        skipLocationChange: true,
      }).then(
        () => {
          this.router.navigate(['/console/project',{action:'create_project'}]);
        }
      );
    }else{
      this.router.navigate(['/console/project',{action:'create_project'}]);
    }
    
  }
  openNewLB(){
    this.router.navigate(['/console/loadbalancer/create']);
  }
  /** END Project 관리 */

  getThemes() {
    this.api.getThemes().subscribe((data: {}) => {
      this.kinLookup(data);
    });
  }

  getThemeFont() {
    this.api.getThemes().subscribe((data: {}) => {
      this.kinLookupFont(data);
    });
  }

  kinLookup(gubn) {
    var i;
    var wrap = document.querySelector('.console');
    var leftMenu = document.querySelector('.menu_inner');
    var consolebody = document.querySelectorAll('.lyBox');
    var logo = document.querySelector('.logo');
    var modal_h = document.querySelectorAll('.modal_header');
    var modal_c = document.querySelectorAll('.modal_content');
    var leftFix = document.querySelectorAll('.leftFix');
    var tooltip_h = document.querySelectorAll('.tooltip-head');
    var tooltip_c = document.querySelectorAll('.tooltip-content');
    var tpa = document.querySelector('.tpA');
    var createBox = document.querySelectorAll('.createBox');
    var button = document.querySelectorAll('.btn.blue');
    var leftnoneBar = document.querySelectorAll('.subMenu');
    var leftBar = document.querySelectorAll('.on');
    var leftButton = document.querySelectorAll('.lnb');

    var _p = document.querySelectorAll('p');
    var _a = document.querySelectorAll('a');
    var _i = document.querySelectorAll('i');
    var _h2 = document.querySelectorAll('h2');
    var _h3 = document.querySelectorAll('h3');
    var _td = document.querySelectorAll('td');
    var _th = document.querySelectorAll('th');
    var _small = document.querySelectorAll('small');
    var _span = document.querySelectorAll('span');
    var _select = document.querySelectorAll('select');
    var _input = document.querySelectorAll('input');
    var _div = document.querySelectorAll('div');
    
    if(gubn == "1") { //default

    } else if(gubn == "2") { //dark

      wrap.classList.add('darkTheme');

      leftMenu.classList.add('seeTheme');
      
      tpa.classList.add('seeTheme');

      logo.classList.add('lightlogo');

      for(i=0;i<button.length;i++) {
        button[i].classList.add('darkButton');
      }
      for(i=0;i<leftFix.length;i++) {
        leftFix[i].classList.add('seeTheme');
      }
      for(i=0;i<consolebody.length;i++) {
        consolebody[i].classList.add('seeTheme');
      }
      for(i=0;i<tooltip_h.length;i++) {
        tooltip_h[i].classList.add('seeTheme');
        tooltip_c[i].classList.add('seeTheme');
      }
      for(i=0;i<modal_h.length;i++) {
        modal_h[i].classList.add('seeTheme');
        modal_c[i].classList.add('seeTheme');
      }
      for(i=0;i<createBox.length;i++) {
        createBox[i].classList.add('seeTheme');
      }
      for(i=0;i<_p.length;i++) {
        _p[i].style.color = '#eee';
      }
      for(i=0;i<_a.length;i++) {
        _a[i].style.color = '#eee';
      }
      for(i=0;i<_i.length;i++) {
        _i[i].style.color = '#eee';
      }
      for(i=0;i<_h2.length;i++) {
        _h2[i].style.color = '#eee';
      }
      for(i=0;i<_h3.length;i++) {
        _h3[i].style.color = '#eee';
      }
      for(i=0;i<_th.length;i++) {
        _th[i].style.color = '#eee';
        _th[i].style.background = '#222b45';
      }
      for(i=0;i<_td.length;i++) {
        _td[i].style.color = '#eee';
      }
      for(i=0;i<_span.length;i++) {
        _span[i].style.color = '#eee';
      }
      for(i=0;i<_small.length;i++) {
        _small[i].style.color = '#eee';
      }
      for(i=0;i<_select.length;i++) {
        _select[i].style.color = '#eee';
        _select[i].style.background = '#151a30';
        _select[i].style.border = '#151a30';
      }
      for(i=0;i<_input.length;i++) {
        _input[i].style.color = '#eee';
        _input[i].style.background = '#151a30';
        _input[i].style.border = '#151a30';
      }
      for(i=0;i<_div.length;i++) {
        _div[i].style.border = '0px';
      }
      for(i=0;i<leftnoneBar.length;i++) {
        leftnoneBar[i].classList.add('darkT');
      }
      for(i=0;i<leftBar.length;i++) {
        leftBar[i].classList.add('darkT');
      }
      for(i=0;i<leftButton.length;i++) {
        leftButton[i].classList.add('darkT');
      }

    } else if(gubn == "3") { //cosmic

      wrap.classList.add('navyTheme');

      leftMenu.classList.add('cosmicTheme');

      tpa.classList.add('cosmicTheme');

      logo.classList.add('lightlogo');

      for(i=0;i<button.length;i++) {
        button[i].classList.add('cosmicButton');
      }
      for(i=0;i<leftFix.length;i++) {
        leftFix[i].classList.add('cosmicTheme');
      }
      for(i=0;i<consolebody.length;i++) {
        consolebody[i].classList.add('cosmicTheme');
      }
      for(i=0;i<tooltip_h.length;i++) {
        tooltip_h[i].classList.add('cosmicTheme');
        tooltip_c[i].classList.add('cosmicTheme');
      }
      for(i=0;i<modal_h.length;i++) {
        modal_h[i].classList.add('cosmicTheme');
        modal_c[i].classList.add('cosmicTheme');
      }
      for(i=0;i<createBox.length;i++) {
        createBox[i].classList.add('cosmicTheme');
      }
      for(i=0;i<_p.length;i++) {
        _p[i].style.color = '#eee';
      }
      for(i=0;i<_a.length;i++) {
        _a[i].style.color = '#eee';
      }
      for(i=0;i<_i.length;i++) {
        _i[i].style.color = '#eee';
      }
      for(i=0;i<_h2.length;i++) {
        _h2[i].style.color = '#eee';
      }
      for(i=0;i<_h3.length;i++) {
        _h3[i].style.color = '#eee';
      }
      for(i=0;i<_th.length;i++) {
        _th[i].style.color = '#eee';
        _th[i].style.background = '#323259';
      }
      for(i=0;i<_td.length;i++) {
        _td[i].style.color = '#eee';
      }
      for(i=0;i<_span.length;i++) {
        _span[i].style.color = '#eee';
      }
      for(i=0;i<_small.length;i++) {
        _small[i].style.color = '#eee';
      }
      for(i=0;i<_select.length;i++) {
        _select[i].style.color = '#eee';
        _select[i].style.background = '#1b1b38';
        _select[i].style.border = '#1b1b38';
      }
      for(i=0;i<_input.length;i++) {
        _input[i].style.color = '#eee';
        _input[i].style.background = '#1b1b38';
        _input[i].style.border = '#1b1b38';
      }
      for(i=0;i<_div.length;i++) {
        _div[i].style.border = '0px';
      }
      for(i=0;i<leftnoneBar.length;i++) {
        leftnoneBar[i].classList.add('cosmicT');
      }
      for(i=0;i<leftBar.length;i++) {
        leftBar[i].classList.add('darkT');
      }
      for(i=0;i<leftButton.length;i++) {
        leftButton[i].classList.add('cosmicT');
      }
    }
  }

  kinLookupFont(gubn) {
    var i;
    var _td = document.querySelectorAll('td');
    var _th = document.querySelectorAll('th');
    var _a = document.querySelectorAll('a');
    var auth_theme = document.querySelectorAll('.auth_theme');

    if(gubn == "1") {

      for(i=0;i<auth_theme.length;i++) {
        auth_theme[i].classList.remove('cosmicTheme');
        auth_theme[i].classList.remove('seeTheme');
      }

    } else if(gubn == "2" || gubn == "3") {
     
      for(i=0;i<_td.length;i++) {
        _td[i].style.color = '#eee';
      }
      for(i=0;i<_th.length;i++) {
        _th[i].style.color = '#eee';
      }
      for(i=0;i<_a.length;i++) {
        _a[i].style.color = '#eee';
      }
      if(gubn == "2") {
        for(i=0;i<auth_theme.length;i++) {
          auth_theme[i].classList.remove('cosmicTheme');
          auth_theme[i].classList.add('seeTheme');
        }
      }
      if(gubn == "3") {
        for(i=0;i<auth_theme.length;i++) {
          auth_theme[i].classList.remove('seeTheme');
          auth_theme[i].classList.add('cosmicTheme');
        }
      }
    }
  }

  bannerClose = false;
}
