
<!-- Modal : 규칙수정 모달 -->
<div id="rule_modify" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>규칙 수정
            <p class="sub-txt"> | 보안규칙을 수정합니다. </p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">프로토콜</th>
                        <td>
                            <select [(ngModel)]="modRuleModalType" (change)="onRuleTypeChangeMod($event.target.value)" name="" required>
                                <option value="all">모든 프로토콜</option>
                                <option value="tcp">TCP</option>
                                <option value="udp">UDP</option>
                                <option value="icmp">ICMP</option>
                                <option value="ssh">SSH</option>
                                <option value="http">HTTP</option>
                                <option value="https">HTTPS</option>
                                <!-- <option value="ipv6-icmp">ipv6-icmp</option> -->
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <textarea [(ngModel)]="modifyRuleData.description" rows="5" placeholder="내용을 입력해주세요" maxlength="255"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">송/수신</th>
                        <td>
                            <select [(ngModel)]="modifyRuleData.direction" name="" required>
                                <option value="ingress" default>수신</option>
                                <option value="egress">송신</option>
                            </select>
                        </td>
                    </tr>
                    <!-- ICMP -->
                    <ng-template [ngIf]="modRuleModalType=='icmp'">
                        <tr>
                            <th scope="row">타입/코드</th>
                            <td>
                                <select [(ngModel)]="modRulePortType" (change)="onRulePortTypeChangeMod($event.target.value)" name="" required>
                                    <option value="all_icmp">모든 ICMP 통신</option>
                                    <option value="one_icmp">직접입력</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="modRulePortType =='one_icmp'">
                            <th scope="row">ICMP Type</th>
                            <td>
                                <input [(ngModel)]="modifyRuleData.from_port" (change)="onRuleFromPortChangeMod($event.target.value)" type="number" name="" id="" value="" placeholder="내용을 입력해주세요">
                            </td>
                        </tr>
                        <tr *ngIf="modRulePortType =='one_icmp'">
                            <th scope="row">ICMP Code</th>
                            <td>
                                <input [(ngModel)]="modifyRuleData.to_port" (change)="onRuleToPortChangeMod($event.target.value)" type="number" name="" id="" value="" placeholder="내용을 입력해주세요">
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template [ngIf]="modRuleModalType=='tcp' || modRuleModalType=='udp'">
                        <tr>
                            <th scope="row">포트</th>
                            <td>
                                <select [(ngModel)]="modRulePortType" (change)="onRulePortTypeChangeMod($event.target.value)" name="" required>
                                    <option value="one_port">단일포트</option>
                                    <option value="many_port">포트범위</option>
                                    <option value="all_port">모든포트</option>
                                </select>
                                <label *ngIf="modRulePortType =='all_port'">입력한 규칙에 모든 포트를 공개합니다. </label>
                            </td>
                        </tr>
                        <!-- 포트 일 때 -->
                        <tr *ngIf="modRulePortType =='one_port'">
                            <th scope="row">허용 포트</th>
                            <td>
                                <input (change)="onRuleFromPortChangeMod($event.target.value)" 
                                        (change)="onRuleToPortChangeMod($event.target.value)" 
                                        type="number" name="" id="" [(ngModel)]="modifyRuleData.from_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                        <!-- 포트범위 일 때 -->
                        <tr *ngIf="modRulePortType =='many_port'">
                            <th scope="row">시작</th>
                            <td>
                                <input [(ngModel)]="modifyRuleData.from_port" (change)="onRuleFromPortChangeMod($event.target.value)" type="number" name="" id="" [(ngModel)]="modifyRuleData.from_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                        <tr *ngIf="modRulePortType =='many_port'">
                            <th scope="row">끝</th>
                            <td>
                                <input [(ngModel)]="modifyRuleData.to_port" (change)="onRuleToPortChangeMod($event.target.value)" type="number" name="" id="" [(ngModel)]="modifyRuleData.to_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template [ngIf]="modRuleModalType!='all' && modRuleModalType!='tcp'&& modRuleModalType!='udp'&& modRuleModalType!='icmp'">
                        <tr>
                            <th scope="row">허용 포트</th>
                            <td>
                                <input readonly type="number" name="" id="" [(ngModel)]="modifyRuleData.from_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                    </ng-template>
                    
                    <!-- 공통 -->
                    <tr>
                        <th scope="row">CIDR </th>
                        <td>
                            <input [(ngModel)]="modifyRuleData.cidr" (change)="onRuleCidrChangeMod($event.target.value)" type="text" name="" id="" value="" placeholder="0.0.0.0/0">
                            <div *ngIf="mod_cidr_validate==false" class="invalid-feedback">
                                <div>유효하지않은 CIDR 입니다.</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="btnArea tp1">
            <a (click)="modifyRule()" class="btn big ciblue mg_r4">변경</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->