
<!-- Modal : 포트포워딩 모달 -->
<div id="port_forwarding" class="modal2 lg_modal">
        <!-- modal_header -->
        <div class="modal_header">
          <h2>포트포워딩 관리
            <p class="sub-txt">| 포트포워딩 규칙을 추가하거나 삭제할 수 있습니다.</p>
          </h2>
        </div>
        <!-- //modal_header -->
      
        <!-- modal_content -->
        <div class="modal_content">
          <div class="boardBox">
            <table class="tbl_col_tp1">
              <colgroup>
                <col style="width: 35%;"/>
                <col style="width: 18%;"/>
                <col style="width: 20%;"/>
                <col style="width: 18%;"/>
                <col style="width: 9%;"/>
              </colgroup>
              <thead>
                <th scope="col">대상 서버</th>
                <th scope="col">내부포트</th>
                <th scope="col">공인IP</th>
                <th scope="col">외부포트</th>
                <th scope="col">설정</th>
              </thead>
              <tbody>
      
                <!--신규 정보-->
                <tr>
                  <td>
                    <select name="" id="netinf" (change)="onChangeNew($event.target.value)">
                        <option value="">선택</option>
                        <option  *ngFor="let pf of pflist" [value]="pf.pf_id">
                            {{pf.server_name}} ({{pf.internal_ip_address}})</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" [(ngModel)]="selected_pf.internal_port" readonly />
                  </td>
                  <td>
                    <input type="text" [(ngModel)]="selected_pf.floatingip_ip" readonly />
                  </td>
                  <td>
                    <input placeholder="1030 ~ 49151" autocomplete="off" type="text" name="externalPort" [(ngModel)]="selected_pf.external_port" />
                  </td>
                  
                  <td>
                    <a class="btn sml ciblue mg_r4" (click)="createPortForwarding(selected_pf)">
                      신규 등록
                    </a>
                  </td>
                </tr>
                <!--신규 정보 End-->
              </tbody>
            </table>
            <br>
            <div class="modal_header">
               <p style="padding-left: 10px;" class="sub-txt">| 사용 중인 포트포워딩 정보입니다.</p> 
            </div>
            <table class="tbl_col_tp1">
              <colgroup>
                <col style="width: 20%;"/>
                <col style="width: 15%;"/>
                <col style="width: 18%;"/>
                <col style="width: 20%;"/>
                <col style="width: 18%;"/>
                <col style="width: 9%;"/>
              </colgroup>
              <thead>
                <th scope="col">서버명</th>
                <th scope="col">사설IP</th>
                <th scope="col">내부포트</th>
                <th scope="col">공인IP</th>
                <th scope="col">외부포트</th>
                <th scope="col">설정</th>
              </thead>
              <tbody>
                <!--기존 정보-->

                <ng-template [ngIf]="portForwardingLists.length==0"><tr><td colspan="6">등록된 정보가 없습니다.</td></tr></ng-template>
                
                <tr *ngFor="let port_forwarding of portForwardingLists">
                  <td>
                    <input [(ngModel)]="port_forwarding.server_name" type="text" value="{{ port_forwarding.server_name }}"
                      readonly />
                  </td>
                  <td>
                    <input [(ngModel)]="port_forwarding.internal_ip_address" type="text"
                      name="{{ port_forwarding.internal_ip_address }}" readonly />
                    <!-- <select>
                      <option>선택하세요.</option>
                      <option *ngFor="let net of selected_server.ip_private">{{net}}</option>
                    </select> -->
                  </td>
                  <td>
                    <input [(ngModel)]="port_forwarding.internal_port" type="text" name="{{ port_forwarding.internal_port }}"
                      readonly />
                  </td>
                  <td>
                    <input type="text" [value]="port_forwarding.fip" readonly />
                    <!-- <input type="text" *ngIf="pfip" [name]="pfip" readonly /> -->
                  </td>
                  <td>
                    <input [(ngModel)]="port_forwarding.external_port" type="text" name="{{ port_forwarding.external_port }}"
                      readonly />
                  </td>
                  <td>
                    <a class="btn sml dGray" (click)="deletePortForwarding2(port_forwarding)">삭제</a>
                  </td>
                </tr>
      
              </tbody>
            </table>
          </div>
          <div class="btnArea tp1">
            <!-- <a
              href="#close-modal"
              (click)="createPortForwarding()"
              rel="modal:close"
              class="btn big ciblue mg_r4"
              >적용</a
            > -->
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
          </div>
        </div>
        <!-- //modal_content -->
      </div>
      <!-- //Modal -->