import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import {ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-notice-view',
  templateUrl: './notice-view.component.html',
})

export class NoticeViewComponent implements OnInit {
    
  noticeId:String = "";  
  noticeView: any = [];
  
  constructor(
    private webapi: WebApiService, 
    private activatedRoute: ActivatedRoute,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.noticeId = params['noticeId'];
    });
    history.replaceState({}, null, location.pathname);
  }

  ngOnInit(): void {
    this.getNoticeView();
  }

  getNoticeView(){
    this.spiner.show();
    this.webapi.getNoticeView(this.noticeId).subscribe(
      (response) => {
        const data = response.body
        this.noticeView = data['result'][0];
        this.noticeView['createdDate'] = this.noticeView['createdDate'].split("T")[0]

      }, (err) => {

      }
    );
    this.spiner.hide();
  }
  
  downloadFile(){
    location.href = "/webapi/articles/download/"+this.noticeView.attachedFilePath
  }
}
