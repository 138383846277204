import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-dialog-subuser-create',
  templateUrl: './dialog-subuser-create.component.html',
  styleUrls: ['../dialog-common.css'],
  encapsulation : ViewEncapsulation.None,
})
export class DialogSubuserCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSubuserCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  selected_project_list = new FormControl();

  projectList: any = [];

  projectEnable: boolean = true;

  name_errMsg: any = '';
  id_errMsg: any = '';
  pw_errMsg: any = '';
  project_errMsg: any = '';
  
  createForm: any = {
    type: '1',
    userName: '',
    userId: '',
    password: '',
    project: []
  };

  ngOnInit(): void {

    this.projectList = this.dialogData.projectList;
    this.createForm['project'] = this.dialogData.projectList[0].id;
  }
  changeList(set){

  }
  onChange(target) {
    // if (target.tagName === 'SELECT') {
    //   this.createForm['projects'] = [];
    //   for (let i = 0; i < target.options.length; i++) {
    //     if (target.options[i].selected) {
    //       this.createForm['projects'].push(target.options[i].value);
    //     }
    //   }
    // } else {
    //   this.createForm[target.name] = target.value;
    // }

    this.createForm[target.name] = target.value;

    // if (target.name == 'type') {
    //   if (target.value == '2') {
    //     this.createForm['project'] = '';
    //   } else {
    //     this.createForm['project'] = this.projectList[0].id;
    //   }
    // }

    // validation ------------

    // Email - ID
    if (target.name == 'userId') {
      var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      this.id_errMsg = '';

      if (this.createForm.userId == '') {
        this.id_errMsg = '이메일을 입력해 주세요.';
      } else if (!emailVal.test(this.createForm.userId)) {
        this.id_errMsg = '이메일 형식이 아닙니다.';
      }
    }
    // Email - ID
    // -------------------------
    // password
    if (target.name == 'password') {
      // var passwordVal = /^([a-z])([A-Z])(d)([$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{8, 20}/
      var passwordVal1 = /[A-Z]/;
      var passwordVal2 = /[0-9]/;
      var passwordVal3 = /[!@#$%^&*()_\[+=\]{}~\?:;`|]/;

      this.pw_errMsg = '';
      if (this.createForm.password == '') {
        this.pw_errMsg = '비밀번호를 입력해 주세요.';
      } else if (this.createForm.password.length < 8) {
        this.pw_errMsg = '8자리 이상 입력해 주세요. (최대 20자)';
      } else if (
        !passwordVal1.test(this.createForm.password) ||
        !passwordVal2.test(this.createForm.password) ||
        !passwordVal3.test(this.createForm.password)
      ) {
        this.pw_errMsg =
          '영문 대문자, 숫자, 특수문자를 1개씩 이상 포함하여 8자리 이상 입력해주세요. (최대 20자)';
      }
    }
    // password

    // validation ------------


  }
  

  
  createSubUser() {
    // validation체크 성공유무 -true초기화
    var valChkPass: boolean = true;

    //이름 validation
    if(this.createForm.userName == ''){
      this.name_errMsg = '이름을 입력해 주세요.';
      valChkPass = false;
    }
    // 이메일 validation
    var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (this.createForm.userId == '') {
      this.id_errMsg = '이메일을 입력해 주세요.';
      valChkPass = false;
    } else if (!emailVal.test(this.createForm.userId)) {
      this.id_errMsg = '이메일 형식이 아닙니다.';
      valChkPass = false;
    }

    // 비밀번호 validation
    var passwordVal1 = /[A-Z]/;
    var passwordVal2 = /[0-9]/;
    var passwordVal3 = /[!@#$%^&*()_\[+=\]{}~\?:;`|]/;

    if (this.createForm.password == '') {
      this.pw_errMsg = '비밀번호를 입력해 주세요.';
      valChkPass = false;
    } else if (this.createForm.password.length < 8) {
      this.pw_errMsg = '8자리 이상 입력해 주세요. (최대 20자)';
      valChkPass = false;
    } else if (
      !passwordVal1.test(this.createForm.password) ||
      !passwordVal2.test(this.createForm.password) ||
      !passwordVal3.test(this.createForm.password)
    ) {
      this.pw_errMsg =
        '영문 대문자, 숫자, 특수문자를 1개씩 이상 포함하여 8자리 이상 입력해주세요. (최대 20자)';
      valChkPass = false;
    }

    if(this.selected_project_list.value.length < 1) {
      this.project_errMsg = '사용 할 프로젝트를 선택해주세요.'
      valChkPass = false;
    }
    this.createForm.project = this.selected_project_list.value;
    if (valChkPass) {

      this.spinner.show();
      this.osapi.createSubUser(this.createForm).subscribe((data: any) => {
        this.spinner.hide();
        // this.subUsers.push(data);
        // alert('저장되었습니다.');
        // (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
        this.closeDialogMsg("success");
        
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigate(['/console/subuser']);
          }
        );
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
    }
  }

}
