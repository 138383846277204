<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Storage</li>
      <li class="active">NAS</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let nas of nasLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons"
                          [class]="nas.status === 'available' ? 'dotActive' : 'dotStop'">power_input</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{nas.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{{nas.name}}</td>
                    </tr>
                    <tr>
                      <th>설명</th>
                      <ng-template [ngIf]="nas.description==null || nas.description==''">
                        <td>없음</td>
                      </ng-template>
                      <ng-template [ngIf]="nas.description">
                        <td>{{ nas.description }}</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <th>크기</th>
                      <td>{{nas.size}} GB</td>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>
                        <label class="dot green"></label>
                        {{ nas.status | uppercase }}
                      </td>
                    </tr>
                    <tr>
                      <th>접속경로</th>
                      <td>
                        <div class="runbox">
                          <div style="-webkit-user-select: text;-moz-user-select: text;-ms-user-select: text;user-select: text;" class="lang" id="copyText">{{ nas.export_locations.path||"".substring(0,20)+"..." }}</div>
                          <button class="btn tiny bor_gray" (click)="clipboardCopy($event)" title="Copy"></button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>