
<!-- Modal : 네트워크 SNAT 관리 모달 -->
<div id="network_sant" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        SNAT 관리
        <p class="sub-txt">해당 네트워크의 SNAT 사용 설정을 할 수 있습니다. '사용'으로 변경 시 공인IP가 할당되고 요금이 부과됩니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th>SNAT 사용 여부</th>
              <td>
                <input type="radio" name="field_snat_type" id="radio_snat_type_able"
                  (click)="setSantState(true)" /><label for="radio_snat_type_able"
                  class="mg_r8">사용</label>
                <input type="radio" name="field_snat_type" id="radio_snat_type_disable"
                  (click)="setSantState(false)" /><label for="radio_snat_type_disable"> 사용안함</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp1">
        <a (click)="updateRouterSnatState()" class="btn big ciblue mg_r4" >적용</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->