<table style="margin-bottom:10px;">
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">네트워크</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{networkList.length + networkExtList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                        <td>{{networkList.length + networkExtList.length}}</td>
                    </tr>
                    <tr>
                        <th>External</th>
                        <td>:</td>
                        <td>{{networkExtList.length}}</td>
                    </tr>
                    <tr>
                        <th>Internal</th>
                        <td>:</td>
                        <td>{{networkList.length}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">서브넷</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{subnetList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                        <td>{{subnetList.length}}</td>
                    </tr>
                    <!-- <tr>
                        <th><label class="dot green"></label>ACTIVE</th>
                        <td>:</td>
                        <td>{{subnetActive}}</td>
                    </tr>
                    <tr>
                        <th><label class="dot red"></label>STOP</th>
                        <td>:</td>
                        <td>{{subnetList.length-subnetActive}}</td>
                    </tr> -->
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">공인 IP</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{fipList.length + pfList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <!-- <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                        <td>342</td>
                    </tr> -->
                    <tr>
                        <th>공인 IP</th>
                        <td>:</td>
                        <td>{{fipList.length}}</td>
                    </tr>
                    <tr>
                        <th>포트포워딩</th>
                        <td>:</td>
                        <td>{{pfList.length}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">보안그룹</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{sgList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                        <td>{{sgList.length}}</td>
                    </tr>
                    <!-- <tr>
                        <th><label class="dot green"></label>ACTIVE</th>
                        <td>:</td>
                        <td>340</td>
                    </tr>
                    <tr>
                        <th><label class="dot red"></label>STOP</th>
                        <td>:</td>
                        <td>2</td>
                    </tr> -->
                </table>
            </div>
        </div>
    </div>
</table>