  <div class="container">
    <div class="header pt_30">
      <ol class="breadcrumb">
        <li>Server</li>
        <li class="active">Instances</li>
      </ol>
    </div>
    <div class="section">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div *ngIf="_blank; else noneData;">
            <div class="card" *ngFor="let serverList of serverLists">
              <div class="card-content">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title class="mFonts">
                          <i class="material-icons"
                            [class]="serverList.status === 'ACTIVE' ? 'dotActive' : 'dotStop'">filter_drama</i>
                      </mat-panel-title>
                      <mat-panel-title class="mFonts">
                        {{serverList.server_name}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="tbl_dtl_tp2">
                        <tbody>
                          <tr>
                            <th>서버명</th>
                            <td>{{serverList.server_name}}</td>
                          </tr>
                          <tr>
                            <th>이미지명</th>
                            <td>{{serverList.image_name}}</td>
                          </tr>
                          <tr>
                            <th>서버유형</th>
                            <td>{{serverList.flavor_name}}</td>
                          </tr>
                          <tr>
                            <th>상태</th>
                            <td>
                              <label [class]="serverList.status === 'ACTIVE' ? 'dot green':'dot red'"></label>
                              {{serverList.status}}
                            </td>
                          </tr>
                          <tr>
                            <th>사설IP</th>
                            <td *ngFor="let item of serverList.ip_private">
                              {{item}}
                            </td>
                          </tr>
                          <tr>
                            <th>공인IP</th>
                            <td *ngIf="serverList.ip_public != null;else emptyPublicIP">
                              {{serverList.ip_public}}
                            </td>
                            <ng-template #emptyPublicIP>
                              <td *ngIf="serverList.is_port_forwarding;else noPortFowarding">
                                <ng-template [ngIf]= "serverList.port_forwarding_ip">
                                    포트포워딩
                                    <p>{{ serverList.port_forwarding_ip }}:{{ serverList.port_forwarding_port }}</p>
                                </ng-template>
                                <ng-template [ngIf]= "!serverList.port_forwarding_ip">
                                    로딩 중..
                                </ng-template>
                              </td>
                              <ng-template #noPortFowarding>
                                <td>할당되지 않음</td>
                              </ng-template>
                            </ng-template>
                          </tr>
                          <tr>
                            <th>원격허용</th>
                            <td>
                              <label [class]="serverList.is_remote_access?'dot green':'dot red'"></label>
                              {{serverList.remote_status_msg}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card-content"> 
                        <button class="waves-effect waves-light btn darken white-text"
                        (click)="openDialogStart(serverList.server_name, serverList.server_id)"
                        [disabled]="serverList.status != 'SHUTOFF'">시작</button>
                        <button class="waves-effect waves-light btn materialize-red darken-2 white-text"
                        (click)="openDialogStop(serverList.server_name, serverList.server_id)"
                        [disabled]="serverList.status != 'ACTIVE'">정지</button>
                        <button class="waves-effect waves-light btn darken white-text"
                        (click)="openDialogRestart(serverList.server_name, serverList.server_id)"
                        [disabled]="serverList.status != 'ACTIVE'">재시작</button>
                      </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
          <ng-template #noneData>
            <h5 class="none_data">데이터 없음</h5>
          </ng-template>
        </div> 
      </div>
    </div>
    <div class="section">
      <div class="row">
      </div>
    </div>
    <br><br>
    <div class="section">
    </div>
  </div>