<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Network</li>
      <li class="active">LoadBalancer</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let loadbalancer of loadbalancerLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons"
                          [class]="loadbalancer.provisioning_status === 'ACTIVE' ? 'dotActive' : 'dotStop'">subject</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{loadbalancer.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{{loadbalancer.name}}</td>
                    </tr>
                    <tr>
                      <th>사설 IP</th>
                      <td>{{loadbalancer.vip_address}}</td>
                    </tr>
                    <tr>
                      <th>공인 IP</th>
                      <td *ngIf="loadbalancer.is_floating_ip; else elseFIP">
                        {{loadbalancer.floating_ip.ip}}
                      </td>
                      <ng-template #elseFIP>
                        <td>없음</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <th>멤버 상태</th>
                      <td>
                        <label
                        [class]="loadbalancer.operating_status_ko == 'ONLINE'? 'dot green'
                        : loadbalancer.operating_status == 'WARNING' ? 'dot yellow' : 'dot red'"></label>
                        {{loadbalancer.operating_status_ko}}
                      </td>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>
                        <label [class]="loadbalancer.provisioning_status == 'ACTIVE'? 'dot green': 'dot red'"></label>
                        {{loadbalancer.provisioning_status}}
                      </td>
                    </tr>
                    <tr>
                      <th>리스너</th>
                      <td *ngIf="!loadbalancer.listener">
                          없음
                      </td>
                      <td *ngFor="let listener of loadbalancer.listener">
                          {{listener.protocol }} {{listener.protocol_port}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>