<div id="Contents">
	<div class="content_header col12">
		<h2>네트워크</h2>
		<div class="lineMap">
			Network / <span>Network</span>
		</div>
	</div>
	<div class="content_body">
		<section class="sec col12">
			<div class="sec_content">
				<div class="lyBox">
					<div class="title_wrap">
						<div class="contArea">
							<div class="tit">
								<app-console-projects></app-console-projects>
							</div>
							<div class="cont">
								<app-console-network-status></app-console-network-status>
							</div>
							<div class="contSub">
								<button class="btn mid blue mg_r4" (click)="openNewNetworkModal()"><i
										class="ico ico_register_white"></i>네트워크 생성</button>
								<button class="btn mid ciblue mg_r4" routerLink="/console/subnet">서브넷목록</button>
								<button class="btn mid ciblue mg_r4" [disabled]="!isActived"
									(click)="openNetworkSnatModal()">SNAT 관리</button>
								<button class="btn mid ciblue mg_r4" [disabled]="!isActived"
									(click)="openNetworkExternalModal()">외부 연결 관리</button>
								<button class="btn mid blue mg_r4" (click)="openCreateSubnetModal()">
									<i class="ico ico_register_white"></i>서브넷 생성</button>
							</div>
						</div>
					</div>
				</div>
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>네트워크 목록</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" name="" [(ngModel)]="filterText" id="search"
									value="" placeholder="검색어를 입력해주세요" />
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid ciblue"><i
											class="ico ico_search_white"></i>검색</button>
								</div>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block">
						<div class="boardBox">
							<table class="tbl_col_tp1 dropmenu pdTp2">
								<colgroup>
									<col style="width: 5%;">
									<col style="width: 15.8%;">
									<col style="width: 15.8%;">
									<col style="width: 15.8%;">
									<col style="width: 15.8%;">
									<col style="width: 15.8%;">
									<col style="width: 15.8%;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">
										</th>
										<th scope="col">이름</th>
										<th scope="col">CIDR</th>
										<th scope="col">연관된 서브넷</th>
										<th scope="col">외부 연결 상태</th>
										<th scope="col">SNAT 사용여부</th>
										<th scope="col">상태</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of networkList  | tableFilter: filterText"
										(contextmenu)="onClickList(item)">
										<td><input type="checkbox" name="sel_chkbox" (change)="onListCheck(item)"
												[attr.data-id]="item.id">
										</td>
										<td>{{item.name}}</td>
										<td>{{item.cidr}}</td>
										<td>
											<p *ngFor="let subnet of item.subnets">{{subnet.name}}({{subnet.cidr}})</p>
										</td>
										<td *ngIf="isBool(item.router_state); else elseExt">
											{{item.router_state == true? '허용':'차단'}}</td>
										<ng-template #elseExt>
											<td>내부 네트워크</td>
										</ng-template>

										<td>{{item.external_gateway_info.enable_snat == true? '사용' : '사용 안함'}}</td>
										<td><label
												[class]="item.status === 'ACTIVE' ? 'dot green' : 'dot red'"></label>{{item.status}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
<div class="dropdown">
	<div id="myDropdown" class="dropdown-content">
		<a routerLink="/console/subnet">서브넷 목록</a>
		<a (click)="openDeleteNetworkModal()" *ngIf="!selected_network.isDefaultNetwork; else elseDeleteDisable">
			네트워크 삭제</a>
		<ng-template #elseDeleteDisable>
			<a disabled>네트워크 삭제</a>
		</ng-template>
		<ng-template [ngIf]="isBool(selected_network.router_state)">
			<a (click)="openNetworkExternalModal()">외부 연결 관리</a>
		</ng-template>
		<ng-template [ngIf]="!isBool(selected_network.router_state)">
			<a disabled>외부 연결 관리</a>
		</ng-template>
		<a (click)="openNetworkSnatModal()">SNAT 관리</a>
	</div>
</div>