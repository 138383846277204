import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GWService } from '../../gwapi.service';
import { OsapiService } from '../../osapi.service';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalService } from '../../global.service';
import { FrontApiService } from '../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-consol-apigatewaye-api-setting',
  templateUrl: './console-apigateway-api-setting.component.html',
})

export class ConsoleApigatewayApiSettingComponent implements OnInit {
  @ViewChild('step1') step1: ElementRef;
  @ViewChild('step2') step2: ElementRef;
  @ViewChild('step3') step3: ElementRef;
  @ViewChild('step4') step4: ElementRef;
  @ViewChild('step5') step5: ElementRef;
  


  constructor(
    private gwapi: GWService,
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    private formbuilder: FormBuilder,
    // private sanitizer: DomSanitizer,
    public global: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private dialogs: DialogSetService,
    @Inject(ConsoleNavComponent) private parent:ConsoleNavComponent
  ) {
  }

  using_lb=false;
  using_detail=false;
  using_health_check=false;

  base_uri="";
  create_api_object={
    name:"",
    protocol:"http",
    path:"",
    lb_algorithm:"round-robin",
    targets:[],
    retries:5,
    connect_timeout:60000,
    write_timeout:60000,
    read_timeout:60000,
    
  }

  lb_target={
    host:"",
    port:80,
    weight:10
  };
  use_lb_targets=[];
  
  message:any={};

  addTarget(){
    this.use_lb_targets.push(this.lb_target);
    this.lb_target={
      host:"",
      port:80,
      weight:10
    };
  }
  removeTarget(object){
    const idx = this.use_lb_targets.indexOf(object) 
    if (idx > -1) this.use_lb_targets.splice(idx, 1)
  }
  ngOnInit(): void {
    
  }

  getRegistrationInfo(){
    this.gwapi.getRegistrationInfo().subscribe((data: any) => {
      console.log(data)
      if(data){
        this.base_uri = data.uri;
      }
    }, 
    (error) => {
    });
  }
  createAPI() {
    if(!this.using_lb){
      this.use_lb_targets=[];
      this.use_lb_targets.push(this.lb_target);
    }
    this.create_api_object.targets = this.use_lb_targets;
    //targets 가 비어있으면 예외처리
    

    if(this.create_api_object.path.indexOf('//') !== -1 ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "경로의 값을 확인해주시기 바랍니다."
        }
      });
      return;
    }

    if(this.create_api_object.path.length == 0 || this.create_api_object.path[0] !='/'){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "경로의 값을 확인해주시기 바랍니다."
        }
      });
      return;
    }
    if(this.create_api_object.name == null ||this.create_api_object.name == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '이름을 입력해주세요.'
        }
      });
      return;
    }
    const name_filter = /^[0-9a-zA-Z\-_]*$/
    if(name_filter.test(this.create_api_object.name)==false ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "이름은 영문자와 숫자, '-', '_'만 입력 가능합니다."
        }
      });
      return;
    }
    if(this.create_api_object.targets == null ||this.create_api_object.targets.length==0 ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "호스트를 추가해야합니다."
        }
      });
      return;
    }

    for(let target of this.use_lb_targets){
      if(target.port<0 ||target.port>65535 ){
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'warning',
            title: '경고',
            message: "사용가능한 포트범위는 0 ~ 65535 입니다."
          }
        });
        return;
      }
    }

    if(this.create_api_object.retries<0 || this.create_api_object.retries >32767){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "재시도 회수의 범위는 0 ~ 32767 입니다."
        }
      });
      return;
    }
    if(this.create_api_object.connect_timeout<0 || this.create_api_object.connect_timeout >2147483646){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "Timeout의 범위는 0 ~ 2147483646 입니다."
        }
      });
      return;
    }
    if(this.create_api_object.read_timeout<0 || this.create_api_object.read_timeout >2147483646){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "Timeout의 범위는 0 ~ 2147483646 입니다."
        }
      });
      return;
    }
    if(this.create_api_object.write_timeout<0 || this.create_api_object.write_timeout >2147483646){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "Timeout의 범위는 0 ~ 2147483646 입니다."
        }
      });
      return;
    }


    this.gwapi
      .createAPI(
        this.create_api_object.name,
        this.create_api_object.targets,
        this.create_api_object.path,
        this.create_api_object.protocol,
        this.create_api_object.lb_algorithm,
        this.create_api_object.retries ,
        this.create_api_object.connect_timeout,
        this.create_api_object.write_timeout,
        this.create_api_object.read_timeout,
        ).subscribe((data: any) => {
        this.spinner.hide();
        this.router.navigate(['/console/apigateway/api']);
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }

}
