import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-snapshot-update',
  templateUrl: './dialog-storage-snapshot-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageSnapshotUpdateComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageSnapshotUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_snapshot:any;

  editSnapshotData: {
    name:string,
    description:string,
  } ={
    name:"",
    description:""
  };
  
  ngOnInit(): void {

    this.selected_snapshot = this.dialogData.selected_snapshot;
    this.editSnapshotData.name = this.selected_snapshot.name;
    this.editSnapshotData.description = this.selected_snapshot.description;
  }

  
  updateVolumeSnapshot(){
    if(this.selected_snapshot==null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      return;
    }
    if(this.editSnapshotData.name ==""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi.updateVolumeSnapshot(this.selected_snapshot.id,this.editSnapshotData.name,this.editSnapshotData.description).subscribe(
      (data:any) => {

        this.spinner.hide();
        this.closeDialogMsg("success");
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigate(['/console/snapshot']);
          }
        );
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
