import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OsapiService } from '../../osapi.service';
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

import { DialogSetService } from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function valiAllChk(params: any);

@Component({
  selector: 'app-console-image-list',
  templateUrl: './console-image-list.component.html',
})
export class ConsoleImageListComponent implements OnInit {
  imageList: any = [];
  serverList: any = [];
  tempImageList: any = [];

  is_server_list_load: boolean = false;
  // 검색 텍스트
  filterText: string;

  isActived: boolean = false;
  isChecked: boolean = false;

  selected_image: any = {};
  panelOpenState: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private http: HttpClient,
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    private global: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialogs: DialogSetService,
    @Inject(ConsoleNavComponent) private parent: ConsoleNavComponent
  ) { }

  ngOnInit(): void {

    this.spinner.show();
    this.renderer.addClass(this.document.body, 'bg_blue');
    this.getImageList()
    this.is_server_list_load = false;
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(image) {
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    this.isActived = false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
      if (chkedbox.getAttribute('data-id') == image.image_id) {
        chkedbox.checked = true;
        this.onClickList(image);
        this.isActived = true;
      }
    }
  }

  onClickList(image) {
    this.isActived = true;
    this.selected_image = image;
  }
  openCreateImageModal() {
    if (!this.is_server_list_load) {
      this.spinner.show();
      setTimeout(() => {
        this.openCreateImageModal();
      },
        500);
    } else {
      this.dialogs.openServerImageCreateDialog({ serverList: this.serverList });
    }
  }
  openModifyImageModal() {
    this.dialogs.openServerImageUpdateDialog({ selected_image: this.selected_image });
  }

  openDeleteImageModal() {
    this.dialogs.openDefaultDeleteDialog({
      title: "서버 이미지 삭제",
      content: "선택하신 이미지(" + this.selected_image.name + ")를 정말로 삭제하시겠습니까?<br>한 번 삭제한 이미지는 복구할 수 없습니다."
    });
    // this.global.openNewFloatingIpModal();
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.deleteImage();
      }
    });
  }

  getImageList() {
    this.spinner.show();
    //TODO - 현재 로그인되어있는 프로젝트명을 가져오는 로직 필요 
    this.osapi.getCurrentProjectImageList().subscribe(
      (res: any) => {

        this.getServerList();
        let data = res.body;

        //console.log(data);

        // for(let image of data){
        //   if(image.properties.default_os_image =="true"){
        //     this.imageList.push(image);
        //   }
        // }

        for(let image of data){
          image.size = (image.size / 1024 / 1024 / 1024).toFixed(2) + " GB";
        }
        this.imageList = data;

        // for(let image of this.imageList){
        //   let bm = JSON.parse(image.properties.block_device_mapping);
        //   console.log(bm);
        // }
        this.parent.getThemeFont();
        this.spinner.hide();
        this.reloadImageStatusCheck();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    );
  }

  getServerList() {
    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList();
      }, 200)
    } else {
      this.serverList = this.parent.serverList;
      this.is_server_list_load = true;
    }
  }

  //Status 자동갱신
  reloadImageStatusCheck() {
    let reload_flag = false;
    for (let image of this.imageList) {
      if (image.status != "active" && image.status != "error") {
        reload_flag = true;
      }
    }
    if (reload_flag) {
      // this.getImageList();
    }
  }
  restoreImage() {
    this.router.navigate(['/console/server/create', { restoreImage: this.selected_image.image_id }]);
  }

  deleteImage() {
    let image_block_devices = null;
    try{
      image_block_devices = JSON.parse(this.selected_image.properties.block_device_mapping);
    }catch(e){

    }
    let snapshot_id="";
    if(Array.isArray(image_block_devices)){
      const image_block_device = image_block_devices.find(e=> e.device_name=="/dev/vda");
      if(image_block_device){
        snapshot_id = image_block_device.snapshot_id;
      }
    }
    this.spinner.show();
    this.osapi.deleteImage(this.selected_image.image_id).subscribe(
      (data: {}) => {
        if(snapshot_id){
          this.osapi.deleteVolumeSnapshot(snapshot_id).subscribe(
            (data: {}) => {
              
            }
          );
        }
        this.spinner.hide();
        this.getImageList();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

}
