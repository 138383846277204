<div class="row">
	<div class="col-lg-12">
        <div class="card-action">
            <h5 class="pb-30">규칙 추가</h5>
        </div>
        <div class="card-content">
            <div>
                <mat-form-field appearance="legacy">
                    <mat-label>프로토콜</mat-label>
					<select matNativeControl [(ngModel)]="modRuleModalType" (change)="onRuleTypeChangeMod($event.target.value)" name="" required>
                        <option value="all">모든 프로토콜</option>
                        <option value="tcp">TCP</option>
                        <option value="udp">UDP</option>
                        <option value="icmp">ICMP</option>
                        <option value="ssh">SSH</option>
                        <option value="http">HTTP</option>
                        <option value="https">HTTPS</option>
                    </select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="legacy">
                    <mat-label>설명</mat-label>
                    <input matInput [(ngModel)]="modifyRuleData.description" rows="5" placeholder="내용을 입력해주세요" maxlength="255">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="legacy">
                    <mat-label>송/수신</mat-label>
					<select matNativeControl [(ngModel)]="modifyRuleData.direction" name="" required>
                        <option value="ingress">수신</option>
                        <option value="egress">송신</option>
                    </select>
                </mat-form-field>
            </div>
            <!-- ICMP -->
            <ng-template [ngIf]="modRuleModalType=='icmp'">
                <div>
                    <mat-form-field appearance="legacy">
                        <mat-label>타입/코드</mat-label>
						<select matNativeControl [(ngModel)]="modRulePortType" (change)="onRulePortTypeChangeMod($event.target.value)" name="" required>
                            <option value="all_icmp">모든 ICMP 통신</option>
                            <option value="one_icmp">직접입력</option>
                        </select>
                    </mat-form-field>
                </div>
                <div *ngIf="modRulePortType =='one_icmp'">
                    <mat-form-field appearance="legacy">
                        <mat-label>ICMP Type</mat-label>
						<input matInput [(ngModel)]="modifyRuleData.from_port" (change)="onRuleFromPortChangeMod($event.target.value)" name="" id="" value="" placeholder="내용을 입력해주세요">
                    </mat-form-field>
                </div>
                <div *ngIf="modRulePortType =='one_icmp'">
                    <mat-form-field appearance="legacy">
                        <mat-label>ICMP Code</mat-label>
						<input matInput [(ngModel)]="modifyRuleData.to_port" (change)="onRuleToPortChangeMod($event.target.value)" name="" id="" value="" placeholder="내용을 입력해주세요">
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template [ngIf]="modRuleModalType=='tcp' || modRuleModalType=='udp'">
                <div>
                    <mat-form-field appearance="legacy">
                        <mat-label>포트</mat-label>
						<select matNativeControl [(ngModel)]="modRulePortType" (change)="onRulePortTypeChangeMod($event.target.value)" name="" required>
                            <option value="one_port">단일포트</option>
                            <option value="many_port">포트범위</option>
                            <option value="all_port">모든포트</option>
                        </select>
                    </mat-form-field>
                    <label *ngIf="modRulePortType =='all_port'">입력한 규칙에 모든 포트를 공개합니다. </label>
                </div>
                <!-- 포트 일 때 -->
                <div *ngIf="modRulePortType =='one_port'">
                    <mat-form-field appearance="legacy">
                        <mat-label>허용 포트</mat-label>
						<input matInput (change)="onRuleFromPortChangeMod($event.target.value)" 
							(change)="onRuleToPortChangeMod($event.target.value)" 
							name="" id="" [(ngModel)]="modifyRuleData.from_port" placeholder="1 ~ 65535">
                    </mat-form-field>
                </div>
                <!-- 포트범위 일 때 -->
                <div *ngIf="modRulePortType =='many_port'">
                    <mat-form-field appearance="legacy">
                        <mat-label>시작</mat-label>
						<input matInput [(ngModel)]="modifyRuleData.from_port" (change)="onRuleFromPortChangeMod($event.target.value)" name="" id="" [(ngModel)]="modifyRuleData.from_port" placeholder="1 ~ 65535">
                    </mat-form-field>
                </div>
                <div *ngIf="modRulePortType =='many_port'">
                    <mat-form-field appearance="legacy">
                        <mat-label>끝</mat-label>
						<input matInput [(ngModel)]="modifyRuleData.to_port" (change)="onRuleToPortChangeMod($event.target.value)" name="" id="" [(ngModel)]="modifyRuleData.to_port" placeholder="1 ~ 65535">
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template [ngIf]="modRuleModalType!='all' && modRuleModalType!='tcp'&& modRuleModalType!='udp'&& modRuleModalType!='icmp'">
                <div>
                    <mat-form-field appearance="legacy">
                        <mat-label>허용 포트</mat-label>
						<input matInput readonly name="" id="" [(ngModel)]="modifyRuleData.from_port" placeholder="1 ~ 65535">
                    </mat-form-field>
                </div>
            </ng-template>
            <!-- 공통 -->
            <div>
                <mat-form-field appearance="legacy">
                    <mat-label>CIDR</mat-label>
					<input matInput [(ngModel)]="modifyRuleData.cidr" (change)="onRuleCidrChangeMod($event.target.value)" name="" id="" value="" placeholder="0.0.0.0/0">
                </mat-form-field>
                <div *ngIf="mod_cidr_validate==false" class="invalid-feedback">유효하지않은 CIDR 입니다.</div>
            </div>
        </div>
    </div>
</div>
<section>
    <div mat-dialog-actions>
        <button mat-button (click)="modifyRule()" class="btn big ciblue mg_r4">변경</button>
        <button mat-button mat-dialog-close class="btn big grey lighten-3 shades-text text-black mg_r4 ">취소</button>
    </div>
</section>