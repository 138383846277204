<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>서브넷</h2>
        <div class="lineMap">
            Network / <span>Subnet</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
					<div class="title_wrap">
						<div class="contArea">
							<div class="tit">
								<app-console-projects></app-console-projects>
							</div>
							<div class="cont">
								<app-console-network-status></app-console-network-status>
							</div>
							<div class="contSub">
                                <button class="btn mid blue mg_r4" (click)="openCreateModal()">
                                    <i class="ico ico_register_white"></i>서브넷 생성</button>
                                <!-- <button class="btn mid red mg_r4" [disabled]="!isActived" onclick="fn_modalMsg('서브넷을 삭제하시겠습니까?')"><i class="ico ico_cancel_white"></i>서브넷 삭제</button> -->
                                <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openUpdateModal()" >서브넷 편집</button>
							</div>
						</div>
					</div>
				</div>
                <!-- //lyBox -->
                <!-- lyBox -->
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>서브넷 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" [(ngModel)]="filterText" id="search"
                                    value="" placeholder="검색어를 입력해주세요" />
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid ciblue"><i
                                            class="ico ico_search_white"></i>검색</button>
                                </div>
                                <!-- 접기 버튼 -->
                                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                            </div>
                        </div>
                    </div>
                    <div class="contArea" style="display:block">

                        <div class="boardBox">
                            <table class="tbl_col_tp1 dropmenu pdTp2">
                                <colgroup>
                                    <col style="width: 5%">
                                    <col style="width: 19%">
                                    <col style="width: 19%;">
                                    <col style="width: 19%;">
                                    <col style="width: 19%;">
                                    <col style="width: 19%;">
                                    <!-- <col style="width: 15%;"> -->
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                        </th>
                                        <th scope="col">서브넷 이름</th>
                                        <th scope="col">서브넷 주소</th>
                                        <th scope="col">게이트웨이 주소 </th>
                                        <th scope="col">소속 네트워크</th>
                                        <th scope="col">네트워크 구분</th>
                                        <!-- <th scope="col">IP 버전 </th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let subnet of subnetList | tableFilter: filterText" (contextmenu)="onClickList(subnet)">
                                        <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck(subnet)"
                                                [attr.data-subnet-id]="subnet.id">
                                        </td>
                                        <td>{{subnet.name}}</td>
                                        <td>{{subnet.cidr}}</td>
                                        <td>{{subnet.gateway_ip}}</td>
                                
                                        <td>
                                            <ng-template [ngIf]="subnet.network">
                                                {{subnet.network.name}} ({{subnet.network.cidr}})
                                            </ng-template>
                                        </td>
                                        <td *ngIf="subnet.network_type == 'ext';else elseExt">
                                            외부 네트워크
                                        </td>
                                        <ng-template #elseExt>
                                            <td>내부 네트워크</td>
                                        </ng-template>
                                        <!-- <td>{{subnet.ipver_str}}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a (click)="openUpdateModal()">서브넷 편집</a>
        <a (click)="openDeleteModal()"
        *ngIf="!selected_subnet.isDefaultSubnet; else elseDeleteDisable">
        서브넷 삭제</a>
        <ng-template #elseDeleteDisable>
            <a disabled>서브넷 삭제</a>
        </ng-template>
        
    </div>
</div>



