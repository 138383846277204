import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import {PoolData} from '../../console-load-balancer-setting/PoolData';


@Component({
  selector: 'app-dialog-loadbalancer-pool-update',
  templateUrl: './dialog-loadbalancer-pool-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogLoadbalancerPoolUpdateComponent extends DialogCommon implements OnInit {
  selected_loadbalancer;
  lbpool = new PoolData();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogLoadbalancerPoolUpdateComponent>,
    ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }

  ngOnInit(): void {
    this.selected_loadbalancer = this.dialogData.selected_loadbalancer;
    this.lbpool.description = this.selected_loadbalancer.pool.description;
    this.lbpool.lb_algorithm = this.selected_loadbalancer.pool.lb_algorithm;
    this.lbpool.session_persistence = this.selected_loadbalancer.session_persistence||null;
  }

  changeAlgorithm(value) {
    this.lbpool.lb_algorithm = value;
  }

  changePersistence(value) {
    if (value === "None") {
      this.lbpool.session_persistence = null;
    } else {
      this.lbpool.session_persistence = { type: "SOURCE_IP" };
    }
  }

  updatePool(){
    
    
    var param = {
      pool: JSON.parse(JSON.stringify(this.lbpool)),
      loadbalancer_id:this.selected_loadbalancer.id
    }
    
    param.pool.id = this.selected_loadbalancer.pool.id;


    this.osapi.updatePool(this.selected_loadbalancer.pool.id,param).subscribe(
      (data)=>{

        this.closeDialogMsg("success");
      }
    )
  }
}
