import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import { Router, ActivatedRoute, NavigationExtras, Params} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-inquiry-register',
  templateUrl: './inquiry-register.component.html',
})
export class InquiryRegisterComponent implements OnInit {

  constructor(
    private webapi: WebApiService,
    private spiner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    ) { }

  modal_comp_category: String = "요금";
  modal_comp_title: String = "";
  modal_comp_contents: String = "";
  modal_comp_file: any = "";

  userInfoList: any = [];


  ngOnInit(): void {
    try{
      this.userInfoList = JSON.parse(localStorage.getItem('userInfo'))
    }catch(e){
      this.userInfoList = null;
    }
  }


  fn_insertInquiry() {

    const body = {
      companyId: this.userInfoList.companyId,
      userId: this.userInfoList.userId,
      contents: this.modal_comp_contents,
      title: this.modal_comp_title,
      category: this.modal_comp_category,
      enabled: true
    };

    if (this.modal_comp_title.trim() == "") {
      alert("제목을 입력해 주십시요.")
    } else if (this.modal_comp_contents.trim() == "") {
      alert("내용을 입력해 주십시요.")
    } else {
      this.spiner.show();
      this.webapi.insertInquiry(body).subscribe(
        (response) => {
          alert("저장되었습니다.");
          this.confirmMail(body.userId, body.title, body.contents);
          this.router.navigate(['/web/mypage/inquriyList']);
      },(err) => {

      }
      );
      this.spiner.hide();
    }
  }

  confirmMail(id, title, contents) {
    let dataForm={
      receiver:'helpdesk@bizlinkersys.com',
      subject:id+'님의 1:1 질문 :'+title,
      text:'내용 :'+ contents
    }
    this.webapi.sendEmail(dataForm).subscribe((data: {}) => {});
  }

  onCategoryChange(deviceValue) {
    this.modal_comp_category = deviceValue;
  }
  onTitleChange(deviceValue) {
    this.modal_comp_title = deviceValue;
  }
  onContentsChange(deviceValue) {
    this.modal_comp_contents = deviceValue;
  }
  onFileChange(deviceValue) {
    this.modal_comp_file = deviceValue;
  }
}
