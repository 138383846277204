import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { OsapiService } from '../../osapi.service';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalService } from '../../global.service';
import { FrontApiService } from '../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-server-setting',
  templateUrl: './console-server-setting.component.html',
})

export class ConsoleServerSettingComponent implements OnInit {
  @ViewChild('step1') step1: ElementRef;
  @ViewChild('step2') step2: ElementRef;
  @ViewChild('step3') step3: ElementRef;
  @ViewChild('step4') step4: ElementRef;
  @ViewChild('step5') step5: ElementRef;
  

  availZoneLists: any = [];
  imageLists: any = [];
  flavorLists: any = [];
  oldFlavorList: any = [];
  securityGroupList: any = [];
  keyPairList: any = [];
  subnetList:any =[];

  // 검색 텍스트
  filterText: string;

  form_1 = this.formbuilder.group({
    testControl: new FormControl(''),
    helpControl: new FormControl(''),
  });

  imagetype = 'image';
  volumeList:any = [];

  constructor(
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    private formbuilder: FormBuilder,
    // private sanitizer: DomSanitizer,
    public global: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private dialogs: DialogSetService,
    @Inject(ConsoleNavComponent) private parent:ConsoleNavComponent
  ) {
    this.restore_image.image_id =  this.actRoute.snapshot.paramMap.get('restoreImage');
  }

  restore_image: { image_id: string } = { image_id: '' };

  sel_name: string = '';
  sel_description: String = '';
  /**
   * 20200910 cbkim
   * 가용존 기존 nova 에서 az-01,az-02,az-03 사용으로 변경
   * 변경에 따라 분배방식 결정 필요
   */
  sel_availzone: String = 'az-01';
  sel_image: String = '';
  sel_volume: String = '';
  sel_flavor: String = '';
  sel_subnet: String = '';
  sel_sg: String = '';
  sel_keypair: String = '';
  sel_network: String = '';
  sel_metadata: { OS: string } = { OS: '' };
  keypair_modal_name: String = '';
  keypair_modal_description: String = '';
  
  sel_volume_object:any={};
  sel_flavor_object:any={name:""};
  sel_subnet_object:any={name:""};
  sel_sg_object:any={name:""};
  sel_image_object:any ={name:""};
  
  message:any={};


  
  is_loaded_availzone:boolean = false;
  is_loaded_imagelist:boolean = false;
  is_loaded_flavorlist:boolean = false;
  is_loaded_sglist:boolean = false;
  is_loaded_keypairlist:boolean = false;
  is_loaded_subnetlist:boolean = false;
  is_loaded_volumelist:boolean = false;

  ngOnInit(): void {
    this.spinner.show();
    this.getAvailzones();
    this.getImageList();
    this.getFlavorList();
    this.getSecurityGroupList();
    this.getKeyPairList();
    // this.getDefaultNetwork();
    this.getSubnetList();
    this.getVolumeList();
    
    this.frontapi.currentMessage.subscribe((message:any) => {
      this.message = message;
      if (message.message_type == 'create security group') {

        this.securityGroupList.push(message.data_object);
        this.sel_sg = message.data_object.id
      }
      if (message.message_type == 'create keypair') {

        this.keyPairList.push({keypair:message.data_object});
        this.sel_keypair = message.data_object.name
      }
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  onNameChange(deviceValue) {

    this.sel_name = deviceValue;
  }
  onDescriptChange(deviceValue) {

    this.sel_description = deviceValue;
  }
  onAvailZoneChange(deviceValue) {

    this.sel_availzone = deviceValue;
  }
  onImageChange(deviceValue) {



    

    // deviceValue = deviceValue.image_id;
    this.sel_image = deviceValue;
    this.imageLists.forEach((image) => {
      if(image.image_id == this.sel_image){
        this.sel_image_object = image;
        if (image.name.includes('Windows')) {
          this.sel_metadata.OS = "Windows";
          this.flavorLists = this.flavorLists.filter(f=> f.disk != 50);
        } else {
          this.flavorLists = this.oldFlavorList;
          this.sel_metadata.OS = "Linux";
        }
      }
    });
  }

  onVolumeChange(value){

    this.sel_volume = value;
    this.volumeList.forEach((volume)=>{
      if(volume.id == this.sel_volume){
        this.sel_volume_object = volume;

      }
    })
    /**
      "block_device_mapping_v2": [{
            "uuid": "70a599e0-31e7-49b7-b260-868f441e862b",
            "source_type": "image",
            "destination_type": "volume",
            "boot_index": 0,
            "volume_size": "1",
            "tag": "disk1"
        }]
     */

  }

  onFlavorChange(deviceValue) {

    this.sel_flavor = deviceValue;
    this.flavorLists.forEach((flavor) => {
      if(flavor.id == this.sel_flavor){
        this.sel_flavor_object = flavor;
      }
    });
  }
  onSubnetChange(deviceValue) {
    this.sel_subnet = deviceValue;
    this.subnetList.forEach((subnet) => {
      if(subnet.id == this.sel_subnet){
        this.sel_subnet_object = subnet;
      }
    });


  }
  onSGChange(deviceValue) {

    // this.sel_sg = deviceValue;
    this.securityGroupList.forEach((sg) => {
      if(sg.id == this.sel_sg)
        this.sel_sg_object = sg;
    });
  }
  onKeyPairChange(deviceValue) {

    // this.sel_keypair = deviceValue;
  }

  
  openNewKeypairModal(){
    this.dialogs.openKeypairCreateDialog({});
  }
  openNewSecurityGroupModal(){
    this.dialogs.openSecurityGroupCreateDialog({});
  }

  sendWarningMsg(msg){
    this.step1.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: msg
        }
      });
      return;
  }

  openConfirmModal(){
    if(this.sel_name == null ||this.sel_name == ""){
      this.step1.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '인스턴스의 이름을 입력해주세요.'
        }
      });
      return;
    }
    const name_filter = /^[0-9a-zA-Z\-_]*$/
    if(this.sel_name.length>20 || name_filter.test(this.sel_name)==false ){
      this.step1.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "20자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다."
        }
      });
      return;
    }

    if(this.sel_flavor == null ||this.sel_flavor == ""){
      this.step2.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '서버의 유형을 선택해주세요.'
        }
      });
      return;
    }
    if(this.imagetype == 'image' &&(this.sel_image == "" || this.sel_image == null)){
      this.step2.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '부팅 이미지를 선택해주세요.'
        }
      });
      return;
    }
    if(this.isVolumeCreate){
      if(this.extendVolumeName == null || this.extendVolumeName == ""){
        this.sendWarningMsg("추가 볼륨 이름을 입력해주세요.");
        return;
      }
      if(this.extendVolumeSize == null || this.extendVolumeSize <= 0 || this.extendVolumeSize >= 10000){
        this.sendWarningMsg("볼륨 크기를 다시 확인해주세요.");
        return;
      }
    }
    if(this.sel_sg == null ||this.sel_sg == ""){
      this.step4.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '서버에 할당할 보안그룹을 선택해주세요.'
        }
      });
      return;
    }
    let tempFlavor = this.flavorLists.filter(f=>f.id == this.sel_flavor);
    if(this.sel_image_object.name.indexOf("Windows") >= 0 && tempFlavor.disk == 50) {
      this.step2.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: 'Windows 서버는 디스크 크기 100G이상 선택해주세요.'
        }
      });
      return;
    }
    if(this.sel_availzone == null ||this.sel_availzone == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '가용구역을 선택해주세요.'
        }
      });
      return;
    }
    if(this.sel_keypair == null ||this.sel_keypair == ""){
      this.step5.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '인증키를 선택해주세요.'
        }
      });
      return;
    }
    if(this.sel_subnet_object.network_id == null ||this.sel_subnet_object.id == null){
      this.step3.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '네트워크를 선택해주세요.'
        }
      });
      return;
    }
    const confirmData ={
      sel_name: this.sel_name,
      sel_description: this.sel_description,
      sel_availzone: this.sel_availzone,
      sel_image: this.sel_image,
      sel_volume: this.sel_volume,
      sel_flavor: this.sel_flavor,
      sel_subnet: this.sel_subnet,
      sel_sg: this.sel_sg,
      sel_keypair: this.sel_keypair,
      sel_network: this.sel_network,
      sel_metadata: this.sel_metadata,
      keypair_modal_name: this.keypair_modal_name,
      keypair_modal_description: this.keypair_modal_description,
      
      sel_volume_object:this.sel_volume_object,
      sel_flavor_object:this.sel_flavor_object,
      sel_subnet_object:this.sel_subnet_object,
      sel_sg_object:this.sel_sg_object,
      sel_image_object: this.sel_image_object,
      
      imagetype: this.imagetype,

      isVolumeCreate: this.isVolumeCreate,
      extendVolumeName:this.extendVolumeName,
      extendVolumeSize:this.extendVolumeSize
    }

    this.dialogs.openServerCreateConfirmDialog(confirmData);
    
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "okay"){
        this.createServer();
      }
    });
  }
  getAvailzones() {
    this.osapi.getAvailzones().subscribe((data: { items: [] }) => {
      this.availZoneLists = data.items;
      this.is_loaded_availzone = true;
      this.loadingSpinnerCheck();
      
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  getImageList() {
    this.osapi.getImageList().subscribe((res:any) => {
      let data = res.body;
      this.imageLists = data;
      for(let i=this.imageLists.length-1;i>=0;i--){
        if(this.imageLists[i].image_id==this.restore_image.image_id){
          this.sel_image = this.imageLists[i].image_id;
          this.sel_image_object = this.imageLists[i];
        }
        if(this.imageLists[i].status==null || this.imageLists[i].status.toLowerCase() == 'queued'){
          this.imageLists.splice(i,1);
        }
      }
      this.is_loaded_imagelist = true;
      this.loadingSpinnerCheck();
      
      
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  leadingSpaces(n, digits) {
    var space = '';
    n = n.toString();
  
    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
        space += '&nbsp;';
    }
    return space + n;
  }

  getFlavorList() {
    this.osapi.getFlavorList().subscribe((data: { items: [] }) => {
      this.flavorLists = data.items;
      
      for(let flavor of this.flavorLists){
        let flavor_text = "";
        flavor_text += "&nbsp;&nbsp;&nbsp;[vCPU] " + this.leadingSpaces(flavor.vcpus,2) + " ";
        flavor_text += "&nbsp;&nbsp;&nbsp;[RAM] " + this.leadingSpaces(flavor.ram / 1024,2) + " GB";
        flavor_text += "&nbsp;&nbsp;&nbsp;[DISK] " + this.leadingSpaces(flavor.disk,3) + " GB";
        flavor.flavor_text = flavor_text;
      }

      this.is_loaded_flavorlist = true;
      this.oldFlavorList = this.flavorLists;
      this.loadingSpinnerCheck();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  getSecurityGroupList() {
    this.osapi.getSecurityGroupList().subscribe((data: { items: [] }) => {
      this.securityGroupList = data.items;
      this.is_loaded_sglist = true;
      this.loadingSpinnerCheck();

    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  getKeyPairList() {
    this.osapi.getKeyPairList().subscribe((data: { items: [] }) => {
      this.keyPairList = data.items;
      this.is_loaded_keypairlist = true;
      this.loadingSpinnerCheck();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  getSubnetList(){
    this.osapi.getSubnetList().subscribe((data:any) => {

      this.subnetList = data;
      this.is_loaded_subnetlist = true;
      this.loadingSpinnerCheck();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  // getDefaultNetwork() {
  //   this.osapi.getUserDefaultNework().subscribe((data: { id: String }) => {
  //     this.sel_network = "a714eca0-eb1c-4d3d-81c5-73b93c68f36f";
  //   }, 
  //   (error) => {
  //     this.spinner.hide();
  //     this.frontapi.changeMessage({
  //       type:'toast',
  //       body:{
  //         type:'error',
  //         title:'오류 알림',
  //         message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
  //       }
  //     });
  //   });
  // }

  getVolumeList(){
    this.osapi.getVolumeList().subscribe(
      (data:any)=>{
        this.volumeList = data.items;
        this.is_loaded_volumelist = true;
        this.loadingSpinnerCheck();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      }
    )
  }

  createServer() {

    this.spinner.show();
    this.osapi.createPort(this.sel_subnet_object.network_id,this.sel_subnet_object.id,[this.sel_sg])
    .subscribe((port:any) => {
      this.osapi
        .createServer(
          this.sel_name,
          this.sel_description,
          this.sel_flavor,
          this.imagetype,
          this.sel_image,
          1,
          '',
          port.id,
          // this.sel_subnet_object.network_id,
          this.sel_sg,
          this.sel_availzone,
          this.sel_keypair,
          this.sel_flavor_object.disk,
          this.sel_metadata
        )
        .subscribe((data:any) => {
          /**
           * 20200916 cbkim
           * 서버 생성 시 볼륨 추가
           */
    
          if(this.isVolumeCreate){
            this.osapi.createVolume(this.extendVolumeName,"","nova",this.extendVolumeSize,"__DEFAULT__",{},null,null,null).subscribe(
              (volume:any)=>{
                this.afterAttachVolume(data.id,volume.id);
              }
            )
          }

          this.spinner.hide();
          this.parent.isLoadedServer = false;
          this.parent.getServerList();
          this.router.navigate(['/console/server']);
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
        });
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  afterAttachVolume(serverId,volumeId){
    this.osapi.getServer(serverId).subscribe(
      (server:any)=>{
        if(server.status == "ACTIVE"){

          this.osapi.attachVolume(serverId,volumeId).subscribe(
            (extendVolume)=>{
              this.parent.isLoadedServer = false;
              this.parent.getServerList();
            }
          )
        }else{
          setTimeout(()=>{

            this.afterAttachVolume(serverId,volumeId);
          },1000)
        }
      }
    )
  }

  loadingSpinnerCheck(){
    if(this.is_loaded_availzone &&this.is_loaded_flavorlist &&this.is_loaded_keypairlist 
      &&this.is_loaded_imagelist &&this.is_loaded_sglist &&this.is_loaded_subnetlist){
        this.spinner.hide();
    }
  }
  setImageType(type){
    this.imagetype = type;
  }

  isVolumeCreate:boolean=false;
  extendVolumeName:string=null;
  extendVolumeSize:number=null;
  setVolumeState(state){
    this.isVolumeCreate = state;
  }
}
