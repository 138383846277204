export class MemberData {
    public name:string;
    public address: string;
    public protocol_port: Number;
    public id:number;
    public monitor_address:boolean;
    public monitor_port:boolean;
    public backup:boolean;
    // public port:number;
    public subnet_id:string;
    public admin_state_up:boolean;
    public weight:number;
    
    constructor() {
        this.name='';
        this.address = '';
        this.protocol_port = 80;
        // this.port = 80;
        this.id = 0;
        this.monitor_address = null;
        this.monitor_port = null;
        this.subnet_id=null;
        this.admin_state_up = true;
        this.backup=false;
        this.weight=1;
    }
}