<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>이미지 목록</h2>
        <div class="lineMap">
            Admin  /  <span>Image</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox consoleRightBody">
                    <div class="titArea">
                        <h3>이미지 목록</h3>
                        <div class="btnArea">
                            <div class="input-group fl">
                                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요">
                                <div class="input-group-btn pd_r10">
                                    <button type="submit" class="btn mid dBlue"><i class="ico ico_search_white"></i>검색</button>
                                </div>
                                <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                            </div>
                        </div>									
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox" style="overflow:auto;">
                            <table class="tbl_col_tp1 dropmenu">
                                <colgroup>
                                    <col style="width:8%;" >
                                    <col style="width:11%;" span="3">
                                </colgroup>
                                <thead>
                                    <th scope="col"><input type="checkbox" id="all_chkbox" (change)="allCheck($event)"></th>
                                    <th scope="col">소유자</th>
                                    <th scope="col">이름</th>
                                    <th scope="col">유형</th>
                                    <th scope="col">상태</th>
                                    <th scope="col">가시성</th>
                                    <th scope="col">보호됨</th>
                                    <th scope="col">디스크포맷</th>
                                    <th scope="col">크기</th>
                                </thead>                                
                                <tbody>																										
                                    <tr *ngFor="let image of imageLists" (contextmenu)="onClickList(image)">
                                        <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck()"></td>
                                        <td>{{image.name}}</td>
                                        <td>{{image.name}}</td>
                                        <td *ngIf="image.properties.bdm_v2">스냅샷 이미지</td>
                                        <td *ngIf="!image.properties.bdm_v2">OS 이미지</td>
                                        <td>{{image.status}} </td>
                                        <td>공용/비공개</td>
                                        <td>{{image.protected}}</td>
                                        <td>{{image.disk_format}} </td>
                                        <td>{{image.size/1048576}} MB</td>
                                    </tr>                                    
                                </tbody>
                            </table>
                        </div>
                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->


<!-- dropdown menu -->
<div class="dropdown">
    <div id="myDropdown" class="dropdown-content">
        <a routerLink="/admin/image/create">이미지 생성</a>
        <a onclick="fn_modalOpen('edit_image');">이미지 편집</a>
        <a onclick="fn_modalOpen('delete_image');">이미지 삭제</a>
        <a onclick="fn_modalOpen('create_volume')">볼륨 생성</a>
    </div>
</div>


<!-- Modal : msg 공통 -->
<div id="delete_image" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>삭제확인</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="msg">
            '{{selected_image.name}}' 이미지를 삭제하시겠습니까?' 
        </div>
        <div class="btnArea">
            <a href="#close-modal" rel="modal:close" (click)="deleteImage()" class="btn big orange mg_r4">삭제</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->

<!-- Modal : 볼륨생성 모달 -->
<div id="create_volume" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>볼륨 생성</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width: 30%;"/>
                    <col style="width: 70%;"/>
                </colgroup>
                <tbody>
                    <tr>
                        <td colspan="2"><span>볼륨 생성 설명입니다.</span></td>
                    </tr>
                    <tr>
                        <th scope="row">이름</th>
                        <td>
                            <input type="text" name="" value="" placeholder="선택값, 수정 가능" title="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <input type="text" name="" value="" placeholder="선택값, 수정 가능" title="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">소스로 이미지를 사용</th>
                        <td>
                            <input type="text" name="" value="" placeholder="이미지 이름, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">유형</th>
                        <td>
                            <select name="">
                                <option value="">detault</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">크기 (GiB)</th>
                        <td>
                            <input type="number" name="" value="" placeholder="숫자만" title="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">가용구역</th>
                        <td>
                            <select name="">
                                <option value="">nova</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">생성</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->



<!-- Modal : 이미지편집 모달 -->
<div id="edit_image" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>이미지 편집</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width: 30%;"/>
                    <col style="width: 70%;"/>
                </colgroup>
                <tbody>
                    <tr>
                        <td colspan="2"><span>이미지 상세정보 입니다.</span></td>
                    </tr>
                    <tr>
                        <th scope="row">이름</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 가져오기" title="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 가져오기" title="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">포멧</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 고정, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">포멧</th>
                        <td>
                            <select name="" disabled>
                                <option value="">-기존값 고정-</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><span>이미지 요구사항 입니다.</span></td>
                    </tr>
                    <tr>
                        <th scope="row">커널</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 고정, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">램디스크 ID</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 고정, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">아키텍처</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 고정, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">최소 디스크(GB)</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 고정, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">최소 RAM</th>
                        <td>
                            <input type="text" name="" value="" placeholder="기존값 고정, 수정 불가" title="" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">이미지 공유</th>
                        <td>
                            <label for="r1"><input type="radio" id="r1" name="radio" value="" checked="checked">사설</label>
                            <label for="r2"><input type="radio" id="r2" name="radio" value="">공유</label>
                            <label for="r3"><input type="radio" id="r3" name="radio" value="">커뮤니티</label>
                            <label for="r4"><input type="radio" id="r4" name="radio" value="">공용</label>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">보호됨</th>
                        <td>
                            <label for="Y"><input type="radio" id="Y" name="radio" value="" checked="checked">예</label>
                            <label for="N"><input type="radio" id="N" name="radio" value="">아니오</label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">생성</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->