import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { GlobalService } from '../../global.service';
import { FrontApiService } from '../../frontapi.service';
import { Router} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';

declare function fn_modalOpen(params: string);

@Component({
  selector: 'app-console-backup-server',
  templateUrl: './console-backup-server.component.html',
})
export class ConsoleBackupServerComponent implements OnInit {

  constructor(
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    // private webapi: WebApiService,
    public global: GlobalService,
    private spinner: NgxSpinnerService) { }

  baktype: any;
  bakTypeList: any = ['Full', 'Incremental', 'Differential', 'Synthetic Full'];
  bakSchedule: any = ['한번만 수행', '1일마다', '1주마다', '1개월마다'];

  // 검색 텍스트
  filterText: string;

  backupServerList: any;

  serverList: any;
  server_list_loading_flag=0;

  newBackupServerData={
    instance_id:"",
    instance_name:"",
    backup_type:"",
    option:"DEFAULT",
    schedule:this.bakSchedule[0],
    copy_num:3,
    server_path:"/",
  };
  ngOnInit(): void {
    this.server_list_loading_flag=0;
    this.getBackupServerList();
    this.getServerList();
  }

  onChangeBackupServer(target_index){
    if(target_index>-1){
      const target_server = this.serverList[target_index];
      this.newBackupServerData.instance_id = target_server.server_id;
      this.newBackupServerData.instance_name = target_server.server_name;
    }else{
      this.newBackupServerData.instance_id = "";
      this.newBackupServerData.instance_name = "";
    }
  }
  onChangeBackupType(type){
    this.baktype = type;
    this.newBackupServerData.backup_type = type;
  }
  onChangeBackupOption(option){
    this.newBackupServerData.option = option;
  }
  getBackupServerList() {
    this.osapi.getBackupServerList().subscribe((data:any) => {
      this.backupServerList = data;
      this.spinner.hide();
    }, 
    (error) => {
      this.server_list_loading_flag=2;
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  getServerList() {
    this.osapi.getServerList().subscribe((data: { items: [] }) => {
      this.serverList = data;
      this.server_list_loading_flag=1;
      this.spinner.hide();
    }, 
    (error) => {
      this.server_list_loading_flag=2;
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  openCreateModal(){
    if(this.server_list_loading_flag==0){
      this.spinner.show();
    }
    fn_modalOpen('serberbak_create');
  }
  createServerBackupService(){
    this.osapi.createBackupServer(this.newBackupServerData).subscribe((data:any) => {
    }, 
    (error) => {
      this.server_list_loading_flag=2;
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
}
