<table style="margin-bottom:10px;">
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">전용 URI : </i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head" style="user-select: text;">{{uri}}</span>
            
            <!-- <div class="tooltip-content"> -->
                <!-- <table>
                    <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                    </tr>
                    <tr>
                        <th>External</th>
                        <td>:</td>
                    </tr>
                    <tr>
                        <th>Internal</th>
                        <td>:</td>
                    </tr>
                </table> -->
            <!-- </div> -->
        </div>
    </div>
    
</table>