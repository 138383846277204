/**
 * 다음 address 메소드
 * 
 */
var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

var before = document.getElementsByTagName('script')[0];
    before.parentNode.insertBefore(script,before);



export function postcode(callback) {
    var width = 500;
    var height = 600;
    try{
        new daum.Postcode({
            oncomplete: function(data){
                return callback(data);
            },
            maxSuggestItems: 5            
        }).open({
            popupName: 'postcodePopup',
            left: (window.screen.width / 2) - (width / 2),
            top: (window.screen.height / 2) - (height / 2)
        })        
    }catch(e){
        //console.log(e)
    }    
}
