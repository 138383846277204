import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
})
export class ServiceComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router : Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    const param = this.route.snapshot.paramMap.get('params');
    if (param){
        const el = document.getElementById(param);
        el.scrollIntoView();
    }
  }

  ServicePriceRouter(param) {
    this.router.navigate(['/web/servicePrice', { param}]);
  }
}
