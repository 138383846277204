import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-security-group-rule-update',
  templateUrl: './dialog-security-group-rule-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogSecurityGroupRuleUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSecurityGroupRuleUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  security_group;
  
  selected_sgr;

  modifyRuleData:{
    direction:string,
    ip_protocol:string,
    from_port:string,
    to_port:string,
    cidr:string,
    group_id:string,
    description:string,
  }={
    direction:"ingress",
    ip_protocol:null,
    from_port:null,
    to_port:null,
    cidr:"",
    group_id:null,
    description:""
  };

  modRuleModalType:string="all";
  modRulePortType:string="one_port";
  mod_cidr_validate = true;

  ngOnInit(): void {

    
    this.security_group = this.dialogData.security_group;
    this.selected_sgr = this.dialogData.selected_sgr;

    this.mod_cidr_validate = true;
    this.modifyRuleData.description = this.selected_sgr.description;
    this.modifyRuleData.direction = this.selected_sgr.direction;
    this.modifyRuleData.from_port = this.selected_sgr.from_port;
    this.modifyRuleData.to_port = this.selected_sgr.to_port;
    this.modifyRuleData.cidr = this.selected_sgr.ip_range.cidr;
    this.modifyRuleData.ip_protocol = this.selected_sgr.ip_protocol;
    if(this.modifyRuleData.ip_protocol){
      this.modRuleModalType = this.modifyRuleData.ip_protocol;
    }else{
      this.modRuleModalType = "all";
    }
    if(this.modRuleModalType == "tcp"){
      if(this.modifyRuleData.from_port==null){
        this.modRulePortType = 'all_port';
      }else if(this.modifyRuleData.from_port == this.modifyRuleData.to_port){
        this.modRulePortType = 'one_port';
      }else{
        this.modRulePortType = 'many_port';
      }
    }else if(this.modRuleModalType == "udp"){
      if(this.modifyRuleData.from_port==null){
        this.modRulePortType = 'all_port';
      }else if(this.modifyRuleData.from_port == this.modifyRuleData.to_port){
        this.modRulePortType = 'one_port';
      }else{
        this.modRulePortType = 'many_port';
      }
    }else if(this.modRuleModalType == "icmp"){
      if(this.modifyRuleData.from_port==null && this.modifyRuleData.to_port==null){
        this.modRulePortType = 'all_icmp';
      }else{
        this.modRulePortType = 'one_icmp';
      }
    }else if(this.modRuleModalType == "all"){
      this.modifyRuleData.from_port=null;
      this.modifyRuleData.to_port=null;
    }
  }

  
  modifyRule(){
    console.log(this.selected_sgr.id);
    if(this.modRulePortType == "all_icmp" ){
    }
    else if(this.modRulePortType == "one_icmp" &&  this.modifyRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP TYPE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.modRulePortType == "one_icmp" &&  this.modifyRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP CODE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.modRuleModalType!="all"&&this.modRulePortType != "all_port" &&  this.modifyRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    else if(this.modRuleModalType!="all"&&this.modRulePortType != "all_port" &&  this.modifyRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    if(!this.mod_cidr_validate){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'유효하지 않은 CIDR입니다.'
        }
      });
      return;
    }
    
    if( this.modifyRuleData.cidr == "0.0.0.0/0"){
      const answer = confirm("CIDR을 '0.0.0.0/0'으로 설정하면 모든 사용자로부터 해당 보안그룹에 접근할 수 있습니다. 진행하시겠습니까?.");
      if(!answer){
        this.modifyRuleData={
          direction:"ingress",
          ip_protocol:null,
          from_port:null,
          to_port:null,
          cidr:"",
          group_id:null,
          description:""
        };
        return;
      }
    }

    this.spinner.show();
    console.log(this.security_group.id);
    this.osapi.deleteSecurityGroupRule(this.selected_sgr.id).subscribe(
      (data) => {
      this.osapi.createSecurityGroupRule(this.security_group.id,
        this.modifyRuleData.direction,this.modifyRuleData.ip_protocol,
        this.modifyRuleData.from_port,this.modifyRuleData.to_port,
        this.modifyRuleData.cidr,this.modifyRuleData.group_id,this.modifyRuleData.description).subscribe(
          (data) => {
            this.spinner.hide();
            this.closeDialogMsg("success");
          }, 
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type:'toast',
              body:{
                type:'error',
                title:'오류 알림',
                message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
        // this.getSecurityGroupRuleList(this.security_group.id);
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
    
  }
  onRuleTypeChangeMod(modRuleModalType){
    this.modRuleModalType = modRuleModalType;
    if(this.modRuleModalType == "all"){
      modRuleModalType = null;
    }
    this.modifyRuleData.ip_protocol = modRuleModalType;
    
    if(modRuleModalType == "ssh"){
      this.modifyRuleData.ip_protocol = "tcp"
      this.modifyRuleData.from_port = '22'
      this.modifyRuleData.to_port = '22'
    }
    if(modRuleModalType == "http"){
      this.modifyRuleData.ip_protocol = "tcp"
      this.modifyRuleData.from_port = '80'
      this.modifyRuleData.to_port = '80'
    }
    if(modRuleModalType == "https"){
      this.modifyRuleData.ip_protocol = "tcp"
      this.modifyRuleData.from_port = '443'
      this.modifyRuleData.to_port = '443'
    }
  }
  onRulePortTypeChangeMod(modRulePortType){
    this.modRulePortType = modRulePortType;
    this.modifyRuleData.from_port = null;
    this.modifyRuleData.to_port = null;
  }
  onRuleFromPortChangeMod(modRuleModalFromPort){
    this.modifyRuleData.from_port = modRuleModalFromPort;
    if(parseInt(modRuleModalFromPort)>65535){
      this.modifyRuleData.from_port = '65535';
    }
    if(parseInt(modRuleModalFromPort)<1){
      this.modifyRuleData.from_port = '1';
    }
    if(parseInt(modRuleModalFromPort)>parseInt(this.modifyRuleData.to_port)){
      this.modifyRuleData.to_port = this.modifyRuleData.from_port;
    }
  }
  onRuleToPortChangeMod(modRuleModalToPort){
    this.modifyRuleData.to_port = modRuleModalToPort;
    if(parseInt(modRuleModalToPort)>65535){
      this.modifyRuleData.to_port = '65535';
    }
    if(parseInt(modRuleModalToPort)<1){
      this.modifyRuleData.to_port = '1';
    }
    if(parseInt(modRuleModalToPort)<parseInt(this.modifyRuleData.from_port)){
      this.modifyRuleData.from_port = this.modifyRuleData.to_port;
    }
  }
  onRuleCidrChangeMod(modRuleModalCidr){
    if(modRuleModalCidr == ""){
      modRuleModalCidr = "0.0.0.0/0"
    }
    //CIDR 체크  IPv4 주소 및 masking 비트가 포함된 문자열 true
    var cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
    if(!cidr_filter.test(modRuleModalCidr)){
      this.mod_cidr_validate = false;
    }else{
      this.mod_cidr_validate = true;
    }
    this.modifyRuleData.cidr = modRuleModalCidr;
  }
}
