import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-subnet-create',
  templateUrl: './dialog-subnet-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogSubnetCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSubnetCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
 
  subnetModel: any;
  networkList: any = [];

  resource: any = [];

  // 생성 시 validation 체크
  network_cidr_validate= true;
  network_cidr_is_include= true;
  network_cidr_not_used= true;

  ngOnInit(): void {

    this.subnetModel = this.dialogData.subnetModel;
    this.networkList = this.dialogData.networkList;
    this.resource = this.dialogData.resource;
  }


  
  createSubnet() {
    if (this.subnetModel.name =="") {
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    if (this.subnetModel.network == '') {
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'네트워크를 선택해주세요.'
        }
      });
      return;
    }
    if (this.subnetModel.cidr == '') {
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'CIDR을 입력해주세요.'
        }
      });
      return;
    }
    
    if (!this.network_cidr_validate) {
      this.subnetModel.cidr = '';
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'유효하지 않은 CIDR 입니다.'
        }
      });
      return;
    }
    if (!this.network_cidr_is_include) {
      this.subnetModel.cidr = '';
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'선택한 네트워크 대역에 포함되지 않는 CIDR입니다.'
        }
      });
      return;
    }
    if (!this.network_cidr_not_used) {
      this.subnetModel.cidr = '';
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'해당 대역을 이미 사용 중인 서브넷이 존재합니다.'
        }
      });
      return;
    }
    if (parseInt(this.subnetModel.cidr.split('/')[1]) > 29 ){
      this.subnetModel.cidr = '';
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'생성하려는 서브넷의 대역이 너무 작습니다.'
        }
      });
      return;
    }
    

    this.spinner.show();
    // network cidr로 network id 찾기
    var networkId;
    for (let network of this.networkList) {
      if (network.cidr === this.subnetModel.network) {
        networkId = network.id;
      }
    }

    // 서브넷 생성, 테넌트id는 back에서
    this.osapi.createSubnet({
      name: this.subnetModel.name,
      enable_dhcp: true,
      network_id: networkId,
      ip_version: 4,
      cidr: this.subnetModel.cidr
    }).subscribe(
      (data: any) => {

        var subnet = data;
        // 외부네트워크일 경우 인터페이스 추가
        for (let r of this.resource) {
          if (r.resourceType === "router") {
            if (r.value === this.subnetModel.network) {
              var routerId = r.objectId;
              this.osapi.getRouters(routerId).subscribe(
                (data: any) => {

                  var router = data;
                  this.osapi.addExtInterface({ router_id: router.id, subnet_id: subnet.id }).subscribe(
                    (data: any) => {

                      this.spinner.hide();
                      

                    },
                    (error) => {

                      this.spinner.hide();
                      this.frontapi.changeMessage({
                        type: 'toast',
                        body: {
                          title: '오류 알림',
                          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
                        }
                      });
                    }
                  )
                },
                (error) => {

                  this.spinner.hide();
                  this.frontapi.changeMessage({
                    type: 'toast',
                    body: {
                      title: '오류 알림',
                      message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
                    }
                  });
                })
            }
          }
        }

        this.spinner.hide();

        this.closeDialogMsg("success");

        if (this.router.url == '/console/subnet') {
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/subnet']);
            }
          );
        } else {
          this.router.navigate(['/console/subnet']);
        }

      },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      })
  }

  
  onChangeSubnetCidr() {
    if (this.subnetModel.cidr == "") {
      this.network_cidr_validate = false;
      this.network_cidr_is_include = true;
      this.network_cidr_not_used = true;
    }
    if (this.subnetModel.network == "") {
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'네트워크를 선택해주세요'
        }
      });
      return;
    }

    //CIDR 체크  masking 비트가 포함된 IPv4 주소 문자열 true
    var hard_cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
   
    if (hard_cidr_filter.test(this.subnetModel.cidr) == false) {
      this.network_cidr_validate = false;
      this.network_cidr_is_include = true;
      this.network_cidr_not_used = true;
      return;
    }else{
      this.network_cidr_validate = true;
    }
    if (!this.isIncludedCidr(this.subnetModel.network, this.subnetModel.cidr)) {
      this.network_cidr_is_include = false;
      this.network_cidr_not_used = true;
      return;
    }else{
      this.network_cidr_is_include = true;
    }
    if (this.isAlreadyUsedCidr(this.subnetModel.cidr)) {
      this.network_cidr_not_used = false;
      return;
    }else{
      this.network_cidr_not_used = true;
    }
    this.subnetModel.gateway_ip = this.getGatewayIP(this.subnetModel.cidr);
  }


  selectNetwork(cidr) {
    this.subnetModel.network = cidr;
  }
  isAlreadyUsedCidr(new_cidr) {
    let chk_flag = false;

    for(let rsc of this.resource){
      if( rsc.resourceType == "subnet"){
        if (!this.compareCidr(new_cidr, rsc.description)) {
          chk_flag = true;
        }
      }
    }
    return chk_flag;
  }
  compareCidr(cidr, cidr2) {
    var split_masking_bit = cidr.split('/');
    var split_masking_bit2 = cidr2.split('/');
    var splited = split_masking_bit[0].split('.');
    var splited2 = split_masking_bit2[0].split('.');

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    let original_bit2 = '';
    for (var i of splited2) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit2 += bit;
    }

    let chk_flag = false;
    for (var i: any = 0; i < split_masking_bit[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag = true;
    }
    let chk_flag2 = false;
    for (var i: any = 0; i < split_masking_bit2[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag2 = true;
    }
    return chk_flag && chk_flag2;
  }
  isIncludedCidr(network_cidr, subnet_cidr) {
    var split_masking_bit = network_cidr.split('/');
    var split_masking_bit2 = subnet_cidr.split('/');
    var splited = split_masking_bit[0].split('.');
    var splited2 = split_masking_bit2[0].split('.');

    if(split_masking_bit[1] > split_masking_bit2[1]){
    	return false
    }

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    let original_bit2 = '';
    for (var i of splited2) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit2 += bit;
    }

    let chk_flag = true;
    for (var i: any = 0; i < split_masking_bit[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag = false;
    }
    return chk_flag;
  }
  getGatewayIP(cidr) {
    var split_masking_bit = cidr.split('/');
    var splited = split_masking_bit[0].split('.');

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    const gateway_ip_bit = original_bit.substring(0, original_bit.length - 1) + "1";

    const gateway_ip_bit_1 = gateway_ip_bit.substring(0, 8);
    const gateway_ip_bit_2 = gateway_ip_bit.substring(8, 16);
    const gateway_ip_bit_3 = gateway_ip_bit.substring(16, 24);
    const gateway_ip_bit_4 = gateway_ip_bit.substring(24, 32);

    const gateway_ip = parseInt(gateway_ip_bit_1, 2) + "." + parseInt(gateway_ip_bit_2, 2) + "." + parseInt(gateway_ip_bit_3, 2) + "." + parseInt(gateway_ip_bit_4, 2);

    var filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    if (filter.test(gateway_ip) == false) {
      console.error("get gateway ip fail");
      return null;
    }
    return gateway_ip;
  }

}
