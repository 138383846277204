<link href="/assets/css/materialize.css" type="text/css" rel="stylesheet" media="screen,projection" />
<nav class="darken nav-header">
    <div class="nav-wrapper">
        <li (click)="backMovement();" class="back-movement">
            <i class="material-icons custom-icon">keyboard_arrow_left</i>
        </li>
        <li class="matSelect">
            <mat-form-field appearance="fill" class="matSelectForm">
                <mat-select (selectionChange)="onProjectChange($event.value)" [(value)]="defaultProject">
                    <mat-option *ngFor="let project of projectLists" [(value)]="project.id">
                        {{project.resource_data.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </li>
        <a (click)="menuListOpen()" class="btn-menu"></a>
        <ul class="side-nav" id="mobile-demo">
            <a class="logo" href="#"></a>
            <a href="javascript:void(0)" class="closebtn" (click)="menuListClose()">&times;</a>
            <li class="gubn_line"><i class="material-icons">dashboard</i><a class="r_sort" (click)="routerDirection('/m/dashboard')">대시보드</a></li>
            <li><i class="material-icons">filter_drama</i><a class="r_sort" (click)="routerDirection('/m/server');"> 서버</a></li>
            <li><a class="r_sort" (click)="routerDirection('/m/image')"> 이미지</a></li>
            <li class="gubn_line"><a class="r_sort" (click)="routerDirection('/m/keypair')"> 인증키</a></li>
            <li><i class="material-icons">settings_input_antenna</i><a class="r_sort" (click)="routerDirection('/m/network')"> 네트워크</a></li>
            <li><a class="r_sort" (click)="routerDirection('/m/subnet')"> 서브넷</a></li>
            <li><a class="r_sort" (click)="routerDirection('/m/loadbalancer')"> 로드 밸런서</a></li>
            <li><a class="r_sort" (click)="routerDirection('/m/securitygroup')"> 보안그룹</a></li>
            <li><a class="r_sort" (click)="routerDirection('/m/ip')"> 공인 IP</a></li>
            <li class="gubn_line"><a class="r_sort" (click)="routerDirection('/m/privatenetwork')"> 사설 네트워크</a></li>
            <li><i class="material-icons">snippet_folder</i><a class="r_sort" (click)="routerDirection('/m/storage')"> 블록스토리지</a></li>
            <li><a class="r_sort" (click)="routerDirection('/m/snapshot')"> 스냅샷</a></li>
            <li class="gubn_line"><a class="r_sort" (click)="routerDirection('/m/nas')"> NAS</a></li>
            <li><i class="material-icons">settings</i><a class="r_sort" (click)="routerDirection('/m/project')"> 프로젝트목록</a></li>
            <li class="gubn_line"><a class="r_sort" (click)="routerDirection('/m/subuser')"> 멤버관리</a></li>
        </ul>
    <!-- </div> -->
    </div>
</nav>
<!-- <footer class="page-footer darken">
    <div class="col" style="width:100%; margin:0px;">
        <ul class="tabs">
            <li class="tab col se" style="width:33%">
                <a href="/m/dashboard">
                    <i class="material-icons">home</i>
                </a>
            </li>
            <li class="tab col se" style="width:33%">
                <a href="/web/mypage/seviceHistory)">
                    <i class="material-icons">shopping_cart</i>
                </a>
            </li>
            <li class="tab col se" style="width:33%">
                <a href="/web/mypage/member">
                    <i class="material-icons">account_circle</i>
                </a>
            </li>
        </ul>
    </div>
</footer> -->
<router-outlet></router-outlet>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#31a3cb" type="square-jelly-box" [fullScreen]="false">
    <p style="color: black;"></p>
</ngx-spinner>

<ejs-toast #toasttype id='toasttype' [position]="position"></ejs-toast>