
<!-- Modal : 네트워크 생성 모달 -->
<div id="network_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        사설 네트워크 생성
        <div class="sub-txt">
          서로 다른 네트워크 또는 프로젝트 간 통신 할 수 있는 사설 네트워크를 구축 할 수 있습니다.
        </div>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th>네트워크 이름</th>
              <td><input type="text" value="" placeholder="영문자, _(언더바), -(하이픈)만 사용이 가능합니다."
                  [(ngModel)]="networkModel.name" (change)="validNetworkName()" maxlength="60"></td>
            </tr>
            <tr>
              <th>CIDR</th>
              <td><input type="text" value="" placeholder="예) 192.168.0.0/16" [(ngModel)]="networkModel.cidr"
                  (change)="validNetworkCidr()">
                <div *ngIf="network_cidr_validate==false" class="invalid-feedback">
                  <div>유효하지않은 CIDR 입니다.</div>
                </div>
                <div *ngIf="network_cidr_not_used==false"
                  class="invalid-feedback">
                  <div>해당 대역을 이미 사용 중입니다.</div>
                </div>
                <div *ngIf="private_network_cidr_validate==false" class="invalid-feedback">
                  <div>CIDR은 사설 주소 범위로 입력되어야 합니다.</div>
                  <div>(10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16)</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="createNetwork()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->