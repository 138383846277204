import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { tableFilterPipe } from './consoleTable-filter.pipe';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { UploaderModule  } from '@syncfusion/ej2-angular-inputs';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModule } from './dialog/dialog.module';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


import { ConsoleLoadBalancerSettingComponent } from './console-load-balancer-setting/console-load-balancer-setting.component';
import { ConsoleLoadBalancerListComponent } from './console-load-balancer-list/console-load-balancer-list.component';
import { ConsoleImageListComponent } from './console-image-list/console-image-list.component';
import { ConsoleIpListComponent } from './console-ip-list/console-ip-list.component';
import { ConsoleKeypairListComponent } from './console-keypair-list/console-keypair-list.component';
import { ConsoleServerListComponent } from './console-server-list/console-server-list.component';
import { ConsoleServerSettingComponent } from './console-server-setting/console-server-setting.component';
import { ConsoleStorageListComponent } from './console-storage-list/console-storage-list.component';
import { ConsoleSnapShotListComponent } from './console-snapShot-list/console-snapShotList.component';
import { ConsoleRuleManageListComponent } from './console-rule-manage-list/console-rule-manage-list.component';
import { ConsoleSecurityGroupListComponent } from './console-security-group-list/console-security-group-list.component';
import { ConsoleNavComponent } from './console-nav/console-nav.component';
import { ConsoleRoutingModule } from './console-routing.module';
import { ConsoleCommonModalComponent } from './console-common-modal/console-common-modal.component';
import { ConsoleSubuserListComponent } from './console-subuser-list/console-subuser-list.component';
import { ConsoleNasListComponent } from './console-nas-list/console-nas-list.component';
import { ConsoleDashboardComponent } from './console-dashboard/console-dashboard.component';
import { ConsoleSubnetListComponent } from './console-subnet-list/console-subnet-list.component';
import { ConsoleNetworkListComponent } from './console-network-list/console-network-list.component';
import { ConsoleDbPostgresqlComponent } from './console-db-postgresql/console-db-postgresql.component';
import { ConsoleDbInstanceComponent } from './console-db-instance/console-db-instance.component';
import { ConsoleDbBlsComponent } from './console-db-bls/console-db-bls.component';
import { ConsoleDnsListComponent } from './console-dns-list/console-dns-list.component';
import { ConsoleCommercialDbmsComponent } from './console-commercial-dbms/console-commercial-dbms.component';
import { ConsoleCommercialDbmsSettingComponent } from './console-commercial-dbms-setting/console-commercial-dbms-setting.component';
import { ConsoleBackupDbComponent } from './console-backup-db/console-backup-db.component';
import { ConsoleBackupServerComponent } from './console-backup-server/console-backup-server.component';
import { ConsoleMessageQueueComponent } from './console-message-queue/console-message-queue.component';
import { ConsoleBaremetalListComponent } from './console-baremetal-list/console-baremetal-list.component';
import { ConsoleBaremetalSettingComponent } from './console-baremetal-setting/console-baremetal-setting.component';
import { ConsoleProjectsComponent } from './console-projects/console-projects.component';
import {ConsoleTestComponent} from './console-test/console-test.component';
import {ConsoleProjectListComponent} from './console-project-list/console-project-list.component';
import { CommonChkboxComponent } from '../common/common-checkbox/common-chkbox.component';
/**
 * 20200901 cbkim
 * 사설 네트워크 추가
 */
import {ConsolePrivateNetworkListComponent} from './console-private-network-list/console-private-network-list.component';
// 20200908 ogto 공통네트워크/서버/스토리지/프로젝트 정보 추가
import {ConsoleNetworkStatusComponent} from './console-network-status/console-network-status.component';
import {ConsoleServerStatusComponent} from './console-server-status/console-server-status.component';
import {ConsoleStorageStatusComponent} from './console-storage-status/console-storage-status.component';
import {ConsoleProjectStatusComponent} from './console-project-status/console-project-status.component';
//210128 ldh 추가
import {ConsoleApigatewayStatusComponent} from './console-apigateway-status/console-apigateway-status.component';

/** 20210106 ldh API Gateway  */
import { ConsoleApigatewayServiceComponent } from './console-apigateway-service/console-apigateway-service.component';
import { ConsoleApigatewayApiListComponent } from './console-apigateway-api-list/console-apigateway-api-list.component';
import { ConsoleApigatewayApiSettingComponent } from './console-apigateway-api-setting/console-apigateway-api-setting.component';
import { ConsoleApigatewayAuthListComponent } from './console-apigateway-auth-list/console-apigateway-auth-list.component';
import { ConsoleApigatewayUsageComponent } from './console-apigateway-usage/console-apigateway-usage.component';
import { ConsoleObjectStorageListComponent } from './console-objectstorage-list/console-objectstorage-list.component';
import { ConsoleBatchComponent } from './console-batch/console-batch.component';

// import {ConsoleTopologyComponent} from './console-topology/console-topology.component';
// import { NgxGraphModule } from '@swimlane/ngx-graph';
// import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NgxSpinnerModule } from "ngx-spinner";

import { AuthGuard, ThemeGuard} from '../auth.guards';

/** i18 */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function createTranslateLoader(http: HttpClient) { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); }

/** 
 * 20200817 cbkim 
 * charts
 */
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';
import {  RangeNavigatorModule, StockChartAllModule} from '@syncfusion/ej2-angular-charts';
import { PeriodSelectorService} from '@syncfusion/ej2-angular-charts';

/**
 * 20200819 cbkim
 * clipboard
 */
import { ClipboardModule } from 'ngx-clipboard';

/**
 * 20201005 cbkim
 * area charts
 */
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { AreaSeriesService, RangeAreaSeriesService, StepAreaSeriesService, StackingAreaSeriesService, 
  DateTimeService, CategoryService, MultiColoredAreaSeriesService } from '@syncfusion/ej2-angular-charts';
import { ConsoleServerMonitorComponent } from './console-server-monitor/console-server-monitor.component';
import { ColumnSeriesService, ExportService, LegendService, DataLabelService} from '@syncfusion/ej2-angular-charts';

/**
 * 20201111 toggle button
 */
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';

@NgModule({
  declarations: [
    ConsoleImageListComponent,
    ConsoleIpListComponent,
    ConsoleKeypairListComponent,
    ConsoleServerListComponent,
    ConsoleServerSettingComponent,
    ConsoleStorageListComponent,
    ConsoleSnapShotListComponent,
    ConsoleRuleManageListComponent,
    ConsoleSecurityGroupListComponent,
    ConsoleNavComponent,
    ConsoleLoadBalancerListComponent,
    ConsoleLoadBalancerSettingComponent,
    ConsoleCommonModalComponent,
    ConsoleSubuserListComponent,
    ConsoleDashboardComponent,
    ConsoleNasListComponent,
    ConsoleSubnetListComponent,
    ConsoleNetworkListComponent,
    ConsoleDbPostgresqlComponent,
    ConsoleDnsListComponent,
    ConsoleCommercialDbmsComponent,
    ConsoleCommercialDbmsSettingComponent,
    ConsoleBackupDbComponent,
    ConsoleBackupServerComponent,
    ConsoleMessageQueueComponent,
    ConsoleDbInstanceComponent,
    ConsoleDbBlsComponent,
    ConsoleBaremetalListComponent,
    ConsoleBaremetalSettingComponent,
    tableFilterPipe,
    ConsoleProjectsComponent,
    ConsoleTestComponent,
    CommonChkboxComponent,
    /** 20200826 cbkim 프로젝트목록 추가 */
    ConsoleProjectListComponent,
    /** 20200901 cbkim 사설네트워크 추가 */
    ConsolePrivateNetworkListComponent,
    // 20200908 ogto 네트워크/서버/스토리지/프로젝트 정보 추가
    ConsoleNetworkStatusComponent,
    ConsoleServerStatusComponent,
    ConsoleStorageStatusComponent,
    ConsoleProjectStatusComponent,
    ConsoleServerMonitorComponent,
    /** 20200910 mby 네트워크 토폴로지  */
    // ConsoleTopologyComponent,
    
    /** 20210106 ldh API Gateway  */
    ConsoleApigatewayServiceComponent,
    ConsoleApigatewayApiListComponent,
    ConsoleApigatewayAuthListComponent,
    ConsoleApigatewayUsageComponent,
    ConsoleApigatewayApiSettingComponent,
    ConsoleApigatewayStatusComponent,

    // 20201123 boogle 오브젝트 스토리지 추가
    ConsoleObjectStorageListComponent,
    ConsoleBatchComponent
  ],
  
  imports: [    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ConsoleRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastModule,UploaderModule,
    /** 20200813 cbkim 다국어지원 */
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient] } }),
    /** 20200817 cbkim charts */
    ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule,RangeNavigatorModule,StockChartAllModule,
    /** 20200819 cbkim clipboard */
    ClipboardModule,
    MatDialogModule,
    DialogModule,
    MatExpansionModule,
    // NgxGraphModule,
    // NgxChartsModule,
    MatTabsModule,
    MatListModule,
    /** 20201005 cbkim area charts */
    ChartModule,
    /** 20201111  */
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DropDownButtonModule,
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers:[
    AuthGuard,
    AreaSeriesService, RangeAreaSeriesService, StepAreaSeriesService, StackingAreaSeriesService, 
    DateTimeService, CategoryService, MultiColoredAreaSeriesService,
    /** 20201102 charts */
    PeriodSelectorService,
    ColumnSeriesService, ExportService, LegendService, DataLabelService,
    ThemeGuard,
  ]
})

export class ConsoleModule { }