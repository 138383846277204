import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { postcode } from 'src/assets/js/util.js';
import { WebApiService } from '../../../webapi.service';
import { Router, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare const $: any;

@Component({
  selector: 'app-mypage-company',
  templateUrl: './mypage-company.component.html',
})
export class MypageCompanyComponent implements OnInit {
  userId = '';
  registrationNumber = '';
  registrationNumberSub ='';
  status = '';
  
  userInfoList: any = [];
  submitted = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private spiner: NgxSpinnerService,
    private webapi: WebApiService,
    private router: Router
  ) { }

  //company-admin form
  companyAdminForm = new FormGroup({
    user: new FormGroup({
      name: new FormControl('', [Validators.pattern(/[가-힣]/)]),
      userId: new FormControl(''),
      passwordOrg: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$%^&*()_\[+=\]{}~\?:;`|]/),
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl(''),
      telephone: new FormControl('', [
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
      ]),
      mobile: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
      ]),
      zipCode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      addressDetail: new FormControl('', [Validators.required]),
    }),
    company: new FormGroup({
      companyName: new FormControl('', [Validators.required]),
      registrationName: new FormControl('', [
        Validators.required,
        Validators.pattern(/[가-힣]/),
      ]),
      registrationNumber: new FormControl(''),
      registrationNumberSub: new FormControl(''),
      telephone: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
      ]),
      address: new FormControl('', [Validators.required]),
      addressDetail: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
    }),
  });

  get companyForm() {
    return this.companyAdminForm.controls;
  }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.getInfoList();
  }

  getInfoList() {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    this.spiner.show();
    this.webapi
      .getCompany(this.userInfoList.registrationNumber,this.userInfoList.registrationNumberSub).subscribe(
        (response) => {
          const data = response.body
          console.log(data);
          this.registrationNumber = data['registrationNumber']
          this.registrationNumberSub = data['registrationNumberSub']
          this.status = data['status']
          this.companyAdminForm.patchValue({
            company: {
              companyName: data['companyName'],
              registrationName: data['registrationName'],
              registrationNumber: data['registrationNumber'],
              registrationNumberSub: data['registrationNumberSub'],
              telephone: data['telephone'],
              zipCode: data['zipCode'],
              address: data['address'],
              addressDetail: data['addressDetail'],
            },
          });
        }), (error: any) => {

        }
    this.webapi.getUser(this.userInfoList.userId).subscribe(
      (response) => {
        const data = response.body;
        this.userId = data['userId'];
        this.companyAdminForm.patchValue({
          user: {
            name: data['name'],
            userId: data['userId'],
            telephone: data['telephone'],
            mobile: data['mobile'],
            zipCode: data['zipCode'],
            address: data['address'],
            addressDetail: data['addressDetail'],
          },
        });
      });
    this.spiner.hide();  
  }

  openDaumPopup(params) {
    if (params === 'user') {
      postcode((data: any) => {
        this.companyAdminForm.patchValue({
          user: {
            zipCode: data.zonecode,
            address: data.address,
            addressDetail: '',
          },
        });
        $('#addressDetailUser').focus();
      });
    } else {
      postcode((data: any) => {
        this.companyAdminForm.patchValue({
          company: {
            zipCode: data.zonecode,
            address: data.address,
            addressDetail: '',
          },
        });
        $('#addressDetailCompany').focus();
      });
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.companyAdminForm.invalid) {
      const invalid = [];
      const controls = this.companyAdminForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      return;
    }
    //비밀번호 체크 로직
    if (
      this.companyAdminForm.controls.user.get('password').value !==
      this.companyAdminForm.controls.user.get('confirmPassword').value
    ) {
      $('#confirmPassword').focus();
      alert('신규 비밀번호가 일치하지 않습니다.');
      return;
    }
    this.spiner.show();
    this.webapi
      .updateCompany(this.userInfoList.userId, this.companyAdminForm.value)
      .subscribe(
        (response) => {
          alert('정보수정에 성공했습니다.');
          const data = response.body;
          console.log(data)
          this.companyAdminForm.patchValue({
            company: {
              companyName: data['company']['companyName'],
              registrationName: data['company']['registrationName'],
              registrationNumber: data['company']['registrationNumber'],
              registrationNumberSub: data['company']['registrationNumberSub'],
              telephone: data['company']['telephone'],
              zipCode: data['company']['zipCode'],
              address: data['company']['address'],
              addressDetail: data['company']['addressDetail'],
            },
            user: {
              name: data['user']['name'],
              userId: data['user']['userId'],
              telephone: data['user']['telephone'],
              passwordOrg: '',
              password: '',
              confirmPassword: '',
              mobile: data['user']['mobile'],
              zipCode: data['user']['zipCode'],
              address: data['user']['address'],
              addressDetail: data['user']['addressDetail'],
            },
          });
        },
        (err) => {
          alert('정보수정에 실패했습니다.');
        }
      );
    this.spiner.hide();  
    this.submitted = false;
  }

  withdrawal(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        userId: this.userId,
        registrationNumber: this.registrationNumber,
        registrationNumberSub: this.registrationNumberSub,
      },
      skipLocationChange: true,
    };
    this.router.navigate(['/web/mypage/withdrawal'], navigationExtras);
  }
}
