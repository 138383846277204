<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>이미지 설정</h2>
        <div class="lineMap">
            Admin  /  <span>Image</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm" #f="ngForm" (ngSubmit)="onSubmit(f.value)">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>Step 1. 이미지 설정</h3>
                        <div class="btnArea">
                            <a href="#this" class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                        </div>
                    </div>
                    <div class="contArea" style="display:block" id="contMain"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">설명</th>
                                        <td>
                                            <span>이미지생성 설명 입니다.</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">상세정보</th>
                                        <td>
                                            <span>이미지 상세정보 입니다.</span>
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <th scope="row">이름</th>
                                        <td>
                                            <input type="text" name="name" ngModel id="" value="" placeholder="내용을 입력해주세요" title="" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">설명</th>
                                        <td>
                                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" title="" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">소스</th>
                                        <td>
                                            <span>이미지 소스 입니다.</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">파일</th>
                                        <td>
                                            <input (change)="onFileChange($event.target.files[0])" type="file" name="data" ngModel id="" value="" placeholder="내용을 입력해주세요" title="" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">포멧</th>
                                        <td>
                                            <select name="" id="" required>
                                                <option value="ISO">ISO</option>
                                                <option value="OVA">OVA</option>
                                                <option value="PLOOP">PLOOP</option>
                                                <option value="QCOM2">QCOM2</option>
                                                <option value="raw">raw</option>
                                                <option value="VDI">VDI</option>
                                                <option value="VDH">VDH</option>
                                                <option value="VMDK">VMDK</option>
                                                <option value="AKI">AKI</option>
                                                <option value="AMI">AMI</option>
                                                <option value="ARI">ARI</option>
                                                <option value="Docker">Docker</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">요구사항</th>
                                        <td>
                                            <span>이미지 요구사항 입니다.</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">커널</th>
                                        <td>
                                            <select name="" id="" required>
                                                <option value="ISO">-커널-</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">램디스크</th>
                                        <td>
                                            <select name="" id="" required>
                                                <option value="ISO">-램디스크-</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">아키텍처</th>
                                        <td>
                                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" title="" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">최소 디스크 (GB)</th>
                                        <td>
                                            <input type="number" name="" id="" value="" placeholder="숫자만 입력해주세요" title="" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">최소 RAM (MB)</th>
                                        <td>
                                            <input type="number" name="" id="" value="" placeholder="숫자만 입력해주세요" title="" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">이미지 공유</th>
                                        <td>
                                            <label for="r1"><input type="radio" id="r1" name="radio" value="" checked="checked">사설</label>
                                            <label for="r2"><input type="radio" id="r2" name="radio" value="">공유</label>
                                            <label for="r3"><input type="radio" id="r3" name="radio" value="">커뮤니티</label>
                                            <label for="r4"><input type="radio" id="r4" name="radio" value="">공용</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">보호됨</th>
                                        <td>
                                            <label for="Y"><input type="radio" id="Y" name="radio" value="" checked="checked">예</label>
                                            <label for="N"><input type="radio" id="N" name="radio" value="">아니오</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1"></div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <div class="lyBox">
                    <div class="titArea">
                        <h3>Step 2. 메타데이터 편집</h3>
                        <div class="btnArea">
                            <a href="#this" class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                        </div>
                    </div>
                    <div class="contArea" style="display:block" id="contDetail">
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width: 85%;"/>
                                    <col style="width: 15%;"/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>기존 메타데이터</th>
                                        <th>
                                            <select name="">
                                                <option value="">-필터-</option>
                                            </select>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td><input type="text" name="" value="code-1234" readonly></td>
                                        <td><button class="btn sml dGray">삭제</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width: 85%;"/>
                                    <col style="width: 15%;"/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">사용 가능한 메타데이터</th>
                                        <th>
                                            <select name="">
                                                <option value="">-필터-</option>
                                            </select>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td><input type="text" name="" value=""></td>
                                        <td><button class="btn sml dGray">추가</button></td>
                                    </tr>
                                    <tr>
                                        <td><input type="text" name="" value="code-1234" readonly></td>                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1">
                            <button type="submit" class="btn big orange" >이미지 생성</button>
                            
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

            </div>
        </section>
        <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->
