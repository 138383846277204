import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OsapiService } from '../../osapi.service';
import { FrontApiService} from '../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router} from '@angular/router';


import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';
import { fips } from 'crypto';

declare function valiAllChk(params:any);

@Component({
  selector: 'app-console-ip-list',
  templateUrl: './console-ip-list.component.html',
})

// @Component({
//   selector: 'dialog-content-example-dialog',
//   templateUrl: 'dialog-content-example-dialog.html',
// })
// export class DialogContentExampleDialog {}

export class ConsoleIpListComponent implements OnInit {
  portfowarding_ip: any = {};
  ipList: any = [];
  serverList: any = [];
  portList: any = [];

   // 검색 텍스트
   filterText: string;
   
   isActived: boolean = false;
   isChecked: boolean = false;

   selected_floatingip: any = {};
  selected_port_id: string = '';
  selected_int_ip: string = '';

  edit_fip_description: string="";

  loading_flag =11;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private http: HttpClient,
    private router:Router,
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    // public dialog: MatDialog,
    public dialogs: DialogSetService,
    @Inject(ConsoleNavComponent) private parent:ConsoleNavComponent
  ) {}

  // openDialog(){
  //   this.dialogs.openCreateIpDialog({});
  // }
  message:any;
  ngOnInit(): void {
    this.loading_flag =11;
    this.renderer.addClass(this.document.body, 'bg_blue');
    this.getIpList();
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);    
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }

  onClickList(fip) {
    this.isActived = true;
    this.selected_floatingip = fip;
    this.edit_fip_description = fip.description;
  }
  
  getIpList() {
    this.spinner.show();
    this.loading_flag =11;
    this.osapi
      .getFloatingipList()
      .subscribe((data: { items:any }) => {
        this.ipList=[];
        data.items.forEach((fip) => {
          if (fip.is_port_forwarding) {
            this.portfowarding_ip = fip;
          } else {
            this.ipList.push(fip);
          }
        });
        this.loading_flag-=10;
        this.parent.getThemeFont();
        this.spinner.hide();
        this.getServerList();
        this.getLoadbalancerList();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
  }
  getPortList() {
    this.portList=[];
    this.serverList.forEach((server) => {
      if (
        server.ip_public == null &&
        server.network_interfaces != null &&
        server.port_forwarding_ip == null
      ) {
        server.network_interfaces.forEach((net_interface) => {
          net_interface.ports.forEach((port) => {
            let tmp_port: {
              port_id: string;
              server_id: string;
              server_name: string;
              interface_name: string;
              address: string;
              subnet_id: string;
              port_security_enabled: boolean;
              security_groups: [];
            };
            tmp_port = {
              port_id: port.id,
              server_id: server.server_id,
              server_name: server.server_name,
              interface_name: net_interface.name,
              address: port.fixed_ips[0].ip_address,
              subnet_id: port.fixed_ips[0].subnet_id,
              port_security_enabled: port.port_security_enabled,
              security_groups: port.security_groups,
            };
            this.portList.push(tmp_port);
          });
        });
      }
    });
  }

  /**
   * 20200928 cbkim
   * 로드밸런서 리스트 가져와서 FIP 매핑
   */
  currentTimer;
  loadbalancerList;

  getLoadbalancerList(){
    this.spinner.show();
    this.loadbalancerList = [];
    this.osapi.getLoadbalancerList().subscribe(
      (data:any)=>{
        this.loadbalancerList = data.items;
        this.loadbalancerList = this.loadbalancerList.filter(lb => lb.provisioning_status != "PENDING_DELETE");
        this.getLoadbalancer();
        this.spinner.hide();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      }
    )
  }

  async getLoadbalancer() {
    if (this.router.url != "/console/publicip") {
      clearTimeout(this.currentTimer);
      return;
    }

    if (this.loadbalancerList != undefined) {
      for (let lb of this.loadbalancerList) {
        let data: any = await this.osapi.getLoadbalancerFull(lb.id).toPromise();
        if (data.floating_ip.hasOwnProperty('ip')) {
          lb.floating_ip = data.floating_ip;

          for (let fip of this.ipList) {
            if (fip.port_details) {
              if (fip.port_details.hasOwnProperty('device_id')) {
                if (fip.port_details.device_id == ("lb-" + lb.id)) {
                  lb.server_name = "로드밸런서 " + lb.name;
                  lb.fixed_ip = lb.vip_address;
                  fip.server = lb;

                  /** IP 추출하면 리스트에서 삭제 */
                  this.loadbalancerList = this.loadbalancerList.filter(item => item.id != lb.id);
                }
              }
            }
          }
        }
      }
      
      /** IP 추출이 완료되면 타이머 멈춤, 공인IP할당안된 LB가있을 경우 루프 돌 수 있음 */
      if(this.loadbalancerList.length == 0){
        clearTimeout(this.currentTimer);
        return;
      }
      this.currentTimer = setTimeout(() => { this.getLoadbalancer() }, 1000);
    }
  }

  getServerList() {
    this.spinner.show();

    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList();
      }, 200)
    } else {
      this.serverList = this.parent.serverList;
      this.ipList.forEach((fip) => {
        if (fip.port_details) {
          this.serverList.forEach((server) => {
            if (fip.instance_id == server.server_id) {
              fip.server = server;
            }
          });
        }
      });
      this.loading_flag-=1;
      this.getPortList();
      this.spinner.hide();
      // console.log(this.ipList);
    }

    

  


    // this.osapi.getServerList().subscribe((data: { items: [] }) => {
    //   this.serverList = data;
    //   this.ipList.forEach((fip) => {
    //     if (fip.port_details) {
    //       this.serverList.forEach((server) => {
    //         if (fip.instance_id == server.server_id) {
    //           fip.server = server;
    //         }
    //       });
    //     }
    //   });
    //   this.loading_flag-=1;
    //   this.getPortList();
      
    //   this.spinner.hide();
    // }, 
    // (error) => {
    //   this.spinner.hide();
    //   this.frontapi.changeMessage({
    //     type:'toast',
    //     body:{
    //       type:'error',
    //       title:'오류 알림',
    //       message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
    //     }
    //   });
    // });
  }

  openNewFloatingIpModal(){
    this.dialogs.openCreateIpDialog({});
    // this.global.openNewFloatingIpModal();
  }
  openEditFloatingIpModal(){
    if(this.selected_floatingip.id && this.loading_flag <= 0){
      this.dialogs.openUpdateIpDialog(
        {
          selected_floatingip:this.selected_floatingip,
        }
      );
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "success"){
          this.getIpList();
        }
      });
    }
    else{
      setTimeout(() =>  { this.openEditFloatingIpModal(); }, 500);
    }
  }

  openDeleteFloatingIpModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"공인IP 반납",
      content:"선택하신 IP("+this.selected_floatingip.ip+")를 정말로 반납하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteFloatingIp();
        }
      });
  }
  openDisconnect(){
    if(this.loading_flag > 0){
      this.spinner.show();
    }
    if(this.selected_floatingip.id && this.loading_flag <= 0){
      this.dialogs.openDisassociateIpDialog(
        {
          selected_floatingip:this.selected_floatingip,
        }
      );
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "success"){
          this.parent.getServerList().then(() => {
            this.getIpList();
          });
        }
      });
    }
    else{
      setTimeout(() => 
      {
          this.openDisconnect();
      },
      500);
    }
  }
  openConnect(){
    if(this.loading_flag > 0){
      this.spinner.show();
    }
    if(this.loading_flag <= 0){
      this.dialogs.openAssociateIpDialog(
        {
          ipList:this.ipList,
          portList:this.portList,
          selected_floatingip:this.selected_floatingip,
        }
      );
      
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "success"){
          this.parent.getServerList().then(() => {
            this.getIpList();
          });
        }
      });
    }
    else{
      setTimeout(() => 
      {
          this.openConnect();
      },
      500);
    }
  }
  
  deleteFloatingIp() {
    this.spinner.show();
    this.osapi
      .deleteFloatingIp(this.selected_floatingip.id)
      .subscribe((data: {}) => {
        this.spinner.hide();
        this.getIpList();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
  }
}
