import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FrontApiService} from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-subuser-list',
  templateUrl: './console-subuser-list.component.html',
})

export class ConsoleSubuserListComponent implements OnInit {
  // list: any[];
  projectList: any = [];
  subUsers: any = [];
  selected_subUser: any = {};

  // 검색 텍스트
  filterText: string;
  
  isActived: boolean = false;
  isChecked: boolean = false;

  is_projectlist_loaded=false;
  isMasterAdmin = false;

  constructor(
    private osapi: OsapiService,
    private webapi: WebApiService,
     private spinner: NgxSpinnerService,
     private frontapi:FrontApiService,
     public global: GlobalService,
     private dialogs:DialogSetService,
     private router:Router,
     private common:ConsoleNavComponent
     ) {
    // this.list = [
    //   { name: '요금', checked: false },
    //   { name: '고객지원', checked: false },
    //   { name: '서비스', checked: false },
    // ];
  }
  ngOnInit(): void {
    this.isMasterAdminCheck();
    this.getProjectList();
    this.getSubUsers();
  }
  isMasterAdminCheck(){
    this.webapi.isProjectAdmin().subscribe(data => {

      this.isMasterAdmin = data.result
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }
  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);    
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }

  onClickList(subUser) {
    this.isActived = true;
    this.selected_subUser = subUser;

  }

  openSubuserCreateModal(){
    if(this.is_projectlist_loaded){
      this.dialogs.openSubuserCreateDialog({projectList:this.projectList});
    }else{
      setTimeout(() => { this.openSubuserCreateModal(); }, 500);
    }
  }

  openSubuserUpdateModal(){
    if(this.is_projectlist_loaded&&this.selected_subUser.is_project_loaded){
      this.spinner.show();
      this.dialogs.openSubuserUpdateDialog({projectList:this.projectList,selected_subUser:this.selected_subUser});
      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/subuser']);
            }
          );
        }
      });
    }else{
      setTimeout(() => { this.openSubuserUpdateModal(); }, 500);
    }
  }
  openSubuserDeleteModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"서브계정 삭제",
      content: "'"+this.selected_subUser.name+"'의 계정을 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteSubUser();
        }
      });
  }

  openMasterDelegateModal(){
    this.dialogs.openDefaultConfirmDialog({
      title: "계정 권한 위임",
      content: "정말로 권한을 위임하시겠습니까?",
      btnText: "확인"
    });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.delegateMaster();
      }
    });
  }
  delegateMaster(){
    this.osapi.delegateMaster({
      user_id:this.selected_subUser.userId
    })
    .subscribe((data: any) => {
      this.router.navigateByUrl('/console', {
        skipLocationChange: true,
      }).then(
        () => {
          this.router.navigate(['/console/subuser']);
        }
      );
      
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
      type:'toast',
      body:{
        type:'error',
        title:'오류 알림',
        message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
      }
    });
    });
  }
  deleteSubUser() {

    this.spinner.show();
    this.osapi
      .deleteSubUser({
        users: {
          horizonUsers: [this.selected_subUser.horizonUid],
          blsUsers: [this.selected_subUser.userId],
        },
      })
      .subscribe((data: any) => {
        this.spinner.hide();

        
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigate(['/console/subuser']);
          }
        );
        
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
  }

  getSubUsers() {
    this.spinner.show();
    this.osapi.getSubUsers().subscribe((data: { result: object }) => {

      if (data.result == null) {
        throw 'osapi.getSubUsers Fail';
      }
      this.subUsers = data.result;
      for(let subuser of this.subUsers){
        subuser.is_project_loaded = false;
        this.setProjectsById(subuser);
      }
      this.common.getThemeFont();
      this.spinner.hide();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        }
        this.projectList = data.items;

        this.is_projectlist_loaded=true;
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
    } catch (err) {
      console.error(err);
    }
  }
  

  setProjectsById(subuser) {
    try {
      subuser.projects=[{name:"Loading..."}]
      if(subuser.horizonUid){
        this.osapi.getProjectsById(subuser.horizonUid).subscribe((data: { items }) => {
          subuser.is_project_loaded = true;

          subuser.projects = data.items;
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
        });
      }
    } catch (e) {

    }
  }
}
