
<!-- Modal : 크기 증설 모달 -->
<div id="volume_expansion" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        크기 증설
        <p class="sub-txt">| 선택한 NAS 의 크기를 증설합니다. <br><span class="red">| 한번 크기를 증설하면 줄일 수 없습니다. </span></p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tbody>
            <tr>
              <th>NAS 이름</th>
              <td>
                <input type="text" value="{{ selected_nas.name }}" readonly />
              </td>
            </tr>
            <tr>
              <th>현재 크기(GB)</th>
              <td>
                <input type="number" value="{{ selected_nas.size }}" readonly />
              </td>
            </tr>
            <tr>
              <th>증설 크기</th>
              <td>
                <input
                  type="number"
                  [(ngModel)]="newSize"
                  placeholder="숫자만 입력"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp1">
        <a (click)="extendNas()" class="btn big ciblue mg_r4" >확인</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->
  
  