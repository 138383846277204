import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-associate-ip',
  templateUrl: './dialog-associate-ip.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogAssociateIpComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogAssociateIpComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }

  ipList: any = [];
  portList;
  selected_port_id = "";
  seleted_floatingip_id = "";

  ngOnInit(): void {
    this.ipList = this.dialogData.ipList;
    this.portList = [];
    
    for (let port of this.dialogData.portList) {
      if(port.interface_name !="net_str" && port.interface_name !="net_pri"){
        this.portList.push(port);
      }else{
      }
    }
    if (this.dialogData.selected_floatingip) {
      if (this.dialogData.selected_floatingip.id) {
        this.seleted_floatingip_id = this.dialogData.selected_floatingip.id;
      } else {
        this.seleted_floatingip_id = ""
      }
    }
    if (this.dialogData.selected_port_id) {
      this.selected_port_id = this.dialogData.selected_port_id;
      for (let port of this.portList) {
        if (port.port_id + '_' + port.address == this.selected_port_id) {
          return;
        }
        
      }
      this.selected_port_id = "";
    }
  }

  associateFloatingIp() {
    if (this.seleted_floatingip_id == "") {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '오류 알림',
          message: '할당할 공인IP를 선택해주세요.'
        }
      });
      return;
    }
    if (this.selected_port_id == "") {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '오류 알림',
          message: '공인IP를 할당할 서버를 선택해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi
      .associateFloatingIp(this.seleted_floatingip_id, this.selected_port_id)
      .subscribe((data: {}) => {
        this.spinner.hide();
        this.closeDialogMsg("success");

      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }
}
