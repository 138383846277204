<div id="Header">
    <div class="logo"style="cursor: pointer;" (click)="routerHome()">
        <a></a>
    </div>
</div>
<!-- Container -->
<div id="Container" class="login">
    <div class="findpw">
        <!-- frm_content -->
        <div class="frm_content">
            <!-- logo -->
            <div class="logoArea">
                <div class="logo">
                    <img src="../assets/img/member/logo_member.png" alt="" routerLink="/" style="cursor: pointer;">
                </div>
                <h1>비밀번호 찾기</h1>
            </div>
            <!-- //logo -->
            <!-- form -->
            <!-- loginForm -->
            <fieldset class="loginForm">
                <legend>비밀번호 찾기</legend>
                <ul class="row_group">
                    <li class="row">
                        <div class="tit">인증코드 전송 방식</div>
                        <div class="cont">
                            <div class="inp">
                                <label class="mg_r8 on"><input type="radio" name="chk_group" value="email"
                                        (change)="changePsswordType($event)" checked> 이메일</label>
                                <label class="mg_r8 on"><input type="radio" name="chk_group" value="phone"
                                        (change)="changePsswordType($event)"> SMS</label>
                            </div>
                        </div>
                    </li>
                    <ng-template [ngIf]="findPasswordType ==='email'">
                        <form [formGroup]="findpwdEmailForm" (ngSubmit)="onSubmitEmail()">
                            <li class="row">
                                <div class="tit">아이디</div>
                                <div class="cont">
                                    <input type="text" name="input" autocomplete="off" spellcheck="false"
                                        placeholder="아이디를 입력해주세요." id="userId" formControlName="userId" required>
                                </div>
                                <div *ngIf="submitted && findpwdEmail.userId.errors" class="invalid-feedback tempError">
                                    <div *ngIf="findpwdEmail.userId.errors.required">아이디를 입력해주세요.</div>
                                    <div *ngIf="findpwdEmail.userId.errors.pattern">이메일 형식이 아닙니다.</div>
                                </div>
                            </li>
                            <button type="submit" id="findpassword" class="btn_login">비밀번호 찾기</button>
                        </form>
                    </ng-template>
                    <ng-template [ngIf]="findPasswordType ==='phone'">
                        <form [formGroup]="findpwdPhoneForm" (ngSubmit)="onSubmitPhone()">
                            <li class="row">
                                <div class="tit">아이디</div>
                                <div class="cont">
                                    <input type="text" name="input" autocomplete="off" spellcheck="false"
                                        placeholder="아이디를 입력해주세요." id="userId" formControlName="userId" required>
                                </div>
                                <div *ngIf="submitted && findpwdPhone.userId.errors" class="invalid-feedback tempError">
                                    <div *ngIf="findpwdPhone.userId.errors.required">아이디를 입력해주세요.</div>
                                    <div *ngIf="findpwdPhone.userId.errors.pattern">이메일 형식이 아닙니다.</div>
                                </div>
                            </li>
                            <li class="row">
                                <div class="tit">핸드폰 번호</div>
                                <div class="cont">
                                    <input type="text" name="input" autocomplete="off" spellcheck="false"
                                        placeholder="핸드폰 번호를 입력해주세요." id="mobile" formControlName="mobile" required
                                        maxlength="11">
                                </div>
                                <div *ngIf="submitted && findpwdPhone.mobile.errors" class="invalid-feedback tempError">
                                    <div *ngIf="findpwdPhone.mobile.errors.required">핸드폰 번호를 입력해주세요.</div>
                                    <div
                                        *ngIf="findpwdPhone.mobile.errors.pattern || findpwdPhone.mobile.errors.minlength">
                                        10 ~ 11자리 숫자만 입력해 주세요</div>
                                </div>
                            </li>
                            <button type="submit" id="findpassword" class="btn_login">비밀번호 찾기</button>
                        </form>
                    </ng-template>
                    <li class="row" id="confirmCodeLi" style="display: none;">
                        <div class="tit" id="timer">
                            인증코드
                        </div>
                        <div class="cont">
                            <div class="inpTp1">
                                <input type="text" id="confirmCode" spellcheck="false" autocomplete="off"
                                    placeholder="인증코드" maxlength="6" style="width:40%; text-align: center;">
                                <a style="cursor: pointer; width: 25%; margin-left: 10px;"
                                    (click)="confirmCodeCheck()">확인</a>
                                <a style="cursor: pointer; width: 25%; margin-left: 10px;"
                                    (click)="reConfirmCode()">재전송</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="chk_group"></div>
                <div class="login_footer">
                    <a href="/">HOME</a>
                    <a href="/member/find-id">아이디 찾기</a>
                    <a href="/member/signUp">회원가입</a>
                </div>
            </fieldset>
            <!-- //loginForm -->
        </div>
        <!-- //frm_content -->
    </div>
</div>
<!-- //ROUTER -->