
<!-- Modal : 공인IP 할당 모달 -->
<div id="modal_publicip" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            헬스모니터 수정
            <p class="sub-txt">로드밸런서 헬스모니터 정보를 수정 할 수 있습니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">유형</th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.type" readonly>
                        </td>
                    </tr>
                    <tr *ngIf="lbmonitor.type == 'HTTP'">
                        <th scope="row">URL</th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.url_path" required>
                        </td>
                    </tr>
                    <tr *ngIf="lbmonitor.type == 'HTTP'">
                        <th scope="row">응답 코드</th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.expected_codes" required>
                        </td>
                    </tr>
                    <tr *ngIf="lbmonitor.type == 'HTTP'">
                        <th scope="row">HTTP 메소드</th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.http_method" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">최대 응답 대기 시간 </th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.timeout" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">상태 확인 주기 </th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.delay" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">최대 재시도 횟수 </th>
                        <td>
                            <input type="text" [(ngModel)]="lbmonitor.max_retries" required>
                        </td>
                    </tr>                                   
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="updateMonitor()" class="btn big ciblue mg_r4">적용</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->