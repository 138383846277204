<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>스냅샷</h2>
    <div class="lineMap">Storage / <span>Snapshot</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <form id="frm" class="frmBox1">
      <!-- section -->
      <section class="sec col12">
        <div class="sec_content">
          <!-- lyBox -->
          <div class="lyBox">
              <div class="title_wrap">
                  <div class="contArea">
                      <div class="tit">
                          <app-console-projects></app-console-projects>
                      </div>
                      <div class="cont">
                          <app-console-storage-status></app-console-storage-status>
                      </div>
                      <div class="contSub">
                          <a class="btn mid blue mg_r4" (click)="openNewSnapshotModal()"><i class="ico ico_register_white"></i>스냅샷 생성</a>
                          <!-- <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openDeleteSnapshotModal()"><i class="ico ico_cancel_white"></i>스토리지 스냅샷 삭제</button> -->
                      </div>
                  </div>
              </div>
          </div>
<!-- 
          <div class="lyBox">
            <div class="title_wrap">
              <div class="contArea">
                <div class="tit">
                    <app-console-projects></app-console-projects>
                </div>
                <div class="cont">
                    <a class="btn mid blue mg_r4" (click)="openNewSnapshotModal()"><i class="ico ico_register_white"></i>스토리지 스냅샷 생성</a>
                    <button class="btn mid ciblue mg_r4" [disabled]="!isActived"
                      (click)="openDeleteSnapshotModal()"><i class="ico ico_cancel_white"></i>스토리지 스냅샷 삭제</button>
                </div>
              </div>
            </div>
          </div> -->
          <!-- //lyBox -->
          <!-- lyBox -->
          <div class="lyBox consoleRightBody">
            <div class="titArea">
              <h3>스냅샷 목록</h3>
              <div class="btnArea">
                <div class="input-group fl">
                  <input type="search" class="form-control" name="" id="search" value="" [(ngModel)]="filterText"
                    [ngModelOptions]="{standalone: true}" placeholder="검색어를 입력해주세요" />
                  <div class="input-group-btn pd_r10">
                    <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                  </div>
                  <!-- 접기 버튼 -->
                  <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                </div>
              </div>
            </div>
            <div class="contArea" style="display: block;">
              <div class="boardBox">
                <table class="tbl_col_tp1 dropmenu">
                  <colgroup>
                    <col style="width: 5%;" />
                    <col style="width: 20%;" />
                    <col style="width: 20%;" />
                    <col style="width: 7%;" />
                    <col style="width: 14%;" />
                    <col style="width: 14%;" />
                    <col style="width: 20%;" />
                  </colgroup>
                  <thead>
                    <th scope="col">
                        <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                    </th>
                    <th scope="col">이름</th>
                    <th scope="col">설명</th>
                    <th scope="col">크기</th>
                    <th scope="col">상태</th>
                    <th scope="col">그룹</th>
                    <th scope="col">스토리지 이름</th>
                  </thead> 
                  <tbody>
                    <tr *ngFor="let snapshot of snapShotLists  | tableFilter: filterText"
                      (contextmenu)="onClickList(snapshot)">
                      <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)" /></td>
                      <td>{{ snapshot.name }}</td>
                      <td *ngIf="snapshot.description != null && snapshot.description != ''; else elseCase">
                        {{ snapshot.description }}
                      </td>
                      <td>{{ snapshot.size }}GiB</td>
                      <td>
                        <label [class]="
                            snapshot.status == 'available'
                              ? 'dot green'
                              : 'dot red'
                          "></label>{{ snapshot.status | uppercase }}
                      </td>
                      <td>없음</td>
                      <td *ngIf="snapshot.volume_name != null; else elseCase">
                        {{ snapshot.volume_name }}
                      </td>
                      <ng-template #elseCase>
                        <td>없음</td>
                      </ng-template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="btn_wrap tp1"></div>
            </div>
          </div>
          <!-- //lyBox -->
        </div>
      </section>
      <!-- //section -->
    </form>
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropmenu -->
<div class="dropdown">
  <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
  <div id="myDropdown" class="dropdown-content">
    <!-- <a href="#" >Server 생성</a> -->
    <a (click)="openNewStorageModal()">스토리지 생성</a>
    <a (click)="openUpdateSnapshotModal()">기본정보 수정</a>
    <a (click)="openDeleteSnapshotModal()">스냅샷 삭제</a>
  </div>
</div>


<!-- Modal : 스토리지 생성 모달 -->
<div *ngIf="selected_snapshot != null" id="snapshot_to_storage_create" class="modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      스토리지 생성
      <p class="sub-txt">| 스토리지 스냅샷을 이용하여 스토리지를 생성합니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">스토리지 이름</th>
            <td>
              <input type="text" name="" id="" [(ngModel)]="newStorageData.name" placeholder="내용을 입력해주세요"
                (change)="onNewStorageNameChange($event.target.value)" required />
            </td>
          </tr>
          <tr>
            <th scope="row">스토리지 설명</th>
            <td>
              <textarea name="" id="" [(ngModel)]="newStorageData.description" rows="5" placeholder="내용을 입력해주세요"
                (change)="onNewStorageDescChange($event.target.value)"></textarea>
            </td>
          </tr>
          <!-- 스냅샷 일 경우 -->
          <tr>
            <th scope="row">스토리지 소스</th>
            <td>
              <select name="" required>
                <option [value]="selected_snapshot.id">{{ selected_snapshot.name }} ({{
                    selected_snapshot.size
                  }}GB)</option>
              </select>
            </td>
          </tr>

          <tr>
            <th scope="row">유형</th>
            <td>
              <select name="" required>
                <option value="__DEFAULT__">Default</option>
              </select>
            </td>
          </tr>
          <tr>
            <th scope="row">크기</th>
            <td>
              <input type="number" name="" id="" [ngModel]="newStorageData.size"
                (change)="onNewStorageSizeChange($event.target.value)" required />
              <!-- <label>GiB</label> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <a href="#close-modal" rel="modal:close" (click)="createVolume()" class="btn big blue mg_r4"><i class="ico ico_register_white"></i>생성</a>
      <a href="#close-modal" rel="modal:close" class="btn big dGray"><i class="ico ico_cancel_white"></i>닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->

