
<!-- Modal : 보안 그룹 선택 모달 -->
<div id="modal_security" class="modal2 lg_modal">
        <div class="modal_header">
          <h2>
            보안 그룹 선택
            <p class="sub-txt">| 보안 그룹에 적용할 보안그룹을 선택합니다. 규칙이 적용되지 않은 접근은 모두 차단됩니다.</p>
          </h2>
        </div>
        <div class="modal_content">
          <div class="boardBox bor_solid disFlex">
            <div class="w30p" style="background-color: aliceblue;">
              <table class="tbl_col_tp1">
                <thead>
                  <tr>
                    <th>대상서버 : {{selected_server.server_name}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sg of securityGroupList">
                    <td class="fl mg_l10">
                      <input type="checkbox" [id]="sg.id" [(ngModel)]="sg.checked"
                        (change)="getSecurityGroupRuleList()" /><label [for]="sg.id"> {{ sg.name }}</label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w70p bor_solid_l">
              <table class="tbl_col_tp1">
                <colgroup>
                  <col style="width: 80px;" />
                  <col style="width: 100px;" />
                  <col style="width: 100px;" />
                  <col style="width: 100px;" />
                  <col style="width: 150px;" />
                </colgroup>
                <thead>
                  <tr>
                    <th>방향</th>
                    <th>IP 프로토콜</th>
                    <th>포트 범위</th>
                    <th>Ether 타입</th>
                    <th>CIDR</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- foreach -->
                  <tr *ngFor="let rule of securityGroupRuleList">
                    <td *ngIf="rule.direction == 'egress'">송신</td>
                    <td *ngIf="rule.direction == 'ingress'">수신</td>
                    <td *ngIf="rule.ip_protocol != null; else protocolNull">
                      {{ rule.ip_protocol }}
                    </td>
                    <ng-template #protocolNull>
                      <td>전체</td>
                    </ng-template>
                    <td *ngIf="
                        rule.from_port == null || rule.to_port == null;
                        else portNotNull
                      ">
                      전체
                    </td>
                    <ng-template #portNotNull>
                      <td *ngIf="rule.to_port == rule.from_port">
                        {{ rule.to_port }}
                      </td>
                      <td *ngIf="rule.to_port != rule.from_port">
                        {{ rule.from_port }} ~ {{ rule.to_port }}
                      </td>
                    </ng-template>
                    <td>{{ rule.ethertype }}</td>
                    <td>{{ rule.ip_range.cidr }}</td>
                  </tr>
                  <!-- //foreach -->
                </tbody>
              </table>
            </div>
          </div>
          <div class="btnArea tp1">
            <a (click)="updateServerSecurityGroup()" class="btn big ciblue mg_r4">확인</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
          </div>
        </div>
      </div>