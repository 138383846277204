import { Component, OnInit } from '@angular/core';
import { MypageMemberComponent } from '../../web/mypage/mypage-member/mypage-member.component';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { WebApiService } from '../../webapi.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mobile-console-dashboard',
  templateUrl: './mobile-console-dashboard.component.html',
  styleUrls: ['./mobile-console-dashboard.component.css'],
  providers: [MypageMemberComponent]
})
export class MobileConsoleDashboardComponent implements OnInit {

  username:any = [];
  usingService: any = {
    project: 0,
    server_instance: 0,
    server_image: 0,
    storage_block: 0,
    storage_snapshot: 0,
    network_public_ip: 0 
  }

  constructor(
    private comp: MypageMemberComponent,
    private frontapi: FrontApiService,
    private spinner: NgxSpinnerService,
    private webapi: WebApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.username = this.comp.sendUserName();
    this.getServiceResourceList();
  }

  getServiceResourceList() {
    this.webapi.getServiceResourceList().subscribe((data) => {
      data.result.forEach(resource => {
        if (resource.resourceType == 'project') {
          this.usingService.project++;
        }
        if (resource.resourceType == 'instance') {
          this.usingService.server_instance++;
        }
        if (resource.resourceType == 'image') {
          this.usingService.server_image++;
        }
        if (resource.resourceType == 'volume') {
          this.usingService.storage_block++;
        }
        if (resource.resourceType == 'snapshot') {
          this.usingService.storage_snapshot++;
        }
        if (resource.resourceType == 'floating ip') {
          this.usingService.network_public_ip++;
        }
      });
    },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
    this.spinner.hide();
  }

  routerDirection(link) {
    this.router.navigate([link]);
  }
}
