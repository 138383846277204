import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-console-message-queue',
  templateUrl: './console-message-queue.component.html',
})
export class ConsoleMessageQueueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
