
<!-- Modal : 서버 기본정보 수정 모달 -->
<div id="server_edit_memo" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      서버
      <p class="sub-txt">| 서버의 사양을 변경합니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">서버 이름</th>
            <td>
              <input type="text" name="" id="serverName" readonly [value]="selected_server.server_name"  />
            </td>
          </tr>
          <tr>
            <th scope="row">기존 사양</th>
            <td>
              <input type="text" name="" id="serverFlavor" readonly [value]="selected_server.flavor_name"  />
            </td>
          </tr>
          <tr>
            <th scope="row">사양</th>
            <td>
                <select [(ngModel)]="flavor_id">
                    <option value="">변경할 사양을 선택해주세요.</option>
                    <option *ngFor="let flavor of flavorLists" [value]="flavor.id">
                        {{flavor.name}})
                    </option>
                </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a (click)="resizeServer()" class="btn big ciblue mg_r4">변경</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->