import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-network-external-manage',
  templateUrl: './dialog-network-external-manage.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNetworkExternalManageComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNetworkExternalManageComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  selected_network: any = {};
  checkAdminState: boolean = true;

  ngOnInit(): void {

    this.selected_network = this.dialogData.selected_network;
    
    if(this.selected_network.router_state){
      document.getElementById('radio_external_type_able').click();
    }else{
      document.getElementById('radio_external_type_disable').click();
    }
  }


  setAdminState(b) {
    this.checkAdminState = b;
  }


  updateRouterState() {
    this.spinner.show();
    const params = {
      admin_state_up: this.checkAdminState,
    };

    this.osapi
      .updateRouters(this.selected_network.routerId, params)
      .subscribe((data: any) => {

        this.spinner.hide();
        // this.getNetworkList();
        this.closeDialogMsg('success');

        if(this.router.url=='/console/network'){
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/network']);
            }
          );
        }else{
          this.router.navigate(['/console/network']);
        }
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }
  
}
