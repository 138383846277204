<!-- Modal : Bucket 생성 모달 -->
<div id="object_info" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      오브젝트 정보
      <p class="sub-txt">
        하위 오브젝트는 최대 9,999개까지의 정보만 확인 가능합니다.
      </p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>오브젝트 명</th>
            <td>
              {{ info.name }}
            </td>
          </tr>
          <tr>
            <th>총 오브젝트 수</th>
            <td>
              {{ info.totalLength }}
            </td>
          </tr>
          <tr>
            <th>총 파일 수</th>
            <td>
              {{ info.fileLength }}
            </td>
          </tr>
          <tr>
            <th>총 폴더 수</th>
            <td>
              {{ info.folderLength }}
            </td>
          </tr>
          <tr>
            <th>총 크기</th>
            <td>
              {{ info.size }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <!-- <a (click)="onClickUpdateRename()" class="btn big ciblue mg_r4">수정</a> -->
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->