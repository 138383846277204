
<!-- Modal : 서브넷 생성 모달 -->
<div id="subnet_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            서브넷 생성
            <!-- <p class="sub-txt"> | 서브넷 생성 설명글 입니다.</p> -->
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th>서브넷 이름</th>
                        <td><input type="text" placeholder="서브넷 명을 입력해주세요" [(ngModel)]="subnetModel.name" maxlength="60"></td>
                    </tr>
                    <tr>
                        <th>네트워크</th>
                        <td>
                            <select [(ngModel)]="subnetModel.network" (change)="selectNetwork($event.target.value)">
                                <option value="">네트워크를 선택해주세요.</option>
                                <option *ngFor="let net of networkList" [value]="net.cidr">
                                    {{net.name}} ({{net.cidr}})
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>네트워크 주소(CIDR)</th>
                        <td>
                            <input type="text" value="" placeholder="" [(ngModel)]="subnetModel.cidr"
                                (change)="onChangeSubnetCidr()">

                            <div *ngIf="network_cidr_validate==false" class="invalid-feedback">
                                <div>유효하지않은 CIDR 입니다.</div>
                            </div>
                            <div *ngIf="network_cidr_is_include==false" class="invalid-feedback">
                                <div>선택하신 '{{subnetModel.network}}' 대역의 CIDR을 지정해 주세요.</div>
                            </div>
                            <div *ngIf="network_cidr_not_used==false" class="invalid-feedback">
                                <div>해당 대역을 이미 사용 중인 서브넷이 존재합니다.</div>
                            </div>
                        </td>
                    </tr>
                    <!-- <tr>
						<th>IP 버전</th>
						<td>
							<select>
								<option selected>IPv4</option>
								<option>IPv6</option>
							</select>
						</td>
                    </tr> -->
                    <tr>
                        <th>게이트웨이 IP</th>
                        <td>
                            <input type="text" value="" placeholder="" [(ngModel)]="subnetModel.gateway_ip" readonly>

                        </td>
                    </tr>
                    <!-- <tr>
						<th>게이트웨이 비활성화</th>
                        <td>
                            <input type="checkbox" value="">
                        </td>
                    </tr>
                    <tr>
						<th>DHCP 사용여부</th>
                        <td>
                            <input type="checkbox" value="">
                        </td>
					</tr>
					<tr>
						<th>IP Pool 할당</th>
                        <td>
                            <textarea name="" value="" rows="5" placeholder="내용을 입력해주세요"></textarea>
                        </td>
                    </tr>
                    <tr>
						<th>DNS </th>
						<td><input type="text" value="" placeholder="ip 형태"></td>
                    </tr>
                    <tr>
						<th>호스트 경로</th>
                        <td>
                            <textarea name="" value="" rows="5" placeholder="내용을 입력해주세요"></textarea>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div class="btnArea tp2">
            <a (click)="createSubnet()" class="btn big ciblue mg_r4">생성</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->