import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-create',
  templateUrl: './dialog-storage-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  serverList: any=[];
  volumeList: any=[];
  imageLists: any=[];
  snapshotLists: any=[];

  attachServerId="";

  //스토리지 생성 팝업
  newStorageData: {
    name:string,
    description:string,
    availability_zone:string,
    size:number,
    volume_type:string,
    metadata:{},
    // group:string,
    source_type:string,
    snapshot_id:string,
    source_volid:string,
    image_id:string
  } ={
    name:"",
    description:"",
    availability_zone:"nova",
    size:null,
    volume_type:"__DEFAULT__",
    metadata:{},
    // group:string,
    source_type:"empty",
    snapshot_id:null,
    source_volid:null,
    image_id:null
  };
  
  // clearModalData(){
  //   this.newFloatingIpData ={description:""}
  // }
  ngOnInit(): void {

    this.getImageList();
    this.getSnapshotList();
    this.getVolumeList();
    if(Array.isArray(this.dialogData.serverList)){
      this.serverList = this.dialogData.serverList;
    }
  }


  createVolume(){
    if(!this.newStorageData.name){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    if(!this.newStorageData.size){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'크기를 입력해주세요.'
        }
      });
      return;
    }
    if(this.newStorageData.volume_type== 'image' && this.newStorageData.image_id==null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이미지를 선택해주세요.'
        }
      });
      return;
    }
    if(this.newStorageData.volume_type== 'snapshot' && this.newStorageData.snapshot_id==null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'스냅샷을 선택해주세요.'
        }
      });
      return;
    }
    if(this.newStorageData.volume_type== 'storage' && this.newStorageData.source_volid==null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'스토리지를 선택해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    //name,description,availability_zone,size,volume_type,metadata,image_id,source_volid,snapshot_id
    this.osapi.createVolume(this.newStorageData.name,this.newStorageData.description,
      this.newStorageData.availability_zone,this.newStorageData.size,
      this.newStorageData.volume_type = "__DEFAULT__",this.newStorageData.metadata,
      this.newStorageData.image_id,this.newStorageData.source_volid,this.newStorageData.snapshot_id).subscribe(
      (data:any) => {
        
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'success',
            title:'스토리지',
            message:'스토리지를 생성하였습니다.'
          }
        });

        if(this.attachServerId !=""){
          this.attachVolume(this.attachServerId,data.id)
        }

        this.spinner.hide();
        
        this.closeDialogMsg('success');

    }, 
    (error) => {

      this.spinner.hide();
      this.closeDialogMsg('success');
      if(error.status == 413){
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'생성에 실패하였습니다. 할당된 제한 용량을 초과하였습니다. 고객센터에 문의하여주시기 바랍니다.'
          }
        });
        return;
      }
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }); 
  }
  
  
  async attachVolume(server_id,volume_id){  
    if(!server_id){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'오류 알림',
          message:'연결할 서버를 식별할 수 없습니다. 서버를 선택해주세요.'
        }
      });
      return;
    }  
    if(!volume_id){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'연결할 볼륨을 식별할 수 없습니다. 서버를 연결하지 못했습니다.'
        }
      });
      this.closeDialogMsg("fail");
      return;
    }  
    try{
      await this.osapi.attachVolume(server_id,volume_id).toPromise();
      
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'success',
          title:'스토리지',
          message:'서버에 스토리지를 연결하였습니다.'
        }
      });
    }catch{
      this.spinner.hide();
      this.closeDialogMsg('success');
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
    
  }

  onNewStorageNameChange(deviceValue) {
    this.newStorageData.name = deviceValue;
  }
  onNewStorageDescChange(deviceValue) {
    this.newStorageData.description = deviceValue;
  }
  onNewStorageAvailZoneChange(deviceValue) {
    this.newStorageData.availability_zone = deviceValue;
  }
  onNewStorageSizeChange(deviceValue) {

    if(this.newStorageData.source_type=="snapshot"){
      let selected_snapshot;
      for(let snap of this.snapshotLists){

        if(snap.id == this.newStorageData.snapshot_id){
          selected_snapshot=snap;
        }
      }
      if(selected_snapshot){
        if(deviceValue < selected_snapshot.size){
          this.newStorageData.size = selected_snapshot.size
        }else{
          this.newStorageData.size = deviceValue;
        }
      }
    }
    else if(this.newStorageData.source_type=="image"){
      let selected_image;
      for(let img of this.imageLists){
        if(img.image_id == this.newStorageData.image_id){
          selected_image=img;
        }
      }
      if(selected_image){
        if(deviceValue < selected_image.min_disk){
          this.newStorageData.size = selected_image.min_disk
        }else{
          this.newStorageData.size = deviceValue;
        }
      }
    }
    else{
      this.newStorageData.size = deviceValue;
    }
  }
  onNewStorageTypeChange(deviceValue) {
    this.newStorageData.volume_type = deviceValue;
  }
  onNewStorageSourceChange(deviceValue) {
    this.newStorageData.source_type = deviceValue;
    this.newStorageData.snapshot_id = null;
    this.newStorageData.source_volid = null;
    this.newStorageData.image_id = null;
  }
  onNewStorageSnapshotChange(deviceValue) {
    this.newStorageData.snapshot_id = deviceValue;
    this.newStorageData.size = null;
  }
  onNewStorageVolumeChange(deviceValue) {
    this.newStorageData.source_volid = deviceValue;
  }
  onNewStorageImageChange(deviceValue) {
    this.newStorageData.image_id = deviceValue;
    this.newStorageData.size = null;
  }
  
  getImageList(){
    this.osapi.getImageList().subscribe(
      (res:any) => {

      this.imageLists= res.body;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });  
  }
  
  getSnapshotList(){
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:{items:[]}) => {

      this.snapshotLists= data.items;

      
      if(this.dialogData.selected_snapshot){
        const selected_snapshot = this.dialogData.selected_snapshot;

        this.newStorageData.source_type = 'snapshot';
        this.newStorageData.snapshot_id = selected_snapshot.id;
        this.newStorageData.size = selected_snapshot.size;
      }
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });  
  }
  getVolumeList(){    
    this.osapi.getVolumeList().subscribe(
      (data:{items:[]}) => {

      this.volumeList= data.items;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
}
