import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../../../osapi.service';

@Component({
  selector: 'app-admin-flavor-setting',
  templateUrl: './admin-flavor-setting.component.html',
})
export class AdminFlavorSettingComponent implements OnInit {

  newFlavorData:{
    flavor_id:string,    name:string,    ram:number,
    vcpus:number,    disk:number,
    ephemeral:number,    swap:number,    flavor_access:Array<any>
  }={
    flavor_id:"",    name:"",    ram:1024,
    vcpus:1,    disk:50,
    ephemeral:0,    swap:0,    flavor_access:[],
  }

  projectList:Array<any>=[];
  sel_projectList:Array<any>=[];

  constructor(private osapi:OsapiService,) { }

  ngOnInit(): void {
    this.getProjectList();
  }

  onClickIncreaseVcpus(){
    this.newFlavorData.vcpus++;
  }
  onClickDecreaseVcpus(){
    this.newFlavorData.vcpus--;
  }
  onClickIncreaseRam(){
    this.newFlavorData.ram++;
  }
  onClickDecreaseRam(){
    this.newFlavorData.ram--;
  }
  onClickIncreaseDisk(){
    this.newFlavorData.disk++;
  }
  onClickDecreaseDisk(){
    this.newFlavorData.disk--;
  }
  onClickIncreaseEphemeral(){
    this.newFlavorData.ephemeral++;
  }
  onClickDecreaseEphemeral(){
    this.newFlavorData.ephemeral--;
  }
  onClickIncreaseSwap(){
    this.newFlavorData.swap++;
  }
  onClickDecreaseSwap(){
    this.newFlavorData.swap--;
  }
  add_project:any;
  onChangeAddProject(project_id){
    this.projectList.forEach(project => {
      if(project.id == project_id){
        this.add_project = project;
      }
    });
  }
  onClickSelectProject(project){
    this.sel_projectList.push(project);
    this.projectList.splice(this.projectList.indexOf(project),1);
    this.newFlavorData.flavor_access.push(project);
  }
  onClickDeselectProject(project){
    this.projectList.push(project);
    this.sel_projectList.splice(this.sel_projectList.indexOf(project),1);
    this.newFlavorData.flavor_access.splice(this.newFlavorData.flavor_access.indexOf(project),1);
  }

  getProjectList() {
    try{
      this.osapi.getProjectList().subscribe((data: { items: [] }) => {
        if(data.items == null){
          throw "osapi.getProjectList Fail";
        }
        this.projectList = data.items;

      });
    }catch(err){
      console.error(err);
    }
  }
  createFlavor(){

    this.osapi
    .createFlavor(
      this.newFlavorData.flavor_id,
      this.newFlavorData.name,
      this.newFlavorData.ram,
      this.newFlavorData.vcpus,
      this.newFlavorData.disk,
      this.newFlavorData.ephemeral,
      this.newFlavorData.swap,
      this.newFlavorData.flavor_access
    )
    .subscribe((data) => {

      location.replace("/admin/flavor");
    });
  }
}
