import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OsapiService } from '../../osapi.service';
import { FrontApiService } from '../../frontapi.service';
// import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import {DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function fn_modalMsg(msg): any;
declare function fn_modalOpen(msg): any;
declare function valiAllChk(params:any);

@Component({
  selector: 'app-console-keypair-list',
  templateUrl: './console-keypair-list.component.html',
})
export class ConsoleKeypairListComponent implements OnInit {
  keyPairList: any = [];

   // 검색 텍스트
   filterText: string;
   
  isActived: boolean = false;
  isChecked: boolean = false;

  // keypair_name;
  // keypair_type;
  // keypair_modal_name: String = '';
  // keypair_modal_description: String = '';
  selected_keypair: any = {};
  message:any={};
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private http: HttpClient,

    private osapi: OsapiService,
    private frontapi: FrontApiService,
    private spinner: NgxSpinnerService,
    public global: GlobalService,
    private dialogs: DialogSetService,
    private common: ConsoleNavComponent
    // private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'bg_blue');
    this.getKeyPairList();
    this.frontapi.currentMessage.subscribe((message:any) => {
      this.message = message;
      if (message.message_type == 'create keypair') {
        this.keyPairList.push({keypair:message.data_object});
      }
    }, 
    (error) => {
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  
	// 전체선택
	onAllCheck(status) {
		this.isActived = status.isActived;
	}

	onListCheck(key){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    this.isActived = false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
      if(chkedbox.getAttribute('data-id') == key.keypair.name) {
        chkedbox.checked = true;
        this.onClickList(key.keypair);
        this.isActived = true;
      }
    }
  }
  
  onClickList(keypair) {
    this.isActived = true;
    this.selected_keypair = keypair;
  }
  onKeyPairListCheck(dataId) {
  }

  openNewKeypairModal(){
    this.dialogs.openKeypairCreateDialog({});
  }
  openModifyKeypairModal(){
    this.dialogs.openKeypairUpdateDialog({selected_keypair:this.selected_keypair});
  }
  openDeleteKeyPairModal(keypair) {
    this.selected_keypair = keypair;
    this.dialogs.openDefaultDeleteDialog({
      title:"인증키 삭제",
      content:"선택하신 인증키("+this.selected_keypair.name+")를 정말로 삭제하시겠습니까?"});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "okay"){
        this.deleteKeyPair();
      }
    });
  }
  
  openDeleteStorageModal(){
    
  }
  getKeyPairList() {
    this.spinner.show();
    this.osapi.getKeyPairList().subscribe((data: { items: [] }) => {
      this.keyPairList = data.items;
      this.common.getThemeFont();
      this.spinner.hide();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  deleteKeyPair() {
    this.spinner.show();
    const keypair_name = this.selected_keypair.name;
    this.osapi.deleteKeyPair(keypair_name).subscribe((data: {}) => {
      this.spinner.hide();
      this.getKeyPairList();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
}
