import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-server-image-update',
  templateUrl: './dialog-server-image-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerImageUpdateComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerImageUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_image:any={};
  edit_image_data:{name:string,description:string} ={name:"",description:""};

  ngOnInit(): void {

    this.selected_image = this.dialogData.selected_image;

    this.edit_image_data.name = this.selected_image.name
    this.edit_image_data.description = this.selected_image.description
  }
  
  updateImage(){
    if(this.edit_image_data.name == ""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi.updateImage(this.selected_image.image_id, this.edit_image_data.name, this.edit_image_data.description,this.selected_image.disk_format,this.selected_image.visibility,this.selected_image.protected).subscribe(
      (data:{}) => {
      this.spinner.hide();
      this.closeDialogMsg('success');

      if(this.router.url=='/console/image'){
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigate(['/console/image']);
          }
        );
      }else{
        this.router.navigate(['/console/image']);
      }
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
      type:'toast',
      body:{
        type:'error',
        title:'오류 알림',
        message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
      }
    });
    });    
    
  }
}
