<!-- Container -->
<div id="Container" class="customerCenter"> <!-- #200531 class 수정 -->
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>고객 지원</h2>
        </div>
    </div>
    <div id="Hometents">
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>customer center</h3>
                    <div class="subTit">
                        고객상담 및 궁금한 사항이 있으시면 자유롭게 문의할 수 있는 공간입니다.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="customerInfo">
                        <ul class="customerList">
                            <li>
                                <p class="tit"><i class="icoAdm ico_customer"></i>1:1문의</p>
                                <p class="cont">
                                    고객이신 경우, 이용중인  서비스에 대해 문의를 하실 수 있습니다.
                                </p>
                                <a routerLink="/web/mypage/inquriyList" class="btn_more btn midTp1 bor_gray"><i class="icoAdm ico_center"></i>문의하기</a>
                            </li>
                            <li>
                                <p class="tit"><i class="icoAdm ico_email"></i>이메일문의</p>
                                <p class="cont">
                                    기타 서비스 문의, 컨설팅, 견적 등의 문의를 하실 수 있습니다.<br><br>
                                    <b>주소 : helpdesk@bizlinkersys.com</b>
                                </p>
                                <a href="mailto:helpdesk@bizlinkersys.com" class="btn_more btn midTp1 bor_gray"><i class="icoAdm ico_center"></i>문의하기</a>
                            </li>

                            <li>
                                <p class="tit"><i class="icoAdm ico_telephone"></i>전화문의</p>
                                <div class="cont">
                                    <b>TEL : 070-7500-1290 <span class="txt_blue_tp4">( 운영시간 : 10:00 ~ 17:00 )</span></b>
                                </div>
                                <a href="tel:070-7500-1290" class="btn_more btn midTp1 bor_gray"><i class="icoAdm ico_center"></i>문의하기</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->

        
    </div>
</div>
<!-- //Container -->
<hr>
