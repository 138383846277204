import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
})
export class FaqListComponent implements OnInit {

  faqList: any = [];
  
  //페이징변수
  curPage:any=0;
  totalCount:any;
  
  searchCate:String = "";
  searchTitle:String = "";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService, 
    private activatedRoute: ActivatedRoute,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
        if(params['page']!=null && params['page']!='undefined'&& params['page']!=""){
          this.curPage = params['page'];
        }

        if(params['searchTitle']!=null && params['searchTitle']!=""){
          this.searchTitle = params['searchTitle'];
        }
        
        if(params['searchCate']!=null && params['searchCate']!=""){
          this.searchCate = params['searchCate'];
        }
    });
  }
  
  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.getFaqList(this.curPage);
  }

  getFaqList(page) {
    this.curPage = page;

    var searchQuerys = "";
    searchQuerys = "&l=10&s="+(this.curPage*10)+"&o=created_date.DESC";
    if(this.searchCate!=""){
      searchQuerys += "&category="+decodeURIComponent(this.searchCate+"");
    }
    if(this.searchTitle!=""){
      searchQuerys += "&title="+decodeURIComponent(this.searchTitle+"")+"&lk=title";
    }
    this.spiner.show();
    this.webapi.getFaqList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.faqList = data['result'];
        this.totalCount = data['total'];
      } , (err) => {

      }
    );
    this.spiner.hide();
  }

  //페이징함수
  getMovePage(e){
    this.getFaqList(e.page)
  }

  // 검색
  fn_faqSearch(title){
    this.searchTitle = title;
    this.getFaqList(0);
  }

  onSearchCateChange(deviceValue){
    this.searchCate=deviceValue;
  }
  
  onSearchTitleChange(deviceValue){
    this.searchTitle=deviceValue;
  }
}
