
<!-- Modal : 이미지 업로드 모달 -->
<div id="image_upload" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>인스턴스 이미지로 업로드</h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width:20%">
                        <col style="width:80%">
                    </colgroup>
                    <tbody>
                        <tr *ngIf="selected_volume">
                            <th scope="row">스토리지 이름</th>
                            <td>
                                <input type="text" name="" id="" [value]="selected_volume.name" placeholder="내용을 입력해주세요"
                                    readonly>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">이미지 이름</th>
                            <td>
                                <input type="text" name="" id="" [(ngModel)]="newImageData.name" placeholder="생성할 이미지의 이름을 작성해주세요.(255자 이내)" maxlength="255" required>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">디스크 포맷</th>
                            <td>
                                <select name="" [(ngModel)]="newImageData.format" required>
                                    <option value="">선택</option>
                                    <option value="qcow2">QCOW2</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">강제</th>
                            <td>
                                <input type="checkbox" name="" id="force" [(ngModel)]="newImageData.forced" checked>
                                <label class="sub-txt"> 선택한 스토리지가 사용중인 경우 "강제" 옵션을 사용하여 스토리지를 이미지로 업로드할 수 있습니다.</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp1">
                <a (click)="createVolumeImage()" class="btn big ciblue mg_r4">업로드</a>
                <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal -->
    