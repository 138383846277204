<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
      <h2>베어메탈 생성</h2>
      <div class="lineMap">Server / <span>Instances</span></div>
    </div>
    <!-- //content_header -->
  
    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
          <div class="sec_content">
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 1. 서버설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
              <div class="titArea tpA">
                  <small>서버의 기본적인 설정을 입력해주세요.</small>
              </div>
              <div class="contArea" style="display: block;" id="contMain">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr class="required">
                        <th scope="row">Zone 선택</th>
                        <td>
                          <select name="zone_val" id="sZone" required>
                            <option>-선택-</option>
                          </select>
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">서버명</th>
                        <td>                        
                          <input
                            type="text"
                            placeholder="20자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다." 
                            required                          
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">설명</th>
                        <td>
                          <textarea rows="5" placeholder="내용을 입력해주세요"></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1"></div>
              </div>
            </div>
            <!-- //lyBox -->
  
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 2. 상세설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
              <div class="titArea tpA">
                  <small>서버에 사용할 이미지와 유형을 선택해주세요. </small>
              </div>
              <div class="contArea" style="display: block;" id="contDetail">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr class="required">
                        <th scope="row">이미지 선택</th>
                        <td>
                          <select>
                            <option value="">-선택-</option>
                          </select>                          
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">서버유형 선택</th>
                        <td>
                          <select>
                            <option value="">-선택-</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1"></div>
              </div>
            </div>
            <!-- //lyBox -->
  
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 3. 네트워크 설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
              <div class="titArea tpA">
                  <small>서버에 사용할 네트워크를 선택해주세요. </small>
              </div>
              <div class="contArea" style="display: block;" id="contDetail">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr class="required">
                        <th scope="row">네트워크 선택</th>
                        <td>
                          <select>
                            <option value="">-선택-</option>  
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1"></div>
              </div>
            </div>
            <!-- //lyBox -->
            
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 4. 보안그룹설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
              <div class="titArea tpA">
                  <small>보유하고 있는 보안그룹을 선택하거나 새로운 보안그룹을 생성해주세요. </small>
              </div>
              <div class="contArea" style="display: block;" id="contSecure">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">보안그룹 생성</th>
                        <td>
                          <a
                            class="btn mid blue"
                            ><i class="ico ico_register_white"></i>신규생성</a
                          >
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">보안그룹 선택</th>
                        <td>
                          <div>
                            <select>
                              <option value="">-선택-</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="btn_wrap tp1"></div>
              </div>
            </div>
            <!-- //lyBox -->
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 5. 인증키설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
              <div class="titArea tpA">
                  <small>보유하고 있는 인증키를 선택하거나 새로운 인증키를 생성하세요. 인증키는 서버에 접근하는데 사용합니다. </small>
              </div>
              <div class="contArea" style="display: block;" id="contAuthkey">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">인증키 생성</th>
                        <td>
                          <a
                            class="btn mid blue"
                            ><i class="ico ico_register_white"></i>신규생성</a
                          >
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">인증키 선택</th>
                        <td>
                          <select>
                            <option value="">-선택-</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1">
                  <button type="submit" class="btn big orange" onclick="fn_modalOpen('modal_comfirm');"></button>
                </div>
              </div>
            </div>
            <!-- //lyBox -->
          </div>
        </section>
      <!-- //section -->
    </div>
    <!-- //content_body -->
  </div>
  <!-- //Contents -->
  
  
  <!-- Modal : 최종 확인 -->
  <div id="modal_comfirm" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>최종 확인</h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="msg">
        <div class="boardBox">
          <table class="tbl_row_tp1">
            <colgroup>
              <col style="width: 30%;" />
              <col style="width: 70%;" />
            </colgroup>
            <tbody>
              <tr>
                <th>Zone</th>
                <td></td>
              </tr>
              <tr>
                <th>서버명</th>
                <td></td>
              </tr>
              <tr>
                <th>서버설명</th>
                <td></td>
              </tr>
              <tr>
                <th>이미지명</th>
                <td></td>
              </tr>
              <tr>
                <th>서버유형</th>
                <td ></td>
              </tr>
              <tr>
                <th>보안그룹</th>
                <td></td>
              </tr>
              <tr>
                <th>인증키명</th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="btnArea">
        <a
          href="#close-modal"
          rel="modal:close"
          class="btn big orange mg_r4"
          >확인</a
        >
        <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->
  