import { Component, OnInit, Inject } from '@angular/core';
import { GWService } from '../../../gwapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-gw-api-create',
  templateUrl: './dialog-gw-api-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogGwApiCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private gwapi: GWService,
    public dialogRef: MatDialogRef<DialogGwApiCreateComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }
  selected_api
  
  using_lb=false;
  using_detail=false;
  using_health_check=false;
  
  
  base_uri="";
  create_api_object={
    id:"",
    name:"",
    protocol:"http",
    path:"",
    lb_algorithm:"round-robin",
    targets:[],
    retries:5,
    connect_timeout:60000,
    write_timeout:60000,
    read_timeout:60000,
    
  }

  lb_target={
    host:"",
    port:80,
    weight:10
  };
  use_lb_targets=[];
  
  message:any={};
  
  addTarget(){
    this.use_lb_targets.push(this.lb_target);
    this.lb_target={
      host:"",
      port:80,
      weight:10
    };
  }
  removeTarget(object){
    const idx = this.use_lb_targets.indexOf(object) 
    if (idx > -1) this.use_lb_targets.splice(idx, 1)
  }


  ngOnInit(): void {
    const api_data = this.dialogData.selected_api;
    this.create_api_object.name = api_data.name;
    this.create_api_object.id = api_data.kong_service_id;
    this.create_api_object.protocol = api_data.protocol;
    this.create_api_object.path = api_data.path;
    if( api_data.lb_algorithm =="no"){
      this.create_api_object.lb_algorithm = "round-robin"
      this.using_lb=false;
      this.lb_target.host = api_data.endpoints[0].host;
      this.lb_target.port = api_data.endpoints[0].port;
      this.lb_target.weight = api_data.endpoints[0].weight;
    }else{

      this.create_api_object.lb_algorithm = api_data.lb_algorithm;
      this.using_lb=true;
      this.use_lb_targets = api_data.endpoints;
    }
    this.create_api_object.retries = api_data.retries;
    this.create_api_object.connect_timeout = api_data.connect_timeout;
    this.create_api_object.write_timeout = api_data.write_timeout;
    this.create_api_object.read_timeout = api_data.read_timeout;
  }

  updateAPI() {
    if(!this.using_lb){
      this.use_lb_targets=[];
      this.use_lb_targets.push(this.lb_target);
    }
    this.create_api_object.targets = this.use_lb_targets;
    //targets 가 비어있으면 예외처리
    
    if(this.create_api_object.name == null ||this.create_api_object.name == ""){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '이름을 입력해주세요.'
        }
      });
      return;
    }
    const name_filter = /^[0-9a-zA-Z\-_]*$/
    if(name_filter.test(this.create_api_object.name)==false ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "이름은 영문자와 숫자, '-', '_'만 입력 가능합니다."
        }
      });
      return;
    }
    
    if(this.create_api_object.path.indexOf('//') !== -1 ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "경로의 값을 확인해주시기 바랍니다."
        }
      });
      return;
    }

    if(this.create_api_object.path.length == 0 || this.create_api_object.path[0] !='/'){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "경로의 값을 확인해주시기 바랍니다."
        }
      });
      return;
    }
    
    if(this.create_api_object.targets == null ||this.create_api_object.targets.length==0 ){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "호스트를 추가해야합니다."
        }
      });
      return;
    }
    for(let target of this.use_lb_targets){
      if(target.port<0 ||target.port>65535 ){
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'warning',
            title: '경고',
            message: "사용가능한 포트범위는 0 ~ 65535 입니다."
          }
        });
        return;
      }
    }
    
    if(this.create_api_object.retries<0 || this.create_api_object.retries >32767){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "재시도 회수의 범위는 0 ~ 32767 입니다."
        }
      });
      return;
    }
    if(this.create_api_object.connect_timeout<0 || this.create_api_object.connect_timeout >2147483646){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "Timeout의 범위는 0 ~ 2147483646 입니다."
        }
      });
      return;
    }
    if(this.create_api_object.read_timeout<0 || this.create_api_object.read_timeout >2147483646){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "Timeout의 범위는 0 ~ 2147483646 입니다."
        }
      });
      return;
    }
    if(this.create_api_object.write_timeout<0 || this.create_api_object.write_timeout >2147483646){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "Timeout의 범위는 0 ~ 2147483646 입니다."
        }
      });
      return;
    }

    this.gwapi
      .updateAPI(
        this.create_api_object.id,
        this.create_api_object.name,
        this.create_api_object.targets,
        this.create_api_object.path,
        this.create_api_object.protocol,
        this.create_api_object.lb_algorithm,
        this.create_api_object.retries ,
        this.create_api_object.connect_timeout,
        this.create_api_object.write_timeout,
        this.create_api_object.read_timeout,
        ).subscribe((data: any) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }





}
