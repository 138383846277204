import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { WebApiService } from '../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NetworkData } from './NetworkData';
import { CidrData } from './CidrData';
import { FrontApiService } from '../../frontapi.service';
import { TranslateService } from '@ngx-translate/core';
import { $ } from 'protractor';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { SubnetData } from "./subnetdata";
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function valiAllChk(params:any);

@Component({
  selector: 'app-console-network-list',
  templateUrl: './console-network-list.component.html',
})
export class ConsoleNetworkListComponent implements OnInit {

  networkList: any = [];
  resource: any = [];
  selected_network: any = {};

  // 검색 텍스트
  filterText: string;

  // usedCidrList: any = [];

  /**
   * Network 생성 모델
   * type : false 외부
   */
  networkModel = new NetworkData('', '', false);
  cidrs: any = [];

  message: any = {};

  isActived: boolean = false;
  isChecked: boolean = false;

  constructor(
    private osapi: OsapiService,
    private webapi: WebApiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    public global: GlobalService,
    private dialogs : DialogSetService,
    private router : Router,
    private common: ConsoleNavComponent
  ) { }

  ngOnInit(): void {
    // this.cidrs.push(new CidrData('10.0.0.0/8',false));
    // this.cidrs.push(new CidrData('172.16.0.0/12',true));
    // this.cidrs.push(new CidrData('192.168.0.0/16',false));
    this.getNetworkList();
    this.spinner.show();

    this.frontapi.currentMessage.subscribe((message) => {
      this.message = message;
    }, (error) => {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(network){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    this.isActived = false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
      if(chkedbox.getAttribute('data-id') == network.id) {
        chkedbox.checked = true;
        this.onClickList(network);
        this.isActived = true;
      }
    }
  }


  onClickList(network) {
    this.isActived = true;
    this.selected_network = network;
  }

  openNewNetworkModal(){
    this.networkModel = new NetworkData('', '', true);
    // fn_modalOpen('network_create');
    this.dialogs.openNetworkCreateDialog({networkModel:this.networkModel,resource:this.resource});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.getNetworkList();
      }else if(result == "fail"){
        this.getNetworkList();
      }
    });
  }
  openNetworkExternalModal(){
    this.dialogs.openNetworkExternalManageDialog({selected_network:this.selected_network});
  }
  openNetworkSnatModal(){
    this.dialogs.openNetworkSnatManageDialog({selected_network:this.selected_network});
  }
  openDeleteNetworkModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"네트워크 삭제",
      content:"선택하신 네트워크("+this.selected_network.name+")를 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteNetwork(this.selected_network);
        }
      });
  }

  subnetModel;
  openCreateSubnetModal() {
    this.subnetModel = new SubnetData('', '', '', '');
    // fn_modalOpen('subnet_create')
    this.dialogs.openSubnetCreateDialog({subnetModel:this.subnetModel,networkList:this.networkList,resource:this.resource});
  }

  getResource() {
    this.osapi.getResource('network').subscribe((data: any[]) => {
      this.resource = data;
    },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  getNetworkList() {
    this.osapi.getResourceAll().subscribe((data: any[]) => {
      this.resource = data;

      this.osapi.getNetworkList().subscribe((data:any) => {
        this.networkList = [];
        this.spinner.hide();

        console.log(data);

        // 외부 네트워크만 목록에 담기
        for(let network of data){
          console.log(network);
          if(network.network_type == "ext"){
            this.networkList.push(network);
          }
          // Default Network 찾기
          network.isDefaultNetwork = false;
          if(network.name == 'Default Network' && network.cidr == "192.168.0.0/16"){
            network.isDefaultNetwork = true;
          }
        }
        //this.networkList = data;

        // 초기화
        for (let item of this.networkList) {
          item.external_gateway_info = {}
          item.external_gateway_info.enable_snat = true;
        }
        // cidr 찾기
        for (let r of this.resource) {
          for (let n of this.networkList) {
            if (r.objectId == n.id) {
              n.cidr = r.value;
              // this.usedCidrList.push(r.value);
            }
          }
        }

        // 라우터 찾기 admin_state 값 UP/DOWN 외부 연결 허용/차단
        // 라우터 ID 가져오기
        for (let r of this.resource) {
          for (let n of this.networkList) {
            if (r.resourceType === 'router') {
              /** 20200904 cbkim 프로젝트 아이디 확인 */
              
              if (n.project_id == r.projectId) {
                if (r.value === n.cidr) {
                  n.routerId = r.objectId;
                  var routerId = r.objectId;
                  this.osapi.getRouters(routerId).subscribe((data: any) => {
                    n.router_state = data.admin_state_up;
                    n.router_id = data.id;
                    n.external_gateway_info = data.external_gateway_info;
                  },(error) => {
                      this.spinner.hide();
                      this.frontapi.changeMessage({
                        type: 'toast',
                        body: {
                          type: 'error',
                          title: '오류 알림',
                          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
                        }
                      });
                    });
                }
              }
            }
          }
        }
        this.common.getThemeFont();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });

        });
    },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        })
      });
  }


  
  deleteNetwork(network) {
    this.spinner.show();
    if (network.existHostPort) {
      this.spinner.hide();
      // 호스트 연결되어있음
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'연결 된 인스턴스가 존재합니다.'
        }
      });
    } else {
      this.osapi.deleteNetwork(network.id).subscribe(
        (data: any) => {          if(this.router.url=='/console/network'){
            this.router.navigateByUrl('/console', {
              skipLocationChange: true,
            }).then(
              () => {
                this.router.navigate(['/console/network']);
              }
            );
          }else{
            this.router.navigate(['/console/network']);
          }
          this.spinner.hide();
          // Resource 삭제
          // this.osapi.blsDestroyResource({ objectId: network.id, status: "DELETED" }).subscribe(
          //   (data: any) => {
          //   }
          // )
        },
        (error) => {
          if(error.status == 409){
            // 연결 된 인터페이스 있음
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '네트워크 삭제',
                message: '사용 중인 네트워크는 삭제 할 수 없습니다.'
              }
            });
          }else{
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          }
          
        }
      );
    }
  }


  isBool(b) {
    return typeof b == 'boolean';
  }
}