import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-nas-create',
  templateUrl: './dialog-nas-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNasCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNasCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  newNas: any = { name: '', size: '', description: '' };

  nasSize: any = ['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000'];
  
  ngOnInit(): void {

    
  }

  
  createNas() {
    if(this.newNas.name == ''){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'이름을 입력해주세요.'
        }
      });
      return
    }
    if(this.newNas.size == ''){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'생성할 NAS의 크기를 선택해주세요.'
        }
      });
      return
    }
    try {
      this.spinner.show();
      this.osapi.createNas(this.newNas).subscribe((data: { share }) => {
        this.spinner.hide();
        this.closeDialogMsg({code:"success",data:data});
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
    } catch (e) {
      this.spinner.hide();

    }
  }

}
