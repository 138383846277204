<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>API 사용량 조회</h2>
        <div class="lineMap">
            Service / <span>API Gateway</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- <form id="frm" class="frmBox1"> -->
            <!-- section -->
            <section class="sec col12">
                <div class="sec_content">
                    <!-- lyBox -->
                    <div class="lyBox consoleBody">
                        <div class="titArea">
                            <h3>API 사용량</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                              
                                    <div class="input-group-btn pd_r10">
                                        <input type="text" style="border: solid darkgray 1px; height: 30px;" [value]="start_date" [matDatepicker]="picker" (dateChange)="changeDate('start',$event.value)">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                    ~
                                    <div class="input-group-btn pd_r10">
                                        <input type="text" style="border: solid darkgray 1px; height: 30px;" [value]="end_date" [matDatepicker]="picker2" (dateChange)="changeDate('end',$event.value)">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </div>

                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid ciblue" (click)="getApiUsageList()"><i class="ico ico_search_white"></i>조회</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display:block">
                            <div class="boardBox">
                                <table class="tbl_col_tp1">
                                    <colgroup>
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                        <col style="width: 12.5%;">
                                    </colgroup>
                                    <thead>
                                        <th scope="col">서비스</th>
                                        <th scope="col">접근경로</th>
                                        <th scope="col">API 키</th>
                                        <th scope="col">호출 수</th>
                                        <th scope="col">성공 수</th>
                                        <th scope="col">실패 수</th>
                                        <th scope="col">업로드 트래픽</th>
                                        <th scope="col">다운로드 트래픽</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="data_list.length ==0"><td colspan="8">검색결과가 없습니다.</td></tr>
                                        <tr *ngFor="let usage of data_list">
                                            <td>{{usage.api_data?usage.api_data.name:'없음'}}</td>
                                            <td>{{usage.route_data?usage.route_data.name:'없음'}}</td>
                                            <td>{{usage.auth_data?usage.auth_data.name:'없음'}}</td>
                                            <td>{{usage.call_all_count}}</td>
                                            <td>{{usage.call_success_count}}</td>
                                            <td>{{usage.call_fail_count}}</td>
                                            <td>{{usage.upload_traffic_size}}</td>
                                            <td>{{usage.download_traffic_size}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1">
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->

        <!-- </form> -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

