<!-- Modal : msg 공통 -->
<div class="modal2 modalMsg">
  <!-- modal_header -->
  <div class="modal_header">
    <ng-template [ngIf]="dialogTitle">
      <h2>{{dialogTitle}}</h2>
    </ng-template>
    <ng-template [ngIf]="!dialogTitle">
      <h2>삭제확인</h2>
    </ng-template>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <ng-template [ngIf]="dialogContent">
      <div class="msg" [innerHTML]="dialogContent"></div>
    </ng-template>
    <ng-template [ngIf]="!dialogContent">
      <div class="msg">삭제하시겠습니까?</div>
    </ng-template>

    <div class="btnArea">
      <a (click)="closeDialogMsg('okay')" class="btn big ciblue mg_r4">삭제</a>
      <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->