import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GWService } from '../../gwapi.service';
import { GlobalService } from '../../global.service';
import { FrontApiService } from '../../frontapi.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { OsapiService } from '../../osapi.service';
import { Item } from '@syncfusion/ej2-navigations';

@Component({
  selector: 'app-console-apigateway-status',
  templateUrl: './console-apigateway-status.component.html',
})
export class ConsoleApigatewayStatusComponent implements OnInit {

  @Input() message: string;
  @Output() modalMsg = new EventEmitter();


  uri="";

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,
    private frontapi: FrontApiService,
    private gwapi:GWService
  ) {
    
  }

  ngOnInit(): void {
    this.getRegistrationInfo();
    this.getAlreadyUsed();
  }
  getRegistrationInfo(){
    this.gwapi.getRegistrationInfo().subscribe((data: any) => {
      console.log(data)
      if(data){
        this.uri = data.uri;
      }
    }, 
    (error) => {
    });
  }

  already_used_flag = true;
  getAlreadyUsed(){
    this.gwapi.getAlreadyUsed().subscribe((data: any) => {
      this.already_used_flag = data.result
      if(this.already_used_flag===false){
        console.log("Gateway 서비스 신청")
        this.startGatewayService();
      }
    }, 
    (error) => {
    });

  }
  startGatewayService(){
    this.spinner.show();
    this.gwapi.startGatewayService().subscribe((data: any) => {
      this.getRegistrationInfo();
      this.spinner.hide();
    }, 
    (error) => {
      this.spinner.hide();
    });

  }

  
  copytokey(inputElement) {

    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);

    this.frontapi.changeMessage({
      type: 'toast',
      body: {
        type: 'success',
        title: '클립보드',
        message: '클립보드에 복사하였습니다.'
      }
    });
  }

}
