import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';

import { DialogTestComponent } from './dialog-test/dialog-test.component';
import { DialogDefaultDeleteComponent } from './dialog-default-delete/dialog-default-delete.component';
import { DialogDefaultConfirmComponent } from './dialog-default-confirm/dialog-default-confirm.component';
import {DialogDefaultMultiactionComponent} from './dialog-default-multiaction/dialog-default-multiaction.component';

import { DialogCreateIpComponent } from './dialog-create-ip/dialog-create-ip.component';
import { DialogUpdateIpComponent } from './dialog-update-ip/dialog-update-ip.component';
import { DialogAssociateIpComponent } from './dialog-associate-ip/dialog-associate-ip.component';
import { DialogDisassociateIpComponent } from './dialog-disassociate-ip/dialog-disassociate-ip.component';

import { DialogStorageCreateComponent } from './dialog-storage-create/dialog-storage-create.component';
import { DialogStorageUpdateComponent } from './dialog-storage-update/dialog-storage-update.component';
import { DialogStorageImageCreateComponent } from './dialog-storage-image-create/dialog-storage-image-create.component';
import { DialogStorageAttachComponent } from './dialog-storage-attach/dialog-storage-attach.component';
import { DialogStorageDetachComponent } from './dialog-storage-detach/dialog-storage-detach.component';

import { DialogStorageSnapshotCreateComponent } from './dialog-storage-snapshot-create/dialog-storage-snapshot-create.component';
import { DialogStorageSnapshotUpdateComponent } from './dialog-storage-snapshot-update/dialog-storage-snapshot-update.component';

import { DialogServerImageCreateComponent } from './dialog-server-image-create/dialog-server-image-create.component';
import { DialogServerImageUpdateComponent } from './dialog-server-image-update/dialog-server-image-update.component';

import { DialogKeypairCreateComponent } from './dialog-keypair-create/dialog-keypair-create.component';
import { DialogKeypairCreateDetailComponent } from './dialog-keypair-create-detail/dialog-keypair-create-detail.component';
import { DialogKeypairUpdateComponent } from './dialog-keypair-update/dialog-keypair-update.component';

import { DialogSecurityGroupCreateComponent } from './dialog-security-group-create/dialog-security-group-create.component';
import { DialogSecurityGroupRuleCreateComponent } from './dialog-security-group-rule-create/dialog-security-group-rule-create.component';
import { DialogSecurityGroupRuleUpdateComponent } from './dialog-security-group-rule-update/dialog-security-group-rule-update.component';

import { DialogNetworkCreateComponent } from './dialog-network-create/dialog-network-create.component';
import { DialogNetworkExternalManageComponent } from './dialog-network-external-manage/dialog-network-external-manage.component';
import { DialogNetworkSnatManageComponent } from './dialog-network-snat-manage/dialog-network-snat-manage.component';

import { DialogPrivateNetworkCreateComponent } from './dialog-private-network-create/dialog-private-network-create.component';
import { DialogPrivateNetworkShareManageComponent } from './dialog-private-network-share-manage/dialog-private-network-share-manage.component';

import { DialogSubnetCreateComponent } from './dialog-subnet-create/dialog-subnet-create.component';
import { DialogSubnetUpdateComponent } from './dialog-subnet-update/dialog-subnet-update.component';

import { DialogServerCreateConfirmComponent } from './dialog-server-create-confirm/dialog-server-create-confirm.component';

import { DialogProjectCreateComponent } from './dialog-project-create/dialog-project-create.component';
import { DialogProjectDeleteComponent } from './dialog-project-delete/dialog-project-delete.component';
import { DialogProjectUpdateComponent } from './dialog-project-update/dialog-project-update.component';

import { DialogSubuserCreateComponent } from './dialog-subuser-create/dialog-subuser-create.component';
import { DialogSubuserUpdateComponent } from './dialog-subuser-update/dialog-subuser-update.component';

import { DialogServerUpdateComponent } from './dialog-server-update/dialog-server-update.component';
import { DialogServerInterfaceUpdateComponent } from './dialog-server-interface-update/dialog-server-interface-update.component';
import { DialogServerPortforwardingUpdateComponent } from './dialog-server-portforwarding-update/dialog-server-portforwarding-update.component';
import { DialogServerSecurityGroupUpdateComponent } from './dialog-server-security-group-update/dialog-server-security-group-update.component';
import { DialogServerWindowPasswordComponent } from './dialog-server-window-password/dialog-server-window-password.component';
import { DialogServerResizeComponent } from './dialog-server-resize/dialog-server-resize.component';

import {DialogNasCreateComponent} from './dialog-nas-create/dialog-nas-create.component';
import {DialogNasExpansionComponent} from './dialog-nas-expansion/dialog-nas-expansion.component';
import {DialogNasRuleUpdateComponent} from './dialog-nas-rule-update/dialog-nas-rule-update.component';
import {DialogNasServerInterfaceUpdateComponent} from './dialog-nas-server-interface-update/dialog-nas-server-interface-update.component';

import {DialogLoadbalancerAssociateIpComponent} from './dialog-loadbalancer-associate-ip/dialog-loadbalancer-associate-ip.component';
import {DialogLoadbalancerDisassociateIpComponent} from './dialog-loadbalancer-disassociate-ip/dialog-loadbalancer-disassociate-ip.component';
import { DialogLoadbalancerMemberCreateComponent } from './dialog-loadbalancer-member-create/dialog-loadbalancer-member-create.component';
import { DialogLoadbalancerMemberUpdateComponent } from './dialog-loadbalancer-member-update/dialog-loadbalancer-member-update.component';
import { DialogLoadbalancerPoolUpdateComponent } from './dialog-loadbalancer-pool-update/dialog-loadbalancer-pool-update.component';
import { DialogLoadbalancerListenerUpdateComponent } from './dialog-loadbalancer-listener-update/dialog-loadbalancer-listener-update.component';
import { DialogLoadbalancerMonitorUpdateComponent } from './dialog-loadbalancer-monitor-update/dialog-loadbalancer-monitor-update.component';

//ldh 210120 게이트웨이 서비스 추가
import { DialogGwApiCreateComponent } from './dialog-gw-api-create/dialog-gw-api-create.component';
import { DialogGwRouteCreateComponent } from './dialog-gw-route-create/dialog-gw-route-create.component';
import { DialogGwRouteUpdateComponent } from './dialog-gw-route-update/dialog-gw-route-update.component';
import { DialogGwAuthCreateComponent } from './dialog-gw-auth-create/dialog-gw-auth-create.component';
import { DialogGwIprestrictionComponent } from './dialog-gw-iprestriction/dialog-gw-iprestriction.component';

import { DialogBucketCreateComponent } from './dialog-bucket-create/dialog-bucket-create.component';
import { DialogObjectStorageJoinComponent } from './dialog-objectstorage-join/dialog-objectstorage-join.component';
import { DialogObjectStorageFolderCreateComponent } from './dialog-objectstorage-folder-create/dialog-objectstorage-folder-create.component';
import { DialogObjectStorageUploadComponent } from './dialog-objectstorage-upload/dialog-objectstorage-upload.component';
import { UploadFileDirective } from './dialog-objectstorage-upload/uploadFile.directive';
import { DialogObjectStorageApiEndpointComponent } from './dialog-objectstorage-apiendpoint/dialog-objectstorage-apiendpoint.component';
import { DialogObjectStorageCopyMoveComponent } from './dialog-objectstorage-copymove/dialog-objectstorage-copymove.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { DialogObjectStorageRenameComponent } from './dialog-objectstorage-rename/dialog-objectstorage-rename.component';
import { DialogObjectStorageInfoComponent } from './dialog-objectstorage-info/dialog-objectstorage-info.component';

import { DialogBatchCreateComponent } from './dialog-batch-create/dialog-batch-create.component';


@NgModule({
  declarations: [
    DialogTestComponent,
    DialogCreateIpComponent,
    DialogDefaultDeleteComponent,
    DialogDefaultConfirmComponent,
    DialogDefaultMultiactionComponent,
    DialogUpdateIpComponent,
    DialogAssociateIpComponent,
    DialogDisassociateIpComponent,
    DialogStorageCreateComponent,
    DialogStorageUpdateComponent,
    DialogStorageSnapshotCreateComponent,
    DialogStorageImageCreateComponent,
    DialogStorageAttachComponent,
    DialogStorageDetachComponent,
    DialogServerImageCreateComponent,
    DialogServerImageUpdateComponent,
    DialogKeypairCreateComponent,
    DialogKeypairCreateDetailComponent,
    DialogKeypairUpdateComponent,
    DialogSecurityGroupCreateComponent,
    DialogSecurityGroupRuleCreateComponent,
    DialogSecurityGroupRuleUpdateComponent,
    DialogNetworkCreateComponent,
    DialogNetworkExternalManageComponent,
    DialogNetworkSnatManageComponent,
    DialogPrivateNetworkCreateComponent,
    DialogPrivateNetworkShareManageComponent,
    DialogSubnetCreateComponent,
    DialogSubnetUpdateComponent,
    DialogStorageSnapshotUpdateComponent,
    DialogServerCreateConfirmComponent,
    DialogProjectCreateComponent,
    DialogProjectDeleteComponent,
    DialogProjectUpdateComponent,
    DialogSubuserCreateComponent,
    DialogSubuserUpdateComponent,
    DialogServerUpdateComponent,
    DialogServerInterfaceUpdateComponent,
    DialogServerPortforwardingUpdateComponent,
    DialogServerSecurityGroupUpdateComponent,
    DialogNasCreateComponent,
    DialogNasExpansionComponent,
    DialogNasRuleUpdateComponent,
    DialogNasServerInterfaceUpdateComponent,
    DialogLoadbalancerAssociateIpComponent,
    DialogLoadbalancerDisassociateIpComponent,
    DialogServerWindowPasswordComponent,
    DialogServerResizeComponent,
    DialogLoadbalancerMemberCreateComponent,
    DialogLoadbalancerMemberUpdateComponent,
    DialogLoadbalancerPoolUpdateComponent,
    DialogLoadbalancerListenerUpdateComponent,
    DialogLoadbalancerMonitorUpdateComponent,

    DialogGwApiCreateComponent,
    DialogGwAuthCreateComponent,
    DialogGwRouteCreateComponent,
    DialogGwRouteUpdateComponent,
    DialogGwIprestrictionComponent,

    DialogBucketCreateComponent,
    DialogObjectStorageJoinComponent,
    DialogObjectStorageFolderCreateComponent,
    DialogObjectStorageUploadComponent,
    UploadFileDirective,
    DialogObjectStorageApiEndpointComponent,
    DialogObjectStorageCopyMoveComponent,
    DialogObjectStorageRenameComponent,
    DialogObjectStorageInfoComponent,
    DialogBatchCreateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatSelectModule,
    TreeModule
  ]
})
export class DialogModule { }
