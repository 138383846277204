<!-- Modal : 공인아이피 기본정보 수정 모달 -->
<div id="ip_edit_memo" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>공인아이피
            <p class="sub-txt"> | 공인IP의 정보를 수정합니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width: 20%;" />
                    <col style="width: 80%;" />
                </colgroup>
                <tbody *ngIf="selected_floatingip">
                    <tr>
                        <th scope="row">IP 주소</th>
                        <td>
                            <input type="text" name="" id="editIpAdress"
                                [ngModel]="selected_floatingip.floating_ip_address" readonly />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <textarea name="" id="editFipMemo" rows="5" placeholder="내용을 입력해주세요" maxlength="255"
                                [(ngModel)]="edit_fip_description"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="updateFloatingIp()" class="btn big ciblue mg_r4">수정</a>
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->