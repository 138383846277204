<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Network</li>
      <li class="active">Security Group</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let item of securityGroupLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons">security</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{item.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{{item.name}}</td>
                    </tr>
                    <tr>
                      <th>설명</th>
                      <td>{{item.description}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="card-content"> 
                    <a class="waves-effect waves-light btn darken white-text" 
                      routerLink="/m/securitygroup/ruleManage/{{item.id}}">규칙보기</a>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>