<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>회원관리</h2>
    <div class="lineMap">
      회원관리 / <span>서브 계정 관리</span>
    </div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="lyBox">
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width: 20%;" />
                  <col style="width: 80%;" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">서브 계정</th>
                    <td>
                      <!-- <a class="btn mid blue" onclick="fn_modalOpen('user_create')"><i class="ico ico_register_white"></i>신규생성</a> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- //lyBox -->
        <!-- lyBox -->
        <div class="lyBox">
          <div class="titArea">
            <h3>서브 계정 관리</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <!-- <input type="search" class="form-control" name="" id="search" value=""    [(ngModel)]="filterText" placeholder="검색어를 입력해주세요" spellcheck="false" autocomplete="off"/> -->
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                </div>
                <!-- 접기 버튼 -->
                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <table class="tbl_col_tp1 dropmenu pdTp2">
                <colgroup>
                  <col style="width: 60px;" />
                  <col style="width: 60px;" />
                  <col style="width: 150px;" />
                  <col style="width: 100px;" />
                  <col style="width: 150px;" />
                  <col style="width: 100px;" />
                  <col style="width: 100px;" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col"><input type="checkbox" name="" id="" /></th>
                    <th scope="col">번호</th>
                    <th scope="col">ID</th>
                    <th scope="col">이름</th>
                    <th scope="col">프로젝트</th>
                    <th scope="col">사용자 유형</th>
                    <th scope="col">생성일</th>
                  </tr>
                </thead>
                <!-- <tbody>
                  <tr *ngFor="let subUser of subUsers  | tableFilter: filterText; index as i" (contextmenu)="onClickList(subUser)">
                    <td><input type="checkbox" name="" id="" /></td>
                    <td>{{ i + 1 }}</td>
                    <td>
                      <a>{{
                        subUser.userId
                      }}</a>
                      <input type="hidden" value="user01" />
                    </td>
                    <td>{{subUser.name}}</td>
                    <td><p *ngFor="let project of subUser.projects" >{{project.name}}</p></td>
                    <td>
                      <span *ngIf="subUser.type==1">운영담당자</span>
                      <span *ngIf="subUser.type==2">회계담당자</span>
                    </td>
                    <td>{{ subUser.createdDate.split("T")[0] }}</td>
                  </tr>
                </tbody> -->
              </table>
              
              <div class="btn_wrap tp1"></div>
            </div>
          </div>
        </div>
        <!-- //lyBox -->

        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<!-- <div class="dropdown">
  <div id="myDropdown" class="dropdown-content">
    <a onclick="fn_modalOpen('user_create')">서브계정 생성</a>
    <a onclick="fn_modalOpen('user_modify')" *ngIf="modifyEnable">서브계정 수정</a>
    <a onclick="fn_modalMsg('서브계정을 삭제하시겠습니까?')">서브계정 삭제</a>
  </div>
</div> -->


<!-- Modal : 서브계정 생성 모달 -->
<div id="user_create" class="modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      서브계정 생성
      <p class="sub-txt">| 서브계정 생성 설명입니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <!-- <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>이름</th>
            <td>
              <input type="text" name="userName" value="" placeholder="이름을 입력해주세요" spellcheck="false" autocomplete="off" (change)="onChange($event.target)" >
              <div class="invalid-feedback" *ngIf="name_errMsg != ''">
                <div>{{name_errMsg}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <th>아이디</th>
            <td>
              <input type="email" name="userId" value="" placeholder="아이디(이메일)을 입력해주세요" spellcheck="false" autocomplete="off"
                (change)="onChange($event.target)" />
              <div class="invalid-feedback" *ngIf="id_errMsg != ''">
                <div>{{id_errMsg}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <th>패스워드</th>
            <td>
              <input type="password" name="password" value="" placeholder="패스워드를 입력해주세요" maxlength="20"
                (change)="onChange($event.target)" />
              <div class="invalid-feedback" *ngIf="pw_errMsg != ''">
                <div>{{pw_errMsg}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <th>사용자 유형</th>
            <td>
              <label>
                <input type="radio" name="type" checked="checked" value="1" (change)="onChange($event.target)" />
                운영담당자
              </label>
              <label>
                <input type="radio" name="type" value="2" (change)="onChange($event.target)" />
                회계담당자
              </label>
            </td>
          </tr>
          <tr *ngIf="projectEnable">
            <th>프로젝트</th>
            <td>              
              <select multiple style="height: 100px;" (change)="onChange($event.target)">
                <option *ngFor="let project of projectList" value="{{ project.id }}">
                  {{ project.name }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a class="btn big orange mg_r4" (click)="createSubUser()">생성</a>
      <a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn">닫기</a>
    </div>
  </div> -->
  <!-- //modal_content -->
</div>
<!-- //Modal -->

<!-- Modal : 서브계정 수정 모달 -->
<div id="user_modify" class="modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      서브계정 수정
      <p class="sub-txt">| 서브계정 수정 설명입니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
          <col style="width: 10%; min-width: 100px;" />
        </colgroup>
        <tbody>
          <!-- <tr>
            <th>프로젝트 관리</th>
            <td>
              <div class="input-group" *ngFor="let project of projectsOfSubUser" style="margin-bottom: 8px;">
                <input type="text" [attr.value]="project.name" readonly />
                <div class="input-group-btn" (click)="deleteRoleBind(project.id)">
                  <button class="btn mid dGray on">
                    <i class="ico ico_moreTp1"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr> -->
          <tr>
            <th>프로젝트 추가</th>
            <td>
              <!-- 운영담당자일 경우에만 활성화 -->
              <!-- <div class="input-group">
                <select (change)="onChangeProjectIdToAdd($event.target.value)">
                  <option value="{{ project.id }}" *ngFor="let project of restProjects">
                    {{ project.name }}
                  </option>
                </select>
                <div class="input-group-btn">
                  <button class="btn mid dGray" (click)="createRoleBind()">
                    <i class="ico ico_moreTp1"></i>
                  </button>
                </div>
              </div> -->
            </td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td>
              <!-- 운영담당자일 경우에만 활성화 -->
              <div class="input-group">
                <input type="search" name="" id="search" value="" placeholder="비밀번호를 입력해주세요" class="form-control">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">수정</a>
      <a href="#close-modal" rel="modal:close" class="btn big orange">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->

<!-- <app-console-common-modal (modalMsg)="deleteSubUser()"></app-console-common-modal> -->