<!-- Contents -->
<div id="Contents" class="dashboard">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>대시보드</h2>
        <div class="lineMap">
            Dashboard  /  <span>Monitoring</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec">
            <div class="sec_content row">
                <!-- lyBox -->
                <div class="lyBox col col12 consoleSubBody">
                    <div class="titArea">
                        <h3>서비스 신청 현황</h3>
                    </div>
                    <div class="contArea"> 
                        <div class="boardBox">
                            <table class="tbl_col_tp1">
                                <colgroup>
                                    <col style="width: 60px;">
                                    <col >
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="2">Server</th>
                                        <th>IP</th>
                                        <th>Status</th>
                                        <th>CPU</th>
                                        <th>MEM</th>
                                        <th>Disk</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>bls1</td>
                                        <td>10.41.26.26</td>
                                        <td><label class="dot green"></label>Active</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                        <td>0%</td>                                        
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>bls2</td>
                                        <td>10.41.26.26</td>
                                        <td><label class="dot green"></label>Active</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                        <td>0%</td>                                        
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>bls3</td>
                                        <td>10.41.26.26</td>
                                        <td><label class="dot red"></label>Error</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                        <td>0%</td>                                        
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>bls4</td>
                                        <td>10.41.26.26</td>
                                        <td><label class="dot green"></label>Active</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                        <td>0%</td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <div class="lyBox col col12 consoleMainBody">
                    <div class="titArea">
                        <h3>전체 리소스 사용량</h3>
                    </div>
                    <div class="contArea tpA"> 
                        <div class="imgArea col col4">
                            <img src="../../../assets/img/console/img_dashboard_03.jpg" alt="">
                        </div>
                        <div class="imgArea col col4">
                            <img src="../../../assets/img/console/img_dashboard_01.jpg" alt="">
                        </div>
                        <div class="imgArea col col4">
                            <img src="../../../assets/img/console/img_dashboard_02.jpg" alt="">
                        </div>
                        <div class="btnArea"></div>
                    </div>
                </div>
                <!-- //lyBox -->

            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->
</div>
<!-- //Contents -->