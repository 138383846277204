import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class CephapiService {

  constructor(private http: HttpClient,private router:Router) {
    this.handleError = this.handleError.bind(this);
   }

  getUsers() {
    return this.http.get('/cephapi/admin/user').pipe(catchError(this.handleError));
  }

  getUser(uid) {
    return this.http.get('/cephapi/admin/user/'+uid).pipe(catchError(this.handleError));
  }

  createUser(body) {
    return this.http.post('/cephapi/admin/user', body).pipe(catchError(this.handleError));
  }

  updateUser(uid, body) {
    return this.http.put('/cephapi/admin/user/'+uid, body).pipe(catchError(this.handleError));
  }

  deleteUser(uid) {
    return this.http.delete('/cephapi/admin/user/'+uid).pipe(catchError(this.handleError));
  }

  checkService() {
    return this.http.get('/cephapi/admin/check').pipe(catchError(this.handleError));
  }

  getRgwUsers() {
    return this.http.get('/cephapi/admin/rgw/user').pipe(catchError(this.handleError));
  }

  getRgwUser(uid) {
    return this.http.get('/cephapi/admin/rgw/user'+uid).pipe(catchError(this.handleError));
  }

  createRgwUser() {
    return this.http.post('/cephapi/admin/rgw/user', {}).pipe(catchError(this.handleError));
  }

  updateRgwUser(uid, body) {
    return this.http.put('/cephapi/admin/rgw/user/'+uid, body).pipe(catchError(this.handleError));
  }

  deleteRgwUser(uid) {
    return this.http.delete('/cephapi/admin/rgw/user/'+uid).pipe(catchError(this.handleError));
  }

  getBuckets() {
    return this.http.get('/cephapi/s3/bucket').pipe(catchError(this.handleError));
  }

  getBucket(bid) {
    return this.http.get('/cephapi/s3/bucket/'+bid).pipe(catchError(this.handleError));
  }

  createBucket(data) {
    let bid = data;
    return this.http.put('/cephapi/s3/bucket/'+bid, data).pipe(catchError(this.handleError));
  }

  deleteBucket(bid) {
    return this.http.delete('/cephapi/s3/bucket/'+bid).pipe(catchError(this.handleError));
  }

  getObjects(bid, params) {
    let hp = new HttpParams();

    hp = hp.append('delimiter', params.delimiter);
    hp = hp.append('prefix', params.prefix)

    return this.http.get(`/cephapi/s3/bucket/${bid}/objects`, { params: hp}).pipe(catchError(this.handleError));
  }

  createFolder(bid, params) {
    return this.http.post(`/cephapi/s3/bucket/${bid}/folder`, params).pipe(catchError(this.handleError));
  }

  downloadObject(data) {
    let hp = new HttpParams();

    hp = hp.append('prefix', data.prefix);
    hp = hp.append('object', data.object.name);

    return this.http.get(`/cephapi/s3/bucket/${data.bucket}/object/`, {params: hp, responseType: 'blob'}).pipe(catchError(this.handleError));
  }

  deleteObject(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };

    return this.http.delete(`/cephapi/s3/bucket/${data.bucket}/object`, options).pipe(catchError(this.handleError));
  }

  deleteTempObject(data) {
    let hp = new HttpParams();

    hp = hp.append('bucket', data.bucket)
    hp = hp.append('prefix', data.prefix);
    hp = hp.append('object', data.name);

    return this.http.delete(`/cephapi/s3/object`, {params: hp}).pipe(catchError(this.handleError));
  }

  copyMoveObject(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      data,
    };

    return this.http.put(`/cephapi/s3/bucket/${data.source_bucket}/object`, options).pipe(catchError(this.handleError));
  }

  renameObject(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      data,
    };

    return this.http.put(`/cephapi/s3/bucket/${data.source_bucket}/object`, options).pipe(catchError(this.handleError));
  }

  getInfo() {
    return this.http.get(`/cephapi/s3/info`).pipe(catchError(this.handleError));
  }

  getUsage() {
    return this.http.get(`/cephapi/s3/usage`).pipe(catchError(this.handleError));
  }

  getChildrenDir(data) {
    let hp = new HttpParams();

    hp = hp.append('prefix', data.prefix)

    return this.http.get(`/cephapi/s3/bucket/${data.bucket}/dirs`, { params: hp}).pipe(catchError(this.handleError));
  }

  getObjectInfo(data) {
    let hp = new HttpParams();

    hp = hp.append('prefix', data.object.originName);
    hp = hp.append('type', data.object.type);

    return this.http.get(`/cephapi/s3/bucket/${data.bucket}/object/info`, { params: hp}).pipe(catchError(this.handleError));
  }

  getObjectCount(data) {
    console.log(data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      data,
    };

    return this.http.post(`/cephapi/s3/bucket/${data.bucket}/object/count`, options).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        // 백엔드에서 실패한 것으로 보낸 에러.
        // console.log(error.error)
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error);
  }
}