import { Component, OnInit } from '@angular/core';

import { OsapiService } from '../../osapi.service';
import { GWService } from '../../gwapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import { DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-apigateway-api-list',
  templateUrl: './console-apigateway-api-list.component.html',
})

export class ConsoleApigatewayApiListComponent implements OnInit {

  isActived: boolean = false;
  isChecked: boolean = false;

  selected_api: any = {};

  api_list:any;

  filterText:any;
  
  
  constructor(
    // @Inject(DOCUMENT) private document: Document,
    // private renderer: Renderer2,
    // private http: HttpClient,
    private gwapi:GWService,
    private osapi:OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private router:Router,
    public global: GlobalService,
    private dialogs:DialogSetService,
    private common:ConsoleNavComponent
  ) { }

  ngOnInit(): void {
    this.getApiList();
  }

  getApiList(){
    this.spinner.show();
    this.gwapi.getAPIList().subscribe((data: any) => {
      this.api_list = data;
      console.log(data)
      this.spinner.hide();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  onClickList(api) {
    this.isActived = true;
    this.selected_api = api;
  }

  openGwApiUpdate(api) {
    this.dialogs.openGwApiCreateDialog({selected_api:api});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.getApiList();
      }
    });
    
  }
  openDeleteAPIModal(api){
    this.selected_api = api;
    this.dialogs.openDefaultDeleteDialog({
      title:"API 삭제",
      content:"선택하신 API 정보를 삭제합니다."});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "okay"){
        this.deleteAPI();
      }
    });

  }
  deleteAPI() {
    this.spinner.show();
    this.gwapi.deleteAPI(this.selected_api.kong_service_id).subscribe((data: {}) => {
      this.spinner.hide();
      this.getApiList();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }


  openGwRouteCreateDialog(api) {
    this.dialogs.openGwRouteCreateDialog({selected_api:api});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.getApiList();
      }
    });
  }

  openGwRouteUpdateDialog(route_object) {
    this.dialogs.openGwRouteUpdateDialog({selected_api:this.selected_api,selected_route:route_object});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.getApiList();
      }
    });
  }
  
  openGwIpRestrictionDialog(route_object){
    this.dialogs.openGwIpRestrictionDialog({target_route:route_object});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.getApiList();
      }
    });

  }

  
  openDeleteRouteModal(route){
    this.dialogs.openDefaultDeleteDialog({
      title:"접근경로 삭제",
      content:"접근경로("+route.name+")의 정보를 삭제합니다."});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "okay"){
        this.deleteRoute(route.kong_route_id);
      }
    });

  }
  deleteRoute(route_id) {
    this.spinner.show();
    this.gwapi.deleteRoute(route_id).subscribe((data: {}) => {
      this.spinner.hide();
      this.getApiList();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }



}
