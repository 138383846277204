import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-common-top',
  templateUrl: './common-top.component.html',
})
export class CommonTopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  // scroll top
  showScroll: boolean;
  showScrollHeight = 100;
  hideScrollHeight = 10;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
      this.showScroll = true;
    } else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
      this.showScroll = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }
}
