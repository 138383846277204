<h5 mat-dialog-title>규칙 삭제</h5>
<div mat-dialog-content>
  <p>해당 규칙을 삭제 하시겠습니까?</p>
</div>
<section>
  <div mat-dialog-actions>
    <button
      mat-button [mat-dialog-close]="true" cdkFocusInitial class="di_button waves-effect waves-light btn darken white-text"
      (click)="deleteRule();">확인
    </button>
    <button mat-button mat-dialog-close class="di_button waves-effect waves-light btn materialize-red darken-2 white-text" (click)="closeDialog();">취소</button>
  </div>
</section>