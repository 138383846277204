import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-subnet-list',
  templateUrl: './mobile-console-subnet-list.component.html',
  styleUrls: ['./mobile-console-subnet-list.component.css']
})
export class MobileConsoleSubnetListComponent implements OnInit {
  subnetLists: any = [];
  networkLists: any = [];
  resource: any;
  is_network_list_loaded=false;
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    private spinner: NgxSpinnerService
  ) { }


  ngOnInit(): void {
    this.spinner.show();
    this.getSubnetList();
  }

  getSubnetList() {
    this.spinner.show();
    this.osapi.getSubnetList().subscribe(
      (data: any) => {
        this.subnetLists = data;

        for(let subnet of this.subnetLists){
          subnet.network_type = "ext";
          subnet.isDefaultSubnet = false;
        }
        this._blank = ((this.subnetLists != 0) ? true : false);
        this.getNetworkList();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

  getNetworkList() {
    this.osapi.getResourceAll().subscribe(
      (data: any[]) => {
        this.resource = data;

        this.osapi.getNetworkList().subscribe(
          (data:any) => {
            this.spinner.hide();
            this.is_network_list_loaded=true;
            /**
             * 20200902 cbkim
             * 소유한 네트워크만 표시
             */
            this.networkLists= [];
            for(let network of data){
              network.isDefaultNetwork = false;
              if(network.isNetworkOwner){
                if(network.name == 'Default Network' && network.cidr == "192.168.0.0/16"){
                  network.isDefaultNetwork = true;
                }
                this.networkLists.push(network);
              }
            }

            //this.networkLists= data;

            // cidr 찾기
            for (let r of this.resource) {
              for (let n of this.networkLists) {
                if (r.objectId == n.id) {
                  n.cidr = r.value;
                }
              }
            }
            
            for (let subnet of this.subnetLists) {
              for (let network of this.networkLists) {
                if(subnet.network_id == network.id){
                  subnet.network_type = network.network_type;
                  subnet.isDefaultSubnet = network.isDefaultNetwork;
                  subnet.network = network
                }
              }
            }
          },
          (error) => {
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    )
  }

}
