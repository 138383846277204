import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-admin-board-notice',
  templateUrl: './admin-board-notice.component.html',
})
export class AdminBoardNoticeComponent implements OnInit {
  constructor(
    private webapi: WebApiService,
    private spiner: NgxSpinnerService,
    private common: AdminNavComponent
  ) { }

  userInfoList: any = [];

  noticeList: any = [];
  selected_notice: any = {};
  modal_comp_category: String = '요금';
  modal_comp_title: String = '';
  modal_comp_contents: String = '';

  modal_comp_file: any;

  searchCate: String = '';
  searchTitle: String = '';

  //페이징변수
  curPage: any;
  totalCount: any;

  noticeForm = new FormGroup({
    boardId: new FormControl('1', []),
    userId: new FormControl('', []),
    enabled: new FormControl(true, []),
    commentEnabled: new FormControl(true, []),
    category: new FormControl('요금', []),
    title: new FormControl('', [Validators.required]),
    contents: new FormControl('', [Validators.required]),
    file: new FormControl('', []),
  });

  ngOnInit(): void {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    this.noticeForm.patchValue({
      userId: this.userInfoList.userId
    })
    this.getNoticeList(0);
  }

  getNoticeList(page) {
    this.curPage = page;
    let searchQuerys = `&o=created_date.DESC&l=10&s=${this.curPage * 10}`;
    if (this.searchTitle) {
      searchQuerys += `&title=${decodeURIComponent(this.searchTitle.toString())}&lk=title`;
    }
    this.spiner.show();
    this.webapi.getNoticeList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.noticeList = data['result'];
        this.totalCount = data['total'];
        this.common.getThemeFont();
      }, (err) => {

      }
    );
    this.spiner.hide();
  }
  //페이징함수
  getMovePage(e) {
    this.getNoticeList(e.page);
  }

  getNoticeDetail(notice: any) {
    this.selected_notice = notice;
    this.modal_comp_category = this.selected_notice.category;
    this.modal_comp_title = this.selected_notice.title;
    this.modal_comp_contents = this.selected_notice.contents;
    this.modal_comp_file = new Array();

    fn_modalOpen('notice_detail');
  }

  onCategoryChange(deviceValue) {
    this.modal_comp_category = deviceValue;
  }
  onTitleChange(deviceValue) {
    this.modal_comp_title = deviceValue;
  }
  onContentsChange(deviceValue) {
    this.modal_comp_contents = deviceValue;
  }
  onFileChanged(deviceValue) {
    this.modal_comp_file = deviceValue.target.files;
  }

  onClickList(notice) {

    this.selected_notice = notice;
  }

  onSubmit(file) {

    const formData = new FormData();
    formData.append('boardId', this.noticeForm.controls.boardId.value);
    formData.append('userId', this.noticeForm.controls.userId.value);
    formData.append('category', this.noticeForm.controls.category.value);
    formData.append('title', this.noticeForm.controls.title.value);
    formData.append('contents', this.noticeForm.controls.contents.value);
    formData.append('enabled', this.noticeForm.controls.enabled.value);

    formData.append(
      'commentEnabled',
      this.noticeForm.controls.commentEnabled.value
    );

    if (file.length > 0) {
      formData.append('file', file[0]);
    }
    this.spiner.show();
    this.webapi.createArticles(formData).subscribe((data: {}) => {
      alert('저장되었습니다.');
      (<HTMLInputElement>document.getElementById('closeModalBtn')).click();

      this.submitFormReset();

      // 재검색
      this.getNoticeList(0);
    });
    this.spiner.hide();
  }

  submitFormReset() {
    this.noticeForm.setValue({
      boardId: '1',
      userId: this.userInfoList.userId,
      enabled: true,
      commentEnabled: true,
      category: '요금',
      title: '',
      contents: '',
      file: '',
    });
  }

  // 검색
  fn_noticeSearch(title) {
    this.searchTitle = title;
    this.getNoticeList(0);
  }

  onSearchTitleChange(deviceValue) {
    this.searchTitle = deviceValue;
  }

  downloadFile() {
    location.href =
      '/webapi/articles/download/' + this.selected_notice.attachedFilePath;
  }

  deleteFile() {
    this.selected_notice.attachedFilePath = '';
  }

  deleteNotice() {
    this.spiner.show();
    this.webapi.deleteArticles(this.selected_notice).subscribe(
      (response) => {
        alert('삭제되었습니다.');
        // 재검색
        this.getNoticeList(0);
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  updateNotice() {
    this.selected_notice.category = this.modal_comp_category;
    this.selected_notice.title = this.modal_comp_title;
    this.selected_notice.contents = this.modal_comp_contents;

    const formData = new FormData();

    formData.append('articleId', this.selected_notice.articleId);
    formData.append('boardId', this.selected_notice.boardId);
    formData.append('userId', this.selected_notice.userId);
    formData.append('category', this.selected_notice.category);
    formData.append('title', this.selected_notice.title);
    formData.append('contents', this.selected_notice.contents);
    formData.append('enabled', this.selected_notice.enabled);
    formData.append('commentEnabled', this.selected_notice.commentEnabled);

    if (this.modal_comp_file.length > 0) {
      formData.append('file', this.modal_comp_file[0]);
    } else if (
      this.selected_notice.attachedFilePath != null &&
      this.selected_notice.attachedFilePath != ''
    ) {
      formData.append(
        'attachedFilePath',
        this.selected_notice.attachedFilePath
      );

    }

    if (this.modal_comp_title.trim() == '') {
      alert('제목을 입력해 주십시요.');
    } else if (this.modal_comp_contents.trim() == '') {
      alert('내용을 입력해 주십시요.');
    } else {
      this.webapi.updateArticlesForm(formData).subscribe((data) => {
        alert('저장되었습니다.');
        (<HTMLInputElement>document.getElementById('closeModalBtn2')).click();
        this.getNoticeList(0);
      });
    }
  }
}
