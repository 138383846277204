import { Component, OnInit, Inject } from '@angular/core';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';

@Component({
  selector: 'app-dialog-objectstorage-folder-create',
  templateUrl: './dialog-objectstorage-folder-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogObjectStorageFolderCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private cephapi: CephapiService,
    public dialogRef: MatDialogRef<DialogObjectStorageFolderCreateComponent>

  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  newFolder: any = { name: '', type: 'folder', acl: 'private', prefix: '' };

  ngOnInit(): void {
    console.log(this.dialogData);

  }


  createFolder() {
    if(this.newFolder.name == ''){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'이름을 입력해주세요.'
        }
      });
      return
    }
    try {
      this.spinner.show();
      this.newFolder.prefix = this.dialogData.prefix;
      this.cephapi.createFolder(this.dialogData.bucket, this.newFolder).subscribe((data) => {
        this.spinner.hide();
        this.closeDialogMsg({code:"success",data:data});
      },
      (error) => {
        let msg = "일시적으로 문제가 발생하였습니다. 새로고침해주세요.";
        if([403, 409].includes(error.status)) msg = "이미 존재하는 버킷 이름입니다.";
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:msg
        }
      });
      });
    } catch (e) {
      this.spinner.hide();

    }
  }

}
