<!-- Container -->
<div id="Container" class="mypage">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>회원 탈퇴</h2>
        </div>
    </div>
    <div id="Contents">
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>회원 탈퇴</h3>
                    <div class="subTit">
                        그 동안 올이음 클라우드 플랫폼을 이용해 주셔서 감사합니다. 회원 탈퇴를 신청하기 전에 아래 내용을 꼭 확인해 주세요.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="serviceInfo">
                        <div class="titArea">                                                        
                            <div class="priceArea">
                                <ul class="lsTp4">
                                    <li>
                                        <p class="cont price">
                                            회원께서는 아래 사항이 모두 확인되어야 회원 탈퇴 신청 및 탈퇴가 완료됩니다
                                        </p>
                                    </li>
                                    <li>
                                        <p class="cont">이용중인 서비스는 모두 반납하셔야 합니다.</p>
                                    </li>
                                    <li>
                                        <p class="cont">이용하신 서비스의 요금이 모두 납부되어야 회원 탈퇴가 완료됩니다.</p><br>
                                        <p class="cont">예시) 1월 중 신청 시, 1월 요금의 납부가 완료되는 2월 15일 이후 탈퇴가 완료됩니다.</p>
                                    </li>
                                    <li>
                                        <p class="cont">
                                            회원 탈퇴 전 이용하신 서비스의 요금은 익월에 청구됩니다.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="cont">
                                            회원 탈퇴 시, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정한 기간동안 법률에 명시된 정보를 보관하며, 그 이외의 정보에 대해서는 파기합니다.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="cont">
                                            관계법령에서 정한 일정 기간 동안 법률에 명시된 정보를 보관하는 경우 해당 정보는 법률상 정해진 목적 내에서만 이용하며, 기간 종료 시 파기합니다.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="btnArea"></div> -->
                        <div class="contArea">
                            <div class="boardBox">
                                <table class="tbl_col_tp2 colorTpA">
                                    <colgroup>
                                        <col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">정보</th>
                                            <th scope="col">보존 이유</th>
                                            <th scope="col">보존 기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>계약 또는 청약철회 등에 관한 기록</td>
                                            <td>전자상거래 등에서의 소비자 보호에 관한 법률	</td>
                                            <td>5년</td>
                                        </tr>
                                        <tr>
                                            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                            <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                            <td>5년</td>
                                        </tr>
                                        <tr>
                                            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                            <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                            <td>3년</td>
                                        </tr>
                                        <tr>
                                            <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                                            <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                            <td>6개월</td>
                                        </tr>
                                        <tr>
                                            <td>소프트웨어</td>
                                            <td>국세기본법, 법인세법</td>
                                            <td>5년</td>
                                        </tr>
                                        <tr>
                                            <td>웹사이트 방문에 관한 기록</td>
                                            <td>통신비밀보호법</td>
                                            <td>3개월</td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                            <div class="btnArea"></div>
                            <div class="titArea">                                                        
                                <div class="priceArea">
                                    <ul class="lsTp4">
                                        <li>
                                            <p class="cont price">
                                                탈퇴 후 재가입
                                            </p>
                                        </li>
                                        <li>
                                            <p class="cont">탈퇴한 아이디로 재가입 시, 탈퇴 이전 크레딧 신청 여부에 따라 할인 크레딧 부여에 제한이 있을 수 있습니다.</p>
                                        </li>
                                        <li>
                                            <p class="cont">탈퇴한 아이디로 재가입은 가능하나, 기존 정보는 삭제되어 복구할 수 없으니 유의하시기 바랍니다.</p>
                                        </li>                                            
                                    </ul>
                                </div>
                            </div>
                            <form [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
                            <div class="sec_content">
                                <div class="boardBox tp3">
                                    <table class="tbl_row_tp1">
                                        <colgroup>
                                            <col style="width: 20%;">
                                            <col style="width: 80%;">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th scope="row">탈퇴 사유</th>
                                                <td>
                                                    <textarea name="reason" id="reason" formControlName="reason" cols="30" row="10" maxlength="250" style="height: 190px;" required></textarea>                                                      
                                                </td>
                                            </tr>                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="btn_wrap tp2">
                                <button type="submit" class="btn big red">회원탈퇴</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>