import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-loadbalancer-associate-ip',
  templateUrl: './dialog-loadbalancer-associate-ip.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogLoadbalancerAssociateIpComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogLoadbalancerAssociateIpComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_loadbalancer:any={};

  floatingIpList:any=[];
  
  selected_fip_id:string;

  ngOnInit(): void {

    this.floatingIpList= this.dialogData.floatingIpList;
    this.selected_loadbalancer= this.dialogData.selected_loadbalancer;
  }

  /**
   * @param id fip id
   */
  changeFip(id){

    this.selected_fip_id = id;
  }

  
  patchFloatingIp() {
    this.spinner.show();
    /**
     * address_id: "4b265305-a722-466e-afd0-b977739fb7c2"
       port_id: "5fd62174-78b4-4eb4-a02e-7e4981ef34d1_172.16.0.219"
     */



    var address_id = this.selected_fip_id;
    var port_id = `${this.selected_loadbalancer.vip_port_id}_${this.selected_loadbalancer.vip_address}`;

    this.osapi.associateFloatingIp(address_id, port_id).subscribe(
      (data) => {

        this.spinner.hide();
        // this.getLoadbalancerList();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'success',
            title: '공인IP 할당',
            message: `로드밸런서(${this.selected_loadbalancer.name})에 공인IP가 할당되었습니다.`
          }
        })
        this.closeDialogMsg("success");
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '로드밸런서 공인IP 할당에 실패하였습니다.'
          }
        })
      }
    )
  }

}
