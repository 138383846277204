import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { WebApiService } from './webapi.service';
import { DOCUMENT } from '@angular/common';
import { AdminService } from './admin.service';

import {AdminNavComponent} from './web/admin/admin-nav/admin-nav.component';
import { FrontApiService} from './frontapi.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private webapi: WebApiService) {    
  }
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    return this.webapi.isCompanyEnabled().toPromise().then((data => {
      if(data === true){
        return true
      }else {
        if(data.login){
          alert('승인 대기 중 입니다.');
        }
        this.router.navigate(['/web']);
        return false
      }
    }))    
    return
  }
}

@Injectable()
export class LoginAuthGuard implements CanActivate{
  constructor(private router: Router, private webapi: WebApiService) {    
  }
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    return this.webapi.blsLoginCheck().toPromise().then(
      (response)=>{
        if(response.isAuthenticated === true){
          return true
        }else{
          alert('로그인 해주시기 바랍니다.');
          this.router.navigate(['/member/login']);
          return false
        }
      }
    )
    return
  }
}

@Injectable()
export class blsAdminAuthGuard implements CanActivate {
  constructor (private router: Router, private webapi: WebApiService){

  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    return this.webapi.blsAdminCheck().toPromise().then(
      (data)=>{
        if(data.result === true){
          return true;
        }else {
          alert('bls 관리자가 아닙니다. 관리자 계정으로 로그인 해주시기 바랍니다.');
          this.router.navigate(['/member/login']);
          return false
        }
      }
    )
    return
  }
}

@Injectable()
export class ThemeGuard implements CanActivate {
  constructor(@Inject(DOCUMENT) private document: HTMLDocument,
  private frontapi:FrontApiService,
  private api:AdminService) {
  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.frontapi.changeMessage({type:'theme'});
    return true;
    // return this.api.getThemes().toPromise().then(
    //   (data)=>{
    //     var msg = {
    //       type:'theme',
    //       data:data
    //     };
    //     console.log(`frontapi : ${msg}`)
        
    //     this.frontapi.changeMessage(msg);
    //     return true;
    //   }
    // )
  }
}

