import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../../admin.service';
import { WebApiService } from '../../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';
declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-admin-board-qna',
  templateUrl: './admin-board-qna.component.html',
})
export class AdminBoardQnaComponent implements OnInit {

  constructor(
    private webapi: WebApiService,
    private spiner: NgxSpinnerService,
    private common: AdminNavComponent
  ) { }

  userInfoList: any = [];

  inquiryList: any = [];
  inquiryDetail: any = [];
  inquiryComment: any = [];

  detail_title: String = "";
  detail_contents: String = "";
  detail_category: String = "";

  modal_inquiry_ans: String = "";

  searchCate: String = "";
  searchTitle: String = "";

  //페이징변수
  curPage: any;
  totalCount: any;

  ngOnInit(): void {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    this.getInquiryList(0);
  }

  getInquiryList(page) {
    this.curPage = page;

    var searchQuerys = "o=created_date.DESC&l=10&s=" + (this.curPage * 10);
    if (this.searchCate != "") {
      searchQuerys += "&category=" + decodeURIComponent(this.searchCate + "");
    }
    if (this.searchTitle != "") {
      searchQuerys += "&title=" + decodeURIComponent(this.searchTitle + "") + "&lk=title";
    }
    this.spiner.show();
    this.webapi.getInquiryList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.inquiryList = data['result'];
        this.totalCount = data['total'];
        this.common.getThemeFont();
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  //페이징함수
  getMovePage(e) {
    this.getInquiryList(e.page)
  }


  fn_detailInquiry(key) {
    this.getInquiryDetail(key)
    fn_modalOpen('qna_detail');
  }

  getInquiryDetail(key) {
    this.spiner.show();
    this.webapi.getInquiryDetail(key).subscribe(
      (response) => {
        const data = response.body
        this.inquiryDetail = data['result'];

        this.modal_inquiry_ans = "";

        this.detail_title = this.inquiryDetail[0].title;
        this.detail_contents = this.inquiryDetail[0].contents;
        this.detail_category = this.inquiryDetail[0].category;

        if (this.inquiryDetail[0].completed)
          this.getInquiryComment(key);
      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  getInquiryComment(key) {
    this.webapi.getInquiryComment(key).subscribe((response) => {
      const data = response.body;
      this.inquiryComment = data['result'];

      this.modal_inquiry_ans = this.inquiryComment[0].contents
    });
    // this.webapi.getInquiryComment(key).subscribe((data) => {
    //   this.inquiryComment = data['result'];
    //   this.modal_inquiry_ans = this.inquiryComment[0].contents


    // });
  }



  onInquiryAnsChange(deviceValue) {
    this.modal_inquiry_ans = deviceValue;
  }



  onSearchTitleChange(deviceValue) {
    this.searchTitle = deviceValue;
  }

  fn_inquirySearch(title) {
    this.searchTitle = title;
    this.getInquiryList(0);
  }


  fn_insertComment() {
    const body = {
      issueId: this.inquiryDetail[0].issueId,
      completed: true
    };

    const body2 = {
      issueId: this.inquiryDetail[0].issueId,
      userId: this.userInfoList.userId,
      contents: this.modal_inquiry_ans.trim(),
      enabled: true
    };

    if (this.modal_inquiry_ans.trim() == "") {
      alert("답변을 입력해 주십시요.")
    } else {
      this.spiner.show();
      if (this.inquiryDetail[0].completed) {
        this.inquiryComment[0].contents = this.modal_inquiry_ans.trim();

        this.webapi.updateInquiryComment(this.inquiryComment[0]).subscribe((data) => {
          alert("수정되었습니다.");
          this.getInquiryList(0);
          (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
        });
      } else {
        this.webapi.insertInquiryComment(body2).subscribe((data) => {


          this.webapi.updateInquiry(body).subscribe((data) => {

            alert("저장되었습니다.");
            this.getInquiryList(0);
            (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
          });
        });
      }
      this.spiner.hide();
    }
    this.getInquiryList(this.curPage)
  }
}
