<!-- Container -->
<div id="Container" class="mypage">
  <div class="visual tp1">
    <div class="cont_inner">
      <h2>회원정보</h2>
    </div>
  </div>
  <div>
    <!-- section -->
    <section class="sec sec_1">
      <!-- sec_inner -->
      <div class="sec_inner">
        <!-- sec_header -->
        <div class="sec_header">
          <h3>Member Information</h3>
          <div class="subTit">
            올이음은 고객님의 소중한 개인정보를 위해 최선을 다하고 있습니다.<br />
            개인정보 중 변경된 내용이 있는 경우 아래에서 해당 내용을
            수정해주세요.
          </div>
        </div>
        <ng-container [formGroup]="companyAdminForm">
          <div class="sec_content" formGroupName="user">
            <div class="rowGroup">
              <div class="row">
                <p class="tit">이름</p>
                <p class="cont">
                  <input type="text" class="wtpB" autocomplete="off" id="name" formControlName="name" required />
                </p>
                <div *ngIf="submitted && companyForm.user.get('name').errors" class="invalid-feedback">
                  <div *ngIf="companyForm.user.get('name').errors.required" style="text-align: center;">
                    이름을 입력해주세요.
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">로그인 아이디</p>
                <p class="cont">
                  <input type="text" class="wtpB" readonly id="userId" formControlName="userId" />
                </p>
              </div>
              <div class="row">
                <p class="tit">현재 비밀 번호</p>
                <div class="cont">
                  <input type="password" class="wtpB" placeholder="비밀번호를 입력해주세요." id="passwordOrg"
                    formControlName="passwordOrg" required />
                </div>
                <div *ngIf="
                    submitted && companyForm.user.get('passwordOrg').errors
                  " class="invalid-feedback">
                  <div *ngIf="companyForm.user.get('passwordOrg').errors.required" style="text-align: center;">
                    현재 비밀번호를 입력해주세요.
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">변경할 비밀 번호</p>
                <p class="cont">
                  <input type="password" class="wtpB" placeholder="변경할 비밀번호를 입력해주세요." id="password"
                    formControlName="password" maxlength="20" />
                </p>
                <div *ngIf="submitted && companyForm.user.get('password').errors" class="invalid-feedback">
                  <div *ngIf="
                      companyForm.user.get('password').errors.pattren ||
                      companyForm.user.get('password').errors.minlength
                    " style="text-align: center;">
                    영문 대문자, 숫자, 특수문자를 1개씩 이상 포함하여 8자리 이상 입력해주세요.
                    (최대 20자)
                  </div>
                </div>
                {{ companyForm.user.get("password").value }}
              </div>
              <div class="row">
                <p class="tit">변경할 비밀 번호 확인</p>
                <p class="cont">
                  <input type="password" class="wtpB" placeholder="변경할 비밀번호를 확인." id="confirmPassword"
                    formControlName="confirmPassword" />
                </p>
                {{ companyForm.user.get("confirmPassword").value }}
              </div>
              <div class="row">
                <p class="tit">전화 번호</p>
                <p class="cont">
                  <input type="text" class="wtpB" id="telephone" placeholder="숫자만 입력해주세요." formControlName="telephone" maxlength="11" />
                </p>
                <div *ngIf="submitted && companyForm.user.get('telephone').errors" class="invalid-feedback">
                  <div *ngIf="
                      companyForm.user.get('telephone').errors.pattren ||
                      companyForm.user.get('telephone').errors.minlength
                    " style="text-align: center;">
                    10 ~ 11자리 숫자만 입력해 주세요
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">핸드폰 번호</p>
                <p class="cont">
                  <input type="text" class="wtpB" id="mobile" placeholder="숫자만 입력해주세요." autocomplete="off" spellcheck="false"
                    formControlName="mobile" required maxlength="11" />
                </p>
                <div *ngIf="submitted && companyForm.user.get('mobile').errors" class="invalid-feedback">
                  <div *ngIf="companyForm.user.get('mobile').errors.required" style="text-align: center;">
                    핸드폰 번호를 입력해주세요.
                  </div>
                  <div *ngIf="
                      companyForm.user.get('mobile').errors.pattren ||
                      companyForm.user.get('mobile').errors.minlength
                    " style="text-align: center;">
                    10 ~ 11자리 숫자만 입력해 주세요
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">우편번호</p>
                <p class="cont">
                  <input type="text" class="wtpA" formControlName="zipCode" readonly required />
                  <input type="text" class="wtpD" formControlName="address" readonly required />
                  <a class="btn mid black" (click)="openDaumPopup('user')">주소찾기</a>
                </p>
              </div>
              <div class="row">
                <p class="tit">상세주소</p>
                <p class="cont">
                  <input type="text" class="wtpB" id="addressDetailUser" formControlName="addressDetail"
                    placeholder="상세주소를 입력해 주세요." required />
                </p>
              </div>
              <div *ngIf="
                  submitted &&
                  (companyForm.user.get('zipCode').errors ||
                    companyForm.user.get('address').errors ||
                    companyForm.user.get('addressDetail').errors)
                " class="invalid-feedback">
                <div style="text-align: center;">주소를 입력해주세요.</div>
              </div>
            </div>
          </div>
          <div class="sec_content" formGroupName="company">
            <div class="rowGroup">
              <div class="row">
                <p class="tit">기업 명</p>
                <p class="cont">
                  <input type="text" class="wtpB" formControlName="companyName" required />
                </p>
                <div *ngIf="
                    submitted && companyForm.company.get('companyName').errors
                  " class="invalid-feedback">
                  <div *ngIf="
                      companyForm.company.get('companyName').errors.required
                    " style="text-align: center;">
                    기업 명을 입력해 주세요.
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">대표자 이름</p>
                <p class="cont">
                  <input type="text" class="wtpB" formControlName="registrationName" required />
                </p>
                <div *ngIf="
                    submitted &&
                    companyForm.company.get('registrationName').errors
                  " class="invalid-feedback">
                  <div *ngIf="
                      companyForm.company.get('registrationName').errors
                        .required
                    " style="text-align: center;">
                    대표자 이름을 입력해주세요.
                  </div>
                  <div *ngIf="
                      companyForm.company.get('registrationName').errors.pattren
                    " style="text-align: center;">
                    한글을 입력해 주세요.
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">사업자등록번호</p>
                <p class="cont">
                  <input type="text" class="wtpB" id="registrationNumber" formControlName="registrationNumber"
                    maxlength="10" readonly />
                </p>
              </div>
              <div class="row">
                <p class="tit">종사업자번호</p>
                <p class="cont">
                  <input type="text" class="wtpB" id="registrationNumberSub" formControlName="registrationNumberSub"
                    maxlength="4" readonly />
                </p>
              </div>
              <div class="row">
                <p class="tit">기업 전화번호</p>
                <p class="cont">
                  <input type="text" class="wtpB" formControlName="telephone" required maxlength="11" />
                </p>
                <div *ngIf="submitted && companyForm.company.get('telephone').errors" class="invalid-feedback">
                  <div *ngIf="companyForm.company.get('telephone').errors.required" style="text-align: center;">
                    기업 전화번호를 입력하세요.
                  </div>
                  <div *ngIf="
                      companyForm.company.get('telephone').errors.pattren ||
                      companyForm.company.get('telephone').errors.minlength
                    " style="text-align: center;">
                    10 ~ 11숫자만 입력해 주세요.
                  </div>
                </div>
              </div>
              <div class="row">
                <p class="tit">우편번호</p>
                <p class="cont">
                  <input type="text" class="wtpA" formControlName="zipCode" readonly required />
                  <input type="text" class="wtpD" formControlName="address" readonly required />
                  <a class="btn mid black" (click)="openDaumPopup('personal')">주소찾기</a>
                </p>
              </div>
              <div class="row">
                <p class="tit">상세주소</p>
                <p class="cont">
                  <input type="text" class="wtpB" id="addressDetailCompany" formControlName="addressDetail"
                    placeholder="상세주소를 입력해 주세요." required />
                </p>
              </div>
              <div *ngIf="
                  submitted &&
                  (companyForm.company.get('zipCode').errors ||
                    companyForm.company.get('address').errors ||
                    companyForm.company.get('addressDetail').errors)
                " class="invalid-feedback">
                <div style="text-align: center;">주소를 입력해주세요.</div>
              </div>
            </div>
            <div class="btn_wrap tp2">
              <a class="btn big blue mg_r8" (click)="onSubmit()">정보수정</a>
              <ng-template [ngIf]="status == '0' || status == '1' || status == '2'">
                <button type="submit" id="" class="btn big red" (click)="withdrawal()">회원탈퇴</button>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- //sec_inner -->
    </section>
    <!-- //section -->
  </div>
</div>
<!-- //Container -->
<hr />
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>