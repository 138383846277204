import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-subuser-list',
  templateUrl: './mobile-console-subuser-list.component.html',
  styleUrls: ['./mobile-console-subuser-list.component.css']
})
export class MobileConsoleSubuserListComponent implements OnInit {
  subUsers: any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.getSubUsers();
  }

  getSubUsers() {
    this.spinner.show();
    this.osapi.getSubUsers().subscribe((data: { result: object }) => {

      if (data.result == null) {
        throw 'osapi.getSubUsers Fail';
      }
      this.subUsers = data.result;
      for(let subuser of this.subUsers){
        subuser.is_project_loaded = false;
        this.setProjectsById(subuser);
      }
      this._blank = ((this.subUsers != 0) ? true : false);
      this.spinner.hide();
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  setProjectsById(subuser) {
    try {
      subuser.projects=[{name:"Loading..."}]
      console.log(subuser.horizonUid);
      if(subuser.horizonUid){
        this.osapi.getProjectsById(subuser.horizonUid).subscribe((data: { items }) => {
          subuser.is_project_loaded = true;
          subuser.projects = data.items;
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
        });
      }
    } catch (e) {

    }
  }
}
