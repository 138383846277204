<div id="Header">
    <div class="logo"style="cursor: pointer;" (click)="routerHome()">
        <a></a>
    </div>
</div>
<div id="Wrap" class="login" style="background-color: #f6f7f8;">
  <div class="Container" class="findid">
    <!-- frm_content -->
    <div class="frm_content">
      <!-- <div class="tag">login</div> -->
      <!-- logo -->
      <div class="logoArea">
        <div class="logo">
          <img src="../assets/img/member/logo_member.png" alt="" routerLink="/" style="cursor: pointer;" />
        </div>
        <h1>로그인</h1>
      </div>
      <!-- //logo -->
      <!-- form -->
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <!-- loginForm -->
        <fieldset class="loginForm">
          <legend>로그인</legend>
          <ul class="row_group">
            <li class="row id">
              <!-- <div class="tit">ID</div> -->
              <div class="cont">
                <input type="email" placeholder="아이디를 입력해주세요." formControlName="userId" spellcheck="false"
                  autocomplete="off" name="loginId" id="loginId" required />
              </div>
              <div *ngIf="submitted && login.userId.errors" class="invalid-feedback tempError">
                <div *ngIf="login.userId.errors.required">이메일을 입력해 주세요.</div>
                <div *ngIf="login.userId.errors.pattern">이메일 형식이 아닙니다.</div>
              </div>
            </li>

            <li class="row pw">
              <!-- <div class="tit">PASSWORD</div> -->
              <div class="cont">
                <input type="password" placeholder="비밀번호를 입력해주세요." formControlName="password" spellcheck="false"
                  autocomplete="off" required />
              </div>
              <div *ngIf="submitted && login.password.errors" class="invalid-feedback tempError">
                <div *ngIf="login.password.errors.required">비밀번호를 입력해주세요.</div>
              </div>
            </li>
          </ul>
          <button type="submit" id="LoginCheck" class="btn_login">
            로그인
          </button>
          <div class="chk_group">
            <div class="inputChk isLabel">
              <input type="checkbox" id="saveChkId" formControlName="loginSave"/>
              <label for="saveChkId" name="saveChkId" id="saveChkIdLabel" (click)="login.loginSave = login.loginSave">아이디 저장</label>
            </div>
          </div>
          <div class="login_footer">
            <a routerLink="/member/find-id">아이디 찾기</a>
            <a routerLink="/member/find-password">비밀번호 찾기</a>
            <a routerLink="/member/signUp">회원가입</a>
          </div>
        </fieldset>
        <!-- //loginForm -->
      </form>
      <!-- //form -->
    </div>
    <!-- //frm_content -->
  </div>
</div>
<!-- //wrap -->
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner> -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>