import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-server-image-create',
  templateUrl: './dialog-server-image-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerImageCreateComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerImageCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
    this.spinner.hide();
  }

  new_image_data:{name:string,description:string,server_id:string} ={name:"",description:"",server_id:""};

  serverList: any=[];
  ngOnInit(): void {

    this.serverList = this.dialogData.serverList;
    if(this.dialogData.selected_server){
      this.new_image_data.server_id = this.dialogData.selected_server.server_id;
    }
  }
  
  
  createServerImage() {
    
    if(this.new_image_data.name == ""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    
    if(this.new_image_data.server_id == ""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이미지를 생성할 서버를 선택해주세요.'
        }
      });
      return;
    }
    this.spinner.show();


    if(this.new_image_data.name == null || this.new_image_data.name ==''){
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'이름을 입력하지 않았습니다.'
        }
      });
      return;
    }
    if(this.new_image_data.server_id == null || this.new_image_data.server_id ==''){
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'서버가 선택되지 않았습니다.'
        }
      });
      return;
    }
    this.osapi.createSnapShotImage(this.new_image_data.server_id, this.new_image_data.name, this.new_image_data.description)
      .subscribe((data: {}) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigateByUrl('/console/image');
          }
        );
      },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          }
        );
      }
    );
  }
}
