<!-- Modal : 메모 수정  -->

<div id="snapshot_edit_memo" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>스토리지 스냅샷
        <p class="sub-txt"> | 설명을 수정합니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>
          <tbody *ngIf="selected_snapshot">
            <tr>
              <th scope="row">스냅샷 이름</th>
              <td>
                <input type="text" name="" id="editSnapshotName" [(ngModel)]="editSnapshotData.name" maxlength="60"/>
              </td>
            </tr>
            <tr>
              <th scope="row">설명</th>
              <td>
                <textarea name="" id="editSnapshotMemo" rows="5" placeholder="내용을 입력해주세요" maxlength="255"
                  [(ngModel)]="editSnapshotData.description"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="updateVolumeSnapshot()" class="btn big ciblue mg_r4" >확인</a>
        <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->