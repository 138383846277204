
<!-- Modal : 프로젝트 생성 모달 -->
<div id="create_project" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            프로젝트 생성
            <p class="sub-txt">사용자가 정의한 별도 프로젝트를 생성합니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width: 40%;" />
                    <col style="width: 60%;" />
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">이름</th>
                        <td>
                            <input type="text" [(ngModel)]="project_modal_name" (change)="onProjectModalNameChange($event.target.value)" required
                                value="" placeholder="내용을 입력해주세요" maxlength="60"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <input type="text" [(ngModel)]="project_modal_description" (change)="onProjectModalDescChange($event.target.value)" name="" id=""
                                value="" placeholder="내용을 입력해주세요" maxlength="255"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">네트워크</th>
                        <td>
                            <input type="radio" name="field_name3" id="create-network-radio1" (click)="setNetworkState(true)"/><label for="create-network-radio1" class="mg_r8"> 생성</label>
                            <input type="radio" name="field_name3" id="create-network-radio2" (click)="setNetworkState(false)" checked /><label for="create-network-radio2"> 생성 안함</label>
                        </td>
                    </tr>
                    <tr *ngIf="isCreateNetwork">
                        <th scope="row">네트워크 이름</th>
                        <td>
                            <input type="text" [(ngModel)]="networkData.name" (change)="chageNetworkName($event.target.value)" name="" id="createNetworkName"
                                value="" placeholder='영문자, _(언더바), -(하이픈) 만 사용 가능합니다.' />
                        </td>
                    </tr>
                    <tr *ngIf="isCreateNetwork">
                        <th scope="row">CIDR</th>
                        <td> 
                            <input type="text" [(ngModel)]="networkData.cidr" (change)="chageNetworkCidr($event.target.value)" name="" id="createNetworkCidr"
                                value="" placeholder="예) 192.168.0.0/16" />
                                <div *ngIf="network_cidr_validate==false" class="invalid-feedback">
                                    <div>유효하지않은 CIDR 입니다.</div>
                                </div>
                                <div *ngIf="network_cidr_not_used==false" class="invalid-feedback">
                                    <div>
                                        <ng-template [ngIf]="!validate_false_by_blsnet">이미 사용중인 네트워크 대역입니다.</ng-template>
                                        <ng-template [ngIf]="validate_false_by_blsnet">
                                            해당 대역은 서비스가능한 대역이 아닙니다.
                                        </ng-template>
                                    </div>
                                </div>
                                <div *ngIf="private_network_cidr_validate==false" class="invalid-feedback">
                                  <div>CIDR은 사설 주소 범위로 입력되어야 합니다.</div>
                                  <div>(10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16)</div>
                                </div>
                        </td>
                    </tr>
                    
                    <tr *ngIf="isCreateNetwork">
                        <th scope="row">서브넷</th>
                        <td>
                            <input type="radio" name="field_name4" id="create-subnet-radio1" (click)="setSubnetState(true)"/><label for="create-subnet-radio1" class="mg_r8"> 생성</label>
                            <input type="radio" name="field_name4" id="create-subnet-radio2" (click)="setSubnetState(false)" checked /><label for="create-subnet-radio2"> 생성 안함</label>
                        </td>
                    </tr>
                    <tr *ngIf="isCreateSubnet">
                        <th scope="row">서브넷 이름</th>
                        <td>
                            <input type="text" [(ngModel)]="subnetData.name" (change)="chageSubnetName($event.target.value)" name="" id="createSubnetName"
                                value="" placeholder='영문자, _(언더바), -(하이픈) 만 사용 가능합니다.' />
                        </td>
                    </tr>
                    <tr *ngIf="isCreateSubnet">
                        <th scope="row">CIDR</th>
                        <td> 
                            <input type="text" [(ngModel)]="subnetData.cidr" (change)="chageSubnetCidr($event.target.value)" name="" id="createSubnetCidr"
                                value="" placeholder="예) 192.168.1.0/24" />
                                <div *ngIf="subnet_cidr_validate==false" class="invalid-feedback">
                                  <div>유효하지않은 CIDR 입니다.</div>
                                </div>
                                <div *ngIf="subnet_cidr_is_include==false" class="invalid-feedback">
                                  <div>입력하신 '{{networkData.cidr}}' 대역의 CIDR을 지정해 주세요.</div>
                                </div>
                                <div *ngIf="subnet_cidr_not_used==false" class="invalid-feedback">
                                  <div>해당 대역을 이미 사용 중인 서브넷이 존재합니다.</div>
                                </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp2">
            <a  (click)="createProject()"  class="btn big ciblue mg_r4">생성</a>
            <a  (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
    <!-- <div class="kmodal_layer"></div> -->
</div>
<!-- //Modal -->