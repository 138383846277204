import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router} from '@angular/router';
import { DialogSetService } from '../dialog/dialog-set.service';

@Component({
  selector: 'app-console-backup-db',
  templateUrl: './console-backup-db.component.html',
})
export class ConsoleBackupDbComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
  }

}
