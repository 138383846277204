import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-server-resize',
  templateUrl: './dialog-server-resize.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerResizeComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerResizeComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  selected_server;
  flavor_id="";

  flavorLists=[];

  ngOnInit(): void {
    this.selected_server = this.dialogData.selected_server;
    // console.log(this.selected_server)
    this.getFlavorList();
  }

  getFlavorList() {
    
    this.osapi.getFlavorList().subscribe((data: { items: [] }) => {
      this.flavorLists = data.items;
      const before_flavor = this.flavorLists.find(e => e.id == this.selected_server.flavor_id);
      this.flavorLists = this.flavorLists.filter(e => e.disk == before_flavor.disk);
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  
  resizeServer() {
    // console.log(this.selected_server.server_id)
    // console.log(this.flavor_id)
    this.spinner.show();
    this.osapi.resizeServer(
        this.selected_server.server_id,
        this.flavor_id
      )
      .subscribe((data: {}) => {

        this.spinner.hide();
        this.closeDialogMsg("success");
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }
  
}
