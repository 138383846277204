import { Component, OnInit, Inject } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { OsapiService } from '../../osapi.service';
import { FrontApiService} from '../../frontapi.service';
import { Router, NavigationExtras } from '@angular/router';
import { GlobalService } from '../../global.service';
import {DialogSetService} from '../dialog/dialog-set.service';
import {MemberData} from '../console-load-balancer-setting/MemberData';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function valiAllChk(params:any);

@Component({
  selector: 'app-console-load-balancer-list',
  templateUrl: './console-load-balancer-list.component.html',
})
export class ConsoleLoadBalancerListComponent implements OnInit {
  loadbalancerList:any=[];
  selected_loadbalancer:any={};
  flavor_id;

  poolList:any=[];
  listenerList:any=[];
  memberList:any=[];

  floatingIpList:any=[];

  resourceList:any=[];

  isActived: boolean = false;
  isChecked: boolean = false;

  is_floating_ip_list_loaded: boolean =false;

  constructor(
    private osapi: OsapiService, 
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    /**
     * 20200826 cbkim
     * 현재 경로 가져오기 위함, timer 제거 목적
     */
    private router:Router,
    public global: GlobalService,
    private dialogs:DialogSetService,
    @Inject(ConsoleNavComponent)private parent:ConsoleNavComponent
    ) { }

  // 검색 텍스트
  filterText: string;

  ngOnInit(): void {
    this.getLoadbalancerList();
    this.getLoadbalancer();
    this.getFloatingipList();
    this.getServerList();
  } 
  
	// 전체선택
	onAllCheck(status) {
		this.isActived = status.isActived;
	}

	onListCheck(loadbalancer){
		const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
		this.isActived = false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
      if(chkedbox.getAttribute('data-id') == loadbalancer.id) {
        chkedbox.checked = true;
        this.onClickList(loadbalancer);
        this.isActived = true;
      }
    }
	}

  onClickList(lb){
    this.isActived = true;
    this.selected_loadbalancer = lb;
  }
  
  openAssociateIpModal(){
    if(this.floatingIpList.length == 0){
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '연결할 공인 IP가 없습니다.'
        }
      });
      this.dialogs.openCreateIpDialog({});
      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigateByUrl('/console/loadbalancer');
            }
          );
        }
      });

    }else{
      if(this.is_floating_ip_list_loaded){
        this.dialogs.openLoadbalancerAssociateIpDialog({floatingIpList:this.floatingIpList,selected_loadbalancer:this.selected_loadbalancer});
      }else{
        setTimeout(() =>  { this.openAssociateIpModal(); }, 500);
      }
    }
  }
  openDisassociateIpModal(){
    this.dialogs.openLoadbalancerDisassociateIpDialog({selected_loadbalancer:this.selected_loadbalancer});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.getLoadbalancerList();
        this.getFloatingipList();
      }
    });
  }
  openDeleteLBModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"로드밸런서 삭제",
      content:"선택하신 로드밸런서("+this.selected_loadbalancer.name+")를 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteLoadbalancer();
        }
      });
  }

  getStatusKo(status){
    var status_ko = status;
    switch (status) {
      case "ONLINE":
          status_ko = "ONLINE"
        break;
      case "DRAINING":
          status_ko = "New connection failed"
        break;
      case "OFFLINE":
          status_ko = "OFFLINE"
        break;
      case "DEGRADED":
          status_ko = "WARNING"
        break;
      case "ERROR":
          status_ko = "ERROR"
        break;
      case "NO_MONITOR":
          status_ko = "NO_MONITOR"
        break;
    }
    return status_ko;
  }

  getLoadbalancerList(){
    this.spinner.show();
    this.loadbalancerList = [];
    this.osapi.getLoadbalancerList().subscribe(
      (data:any)=>{
        this.loadbalancerList = data.items;

        this.loadbalancerList = this.loadbalancerList.filter(lb => lb.provisioning_status != "PENDING_DELETE");

        // for(let lb of this.loadbalancerList){
        //   lb.listener = [];
        // }
        console.log(this.loadbalancerList);
        this.getLoadbalancer();
        this.spinner.hide();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      }
    )
  }

  async deleteLoadbalancer(){
    /**
     * 20200923 cbkim
     * 공인IP 있을 경우 해제 후 삭제
     * is_floating_ip || floating_ip
     */

    if(this.selected_loadbalancer.floating_ip){
      await this.osapi.disassociateFloatingIp(this.selected_loadbalancer.floating_ip.id).toPromise();
    }

    this.osapi.deleteLoadbalancer(this.selected_loadbalancer.id).toPromise();

    // this.osapi.deleteLoadbalancer(this.selected_loadbalancer.id).subscribe(
    //   (data:any)=>{
    //     this.loadbalancerList = [];
    //     this.getLoadbalancerList();
    //   }
    // )
  }

  /**
   * 20200825 cbkim
   * floating ip mapping
   */
  pairIps=[];

  getFloatingipList(){
    /**
     * 20200811 cbkim
     * Resource 확인 후 포트포워딩 IP 제거
     */

    this.osapi.getResourceAll().subscribe(
      (resources:any)=>{
        this.resourceList = resources;
        this.osapi.getFloatingipList().subscribe(
          (data:any)=>{
            this.is_floating_ip_list_loaded=true;
            var pfs = [];
            for(let resource of resources){
              if(resource.resourceType === "portforwarding ip"){
                pfs.push(resource.value);
              }
            }
            
            this.floatingIpList = data.items;
            var fiplist = []

            for(let item of this.floatingIpList){
              if(!item.is_port_forwarding && item.fixed_ip == null){
                fiplist.push(item);
              }
              if(item.fixed_ip){
                this.pairIps.push({private:item.fixed_ip,public:item.ip})
              }
            }
            this.floatingIpList = fiplist;
          }
        )
        
      }
    )
  }

  currentTimer;

  async getLoadbalancer(){
    if(this.router.url != "/console/loadbalancer"){
      clearTimeout(this.currentTimer);
      return;
    }

    if(this.loadbalancerList != undefined){
      for(let lb of this.loadbalancerList){
        let data:any = await this.osapi.getLoadbalancerFull(lb.id).toPromise();
        if(data.floating_ip.hasOwnProperty('ip')){
          lb.floating_ip = data.floating_ip;
          lb.is_floating_ip = true;
        }
        lb.provisioning_status = data.provisioning_status;
        lb.provisioning_status_ko = this.getStatusKo(lb.provisioning_status);
        lb.operating_status = data.operating_status;
        lb.operating_status_ko = this.getStatusKo(lb.operating_status);

        lb.listeners = data.listeners;
        var newListener=[];

        let memberCnt = 0;
        for(let listener of lb.listeners){
          let listener_data:any = await this.osapi.getLoadbalancerListener(listener.id).toPromise();
          listener_data.operating_status_ko = this.getStatusKo(listener_data.operating_status);

          /** pool 이 존재하면 */
            if(listener_data.default_pool_id != null){
              let pool_data:any = await this.osapi.getPools(listener_data.default_pool_id).toPromise();
              pool_data.operating_status_ko = this.getStatusKo(pool_data.operating_status);
    
              let members:any = await this.osapi.getPoolMembers(listener_data.default_pool_id).toPromise();
              members = members.items;
  
              for(let member of members){
                member.operating_status_ko = this.getStatusKo(member.operating_status);
                memberCnt++;
              }
    
              pool_data.members = members;
              listener_data.pool = pool_data;
            }
            newListener.push(listener_data);
        }
        if(memberCnt == 0){
          lb.operating_status = "EMPTY"
          lb.operating_status_ko = "EMPTY"
        }
        lb.listener = newListener;
        this.parent.getThemeFont();
      }
    }

    this.currentTimer = setTimeout(()=>{this.getLoadbalancer()},30000);
  }

  updateLoadbalancer(){

  }

  updateListener(){
    this.dialogs.openLoadbalancerUpdateListenerDialog({selected_loadbalancer:this.selected_loadbalancer});
  }

  updateMember(listener){
    this.dialogs.openLoadbalancerUpdateMemberDialog({selected_loadbalancer:this.selected_loadbalancer,serverList:this.serverList,listener:listener});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "success"){
        this.refersh();
      }
    });
  }

  updatePool(){
    this.dialogs.openLoadbalancerUpdatePoolDialog({selected_loadbalancer:this.selected_loadbalancer});
  }

  updateHealthMonitor(){
    this.dialogs.openLoadbalancerUpdateMonitorDialog({selected_loadbalancer:this.selected_loadbalancer});
  }

  goSettings(lb){
    //this.router.navigate(['/console/loadbalancer/create',{loadbalancer_id:lb.id}]);
  }


  serverList:any=[];
  getServerList(){
    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList();
      }, 200)
    } else {
      this.serverList = this.parent.serverList;

      this.spinner.hide();
      
     
    }
  }

  refersh(){
    this.getLoadbalancer();
  }

  createListener(){
    this.router.navigate(['/console/loadbalancer/create',{loadbalancer_id:this.selected_loadbalancer.id}]);
  }

  openDeleteListenerDialog(listener){
    this.dialogs.openDefaultDeleteDialog({
      title:"리스너 삭제",
      content:"선택하신 리스너("+listener.name+")를 정말로 삭제하시겠습니까?"});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteListener(listener);
        }
      });
  }
  deleteListener(listener) {
    this.spinner.show();
    this.osapi.deleteListener(listener.id).subscribe(
      (data) => {
        this.spinner.hide();
        this.getLoadbalancerList();
      }
    )
  }

  /**
   * END
   */
}