import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-nas-list',
  templateUrl: './mobile-console-nas-list.component.html',
  styleUrls: ['./mobile-console-nas-list.component.css']
})
export class MobileConsoleNasListComponent implements OnInit {
  nasLists: any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.getNasList();
  }

  getNasList() {
    try {
      this.spinner.show();
      this.osapi.getNasList().subscribe((data) => {
        this.nasLists = data;
        
        for (let nas of this.nasLists) {
          if(!nas.export_locations){
            nas.export_locations = "";
          }
          nas.is_attachserver_by_nas_load = false;
        }
        this._blank = ((this.nasLists != 0) ? true : false);
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
    }
  }

  clipboardCopy(e) {
    const text = document.getElementById('copyText');
    let range = document.createRange(); // 텍스트 기본정보를 Range 객체에 저장
    range.selectNode(text.childNodes[0]);

    let sel = window.getSelection();
    sel.removeAllRanges();  // 기존 선택정보 삭제
    sel.addRange(range);    // 텍스트 정보 선택
    document.execCommand('copy'); // 복사
    sel.removeRange(range);       // 선택 정보 삭제

    this.frontapi.changeMessage({
      type: 'toast',
      body: {
        type: 'success',
        title: '클립보드',
        message: '클립보드에 접속경로를 복사하였습니다.'
      }
    });
  }
}
