<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>DB목록</h2>
    <div class="lineMap">Database / <span>PostgreSQL</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <form id="frm">
      <!-- section -->
      <section class="sec col12">
        <div class="sec_content">
          <!-- lyBox -->
          <div class="lyBox">
            <div class="contArea" style="display: block;">
              <div class="boardBox">
                <table class="tbl_row_tp1">
                  <colgroup>
                    <col style="width: 20%;" />
                    <col style="width: 80%;" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">Cloud DB 생성</th>
                      <td>
                        <button
                          class="btn mid orange mg_r4"
                          onclick="fn_modalOpen('db_create');"
                        >
                          Cloud DB 생성
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- //lyBox -->
          <!-- lyBox -->
          <div class="lyBox">
            <div class="titArea">
              <h3>DB 목록</h3>
              <div class="btnArea">
                <div class="input-group fl">
                  <input
                    type="search"
                    class="form-control"
                    name=""
                    id="search"
                    value=""
                    placeholder="검색어를 입력해주세요"
                  />
                  <div class="input-group-btn pd_r10">
                    <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="contArea" style="display: block;">
              <div class="boardBox">
                <table class="tbl_col_tp1 dropmenu">
                  <colgroup>
                    <col style="width: 60px;" />
                    <col style="width: 200px;" />
                    <col style="width: 200px;" />
                    <col style="width: 120px;" />
                    <col style="width: 120px;" />
                    <col style="width: 120px;" />
                  </colgroup>
                  <thead>
                    <th scope="col">
                      <input type="checkbox" name="" id="checkbox" />
                    </th>
                    <th scope="col">DB이름</th>
                    <th scope="col">DB버전</th>
                    <th scope="col">USERNAME</th>
                    <th scope="col">생성일</th>
                    <th scope="col">상태</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          class="mycheckbox"
                          type="checkbox"
                          name=""
                          id=""
                        />
                      </td>
                      <td>클라우드 DB</td>
                      <td>PostgreSQL 12.3</td>
                      <td>postgres</td>
                      <td>2020-07-08</td>
                      <td><label class="dot green"></label>Active</td>
                    </tr>
                  </tbody>
                  <!-- //foreach -->
                </table>
              </div>
              <div class="btn_wrap tp1"></div>
            </div>
          </div>
          <!-- //lyBox -->
        </div>
      </section>
      <!-- //section -->
    </form>
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
  <div id="myDropdown" class="dropdown-content">
    <a onclick="fn_modalOpen('password_change')">암호변경</a>
    <a onclick="fn_modalOpen('ip_change')">접근허용 IP변경</a>
  </div>
</div>

<!-- Modal : Cloud DB 생성 모달 -->
<div id="db_create" class="modal">
  <div class="modal_header">
    <h2>
      Cluod DB 생성
      <p class="sub-txt">|Cloud DB 생성 텍스트 설명입니다.</p>
    </h2>
  </div>

  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">엔진 유형</th>
            <td>
              <select
                required
                (change)="onChange($event.target.value)"
                [(ngModel)]="newDB.type"
              >
                <option value="">-선택-</option>
                <option
                  *ngFor="let engine of engines"
                  value="{{ engine.type }}"
                  >{{ engine.type }}</option
                >
              </select>
            </td>
          </tr>
          <tr>
            <th scope="row">엔진 버전</th>
            <td>
              <select required [(ngModel)]="newDB.version">
                <option value="">-선택-</option>
                <option
                  *ngFor="let version of engineVersions"
                  value="{{ version }}"
                  >{{ version }}</option
                >
              </select>
            </td>
          </tr>
          <tr>
            <th scope="row">DB 이름</th>
            <td>
              <input
                type="text"
                [(ngModel)]="newDB.dbName"
                placeholder="DB 이름을 입력해주세요"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">DB USERNAME</th>
            <td>
              <input
                type="text"
                [(ngModel)]="newDB.dbUsername"
                placeholder="DB USERNAME을 입력해주세요"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">접근허용 IP</th>
            <td>
              <input
                type="text"
                [(ngModel)]="newDB.accessIP"
                placeholder="접근허용 IP를 입력해주세요"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">USER 암호</th>
            <td>
              <input
                type="password"
                [(ngModel)]="newDB.password1"
                placeholder="USER 암호을 입력해주세요"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">USER 암호 확인</th>
            <td>
              <input
                type="password"
                [(ngModel)]="newDB.password2"
                placeholder="USER 암호를 한번 더 입력해주세요"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a
        href="#close-modal"
        (click)="createCloudDB()"
        rel="modal:close"
        class="btn big orange mg_r4"
        >확인</a
      >
      <a href="#close-modal" rel="modal:close" class="btn big orange">닫기</a>
    </div>
  </div>
</div>

<!-- Modal : 암호변경 팝업 -->
<div id="password_change" class="modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      암호변경
      <p class="sub-txt">| 암호변경 팝업</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">현재 암호</th>
            <td>
              <input type="password" placeholder="현재 암호을 입력해주세요" />
            </td>
          </tr>
          <tr>
            <th scope="row">변경할 암호</th>
            <td>
              <input type="password" placeholder="변경할 암호을 입력해주세요" />
            </td>
          </tr>
          <tr>
            <th scope="row">변경할 암호 확인</th>
            <td>
              <input
                type="password"
                placeholder="변경할 암호을 한번 더 입력해주세요"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4"
        >확인</a
      >
      <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->

<!-- Modal : 접근허용 IP변경 팝업 -->
<div id="ip_change" class="modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      접근허용 IP변경
      <p class="sub-txt">| 접근허용 IP변경</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 20%;" />
          <col style="width: 80%;" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">접근허용 IP</th>
            <td>
              <input
                type="text"
                placeholder="IP 형태 or IP/cidr(IP 대역) 형태의 유효성 검사"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4"
        >확인</a
      >
      <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->
