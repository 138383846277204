<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>API 등록</h2>
    <div class="lineMap">Service / <span>API Gateway</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="createBox">
          <div #step1 class="titArea">
            <h3>Step 1. 기본 정보 입력</h3>
          </div>
          <div class="titArea tpA">
            <small>등록할 API의 정보를 입력해주세요.</small>
          </div>
          <div class="contArea" style="display: block;" id="contMain">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <tr class="">
                    <th scope="row">API 이름</th>
                    <td>
                      <input type="text" name="name" id="name" [(ngModel)]="create_api_object.name"
                        placeholder="영문자와 숫자, '-', '.'만 입력 가능합니다."
                        title="영문자와 숫자, '-', '.'만 입력 가능합니다." required maxlength="255"/>
                    </td>
                  </tr>
                  <tr class="">
                    <th scope="row">연결 프로토콜</th>
                    <td>
                      <select name="protocol" id="protocol"  required class="size435" [(ngModel)]="create_api_object.protocol">
                        <option value="http">HTTP</option>
                        <option value="https">HTTPS</option>
                        <!-- <option value="tcp">TCP</option> -->
                        <!-- <option value="udp">UDP</option> -->
                        <!-- <option value="grpc">GRPC</option> -->
                        <!-- <option value="grpcs">GRPCS</option> -->
                        <!-- <option value="tls">TLS</option> -->
                      </select>
                    </td>
                  </tr>
                  <tr class="">
                    <th scope="row">연결 경로</th>
                    <td>
                      <input type="text" name="path" id="path" [(ngModel)]="create_api_object.path"
                        placeholder="ex)/api/path"
                        title="ex)/api/path" required maxlength="255"/>
                    </td>
                  </tr>
                  <tr class="">
                    <th scope="row">호스트 로드밸런싱 사용</th>
                    <td>
                      <label for="api-lb-radio1" (change)="using_lb=$event.target.checked" class="mg_r8"> <input type="radio" name="api-lb" id="api-lb-radio1" />사용</label>
                      <label for="api-lb-radio2" (change)="using_lb=!$event.target.checked"> <input type="radio" name="api-lb" id="api-lb-radio2" checked/>사용 안함</label>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>

            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
          <!-- //lyBox -->

          <!-- lyBox -->
          <div #step2 class="titArea">
            <h3>Step 2. 호스트 추가</h3>
          </div>
          <div class="contArea" style="display:block"> 

              <div class="boardCreateBox">
                  <!-- 로드밸런싱 미사용 -->
                  <table class="tbl_row_tp1" *ngIf="!using_lb">
                    <colgroup>
                      <col style="width:220px;" />
                      <col style="width:auto" />
                    </colgroup>
                    <tbody>
                      <tr class="">
                        <th scope="row">호스트 주소</th>
                        <td>
                          <input type="text" name="host" id="host"  [(ngModel)]="lb_target.host"
                            placeholder="ex)192.168.0.1, www.example.com"
                            title="ex)192.168.0.1, www.example.com" required maxlength="255"/>
                        </td>
                      </tr>
                      <tr class="">
                        <th scope="row">포트 번호</th>
                        <td>
                          <input type="number" name="port" id="port"  [(ngModel)]="lb_target.port"
                            placeholder="80" max="65535" min="0"
                            title="80" required/>
                        </td>
                      </tr>
    
    
                    </tbody>
                  </table>
                  <!-- 로드밸런싱 사용 -->
                  <table class="tbl_col_tp1" *ngIf="using_lb">
                      <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                      </colgroup>
                      <thead>
                          <th>호스트 주소</th>
                          <th>포트번호</th>
                          <th>연결비중</th>
                          <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                              <input type="text" name="host" [(ngModel)]="lb_target.host"
                              placeholder="ex)192.168.0.1, www.example.com"
                              title="" required maxlength="255"/>
                          </td>
                          <td>
                              <input type="number" name="port" [(ngModel)]="lb_target.port"
                              placeholder="80"
                              title="" required  max="65535" min="0"/>
                          </td>
                          <td>
                              <input type="number" name="weight" [(ngModel)]="lb_target.weight"
                              placeholder="10"
                              title="" required />
                          </td>
                          <td><button class="btn sml dGray" (click)="addTarget()">추가</button></td>
                        </tr>
                        <tr *ngFor="let target of use_lb_targets">
                          <td>
                            {{target.host}}
                          </td>
                          <td>
                            {{target.port}}
                          </td>
                          <td>
                            {{target.weight}}
                          </td>
                          <td><button class="btn sml dGray" (click)="removeTarget(target)">삭제</button></td>
                        </tr>                             
                      </tbody>
                  </table>
                  <table class="tbl_row_tp1">
                      <colgroup>
                        <col style="width:220px;" />
                        <col style="width:auto" />
                      </colgroup>
                      <tbody>
                        <tr class=""  *ngIf="using_lb">
                          <th scope="row">로드밸런스 알고리즘</th>
                          <td>
                            <select name="server_val" id="sOption"  required class="size435">
                              <option value="round-robin">round-robin</option>
                              <option value="least-connections">least-connections</option>
                              <option value="consistent-hashing">consistent-hashing</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
              </div>
              

              <div class="btn_wrap tp1"></div>
          </div>


          <!-- lyBox -->
          <div #step3 class="titArea">
            <h3>Step 3. 상세설정</h3>
          </div>
          
          <div class="titArea tpA">
            <small>필요한 경우 상세설정을 통하여 API 연결을 제어할 수 있습니다.</small>
          </div>
          <div class="contArea" style="display: block;" id="contDetail">
            <div class="boardCreateBox">
              <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                    <tr class="">
                      <th scope="row">상세정보 편집</th>
                      <td>
                          <label for="api-detail-radio1" (change)="using_detail=$event.target.checked" class="mg_r8"> <input type="radio" name="api-detail" id="api-detail-radio1" />사용</label>
                          <label for="api-detail-radio2" (change)="using_detail=!$event.target.checked"> <input type="radio" name="api-detail" id="api-detail-radio2" checked />사용 안함</label>
                      </td>
                    </tr>
                    <ng-template [ngIf]="using_detail">
                        <tr class="">
                          <th scope="row">retries</th>
                          <td>
                            <input type="number" 
                              placeholder="연결에 실패한 경우 재시도를 수행할 회수를 지정합니다." [(ngModel)]="create_api_object.retries"
                              title="연결에 실패한 경우 재시도를 수행할 회수를 지정합니다."/>
                          </td>
                        </tr>
                        <tr class="">
                          <th scope="row">connect_timeout</th>
                          <td>
                            <input type="number"
                              placeholder="서버 연결에 대한 제한 시간입니다." [(ngModel)]="create_api_object.connect_timeout"
                              title="서버 연결에 대한 제한 시간입니다."/>
                          </td>
                        </tr>
                        <tr class="">
                          <th scope="row">write_timeout</th>
                          <td>
                            <input type="number"
                              placeholder="서버로 요청을 전송할 때 두 개의 연속 쓰기 작업 사이의 제한시간입니다." [(ngModel)]="create_api_object.write_timeout"
                              title="서버로 요청을 전송할 때 두 개의 연속 쓰기 작업 사이의 제한시간입니다."/>
                          </td>
                        </tr>
                        <tr class="">
                          <th scope="row">read_timeout</th>
                          <td>
                            <input type="number"
                              placeholder="서버로 요청을 전송할 때 두 개의 연속 읽기 작업 사이의 제한시간입니다." [(ngModel)]="create_api_object.read_timeout"
                              title="서버로 요청을 전송할 때 두 개의 연속 읽기 작업 사이의 제한시간입니다."/> 
                          </td>
                        </tr>

                    </ng-template>
                </tbody>
              </table>
            </div>

            <!-- <div class="btn_wrap tp1"></div> -->
          </div>
          <!-- //lyBox -->

          <!-- lyBox -->
          <!-- <div #step4 class="titArea">
            <h3>Step 4. 헬스체크 정보</h3>
          </div> -->
          <div class="contArea" style="display: block;" id="contSecure">
            <div class="boardCreateBox">
              <!-- <table class="tbl_row_tp1">
                <colgroup>
                  <col style="width:220px;" />
                  <col style="width:auto" />
                </colgroup>
                <tbody>
                  <tr class=""> 
                    <th scope="row">헬스체크 사용</th>
                    <td>
                        <label for="api-health-check-radio1" (change)="using_health_check=$event.target.checked" class="mg_r8"><input type="radio" name="api-health-check" id="api-health-check-radio1" /> 사용</label>
                        <label for="api-health-check-radio2" (change)="using_health_check=!$event.target.checked"> <input type="radio" name="api-health-check" id="api-health-check-radio2" checked />사용 안함</label>
                    </td>
                  </tr>
                  <ng-template [ngIf]="using_health_check">
                      <tr>
                        <th scope="row">보안그룹 생성</th>
                        <td>
                          <a class="btn mid ciblue"><i
                              class="ico ico_register_white"></i>신규생성</a>
                        </td>
                      </tr>
                      <tr class="">
                        <th scope="row">보안그룹 선택</th>
                        <td>
                          <div>
                            <select name="group_value" id="sGroup" class="size435">
                              <option value="">-선택-</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                  </ng-template>
                </tbody>
              </table> -->
            </div>
            
            <div class="btn_wrap tp1">
              <button type="submit" class="btn big blue" (click)="createAPI()"><i class="ico ico_check_white"></i>API 등록</button>
            </div>
          </div>
          <!-- //lyBox -->
          <!-- lyBox -->
          
        </div>
        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
    <!-- </form> -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->