 <!-- Modal : 인증키 기본정보 수정 모달 --> 
  <div id="key_edit_memo" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>인증키
        <p class="sub-txt"> | 인증키의 상세정보입니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>
          <tbody *ngIf="selected_keypair">
            <tr>
              <th scope="row">인증키 이름</th>
              <td>
                <input type="text" name="" id="keyName" [(ngModel)]="edit_key_data.name"  readonly />
              </td>
            </tr>
            <tr>
              <th scope="row">설명</th>
              <td>
                <textarea name="" id="editKeyMemo" rows="5" placeholder="내용을 입력해주세요" maxlength="255" 
                  [(ngModel)]="edit_key_data.description"></textarea>
              </td>
            </tr>
            <tr>
              <th scope="row">공개 키</th>
  
              <td>
                <textarea name="" id="edit_public_key" rows="10" [(ngModel)]="selected_keypair.public_key" readonly
                  disabled></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp1">
        <a class="btn big ciblue mg_r4" (click)="updateKeypair()">수정</a>
        <a  class="btn big bor_gray" (click)="closeDialog()" >닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->