import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dialog-keypair-update',
  templateUrl: './dialog-keypair-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogKeypairUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogKeypairUpdateComponent>,
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  edit_key_data:any ={};
  selected_keypair:any ={};

  ngOnInit(): void {

    this.selected_keypair = this.dialogData.selected_keypair;
    
    this.edit_key_data.name=this.selected_keypair.name
    this.edit_key_data.description=this.selected_keypair.description
  }

  updateKeypair(){
    if(this.edit_key_data.description.length>255){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:"설명은 255자 이내로 작성해야합니다."
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi.updateKeyPair(this.edit_key_data.name,this.edit_key_data.description).subscribe((data: {}) => {
      this.spinner.hide();
      this.closeDialogMsg("success");
        if(this.router.url=='/console/keypair'){
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/keypair']);
            }
          );
        }else{
          this.router.navigate(['/console/keypair']);
        }
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }
}
