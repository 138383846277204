
<!-- Modal : 네트워크 삭제 모달 -->
<div id="modal_projectlist_delete" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        프로젝트 삭제
        <p class="sub-txt" style="color:red !important;"> | *경고* 프로젝트를 삭제하실 경우 해당 프로젝트의 모든 자원이 소멸되고 복구 할 수 없습니다.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr *ngIf="resourceCountMap.length != 0">
              <td colspan=2>*사용 중인 자원 현황</td>
            </tr>
            <tr *ngFor="let resource of resourceCountMap">
              <ng-template [ngIf]="resource.cnt != 0">
                  <th>{{resource.locale}}</th>
                  <td>
                    {{resource.cnt}} 개
                  </td>
              </ng-template>
            </tr>
            <tr>
              <th>삭제 확인</th>
              <td>
                <input type="text" placeholder="삭제하시려면 '프로젝트삭제'를 입력해주세요." [(ngModel)]="deleteCheckMessage">
                <div *ngIf="!deleteCheck"
                  class="invalid-feedback">
                  <div>삭제하시려면 '프로젝트삭제'를 정확하게 입력해주세요. </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="deleteBLSProject()" class="btn big ciblue mg_r4">삭제</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        <!-- <a (click)="closeDialog()" class="btn big red"><i class="ico ico_cancel_white"></i>취소</a> -->
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->