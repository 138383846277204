<!-- Validation Check
<input type="text" class="error-invalid"/>
<div>
    <div class="error-invalid-feedback">
        필수 값입니다.
    </div>
</div>
 -->

<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
      <h2>서비스 신청</h2>
      <div class="lineMap">Service / <span>Service</span></div>
    </div>
    <!-- //content_header -->
  
    <!-- content_body -->
    <div class="content_body">
      <form name="serverFrm" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm">
        <!-- section -->
        <section class="sec col12">
          <div class="sec_content">
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 1. 서비스 설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                </div>
              </div>
              <div class="titArea">
                  <small>서비스의 기본적인 설정을 입력해주세요.</small>
              </div>
              <div class="contArea" style="display: block;">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr class="required">
                        <th scope="row">선택</th>
                        <td>
                          <select 
                            name="field_name0"
                            #field_name0="ngModel"
                            [ngModel] = "file_name.field_name0"
                            [ngClass]="{ 'error-invalid': field_name0.invalid && (f.submitted || field_name0.dirty) }"
                            required
                          >
                            <option value="" selected>-선택-</option>
                            <option *ngFor="let option of optionList" value="option">{{option}}</option>
                          </select>
                          <div *ngIf="field_name0.invalid && (field_name0.dirty || field_name0.touched)">
                            <div class="error-invalid-feedback" *ngIf="field_name0.errors?.required">
                                **은 필수 값입니다.
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">서비스명</th>
                        <td>                        
                          <input
                            type="text"
                            name="field_name1"
                            #field_name1="ngModel"
                            [ngModel] = "file_name.field_name1"
                            [ngClass]="{ 'error-invalid': field_name1.invalid && (f.submitted || field_name1.dirty || field_name1.touched) }"
                            placeholder="**자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다."
                            pattern="^[A-Za-z0-9-.+]*$"
                            required
                          />
                          <div *ngIf="field_name1.invalid && (field_name1.dirty || field_name1.touched)">
                              <div class="error-invalid-feedback" *ngIf="field_name1.errors?.required">
                                  필수 값입니다. **자 이내로 작성해주세요.
                              </div>
                              <div class="error-invalid-feedback" *ngIf="field_name1.errors.pattern">
                                  영문자와 숫자, '-', '.'만 입력 가능합니다.
                              </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">설명</th>
                        <td>
                          <textarea name="memo_val" rows="5" placeholder="내용을 입력해주세요"></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1"></div>
              </div>
            </div>
            <!-- //lyBox -->
  
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 2. 서비스 설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"
                    ><i class="ico ico_moreTp1"></i
                  ></a>
                </div>
              </div>
              <div class="titArea">
                  <small>서비스 신청 설명입니다. </small>
              </div>
              <div class="contArea" style="display: block;" id="contDetail">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr class="required">
                        <th scope="row">SELECT</th>
                        <td>
                          <select name="file_name.field_name2">
                            <option value="">-선택-</option>                            
                          </select>
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">RADIO</th>
                        <td>
                          <input type="radio" name="field_name3" id="radio1" checked/><label for="radio1" class="mg_r8"> 라디오1</label>
                          <input type="radio" name="field_name3" id="radio2"/><label for="radio2"> 라디오2</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1"></div>
              </div>
            </div>
            <!-- //lyBox -->
  
            <!-- lyBox -->
            <div class="lyBox">
              <div class="titArea">
                <h3>Step 3. 서비스 설정</h3>
                <div class="btnArea">
                  <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
                </div>
              </div>
              <div class="titArea">
                  <small>서비스 신청 설명입니다.</small>
              </div>
              <div class="contArea" style="display: block;" id="contAuthkey">
                <div class="boardBox">
                  <table class="tbl_row_tp1">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 80%;" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">BUTTON</th>
                        <td>
                          <a
                            class="btn mid orange" onclick="fn_modalOpen('modal_comfirm');">확인</a>
                        </td>
                      </tr>
                      <tr class="required">
                        <th scope="row">선택</th>
                        <td>
                          <select name="file_name.field_name4">
                            <option value="">-선택-</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="btn_wrap tp1">
                  <button type="submit" class="btn big orange" [disabled]="f.form.pristine || f.form.invalid">서비스 신청</button>
                </div>
              </div>
            </div>
            <!-- //lyBox -->
          </div>
        </section>
      <!-- //section -->
      </form>
      <!-- </form> -->
    </div>
    <!-- //content_body -->
  </div>
  <!-- //Contents -->
  
  
  <!-- Modal : 최종 확인 -->
  <div id="modal_comfirm" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>최종 확인</h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
          <table class="tbl_row_tp1">
            <colgroup>
              <col style="width: 30%;" />
              <col style="width: 70%;" />
            </colgroup>
            <tbody>
              <tr>
                <th>선택</th>
                <td></td>
              </tr>
              <tr>
                <th>서비스명</th>
                <td></td>
              </tr>              
              <tr>
                <th>SELECT</th>
                <td></td>
              </tr>
              <tr>
                <th>RADIO</th>
                <td></td>
              </tr>              
            </tbody>
          </table>
        </div>
      <div class="btnArea">
        <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">확인</a>
        <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->
  