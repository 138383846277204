
<!-- Modal : API GW IP 차단 -->
<div id="gw_iprestriction" class="modal2 lg_modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>포트포워딩 관리
      <p class="sub-txt">| 포트포워딩 규칙을 추가하거나 삭제할 수 있습니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_col_tp1">
        <colgroup>
          <col style="width: 25%;"/>
          <col style="width: 55%;"/>
          <col style="width: 20%;"/>
        </colgroup>
        <thead>
          <th scope="col">구분</th>
          <th scope="col">CIDR</th>
          <th scope="col"></th>
        </thead>
        <tbody>

          <!--신규 정보-->
          <tr>
            <td>
              <select name="" id="type" [(ngModel)]="new_rule.type">
                <option value="deny">DENY</option>
                <option value="allow">ALLOW</option>
              </select>
            </td>
            <td>
              <input placeholder="0.0.0.0/0" autocomplete="off" type="text" name="cidr" [(ngModel)]="new_rule.cidr" />
            </td>
            
            <td>
              <a class="btn sml ciblue mg_r4" (click)="addRule()">
                규칙 추가
              </a>
            </td>
          </tr>
          <!--신규 정보 End-->
        </tbody>
      </table>
      <br>
      <div class="modal_header">
         <p style="padding-left: 10px;" class="sub-txt">| 등록된 IP 차단 정보입니다. 'ALLOW' 규칙을 설정하는 경우 해당 CIDR 이외의 모든 IP가 차단됩니다.</p> 
      </div>
      <table class="tbl_col_tp1">
        <colgroup>
          <col style="width: 25%;"/>
          <col style="width: 55%;"/>
          <col style="width: 20%;"/>
        </colgroup>
        <thead>
          <th scope="col">구분</th>
          <th scope="col">CIDR</th>
          <th scope="col"></th>
        </thead>
        <tbody>
          <!--기존 정보-->

          <ng-template [ngIf]="deny_list.length==0&&allow_list.length==0"><tr><td colspan="3">등록된 정보가 없습니다.</td></tr></ng-template>
          
          <tr *ngFor="let deny of deny_list">
            <td>
              <input type="text" value="DENY" readonly />
            </td>
            <td>
              <input type="text" value="{{ deny }}" readonly />
            </td>
            <td>
              <a class="btn sml dGray" (click)="removeRule('deny',deny)">삭제</a>
            </td>
          </tr>
          <tr *ngFor="let allow of allow_list">
            <td>
              <input type="text" value="ALLOW" readonly />
            </td>
            <td>
              <input type="text" value="{{ allow }}" readonly />
            </td>
            <td>
              <a class="btn sml dGray" (click)="removeRule('allow',allow)">삭제</a>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a (click)="updateIprestriction()" class="btn big ciblue mg_r4" >저장</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->