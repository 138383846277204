
<div id="manage_server" class="modal2 lg_modal">
        <!-- modal_header -->
        <div class="modal_header">
          <h2>서버 연결 관리</h2>
        </div>
        <!-- //modal_header -->
      
        <!-- modal_content -->
        <div class="modal_content">
          <div class="boardBox">
            <table class="tbl_col_tp1">
              <colgroup>
                <col style="width: 30%;" />
                <col style="width: 50%;" />
                <col style="width: 10%px;" />
              </colgroup>
              <thead>
                <th scope="col">서버 이름</th>
                <th scope="col">IP 주소</th>
                <th>추가 / 삭제</th>
              </thead>
              <tbody>
                <!-- 기존 서버 리스트 -->
                <ng-container
                  *ngIf="attachedServerList && attachedServerList.length > 0"
                >
                  <tr *ngFor="let server of attachedServerList">
                    <td>
                        {{ server.server_name }}
                      <!-- <input type="text" value="{{ server.server_name }}" readonly /> -->
                    </td>
                    <td>
                        {{ joinInterfaces(server.network_interfaces) }}
                      <!-- <input
                        type="text"
                        value="{{ joinInterfaces(server.network_interfaces) }}"
                        readonly
                      /> -->
                    </td>
                    <td>
                      <a class="btn sml dGray"
                        (click)="detachNasInterface(server.server_id)"
                        >삭제</a
                      >
                    </td>
                  </tr>
                </ng-container>
                <!-- 신규 서버 추가-->
                <tr>
                  <td>
                    <select style="text-align-last: center;" [(ngModel)]="serverIdForAttachingInterface" (change)="onChangeAttachingInterface()">
                      <option value="">-선택-</option>
                      <option
                        *ngFor="let server of detachedServerList"
                        value="{{ server.server_id }}"
                        >{{ server.server_name }}</option
                      >
                    </select>
                  </td>
                  <td>
                    <ng-template [ngIf]="attaching_interface_modal_server_data">
                      <input type="text" readonly value="{{ joinInterfaces(attaching_interface_modal_server_data.network_interfaces) }}"/>
                    </ng-template>
                    <ng-template [ngIf]="attaching_interface_modal_server_data == null">
                      <input type="text" readonly/>
                    </ng-template>
                  </td>
                  <td>
                    <a class="btn sml ciblue" *ngIf="serverIdForAttachingInterface" (click)="attachNasInterface()">추가</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btnArea tp1">
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
            <!-- <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a> -->
          </div>
        </div>
        <!-- //modal_content -->
      </div>