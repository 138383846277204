import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../webapi.service';
import {ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-material-view',
  templateUrl: './material-view.component.html',
})
export class MaterialViewComponent implements OnInit {
    
  materialId:String = "";
  materialView: any = [];
  
  constructor(
    private webapi: WebApiService, 
    private activatedRoute: ActivatedRoute,
    private spiner: NgxSpinnerService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.materialId = params['materialId'];
    });
  }

  ngOnInit(): void {
    this.getMaterialView();
  }

  getMaterialView(){
    this.spiner.show();
    this.webapi.getMaterialView(this.materialId).subscribe(
      (response) => {
        const data = response.body
        this.materialView = data['result'][0];
        this.materialView['createdDate'] = this.materialView['createdDate'].split("T")[0]

      }, (err) => {

      }
    );
    this.spiner.hide();
  }

  downloadFile() {
    location.href =
      `/webapi/articles/${ this.materialView.articleId}/attachment`;
  }
}
