import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-storage-update',
  templateUrl: './dialog-storage-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogStorageUpdateComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogStorageUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_volume;
  //스토리지 생성 팝업
  updateStorageData: {
    name:string,
    description:string
  } ={
    name:"",
    description:""
  };

  ngOnInit(): void {

    this.selected_volume = this.dialogData.selected_volume;

    this.updateStorageData.description = this.selected_volume.description;
    this.updateStorageData.name = this.selected_volume.name;
  }
  
  updateVolume(){
    if(this.updateStorageData.name==""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi.updateVolume(this.selected_volume.id,this.updateStorageData.name,this.updateStorageData.description).subscribe(
      (data:any) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
        if(this.router.url=='/console/storage'){
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/storage']);
            }
          );
        }else{
          this.router.navigate(['/console/storage']);
        }
      // this.volumeList[this.volumeList.indexOf(this.selected_volume)].name=data.name;
      // this.volumeList[this.volumeList.indexOf(this.selected_volume)].description=data.description;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
}
