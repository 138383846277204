<!-- Modal : Bucket 생성 모달 -->
<div id="object_rename" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      오브젝트 이름 변경
      <p class="sub-txt">
        새로운 이름을 입력 후 '수정' 버튼을 클릭하세요.
      </p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>오브젝트 명</th>
            <td>
              <input type="text" [(ngModel)]="newObject.name" placeholder="이름을 입력해주세요" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <a (click)="onClickUpdateRename()" class="btn big ciblue mg_r4">수정</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->