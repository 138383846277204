<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>보안그룹</h2>
        <div class="lineMap">
            Network / <span>Security Group</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm" class="frmBox1">
            <!-- section -->
            <section class="sec col12">
                <div class="sec_content"> 
                    <!-- lyBox -->
                    <div class="lyBox">
                        <div class="title_wrap">
                            <div class="contArea">
                                <div class="tit">
                                    <app-console-projects></app-console-projects>
                                </div>
                                <div class="cont">
                                    <app-console-network-status></app-console-network-status>
                                </div>
                                <div class="contSub">
                                    <button class="btn mid blue mg_r4" (click)="openNewSecurityGroupModal()">
                                        <i class="ico ico_register_white"></i>보안그룹 생성
                                    </button>   
                                    <!-- <button class="btn mid red mg_r4" [disabled]="!isActived" onclick="fn_modalOpen('security_delete')"><i class="ico ico_cancel_white"></i>보안그룹 삭제</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                    <!-- lyBox -->
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>보안그룹 목록</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                    <input type="search" class="form-control" name="" id="search" [(ngModel)]="filterText" [ngModelOptions]="{standalone: true}" value="" placeholder="검색어를 입력해주세요">
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid dBlue"><i class="ico ico_search_white"></i>검색</button>
                                    </div>
                                    <!-- 접기 버튼 -->
                                    <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display:block">
                            <div class="boardBox">
                                <table class="tbl_col_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;">
                                        <col style="width: 30%;">
                                        <col style="width: 45%;">
                                        <col style="width: 20%;">
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                        </th>
                                        <th scope="col">이름</th>
                                        <th scope="col">설명</th>
                                        <th scope="col">보기</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of securityGroupList  | tableFilter: filterText " (contextmenu)="onClickList(item)" (click)="onClickList(item)">
                                            <td><input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)" [attr.data-id]="item.id"></td>
                                            <td>{{item.name}}</td>
                                            <td>
                                                <ng-template [ngIf]="item.description != null && item.description != ''" [ngIfElse]="elseCase">{{item.description}}</ng-template>
                                                <ng-template #elseCase>없음</ng-template>
                                            </td>
                                            <td><a  [routerLink]="'/console/securitygroup/ruleManage/' + item.id"
                                                routerLinkActive="router-link-active" 
                                                class="btn sml bor_gray btn_expand">규칙보기</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1"></div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->
        </form>
    </div>
    <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropmenu -->
<div class="dropdown">
    <!-- <button type="button" onclick="myDropdown()" class="btn mid dGray dropbtn">메뉴</button> -->
    <div id="myDropdown" class="dropdown-content">
        <!-- <a onclick="fn_modalOpen('security_create')">보안그룹 생성</a> -->
        <ng-template [ngIf]="selected_securitygroup && !selected_securitygroup.isDefault"><a (click)="openDeleteSecurityGroupModal()">보안그룹 삭제</a></ng-template>
        <ng-template [ngIf]="selected_securitygroup && selected_securitygroup.isDefault"><a disabled>보안그룹 삭제</a></ng-template>
        <a (click)="openSecurityGroupRuleList()">규칙 관리</a>
    </div>
</div>
