import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-equipment-monitoring',
  templateUrl: './admin-equipment-monitoring.component.html',
})
export class AdminEquipmentMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
