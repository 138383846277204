import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-default-delete',
  templateUrl: './dialog-default-delete.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogDefaultDeleteComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogDefaultDeleteComponent>) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  dialogTitle:string;
  dialogContent:string;
  ngOnInit(): void {
    this.dialogTitle = this.dialogData.title
    this.dialogContent = this.dialogData.content
  }
}
