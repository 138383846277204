<!-- Container -->
<div id="Container" class="introduction">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>회사소개</h2>
        </div>
    </div>
    <div>
        <!-- section --> 
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3><span>올이음</span> instruction</h3>
                    <div class="subTit">
                        올이음은 안전하고 효율적인 클라우드 서비스를 제공합니다.
                    </div>
                </div>
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="companyIntro">
                        <div class="cont">
                            올이음은 안전하고 효율적인 IT 서비스 개발과 비즈니스를 연결할 수 있도록 클라우드 서비스를 제공합니다.<br>
                            웹 관리 콘솔을 이용하여 PC/모바일에서 올이음에서 제공하는 서비스를 클릭 몇 번으로 간단하게 구축이 가능합니다.<br>
                            합리적인 비용으로 쉽게 사용할 수 있는 올이음 클라우드 서비스. <br>
                            고객의 인프라 비용을 절감하고 효율적인 운용을 제공하기 위해 노력하겠습니다.
                        </div>
                        <ul class="icoBox">
                            <li class="lm_1">
                                <p class="tit">합리적인 비용으로 <br>IT 인프라와 플랫폼 이용</p>
                            </li>
                            <li class="lm_2">
                                <p class="tit">쉽게 사용할 수 있는 <br>클라우드 서비스</p>
                            </li>
                            <li class="lm_3">
                                <p class="tit">비즈니스 변화에 빠른<br>IT 인프라 대응</p>
                            </li>
                        </ul>

                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
        
        <!-- section -->
        <section class="sec sec_4">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3><span>올이음</span> 클라우드 고객사</h3>
                    <div class="subTit">
                        <!-- 고객사 소개문구 -->
                    </div>
                </div>
                <!-- //sec_header -->
            
                <!-- sec_content -->
                <div class="sec_content">
                    <div class="serviceStep">
                        <a class="fs" href="https://www.klnet.co.kr/" title="케이엘넷 바로가기" target="_blank">
                            <div>
                                <img class="cmncl" src="../../../assets/img/company/klnet.png" alt="케이엘넷"/>
                            </div>
                            <p class="cont pd_t10 pd_b10">바로가기 >></p>
                        </a>
                        <a href="http://www.yjit.co.kr/" title="양재IT 바로가기" target="_blank">
                            <div>
                                <img class="cmncl" src="../../../assets/img/company/yjit.png" alt="양재IT"/>
                            </div>
                            <p class="cont pd_t10 pd_b10">바로가기 >></p>
                        </a>

                        <a class="fs" href="https://www.transhub.co.kr/" title="다이몬소프트 바로가기" target="_blank">
                            <div>
                                <img class="cmncl" src="../../../assets/img/company/transhub.png" alt="다이몬소프트"/>
                            </div>
                            <p class="cont pd_t10 pd_b10">바로가기 >></p>
                        </a>
                        <a href="https://www.chassiradar.co.kr/" title="CnTR 바로가기" target="_blank">
                            <div>
                                <img class="cmncl" src="../../../assets/img/company/CnTR.png" alt="CnTR"/>
                            </div>
                            <p class="cont pd_t10 pd_b10">바로가기 >></p>
                        </a>
                        
                        <a href="https://www.logisone.co.kr/" title="로지스원 바로가기" target="_blank">
                            <div>
                                <img class="cmncl" src="../../../assets/img/company/logisone.png" alt="로지스원"/>
                            </div>
                            <p class="cont pd_t10 pd_b10">바로가기 >></p>
                        </a>
                        <a href="https://www.panlogis.co.kr/" title="판로지스 바로가기" target="_blank">
                            <div>
                                <img class="cmncl" src="../../../assets/img/company/panlogis.png" alt="판로지스"/>
                            </div>
                            <p class="cont pd_t10 pd_b10">바로가기 >></p>
                        </a>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
        
    </div>
</div>
<!-- //Container -->
<hr>