import { Component, OnInit, Inject } from '@angular/core';
import { GWService } from '../../../gwapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-gw-route-update',
  templateUrl: './dialog-gw-route-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogGwRouteUpdateComponent extends DialogCommon implements OnInit {

  // is_strip_path = true;
  // is_preserve_host = false;
  // is_keyauth = false;

  acl_auth_list = [];
  auth_list=[];
  selected_acl_auth;

  selected_api;
  original_route;
  update_route_object={
    name:"",
    path:"",
    protocol:["https"],
    methods:["GET"],
    is_strip_path:true,
    is_preserve_host:false,
    is_keyauth:false,
    is_acl:false,
    is_iprestriction:false,
    is_cors:false,
    is_requestsizelimiting:false,
    snis:[],
    acl_allow_groups:[],
    ip_restriction:{allow_ips:[],deny_ips:[]},
    cors_params_text:{
      origins:"*",
      methods:"GET,POST",
      headers:"Accept,Accept-Version,Content-Length,Content-MD5,Content-Type,Date,X-Auth-Token",
      exposed_headers:"",
      credentials:false,
      max_age:null
    },
    cors_params:{
      origins:[],
      methods:[],
      headers:[],
      exposed_headers:[],
      credentials:false,
      max_age:null
    },
    request_size_limit_size:128

  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private gwapi: GWService,
    public dialogRef: MatDialogRef<DialogGwRouteUpdateComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }
  ngOnInit(): void {
    this.selected_api = this.dialogData.selected_api;
    this.original_route = this.dialogData.selected_route;

    console.log(this.original_route)
    this.update_route_object.name = this.original_route.name
    this.update_route_object.path = this.original_route.path
    this.update_route_object.protocol[0] = this.original_route.protocol
    this.update_route_object.methods[0] = this.original_route.method
    this.update_route_object.is_strip_path = this.original_route.is_strip_path
    this.update_route_object.is_preserve_host = this.original_route.is_preserve_host
    this.update_route_object.is_keyauth = this.original_route.is_keyauth
    this.update_route_object.is_acl = this.original_route.is_acl
    this.update_route_object.is_iprestriction = this.original_route.is_iprestriction
    this.update_route_object.is_cors = this.original_route.is_cors
    if(this.update_route_object.is_cors){
      if(this.original_route.cors_data.origins)
        this.update_route_object.cors_params_text.origins=this.original_route.cors_data.origins.toString();
      else
        this.update_route_object.cors_params_text.origins=""
      if(this.original_route.cors_data.methods)
        this.update_route_object.cors_params_text.methods=this.original_route.cors_data.methods.toString();
      else
        this.update_route_object.cors_params_text.methods=""
      if(this.original_route.cors_data.headers)
        this.update_route_object.cors_params_text.headers=this.original_route.cors_data.headers.toString();
      else
        this.update_route_object.cors_params_text.headers=""
      if(this.original_route.cors_data.exposed_headers)
        this.update_route_object.cors_params_text.exposed_headers=this.original_route.cors_data.exposed_headers.toString();
      else
        this.update_route_object.cors_params_text.exposed_headers=""

      this.update_route_object.cors_params_text.credentials=this.original_route.cors_data.credentials;
      this.update_route_object.cors_params_text.max_age=this.original_route.cors_data.max_age;
    }
    this.update_route_object.is_requestsizelimiting = this.original_route.is_requestsizelimiting
    if(this.update_route_object.is_requestsizelimiting){
      this.update_route_object.request_size_limit_size = this.original_route.request_size_limit.size
    }
    this.getAuthList();
  }
  getAclPlugin(plugin_id){
    this.gwapi.getPlugin(plugin_id).subscribe((data: any) => {
      // this.auth_list = data;
      for(let auth of this.auth_list){
        for(let acl_group of data.config.allow){
          if(auth.kong_group_name == acl_group){
            this.selected_acl_auth = auth
            this.addAcl()
          }
        }
      }
      console.log(data)
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  getAuthList(){
    this.spinner.show();
    this.gwapi.getAuthList().subscribe((data: any) => {
      this.auth_list = data;
      console.log(data)
      
      if(this.update_route_object.is_acl){
        this.getAclPlugin(this.original_route.kong_plugin_acl_id);
      }
      this.spinner.hide();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });

  }
  changeNewAcl(new_auth_id){
    const idx = this.auth_list.findIndex(e=>e.kong_consumer_id==new_auth_id);
    this.selected_acl_auth = this.auth_list[idx];
    console.log(this.auth_list[idx])
  }

  addAcl(){
    this.acl_auth_list.push(this.selected_acl_auth);
    const idx = this.auth_list.findIndex(e=>e.kong_consumer_id==this.selected_acl_auth.kong_consumer_id);
    if (idx > -1) this.auth_list.splice(idx, 1)
    this.selected_acl_auth ={}

  }
  removeAcl(auth){
    this.auth_list.push(auth);
    const idx = this.acl_auth_list.findIndex(e=>e.kong_consumer_id==auth.kong_consumer_id);
    if (idx > -1) this.acl_auth_list.splice(idx, 1)
  }
  updateRoute(){
    if(this.acl_auth_list.length>0){
      this.update_route_object.is_acl = true;
      this.update_route_object.acl_allow_groups=this.acl_auth_list.map((item) => {
        return item.kong_group_name;
      });
    }
    if(this.update_route_object.request_size_limit_size){
      this.update_route_object.is_requestsizelimiting = this.update_route_object.request_size_limit_size>0
    }
    if(this.update_route_object.is_cors){
      this.update_route_object.cors_params.origins = this.update_route_object.cors_params_text.origins.split(',');
      this.update_route_object.cors_params.methods = this.update_route_object.cors_params_text.methods.split(',');
      this.update_route_object.cors_params.headers = this.update_route_object.cors_params_text.headers.split(',');
      if(this.update_route_object.cors_params_text.exposed_headers.length>0){
        this.update_route_object.cors_params.exposed_headers = this.update_route_object.cors_params_text.exposed_headers.split(',');
      }else{
        this.update_route_object.cors_params.exposed_headers = null;
      }
      this.update_route_object.cors_params.credentials = this.update_route_object.cors_params_text.credentials;
      this.update_route_object.cors_params.max_age = this.update_route_object.cors_params_text.max_age;
    }
    this.spinner.show();
    this.gwapi.updateRoute(
      this.original_route.kong_route_id,
      this.update_route_object.name,
      this.update_route_object.path,
      this.update_route_object.protocol,
      this.update_route_object.methods,
      this.update_route_object.is_strip_path,
      this.update_route_object.is_preserve_host,
      this.update_route_object.is_keyauth,
      this.update_route_object.is_acl,
      this.update_route_object.is_iprestriction,
      this.update_route_object.is_cors,
      this.update_route_object.is_requestsizelimiting,
      this.update_route_object.snis,
      this.update_route_object.acl_allow_groups,
      this.update_route_object.ip_restriction,
      this.update_route_object.cors_params,
      this.update_route_object.request_size_limit_size

    ).subscribe((data: any) => {
      this.spinner.hide();
      this.closeDialogMsg("success");
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
}
