<!-- Container -->
<div id="Container" class="mypage">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>회원정보</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <div class="sec_header">
                    <h3>회원정보</h3>
                    <div class="subTit">
                        올이음은 고객님의 소중한 개인정보를 위해 최선을 다하고 있습니다.<br>
                        개인정보 중 변경된 내용이 있는 경우 아래에서 해당 내용을 수정해주세요.
                    </div>
                </div>
                <!-- //sec_header -->
                <div class="sec_content" [formGroup]="profileUserForm">
                    <div class="rowGroup">
                        <div class="row">
                            <p class="tit">이름</p>
                            <p class="cont">
                                <input type="text" class="wtpB" id="name" formControlName="name" spellcheck="false"
                                    autocomplete="off" required>
                            </p>
                        </div>
                        <div class="row">
                            <p class="tit">로그인 아이디</p>
                            <p class="cont">
                                <input type="text" class="wtpB" id="userId" formControlName="userId" readonly>
                            </p>
                        </div>
                        <div class="row">
                            <p class="tit">현재 비밀번호</p>
                            <p class="cont">
                                <input type="password" class="wtpB" id="passwordOrg" formControlName="passwordOrg"
                                    placeholder="비밀번호를 입력해주세요." required>
                            </p>
                            <div *ngIf="submitted && userForm.passwordOrg.errors" class="invalid-feedback">
                                <div *ngIf="userForm.passwordOrg.errors.required" style="text-align: center;">현재 비밀번호를
                                    입력해 주세요</div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="tit">변경할 비밀번호</p>
                            <p class="cont">
                                <input type="password" class="wtpB" formControlName="password"
                                    placeholder="변경할 비밀번호를 입력해주세요." maxlength="20">
                            </p>
                            <div *ngIf="submitted && userForm.password.errors" class="invalid-feedback">
                                <div *ngUf="userForm.password.errors.pattern || userForm.password.errors.minlength">영문,
                                    숫자, 특수문자를 포함하여 8자리 이상 입력해주세요. (최대 20자)</div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="tit">변경할 비밀번호 확인</p>
                            <p class="cont">
                                <input type="password" class="wtpB" formControlName="confirmPassword"
                                    placeholder="변경할 비밀번호를 확인.">
                            </p>
                        </div>
                        <div class="row">
                            <p class="tit">전화 번호</p>
                            <p class="cont">
                                <input type="text" class="wtpB" id="telephone" formControlName="telephone"
                                    maxlength="11">
                            </p>
                            <div *ngIf="submitted && userForm.telephone.errors" class="invalid-feedback">
                                <div *ngIf="userForm.telephone.errors.pattern || userForm.telephone.errors.minlength"
                                    style="text-align: center;">10 ~ 11자리 숫자만 입력해 주세요</div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="tit">핸드폰 번호</p>
                            <p class="cont">
                                <input type="text" class="wtpB" id="mobile" formControlName="mobile" required
                                    maxlength="11">
                            </p>
                            <div *ngIf="submitted && userForm.mobile.errors" class="invalid-feedback">
                                <div *ngIf="userForm.mobile.errors.required" style="text-align: center;">핸드폰 번호를 입력해
                                    주세요.</div>
                                <div *ngIf="userForm.mobile.errors.pattern || userForm.mobile.errors.minlength"
                                    style="text-align: center;">10 ~ 11자리 숫자만 입력해 주세요</div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="tit">우편번호</p>
                            <p class="cont">
                                <input type="text" class="wtpA" formControlName="zipCode" readonly required>
                                <a class="btn mid black" (click)="openDaumPopup()">주소찾기</a>
                            </p>
                        </div>
                        <div class="row">
                            <p class="tit">상세주소</p>
                            <p class="cont">
                                <input type="text" class="wtpD" formControlName="address" readonly required>
                            </p>
                        </div>
                        <div class="row">
                            <p class="tit"></p>
                            <p class="cont">
                                <input type="text" class="wtpB" id="addressDetail" formControlName="addressDetail"
                                placeholder="상세주소를 입력해 주세요." required>
                            </p>
                        </div>
                        <div *ngIf="submitted && (userForm.zipCode.errors || userForm.address.errors || userForm.addressDetail.errors)"
                            class="invalid-feedback">
                            <div style="text-align: center;">주소를 입력해주세요.</div>
                        </div>
                    </div>
                    <div class="btn_wrap tp2">
                        <button type="submit" id="" class="btn big blue mg_r8" (click)="onSubmit()">정보수정</button>
                        <ng-template [ngIf]="status =='0' || status =='1' || status =='2'">
                            <button type="submit" id="" class="btn big red" (click)="withdrawal()">회원탈퇴</button>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->


    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>