
<!-- Modal : 서버 기본정보 수정 모달 -->
<div id="server_edit_memo" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      서버
      <p class="sub-txt">| 서버의 기본정보를 변경합니다.</p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 20%;" />
          <col style="width: 80%;" />
        </colgroup>
        <tbody *ngIf="selected_server">
          <tr>
            <th scope="row">서버 이름</th>
            <td>
              <input type="text" name="" id="editServerName" [(ngModel)]="edit_server_data.name" placeholder="20자 이내로 작성해주세요. 영문자와 숫자, '-', '.'만 입력 가능합니다." maxlength="20" />
            </td>
          </tr>
          <tr>
            <th scope="row">설명</th>
            <td>
              <textarea name="" id="editServerMemo" rows="5" placeholder="내용을 입력해주세요"
                [(ngModel)]="edit_server_data.description" maxlength="255"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btnArea tp1">
      <a (click)="updateServerInfo()"  class="btn big ciblue mg_r4">수정</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->