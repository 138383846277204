<div class="container">
    <div class="header pt_30">
        <ol class="breadcrumb">
            <li>Home</li>
            <li class="active">Dashboard</li>
        </ol>
    </div>
    <div class="section">
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="card horizontal cardIcon waves-effect waves-dark">
                    <div class="card-image darken">
                        <i class="fa fa-users fa-5x"></i>
                    </div>
                    <div class="card-stacked">
                        <div class="card-content">
                            <h4>{{username}} 님</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-action">
                        <b class="center light-blue-text">이용중인 서비스</b>
                    </div>
                    <div class="card-image">
                        <ul class="collection">
                            <li class="collection-item avatar waves-effect waves-dark"
                                *ngIf="usingService.project > 0" (click)="routerDirection('/m/project')">
                                <i class="material-icons circle blue">folder</i>
                                <td class="title">활성 프로젝트</td>
                                <td>{{usingService.project}} 개</td>
                            </li>
                            <li class="collection-item avatar waves-effect waves-dark"
                                *ngIf="usingService.server_instance > 0" (click)="routerDirection('/m/server')">
                                <i class="material-icons circle blue">folder</i>
                                <td class="title">Instance</td>
                                <td>{{usingService.server_instance}} 대</td>
                            </li>
                            <li class="collection-item avatar waves-effect waves-dark"
                                *ngIf="usingService.server_image > 0" (click)="routerDirection('/m/image')">
                                <i class="material-icons circle blue">folder</i>
                                <td class="title">Image</td>
                                <td>{{usingService.server_image}} 대</td>
                            </li>
                            <li class="collection-item avatar waves-effect waves-dark"
                                *ngIf="usingService.storage_block > 0" (click)="routerDirection('/m/storage')">
                                <i class="material-icons circle blue">folder</i>
                                <td class="title">Block Storage</td>
                                <td>{{usingService.storage_block}} 개</td>
                            </li>
                            <li class="collection-item avatar waves-effect waves-dark"
                                *ngIf="usingService.storage_snapshot > 0" (click)="routerDirection('/m/snapshot')">
                                <i class="material-icons circle blue">folder</i>
                                <td class="title">Snapshot</td>
                                <td>{{usingService.storage_snapshot}} 개</td>
                            </li>
                            <li class="collection-item avatar waves-effect waves-dark"
                                *ngIf="usingService.network_public_ip > 0" (click)="routerDirection('/m/ip')">
                                <i class="material-icons circle blue">folder</i>
                                <td class="title">Public IP</td>
                                <td>{{usingService.network_public_ip}} 개</td>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br>
    <div class="section">
    </div>
</div>
