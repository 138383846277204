<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>회원관리</h2>
    <div class="lineMap">회원관리 / <span>회원목록</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="lyBox consoleRightBody">
          <div class="titArea">
            <h3>회원 목록</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요."
                  autocomplete="off" />
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid orange" (click)="getCompaniesSearch()">
                    검색
                  </button>
                  
                </div>
                <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block">
            <div class="boardBox">
              <table class="tbl_col_tp1 pdTp2">
                <colgroup>
                  <col style="width: 60px;"/>
                  <col style="width: 200px;"/>
                  <col style="width: 150px;"/>
                  <col style="width: 100px;"/>
                  <col style="width: 120px;"/>
                  <col style="width: 100px;"/>
                  <col style="width: 80px;"/>
                  <col style="width: 80px;"/>
                  <col style="width: 120px;"/>
                  <col style="width: 80px;"/>
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">번호</th>
                    <th scope="col">ID</th>
                    <th scope="col">회사</th>
                    <th scope="col">이름</th>
                    <th scope="col">전화번호</th>
                    <th scope="col">가입일</th>
                    <th scope="col">상태</th>
                    <th scope="col">크레딧</th>
                    <th scope="col">사업자등록증사본</th>
                    <th scope="col">승인/취소</th>
                  </tr>
                </thead>

                <!-- 기업 승인 테스트 데이터-->
                <tbody>
                  <tr *ngFor="let company of companies; index as i" class="link">
                    <td (click)="fn_detailCall(company.Users[0])" style="cursor: pointer;">{{ 10 * curPage + i + 1 }}</td>
                    <td (click)="fn_detailCall(company.Users[0])" style="cursor: pointer;">
                      {{ company.Users[0].userId }}
                    </td>
                    <td (click)="fn_detailCall(company.Users[0])" style="cursor: pointer;">{{ company.companyName === 'empty company' ? '개인' : company.companyName }}</td>
                    <td (click)="fn_detailCall(company.Users[0])" style="cursor: pointer;">
                      {{ company.Users[0].name }}
                    </td>
                    <td (click)="fn_detailCall(company.Users[0])" style="cursor: pointer;">{{ company.telephone }}</td>
                    <td (click)="fn_detailCall(company.Users[0])" style="cursor: pointer;">{{ company.createdDate.split("T")[0] }}</td>
                    <td>
                      <label [class]=" company.status ==1 ? 'dot red' : 'dot green'"></label> {{ company.status ==1 ? "미승인" : "승인" }}
                    </td>
                    <td>
                      {{ company.credit }}
                    </td>
                    <td>
                      <a *ngIf="company.documentFile" (click)="fn_downloadRegistration(company.registrationNumber,company.registrationNumberSub)" class="btn sml dGray">다운로드</a>
                    </td>
                    <td>
                      <button type="button" class="btn sml blue" *ngIf="company.status ==1"
                        (click)="enableCompany(company.registrationNumber,company.registrationNumberSub)">
                        승인
                      </button>
                      <!-- <button (click)="test(company)">ㅋㅋ..</button> -->
                    </td>
                  </tr>
                </tbody>
                <!--기업 승인 테스트 데이터-->
              </table>

              <!-- 페이징 -->
              <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>

            </div>
          </div>
        </div>
        <!-- //lyBox -->

        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- Modal : 회원 정보 모달 -->
<div id="user_detail" class="modal">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>회원 정보 수정</h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <form (ngSubmit)="onSubmit()">
      <fieldset class="userDetailForm">
        <!-- userId 변경 불가 -->
        <input type="hidden" id="comp_userId" [value]="modal_comp_userId"
          (change)="onUserIdChange($event.target.value)" />

        <div class="boardBox">
          <table class="tbl_row_tp1">
            <colgroup>
              <col style="width: 30%;" />
              <col style="width: 70%;" />
            </colgroup>
            <tbody>
              <tr>
                <td><span>로그인 계정</span></td>
                <td>
                  <span id="comp_userId">{{ modal_comp_userId }}</span>
                </td>
              </tr>
              <tr>
                <td><span>이름</span></td>
                <td>
                  <input type="text" id="comp_name" [value]="modal_comp_name"
                    (change)="onUserNameChange($event.target.value)" maxlength="20" />
                </td>
              </tr>
              <tr>
                <td><span>전화번호</span></td>
                <td>
                  <input type="text" id="comp_telephone" [value]="modal_comp_telephone"
                    (change)="onUserTelephoneChange($event.target.value)" maxlength="13" />
                </td>
              </tr>
              <tr>
                <td><span>휴대폰번호</span></td>
                <td>
                  <input type="text" id="comp_mobile" [value]="modal_comp_mobile"
                    (change)="onUserMobileChange($event.target.value)" maxlength="13" />
                </td>
              </tr>
              <tr>
                <td><span>가입경로</span></td>
                <td>
                  <input type="text" id="comp_joinChannel" [value]="modal_comp_joinChannel"
                    (change)="onUserJoinChannelChange($event.target.value)" />
                </td>
              </tr>
              <tr>
                <td><span>계정잠금</span></td>
                <td>
                  <input type="radio" id="comp_accountLockT" name="comp_accountLock"
                    (change)="onUserAccountLockChange(true)" />Y
                  <input type="radio" id="comp_accountLockF" name="comp_accountLock"
                    (change)="onUserAccountLockChange(false)" />N
                </td>
              </tr>
              <tr>
                <td><span>가입일</span></td>
                <td><span id="comp_createdDate"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="btnArea tp1">
          <button type="submit" class="btn big orange mg_r4">수정</button>
          <a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn">닫기</a>
        </div>
      </fieldset>
    </form>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->