<!-- Modal : 네트워크 생성 모달 -->
<div id="network_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        네트워크 생성
        <div class="sub-txt">
          네트워크는 공인망(인터넷)과 연결되며 '외부 연결 관리'를 통해 외부 트래픽을 허용/차단 할 수 있습니다. SNAT 사용 시 공인IP 사용료가 부과됩니다.
        </div>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <table class="tbl_row_tp1">
          <colgroup>
            <col style="width:30%">
            <col style="width:70%">
          </colgroup>
          <tbody>
            <tr>
              <th>네트워크 이름</th>
              <td><input type="text" value="" placeholder="영문자, _(언더바), -(하이픈)만 사용이 가능합니다."
                  [(ngModel)]="networkModel.name" (change)="validNetworkName()" maxlength="60"></td>
            </tr>
            <tr>
              <th>CIDR</th>
              <td><input type="text" value="" placeholder="예) 192.168.0.0/16" [(ngModel)]="networkModel.cidr"
                  (change)="validNetworkCidr()">
                <div *ngIf="network_cidr_validate==false" class="invalid-feedback">
                  <div>유효하지않은 CIDR 입니다.</div>
                </div>
                <div *ngIf="network_cidr_not_used==false"
                  class="invalid-feedback">
                  <div>
                      <ng-template [ngIf]="!validate_false_by_blsnet">이미 사용중인 네트워크 대역입니다.</ng-template>
                      <ng-template [ngIf]="validate_false_by_blsnet">
                          해당 대역은 서비스가능한 대역이 아닙니다.
                      </ng-template>
                  </div>
                </div>
                <div *ngIf="private_network_cidr_validate==false" class="invalid-feedback">
                  <div>CIDR은 사설 주소 범위로 입력되어야 합니다.</div>
                  <div>(10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16)</div>
                </div>
              </td>
            </tr>
            <!-- 20200901 cbkim 사설 네트워크 탭 생성에 따른 삭제-->
            <!-- <tr>
              <th>네트워크 타입</th>
              <td>
                <input type="radio" name="field_create_network" id="radio2" checked
                  (click)="setkNetworkType(false)" />
                <label for="radio2"> 외부</label>
                <input type="radio" name="field_create_network" id="radio1"
                  (click)="setkNetworkType(true)" />
                <label for="radio1" class="mg_r8"> 내부</label>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="sub-txt">
                  {{network_create_message}}
                </div>
              </td>
            </tr> -->
            <!-- END 20200901 cbkim -->
          </tbody>
        </table>
      </div>
      <div class="btnArea tp2">
        <a (click)="createNetwork()" class="btn big ciblue mg_r4">생성</a>
        <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->