import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../../../webapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

@Component({
  selector: 'app-admin-users-log',
  templateUrl: './admin-users-log.component.html'
})
export class AdminUsersLogComponent implements OnInit {
  logList:any = [];
  allList:any = [];
  okList:any = [];
  failList:any = [];

  constructor(
    private webapi: WebApiService,
    private common: AdminNavComponent
  ) { }

  ngOnInit(): void {
    this.getUserLogs();
  }

  getUserLogs(){
    this.webapi.getUserLogs().subscribe(
      (data)=>{
        this.allList=data;
        this.logList=data;
        
        for(let item of this.allList){
          if(item.httpStatusCode == 200){
            this.okList.push(item);
          }else{
            this.failList.push(item);
          }
        }
        this.common.getThemeFont();
      }
    )
  }

  changeList(type){
    switch (type) {
      case 1:
        this.logList = this.allList;
        break;
      case 2:
          this.logList = this.okList;
        break;
      case 3:
          this.logList = this.failList;
        break;
    }
  }
}
