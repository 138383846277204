<!-- Modal : Bucket 생성 모달 -->
<div id="bucket_create" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      버킷 생성
      <p class="sub-txt">
        Object Storage Bucket을 생성합니다.<br/>
        * 버킷명 규칙<br/>
        &nbsp;&nbsp;- 이미 생성된 버킷명과 중복이 불가능합니다. (다른 사용자가 사용중인 버킷명 포함.)<br/>
        &nbsp;&nbsp;- 버킷명은 최소 3자, 최대 63자까지 가능합니다.<br/>
        &nbsp;&nbsp;- 버킷명은 소문자, 숫자, '-'(하이픈), '.'(마침표)이 포함 가능합니다.<br/>
        &nbsp;&nbsp;- 버킷명은 소문자 또는 숫자로 시작하고 끝나야합니다.<br/>
        &nbsp;&nbsp;- '-'(하이픈) 또는 '.'(마침표)은 연속으로 사용 불가능합니다.<br/>
        &nbsp;&nbsp;- 버킷명은 IPv4 주소 형식으로 사용 불가능합니다. (예. 192.168.0.1)<br/>
      </p>
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>버킷명</th>
            <td>
              <input type="text" [(ngModel)]="newBucket.name" placeholder="이름을 입력해주세요" />
            </td>
          </tr>

          <!-- <tr>
            <th>설명</th>
            <td><input type="text" [(ngModel)]="newBucket.description" placeholder="설명을 입력해주세요" /></td>
          </tr> -->
          <!-- <tr>
            <th>크기(GB)</th>
            <td>
              <select [(ngModel)]="newNas.size">
                <option value="" selected>-선택-</option>
                <option *ngFor="let size of nasSize" value="{{size}}">{{size}} GB</option>
              </select>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <div class="btnArea tp2">
      <a (click)="createBucket()" class="btn big ciblue mg_r4">생성</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->