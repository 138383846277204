<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>서버 생성</h2>
        <div class="lineMap">Server / <span>Instances</span></div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- <form [formGroup]="serverFrm" (ngSubmit)="onSubmit('modal_comfirm')"> -->
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="createBox">
                    <div #step1 class="titArea">
                        <h3>모니터링</h3>
                    </div>
                    <div class="titArea tpA">
                        <div class="col col2">
                            <!-- <button type="submit" class="btn mid ciblue btn_right_margin"
                                (click)="changeWhereDate(0)" >1일</button>
                            <button type="submit" class="btn mid ciblue btn_right_margin"
                                (click)="changeWhereDate(1)">1주일</button>
                            <button type="submit" class="btn mid ciblue btn_right_margin"
                                (click)="changeWhereDate(2)">한달</button>
                            <button type="submit" class="btn mid ciblue btn_right_margin"
                                (click)="changeWhereDate(3)">전체</button> -->
                                <!-- <div style="padding:8px;">
                                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                        <mat-button-toggle (click)="changeWhereDate(0)" selected value="bold">하루</mat-button-toggle>
                                        <mat-button-toggle (click)="changeWhereDate(1)" value="italic">일주일</mat-button-toggle>
                                        <mat-button-toggle (click)="changeWhereDate(2)" value="underline">전체</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div> -->

                            <div style="padding:8px;">
                                <h3 style="margin-bottom: 8px;">{{selectedInstance}}</h3>
                                <!-- <ul>
                                    <li *ngFor="let item of dataList" (click)="changeChartData(item.server_id)">{{item.display_name}}</li>
                                </ul> -->
                                <mat-selection-list #shoes [multiple]="false">
                                    <mat-list-option (click)="changeChartData(0)" selected>전체</mat-list-option>
                                    <mat-list-option *ngFor="let item of instanceList" (click)="changeChartData(item.server_id)">
                                            {{item.display_name}}
                                    </mat-list-option>
                                </mat-selection-list>
                                <!-- <mat-action-list>
                                    <button *ngFor="let item of dataList" (click)="changeChartData(item.server_id)" [disabled]="selectedInstance==item.display_name"
                                        class="btn mid">{{item.display_name}}</button>
                                </mat-action-list> -->
                            </div>
                        </div>
                        <div class="col col10">
                            <div id="cpu-container" class="col col6">
                                <ejs-chart  #cpuChart title="CPU 사용률"  [primaryYAxis]='cpuYAxis'  [primaryXAxis]='primaryXAxis' [tooltip]='tooltip'
                                    [zoomSettings]='zoom' [legendSettings]='legend' 
                                    height=300 [border]='border' [margin]='margin'>
                                    <e-series-collection>
                                        <!-- <e-series  [dataSource]='cpuData' type='Line' xName='x' yName='y' name='Used' opacity=0.3>
                                        </e-series> -->
                                        <!-- <e-series *ngFor="let data of cpuData" [dataSource]="data.values" type='Line' xName="x" yName="y">
                                            </e-series> -->
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div id="memory-container" class="col col6">
                                <ejs-chart #memoryChart [primaryXAxis]='primaryXAxis' [primaryYAxis]='memoryYAxis'  [tooltip]='tooltip'
                                    title="메모리 사용률" [zoomSettings]='zoom' [legendSettings]='legend' height=300
                                    [border]='border' [margin]='margin'>
                                    <e-series-collection>
                                        <!-- <e-series [dataSource]='memData' type='Area' xName='x' yName='y' name='Used' opacity=0.3>
                                        </e-series> -->
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div id="disk-container" class="col col6">
                                <ejs-chart #diskChart [primaryXAxis]='primaryXAxis' [primaryYAxis]='diskRootYAxis' [tooltip]='tooltip'
                                    title="디스크 사용률" [zoomSettings]='zoom' [legendSettings]='legend' height=300
                                    [border]='border' [margin]='margin'>
                                    <e-series-collection>
                                        <!-- <e-series [dataSource]='diskData' type='Area' xName='x' yName='y'
                                            name="Used" opacity=0.3></e-series> -->
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div id="network-container" class="col col6">
                                <ejs-chart #networkChart [primaryXAxis]='primaryXAxis' [primaryYAxis]='networkYAxis' [tooltip]='tooltip'
                                    title="네트워크 데이터 송수신" [zoomSettings]='zoom' [legendSettings]='legend' height=300
                                    [border]='border' [margin]='margin'>
                                    <e-series-collection>
                                        <!-- <e-series [dataSource]='networkInData' type='Line' xName='x' yName='y'
                                            name='IN' opacity=0.6 fill='#ff0000'></e-series>
                                        <e-series [dataSource]='networkOutData' type='Line' xName='x' yName='y'
                                            name='OUT' opacity=0.6 fill='#00ff00'></e-series> -->
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <!-- <div class="col col6">
                                <ejs-chart [primaryXAxis]='primaryXAxis' [primaryYAxis]='diskYAxis'
                                    title="디스크 읽기/쓰기 바이트" [zoomSettings]='zoom' [legendSettings]='legend' height=300
                                    [border]='border' [margin]='margin'>
                                    <e-series-collection>
                                        <e-series [dataSource]='diskWriteData' type='Line' xName='x' yName='y'
                                            name="WRITE" opacity=0.6 fill='#ff0000'></e-series>
                                        <e-series [dataSource]='diskReadData' type='Line' xName='x' yName='y'
                                            name="READ" opacity=0.6 fill='#00ff00'></e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#31a3cb" type="square-jelly-box" [fullScreen]="false">
    <p style="color: black;">데이터를 불러오고있습니다. (서버 수/시간에 따라 지연 될 수 있습니다.)</p>
</ngx-spinner>