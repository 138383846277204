<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Network</li>
      <li class="active">Public IP</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let item of ipLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons"
                        [class]="item.status === 'ACTIVE' ? 'dotActive' : 'dotStop'">wifi_tethering</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{item.ip}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>IP주소</th>
                      <td>{{item.ip}}</td>
                    </tr>
                    <tr>
                      <th>서버</th>
                      <td *ngIf="item.status.toUpperCase() == 'ACTIVE';else elseCase">
                        <ng-template [ngIf]="item.server==null">로딩 중</ng-template>
                        <ng-template [ngIf]="item.server!=null">{{item.server.server_name}} ({{item.fixed_ip}})</ng-template>
                      </td>
                    </tr>
                    <tr>
                      <th>설명</th>
                      <td *ngIf="item.description;else elseCase">{{item.description}}</td>
                      <ng-template #elseCase>
                        <td>없음</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>
                        <label [class]="item.status == 'ACTIVE'? 'dot green': 'dot red'"></label>
                        {{item.status}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>