<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>서비스 관리</h2>
		<div class="lineMap">
			청구관리 /  <span> 서비스 관리</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<div class="lyBox">								
					<div class="contArea" style="display:block"> 
						<div class="boardBox">
							<table class="tbl_row_tp1">
								<colgroup>
									<col style="width:20%">
									<col style="width:80%">
								</colgroup>
								<tbody>                                    
									<tr>
										<th scope="row">서비스 생성</th>
										<td>
											<a class="btn mid orange mg_r4" onclick="fn_modalOpen('create_service')">서비스 생성</a>
										</td>
									</tr>                                                
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>서비스 관리</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<!-- <input type="search" class="form-control" (keydown.enter)="onSearch($event.target.value)" [value]="searchTitle" (change)="onSearchChange($event.target.value)" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid dBlue" (click)="onSearch(searchTitle)"><i class="ico ico_search_white"></i>검색</button>
								</div> -->
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block"> 

						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2 dropmenu">
								<colgroup>
									<col style="width: 60px;">
									<col style="width: 60px;">
									<col style="width: 150px;">
									<col style="width: 120px;">
									<col style="width: 60px;">
									<col style="width: 120px;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">버전</th>							
										<th scope="col">서비스코드</th>
										<th scope="col">서비스명</th>
										<th scope="col">활성화여부</th>
										<th scope="col">생성일자</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let service of serviceList; index as i; " (contextmenu)="getServiceDetail(service)">
										<td>{{ 10 * curPage + (i + 1) }}</td>
										<td>{{ service.version }}</td>
										<td>{{ service.code }}</td>
										<td>{{ service.nameKo }}</td>
										<td>
											<label [class]="service.enabled ? 'dot green' : 'dot red'"></label> {{ service.enabled }}
										</td>
										<td >{{ service.createdDate | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
									</tr>
									<tr *ngIf="totalCount < 1">
										<td colspan="6">조회된 내역이 없습니다</td>
									</tr>
								</tbody>
							</table>
	
							<!-- 페이징 -->
							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
						</div>
					</div>
				</div>
				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->


<div class="dropdown">
	<div id="myDropdown" class="dropdown-content">
		<a (click)="updateModal()">서비스 편집</a>
		<a onclick="fn_modalMsg('삭제하시겠습니까?')">서비스 삭제</a>
	</div>
</div>


<!-- Modal : Service 생성 모달 -->
<div id="create_service" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>서비스 생성</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<form [formGroup]="serviceForm" (ngSubmit)="onSubmit($event)">
			<div class="boardBox">			
				<table class="tbl_row_tp1">
					<colgroup>
						<col style="width:30%">
						<col style="width:70%">
					</colgroup>
					<tbody>
						<tr class="required">
							<th>서비스 버전</th>
							<td>
								<input type="number" formControlName="version" placeholder="숫자만 입력해주세요"
									[class]="serviceChk.version.invalid && serviceChk.version.dirty ? 'error-invalid' : ''"
								>
								<div *ngIf="serviceChk.version.dirty">
									<div class="error-invalid-feedback" *ngIf="serviceChk.version.errors?.required">
										서비스 버전은 필수 값입니다.
									</div>
								</div>
							</td>
						</tr>
						<tr class="required">
							<th>서비스 코드</th>
							<td>
								<input type="text" formControlName="code" placeholder="32자 이내로 작성해주세요"
									[class]="serviceChk.code.invalid && serviceChk.code.dirty ? 'error-invalid' : ''"
								>
								<div *ngIf="serviceChk.code.dirty">
									<div class="error-invalid-feedback" *ngIf="serviceChk.code.errors?.required">
										서비스 코드는 필수 값입니다. 32자 이내로 작성해주세요.
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<th>서비스 이름(한글)</th>
							<td>
								<input type="text" formControlName="nameKo" placeholder="128자 이내로 작성해주세요"
									[class]="serviceChk.nameKo.invalid && serviceChk.nameKo.dirty ? 'error-invalid' : ''"
								>
							</td>
						</tr>
						<tr>
							<th>서비스 이름(영문)</th>
							<td>
								<input type="text" formControlName="nameEn" placeholder="128자 이내로 작성해주세요"
									[class]="serviceChk.nameEn.invalid && serviceChk.nameEn.dirty ? 'error-invalid' : ''"
								>
							</td>
						</tr>
						<tr class="required">
							<th>대분류</th>
							<td>
								<select formControlName="categoryMain" placeholder="대분류를 선택해주세요"
									[class]="serviceChk.categoryMain.invalid && serviceChk.categoryMain.dirty ? 'error-invalid' : ''">
									<option value="" selected>-선택-</option>
									<option value="Compute">Compute</option>
									<option value="Storage">Storage</option>
									<option value="Backup">Backup</option>
								</select>
								<div *ngIf="serviceChk.categoryMain.dirty">
									<div class="error-invalid-feedback" *ngIf="serviceChk.categoryMain.errors?.required">
										대분류는 필수 값입니다.
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<th>중분류</th>
							<td>
								<select formControlName="categorySub" placeholder="중분류를 선택해주세요"
									[class]="serviceChk.categorySub.invalid && serviceChk.categorySub.dirty ? 'error-invalid' : ''">
									<option value="" selected>-선택-</option>
									<option value="NAS">NAS</option>
									<option value="server">Server</option>
									<option value="db">DB</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>소분류</th>
							<td>
								<select formControlName="categorySpecific" placeholder="소분류를 선택해주세요"
									[class]="serviceChk.categorySpecific.invalid && serviceChk.categorySpecific.dirty ? 'error-invalid' : ''">
									<option value="" selected>-선택-</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>최소신청단위</th>
							<td>
								<select formControlName="minimum" placeholder="최소신청단위를 선택해주세요"
									[class]="serviceChk.minimum.invalid && serviceChk.minimum.dirty ? 'error-invalid' : ''">
									<option value="" selected>-선택-</option>
									<option value="100GB">100GB</option>
									<option value="300GB">300GB</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>과금단위</th>
							<td>
								<select formControlName="per" placeholder="과금단위를 선택해주세요"
									[class]="serviceChk.per.invalid && serviceChk.per.dirty ? 'error-invalid' : ''">
									<option value="" selected>-선택-</option>
									<option value="10GB" selected>10GB</option>
									<option value="100GB">100GB</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>활성화 여부</th>
							<td>
								<input type="checkbox" formControlName="enabled" readonly>
							</td>
						</tr>
						<tr class="required">
							<th>시간당 요금</th>
							<td>
								<input type="number" formControlName="priceHour" placeholder="숫자만 입력해주세요"
									[class]="serviceChk.priceHour.invalid && serviceChk.priceHour.dirty ? 'error-invalid' : ''"
								>
								<div *ngIf="serviceChk.priceHour.dirty">
									<div class="error-invalid-feedback" *ngIf="serviceChk.priceHour.errors?.required">
										시간당 요금은 필수 값입니다.
									</div>
								</div>
							</td>
						</tr>
						<tr class="required">
							<th>하루당 요금</th>
							<td>
								<input type="number" formControlName="priceDay" placeholder="숫자만 입력해주세요"
									[class]="serviceChk.priceDay.invalid && serviceChk.priceDay.dirty ? 'error-invalid' : ''"
								>
								<div *ngIf="serviceChk.priceDay.dirty">
									<div class="error-invalid-feedback" *ngIf="serviceChk.priceDay.errors?.required">
										하루당 요금은 필수 값입니다.
									</div>
								</div>
							</td>
						</tr>
						<tr class="required">
							<th>월 당 요금</th>
							<td>
								<input type="number" formControlName="priceMonth" placeholder="숫자만 입력해주세요"
									[class]="serviceChk.priceMonth.invalid && serviceChk.priceMonth.dirty ? 'error-invalid' : ''"
								>
								<div *ngIf="serviceChk.priceMonth.dirty">
									<div class="error-invalid-feedback" *ngIf="serviceChk.priceMonth.errors?.required">
										월 당 요금은 필수 값입니다.
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>				
			</div>
			<div class="btnArea tp1">
				<button type="submit" class="btn big orange mg_r4" [disabled]='!serviceForm.valid'>저장</button>
				<a href="#close-modal" rel="modal:close" id="close-create" class="btn big orange" (click)="serviceForm.reset({enabled:true});">닫기</a>
			</div>
		</form>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->

<!-- Modal : Service 수정 모달 -->
<div id="update_service" class="modal">
		<!-- modal_header -->
		<div class="modal_header">
			<h2>서비스 편집</h2>
		</div>
		<!-- //modal_header -->
	
		<!-- modal_content -->
		<div class="modal_content">
			<form [formGroup]="updateForm" (ngSubmit)="onUpdate($event)">
				<div class="boardBox">			
					<table class="tbl_row_tp1">
						<colgroup>
							<col style="width:30%">
							<col style="width:70%">
						</colgroup>
						<tbody>
							<tr class="required">
								<th>서비스 버전</th>
								<td>
									<input type="number" formControlName="version" placeholder="숫자만 입력해주세요"
										[class]="updateChk.version.invalid && updateChk.version.dirty ? 'error-invalid' : ''"
									>
									<div *ngIf="updateChk.version.dirty">
										<div class="error-invalid-feedback" *ngIf="updateChk.version.errors?.required">
											서비스 버전은 필수 값입니다.
										</div>
									</div>
								</td>
							</tr>
							<tr class="required">
								<th>서비스 코드</th>
								<td>
									<input type="text" formControlName="code" placeholder="32자 이내로 작성해주세요"
										[class]="updateChk.code.invalid && updateChk.code.dirty ? 'error-invalid' : ''"
									>
									<div *ngIf="updateChk.code.dirty">
										<div class="error-invalid-feedback" *ngIf="updateChk.code.errors?.required">
											서비스 코드는 필수 값입니다. 32자 이내로 작성해주세요.
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>서비스 이름(한글)</th>
								<td>
									<input type="text" formControlName="nameKo" placeholder="128자 이내로 작성해주세요">
								</td>
							</tr>
							<tr>
								<th>서비스 이름(영문)</th>
								<td>
									<input type="text" formControlName="nameEn" placeholder="128자 이내로 작성해주세요">
								</td>
							</tr>
							<tr class="required">
								<th>대분류</th>
								<td>
									<select formControlName="categoryMain" 
										[class]="updateChk.categoryMain.invalid && updateChk.categoryMain.dirty ? 'error-invalid' : ''">
										<option value="Compute">Compute</option>
										<option value="Storage">Storage</option>
										<option value="Backup">Backup</option>
									</select>
									<div *ngIf="updateChk.categoryMain.dirty">
										<div class="error-invalid-feedback" *ngIf="updateChk.categoryMain.errors?.required">
											대분류는 필수 값입니다.
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>중분류</th>
								<td>
									<select formControlName="categorySub">
										<option value="NAS">NAS</option>
										<option value="server">Server</option>
										<option value="db">DB</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>소분류</th>
								<td>
									<select formControlName="categorySpecific">
										<option value="">-선택-</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>최소신청단위</th>
								<td>
									<select formControlName="minimum">
										<option value="100GB">100GB</option>
										<option value="300GB">300GB</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>과금단위</th>
								<td>
									<select formControlName="per">
										<option value="10GB" selected>10GB</option>
										<option value="100GB">100GB</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>활성화 여부</th>
								<td>
									<input type="checkbox" formControlName="enabled">
								</td>
							</tr>
							<tr class="required">
								<th>시간당 요금</th>
								<td>
									<input type="number" formControlName="priceHour" placeholder="숫자만 입력해주세요"
										[class]="updateChk.priceHour.invalid && updateChk.priceHour.dirty ? 'error-invalid' : ''"
									>
									<div *ngIf="updateChk.priceHour.dirty">
										<div class="error-invalid-feedback" *ngIf="updateChk.priceHour.errors?.required">
											시간당 요금은 필수 값입니다.
										</div>
									</div>
								</td>
							</tr>
							<tr class="required">
								<th>하루당 요금</th>
								<td>
									<input type="number" formControlName="priceDay" placeholder="숫자만 입력해주세요"
										[class]="updateChk.priceDay.invalid && updateChk.priceDay.dirty ? 'error-invalid' : ''"
									>
									<div *ngIf="updateChk.priceDay.dirty">
										<div class="error-invalid-feedback" *ngIf="updateChk.priceDay.errors?.required">
											하루당 요금은 필수 값입니다.
										</div>
									</div>
								</td>
							</tr>
							<tr class="required">
								<th>월 당 요금</th>
								<td>
									<input type="number" formControlName="priceMonth" placeholder="숫자만 입력해주세요"
										[class]="updateChk.priceMonth.invalid && updateChk.priceMonth.dirty ? 'error-invalid' : ''"
									>
									<div *ngIf="updateChk.priceMonth.dirty">
										<div class="error-invalid-feedback" *ngIf="updateChk.priceMonth.errors?.required">
											월 당 요금은 필수 값입니다.
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>				
				</div>
				<div class="btnArea tp1">
					<button type="submit" class="btn big orange mg_r4" [disabled]='!updateForm.valid'>수정</button>
					<a href="#close-modal" rel="modal:close" id="close-update" class="btn big orange">닫기</a>
				</div>
			</form>
		</div>
		<!-- //modal_content -->
	</div>
	<!-- //Modal -->


<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>삭제확인</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<div class="msg"></div>
		<div class="btnArea">
			<a class="btn big orange mg_r4" (click)="deleteService()">삭제</a>
			<a class="btn big orange" href="#close-modal" rel="modal:close" id="close-modal">취소</a>
		</div>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal --> 