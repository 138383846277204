<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Server</li>
      <li class="active">KeyPair</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let key of keypairLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons">vpn_key</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{key.keypair.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
            <tbody>
            <tr>
              <th>이름</th>
              <td>{{key.keypair.name}}</td>
            </tr>
            <tr>
              <th>설명</th>
              <td>{{key.keypair.description?key.keypair.description:"없음"}}</td>
            </tr>
            <tr>
              <th>지문</th>
              <td>{{key.keypair.fingerprint}}</td>
            </tr>
            </tbody>
          </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>