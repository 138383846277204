
<!-- Modal : 규칙추가 모달 -->
<div id="rule_create" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>규칙 추가
            <p class="sub-txt">새로운 보안규칙을 정의합니다. </p>
        </h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">프로토콜</th>
                        <td>
                            <select [(ngModel)]="addRuleModalType" (change)="onRuleTypeChange($event.target.value)" name="" required>
                                <option value="all">모든 프로토콜</option>
                                <option value="tcp">TCP</option>
                                <option value="udp">UDP</option>
                                <option value="icmp">ICMP</option>
                                <option value="ssh">SSH</option>
                                <option value="http">HTTP</option>
                                <option value="https">HTTPS</option>
                                <!-- <option value="ipv6-icmp">ipv6-icmp</option> -->
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">설명</th>
                        <td>
                            <textarea [(ngModel)]="newRuleData.description" (change)="onRuleDescriptionChange($event.target.value)" rows="5" placeholder="내용을 입력해주세요" maxlength="255"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">송/수신</th>
                        <td>
                            <select [(ngModel)]="newRuleData.direction" (change)="onRuleDirectionChange($event.target.value)" name="" required>
                                <option value="ingress" default>수신</option>
                                <option value="egress">송신</option>
                            </select>
                        </td>
                    </tr>
                    <!-- ICMP -->
                    <ng-template [ngIf]="addRuleModalType=='icmp'">
                        <tr>
                            <th scope="row">타입/코드</th>
                            <td>
                                <select [(ngModel)]="addRulePortType" (change)="onRulePortTypeChange($event.target.value)" name="" required>
                                    <option value="all_icmp">모든 ICMP 통신</option>
                                    <option value="one_icmp">직접입력</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="addRulePortType =='one_icmp'">
                            <th scope="row">ICMP Type</th>
                            <td>
                                <input [(ngModel)]="newRuleData.from_port" (change)="onRuleFromPortChange($event.target.value)" type="number" name="" id=""  placeholder="내용을 입력해주세요">
                            </td>
                        </tr>
                        <tr *ngIf="addRulePortType =='one_icmp'">
                            <th scope="row">ICMP Code</th>
                            <td>
                                <input [(ngModel)]="newRuleData.to_port" (change)="onRuleToPortChange($event.target.value)" type="number" name="" id="" placeholder="내용을 입력해주세요">
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template [ngIf]="addRuleModalType=='tcp' || addRuleModalType=='udp'">
                        <tr>
                            <th scope="row">포트</th>
                            <td>
                                <select [(ngModel)]="addRulePortType" (change)="onRulePortTypeChange($event.target.value)" name="" required>
                                    <option value="one_port">단일포트</option>
                                    <option value="many_port">포트범위</option>
                                    <option value="all_port">모든포트</option>
                                </select>
                                <label *ngIf="addRulePortType =='all_port'">입력한 규칙에 모든 포트를 공개합니다. </label>
                            </td>
                        </tr>
                        <!-- 포트 일 때 -->
                        <tr *ngIf="addRulePortType =='one_port'">
                            <th scope="row">허용 포트</th>
                            <td>
                                <input (change)="onRuleFromPortChange($event.target.value)" 
                                        (change)="onRuleToPortChange($event.target.value)" 
                                        type="number" name="" id="" [(ngModel)]="newRuleData.from_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                        <!-- 포트범위 일 때 -->
                        <tr *ngIf="addRulePortType =='many_port'">
                            <th scope="row">시작</th>
                            <td>
                                <input (change)="onRuleFromPortChange($event.target.value)" type="number" name="" id="" [(ngModel)]="newRuleData.from_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                        <tr *ngIf="addRulePortType =='many_port'">
                            <th scope="row">끝</th>
                            <td>
                                <input (change)="onRuleToPortChange($event.target.value)" type="number" name="" id="" [(ngModel)]="newRuleData.to_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template [ngIf]="addRuleModalType!='all' && addRuleModalType!='tcp'&& addRuleModalType!='udp'&& addRuleModalType!='icmp'">
                        <tr>
                            <th scope="row">허용 포트</th>
                            <td>
                                <input readonly type="number" name="" id="" [(ngModel)]="newRuleData.from_port" placeholder="1 ~ 65535">
                            </td>
                        </tr>
                    </ng-template>
                    <!-- 공통 -->
                    <tr>
                        <th scope="row">CIDR </th>
                        <td>
                            <input (change)="onRuleCidrChange($event.target.value)" type="text" name="" id="" [(ngModel)]="newRuleData.cidr" placeholder="0.0.0.0/0">
                            
                            <div *ngIf="cidr_validate==false" class="invalid-feedback">
                                <div>유효하지않은 CIDR 입니다.</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="addRule()" class="btn big ciblue mg_r4">추가</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->