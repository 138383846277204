import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';

declare function fn_modalOpen(params: string);

@Component({
  selector: 'app-console-commercial-dbms-setting',
  templateUrl: './console-commercial-dbms-setting.component.html',
})
export class ConsoleCommercialDbmsSettingComponent implements OnInit {

  optionList: any = ['옵션1', '옵션2', '옵션3'];  
  file_name: any = {
    field_name0: '',
    field_name1: '',
    field_name2: '',
    field_name3: '',
  };

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,) { }

  ngOnInit(): void {
  }

  onSubmit(params){
    fn_modalOpen('modal_id');
  }
}
