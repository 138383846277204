<!-- Contents -->
<div id="Contents" class="dashboard">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>대시보드</h2>
        <div class="lineMap">
            Dashboard / <span>Main</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec">
            <div class="sec_content row">
                <!-- lyBox -->
                <div class="lyBox col col6 consoleSubBody">
                    <div class="titArea">
                        <h3>이용 중인 서비스</h3>
                    </div>
                    <div class="contArea">
                        <div class="boardBoxSub">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:45%">
                                    <col style="width:35%">
                                </colgroup>
                                <tbody>
                                    <tr *ngIf="usingService.project >0">
                                        <!-- <th scope="row">Project</th> -->
                                        <td><label class="btn tiny blue radius">Project</label></td>
                                        <td>활성 프로젝트</td>
                                        <td>{{usingService.project}} 개</td>
                                    </tr>
                                    <tr *ngIf="usingService.server_instance >0">
                                        <!-- <th scope="row">Server</th> -->
                                        <td><label class="btn tiny blue radius">Server</label></td>
                                        <td>Instance</td>
                                        <td>{{usingService.server_instance}} 대</td>
                                    </tr>
                                    <tr *ngIf="usingService.server_image >0">
                                        <!-- <th scope="row">Server</th> -->
                                        <td><label class="btn tiny blue radius">Server</label></td>
                                        <td>Image</td>
                                        <td>{{usingService.server_image}} 대</td>
                                    </tr>
                                    <tr *ngIf="usingService.storage_block >0">
                                        <!-- <th scope="row">Storage</th> -->
                                        <td><label class="btn tiny blue radius">Storage</label></td>
                                        <td>Block Storage</td>
                                        <td>{{usingService.storage_block}} 개</td>
                                    </tr>
                                    <tr *ngIf="usingService.storage_snapshot >0">
                                        <!-- <th scope="row">Storage</th> -->
                                        <td><label class="btn tiny blue radius">Storage</label></td>
                                        <td>Snapshot</td>
                                        <td>{{usingService.storage_snapshot}} 개</td>
                                    </tr>
                                    <tr *ngIf="usingService.network_public_ip >0">
                                        <!-- <th scope="row">Network</th> -->
                                        <td><label class="btn tiny blue radius">Network</label></td>
                                        <td>Public IP</td>
                                        <td>{{usingService.network_public_ip}} 개</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <!-- <div class="lyBox col col4">
                        <div class="titArea">
                            <h3>7월이용 요금<span class="subTit">(6/29 00:01 기준)</span></h3>
                        </div>
                        <div class="contArea"> 
                            <div class="priceArea">
                                <p class="tit">7월</p>
                                <p>128,000<em>원</em></p>
                            </div>
                        </div>
                    </div> -->
                <!-- //lyBox -->

                <!-- lyBox -->
                <div class="lyBox col col6 consoleSubBody">
                    <div class="titArea">
                        <h3>공지사항</h3>
                    </div>
                    <div class="contArea">
                        <div class="boardBoxSub">
                            <table class="tbl_row_tp1 pdTp3">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:50%">
                                    <col style="width:30%">
                                </colgroup>
                                <tbody>
                                    <tr *ngIf="noticeList.length === 0">
                                        <td colspan=3>등록 된 공지사항이 없습니다.</td>
                                    </tr>
                                    <!-- <tr *ngIf="noticeList.length > 0" style="background-color: #2c9cc6; color:white;">
                                        <td></td>
                                        <td class="l"><a class="btn_console" href="http://service.bizlinkersys.com/" target="_blank" style="color:white; font-weight: 500; font-size: 14px;">베타서비스 오픈</a></td>
                                        <td style="color:white; font-weight: 500;">2021-08-13</td>
                                    </tr> -->
                                    <tr *ngFor="let notice of noticeList">
                                        <!-- <th scope="row"><label class="btn tiny dGray radius">{{notice.category}}</label></th> -->
                                        <th scope="row">{{notice.category}}</th>
                                        <td class="l">
                                            <a (click)="linkNotice(notice.articleId)" class="link">{{notice.title}}</a>
                                        </td>
                                        <td>{{ notice.createdDate.substring(0, 10) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="lyBox col col12 consoleMainBody">
                    <div class="titArea">
                        <h3>모니터링</h3>
                    </div>
                    <div class="contArea">
                        <div class="boardBoxSub">
                            <ejs-chart #chartNetwork [primaryXAxis]='primaryXAxis'[primaryYAxis]='primaryYAxis' [title]='chartTitleNetwork'>
                                <e-series-collection>
                                    <e-series [dataSource]='networkChartData' type='Area' xName='x' yName='y' name='Product A' fill='#69D2E7' opacity=0.6></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div> -->
                <div class="lyBox col col12 consoleMainBody">
                    <div class="titArea">
                        <h3>자원 현황</h3>
                        <button class="btn mid dBlue ab_r" (click)="chartRefresh()"><i class="ico ico_reset_white"></i>새로고침</button>
                    </div>
                    <div class="contArea">
                        <div class="boardBoxMain">
                            <table class="tbl_row_tp1 pdTp3">
                                <colgroup>
                                    <col style="width:100%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="" style="width:100%; display:inline-block;">
                                                <div class="col col3">
                                                    <ejs-accumulationchart id="" #chart [legendSettings]='legend' [enableBorderOnMouseMove]="false"
                                                        style='display:inline-block;width: 90%' [tooltip]="tooltip" (textRender)="onCpuRender($event)"
                                                        [title]="chartTitlevCPU" (load)='load($event)' >
                                                        <e-accumulation-series-collection>
                                                            <e-accumulation-series name='Profit' [dataSource]='cpuData'
                                                                xName='x' yName='y' [dataLabel]="dataLabel" [pointColorMapping]= 'map' 
                                                                [emptyPointSettings]='emptyPointSettings'>
                                                            </e-accumulation-series>
                                                        </e-accumulation-series-collection>
                                                    </ejs-accumulationchart>
                                                </div>

                                                <div class="col col3">
                                                    <ejs-accumulationchart id="" #diskchart [legendSettings]='legend' [enableBorderOnMouseMove]="false"
                                                        style='display:inline-block;width: 90%' [tooltip]="tooltip" (textRender)="onDiskRender($event)"
                                                        [title]="chartTitleDISK" (load)='load($event)'>
                                                        <e-accumulation-series-collection>
                                                            <e-accumulation-series name='Profit' [dataSource]='diskData'
                                                                xName='x' yName='y' [dataLabel]="dataLabel" [pointColorMapping]= 'map'
                                                                [emptyPointSettings]='emptyPointSettings'>
                                                            </e-accumulation-series>
                                                        </e-accumulation-series-collection>
                                                    </ejs-accumulationchart>
                                                </div>
                                                <div class="col col3">
                                                    <ejs-accumulationchart id="" #memchart [legendSettings]='legend' [enableBorderOnMouseMove]="false"
                                                        style='display:inline-block;width: 90%' [tooltip]="tooltip" (textRender)="onMemRender($event)"
                                                        [title]="chartTitleRAM" (load)='load($event)'>
                                                        <e-accumulation-series-collection>
                                                            <e-accumulation-series name='Profit' [dataSource]='memData'
                                                                xName='x' yName='y' [dataLabel]="dataLabel" [pointColorMapping]= 'map'
                                                                [emptyPointSettings]='emptyPointSettings'>
                                                            </e-accumulation-series>
                                                        </e-accumulation-series-collection>
                                                    </ejs-accumulationchart>
                                                </div>
                                                <div class="col col3">
                                                    <ejs-accumulationchart id="" #ipchart [legendSettings]='legend' [enableBorderOnMouseMove]="false"
                                                        style='display:inline-block;width: 90%' [tooltip]="tooltip" (textRender)="onIpRender($event)"
                                                        [title]="chartTitleIp" (load)='load($event)'>
                                                        <e-accumulation-series-collection>
                                                            <e-accumulation-series name='Profit' [dataSource]='fipData'
                                                                xName='x' yName='y' [dataLabel]="dataLabel" [pointColorMapping]= 'map'
                                                                [emptyPointSettings]='emptyPointSettings'>
                                                            </e-accumulation-series>
                                                        </e-accumulation-series-collection>
                                                    </ejs-accumulationchart>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->

                <!-- lyBox -->
                <!-- <div class="lyBox col col4">
                        <div class="titArea">
                            <h3>서비스 신청 현황</h3>
                        </div>
                        <div class="contArea"> 
                            <div class="boardBox">
                                <table class="tbl_row_tp1">
                                    <colgroup>
                                        <col style="width:25%">
                                        <col style="width:55%">
                                        <col style="width:20%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th scope="row">서버백업</th>
                                            <td><a href="#this" class="link">'Server1' 서버 백업 신청</a></td>
                                            <td><label class="btn tiny dGray radius">대기</label></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">상용DB</th>
                                            <td><a href="#this" class="link">'db2' Tibero DB 서비스 신청</a></td>
                                            <td><label class="btn tiny blue radius">완료</label></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">서버백업</th>
                                            <td><a href="#this" class="link">'Server1' 서버 백업 신청</a></td>
                                            <td><label class="btn tiny dGray radius">대기</label></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">상용DB</th>
                                            <td><a href="#this" class="link">'db2' Tibero DB 서비스 신청</a></td>
                                            <td><label class="btn tiny blue radius">완료</label></td>  
                                        </tr> -->
                <!-- <tr>
                                            <th scope="row">서버백업</th>
                                            <td><a href="#this" class="link">'Server1' 서버 백업 신청</a></td>
                                            <td>대기</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">상용DB</th>
                                            <td><a href="#this" class="link">'db2' Tibero DB 서비스 신청</a></td>
                                            <td><span class="txt_red">완료</span></td>
                                        </tr> -->
                <!-- </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                <!-- //lyBox -->

                <!-- lyBox -->
                <!-- <div class="lyBox col col4">
                        <div class="titArea">
                            <h3>네트워크 트래픽 이용 추세</h3>
                        </div>
                        <div class="contArea tpA"> 
                            <div class="imgArea">
                                <img src="../../../assets/img/console/img_dashboard_03.jpg" alt="">
                            </div>
                            <div class="btnArea">
                                <p>Outbound 용량 100GB</p>
                            </div>
                        </div>
                    </div> -->
                <!-- //lyBox -->

                <!-- lyBox -->
                <!-- <div class="lyBox col col4">
                            <div class="titArea">
                                <h3 >새로 시작하기</h3>
                            </div>
                            <div class="contArea tpB">
                                <div class="bannerArea">
                                    <ul class="bannerSlider">
                                        <li>
                                            <div class="cont c">
                                                <h3><i class="ico ico_server"></i>서버</h3><br>
                                                비즈니스 환경에 맞춰 원하는 컴퓨팅 리소스를 빠르게 생성하여 효율적으로 활용할 수 있는 서비스를 제공합니다.
                                            </div>
                                            <div class="btnArea">
                                                <a (click)="linkServerCreate($event)" class="btn mid blue">서비스 신청</a>
                                            </div>
                                        </li>
        
                                        <li>
                                            <div class="cont c">
                                                <h3><i class="ico ico_storage"></i>스토리지</h3><br>
                                                데이터를 인터넷에 저장하여 서비스로 관리하고 운영할 수 있습니다.<br>
                                                언제 어디서든 데이터에 액세스할 수 있습니다.
                                            </div>
                                            <div class="btnArea">
                                                <a class="btn mid blue" (click)="linkServerCreate($event)">서비스 신청</a>
                                            </div>
                                        </li>
        
                                        <li>
                                            <div class="cont c">
                                                <h3><i class="ico ico_backup"></i>데이터 백업</h3><br>
                                                서버 및 데이터베이스의 데이터를 안전하게 보관하기 위한 별도 백업 솔루션 서비스 입니다.
                                            </div>
                                            <div class="btnArea">
                                                <a class="btn mid blue" (click)="linkServerCreate($event)">서비스 신청</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                <!-- //lyBox -->

                <!-- lyBox -->
                <!-- <div class="lyBox col col4">
                        <div class="titArea">
                            <h3>차트 1</h3>
                        </div>
                        <div class="contArea tpA"> 
                            <div class="imgArea">
                                <img src="../../../assets/img/console/img_dashboard_01.jpg" alt="">
                            </div>
                        </div>
                    </div> -->
                <!-- //lyBox -->

                <!-- lyBox -->
                <!-- <div class="lyBox col col4">
                        <div class="titArea">
                            <h3>차트 2</h3>
                        </div>
                        <div class="contArea tpA"> 
                            <div class="imgArea">
                                <img src="../../../assets/img/console/img_dashboard_02.jpg" alt="">
                            </div>
                        </div>
                    </div> -->
                <!-- //lyBox -->

            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->
</div>
<!-- //Contents -->