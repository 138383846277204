import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-console-baremetal-setting',
  templateUrl: './console-baremetal-setting.component.html',
})
export class ConsoleBaremetalSettingComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {

  }  
}
