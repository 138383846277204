		<!-- Container -->
		<div id="Container" class="servicePrice">
			<div class="visual tp1">
				<div class="cont_inner">
					<h2>서비스별 요금</h2>
				</div>
			</div>
			<div>
				<!-- section -->
				<section class="sec sec_1">
					<!-- sec_inner -->
					<div class="sec_inner">
						<!-- sec_header -->
						<div class="sec_header">
							<h3>서비스별 요금</h3>
							<div class="subTit">
								각 서비스 별로 요금을 확인해 볼 수 있습니다.
							</div>
						</div>
						<!-- //sec_header -->
						
						<!-- sec_content -->
						<div class="sec_content">
							<ul class="priceList">
								<li class="map_server">
									<h4>Server</h4>
									<div class="subTit">
										비즈니스 환경에 맞춰 원하는 컴퓨팅 리소스를 빠르게 생성하여 효율적으로 활용할 수 있는 서비스를 제공합니다.
										<br>애플리케이션이나 웹 사이트를 구축하는데 필요한 서버를 수 분만에 생성하여 운영할 수 있습니다.
									</div>
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:15%">
												<col >
												<col style="width:8%">
												<col style="width:8%">
												<col style="width:8%">
												<col style="width:12%">
												<col style="width:12%">
												<col style="width:12%">
											</colgroup>
											<thead>
												<tr>
													<th scope="col" rowspan="2">분류</th>
													<th scope="col" rowspan="2">서비스(상품)명</th>
													<th scope="col" colspan="3">사양</th>
													<th scope="col" colspan="3">요금(단위:원, 부가세 별도)</th>
												</tr>
												<tr>
													<th scope="col">vCPU</th>
													<th scope="col">Memory(GB)</th>
													<th scope="col">Disk(GB)</th>
													<th scope="col">시간</th>
													<th scope="col">일</th>
													<th scope="col">월</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="38">Compute</td>
													<td>Server-c1m1H50</td>
													<td>1</td>
													<td>1</td>
													<td>50</td>
													<td>25</td>
													<td>600</td>
													<td>18,000 </td>
												</tr>
												<tr>
													<td>Server-c1m2H50</td>
													<td>1</td>
													<td>2</td>
													<td>50</td>
													<td>47</td>
													<td>1,128</td>
													<td>33,840</td>
												</tr>
												<tr>
													<td>Server-c2m2H50</td>
													<td>2</td>
													<td>2</td>
													<td>50</td>
													<td>64</td>
													<td>1,536</td>
													<td>46,080</td>
												</tr>
												<tr>
													<td>Server-c2m2H100</td>
													<td>2</td>
													<td>2</td>
													<td>100</td>
													<td>71</td>
													<td>1,704</td>
													<td>51,120</td>
												</tr>
												<tr>
													<td>Server-c2m4H50</td>
													<td>2</td>
													<td>4</td>
													<td>50</td>
													<td>98</td>
													<td>2,352</td>
													<td>70,560</td>
												</tr>
												<tr>
													<td>Server-c2m4H100</td>
													<td>2</td>
													<td>4</td>
													<td>100</td>
													<td>105</td>
													<td>2,520</td>
													<td>75,600</td>
												</tr>
												<tr>
													<td>Server-c2m8H50</td>
													<td>2</td>
													<td>8</td>
													<td>50</td>
													<td>131</td>
													<td>3,144</td>
													<td>94,320</td>
												</tr>
												<tr>
													<td>Server-c2m8H100</td>
													<td>2</td>
													<td>8</td>
													<td>100</td>
													<td>138</td>
													<td>3,312</td>
													<td>99,360</td>
												</tr>
												<tr>
													<td>Server-c2m16H50</td>
													<td>2</td>
													<td>16</td>
													<td>50</td>
													<td>219</td>
													<td>5,256</td>
													<td>157,680</td>
												</tr>
												<tr>
													<td>Server-c2m16H100</td>
													<td>2</td>
													<td>16</td>
													<td>100</td>
													<td>226</td>
													<td>5,424</td>
													<td>162,720</td>
												</tr>
												<tr>
													<td>Server-c4m4H50</td>
													<td>4</td>
													<td>4</td>
													<td>50</td>
													<td>123</td>
													<td>2,952</td>
													<td>88,560</td>
												</tr>
												<tr>
													<td>Server-c4m4H100</td>
													<td>4</td>
													<td>4</td>
													<td>100</td>
													<td>130</td>
													<td>3,120</td>
													<td>93,600</td>
												</tr>
												<tr>
													<td>Server-c4m8H50</td>
													<td>4</td>
													<td>8</td>
													<td>50</td>
													<td>200</td>
													<td>4,800</td>
													<td>144,000</td>
												</tr>
												<tr>
													<td>Server-c4m8H100</td>
													<td>4</td>
													<td>8</td>
													<td>100</td>
													<td>207</td>
													<td>4,968</td>
													<td>149,040</td>
												</tr>
												<tr>
													<td>Server-c4m16H50</td>
													<td>4</td>
													<td>16</td>
													<td>50</td>
													<td>260</td>
													<td>6,240</td>
													<td>187,200</td>
												</tr>
												<tr>
													<td>Server-c4m16H100</td>
													<td>4</td>
													<td>16</td>
													<td>100</td>
													<td>267</td>
													<td>6,408</td>
													<td>192,240</td>
												</tr>
												<tr>
													<td>Server-c4m32H50</td>
													<td>4</td>
													<td>32</td>
													<td>50</td>
													<td>431</td>
													<td>10,344</td>
													<td>310,320</td>
												</tr>
												<tr>
													<td>Server-c4m32H100</td>
													<td>4</td>
													<td>32</td>
													<td>100</td>
													<td>438</td>
													<td>10,512</td>
													<td>315,360</td>
												</tr>
												<tr>
													<td>Server-c8m8H50</td>
													<td>8</td>
													<td>8</td>
													<td>50</td>
													<td>239</td>
													<td>5,736</td>
													<td>172,080</td>
												</tr>
												<tr>
													<td>Server-c8m8H100</td>
													<td>8</td>
													<td>8</td>
													<td>100</td>
													<td>246</td>
													<td>5,904</td>
													<td>177,120</td>
												</tr>
												<tr>
													<td>Server-c8m16H50</td>
													<td>8</td>
													<td>16</td>
													<td>50</td>
													<td>400</td>
													<td>9,600</td>
													<td>288,000</td>
												</tr>
												<tr>
													<td>Server-c8m16H100</td>
													<td>8</td>
													<td>16</td>
													<td>100</td>
													<td>407</td>
													<td>9,768</td>
													<td>293,040</td>
												</tr>
												<tr>
													<td>Server-c8m32H50</td>
													<td>8</td>
													<td>32</td>
													<td>50</td>
													<td>525</td>
													<td>12,600</td>
													<td>378,000</td>
												</tr>
												<tr>
													<td>Server-c8m32H100</td>
													<td>8</td>
													<td>32</td>
													<td>100</td>
													<td>532</td>
													<td>12,768</td>
													<td>383,040</td>
												</tr>
												<!-- <tr>
													<td>Server-c10m64H50</td>
													<td>10</td>
													<td>64</td>
													<td>50</td>
													<td>1,054</td>
													<td>25,296</td>
													<td>758,880</td>
												</tr>
												<tr>
													<td>Server-c10m64H100</td>
													<td>10</td>
													<td>64</td>
													<td>100</td>
													<td>1,061</td>
													<td>25,464</td>
													<td>763,920</td>
												</tr> -->
												<tr>
													<td>Server-c12m12H50</td>
													<td>12</td>
													<td>12</td>
													<td>50</td>
													<td>355</td>
													<td>8,520</td>
													<td>255,600</td>
												</tr>
												<tr>
													<td>Server-c12m12H100</td>
													<td>12</td>
													<td>12</td>
													<td>100</td>
													<td>362</td>
													<td>8,688</td>
													<td>260,640</td>
												</tr>
												<tr>
													<td>Server-c12m16H50</td>
													<td>12</td>
													<td>16</td>
													<td>50</td>
													<td>399</td>
													<td>9,576</td>
													<td>287,280</td>
												</tr>
												<tr>
													<td>Server-c12m16H100</td>
													<td>12</td>
													<td>16</td>
													<td>100</td>
													<td>406</td>
													<td>9,744</td>
													<td>292,320</td>
												</tr>
												<tr>
													<td>Server-c12m32H50</td>
													<td>12</td>
													<td>32</td>
													<td>50</td>
													<td>763</td>
													<td>18,312</td>
													<td>549,360</td>
												</tr>
												<tr>
													<td>Server-c12m32H100</td>
													<td>12</td>
													<td>32</td>
													<td>100</td>
													<td>770</td>
													<td>18,480</td>
													<td>554,400</td>
												</tr>
												<tr>
													<td>Server-c12m64H50</td>
													<td>12</td>
													<td>64</td>
													<td>50</td>
													<td>990</td>
													<td>23,760</td>
													<td>712,800</td>
												</tr>
												<tr>
													<td>Server-c12m64H100</td>
													<td>12</td>
													<td>64</td>
													<td>100</td>
													<td>997</td>
													<td>23,928</td>
													<td>717,840</td>
												</tr>
												<tr>
													<td>Server-c16m16H50</td>
													<td>16</td>
													<td>16</td>
													<td>50</td>
													<td>487</td>
													<td>11,688</td>
													<td>350,640</td>
												</tr>
												<tr>
													<td>Server-c16m16H100</td>
													<td>16</td>
													<td>16</td>
													<td>100</td>
													<td>494</td>
													<td>11,856</td>
													<td>355,680</td>
												</tr>
												<tr>
													<td>Server-c16m32H50</td>
													<td>16</td>
													<td>32</td>
													<td>50</td>
													<td>810</td>
													<td>19,440</td>
													<td>583,200</td>
												</tr>
												<tr>
													<td>Server-c16m32H100</td>
													<td>16</td>
													<td>32</td>
													<td>100</td>
													<td>817</td>
													<td>19,608</td>
													<td>588,240</td>
												</tr>
												<tr>
													<td>Server-c16m64H50</td>
													<td>16</td>
													<td>64</td>
													<td>50</td>
													<td>1030</td>
													<td>24,720</td>
													<td>741,600</td>
												</tr>
												<tr>
													<td>Server-c16m64H100</td>
													<td>16</td>
													<td>64</td>
													<td>100</td>
													<td>1037</td>
													<td>24,888</td>
													<td>746,640</td>
												</tr>
												

											</tbody>
										</table>
									</div>
								</li>

								<li class="map_os">
									<h4>OS</h4>
									<!-- <div class="subTit">
										비즈니스 환경에 맞춰 원하는 컴퓨팅 리소스를 빠르게 생성하여 효율적으로 활용할 수 있는 서비스를 제공합니다.
										<br>애플리케이션이나 웹 사이트를 구축하는데 필요한 서버를 수 분만에 생성하여 운영할 수 있습니다.
									</div> -->
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:8%">
												<col style="width:8%">
												<col >
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:6%">
												<col style="width:8%">
											</colgroup>
											<thead>
												<tr>
													<th scope="col" colspan="2" rowspan="3">분류</th>
													<th scope="col" colspan="2" rowspan="2">서비스</th>
													<th scope="col" colspan="5">운영체제 사용가능 여부</th>
													<th scope="col" colspan="3" rowspan="2">요금(단위:원, 부가세 별도)</th>
												</tr>
												<tr>
													<th scope="col" colspan="3">메모리 기준</th>
													<th scope="col" colspan="2">CPU 기준</th>
												</tr>
												<tr>
													<th scope="col">상품명</th>
													<th scope="col">비트</th>
													<th scope="col">32GB 이하</th>
													<th scope="col">64~128GB</th>
													<th scope="col">256GB</th>
													<th scope="col">16 vCPU이하</th>
													<th scope="col">32 vCPU 이상</th>
													<th scope="col">시간</th>
													<th scope="col">일</th>
													<th scope="col">월</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="8">Compute</td>
													<td rowspan="6">Linux</td>
													<td>CentOS 7.4</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>0</td>
													<!-- <td></td> -->
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>CentOS 7.8</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>0</td>
													<!-- <td></td> -->
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>CentOS 8.2</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>0</td>
													<!-- <td></td> -->
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>Ubuntu Server 16.04</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>0</td>
													<!-- <td></td> -->
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>Ubuntu Server 18.04</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>0</td>
													<!-- <td></td> -->
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>Ubuntu Server 20.04</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>0</td>
													<!-- <td></td> -->
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td rowspan="2">Windows</td>
													<td>Windows Server 2012 Standard</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>28</td>
													<td>672</td>
													<td>20,160</td>
												</tr>
												<tr>
													<td>Windows Server 2016 Standard</td>
													<td>64</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>O</td>
													<td>28</td>
													<td>672</td>
													<td>20,160</td>
												</tr>
											</tbody>
										</table>
									</div>
								</li>
								<!--<br>* 네트워크 트래픽 사용량에 따라 과금
								<br><br>* 서버 정지 시 요금
								-->
								<li class="map_storage">
									<h4>Storage, BackUp</h4>
									<div class="subTit">
										데이터를 인터넷에 저장하여 서비스로 관리하고 운영할 수 있습니다. 용량 및 비용을 온디맨드로 제공하여 자체 데이터 스토리지 인프라를 구매하거나<br>
										관리할 필요가 없습니다. 언제 어디서든 데이터에 액세스할 수 있습니다.
									</div>
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:9%">
												<col style="width:9%">
												<col style="width:9%">
												<col >
												<col style="width:11%">
												<col style="width:9%">
												<col style="width:9%">
												<col style="width:9%">
												<col style="width:9%">
												
											</colgroup>
											<thead>
												<tr>
													<th scope="col" colspan="3" rowspan="2">분류</th>
													<th scope="col" colspan="3">서비스</th>
													<th scope="col" colspan="3">요금(단위:원, 부가세 별도)</th>
												</tr>

												<tr>
													<th scope="col">상품명</th>
													<th scope="col">최소신청/구간</th>
													<th scope="col">단위</th>
													<th scope="col">시간</th>
													<th scope="col">일</th>
													<th scope="col">월</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="3">Storage</td>
													<td>Block</td>
													<td>HDD</td>
													<td>블록 스토리지</td>
													<td></td>
													<td>10GB 당</td>
													<td>1.4</td>
													<td>34</td>
													<td>1,008</td>
												</tr>
												<tr>
													<td>NAS</td>
													<td>HDD</td>
													<td>NAS HDD</td>
													<td>300GB</td>
													<td>100GB 당</td>
													<td>14.0</td>
													<td>336</td>
													<td>10,080</td>
												</tr>
												<tr>
													<td>Object<br>Storage</td>
													<td></td>
													<td>
														오브젝트 스토리지<br>
														*Beta Service
													</td>
													<td></td>
													<td>-</td>
													<td>-</td>
													<td>-</td>
													<td>-</td>
												</tr>
												<tr>
													<td rowspan="2">BackUp</td>
													<td rowspan="2">Image</td>
													<td>Server</td>
													<td>서버 이미지</td>
													<td></td>
													<td>10GB 당</td>
													<td>1.4</td>
													<td>34</td>
													<td>1,008</td>
												</tr>
												<tr>
													<td>Volume</td>
													<td>스토리지 스냅샷</td>
													<td></td>
													<td>10GB 당</td>
													<td>1.4</td>
													<td>34</td>
													<td>1,008</td>
												</tr>
												<!-- <tr>
													<td rowspan="2">Object</td>
													<td></td>
													<td>HDD</td>
													<td></td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>30</td>
												</tr>
												<tr>
													<td>네트워크 전송 요금</td>
													<td>아웃바운드 트래픽; 3.8GB 사용시 3GB로 계상</td>
													<td></td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>100</td>
												</tr> -->

												<!-- <tr>
													<td>Server</td>
													<td>Commvault BackUp</td>
													<td>100GB 이하는 기본요금 30,240원</td>
													<td>100GB</td>
													<td>10GB 당</td>
													<td>4.2</td>
													<td>101</td>
													<td>3,024</td>
												</tr>
												<tr>
													<td>DB</td>
													<td>Commvault BackUp</td>
													<td>100GB 이하는 기본요금 30,240원</td>
													<td>100GB</td>
													<td>10GB 당</td>
													<td>4.2</td>
													<td>101</td>
													<td>3,024</td>
												</tr> -->
											</tbody>
										</table>
									</div>
								</li>

								<li class="map_network">
									<h4>Network</h4>
									<div class="subTit">
										사용자가 정의한 네트워크를 기반으로 컴퓨팅 리소스를 배치할 수 있도록하여 사용자의 편의를 높였습니다.<br>
										온프레미스(IDC)에서 사용하던 네트워크 구성을 그대로 구현하여 사용할 수 있습니다.										
									</div>
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:9%">
												<col style="width:10%">
												<col style="width:9%">
												<col >
												<col style="width:11%">
												<col style="width:9%">
												<col style="width:9%">
												<col style="width:9%">
												<col style="width:9%">
												
											</colgroup>
											<thead>
												<tr>
													<th scope="col" colspan="3" rowspan="2">분류</th>
													<th scope="col" colspan="3">서비스</th>
													<th scope="col" colspan="3">요금(단위:원, 부가세 별도)</th>
												</tr>

												<tr>
													<th scope="col">상품명</th>
													<th scope="col">최소신청/구간</th>
													<th scope="col">단위</th>
													<th scope="col">시간</th>
													<th scope="col">일</th>
													<th scope="col">월</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="10">Network</td>
													<td rowspan="5">Internet</td>
													<td>Inbound</td>
													<td>인터넷 인바운드 트래픽</td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td>0</td>
												</tr>
												<tr>
													<td rowspan=4>Outbound</td>
													<td rowspan=4>인터넷 아웃바운드 트래픽</td>
													<td>0~1TB</td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>0</td>
												</tr>
												<tr>
													<td>1~10TB</td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>100</td>
												</tr>
												<tr>
													<td>10~50TB</td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>100</td>
												</tr>
												<tr>
													<td>50TB~</td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>100</td>
												</tr>
												<tr>
													<td>Private</td>
													<td>Internal</td>
													<td>사설IP 내부 트래픽</td>
													<td></td>
													<td>1GB 당</td>
													<td></td>
													<td></td>
													<td>0</td>
												</tr>
												<tr>
													<td>Internet</td>
													<td>IP</td>
													<td>공인 IP</td>
													<td></td>
													<td>개 당</td>
													<td>7.0 </td>
													<td>168 </td>
													<td>5,040 </td>
												</tr>
												<tr>
													<td rowspan=3>Loadbalancer</td>
													<td>Basic</td>
													<td>LB 2vCpu 2GB MEM </td>
													<td></td>
													<td>개 당</td>
													<td>26.0</td>
													<td>624</td>
													<td>18,720</td>
												</tr>
												<tr>
													<td>Standard</td>
													<td>LB 4vCpu 4GB MEM </td>
													<td></td>
													<td>개 당</td>
													<td>52.0 </td>
													<td>1,248 </td>
													<td>37,440 </td>
												</tr>
												<tr>
													<td>Premium</td>
													<td>LB 8vCpu 8GB MEM </td>
													<td></td>
													<td>개 당</td>
													<td>91.0 </td>
													<td>2,184 </td>
													<td>65,520 </td>
												</tr>												
											</tbody>
										</table>
									</div>
									<div class="subTit">
										* 로드밸런서의 경우 트래픽 사용량에 따라 추가 요금 발생 가능
									</div>
								</li> <br>
								<li class="map_database">
									<h4>DataBase</h4>
									<div class="subTit">
										고객 서비스 요구사항에 맞는 다양한 데이터베이스 플랫폼을 제공합니다.
									</div>
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:12%">
												<col style="width:12%">
												<col >
												<col style="width:10%">
												<col style="width:10%">
												<col style="width:10%">
												
											</colgroup>
											<thead>
												<tr>
													<th scope="col" colspan="2" rowspan="2">분류</th>
													<th scope="col" rowspan="2">서비스(상품)명</th>
													<th scope="col" colspan="3">요금(단위:원, 부가세 별도)</th>
												</tr>

												<tr>
													<th scope="col">시간</th>
													<th scope="col">일</th>
													<th scope="col">월</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="3">Database</td>
													<td>MySQL 5.6</td>
													<td>MySQL이 포함된 OS 제공 2 vCPU, 4GB MEM 이상, 100GB HDD 이상</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>MySQL 5.7</td>
													<td>MySQL이 포함된 OS 제공 2 vCPU, 4GB MEM 이상, 100GB HDD 이상</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>PostgreSQL 12.3</td>
													<td>PostgreSQL이 포함된 OS 제공 2 vCPU, 4GB MEM 이상, 100GB HDD 이상</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
										
											</tbody>
										</table>
									</div>
									<div class="subTit">
										* DBMS가 포함된 인스턴스를 제공하고 인스턴스 생성하여 제공
									</div>
								</li><br>
								
								<!-- <li>
									<h4 class="map_security">보안</h4>
									<div class="subTit">
										클라우드 영역에 방화벽 및 VPN을 제공하여, 가상 네트워크 환경에서 보안을 제공 합니다.
									</div>
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:15%">
												<col style="width:10%">
												<col style="width:10%">
												<col style="width:15%">
												<col style="width:15%">
												<col style="width:18%">
												<col style="width:*">
											</colgroup>
											<thead>
												<tr>
													<th scope="col" rowspan="2">타입</th>
													<th scope="col" colspan="3">제공 사양</th>
													<th scope="col" colspan="2">이용 요금</th>
													<th scope="col">비고</th>
												</tr>
												<tr>
								
													<th scope="col">vCPU</th>
													<th scope="col">메모리</th>
													<th scope="col">디스크</th>
													<th scope="col">시간</th>
													<th scope="col">월</th>
													<th scope="col">비고</th>
													
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="2">Compact</td>
													<td>1개</td>
													<td>2GB</td>
													<td>50GB</td>
													<td>37원</td>
													<td>26,000원</td>
													<td rowspan="2">
														개인 홈페이지 운영<br>소규모 웹사이트 운영
													</td>
												</tr>
												<tr>
													<td>2개</td>
													<td>2GB</td>
													<td>50GB / 100GB</td>
													<td>64 원/ 68 원</td>
													<td>46,000 원 / 48,880 원</td>
												</tr>
												<tr>
													<td rowspan="14">Compact</td>
													<td>2 개</td>
													<td>4GB</td>
													<td>50GB / 100GB</td>
													<td>96 원 / 100 원</td>
													<td>69,000 원 / 71,880 원</td>
													<td rowspan="14">중/대규모 모바일 및<br>웹 서비스 운영<br>게임/미디어 서비스 운영</td>
												</tr>
												<tr>
													<td>2 개</td>
													<td>8GB</td>
													<td>50GB / 100GB</td>
													<td>50GB / 100GB</td>
													<td>91,600 원 / 94,480 원</td>
												</tr>
												<tr>
													<td>2 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>128 원 / 132 원</td>
													<td>91,600 원 / 94,480 원</td>
												</tr>
												<tr>
													<td>2 개</td>
													<td>4GB</td>
													<td>50GB / 100GB</td>
													<td>120 원 / 124 원</td>
													<td>124,000 원 / 126,880 원</td>
												</tr>
												<tr>
													<td>4 개</td>
													<td>8GB</td>
													<td>50GB / 100GB</td>
													<td>174 원 / 178 원</td>
													<td>86,000 원 / 88,880 원</td>
												</tr>
												<tr>
													<td>4 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>255 원 / 259 원</td>
													<td>125,000 원 / 127,880 원</td>
												</tr>
												<tr>
													<td>4 개</td>
													<td>32GB</td>
													<td>50GB / 100GB</td>
													<td>345 원 / 349 원</td>
													<td>183,200 원 / 186,080 원</td>
												</tr>
												<tr>
													<td>8 개</td>
													<td>4GB</td>
													<td>50GB / 100GB</td>
													<td>237 원 / 241 원</td>
													<td>248,000 원 / 250,880 원</td>
												</tr>
												<tr>
													<td>8 개</td>
													<td>8GB</td>
													<td>50GB / 100GB</td>
													<td>317 원 / 321 원</td>
													<td>170,000 원 / 172,880 원</td>
												</tr>
												<tr>
													<td>8 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>509 원 / 513 원</td>
													<td>228,000 원 / 230,880 원</td>
												</tr>
												<tr>
													<td>12 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>362 원 / 366 원</td>
													<td>763 원 / 767 원</td>
												</tr>
												<tr>
													<td>12 개</td>
													<td>32GB</td>
													<td>50GB / 100GB</td>
													<td>459 원 / 463 원</td>
													<td>366,400 원 / 369,280 원</td>
												</tr>
												<tr>
													<td>16 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>807 원 / 811 원</td>
													<td>260,000 원 / 262,880 원</td>
												</tr>
												<tr>
													<td>16 개</td>
													<td>32GB</td>
													<td>50GB / 100GB</td>
													<td>807 원 / 811 원</td>
													<td>549,000 원 / 551,880 원</td>
												</tr>
											</tbody>
										</table>
									</div>
								</li>
								
								<li>
									<h4 class="map_monitoring">실시간 모니터링</h4>
									<div class="subTit">
										사용자가 정의한 네트워크를 기반으로 컴퓨팅 리소스를 배치할 수 있도록하여 사용자의 편의를 높였습니다.<br>
										온프레미스(IDC)에서 사용하던 네트워크 구성을 그대로 구현하여 사용할 수 있습니다.
									</div>
									<div class="board">
										<table class="tbl_col_tp2">
											<colgroup>
												<col style="width:15%">
												<col style="width:10%">
												<col style="width:10%">
												<col style="width:15%">
												<col style="width:15%">
												<col style="width:18%">
												<col style="width:*">
											</colgroup>
											<thead>
												<tr>
													<th scope="col" rowspan="2">타입</th>
													<th scope="col" colspan="3">제공 사양</th>
													<th scope="col" colspan="2">이용 요금</th>
													<th scope="col">비고</th>
												</tr>
												<tr>
								
													<th scope="col">vCPU</th>
													<th scope="col">메모리</th>
													<th scope="col">디스크</th>
													<th scope="col">시간</th>
													<th scope="col">월</th>
													<th scope="col">비고</th>
													
												</tr>
											</thead>
											<tbody>
												<tr>
													<td rowspan="2">Compact</td>
													<td>1개</td>
													<td>2GB</td>
													<td>50GB</td>
													<td>37원</td>
													<td>26,000원</td>
													<td rowspan="2">
														개인 홈페이지 운영<br>소규모 웹사이트 운영
													</td>
												</tr>
												<tr>
													<td>2개</td>
													<td>2GB</td>
													<td>50GB / 100GB</td>
													<td>64 원/ 68 원</td>
													<td>46,000 원 / 48,880 원</td>
												</tr>
												<tr>
													<td rowspan="14">Compact</td>
													<td>2 개</td>
													<td>4GB</td>
													<td>50GB / 100GB</td>
													<td>96 원 / 100 원</td>
													<td>69,000 원 / 71,880 원</td>
													<td rowspan="14">중/대규모 모바일 및<br>웹 서비스 운영<br>게임/미디어 서비스 운영</td>
												</tr>
												<tr>
													<td>2 개</td>
													<td>8GB</td>
													<td>50GB / 100GB</td>
													<td>50GB / 100GB</td>
													<td>91,600 원 / 94,480 원</td>
												</tr>
												<tr>
													<td>2 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>128 원 / 132 원</td>
													<td>91,600 원 / 94,480 원</td>
												</tr>
												<tr>
													<td>2 개</td>
													<td>4GB</td>
													<td>50GB / 100GB</td>
													<td>120 원 / 124 원</td>
													<td>124,000 원 / 126,880 원</td>
												</tr>
												<tr>
													<td>4 개</td>
													<td>8GB</td>
													<td>50GB / 100GB</td>
													<td>174 원 / 178 원</td>
													<td>86,000 원 / 88,880 원</td>
												</tr>
												<tr>
													<td>4 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>255 원 / 259 원</td>
													<td>125,000 원 / 127,880 원</td>
												</tr>
												<tr>
													<td>4 개</td>
													<td>32GB</td>
													<td>50GB / 100GB</td>
													<td>345 원 / 349 원</td>
													<td>183,200 원 / 186,080 원</td>
												</tr>
												<tr>
													<td>8 개</td>
													<td>4GB</td>
													<td>50GB / 100GB</td>
													<td>237 원 / 241 원</td>
													<td>248,000 원 / 250,880 원</td>
												</tr>
												<tr>
													<td>8 개</td>
													<td>8GB</td>
													<td>50GB / 100GB</td>
													<td>317 원 / 321 원</td>
													<td>170,000 원 / 172,880 원</td>
												</tr>
												<tr>
													<td>8 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>509 원 / 513 원</td>
													<td>228,000 원 / 230,880 원</td>
												</tr>
												<tr>
													<td>12 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>362 원 / 366 원</td>
													<td>763 원 / 767 원</td>
												</tr>
												<tr>
													<td>12 개</td>
													<td>32GB</td>
													<td>50GB / 100GB</td>
													<td>459 원 / 463 원</td>
													<td>366,400 원 / 369,280 원</td>
												</tr>
												<tr>
													<td>16 개</td>
													<td>16GB</td>
													<td>50GB / 100GB</td>
													<td>807 원 / 811 원</td>
													<td>260,000 원 / 262,880 원</td>
												</tr>
												<tr>
													<td>16 개</td>
													<td>32GB</td>
													<td>50GB / 100GB</td>
													<td>807 원 / 811 원</td>
													<td>549,000 원 / 551,880 원</td>
												</tr>
											</tbody>
										</table>
									</div>
								</li> -->
							</ul>
						</div>
						<!-- //sec_content -->
					</div>
					<!-- //sec_inner -->
				</section>
				<!-- //section -->

				
			</div>
		</div>
		<!-- //Container --><hr>