import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-security-group-rule-create',
  templateUrl: './dialog-security-group-rule-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogSecurityGroupRuleCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSecurityGroupRuleCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  security_group;

  newRuleData:{
    direction:string,
    ip_protocol:string,
    from_port:string,
    to_port:string,
    cidr:string,
    group_id:string,
    description:string,
  }={
    direction:"ingress",
    ip_protocol:null,
    from_port:null,
    to_port:null,
    cidr:"",
    group_id:null,
    description:""
  };

  cidr_validate = true;
  addRuleModalType:string="all";
  addRulePortType:string="one_port";

  ngOnInit(): void {
    console.log(this.dialogData.security_group);
    this.security_group = this.dialogData.security_group;
  }
  

  
  addRule(){
    if(this.addRulePortType == "all_icmp" ){
    }
    else if(this.addRulePortType == "one_icmp" &&  this.newRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP TYPE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.addRulePortType == "one_icmp" &&  this.newRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'ICMP CODE을 입력해주세요.'
        }
      });
      return;
    }
    else if(this.addRuleModalType!="all"&&this.addRulePortType != "all_port" &&  this.newRuleData.from_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    else if(this.addRuleModalType!="all"&&this.addRulePortType != "all_port" &&  this.newRuleData.to_port == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'포트를 입력해주세요.'
        }
      });
      return;
    }
    if(!this.cidr_validate){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'유효하지 않은 CIDR입니다.'
        }
      });
      return;
    }
    if( this.newRuleData.cidr == "0.0.0.0/0"){
      const answer = confirm("CIDR을 '0.0.0.0/0'으로 설정하면 모든 사용자로부터 해당 보안그룹에 접근할 수 있습니다. 진행하시겠습니까?.");
      if(!answer){
        this.newRuleData = {
          direction:"ingress",
          ip_protocol:null,
          from_port:null,
          to_port:null,
          cidr:"",
          group_id:null,
          description:""
        };
        return;
      }
    }
    this.spinner.show();
    this.osapi.createSecurityGroupRule(this.security_group.id,
                      this.newRuleData.direction,this.newRuleData.ip_protocol,
                      this.newRuleData.from_port,this.newRuleData.to_port,
                      this.newRuleData.cidr,this.newRuleData.group_id,this.newRuleData.description).subscribe(
      (data) => {
        this.spinner.hide();
        this.closeDialogMsg("success");

    }, 
    (error) => {

      this.spinner.hide();
      if(error.status == 409){
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'동일한 규칙이 이미 존재합니다.'
          }
        });
        return;
      }
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }


  
  onRuleTypeChange(addRuleModalType){
    this.addRuleModalType = addRuleModalType;
    if(addRuleModalType == "all"){
      addRuleModalType = null;
    }
    if(addRuleModalType == "icmp"){
      this.addRulePortType = "all_icmp"
    }
    this.newRuleData.ip_protocol = addRuleModalType;

    if(addRuleModalType == "ssh"){
      this.newRuleData.ip_protocol = "tcp"
      this.newRuleData.from_port = '22'
      this.newRuleData.to_port = '22'
    }
    if(addRuleModalType == "http"){
      this.newRuleData.ip_protocol = "tcp"
      this.newRuleData.from_port = '80'
      this.newRuleData.to_port = '80'
    }
    if(addRuleModalType == "https"){
      this.newRuleData.ip_protocol = "tcp"
      this.newRuleData.from_port = '443'
      this.newRuleData.to_port = '443'
    }
  }
  onRuleDescriptionChange(addRuleModalDesc){

    this.newRuleData.description = addRuleModalDesc;
  }
  onRuleDirectionChange(addRuleModalDirection){
    this.newRuleData.direction = addRuleModalDirection;
  }
  onRulePortTypeChange(addRulePortType){
    this.addRulePortType = addRulePortType;
    this.newRuleData.from_port = null;
    this.newRuleData.to_port = null;
  }
  onRuleFromPortChange(addRuleModalFromPort){
    this.newRuleData.from_port = addRuleModalFromPort;
    if(parseInt(addRuleModalFromPort)>65535){
      this.newRuleData.from_port = '65535';
    }
    if(parseInt(addRuleModalFromPort)<1){
      this.newRuleData.from_port = '1';
    }
    if(parseInt(addRuleModalFromPort)>parseInt(this.newRuleData.to_port)){
      this.newRuleData.to_port = this.newRuleData.from_port;
    }
  }
  onRuleToPortChange(addRuleModalToPort){
    this.newRuleData.to_port = addRuleModalToPort;
    if(parseInt(addRuleModalToPort)>65535){
      this.newRuleData.to_port = '65535';
    }
    if(parseInt(addRuleModalToPort)<1){
      this.newRuleData.to_port = '1';
    }
    if(parseInt(addRuleModalToPort)<parseInt(this.newRuleData.from_port)){
      this.newRuleData.from_port = this.newRuleData.to_port;
    }
  }
  onRuleCidrChange(addRuleModalCidr){
    
    if(addRuleModalCidr == ""){
      addRuleModalCidr = "0.0.0.0/0"
    }
    //CIDR 체크  IPv4 주소 및 masking 비트가 포함된 문자열 true
    var cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
    if(!cidr_filter.test(addRuleModalCidr)){
      this.cidr_validate = false;
    }else{
      this.cidr_validate = true;
    }
    this.newRuleData.cidr = addRuleModalCidr;
  }
}
