
<div id="manage_rule" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
          <h2>접근 규칙 관리</h2>
        </div>
        <!-- //modal_header -->
      
        <!-- modal_content -->
        <div class="modal_content">
          <div class="boardBox">
            <table class="tbl_col_tp1">
              <colgroup>
                <col style="width: 40px;"  />
                <col style="width: 40px;" />
                <col style="width: 25px;" />
                <col style="width: 20px;" />
              </colgroup>
              <thead>
                <th scope="col">접근 수준</th>
                <th scope="col">접근 허용 IP</th>
                <th scope="col">상태</th>
                <th scope="col"></th>
              </thead>
              <tbody>
                <!-- 기존 규칙 리스트 -->
                <ng-container *ngIf="selected_nas.ruleList && selected_nas.ruleList.length > 0">
                  <tr *ngFor="let rule of selected_nas.ruleList">
                    <td>
                      <ng-template [ngIf]="rule.access_level =='rw'">읽기/쓰기</ng-template>
                      <ng-template [ngIf]="rule.access_level =='ro'">읽기 전용</ng-template>
                      <!-- <input *ngIf="rule.access_level" type="text" value="{{ rule.access_level }}" readonly /> -->
                    </td>
                    <td>
                        {{ rule.access_to }}
                      <!-- <input *ngIf="rule.access_to" type="text" value="{{ rule.access_to }}" readonly /> -->
                    </td>
                    <td>
                        {{ rule.state | uppercase }}
                      <!-- <input *ngIf="rule.state" type="text" value="{{ rule.state }}" readonly /> -->
                    </td>
                    <td>
                      <a class="btn sml dGray" (click)="deleteAccessRule(rule.id)"
                        >삭제</a
                      >
                    </td>
                  </tr>
                </ng-container>
                <!-- 신규 규칙 추가-->
                <tr>
                  <td>
                    <select [(ngModel)]="newRule.access_level">
                      <option value="rw">읽기/쓰기</option>
                      <option value="ro">읽기 전용</option>
                    </select>
                  </td>
                  <td>
                    <!-- <input
                      type="text"
                      placeholder="IP 주소를 입력해주세요. 예) 172.10.1.0/24"
                      [(ngModel)]="newRule.access_to"
                    /> -->
                    <select [(ngModel)]="newRule.access_to" name="" required>
                      <option value="">-선택-</option>
                      <ng-template [ngIf]="selected_nas.attached_server">
                          <option *ngFor="let server of selected_nas.attached_server" [value]="server.nas_addr">
                            {{ server.server_name }}{{ " " }} 
                            ({{server.nas_addr}})
                          </option>
                      </ng-template>
                    </select>
                  </td>
                  <td><input type="text" readonly /></td>
                  <td>
                    <a (click)="createAccessRule()" class="btn sml ciblue" >적용</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btnArea tp2">
            <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
          </div>
        </div>
        <!-- //modal_content -->
      </div>