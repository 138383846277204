import { Component, OnInit } from '@angular/core';

import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { GWService } from '../../gwapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';



@Component({
  selector: 'app-console-apigateway-usage',
  templateUrl: './console-apigateway-usage.component.html',
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ko-KR'},
  ],
})

export class ConsoleApigatewayUsageComponent implements OnInit {


  start_date;
  end_date;

  data_list=[];
  
  constructor(
    // @Inject(DOCUMENT) private document: Document,
    // private renderer: Renderer2,
    // private http: HttpClient,
    private osapi:OsapiService,
    private gwapi:GWService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private router:Router,
    public global: GlobalService,
    private dialogs:DialogSetService,
    private common:ConsoleNavComponent,
    private _adapter: DateAdapter<any>
    ) { }

    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
      console.log(type)   
      console.log(event.value)   
    }
    ngOnInit(): void {
      
      const today = new Date();
      const before_date = new Date();
      before_date.setDate(today.getDate()-7);
      // 검색 텍스트
      // this.start_date =  new FormControl(before_date);
      this.start_date =  before_date;
      // this.end_date = new FormControl(today);
      this.end_date = today
      ;
      this._adapter.setLocale('ko');
      this.getApiUsageList();
    }
    changeDate(type,date){ 
      console.log(date)
      if(type == 'start'){
        this.start_date=date;
      } else{
        this.end_date=date;
      }
    }
    
    getApiUsageList(){
      let start_date_text = getFormatDate(this.start_date);
      let end_date_text = getFormatDate(this.end_date);
      console.log(start_date_text,end_date_text)

      this.gwapi.getApiUsageList(start_date_text,end_date_text).subscribe((data: any) => {
        this.data_list = data;
        for(let usage of this.data_list){

        }
        console.log(data)
      }, 
      (error) => {
      });
    }

}
function getFormatDate(date){
  var year = date.getFullYear();              //yyyy
  var month = (1 + date.getMonth());          //M
  month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
  var day = date.getDate();                   //d
  day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
  return  year + '-' + month + '-' + day;       //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
}