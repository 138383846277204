<table style="margin-bottom:10px;">
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">프로젝트</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{projectList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                        <td>{{projectList.length}}</td>
                    </tr>
                    <!-- <tr>
                        <td><label class="dot green"></label></td>
                        <td>ACTIVE</td>
                        <td>:</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td><label class="dot red"></label></td>
                        <td>STOP</td>
                        <td>:</td>
                        <td>0</td>
                    </tr> -->
                </table>
            </div>
        </div>
    </div>
    <div style="padding-right:20px; display:inline-block">
        <i class="mg_r4 stFont">멤버</i>
        <div class="tooltip">
            <span class="btn mid status mg_r4 tooltip-head">{{subuserList.length}}</span>
            <div class="tooltip-content">
                <table>
                    <tr>
                        <th>TOTAL</th>
                        <td>:</td>
                        <td>{{subuserList.length}}</td>
                    </tr>
                    <!-- <tr>
                        <td><label class="dot green"></label></td>
                        <td>ACTIVE</td>
                        <td>:</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td><label class="dot red"></label></td>
                        <td>STOP</td>
                        <td>:</td>
                        <td>7</td>
                    </tr> -->
                </table>
            </div>
        </div>
    </div>
</table>