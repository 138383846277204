import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { postcode } from 'src/assets/js/util.js';
import { WebApiService } from '../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare const $: any;

@Component({
  selector: 'app-mypage-member',
  templateUrl: './mypage-member.component.html',
})
export class MypageMemberComponent implements OnInit {
  userId = '';
  registrationNumber = '';
  registrationNumberSub ='';
  status = '';

  userInfoList: any = [];
  companyInfoList: any = [];
  submitted = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private spiner: NgxSpinnerService,
    private webapi: WebApiService,
    private router: Router
  ) { }

  // 개인용 폼 //
  profileUserForm = new FormGroup({
    name: new FormControl('', [Validators.pattern(/[가-힣]/)]),
    userId: new FormControl('', [Validators.required]),
    passwordOrg: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.pattern(/[A-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.pattern(/[!@#$%^&*()_\[+=\]{}~\?:;`|]/),
      Validators.minLength(8),
    ]),
    confirmPassword: new FormControl(''),
    telephone: new FormControl('', [
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10),
    ]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10),
    ]),
    zipCode: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    addressDetail: new FormControl('', [Validators.required]),
  });
  get userForm() {
    return this.profileUserForm.controls;
  }
  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.spiner.show()
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));

    this.companyInfoList = JSON.parse(localStorage.getItem('companyInfo'));
    
    this.status = this.companyInfoList['status'];
    this.webapi.getUser(this.userInfoList.userId).subscribe(
      (response) => {
        const data = response.body;
        console.log(data)
        this.userId = data['userId']
        this.registrationNumber = data['registrationNumber']
        this.registrationNumberSub = data['registrationNumberSub']
        this.profileUserForm.patchValue({
          name: data['name'],
          userId: data['userId'],
          telephone: data['telephone'],
          mobile: data['mobile'],
          zipCode: data['zipCode'],
          address: data['address'],
          addressDetail: data['addressDetail'],
        });
      }, (error) => {

      }
    );
    this.spiner.hide();
  }

  openDaumPopup() {
    postcode((data: any) => {
      this.profileUserForm.controls.zipCode.setValue(data.zonecode);
      this.profileUserForm.controls.address.setValue(data.address);
      this.profileUserForm.controls.addressDetail.setValue('');
      $('#addressDetail').focus();
    });
  }

  sendUserName() {
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'));
    return this.userInfoList.name;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.profileUserForm.invalid) {
      const invalid = [];
      const controls = this.profileUserForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      return;
    }
    //비밀번호 체크 로직
    if (
      this.profileUserForm.controls.password.value !==
      this.profileUserForm.controls.confirmPassword.value
    ) {
      $('#confirmPassword').focus();
      alert('신규 비밀번호가 일치하지 않습니다.');
      return;
    }
    this.spiner.show();
    this.webapi
      .updateUser(this.userInfoList.userId, this.profileUserForm.value)
      .subscribe(
        (response) => {

          const data = response.body;
          alert('정보수정에 성공했습니다.');
          this.profileUserForm.patchValue({
            name: data['name'],
            userId: data['userId'],
            passwordOrg: '',
            password: '',
            confirmPassword: '',
            telephone: data['telephone'],
            mobile: data['mobile'],
            zipCode: data['zipCode'],
            address: data['address'],
            addressDetail: data['addressDetail'],
          });
        },
        (err) => {
          if (err.error === 'err-invalid-password') {
            alert('비밀번호가 일치하지 않습니다.');
            this.profileUserForm.patchValue({
              passwordOrg: '',
            });
          }
        }
      );
    this.spiner.hide();
    this.submitted = false;
  }

  withdrawal(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        userId: this.userId,
        registrationNumber: this.registrationNumber,
        registrationNumberSub: this.registrationNumberSub,
      },
      skipLocationChange: true,
    };
    this.router.navigate(['/web/mypage/withdrawal'], navigationExtras);
  }
}
