import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';

@Component({
  selector: 'app-console-common-modal',
  templateUrl: './console-common-modal.component.html',
})
export class ConsoleCommonModalComponent implements OnInit {

  @Input() message: string;
  @Output() modalMsg = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,
  ) {
    
  }

  ngOnInit(): void {
  }
}
