import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any): any;

@Component({
  selector: 'app-admin-claim-creditlist',
  templateUrl: './admin-claim-creditlist.component.html',
})
export class AdminCreditListComponent implements OnInit {

  constructor(
    private webapi: WebApiService,
    private common: AdminNavComponent
  ) { }

  userList: any = [];
  creditHistoryInfo: any = [];
  
  // 페이징 변수
  curPage: any;
  totalCount: any;
  searchText: any;

  ngOnInit(): void {    
    this.getCreditHistoryList(0);
  }

  getCreditHistoryList(page) {
    this.curPage = page;
    let searchQueries = `o=credit_history_id.DESC&l=10&s=${this.curPage * 10}`;
    
    // 검색조건
    if (this.searchText) {
      const decodeSearch = decodeURIComponent(this.searchText);
      searchQueries += `&company_name=${decodeSearch}&lk=company_name`;
    }

    this.webapi.getCreditHistory(searchQueries).subscribe(
      (response) => {

        const data = response.body
        this.creditHistoryInfo = data['result'];
        this.totalCount = data['total'];
        this.common.getThemeFont();
      }, (err) => {

      }
    );
  }

  onSearch(deviceValue){

  }

  serachCreditHistory(){
    this.getCreditHistoryList(0);
  }

  //페이징함수
  getMovePage(e) {
    this.getCreditHistoryList(e.page);
  }

  changeSearchValue(deviceValue){
    this.searchText, deviceValue = deviceValue.trim();
  }
}
