<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>메일/문자 발송</h2>
		<div class="lineMap">
			메일/문자 발송 /  <span>SMS 발송 목록</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">				
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>SMS 발송</h3>
					</div>
					<div class="contArea" style="display:block">
						<form [formGroup]="sendForm" (ngSubmit)="onSubmit();">
							<div class="boardBox">
								<table class="tbl_row_tp1 pdTp2">
									<colgroup>
										<col style="width:20%;">
										<col />
										<col style="width:100px;">
									</colgroup>
									<tbody>
										<tr class="required">
											<th scope="row">구분</th>
											<td colspan="2">
												<label for="sms" class="mg_r8"><input type="radio" id="sms" formControlName="msgType" value=0 [checked]="msgType === 0" (change)="changeType($event)"> SMS</label>
												<label for="mms"><input type="radio" id="mms" formControlName="msgType" value=1 [checked]="msgType === 5" (change)="changeType($event)"> MMS</label>
											</td>
										</tr>
										<tr class="required">
											<th scope="row">송신자</th>
											<td colspan="2">
												<input type="text" formControlName="tranCallback" required readonly>
											</td>
										</tr>
										<tr class="required">
											<th scope="row">수신자</th>
											<td>
												<input type="text" formControlName="tranPhone" [value]="tranPhone" required readonly
													[class]="sendChk.tranPhone.invalid && (submitted || sendChk.tranPhone.dirty) ? 'error-invalid' : ''"
												>
												<div *ngIf="sendChk.tranPhone.invalid && (submitted || sendChk.tranPhone.dirty)">
													<div class="error-invalid-feedback" *ngIf="sendChk.tranPhone.errors.required">
														수신자는 필수 값입니다.
													</div>
												</div>
											</td>
											<td><a href="javascript:void(0);" class="btn mid blue" (click)="fn_getAdress()">주소록</a></td>
										</tr>

										<tr>
											<th scope="row">발송일자</th>
											<td colspan="2">
												<input type="datetime-local" formControlName="tranDate"
													[class]="sendChk.tranDate.invalid && (submitted || sendChk.tranDate.touched || sendChk.tranDate.dirty) ? 'error-invalid' : ''"
												>
												<!-- <div *ngIf="sendChk.tranDate.invalid && (submitted || sendChk.tranDate.touched || sendChk.tranDate.dirty)">
													<div class="error-invalid-feedback" *ngIf="sendChk.tranDate.errors?.required">
														발송일자는 필수 값입니다.
													</div>
												</div> -->
											</td>
										</tr>
										
										<tr class="required" *ngIf="msgType === 0">
											<input type="hidden" formControlName="subject">
										</tr>

										<tr class="required" *ngIf="msgType === 5">
											<th scope="row">제목</th>
											<td colspan="2">
												<input type="text" formControlName="subject" (keyup)="titleHandler($event)" required
													placeholder="한글 32자, 영문 64자 이내로 입력해주세요."
													[class]="sbjChk && (submitted || sendChk.subject.dirty) ? 'error-invalid' : ''"
												>
												<div *ngIf="sbjChk && (submitted || sendChk.subject.dirty)">
													<div class="error-invalid-feedback" *ngIf="sendChk.subject.errors.required">
														내용은 필수 값입니다. 한글 32자, 영문 64자 이내로 입력해주세요.
													</div>
												</div>
											</td>
										</tr>

										<tr class="required">
											<th scope="row">내용</th>
											<td colspan="2">
												<textarea rows="7" formControlName="msgBody" (keyup)="bytesHandler($event)" required
													placeholder="한글 {{maxByte / 2}}자, 영문 {{maxByte}}자 이내로 입력해주세요."
													[class]="byteChk || sendChk.msgBody.invalid && (submitted || sendChk.msgBody.touched || sendChk.msgBody.dirty) ? 'error-invalid' : ''"
												></textarea>

												<div *ngIf="sendChk.msgBody.invalid && (submitted || sendChk.msgBody.touched || sendChk.msgBody.dirty)">
													<div class="error-invalid-feedback" *ngIf="sendChk.msgBody.errors.required">
														내용은 필수 값입니다. 한글 {{maxByte / 2}}자, 영문 {{maxByte}}자 이내로 입력해주세요.
													</div>
												</div>
												<div class="error-invalid-feedback" *ngIf="byteChk">
													한글 {{maxByte / 2}}자, 영문 {{maxByte}}자를 초과 입력할 수 없습니다.
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="btn_wrap tp1">
								<button type="submit" class="btn big orange mg_r4" [disabled]='!sendForm.valid || byteChk || sbjChk'>전송</button>
								<!-- <span>{{sendForm.value | json}} {{sendForm.valid}} {{byteChk}} {{sbjChk}}</span> -->
							</div>
						</form> 
					</div>
				</div>
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->



<!-- Modal : adress 추가모달 -->
<div id="smsModal" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>주소록</h2>
		<div class="btnArea">
			<div class="input-group fl">
				<input type="search" class="form-control" [(ngModel)]="listFilter" id="filterInput" placeholder="검색어를 입력해주세요"/>
			</div>
		</div>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<div class="contArea" style="display:block">
			<table class="tbl_col_tp1">
				<colgroup>
					<col style="width:10%">
					<col style="width:20%">
					<col style="width:70%">
				</colgroup>
				<thead>
					<tr>
							<!-- <input type="checkbox"value=""> -->
						<th scope="col"></th>
						<th scope="col">사용자명</th>
						<th scope="col">연락처</th>
					</tr>
				</thead>
			</table>
	
			<div class="boardBox" style="overflow:auto; height:300px;">
				<table class="tbl_col_tp1">
					<colgroup>
						<col style="width:10%">
						<col style="width:30%">
						<col style="width:60%">
					</colgroup>
					<tbody>

						<tr *ngFor='let user of userListFilter'>
							<td><input type="checkbox" [value]="user.mobile" name="userReceiver" ></td>
							<td><span>{{user.name}}</span></td>
							<td class="l"><span>{{user.mobile}}</span></td>
						</tr>
					</tbody>
				</table>				
			</div>
		</div>
		<div class="btnArea tp1">
			<a href="javascript:void(0);" (click)="fn_getAdressReceiver()" class="btn big orange mg_r4" >완료</a>
			<a href="#close-modal" rel="modal:close" id="closeModalBtn" class="btn big orange">닫기</a>
		</div>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->