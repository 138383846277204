import { Component, OnInit, Inject, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router} from '@angular/router';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',

})
export class MainComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router : Router,
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'login');
    this.renderer.addClass(this.document.getElementById('Wrap'),'main');
  }

  ngAfterViewInit() {
    $('.mainVisual_slider').bxSlider({
      auto: true,
      pause: 6000,
      speed: 1600,
      mode: 'fade',
    });
  }

  ServiceScrollRouter(params) {
    this.router.navigate(['/web/service', {params}]);
  }
}
