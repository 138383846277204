import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-keypair-create-detail',
  templateUrl: './dialog-keypair-create-detail.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogKeypairCreateDetailComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogKeypairCreateDetailComponent>,
    private sanitizer: DomSanitizer,
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  fileUrl;
  privatekey_name: string;
  new_keypair;

  ngOnInit(): void {

    this.fileUrl = this.dialogData.fileUrl;
    this.privatekey_name = this.dialogData.privatekey_name;
    this.new_keypair = this.dialogData.new_keypair;
  }

  
  
  downloadKeyPair() {
    let a = document.getElementById('privatekey_download') as HTMLAnchorElement;
    a.click();
    this.fileUrl = null;
    this.privatekey_name = null;
    this.closeDialogMsg("success");
  }

}
