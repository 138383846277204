import { Component, OnInit } from '@angular/core';
import { AdminNavComponent } from '../admin-nav/admin-nav.component';
import { HttpClient } from '@angular/common/http';
import { AdminService } from '../../../admin.service';
import { WebApiService } from '../../../webapi.service';

@Component({
  selector: 'app-admin-setting',
  templateUrl: './admin-setting.component.html',
  providers: [AdminNavComponent],
})
export class AdminSettingComponent implements OnInit {
  themeSelect;
  constructor(
    private common: AdminNavComponent,
    private http: HttpClient,
    private webapi: WebApiService,
    private api: AdminService,
  ) { }

  ngOnInit(): void {
    this.selectTheme();
  }

  selectTheme() {
    this.api.getThemes().subscribe((data: {}) => {
      this.themeSelect = data;
    });
  }

  changeTheme(gubn) {
    this.api.changeThemes(gubn).subscribe((data: {}) => {
      this.common.getThemes();
    });
  }

  uploadFile(){
    
  }
}