import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
// import { GlobalService } from '../../global.service';
import {ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';

declare function fn_modalMsg(msg): any;
declare function fn_modalOpen(id): any;

@Component({
  selector: 'app-console-rule-manage-list',
  templateUrl: './console-rule-manage-list.component.html',
})
export class ConsoleRuleManageListComponent implements OnInit {
  security_group_id="";
  security_group;
  securityGroupRuleList;
  selected_sgr;
  // private globalService;
  
  get_securitygroup_loading_flag =false;
  
  constructor(
    private osapi:OsapiService, 
    route:ActivatedRoute,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    public global: GlobalService,
    private dialogs: DialogSetService,
    ) { 
    this.security_group_id = route.snapshot.params['sgId'];
    this.getSecurityGroup(this.security_group_id);
  }

  ngOnInit(): void {
    this.getSecurityGroupRuleList(this.security_group_id);
  }
  ngOnDestroy(){
  }
  onListCheck(e){
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);    
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;

    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }
  onClickList(sgr){
    console.log(sgr);
    this.selected_sgr = sgr;
  }
  getSecurityGroup(sg_id){
    this.get_securitygroup_loading_flag =false;
    this.spinner.show();
    this.osapi.getSecurityGroup(sg_id).subscribe(
      (data) => {
        this.get_securitygroup_loading_flag =true;
        this.spinner.hide();
        this.security_group= data;
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
  getSecurityGroupRuleList(sg_id){
    this.spinner.show();
    this.osapi.getSecurityGroupRuleList(sg_id).subscribe(
      (data) => {
        this.spinner.hide();

      this.securityGroupRuleList= data;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
  
  openNewRuleModal(){
    if(this.get_securitygroup_loading_flag){
      this.dialogs.openSecurityGroupRuleCreateDialog(
        {
          security_group:this.security_group,
        }
      );
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "success"){
          this.getSecurityGroupRuleList(this.security_group_id);
        }
      });
    }else{
      this.spinner.show();
      setTimeout(() =>  { this.openNewRuleModal(); }, 500);
    }
  }
  openModifiedRuleModal(){
    if(this.get_securitygroup_loading_flag){
      this.dialogs.openSecurityGroupRuleUpdateDialog(
        {
          security_group:this.security_group,
          selected_sgr:this.selected_sgr,
        }
      );
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "success"){
          this.getSecurityGroupRuleList(this.security_group_id);
        }
      });
    }else{
      this.spinner.show();
      setTimeout(() =>  { this.openModifiedRuleModal(); }, 500);
    }
  }

  // setTimeout(() =>  { this.openEditFloatingIpModal(); }, 500);
  openDeleteRuleModal(){
    this.dialogs.openDefaultDeleteDialog({
      title:"보안그룹 규칙 삭제",
      content:"선택한 규칙을 삭제합니다."});
    // this.global.openNewFloatingIpModal();
      this.dialogs.setAfterClosed().subscribe(result => {
        if(result == "okay"){
          this.deleteRule();
        }
      });
  }
  
  
  deleteRule(){
    this.spinner.show();
    this.osapi.deleteSecurityGroupRule(this.selected_sgr.id).subscribe(
      (data) => {
        this.spinner.hide();
        this.getSecurityGroupRuleList(this.security_group_id);
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
}
