import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../../../osapi.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-admin-image-setting',
  templateUrl: './admin-image-setting.component.html',
})
export class AdminImageSettingComponent implements OnInit {

  newImageData:{
    
  }
  constructor(private osapi:OsapiService,private http: HttpClient) { }

  ngOnInit(): void {
  }
  fileData:any;
  onFileChange(file){

    this.fileData = file
  }
  onSubmit(formValue: any){
    const fd = new FormData();
    fd.append('source_type','file-legacy');
    fd.append('$$originalJSON','{"source_type":"file-legacy","data":{},"is_copying":false,"protected":true,"min_disk":1,"min_ram":2,"container_format":"bare","disk_format":"qcow2","visibility":"public","name":"miyssss","description":"t","architecture":"test","OS":"os"}');

    this.createImage({'$$originalJSON':'{"source_type":"file-legacy","data":{},"is_copying":false,"protected":true,"min_disk":1,"min_ram":2,"container_format":"bare","disk_format":"qcow2","visibility":"public","name":"miyssss","description":"t","architecture":"test","OS":"os"}'});
  }
  createImage(form_data){
    this.osapi
    .createImage(form_data).subscribe(
      (data) => {

      // location.replace("/admin/image");
    });
  }
}
