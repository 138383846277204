import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, LoginAuthGuard } from '../auth.guards';
import {MobileNavComponent} from './mobile-nav/mobile-nav.component';
import {MobileConsoleDashboardComponent} from './mobile-console-dashboard/mobile-console-dashboard.component';
import {MobileConsoleServerListComponent} from './mobile-console-server-list/mobile-console-server-list.component';
import {MobileConsoleImageListComponent} from './mobile-console-image-list/mobile-console-image-list.component';
import {MobileConsoleKeypairListComponent} from './mobile-console-keypair-list/mobile-console-keypair-list.component';
import {MobileConsoleIpListComponent} from './mobile-console-ip-list/mobile-console-ip-list.component';
import {MobileConsoleNetworkListComponent} from './mobile-console-network-list/mobile-console-network-list.component';
import {MobileConsoleSubnetListComponent} from './mobile-console-subnet-list/mobile-console-subnet-list.component';
import {MobileConsoleLoadbalancerListComponent} from './mobile-console-loadbalancer-list/mobile-console-loadbalancer-list.component';
import {MobileConsoleSecurityGroupListComponent} from './mobile-console-security-group-list/mobile-console-security-group-list.component';
import {MobileConsolePrivateNetworkListComponent} from './mobile-console-private-network-list/mobile-console-private-network-list.component';
import {MobileConsoleStorageListComponent} from './mobile-console-storage-list/mobile-console-storage-list.component';
import {MobileConsoleSnapShotListComponent} from './mobile-console-snapshot-list/mobile-console-snapshot-list.component';
import {MobileConsoleNasListComponent} from './mobile-console-nas-list/mobile-console-nas-list.component';
import {MobileConsoleProjectListComponent} from './mobile-console-project-list/mobile-console-project-list.component';
import {MobileConsoleSubuserListComponent} from './mobile-console-subuser-list/mobile-console-subuser-list.component';
import {MobileConsoleRuleManageListComponent} from './mobile-console-rule-manage-list/mobile-console-rule-manage-list.component'

const routes: Routes = [
  {
    path: 'm', 
    component: MobileNavComponent,canActivate:[LoginAuthGuard, AuthGuard],
    children: [
      { path: '', component: MobileConsoleDashboardComponent },
      { path: 'dashboard', component: MobileConsoleDashboardComponent },
      { path: 'server', component: MobileConsoleServerListComponent },
      { path: 'image', component: MobileConsoleImageListComponent },
      { path: 'keypair', component: MobileConsoleKeypairListComponent },
      { path: 'network', component: MobileConsoleNetworkListComponent },
      { path: 'ip', component: MobileConsoleIpListComponent },
      { path: 'subnet', component: MobileConsoleSubnetListComponent },
      { path: 'loadbalancer', component: MobileConsoleLoadbalancerListComponent },
      { path: 'securitygroup', component: MobileConsoleSecurityGroupListComponent },
      { path: 'privatenetwork', component: MobileConsolePrivateNetworkListComponent },
      { path: 'storage', component: MobileConsoleStorageListComponent },
      { path: 'snapshot', component: MobileConsoleSnapShotListComponent },
      { path: 'nas', component: MobileConsoleNasListComponent },
      { path: 'project', component: MobileConsoleProjectListComponent },
      { path: 'subuser', component: MobileConsoleSubuserListComponent },
      { path: 'securitygroup/ruleManage/:sgId', component: MobileConsoleRuleManageListComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MobileRoutingModule {}
