import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-customer-center',
  templateUrl: './customer-center.component.html',
})
export class CustomerCenterComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
  }

}
