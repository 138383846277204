import { ListenersData } from "./ListenersData";
import { PoolData } from "./PoolData";

export class LoadbalancerData {
  public name: string;
  public admin_state_up: boolean;
  public description: string;
  public project_id: string;
  public flavor_id:string; /** default */
  // public listeners: any[];
  // public pools: any[];
  public vip_subnet_id: string;
  // public vip_address: string;
  // public provider: string;
  // public vip_qos_policy_id:string=null, /**default */
  // public tags: [];
  // public availability_zone: string;
  

  constructor() {
    this.name = "LB-";
    this.admin_state_up = true;
    this.description = "";
    this.project_id = null;
    this.flavor_id = null;
    // this.listeners = new Array();
    // this.pools = new Array();
    this.vip_subnet_id = null;
    // this.vip_address = '';
    // this.provider = "amphora";
    // this.tags = [];
    // this.availability_zone = "nova";
  }
}