import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../../../webapi.service';
import { NgForm } from '@angular/forms';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(params: string): any;

@Component({
  selector: 'app-admin-claim-claimList',
  templateUrl: './admin-claim-claimList.component.html',
})
export class AdminClaimClaimListComponent implements OnInit {

  constructor(
    private webapi: WebApiService,
    private common: AdminNavComponent
  ) { }

  totalInvoiceInfo: any = [];
  selectedInvoice: any = [];
  changeDetailInfo: any = [];

  totalRealCost: number = 0;
  totalRawCost: number = 0;
  credit: number = 0;
  sign: number = 1;
  maxCredit: number = 0;
  isCheck = false;
  title: any;

  // 페이징 변수
  curPage: any;
  totalCount: any;
  searchText: any;

  ngOnInit(): void {
    this.getTotalInvoice(0);
  }

  getTotalInvoice(page){
    this.curPage = page;
    // let searchQueries = `o=total_invoice_month.DESC,total_invoice_id&l=10&s=${this.curPage * 10}`;
    let searchQueries = `o=total_invoice_month.DESC&l=10&s=${this.curPage * 10}`;

    // 검색조건
    if (this.searchText) {
      const decodeSearch = decodeURIComponent(this.searchText);
      searchQueries += `&company_name=${decodeSearch}&lk=company_name`;
    }


    this.webapi.getTotalInvoice(searchQueries).subscribe((data) => {

      this.totalInvoiceInfo = data['result'];
      this.totalCount = data['total'];
      this.common.getThemeFont();
    });
  }

  getInvoiceDetail(param){
    const registrationNumber = param.registrationNumber;
    const registrationNumberSub = param.registrationNumberSub;
    this.totalRawCost = param.rawCost;
    this.totalRealCost = param.realCost;
    this.getAvailableCredit(registrationNumber,registrationNumberSub);

    this.selectedInvoice = param;

    if (this.selectedInvoice.Invoices.length > 0) {
      this.title = `${this.selectedInvoice.Company.companyName}의 ${this.selectedInvoice.totalInvoiceMonth}월`;
      fn_modalOpen('claim_detail');
    }
  }

  // 유효한 크레딧
  getAvailableCredit(registrationNumber,registrationNumberSub){
    this.webapi.getAvailableCredit(registrationNumber,registrationNumberSub).subscribe(
      (response) => {
        const data = response.body
        this.maxCredit = data['sum'];
      }, (err) => {

      }
    );
  }

  changeCredit(e){
    this.isCheck = this.validNumber(e);
    const changeCredit = parseFloat(e.target.value);

    if (this.isCheck && (changeCredit > this.maxCredit)) {
      alert('사용 가능한 크레딧을 초과하였습니다.');
      this.credit = 0;
      e.target.focus();
      return;
    }
  }

  getTotalRawCost(rowCostInfo){
    if (rowCostInfo){
      return rowCostInfo.map(t => parseFloat(t.rawCost)).reduce((a, value) => a + value, 0);
    }
    return 0;
  }

  getTotalRealCost(realCostInfo){
    if (realCostInfo){
      return realCostInfo.map(t => parseFloat(t.realCost)).reduce((a, value) => a + value, 0);
    }
    return 0;
  }

  changeRealCost(e, invoice){
    let changeCost;

    if (this.validNumber(e)){
      changeCost = e.target.value;
    }
    
    if (!changeCost || changeCost < 1) {
      alert('예상금액을 입력해주세요.');
      e.target.value = null;
      e.target.focus();
      return;
    }
    
    this.isCheck = this.validNumber(e);
    if (this.isCheck) {
      this.isCheck = this.compareCost(e, invoice);
    }
    if (this.isCheck) {
      this.changeTotalRealCost(e, invoice);
    }
  }
  
  // 청구금액 & 사용금액 비교
  compareCost(e, invoice): boolean {
    let selectedInvoiceDetail = this.selectedInvoice.Invoices.find((item, idx) => {
      return item.invoiceId === invoice.invoiceId;
    });

    const changeCost = parseFloat(e.target.value);
    const originCost = parseFloat(selectedInvoiceDetail.realCost);
    const selectRawCost = parseFloat(selectedInvoiceDetail.rawCost);

    if (changeCost > selectRawCost) {
      alert('예상금액은 사용금액보다 클 수 없습니다.');
      e.target.value = originCost;
      return false;
    }
    return true;
  }

  // 예상금액 합계
  changeTotalRealCost(e, invoice){
    this.changeDetailInfo = this.selectedInvoice.Invoices.map(function(obj){
      let rObj = obj;
      if (rObj.invoiceId === invoice.invoiceId) {
        rObj.realCost = e.target.value;
      }
      return rObj;
    });

    this.totalRealCost = this.getTotalRealCost(this.changeDetailInfo);
  }

  // 숫자만 입력
  validNumber(e){
    const reg = /^[0-9]+$/;

    if (!reg.test(e.target.value)) {
      e.target.value = null;
      alert('숫자만 입력 가능합니다.');
      return false;
    }
    return true;
  }

  // 페이징 함수
  getMovePage(e){
    this.getTotalInvoice(e.page);
  }

  // 검색
  serachInvoice(){
    this.getTotalInvoice(0);
  }

  changeSearchValue(e){
    e.target.value = e.target.value.trim();
    this.searchText = e.target.value;

  }
  
  initCredit(){
    this.credit = 0;
  }

  onSubmit(e) {
    // 예상금액 변동시
    if (this.changeDetailInfo.length > 0) {
      this.selectedInvoice.Invoices = this.changeDetailInfo;
    }

    let sendValue = 
    {
      "companyId": this.selectedInvoice.Company.companyId,
      "invoices": this.selectedInvoice.Invoices,
      "credit": this.credit,
      "sign": this.sign
    };
    

    
    this.webapi.updateTotalInvoice(this.selectedInvoice.totalInvoiceId, sendValue).subscribe((data) => {

      if(data){
        document.getElementById('close-modal').click();
        this.getTotalInvoice(0);
      }
    });
  }
}
