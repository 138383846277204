import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-create-ip',
  templateUrl: './dialog-create-ip.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogCreateIpComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogCreateIpComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  newFloatingIpData:{
    description:string
  }={description:""}
  
  
  ngOnInit(): void {

  }

  createFloatingIp(){
    this.spinner.show();

    this.osapi.createFloatingIp(this.newFloatingIpData.description).subscribe(
      (data:{}) => {

      this.spinner.hide();
      this.closeDialogMsg("success");
      // location.reload();
      if(this.router.url=='/console/publicip'){
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigateByUrl('/console/publicip');
          }
        );
      }else{
      }
    }, 
    (error) => {

      this.spinner.hide();
      this.closeDialog();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  
}
