import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-project-list',
  templateUrl: './mobile-console-project-list.component.html',
  styleUrls: ['./mobile-console-project-list.component.css']
})
export class MobileConsoleProjectListComponent implements OnInit {
  projectLists: any = [];
  userInfo: any;
  currentProjectName: string = "";
  emptyProject = true;
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getUserInfo();
  }

  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        }
        this.projectLists = data.items;
        if (this.emptyProject) {
          this.userInfo.projectId = this.projectLists[0].id;
          this.emptyProject = false;
        }
        for (const project of this.projectLists) {

          if (project.id == this.userInfo.projectId) {
            if (project.resource_data == null) {
              this.currentProjectName = project.name;
            } else {
              this.currentProjectName = project.resource_data.value;
            }
          }
        }
        this._blank = ((this.projectLists != 0) ? true : false);
        this.spinner.hide();
      },
        (error) => {
          this.currentProjectName = "점검중입니다.";
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }

  getUserInfo() {
    try {
      this.osapi.getUserInfo().subscribe((data) => {
        this.userInfo = data;
        this.getProjectList();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }

}
