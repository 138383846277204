import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { UploadingEventArgs, Uploader } from '@syncfusion/ej2-inputs';

/** 20200819 cbkim clipboard */
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-dialog-server-window-password',
  templateUrl: './dialog-server-window-password.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerWindowPasswordComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    private elementRef: ElementRef,
    private _clipboardService: ClipboardService,
    public dialogRef: MatDialogRef<DialogServerWindowPasswordComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  
  /**
   * 20200818 cbkim
   * admin password uploader
   */

  public uploadObj: Uploader;
  @ViewChild('defaultuploadbox') defaultuploadbox: ElementRef;
  public ele: HTMLElement;
  dropareamessage: string;

  selected_server: any = {
    image_snapshot_name: '',
    image_snapshot_description: '',
  };

  ngOnInit(): void {

    this.selected_server = this.dialogData.selected_server;
    
    
    this.ele = document.getElementById('droparea');

    this.uploadObj = new Uploader({
      asyncSettings: {
        saveUrl: `/blsapi/uploadbox/Save/${this.selected_server.server_id}`,
        removeUrl: ''
      },
      buttons: {
        //browse:'Browser..'
      },
      allowedExtensions: '.pem',
      multiple: false,
      success: this.onUploadSuccess,
      uploading: this.onUploadBegin,
      dropArea: this.ele
    });

    this.uploadObj.appendTo('#defaultupload');
    document.getElementById('dropareamessage').innerHTML = "여기에 인증키 파일을 올려주세요. (*.pem)";
    //this.dropareamessage = "여기에 키페어 파일을 올려주세요. (*.pem)";
    document.getElementById('droparea').setAttribute('data-is-password', "false");

  }

  
  public onUploadBegin(args: UploadingEventArgs) {

    if (this.uploadObj) {

    }
  }

  onUploadSuccess(args: any): void {
    if (this.uploadObj) {

    }


    var customHeader = args.response.headers.split('\n');


    args.statusText = "비밀번호를 생성하였습니다."


    for (let item of customHeader) {
      if (item.includes("novakey:")) {
        var msg = item.split(":")[1];

        document.getElementById('dropareamessage').innerHTML = `${msg}`
        document.getElementById('droparea').setAttribute('data-is-password', "true");
        break;
      }
    }
  }

  getClipboard(el) {
    if (el.getAttribute('data-is-password').toLowerCase() == 'false') {
      return;
    }
    this._clipboardService.copyFromContent(document.getElementById('dropareamessage').innerText);
    this.frontapi.changeMessage({
      type: 'toast',
      body: {
        type: 'info',
        title: 'Information',
        message: '클립보드에 복사되었습니다.'
      }
    });
  }

  // clearAdminPasswordPopup() {
  //   //document.getElementById('#defaultuploadbox').innerHTML='<input type="file" id="defaultupload" name="UploadFiles"/>';
  //   this.defaultuploadbox.nativeElement.innerHTML = `<input type="file" id="defaultupload" name="UploadFiles"/>`
  // }
  
}
