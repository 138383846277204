import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tableFilter'
})
export class tableFilterPipe implements PipeTransform{
    transform(value: any, args? : any){
        if(!value) return null;
        if(!args) return value;

        return value.filter((item) => {
            if (item.keypair) {
                item = item.keypair
            }

            const reg = (new RegExp(args))

            const desc = item.description 
            if (desc && reg.test(desc)) {     
                return true                       
            }

            const serverName = item.server_name || item.name
            if (serverName && reg.test(serverName)) {
                return true
            }

            if (item.id && reg.test(item.id)) {
                return true
            }

            if (item.ip && reg.test(item.ip)) {
                return true                
            }

            if (item.gateway_ip && reg.test(item.gateway_ip)) {
                return true                
            }
            
            if (item.ip_private) {
                const hasPrivateIP = JSON.stringify(item.ip_private).toLowerCase().includes(args);
                if (hasPrivateIP) {
                    return true
                }
            }

            if (item.ip_public) {
                const hasPublicIP = JSON.stringify(item.ip_public).toLowerCase().includes(args);
                if (hasPublicIP) {
                    return true
                }
            }
            return false
        })
    }
}
