
<!-- Modal : 공인IP 할당 모달 -->
<div id="modal_publicip" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            리스너 수정
            <p class="sub-txt">로드밸런서 리스너 '프로토콜/포트/연결수제한'를 변경 할 수 있습니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->


    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">이름</th>
                        <td>
                            <input type="text" [(ngModel)]="lbListener.name" placeholder="이름을 입력해주세요" required>
                        </td>
                    </tr>
                    <!-- <tr>
                        <th scope="row">설명 </th>
                        <td>
                            <input type="text" [(ngModel)]="lbListener.description" placeholder="설명을 입력해주세요">
                        </td>
                    </tr> -->
                    <tr>
                        <th scope="row">프로토콜 </th>
                        <td>
                            <input type="text" [(ngModel)]="lbListener.protocol" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">port</th>
                        <td>
                            <input type="text" [(ngModel)]="lbListener.protocol_port" readonly>
                        </td>
                    </tr>
                    <!-- <tr>
                        <th scope="row">client data timeout</th>
                        <td>
                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">tcp inspect timeout</th>
                        <td>
                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">member connect timeout</th>
                        <td>
                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">member data timeout</th>
                        <td>
                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                        </td>
                    </tr> -->
                    <tr>
                        <th scope="row">연결 수 제한</th>
                        <td>
                            <input type="text" [(ngModel)]="lbListener.connection_limit" placeholder="내용을 입력해주세요">
                        </td>
                    </tr>                                        
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a (click)="updateLinster()" class="btn big ciblue mg_r4">적용</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->