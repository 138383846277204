import { Component, OnInit, Inject } from '@angular/core';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';

@Component({
  selector: 'app-dialog-objectstorage-apiendpoint',
  templateUrl: './dialog-objectstorage-apiendpoint.component.html',
  styleUrls: ['../dialog-common.css', 'dialog-objectstorage-apiendpoint.css']
})
export class DialogObjectStorageApiEndpointComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private cephapi: CephapiService,
    public dialogRef: MatDialogRef<DialogObjectStorageApiEndpointComponent>

  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  public objectStorageInfo: any;
  public accessKeyHide: boolean;
  public secretAccessKeyHide: boolean;

  ngOnInit() {
    this.objectStorageInfo = this.dialogData;
    this.accessKeyHide = true;
    this.secretAccessKeyHide = true;
  }

  copytokey(inputElement) {

    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);

    this.frontapi.changeMessage({
      type: 'toast',
      body: {
        type: 'success',
        title: '클립보드',
        message: '클립보드에 복사하였습니다.'
      }
    });
  }
}
