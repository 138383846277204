import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { bls_error } from '../../utils/bls-error';
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import { DialogSetService } from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

declare function fn_modalMsg(msg): any;
declare function fn_modalOpen(msg): any;
declare function valiAllChk(params: any);

@Component({
  selector: 'app-console-nas-list',
  templateUrl: './console-nas-list.component.html',
})
export class ConsoleNasListComponent implements OnInit {
  nasList: any = [];
  // ruleList: any = [];
  selected_nas: any = {};
  serverList: any = [];
  attachedServerList: any = [];
  detachedServerList: any = [];

  is_server_list_load: boolean = false;

  // 검색 텍스트
  filterText: string;

  isActived: boolean = false;
  isChecked: boolean = false;


  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private dialogs: DialogSetService,
    public global: GlobalService,
    @Inject(ConsoleNavComponent) private parent: ConsoleNavComponent
  ) { }

  ngOnInit(): void {
    this.is_server_list_load = false;
    this.selected_nas = {};
    this.nasList = [];
    this.serverList = [];
    this.attachedServerList = [];
    this.detachedServerList = [];

    this.getNasList();
    this.updateStatus();
  }

  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onListCheck(e) {
    const $_CHKEDLIST = document.getElementsByName('sel_chkbox');
    // const result = valiAllChk($_CHKEDLIST);
    // this.isChecked = $_CHKEDLIST.length > result ? false : true;
    // this.isActived = result > 0 ? true : false;
    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = <HTMLInputElement>$_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
    }
    e.target.checked = true;
  }

  onClickList(nas) {
    this.isActived = true;
    this.selected_nas = nas;

  }

  openManageServerModal() {
    
    this.parent.getServerList().then(() => {this.getServerList();});
    if (this.is_server_list_load) {
      this.dialogs.openNasServerInterfaceUpdateDialog({ attachedServerList: this.attachedServerList, detachedServerList: this.detachedServerList });
      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {

          this.parent.isLoadedServer = false;
          this.parent.getServerList().then(() => {
            this.router.navigateByUrl('/console', {
              skipLocationChange: true,
            }).then(() => {
              this.router.navigate(['/console/nas']);
            }
            );
          });
        }
      });
    } else {
      this.spinner.show();
      setTimeout(() => { this.openManageServerModal(); }, 500);
    }
  }
  openManageRuleModal() {
    if (this.is_server_list_load) {
      this.dialogs.openNasRuleUpdateDialog({ selected_nas: this.selected_nas });
      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigate(['/console/nas']);
            }
          );
        }
      });
    } else {
      this.spinner.show();
      setTimeout(() => { this.openManageRuleModal(); }, 500);
    }
  }
  openNewNasModal() {
    this.dialogs.openNasCreateDialog({});
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result ? result.code == "success" : false) {
        this.nasList.unshift(result.data.share);
        for(let export_location of result.data.share.export_location.export_locations){
          if(export_location.path.includes("10.251.0.201")){
            result.data.share.export_locations = export_location;
          }
        }
        this.updateStatus();
      }
    });
  }

  openDeleteNasModal() {
    this.dialogs.openDefaultDeleteDialog({
      title: "NAS 삭제",
      content: "선택한 NAS(" + this.selected_nas.name + ")를 삭제합니다."
    });
    // this.global.openNewFloatingIpModal();
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.deleteNas();
      }
    });
  }
  openExpansionNasModal() {
    this.dialogs.openNasExpansionDialog({ selected_nas: this.selected_nas });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result && result.code == "success") {
        this.router.navigateByUrl('/console', {
          skipLocationChange: true,
        }).then(
          () => {
            this.router.navigate(['/console/nas']);
          }
        );
      }
    });
  }
  deleteNas() {
    try {
      this.spinner.show();
      this.osapi.deleteNas(this.selected_nas.id).subscribe(() => {
        this.spinner.hide();
        this.nasList = this.nasList.filter(
          (nas) => nas.id != this.selected_nas.id
        );
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
    }
  }

  getNasList() {
    try {
      this.spinner.show();
      this.osapi.getNasList().subscribe((data) => {

        this.nasList = data;
        
        for (let nas of this.nasList) {
          if(!nas.export_locations){
            nas.export_locations = "";
          }
          nas.is_attachserver_by_nas_load = false;
          this.getNasAccessRules(nas);
        }
        this.parent.getThemeFont();
        this.getServerList();
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
    }
  }


  getNasAccessRules(nas) {
    try {
      this.osapi
        .getNasAccessRules(nas.id)
        .subscribe((data: { access_list }) => {
          this.spinner.hide();
          nas.ruleList = data.access_list;
          this.makeAttachedAndDetachedServerListByNas(nas, this.serverList, !nas.is_attachserver_by_nas_load);
        },
          (error) => {
            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
    } catch (e) {
    }
  }
  makeAttachedAndDetachedServerListByNas(nas, servers, ativate = true) {
    if (ativate) {
      if (servers == null || this.is_server_list_load == false) {
        return false;
      }
      if (nas.ruleList == null || nas == null) {
        return false;
      }
      nas.attached_server = [];
      nas.dettached_server = [];
      for (let server of servers) {
        let flag = false;
        for (let i = 0; i < server.network_interfaces.length; i++) {
          if (server.network_interfaces[i].name === 'net_str') {
            flag = true;
            for (let rule of nas.ruleList) {
              if (server.network_interfaces[i].addr == rule.access_to) {
                flag = false;
              } else {
              }
            }
          }
        }
        if (flag) {
          nas.attached_server.push(server);
        } else {
          nas.dettached_server.push(server);
        }
      }
      nas.is_attachserver_by_nas_load = true;
      return true;
    }
  }

  updateStatus() {
    this.nasList.forEach(async (nas, index) => {
      const beingStatus = [
        'creating',
        'deleting',
        'shrinking',
        'creating_from_snapshot',
        'reverting',
      ];
      if (beingStatus.includes(nas.status)) {
        // 해당 nas 상태 폴링 업데이트 (최대 5번)
        try {
          for (let i = 0; i < 5; i++) {
            await this.sleep(1000);
            this.osapi.getNas(nas.id).subscribe((data: { share }) => {
              this.nasList[index].status = data.share.status;
              this.getNasAccessRules(nas);
            },
              (error) => {
                this.spinner.hide();
                this.frontapi.changeMessage({
                  type: 'toast',
                  body: {
                    type: 'error',
                    title: '오류 알림',
                    message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
                  }
                });
              });
          }
        } catch (e) {
        }
      }
    });
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  getServerList() {
    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList();
      }, 200)
    } else {
      this.serverList = this.parent.serverList;
      this.is_server_list_load = true;
      this.spinner.hide();
      this.makeAttachedAndDetachedServerList(this.serverList);
      for (let nas of this.nasList) {
        this.makeAttachedAndDetachedServerListByNas(nas, this.serverList, !nas.is_attachserver_by_nas_load);
      }
    }
  }


  makeAttachedAndDetachedServerList(servers) {

    this.attachedServerList = servers.filter((server) => {
      for (let i = 0; i < server.network_interfaces.length; i++) {
        if (server.network_interfaces[i].name === 'net_str') {
          return true;
        }
      }
      return false;
    });
    for (let server of this.attachedServerList) {
      for (let i = 0; i < server.network_interfaces.length; i++) {
        if (server.network_interfaces[i].name === 'net_str') {
          server.nas_addr = server.network_interfaces[i].addr;
        }
      }
    }

    this.detachedServerList = servers.filter((server) => {
      for (let i = 0; i < server.network_interfaces.length; i++) {
        if (server.network_interfaces[i].name === 'net_str') {
          return false;
        }
      }
      return true;
    });
  }


  clipboardCopy(e) {
    const text = document.getElementById('copyText');
    let range = document.createRange(); // 텍스트 기본정보를 Range 객체에 저장
    range.selectNode(text.childNodes[0]);

    let sel = window.getSelection();
    sel.removeAllRanges();  // 기존 선택정보 삭제
    sel.addRange(range);    // 텍스트 정보 선택
    document.execCommand('copy'); // 복사
    sel.removeRange(range);       // 선택 정보 삭제

    this.frontapi.changeMessage({
      type: 'toast',
      body: {
        type: 'success',
        title: '클립보드',
        message: '클립보드에 접속경로를 복사하였습니다.'
      }
    });
  }
}

function getCookie(key) {
  var result = null;
  var cookie = document.cookie.split(';');
  cookie.some(function (item) {
    // 공백을 제거
    item = item.replace(' ', '');

    var dic = item.split('=');

    if (key === dic[0]) {
      result = dic[1];
      return true;
    }
  });
  return result;
}