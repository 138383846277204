import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { WebModule } from './web/web.module';
import { ConsoleModule } from './console/console.module';
import { AppRoutingModule } from './app-routing.module';
import {MobileModule} from './mobile/mobile.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { RadioButtonModule, CheckBoxModule, ButtonModule } from '@syncfusion/ej2-angular-buttons';
// import { NgxAccordionTableModule } from 'ngx-accordion-table'  


@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
  ],
  imports: [   
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ConsoleModule,
    WebModule,
    MobileModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ToastModule,
    RadioButtonModule,
    CheckBoxModule,
    ButtonModule, 
    // NgxAccordionTableModule
  ],
  providers: [
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
