<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Network</li>
      <li class="active">Network</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let item of networkLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons"
                        [class]="item.status === 'ACTIVE' ? 'dotActive' : 'dotStop'">settings_input_antenna</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{item.name}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{{item.name}}</td>
                    </tr>
                    <tr>
                      <th>CIDR</th>
                      <td>{{item.cidr}}</td>
                    </tr>
                    <tr>
                      <th>연관된 서브넷</th>
                      <td *ngFor="let subnet of item.subnets">
                        {{subnet.name}}({{subnet.cidr}})
                      </td>
                    </tr>
                    <tr>
                      <th>외부연결상태</th>
                      <td *ngIf="item.router_state != null; else elseExt">
                        {{item.router_state == true? '허용':'차단'}}
                      </td>
                      <ng-template #elseExt>
                        <td>내부 네트워크</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <th>SNAT</th>
                      <td>{{item.external_gateway_info.enable_snat == true? '사용' : '사용 안함'}}</td>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>
                        <label [class]="item.status === 'ACTIVE' ? 'dot green' : 'dot red'"></label>
                        {{item.status}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>