import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { FrontApiService } from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';

import { isArray } from 'util';
import { bls_error } from '../../utils/bls-error';
import { ɵPLATFORM_SERVER_ID, DOCUMENT } from '@angular/common';

import { BlsCommon } from '../../blscommon';
// import { UploadingEventArgs, Uploader } from '@syncfusion/ej2-inputs';
import { getUniqueID } from '@syncfusion/ej2-base';


import { DialogSetService } from '../dialog/dialog-set.service';
import { windowToggle } from 'rxjs/operators';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';


//import { type } from 'os';

declare const $: any;
@Component({
  selector: 'app-console-server-list',
  templateUrl: './console-server-list.component.html',
})
export class ConsoleServerListComponent extends BlsCommon implements OnInit {
  serverLists: any = [];
  projectList: any = [];
  portList: any = [];
  selected_project: any = {};
  selected_server: any = {
    image_snapshot_name: '',
    image_snapshot_description: '',
  };
  isAvailablePF: boolean;
  project_modal_name: String = '';
  project_modal_description: String = '';
  portforwardingParam: any;
  filterText: string;

  //포트포워딩 팝업 관련 변수
  portForwardingLists: any = [];

  // project 관련
  resource: any = [];

  //public ip
  ipList: any = [];
  selected_port_id = "";

  // component communicationn
  message: any = {};

  // subnet
  subnetList: any = [];
  networkList: any = [];
  // security_group
  securityGroupList: any = [];

  /*** 20200731 END*/

  isActived: boolean = false;
  isChecked: boolean = false;

  routeAction: string = "";

  is_server_list_loaded = false;
  is_portfowarding_list_loaded = false;
  is_security_group_loaded = false;
  is_subnet_loaded = false;

  panelOpenState: boolean = false;

  constructor(
    private osapi: OsapiService,
    private frontapi: FrontApiService,
    public global: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private actRoute: ActivatedRoute,
    @Inject(DOCUMENT) document,
    private elementRef: ElementRef,
    private dialogs: DialogSetService,
    @Inject(ConsoleNavComponent) private parent: ConsoleNavComponent,
  ) {
    super();
    this.routeAction = this.actRoute.snapshot.paramMap.get('action');
  }

  ngOnInit(): void {
    this.getServerList(false);
    this.getResource();
    this.getIpList();
    this.getSubnetList();
    this.getSecurityGroupList();

    this.frontapi.currentMessage.subscribe((message: any) => {
      this.message = message;

      if (message.type === 'portfowarding refresh') {
        this.portForwardingLists = message.body.portForwardingLists;
        this.serverLists = message.body.serverLists;
        this.parent.getServerList().then(() => {
          this.getServerList(false);
      });

      }

      if (message.type === 'serverlist refresh') {
        this.parent.getServerList().then(() => {

          this.getServerList(false);
        });
      }
    }, (error) => {

      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: 'Front API ERROR',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
    if (this.routeAction) {
      if (this.routeAction == 'portfowarding') {
        this.openPortForwardingModal();
      }
    }

  }


  // 전체선택
  onAllCheck(status) {
    this.isActived = status.isActived;
  }

  onServerListCheck(serverList) {
    let $_CHKEDLIST: any = document.getElementsByName('sel_chkbox');
    this.isActived = false;

    for (let i = 0; i < $_CHKEDLIST.length; i++) {
      let chkedbox: HTMLInputElement = $_CHKEDLIST[i];
      if (chkedbox.checked) {
        chkedbox.checked = false;
      }
      if (chkedbox.getAttribute('data-id') == serverList.server_id) {
        chkedbox.checked = true;
        this.onClickList(serverList);
        this.isActived = true;
      }
    }
  }
  onClickList(server) {
    // console.log(server);
    this.isActived = true;
    this.selected_server = server;
    if (server.network_interfaces[0]) {
      this.selected_port_id = server.network_interfaces[0].port_id + '_' + server.network_interfaces[0].addr;
    } else {
      this.selected_port_id = ""
    }
    this.selected_server.image_snapshot_name = this.selected_server.server_name + '의 스냅샷';
    // this.isAvailablePF = this.isAvailablePF_(server.ip_private[0]);

    // console.log(server);
    
    if(server.network_interfaces[0]){
      this.isAvailablePF = this.isAvailablePF_(server.network_interfaces[0].addr);
    }

    this.setPfParam();
    this.getPfIP(server.network_interfaces[0].ports[0].network_id);
    for (let sg of this.securityGroupList) {
      sg.checked = false;
      for (let selected_sg of server.security_groups) {
        if (sg.id == selected_sg.id) {
          sg.checked = true;
        }
        // else {
        //   sg.checked = false || sg.checked;
        // }
      }
    }
  }


  openServerStartModal() {

    this.dialogs.openDefaultConfirmDialog({
      title: "서버 시작",
      content: "'" + this.selected_server.server_name + "'를 실행합니다"
    });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.selected_server.status = "ACTIVATING";
        this.startServer(this.selected_server.server_id);
      }
    });
  }
  openServerStopModal() {
    this.dialogs.openDefaultConfirmDialog({
      title: "서버 정지",
      content: "'" + this.selected_server.server_name + "'를 정지하시겠습니까?"
    });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.selected_server.status = "SHUTTING DOWN";
        this.stopServer(this.selected_server.server_id);
      }
    });
  }
  openServerRestartModal() {
    this.dialogs.openDefaultConfirmDialog({
      title: "서버 재시작",
      content: "'" + this.selected_server.server_name + "'를 재시작하시겠습니까?"
    });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {
        this.selected_server.status = "REBOOT";
        this.softRebootServer(this.selected_server.server_id);
      }
    });
  }
  openServerUpdateModal() {
    this.dialogs.openServerUpdateDialog({ selected_server: this.selected_server });
  }
  openServerResizeModal() {
    this.dialogs.openServerResizeDialog({ selected_server: this.selected_server });
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "success") {
        this.selected_server.status = "RESIZE";
        this.reloadServerStatus(this.selected_server.server_id, ['ACTIVE','SHUTOFF', 'ERROR']);
      }
    })
  }
  openServerDeleteModal() {
    this.dialogs.openDefaultDeleteDialog({
      title: "서버 인스턴스 삭제",
      content: "선택하신 서버 인스턴스(" + this.selected_server.server_name + ")를 정말로 삭제하시겠습니까?<br>한 번 삭제한 인스턴스는 복구할 수 없습니다."
    });
    // this.global.openNewFloatingIpModal();
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "okay") {

        //let portForwarding = this.portForwardingLists.filter(o => o.port_forwarding_id == this.selected_server.port_forwarding_id);

        /***
         * 20200916 cbkim
         * 서버 삭제시 포트포워딩 찾아서 삭제
         */

        for (let pf of this.portForwardingLists) {
          for (let ip of this.selected_server.ip_private) {
            if (pf.internal_ip_address == ip) {
              this.deletePortForwarding(this.selected_server.server_id, pf);
              return;
            }
          }
        }
        this.deletePortForwarding(this.selected_server.server_id, false);
      }
    });
  }
  openServerImageCreateModal() {
    this.dialogs.openServerImageCreateDialog({ serverList: this.serverLists, selected_server: this.selected_server });
  }
  openInterfaceModal() {
    this.dialogs.openServerInterfaceUpdateDialog({ selected_server: this.selected_server, subnetList: this.subnetList });
  }
  openSecurityGroupModal() {
    if (this.is_security_group_loaded) {
      this.dialogs.openServerSecurityGroupUpdateDialog({ securityGroupList: this.securityGroupList, selected_server: this.selected_server });
      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {
          setTimeout(() => {
            this.parent.getServerList().then(() => {
              this.router.navigateByUrl('/console', {
                skipLocationChange: true,
              }).then(() => { this.router.navigate(['/console/server']); });
            });
          }, 1000)
        }
      });
    } else {
      setTimeout(() => { this.openSecurityGroupModal(); }, 500);
    }
  }
  openPortForwardingModal() {
    if (this.is_server_list_loaded && this.is_portfowarding_list_loaded) {
      this.dialogs.openServerPortforwardingUpdateDialog({
        serverLists: this.serverLists,
        resource: this.resource,
        portForwardingLists: this.portForwardingLists
      });
    } else {
      setTimeout(() => { this.openPortForwardingModal(); }, 500);
    }
  }
  openIpConnectModal() {
    let flag = false;
    for (let ip of this.ipList) {
      if (ip.fixed_ip == null) {
        flag = true;
        // this.seleted_floatingip_id = ip.id;
        break;
      }
    }
    if (flag) {
      this.dialogs.openAssociateIpDialog({
        ipList: this.ipList,
        portList: this.portList,
        selected_port_id: this.selected_port_id
      });

      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {
          setTimeout(() => {
            this.parent.getServerList().then(() => {
              this.router.navigateByUrl('/console', {
                skipLocationChange: true,
              }).then(() => { this.router.navigate(['/console/server']); });
            });
          }, 1000)
        }
      });
    } else {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '연결할 공인 IP가 없습니다.'
        }
      });
      this.dialogs.openCreateIpDialog({});
      this.dialogs.setAfterClosed().subscribe(result => {
        if (result == "success") {
          this.router.navigateByUrl('/console', {
            skipLocationChange: true,
          }).then(
            () => {
              this.router.navigateByUrl('/console/server');
            }
          );
        }
      });

    }
  }
  openIpDisassociateModal() {

    let seleted_floatingip_id = null;
    for (let fip of this.ipList) {
      if (fip.instance_id == this.selected_server.server_id) {
        seleted_floatingip_id = fip.id;
      }
    }
    if (seleted_floatingip_id == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '해제할 공인 IP가 없습니다.'
        }
      });

      return;
    }

    const selected_floatingip = { id: seleted_floatingip_id, ip: this.selected_server.ip_public, server: this.selected_server, fixed_ip: this.selected_server.ip_private };

    this.dialogs.openDisassociateIpDialog(
      {
        selected_floatingip: selected_floatingip
      }
    );
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "success") {
        setTimeout(() => {
          this.parent.getServerList().then(() => {
            this.router.navigateByUrl('/console', {
              skipLocationChange: true,
            }).then(() => { this.router.navigate(['/console/server']); });
          });
        }, 1000)
      }
    });
  }
  openAdminPasswordModal() {
    this.dialogs.openServerWindowPasswordDialog({ selected_server: this.selected_server });
  }

  getSecurityGroupList() {
    this.osapi.getSecurityGroupList().subscribe((data: any) => {

      this.is_security_group_loaded = true;
      this.securityGroupList = data.items;
    }, (error) => {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }


  getSubnetList() {
    /** 
     * 20200902 cbkim
     * 다른 프로젝트 공유 된 네트워크 서브넷 찾아오기 
     */

    this.osapi.getSubnetList().subscribe((data: any) => {
      this.subnetList = [];
      this.subnetList = data;

      this.osapi.getNetworkList().subscribe(
        (networks: any) => {
          this.is_subnet_loaded = true;
          this.networkList = networks;
          for (let network of networks) {
            // 네트워크 소유자가 아니면

            if (!network.isNetworkOwner) {
              for (let sub of network.subnets) {
                this.subnetList.push(sub);
              }
            }
          }
          this.setSubnetParam();
        }
      )
    }, (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  setSubnetParam(){
    if(this.is_subnet_loaded && this.is_server_list_loaded){
      // console.log("setSUBNET");
      let serverlist = this.serverLists;
      for(let server_data of serverlist){
        // console.log(server_data.ip_private );
        for(let i=0; i< server_data.ip_private.length ; i++){
          for(let port of server_data.network_interfaces[0].ports){
            if(port.fixed_ips[0].ip_address == server_data.ip_private[i]){
              for(let subnet_data of this.subnetList){
                if(subnet_data.id === port.fixed_ips[0].subnet_id){
                  server_data.ip_private[i] = server_data.ip_private[i] + " ("+subnet_data.name+" : "+subnet_data.cidr+")";
                  break;
                }
              }
              break;
            }
          }
        }
      }

    }

  }
  setPfParam() {
    this.portforwardingParam = {
      protocol: 'tcp',
      internal_ip_address: this.selected_server.port_forwarding_int,
      internal_port: '22',
      // internal_port_id: "ae43d049-9cd1-4ee1-92f2-790784982db8",
      internal_port_id: this.selected_server.port_forwarding_port_id,
      external_port: '',
    };
  }

  onChangeImageSnapshotName(image_name) {
    this.selected_server.image_snapshot_name = image_name;
  }
  //Status 자동갱신
  reloadServerStatus(server_id, status, setting_status?) {
    if (!Array.isArray(status)) {
      throw 'status required Array Object';
    }
    this.osapi
      .getServer(server_id)
      .subscribe((data: any) => {
        let server = this.serverLists.find(e=> e.server_id == server_id);
        // server = data;
        if(setting_status){
          server.status = setting_status;
        }else{
          server.status = data.status;
        }
        // console.log(data)
        if(data.image_name){
          server.image_name = data.image_name;
        }
        if(data.flavor_name){
          server.flavor_name = data.flavor_name;
        }
        if(data.flavor_id){
          server.flavor_id = data.flavor_id;
        }
        if(data.ip_private){
          server.ip_private = data.ip_private;
        }
        if(data.network_interfaces){
          server.network_interfaces = data.network_interfaces;
        }
        // server.volume_list = data.volume_list;
        // console.log(data);
        // this.serverLists.forEach((server) => {
        //   if (data.id == server.server_id) {
        //     server.status = data.status;
        //   }
        // });

        if (status.indexOf(data.status.toUpperCase()) != -1) {
          server.status = data.status;

          this.parent.isLoadedServer = false;
          this.parent.getServerList();
          this.getServerList(true);
          return 'stop reload';
        }
        if(setting_status){
          this.reloadServerStatus(server_id, status,setting_status);
        }else{
          this.reloadServerStatus(server_id, status);
        }
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
  }

  getServerList(nospinner) {
    if (!nospinner) {
      this.spinner.show();
    }

    if (!this.parent.isLoadedServer) {
      setTimeout(() => {
        this.getServerList(nospinner);
      }, 200)
    } else {
      this.serverLists =[];
      // console.log(this.parent.serverList)
      this.serverLists = this.parent.serverList;
      //  console.log(this.serverLists)
      for (let server of this.serverLists) {
        server.is_remote_access = false;
        server.remote_status_msg = "접속 차단"
        server.remote_acecss_msg = "보안그룹 규칙 없음"
        for (let groups of server.security_groups) {
          for (let rule of groups.security_group_rules) {
            if (rule.port_range_max == null && rule.port_range_min == null && 
              (rule.protocol == null || rule.protocol == "tcp") && rule.direction == "ingress"){
                server.is_remote_access = true;  
                server.remote_status_msg = "접속 허용 (ALL)"
                server.remote_acecss_msg = "모든 포트 수신 허용"
                break;
            }
            if (server.os_type === "win") {
              if (rule.port_range_min <= 3389 && rule.port_range_max >= 3389) {
                server.is_remote_access = true;
                server.remote_status_msg = "접속 허용 (3389)"
                server.remote_acecss_msg = "3389포트 수신 허용"
              }
            } else {
              if (rule.port_range_min <= 22 && rule.port_range_max >= 22) {
                server.is_remote_access = true;
                server.remote_status_msg = "접속 허용 (22)"
                server.remote_acecss_msg = "22포트 수신 허용"
              }
            }
          }
        }
      }
      this.parent.getThemeFont();
      this.is_server_list_loaded = true;
      this.setSubnetParam();
      this.getPfList();
      this.getPortList();
      this.spinner.hide();

      this.serverLists.forEach((server) => {
        if (
          server.status.toUpperCase() == 'BUILD' ||
          server.status.toUpperCase() == 'REBOOT' ||
          server.status.toUpperCase() == 'RESIZE' ||
          server.status.toUpperCase() == 'VERIFY_RESIZE'
        ) {
          if(server.status.toUpperCase() == 'VERIFY_RESIZE'){
            this.osapi.confirmResize(server.server_id).subscribe(
              (data: {}) => {
                this.reloadServerStatus(server.server_id, [
                  'ACTIVE','SHUTOFF',
                  'ERROR',
                ]);
              }
            );
          }else{
            this.reloadServerStatus(server.server_id, [
              'ACTIVE',
              'ERROR',
            ]);
          }
        }
      });
    }
  }

  getIpList() {
    this.osapi
      .getFloatingipList()
      .subscribe((data: { items: any }) => {
        data.items.forEach((fip) => {
          if (fip.is_port_forwarding) {
            // this.portfowarding_ip = fip;
          } else {
            this.ipList.push(fip);
          }
        });
      }, (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  getPortList() {

    if (!this.is_subnet_loaded) {
      setTimeout(() => {
        this.getPortList()
      }, 200);
    } else {
      this.portList=[];
      this.serverLists.forEach((server) => {
        if (
          server.ip_public == null &&
          server.network_interfaces != null &&
          server.port_forwarding_ip == null
        ) {
          server.network_interfaces.forEach((net_interface) => {

            net_interface.ports.forEach((port) => {

              for (let network of this.networkList) {
                if (network.network_type === "int") {


                  if (port.network_id == network.id) {
                    return;
                  }
                }
              }
              let tmp_port: {
                port_id: string;
                server_id: string;
                server_name: string;
                interface_name: string;
                address: string;
                subnet_id: string;
                port_security_enabled: boolean;
                security_groups: [];
              };
              tmp_port = {
                port_id: port.id,
                server_id: server.server_id,
                server_name: server.server_name,
                interface_name: net_interface.name,
                address: port.fixed_ips[0].ip_address,
                subnet_id: port.fixed_ips[0].subnet_id,
                port_security_enabled: port.port_security_enabled,
                security_groups: port.security_groups,
              };
              this.portList.push(tmp_port);
            });
          });
        }
      });
    }
  }

  getPfList() {
    this.spinner.show();
    try {
      this.osapi.getPfList().subscribe((data: Array<any>) => {
        for(let server of this.serverLists){
          server.is_port_forwarding =false;
        }
        var t = data.map((item) => {
          this.serverLists.forEach((server) => {

            /**
             * 20200923 cbkim
             * 생성 시 network_interfaces 없음, 예외처리 추가
             */
            if (server.network_interfaces) {
              if (server.network_interfaces.length > 0) {
                for(let net_ifc of server.network_interfaces){
                  if (
                    net_ifc.adresses.indexOf(item.internal_ip_address) > -1
                  ) {
                    item['server_name'] = server.server_name;
                    item['port_forwarding_id'] = server.port_forwarding_id;
                    server.is_port_forwarding =true;
                  }
                }
              }
            }
          });
          return item;
        });
        this.portForwardingLists = t;

        this.is_portfowarding_list_loaded = true;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
      this.spinner.hide();
    }
  }

  //하이퍼바이저를 이용한 일시정지
  suspendServer(server_id) {
    this.spinner.show();
    this.osapi.suspendServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
    this.reloadServerStatus(server_id, ['SUSPENDED', 'ERROR']);
  }
  //하이퍼바이저 일시정지 상태 복구
  resumeServer(server_id) {
    this.spinner.show();
    this.osapi.resumeServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      }, //success
      (error) => {
        this.spinner.hide();
      }
    );
    this.reloadServerStatus(server_id, ['ACTIVE', 'ERROR']);
  }
  //서버 종료
  stopServer(server_id) {
    this.spinner.show();
    this.osapi.stopServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      }, //success
      (error) => {

        this.spinner.hide();
      }
    );
    this.reloadServerStatus(server_id, ['SHUTOFF', 'ERROR'],"SHUTTING DOWN");
  }
  //서버 시작
  startServer(server_id) {
    this.spinner.show();
    this.osapi.startServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      }, //success
      (error) => {
        this.spinner.hide();
      }
    );
    this.reloadServerStatus(server_id, ['ACTIVE', 'ERROR'],"ACTIVATING");
  }
  //서버 OS 재시작 명령
  softRebootServer(server_id) {
    this.spinner.show();
    this.osapi.softRebootServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      }, //success
      (error) => {
        this.spinner.hide();
      }
    );
    this.reloadServerStatus(server_id, ['ACTIVE', 'ERROR'],"REBOOT");
  }
  //서버 강제 재시작
  hardRebootServer(server_id) {
    this.spinner.show();
    this.osapi.hardRebootServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      }, //success
      (error) => {
        this.spinner.hide();
      }
    );
    this.reloadServerStatus(server_id, ['ACTIVE', 'ERROR']);
  }
  deletePortForwarding(server_id, pf) {
    // console.log(pf);
    this.spinner.show();
    if (pf) {
      this.osapi
        .deletePortForwarding2(pf.fip_id, pf.id)
        .subscribe((data) => {
          this.deleteServer(server_id);
        },
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
    } else {
      this.deleteServer(server_id);
    }
  }

  afterDeleteServer(server_id) {
    this.parent.getServerList().then(() => {
      if (!this.parent.isLoadedServer) {
        setTimeout(() => {
          this.afterDeleteServer(server_id);
        }, 1000);
      } else {
        this.getServerList(false);
        let chk = false;
        for (let server of this.serverLists) {
          if (server.server_id == server_id) {
            chk = true;
            break;
          }
        }
        if (chk) {
          this.parent.isLoadedServer = false;
          this.parent.getServerList();
          this.afterDeleteServer(server_id);
        }
      }
    });
  }

  async deleteServer(server_id) {
    const idx = this.serverLists.findIndex(e=>e.server_id==server_id);
    if (idx > -1) this.serverLists.splice(idx, 1)
    /**
     * 20200921 cbkim
     * 서버 삭제 전 포트 찾아서 삭제
     */
    await this.osapi.deleteServer(server_id).toPromise();

    /**
     * 20201008 cbkim
     * 삭제 후로 변경
     */
    for (let interf of this.selected_server.network_interfaces) {
      for (let port of interf.ports) {
        //await this.osapi.serverInfDetach(this.selected_server.server_id,port.id).toPromise();
        await this.osapi.deletePort(port.network_id, port.id).toPromise();
      }
    }

    setTimeout(() => {
      this.parent.getServerList().then(() => {
        this.getServerList(false);
      })
    }, 5000);
    this.spinner.hide();
  }

  isAvailablePF_(server_ip) {
    let res = true;

    for (let pf of this.portForwardingLists) {
      if (pf.internal_ip_address === server_ip) {
        res = false;
        break;
      }
    }
    return res;
  }

  getResource() {
    this.osapi.getResourceAll().subscribe((data: {}) => {
      this.resource = data;
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  getPfIP(networkId) {
    this.osapi.getPfIP(networkId).subscribe((data) => {
      return (this.selected_server.pf = data);
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  contentRefresh() {
    this.parent.isLoadedServer = false;
    this.parent.getServerList().then(() => {
      this.getServerList(false);
    })
  }
}