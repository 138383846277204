import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';

declare function fn_modalOpen(id: any):any;

@Component({
  selector: 'app-admin-send-mail',
  templateUrl: './admin-send-mail.component.html',
})
export class AdminSendMailComponent implements OnInit {

  constructor(private webapi: WebApiService) { }
  userList: any = [];
  receivers :any
  
  sendForm = new FormGroup({
    receiver: new FormControl('', [
      Validators.required,
    ]),
    subject: new FormControl('', [
      Validators.required,
      Validators.maxLength(200),
    ]),
    text: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
    ]),
  });
  get sendChk() { return this.sendForm.controls; }

  //// List Filter
  _listFilter = '';
  get listFilter(): string {
      return this._listFilter;
  }
  userListFilter:any
  set listFilter(value: string) {
    this._listFilter = value;
    this.userListFilter = this.listFilter ? this.doFilter(this.listFilter) : this.userList;
  }
  doFilter(filterBy: string) {

    return this.userList.filter(userList =>
      userList.name.indexOf(filterBy) !== -1);
  }
  //// List Filter

  ngOnInit(): void {    
    this.getUserList();    
    this.listFilter = '';
  }

  getUserList() {
    this.webapi.getUserListWithStatus(2).subscribe((data: {}) => {
      this.userList = data['result'];
      this.userListFilter = data['result'];

    });
  }

  fn_send(){
    // receiver: 받는사람 이메일
    // subject: 제목
    // text: 본문

    let receiverGrp = "";
    let receiver=[];

    receiverGrp = this.sendForm.controls.receiver.value;

    receiver=receiverGrp.split(",");

    let msg = "";
    let valChk = true;

    for(let i=0;i<receiver.length;i++){
      const emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

      if(!emailVal.test(receiver[i])){
        valChk = false
        msg = receiver[i]+"은 이메일형식이 아닙니다.";
      }
    }

    if(valChk){
      for(let i=0;i<receiver.length;i++){
        this.sendForm.value.receiver = receiver[i];
        this.webapi.sendEmail(this.sendForm.value).subscribe((data: {}) => {});
        if(i==(receiver.length-1))
          alert("전송되었습니다.");
      }
    }else{
      alert("Err : "+msg)
    }
  }

  fn_getAdress(){
    fn_modalOpen('adressModal');
  }

  fn_getAdressReceiver(){
    this.receivers='';
    let chkReceiver = document.getElementsByName('userReceiver');

    for(let i=0; i < chkReceiver.length; i++){
      if((<HTMLInputElement>chkReceiver[i]).checked){
        this.receivers+=(<HTMLInputElement>chkReceiver[i]).value+",";
      }
    }
    this.receivers = this.receivers.substring(0,this.receivers.length-1);
    this.sendForm.controls.receiver.setValue(this.receivers);
    
    (<HTMLInputElement>document.getElementById('closeModalBtn')).click();
  }

  onSearch(e){

  }
}
