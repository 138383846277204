import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../../webapi.service';
import { AdminNavComponent } from '../../admin-nav/admin-nav.component';

declare function fn_modalOpen(id: any):any;

@Component({
  selector: 'app-admin-claim-payBoard',
  templateUrl: './admin-claim-payBoard.component.html',
})
export class AdminClaimPayBoardComponent implements OnInit {

  constructor(
    private webapi: WebApiService,
    private common: AdminNavComponent
  ) { }
  
  serviceList: any = [];
  selected_service: any;
  searchTitle: string;

  // 페이징 변수
  curPage: any;
  totalCount: any;

  serviceForm = new FormGroup({
    version: new FormControl('', [
      Validators.required,
    ]),
    code: new FormControl('', [
      Validators.required,
      Validators.maxLength(32),
    ]),    
    nameKo: new FormControl('', [
      Validators.maxLength(128),
    ]),
    nameEn: new FormControl('', [
      Validators.maxLength(128),
    ]),
    categoryMain: new FormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    categorySub: new FormControl('', [
      Validators.maxLength(64),
    ]),
    categorySpecific: new FormControl('', [
      Validators.maxLength(64),
    ]),
    minimum: new FormControl('', [
      Validators.maxLength(32),
    ]),
    per: new FormControl('', [
      Validators.maxLength(32),
    ]),
    enabled: new FormControl(true, [
      Validators.required,
    ]),
    priceHour: new FormControl('', [
      Validators.required,
    ]),
    priceDay: new FormControl('', [
      Validators.required,
    ]),
    priceMonth: new FormControl('', [
      Validators.required,
    ]),
  });
  get serviceChk() { return this.serviceForm.controls; }

  
  updateForm = new FormGroup({
    // serviceId: new FormControl('', [
    //   Validators.required,
    // ]),
    version: new FormControl('', [
      Validators.required,
    ]),
    code: new FormControl('', [
      Validators.required,
      Validators.maxLength(32),
    ]),
    nameKo: new FormControl('', [
      Validators.maxLength(128),
    ]),
    nameEn: new FormControl('', [
      Validators.maxLength(128),
    ]),
    categoryMain: new FormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    categorySub: new FormControl('', [
      Validators.maxLength(64),
    ]),
    categorySpecific: new FormControl('', [
      Validators.maxLength(64),
    ]),
    minimum: new FormControl('', [
      Validators.maxLength(32),
    ]),
    per: new FormControl('', [
      Validators.maxLength(32),
    ]),
    enabled: new FormControl('', [
      Validators.required,
    ]),
    priceHour: new FormControl('', [
      Validators.required,
    ]),
    priceDay: new FormControl('', [
      Validators.required,
    ]),
    priceMonth: new FormControl('', [
      Validators.required,
    ]),
  });
  get updateChk() { return this.updateForm.controls; }

  ngOnInit(): void {
    this.getServiceList(0);
  }

  getServiceList(page){
    this.curPage = page;    
    let searchQuerys = "o=service_code.DESC&l=10&s="+(this.curPage * 10);

    // 검색 조건
    if (this.searchTitle){
      searchQuerys += `&nameKo=${decodeURIComponent(this.searchTitle)}&lk=nameKo`;
    }
    
    this.webapi.getServiceList(searchQuerys).subscribe((data) => {

      this.serviceList = data['result'];
      this.totalCount = data['total'];
      this.common.getThemeFont();
    });
  }

  getServiceDetail(service) {
    this.selected_service = service;
    this.updateForm.reset();
  }

  updateModal(){    
    if (this.selected_service){
      this.updateForm.setValue({
        // serviceId: this.selected_service.serviceId,
        version: this.selected_service.version,
        code: this.selected_service.code,
        nameKo: this.selected_service.nameKo,
        nameEn: this.selected_service.nameEn,
        categoryMain: this.selected_service.categoryMain,
        categorySub: this.selected_service.categorySub,
        categorySpecific: this.selected_service.categorySpecific,
        minimum: this.selected_service.minimum,
        per: this.selected_service.per,
        enabled: this.selected_service.enabled,
        priceHour: this.selected_service.priceHour,
        priceDay: this.selected_service.priceDay,
        priceMonth: this.selected_service.priceMonth,
      });

      fn_modalOpen("update_service");
    }    
  }

  onSubmit(e){
    this.webapi.createService(this.serviceForm.value).subscribe((data) => {
      if (data){
        alert("저장되었습니다.");
        document.getElementById('close-create').click();
        this.serviceForm.reset({enabled:true});
        this.getServiceList(0);
      }
    });
  }

  onUpdate(e){
    this.webapi.updateService(this.updateForm.value).subscribe((data) => {
      if (data){
        alert("수정이 완료되었습니다.");
        document.getElementById('close-update').click();
        this.getServiceList(0);
      }      
    });
  }

  // 페이징 함수
  getMovePage(e){
    this.getServiceList(e.page)
  }  

  // 검색
  onSearch(title){
    this.searchTitle = title;
    this.getServiceList(0);
  }

  onSearchChange(deviceValue){
    this.searchTitle = deviceValue;
  }  

  deleteService(){
    this.webapi.deleteService(this.selected_service).subscribe((data) => {
      if (data){
        alert("삭제가 완료되었습니다.");
        document.getElementById('close-modal').click();
        this.getServiceList(0);
      }
    });
  }

}
