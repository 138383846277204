import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebApiService } from '../../../webapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare const $: any;
@Component({
  selector: 'app-mypage-payment',
  templateUrl: './mypage-payment.component.html',
})
export class MypagePaymentComponent implements OnInit {
  userInfoList : any = [];
  submitted = false;
  activePaymentType: string= '';
  paymentTypeList:any = [];
  companypaymentwayId: string = '';
  paymentWayResult: any = [];
  //결제수단 타입
  selPaymentType = "";

  //결제수단 은행
  paymentBankForm = new FormGroup({
    accountNumber : new FormControl('',[
      Validators.required,
      Validators.pattern(/[0-9]/),
    ]),
    bank: new FormControl('',[
      Validators.required,
      Validators.pattern(/[가-힣]/)
    ]),
    accountName: new FormControl('',[
      Validators.required,
      Validators.pattern(/[가-힣]/)
    ])
  })
  get bankForm(){
    return this.paymentBankForm.controls
  }

  //결제수단 카드
  paymentCardForm = new FormGroup({
    cardNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/[0-9]/),
      Validators.minLength(11)
    ]),    
    cardCvc: new FormControl('',[
      Validators.required,
      Validators.pattern(/[0-9]/),
      Validators.minLength(3)
    ]),
    cardExpire: new FormControl('', [
      Validators.required,
      Validators.pattern(/[0-9]/),
      Validators.minLength(4)
    ]),
    cardName: new FormControl('',[
      Validators.required
    ])
  })
  get cardForm(){
    return this.paymentCardForm.controls
  }

  paymentAcountForm = new FormGroup({
    blsAcountNum : new FormControl('',[
      Validators.required,
      Validators.pattern(/[0-9]/)
    ]),
    managerName : new FormControl('',[
      Validators.required
    ]),
    telephone: new FormControl('',[
      Validators.required
    ]),
    email: new FormControl('',[
      Validators.required
    ]),    
  })

  get acountForm(){
    return this.paymentAcountForm.controls
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private webapi: WebApiService,
    private spiner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.renderer.removeClass(this.document.getElementById('Wrap'), 'main');
    this.userInfoList = JSON.parse(localStorage.getItem('userInfo'))
    this.spiner.show();
    this.webapi.getCompanyPaymentWay().subscribe(
      (response) => {

        this.paymentTypeList = response.body.result
      }, (err) => {

      }
    )
    this.spiner.hide();
    this.getCompanyPaymentWayActive();
  }

  getCompanyPaymentWayActive(){
    var searchQuerys = "registration_number="+this.userInfoList.registrationNumber+"&registration_number_sub="+this.userInfoList.registrationNumberSub+"&active=true";
    this.spiner.show();
    this.webapi.getCompanyPaymentWayActive(searchQuerys).subscribe(
      (response) => {

        if(response.body.result.length === 0){
          this.selPaymentType = '1'
          this.activePaymentType = '0'
        }else {
          const data = response.body.result[0]
          this.paymentWayResult = data
          this.activePaymentType = data['paymentwayId'];
          this.selPaymentType = data['paymentwayId'];
          this.companypaymentwayId = data['companypaymentwayId'];

          this.paymentBankForm.patchValue({
            accountNumber: data['accountNumber'],
            bank : data['bank'],
            accountName : data['accountName']
          })
          this.paymentCardForm.patchValue({
            cardNumber: data['cardNumber'],
            cardExpire: data['cardExpire'],
            cardCvc: data['cardCvc'],
            cardName: data['cardName'],
          })
        }
        $("input:radio[name='paymentTypeRadio']:radio[value='"+this.activePaymentType+"']").prop('checked', true); 
      }, (err) => {

      }
    )
    this.spiner.hide();
  }

  changePayType(e) {
    this.submitted = false;    
    this.selPaymentType = e.target.value
  }

  onSubmitBank():void {
    this.submitted = true;
      if (this.paymentBankForm.invalid) {
        const invalid = [];
        const controls = this.paymentBankForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }

      return;
    }
    if(this.companypaymentwayId === '' || this.companypaymentwayId === null || this.companypaymentwayId === undefined){
      this.webapi.postPaymentWayActive({paymentwayId: this.selPaymentType, companyId: this.userInfoList.companyId, ...this.paymentBankForm.value}).subscribe(
      (response) => {
        alert('결제 수단이 등록 되었습니다.')
        this.getCompanyPaymentWayActive();
      }, (err) => {

      }
    )
    }else {
      this.webapi.updataPaymentWayActive({companypaymentwayId: this.companypaymentwayId, paymentwayId: this.selPaymentType, companyId: this.userInfoList.companyId, ...this.paymentBankForm.value}).subscribe(
        (response) => {
          alert('결제 수단이 변경 되었습니다.')
          this.getCompanyPaymentWayActive();
        },(err) => {

        }
      )
    }
  }

  onSubminCard():void {
    this.submitted = true;
      if (this.paymentCardForm.invalid) {
        const invalid = [];
        const controls = this.paymentCardForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }

      return;
    }

    if(this.companypaymentwayId === '' || this.companypaymentwayId === null || this.companypaymentwayId === undefined){
      this.webapi.postPaymentWayActive({paymentwayId: this.selPaymentType, companyId: this.userInfoList.companyId, ...this.paymentCardForm.value}).subscribe(
        (response) => {

          alert('결제 수단이 등록되었습니다.')
          this.getCompanyPaymentWayActive();
        },(err) => {

        }
      )
    }else {
      this.webapi.updataPaymentWayActive({companypaymentwayId: this.companypaymentwayId, paymentwayId: this.selPaymentType, companyId: this.userInfoList.companyId,...this.paymentCardForm.value}).subscribe(
        (response) => {

          alert('결제 수단이 변경되었습니다.')
          this.getCompanyPaymentWayActive();
        },(err) => {

        }
      )
    }  
  }

  onSubmitAcount(): void {    
    if(this.companypaymentwayId === '' || this.companypaymentwayId === null || this.companypaymentwayId === undefined){
      this.webapi.postPaymentWayActive({paymentwayId: this.selPaymentType, companyId: this.userInfoList.companyId}).subscribe(
        (response) => {

          alert('결제 수단이 등록되었습니다.')
          this.getCompanyPaymentWayActive();
        },(err) => {

        }
      )
    }else {
      this.webapi.updataPaymentWayActive({companypaymentwayId: this.companypaymentwayId, paymentwayId: this.selPaymentType, companyId: this.userInfoList.companyId}).subscribe(
        (response) => {

          alert('결제 수단이 변경되었습니다.')
          this.getCompanyPaymentWayActive();
        },(err) => {

        }
      )
    }
  }

}
