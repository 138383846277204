  <div class="container">
    <div class="header pt_30"> 
      <ol class="breadcrumb">
        <li><a href="#">Network</a></li>
        <li class="active">Security Group</li>
        <li class="active">Rules</li>
      </ol>
    </div>
    <div class="section">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="card-action">
              <button class="waves-effect waves-light darken white-text wd_30 rule_btn"
                (click)="openDialogCreate();">규칙 추가</button>
          </div>
          <div *ngIf="_blank; else noneData;">
            <div class="card" *ngFor="let item of securityGroupRuleLists">
              <div class="card-content">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title class="mFonts">
                          <i class="material-icons">rule</i>
                      </mat-panel-title>
                      <mat-panel-title class="mFonts">
                        <p *ngIf="item.direction=='ingress'">수신-</p>
                        <p *ngIf="item.direction=='egress'">송신-</p>
                        <p *ngIf="item.from_port == null ||item.to_port == null; else titleportNotNull">전체</p>
                        <ng-template #titleportNotNull>
                            <p *ngIf="item.to_port == item.from_port">
                                {{item.to_port}}
                                <ng-template [ngIf]="item.to_port=='80'">(HTTP)</ng-template>
                                <ng-template [ngIf]="item.to_port=='443'">(HTTPS)</ng-template>
                                <ng-template [ngIf]="item.to_port=='22'">(SSH)</ng-template>
                            </p>
                            <p *ngIf="item.to_port != item.from_port">{{item.from_port}} ~ {{item.to_port}}</p>
                        </ng-template>
                        <p>-{{item.ip_range.cidr}}</p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="tbl_dtl_tp2">
                      <tbody>
                      <tr>
                        <th>송/수신</th>
                        <td *ngIf="item.direction=='ingress'">수신</td>
                        <td *ngIf="item.direction=='egress'">송신</td>
                      </tr>
                      <tr>
                        <th>Ether 타입</th>
                        <td>{{item.ethertype}}</td>
                      </tr>
                      <tr>
                        <th>IP 프로토콜</th>
                        <td *ngIf="item.ip_protocol != null; else protocolNull">{{item.ip_protocol}}</td>
                        <ng-template #protocolNull>
                            <td>전체</td>
                        </ng-template>
                      </tr>
                      <tr>
                        <th>포트 범위</th>
                        <td *ngIf="item.from_port == null ||item.to_port == null; else portNotNull">전체</td>
                        <ng-template #portNotNull>
                            <td *ngIf="item.to_port == item.from_port">
                                {{item.to_port}}
                                <ng-template [ngIf]="item.to_port=='80'">(HTTP)</ng-template>
                                <ng-template [ngIf]="item.to_port=='443'">(HTTPS)</ng-template>
                                <ng-template [ngIf]="item.to_port=='22'">(SSH)</ng-template>
                            </td>
                            <td *ngIf="item.to_port != item.from_port">{{item.from_port}} ~ {{item.to_port}}</td>
                        </ng-template>
                      </tr>
                      <tr>
                        <th>CIDR</th>
                        <td>{{item.ip_range.cidr}}</td>
                      </tr>
                      <tr>
                        <th>설명</th>
                        <td>
                          <ng-template [ngIf]="item.description==null || item.description=='' ">
                            없음
                          </ng-template>
                          <ng-template [ngIf]="item.description!=null && item.description!='' ">
                              {{item.description}}
                          </ng-template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="card-content"> 
                      <button class="waves-effect waves-light btn darken white-text"
                      (click)="openDialogUpdate(item)"
                      >수정</button>
                      <button class="waves-effect waves-light btn materialize-red darken-2 white-text"
                      (click)="openDialogDelete(item)"
                      >삭제</button>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
          <ng-template #noneData>
            <h5 class="none_data">데이터 없음</h5>
          </ng-template>
        </div> 
      </div>
    </div>
    <div class="section">
      <div class="row">
      </div>
    </div>
    <br><br>
    <div class="section">
    </div>
  </div>