<!-- Container -->
<div id="Container" class="notice">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>공지사항</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_header -->
                <!-- <div class="sec_header">
                    <h3>공지사항</h3>
                    <div class="subTit">
                        서비스 안내와 공지, 신규 서비스에 대한 소식을 전해드립니다.
                    </div>
                </div> -->
                <!-- //sec_header -->
                
                <!-- sec_content -->
                <div class="sec_content">
                    <!-- searchArea -->
                    <div class="searchArea">
                        <div class="inp inpSelect">
                            <select [value]="searchCate" (change)="onSearchCateChange($event.target.value)">
                                <option value="">전체</option>
                                <option value="요금">요금</option>
                                <option value="사이트">사이트</option>
                                <option value="서비스">서비스</option>
                            </select>
                        </div>
                        <div class="inp inpSearch">
                            <input type="text" (keydown.enter)="fn_noticeSearch($event.target.value)" [value]="searchTitle" (change)="onSearchTitleChange($event.target.value)">
                            <button class="inpSearch-btn" (click)="fn_noticeSearch(searchTitle)"></button>
                        </div>
                    </div>
                    <!-- //searchArea -->

                    <div class="boardBox tp2">
                        <table class="tbl_col_tp1 pdTp2">
                            <colgroup>
                                <col style="width:8%">
                                <col style="width:12%">
                                <col >
                                <col style="width:12%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">번호</th>
                                    <th scope="col">카테고리</th>
                                    <th scope="col">제목</th>
                                    <th scope="col">등록일</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr style="background-color: #2c9cc6; color:white;">
                                    <td></td>
                                    <td></td>
                                    <td class="l"><a class="btn_console" href="http://service.bizlinkersys.com/" target="_blank" style="color:white; font-weight: 500;">베타서비스 오픈</a></td>
                                    <td style="color:white; font-weight: 500;">2021-08-13</td>
                                </tr> -->
                                <tr *ngFor="let notice of noticeList; index as i;">
                                    <td>{{ 10*curPage+i+1 }}</td>
                                    <td>{{ notice.category ? notice.category : "카테고리" }}</td>
                                    <td class="l"><a href="javascript:void(0)" (click)="getNoticeView(notice.articleId)">{{ notice.title }}</a></td>
                                    <td>{{ notice.createdDate.substring(0, 10) }}</td>
                                </tr>
                                <tr *ngIf="totalCount < 1">
                                    <td colspan="4">조회된 내역이 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>