import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-paging',
  templateUrl: './common-paging.component.html',
})
export class CommonPagingComponent implements OnInit,OnChanges {

  /** curPage 현재페이지                  필수값(-1)
   * totalCount 총 리스트갯수             필수값
   * 
   * rangeSize 하단에 보여질 페이지 갯수   default 10
   * rowCount 한화면에 표현될 페이지row수  default 10
   * 
   * 
   * */


  /** 현재 페이지 **/
  @Input()  curPage : any;  
  // 총 리스트갯수
  @Input()  totalCount: any;
  // 총 페이지 갯수
  @Input()  totalPageCount: any;
  // 총 블럭 갯수
  @Input()  totalRangeCount: any;

  // 블럭당 페이지 갯수
  @Input() rangeSize: any=10;
  // 페이지당 row 갯수
  @Input() rowCount: any=10;


  // 현재블럭
  curRange: any;
  
  // 시작페이지
  startPage: any;
  // 끝페이지
  endPage: any;

  prevPage: any;
  nextPage: any;

  // 페이지배열
  pageArr: any[];  

  @Output() changePageGo = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    //this.settingPaging();
  }

  ngOnChanges(){
    
    this.settingPaging();

  }

  settingPaging(){
    this.totalPageCount =1;

    // totalCount 가져오는 쿼리..
    if(this.totalCount){

      // page셋팅
      this.totalPageCount = Math.ceil(this.totalCount/this.rowCount)
      this.pageArr = new Array(this.totalPageCount);
      for(var i=0;i<this.totalPageCount;i++){
        this.pageArr[i] = i;
      }

      this.totalRangeCount = Math.ceil(this.totalPageCount/this.rangeSize)
    
      // ------------- 블럭 세팅 -------------- 
      // 현재 블럭
      this.curRange = Math.floor((this.curPage)/this.rangeSize) + 1;  
      
      this.startPage = (this.curRange - 1) * this.rangeSize + 1;
      this.endPage = this.startPage + this.rangeSize - 1;      
      
      if(this.endPage > this.totalPageCount){
          this.endPage = this.totalPageCount;
      }      
      this.prevPage = this.curPage - 1;
      this.nextPage = this.curPage + 1;
    }
  }

  chagnePage(page){
    this.changePageGo.emit({
      page:page
    })
  }
}
