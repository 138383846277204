<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Storage</li>
      <li class="active">SnapShot</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let snapshot of snapShotLists">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons"
                          [class]="snapshot.status === 'available' ? 'dotActive' : 'dotStop'">share</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts" *ngIf="snapshot.name?.length > 10; else ltitle;">
                      {{snapshot.name.substring(0, 10)+"...."}}
                    </mat-panel-title>
                    <ng-template #ltitle>
                      <mat-panel-title class="mFonts">
                        {{snapshot.name}}
                      </mat-panel-title>
                    </ng-template>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{{snapshot.name}}</td>
                    </tr>
                    <tr>
                      <th>설명</th>
                      <td *ngIf="snapshot.description != null && snapshot.description != ''; else elseCase">
                        {{ snapshot.description }}
                      </td>
                    </tr>
                    <tr>
                      <th>크기</th>
                      <td>{{snapshot.size}}GiB</td>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>
                        <label [class]="snapshot.status == 'available'? 'dot green': 'dot red'"></label>
                        {{ snapshot.status | uppercase }}
                      </td>
                    </tr>
                    <tr>
                      <th>그룹</th>
                      <td *ngIf="snapshot.volume_name != null; else elseCase">
                        {{ snapshot.volume_name }}
                      </td>
                    </tr>
                    <tr>
                      <th>스토리지</th>
                      <td></td>
                    </tr>
                    <ng-template #elseCase>
                      <td>없음</td>
                    </ng-template>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>