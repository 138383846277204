
<!-- Modal : 사설 네트워크 공유 관리 모달 -->
<div id="private_network_share" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
      <h2>
        사설 네트워크 공유 관리
        <p class="sub-txt">이 네트워크를 다른 프로젝트에 공유하시려면 목록에 추가해주세요.</p>
      </h2>
    </div>
    <!-- //modal_header -->
  
    <!-- modal_content -->
    <div class="modal_content">
      <div class="boardBox">
        <div class="titleBox">공유 목록</div>
        <div class="boardBox">
          <table class="tbl_col_tp1">
            <colgroup>
              <col style="width:30%">
              <col style="width:40%">
              <col style="width: 30%; min-width: 100px;" />
            </colgroup>
            <thead>
              <th>프로젝트명</th>
              <th>연결 된 인터페이스</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngIf="rbacList.length === 0">
                <td colspan="3">
                  공유 중인 프로젝트가 없습니다.
                </td>
              </tr>
              <tr *ngFor="let rbac of rbacList">
                <td>{{rbac.project_name}}</td>
                <td>
                  <p *ngFor="let port of rbac.ports">{{port.server_name}} {{port.ip_address}} 
                    <button (click)="detachInterface(port)" class="btn tiny dGray" style="margin-left:10px;">해제</button></p>
                </td>
                <td *ngIf="rbac.ports.length == 0">
                  <button class="btn sml dGray"(click)="deleteRBAC(rbac)">삭제</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="titleBox">추가 가능한 프로젝트 목록</div>
        <div class="boardBox">
          <table class="tbl_col_tp1">
            <colgroup>
              <col style="width:70%" />
              <col style="width:30%; min-width: 100px;" />
            </colgroup>
            <thead>
              <th colspan="2">프로젝트명</th>
            </thead>
            <tbody>
              <tr *ngIf="projectList.length === 0">
                <td colspan="2">추가 가능한 프로젝트가 없습니다.</td>
              </tr>
              <tr *ngFor="let project of projectList">
                <td>{{project.name}}</td>
                <td><button class="btn sml ciblue" (click)="createRBAC(project)">추가</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="btnArea tp1">
        <!-- <a  class="btn big ciblue mg_r4">적용</a> -->
        <a class="btn big bor_gray" (click)="closeDialog()">닫기</a>
      </div>
    </div>
    <!-- //modal_content -->
  </div>
  <!-- //Modal -->