<!-- Contents -->
<div id="Contents">
  <!-- content_header -->
  <div class="content_header col12">
    <h2>NAS</h2>
    <div class="lineMap">Storage / <span>NAS</span></div>
  </div>
  <!-- //content_header -->

  <!-- content_body -->
  <div class="content_body">
    <!-- section -->
    <section class="sec col12">
      <div class="sec_content">
        <!-- lyBox -->
        <div class="lyBox">
            <div class="title_wrap">
                <div class="contArea">
                    <div class="tit">
                        <app-console-projects></app-console-projects>
                    </div>
                    <div class="cont">
                        <app-console-storage-status></app-console-storage-status>
                    </div>
                    <div class="contSub">
                        <a class="btn mid blue mg_r4" (click)="openNewNasModal()"><i class="ico ico_register_white"></i>NAS 생성</a>
                        <!-- <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openDeleteNasModal()"><i class="ico ico_cancel_white"></i>NAS 삭제</button> -->
                        <a class="btn mid ciblue mg_r4" (click)="openManageServerModal()">서버연결 관리 </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="lyBox">
          <div class="title_wrap">
            <div class="contArea">
              <div class="tit">
                DefaultProject -->
                  <!-- <app-console-projects></app-console-projects> -->
              <!-- </div>
              <div class="cont">
                  <a class="btn mid blue mg_r4" (click)="openNewNasModal()"><i class="ico ico_register_white"></i>NAS 생성</a>
                  <button class="btn mid ciblue mg_r4" [disabled]="!isActived" (click)="openDeleteNasModal()"><i class="ico ico_cancel_white"></i>NAS 삭제</button>
                  <a class="btn mid blue mg_r4" (click)="openManageServerModal()"><i class="ico ico_register_white"></i>NAS 전용 네트워크 관리 </a>
              </div>
            </div>
          </div>
        </div> -->
        <!-- //lyBox -->
        <!-- lyBox -->
        <div class="lyBox consoleRightBody">
          <div class="titArea">
            <h3>NAS 목록</h3>
            <div class="btnArea">
              <div class="input-group fl">
                <input
                  type="search"
                  class="form-control"
                  name=""
                  [(ngModel)]="filterText"
                  id="search"
                  value=""
                  placeholder="검색어를 입력해주세요"
                />
                <div class="input-group-btn pd_r10">
                  <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                </div>
                <!-- 접기 버튼 -->
                <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
              </div>
            </div>
          </div>
          <div class="contArea" style="display: block;">
            <div class="boardBox">
              <table class="tbl_col_tp1 dropmenu pdTp2">
                <colgroup>
                  <col style="width: 5%;">
                  <col style="width: 15%;"/>
                  <col style="width: 19%;"/>
                  <col style="width: 14%;"/>
                  <col style="width: 14%;"/>
                  <col style="width: 34%;"/>
                  <!-- <col style="width: 10%;"/> -->
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                    </th>
                    <th scope="col">이름</th>
                    <th scope="col">설명</th>
                    <th scope="col">크기(GB)</th>
                    <th scope="col">상태</th>
                    <th scope="col">접속경로</th>
                    <!-- <th scope="col">생성일</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let nas of nasList  | tableFilter: filterText"
                    (contextmenu)="onClickList(nas)"
                  >
                    <td>
                      <input type="checkbox" name="sel_chkbox" (change)="onListCheck($event)">
                    </td>
                    <td>{{ nas.name }}</td>
                    <ng-template [ngIf]="nas.description==null || nas.description==''">
                      <td>없음</td>
                    </ng-template>
                    <ng-template [ngIf]="nas.description">
                      <td>{{ nas.description }}</td>
                    </ng-template>
                    <td>{{ nas.size }} GB</td>
                    <td>
                      <label class="dot green"></label
                      >{{ nas.status | uppercase }}
                    </td>
                    <td>
                      <div class="runbox">
                        <div style="-webkit-user-select: text;-moz-user-select: text;-ms-user-select: text;user-select: text;" class="lang" id="copyText">{{ nas.export_locations.path||"" }}</div>
                        <button class="btn tiny bor_gray" (click)="clipboardCopy($event)" title="Copy"></button>
                      </div>                      
                    </td>
                    <!-- <td>{{ nas.export_location.created_at.split("T")[0] }}</td> -->
                  </tr>
                </tbody>
              </table>

              <div class="btn_wrap tp1"></div>
            </div>
          </div>
        </div>
        <!-- //lyBox -->

        <!-- //lyBox -->
      </div>
    </section>
    <!-- //section -->
  </div>
  <!-- //content_body -->
</div>
<!-- //Contents -->

<!-- dropdown menu -->
<div class="dropdown">
  <div id="myDropdown" class="dropdown-content">
    <a (click)="openExpansionNasModal()">크기 증설</a>
    <a (click)="openManageServerModal()">서버 연결 관리</a>
    <a (click)="openManageRuleModal()">접근 규칙 관리</a>
    <a (click)="openDeleteNasModal()">NAS 삭제</a>
  </div>
</div>



