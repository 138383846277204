import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-private-network-list',
  templateUrl: './mobile-console-private-network-list.component.html',
  styleUrls: ['./mobile-console-private-network-list.component.css']
})
export class MobileConsolePrivateNetworkListComponent implements OnInit {
  networkLists: any = [];
  _blank =true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getNetworkList();
  }

  getNetworkList() {
    this.osapi.getNetworkList().subscribe(
      (data: any) => {
        this.networkLists = [];

        for (let network of data) {
          if (network.network_type == 'int') {
            this.networkLists.push(network);
          }
        }
        this._blank = ((this.networkLists != 0) ? true : false);
        this.spinner.hide();
      }
    ), (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    }
  }
}
