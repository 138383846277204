

<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>게시판관리</h2>
		<div class="lineMap">
				게시판관리 /  <span>1:1문의</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>1:1문의</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" (keydown.enter)="fn_inquirySearch($event.target.value)" [value]="searchTitle" (change)="onSearchTitleChange($event.target.value)" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid dBlue" (click)="fn_inquirySearch(searchTitle)"><i class="ico ico_search_white"></i>검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block"> 

						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
								<colgroup>
									<col style="width:5%">
									<col style="width:10%">
									<col style="width:40%">
									<col style="width:25%">
									<col style="width:10%">
									<col style="width:10%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">카테고리</th>
										<th scope="col">제 목</th>
										<th scope="col">등록자</th>
										<th scope="col">등록일</th>
										<th scope="col">상 태</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let inquiry of inquiryList; index as i;" (click)="fn_detailInquiry(inquiry.issueId)">
										<td>{{10*curPage+i+1}}</td>
										<td>{{ inquiry.category }}</td>
										<td style="text-align: left;">
											<a>{{ inquiry.title }}</a>
										</td>
										<td>
											<a>{{ inquiry.userId }}</a>
										</td>
										<td>{{ inquiry.createdDate.substring(0, 10) }}</td>
										<td>
											<label [class]="inquiry.completed ? 'btn tiny red radius' : 'btn tiny blue radius'">
												{{ inquiry.completed ? "처리완료" : "처리중" }}
											</label>
										</td>
									</tr>
								</tbody>
							</table>	
							
							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
						</div>
					</div>
				</div>
				<!-- //lyBox -->

				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->





<!-- Modal : FAQ 수정모달 -->
<div id="qna_detail" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>1:1문의 답글</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
					<tr>
						<td><span>카테고리</span></td>
						<td><input type="text" [value]="detail_category" disabled></td>
					</tr>
					<tr>
						<td><span>질문제목</span></td>
						<td><input type="text" [value]="detail_title" disabled></td>
					</tr>
					<tr>
						<td><span>질문내용</span></td>
						<td >
							<textarea rows="7" [value]="detail_contents" disabled></textarea>
						</td>
					</tr>
                    <tr>
						<td><span>질문 답변</span></td>
						<td >
							<textarea rows="7" maxlength="200" [value]="modal_inquiry_ans" (change)="onInquiryAnsChange($event.target.value)"></textarea>
						</td>
					</tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a href="javascript:void(0);" class="btn big orange mg_r4" (click)="fn_insertComment()">저장</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>    