import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-security-group-create',
  templateUrl: './dialog-security-group-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogSecurityGroupCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogSecurityGroupCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  newSecurityGroupData:{
    name:string,
    description:string
  }={name:"", description:""}

  ngOnInit(): void {

  }
  createSecurityGroup() {
    if(this.newSecurityGroupData.name ==""){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'이름을 입력해주세요.'
        }
      });
      return;
    }
    if(this.newSecurityGroupData.description.length >255){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'warning',
          title:'경고',
          message:'설명은 255자 이내로 작성해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi
      .createSecurityGroup(
        this.newSecurityGroupData.name,
        this.newSecurityGroupData.description
      )
      .subscribe((data) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
        let message ={
          message_type:'create security group',
          data_object: data
        };
        this.frontapi.changeMessage(message);

      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
