<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2 *ngIf="!loadbalancer_id;else elseHeader">로드밸런서 생성</h2>
        <ng-template #elseHeader>
            <h2>리스너 생성</h2>    
        </ng-template>
        <div class="lineMap">
            Network  /  <span>LoadBalancer</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12 mg_t10">
            <div class="sec_content">
                <!-- createBox -->
                <div class="createBox">
                    <div class="titArea">
                        <h3>Step 1. 로드밸런서</h3>
                    </div>
                    <div class="titArea tpA">
                        <small *ngIf="!loadbalancer_id;else elseSmall">로드밸런서를 설치 할 서브넷을 선택하세요.</small>
                        <ng-template #elseSmall>
                            <small>리스너를 추가 할 로드밸런서 정보입니다.</small>
                        </ng-template>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardCreateBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">이름</th>
                                        <td>
                                            <input type="text" maxlength="60" [(ngModel)]="lbdata.name" [readonly]="loadbalancer_id" placeholder="이름을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">설명 </th>
                                        <td>
                                            <input type="text" maxlength="255" [(ngModel)]="lbdata.description" [readonly]="loadbalancer_id">
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <th scope="row">Floavor</th>
                                        <td>
                                            <select name="" id="" required>
                                                <option value="">-선택-</option>
                                            </select>
                                        </td>
                                    </tr> -->
                                    <tr *ngIf="!loadbalancer_id">
                                        <th scope="row">서브넷</th>
                                        <td>
                                            <select (change)="selectSubnet($event.target.value)">
                                                <option value="">-선택-</option>
                                                <option *ngFor="let subnet of subnetList" [value]="subnet.id">{{subnet.name}} ( {{subnet.cidr}} )</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn_wrap tp1"></div>
                    </div>

                    <div class="titArea">
                        <h3>Step 2. 리스너</h3>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardCreateBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">이름</th>
                                        <td>
                                            <input type="text" maxlength="60" [(ngModel)]="lbListener.name" placeholder="이름을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <th scope="row">설명 </th>
                                        <td>
                                            <input type="text" [(ngModel)]="lbListener.description" placeholder="설명을 입력해주세요">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <th scope="row">프로토콜 </th>
                                        <td>
                                            <select required (change)="changeProtocol($event.target.value)">
                                                <option>HTTP</option>
                                                <option>TCP</option>
                                                <!-- <option>HTTPS</option> -->
                                                <option>UDP</option>
                                                <!-- <option>teminated HTTPS</option> -->
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">port</th>
                                        <td>
                                            <input type="number" maxlength="7" [(ngModel)]="lbListener.protocol_port" placeholder="port를 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <th scope="row">client data timeout</th>
                                        <td>
                                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">tcp inspect timeout</th>
                                        <td>
                                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">member connect timeout</th>
                                        <td>
                                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">member data timeout</th>
                                        <td>
                                            <input type="text" name="" id="" value="" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <th scope="row">연결 수 제한</th>
                                        <td>
                                            <input type="number" [(ngModel)]="lbListener.connection_limit" placeholder="내용을 입력해주세요">
                                        </td>
                                    </tr>                                        
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1"></div>
                    </div>
                    <div class="titArea">
                        <h3>Step 3. Pool 설정</h3>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardCreateBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">이름</th>
                                        <td>
                                            <input type="text" maxlength="60" [(ngModel)]="lbpool.name" placeholder="이름을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">설명 </th>
                                        <td>
                                            <input type="text" maxlength="255" [(ngModel)]="lbpool.description" placeholder="설명을 입력해주세요">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">로드밸런서 방식 </th>
                                        <td>
                                            <select (change)="changeAlgorithm($event.target.value)" >
                                                <option value="LEAST_CONNECTIONS">LEAST_CONNECTIONS</option>
                                                <option value="SOURCE_IP">SOURCE_IP</option>
                                                <option value="ROUND_ROBIN" selected>ROUND_ROBIN</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">세션 지속성</th>
                                        <td>
                                            <select (change)="changePersistence($event.target.value)" required>
                                                <option value="None">-선택-</option>
                                                <option value="SOURCE_IP">SOURCE_IP</option>
                                                <!-- <option value="">Http cookie</option>
                                                <option value="">App cookie</option> -->
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1"></div>
                    </div>
                    <div class="titArea">
                        <h3>Step 4. 인스턴스 추가</h3>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="titleBox">추가 된 인스턴스 목록</div>
                        <div class="boardCreateBox">
                            <table class="tbl_col_tp1">
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col style="width: 10%; min-width: 100px;"/>
                                </colgroup>
                                <thead>
                                    <th>이름</th>
                                    <th>IP 주소</th>
                                    <th>PORT </th>
                                    <th>Active/Standby</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let member of lbmember">
                                        <td>{{member.name}}</td>
                                        <td>{{member.address}}</td>
                                        <td><input style="width:auto;" type="text" [(ngModel)]="member.protocol_port"></td>
                                        <td *ngIf="!member.backup;else elseCase">Active</td>
                                        <ng-template #elseCase>
                                            <td>Standby</td>
                                        </ng-template>
                                        <td><button class="btn sml dGray" (click)="delSubnet(member)">삭제</button></td>
                                    </tr>                                        
                                </tbody>
                            </table>
                        </div>
                        <div class="titleBox">사용 가능한 인스턴스 목록</div>
                        <div class="boardCreateBox">
                            <table class="tbl_col_tp1">
                                <colgroup>
                                    <col style="width:30%"/>
                                    <col style="width:30%"/>
                                    <col style="width:30%"/>
                                    <col style="width:10%; min-width: 100px;"/>
                                </colgroup>
                                <thead>
                                    <th>이름 </th>
                                    <th>IP 주소 </th>
                                    <th>Active/Standby</th>
                                    <th> </th>
                                </thead>
                                <tbody *ngFor="let server of serverList" >
                                    <tr *ngIf="checkSubnet(server)">
                                        <td>{{server.server_name}}</td>
                                        <td><p *ngFor="let fixed_ip of server.ip_private">{{fixed_ip}}</p></td>
                                        <td>
                                            <select (click)="onClickMemberList(server)" (change)="changeMode($event.target.value)">
                                                <option value="0">Active</option>
                                                <option value="1">Standby</option>
                                            </select>
                                        </td>
                                        <td><button class="btn sml dGray" (click)="addSubnet(server)">추가</button></td>
                                    </tr>                                        
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1"></div>
                    </div>
                    <div class="titArea">
                        <h3>Step 5. 상태 확인</h3>
                    </div>
                    <div class="titArea tpA">
                        <small>로드밸런서 상태 확인</small>
                    </div>
                    <div class="contArea" style="display:block"> 
                        <div class="boardCreateBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%"/>
                                    <col style="width:80%"/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">유형 </th>
                                        <td>
                                            <select (change)="changeMonitorType($event.target.value)" required>
                                                <option value="HTTP">HTTP</option>
                                                <!-- <option value="HTTPS">HTTPS</option> -->
                                                <option value="TCP">TCP</option>
                                                <!-- <option value="">ping</option>
                                                <option value="">tls-hello</option>
                                                <option value="">udp-conenct</option> -->
                                            </select>
                                        </td>
                                    </tr>
                                    <tr *ngIf="lbmonitor.type == 'HTTP'">
                                        <th scope="row">URL</th>
                                        <td>
                                            <input type="text" [(ngModel)]="lbmonitor.url_path" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">최대 응답 대기 시간 </th>
                                        <td>
                                            <input type="number" [(ngModel)]="lbmonitor.timeout" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">상태 확인 주기 </th>
                                        <td>
                                            <input type="number" [(ngModel)]="lbmonitor.delay" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">최대 재시도 횟수 </th>
                                        <td>
                                            <input type="number" [(ngModel)]="lbmonitor.max_retries" placeholder="내용을 입력해주세요" required>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="btn_wrap tp1">
                            <button *ngIf="!loadbalancer_id;else elseSubmit" type="submit" class="btn big blue" (click)="createLoadbalancer()"><i class="ico ico_register_white"></i>로드밸런서 생성</button>
                            <ng-template #elseSubmit>
                                    <button type="submit" class="btn big blue" (click)="createListener()"><i class="ico ico_register_white"></i>리스너 생성</button>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <!-- //createBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

