<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>삭제확인</h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="msg">삭제하시겠습니까?</div>
    <div class="btnArea">
      <a class="btn big red mg_r4" (click)="modalMsg.emit()" rel="modal:close"><i class="ico ico_delete_white"></i>삭제</a>
      <a class="btn big dGray" href="#close-modal" rel="modal:close"><i class="ico ico_cancel_white"></i>취소</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->
