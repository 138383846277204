
<!-- Modal : 서브넷 생성 모달 -->
<div id="subnet_create" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>
                배치 생성
                <!-- <p class="sub-txt"> | 서브넷 생성 설명글 입니다.</p> -->
            </h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width:30%">
                        <col style="width:70%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>일간</th>
                            <td><input type="text" value="매일" readonly></td>
                        </tr>
                        <tr>
                            <th>시간</th>
                            <td>
                                <select name="sel_hour" id="sHour" [(ngModel)]="bHour" class="size435">
                                    <option *ngFor="let h of selecteHourList" [value]="h">
                                        {{ h }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>분</th>
                            <td>
                                <select name="sel_min" id="sMin" [(ngModel)]="bMin" class="size435">
                                    <option *ngFor="let m of selecteMinList" [value]="m">
                                    {{ m }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>
                                <input type="text" name="" [(ngModel)]="userEmail" id="email" value="" placeholder="" readonly />
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <div class="btnArea tp2">
                <a (click)="onClickRegiBatch($event)" class="btn big ciblue mg_r4">생성</a>
                <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal -->