
<!-- Modal : 볼륨 연결 모달 -->
<div id="volume_connect" class="modal2">
        <!-- modal_header -->
        <div class="modal_header">
            <h2>스토리지 연결</h2>
        </div>
        <!-- //modal_header -->
    
        <!-- modal_content -->
        <div class="modal_content">
            <div class="boardBox">
                <table class="tbl_row_tp1">
                    <colgroup>
                        <col style="width:30%">
                        <col style="width:70%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">대상 스토리지 이름</th>
                            <td>
                                {{selected_volume.name}}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">크기</th>
                            <td>
                                {{selected_volume.size}} GiB
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">연결할 인스턴스</th>
                            <td>
                                <select [(ngModel)]="attach_volume_modal_server" name="" required>
                                    <option value="">선택</option>
                                    <option *ngFor="let server of serverList" [value]="server.server_id">
                                        {{server.server_name}} - {{server.flavor_name}}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btnArea">
                <a class="btn big ciblue mg_r4" (click)="attachVolume(attach_volume_modal_server,selected_volume.id)">연결</a>
                <a class="btn big bor_gray" (click)="closeDialog()">취소</a>
            </div>
        </div>
        <!-- //modal_content -->
    </div>
    <!-- //Modal -->