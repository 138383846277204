import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-equipment-list',
  templateUrl: './admin-equipment-list.component.html',
})
export class AdminEquipmentListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
