import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import {Router} from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class GWService {
  projects = [];
  // userInfo: { projectId: string; userId: string; companyId: number };
  userInfo: any;
  _that = this;

  constructor(private http: HttpClient,private router:Router) {
    this.handleError = this.handleError.bind(this);
   }

   
   getAlreadyUsed() {
    return this.http.get('/blsapi/api-gw/used').pipe(catchError(this.handleError));
  }
  startGatewayService() {
    return this.http.post('/blsapi/api-gw/registration',{}).pipe(catchError(this.handleError));
  }
  getRegistrationInfo() {
    return this.http.get('/blsapi/api-gw/registration').pipe(catchError(this.handleError));
  }

  getAPIList() {
    return this.http.get('/blsapi/api-gw/api').pipe(catchError(this.handleError));
  }
  getAuthList() {
    return this.http.get('/blsapi/api-gw/auth').pipe(catchError(this.handleError));
  }
  createAPI(
    name,
    targets,
    path,
    protocol,
    algorithm,
    retries ,
    connect_timeout,
    write_timeout,
    read_timeout
    ) {
    const param ={
      name:name,
      targets:targets,
      path:path,
      protocol:protocol,
      algorithm:algorithm,
      connect_timeout:connect_timeout,
      write_timeout:write_timeout,
      read_timeout:read_timeout,
      retries:retries 
    }
    return this.http.post('/blsapi/api-gw/api',param).pipe(catchError(this.handleError));
  }
  updateAPI(
    id,
    name,
    targets,
    path,
    protocol,
    algorithm,
    retries ,
    connect_timeout,
    write_timeout,
    read_timeout
    ) {
    const param ={
      name:name,
      targets:targets,
      path:path,
      protocol:protocol,
      algorithm:algorithm,
      connect_timeout:connect_timeout,
      write_timeout:write_timeout,
      read_timeout:read_timeout,
      retries:retries 
    }
    return this.http.post('/blsapi/api-gw/api/'+id,param).pipe(catchError(this.handleError));
  }
  deleteAPI(service_id){
    return this.http.delete('/blsapi/api-gw/api/'+service_id).pipe(catchError(this.handleError));
  }
  createRoute(service_id,name,path,protocol,methods,is_strip_path,is_preserve_host,
              is_keyauth,is_acl,is_iprestriction,is_cors,is_requestsizelimiting,snis,
              acl_allow_groups,ip_restriction:{allow_ips:Array<string>,deny_ips:Array<string>},
              cors_params, request_size_limit_size) {
    const param ={
      name:name,
      path:path,
      protocol:protocol,
      methods:methods,
      is_strip_path:is_strip_path,
      is_preserve_host:is_preserve_host,
      is_keyauth:is_keyauth,
      is_acl:is_acl,
      acl:{allow_groups:acl_allow_groups},
      is_iprestriction:is_iprestriction,
      ip_restriction:{allow:ip_restriction.allow_ips,deny:ip_restriction.deny_ips},
      is_cors:is_cors,
      cors:cors_params,
      is_requestsizelimiting:is_requestsizelimiting,
      request_size_limit:{size:request_size_limit_size,size_unit:"megabytes",header_check:false},
      snis:snis,

    }
    return this.http.post('/blsapi/api-gw/api/'+service_id+'/route',param).pipe(catchError(this.handleError));
  }
  updateRoute(route_id,name,path,protocol,methods,is_strip_path,is_preserve_host,
              is_keyauth,is_acl,is_iprestriction,is_cors,is_requestsizelimiting,snis,
              acl_allow_groups,ip_restriction:{allow_ips:Array<string>,deny_ips:Array<string>},
              cors_params, request_size_limit_size) {
    const param ={
      name:name,
      path:path,
      protocol:protocol,
      methods:methods,
      is_strip_path:is_strip_path,
      is_preserve_host:is_preserve_host,
      is_keyauth:is_keyauth,
      is_acl:is_acl,
      acl:{allow_groups:acl_allow_groups},
      is_iprestriction:is_iprestriction,
      ip_restriction:{allow:ip_restriction.allow_ips,deny:ip_restriction.deny_ips},
      is_cors:is_cors,
      cors:cors_params,
      is_requestsizelimiting:is_requestsizelimiting,
      snis:snis,
      request_size_limit:{size:request_size_limit_size,size_unit:"megabytes",header_check:false}


    }
    return this.http.post('/blsapi/api-gw/route/'+route_id,param).pipe(catchError(this.handleError));
  }
  deleteRoute(route_id){
    return this.http.delete('/blsapi/api-gw/route/'+route_id).pipe(catchError(this.handleError));
  }
  createAuth(name,type) {
    const param ={
      name:name,
      type:type
    }
    return this.http.post('/blsapi/api-gw/auth',param).pipe(catchError(this.handleError));
  }
  deleteAuth(consumer_id){
    return this.http.delete('/blsapi/api-gw/auth/'+consumer_id).pipe(catchError(this.handleError));
  }
  getPlugin(plugin_id){
    return this.http.get('/blsapi/api-gw/plugin/'+plugin_id).pipe(catchError(this.handleError));
  }
  updateIpRestriction(route_id,allow_list,deny_list) {
    const param ={ip_restriction:{allow:allow_list,deny:deny_list} }
    return this.http.post('/blsapi/api-gw/route/'+route_id+'/iprestriction',param).pipe(catchError(this.handleError));
  }
  getApiUsageList(start_date,end_date){
    const param ={start_date:start_date,end_date:end_date }
    return this.http.post('/blsapi/api-gw/usage',param).pipe(catchError(this.handleError));

  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
    } else {
      // 백엔드 공통 오류 처리
      switch (error.status) {
        case 500:
          break;
        case 401:
          break;
      }
    }
    return throwError(error);
  }
}