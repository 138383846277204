<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>청구관리</h2>
		<div class="lineMap">
			청구관리 /  <span>청구내역</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>청구내역</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" id="search" placeholder="검색어를 입력해주세요" (change)="changeSearchValue($event)"/>
								<div class="input-group-btn pd_r10">
								<button type="submit" class="btn mid orange" (click)="serachInvoice()"><i class="ico ico_search_white"></i>검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block"> 
						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
								<colgroup>
									<col style="width:60px;">
									<col style="width:200px;">
									<col style="width:150px;">
									<col style="width:150px;">
									<col style="width:150px;">
									<col style="width:100px;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">ID</th>
										<th scope="col">회사명</th>
										<th scope="col">청구 월</th>
										<th scope="col">청구금액 (VAT 포함)</th>
										<th scope="col">상태</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let totalInvoice of totalInvoiceInfo; index as i;" (click)="getInvoiceDetail(totalInvoice)">
										<td>{{10 * curPage + (i+1)}}</td>
										<td><a class="link">{{ totalInvoice.Company.adminId }}</a></td>
										<td>{{ totalInvoice.Company.companyName === 'empty company' ?  '개인' : totalInvoice.Company.companyName }}</td>
										<td>{{ totalInvoice.totalInvoiceMonth }}</td>
										<td>{{ totalInvoice.realCost | currency:'KRW' }}</td>
										<td>
											<label [class]="totalInvoice.Calculation === null ? '' : 'btn tiny blue radius'">
												{{ totalInvoice.Calculation === null ? '' : '완료'}}
											</label>
										</td>
									</tr>
								</tbody>
							</table>
							
							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
						</div>

					</div>
				</div>
				<!-- //lyBox -->
				
				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->


<!-- Modal : 청구내역 상세모달 -->
<div id="claim_detail" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>청구 상세
			<p class="sub-txt">| {{title}} 청구 상세내역 입니다.</p>
		</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<form name="invoiceFrm" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit($event)">
			<div class="boardBox">
				<table class="tbl_col_tp2">
					<colgroup>
						<col style="width: 60px;"/>
						<col style="width: 200px;"/>
						<col />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">청구ID</th>
							<th scope="col">서비스</th>
							<!-- <th scope="col">사용 내역</th> -->
							<th scope="col">사용 금액</th>
							<th scope="col">할인 금액</th>
							<th scope="col">예상 금액</th>
						</tr>
					</thead>
					<tbody *ngIf="totalCount > 0">
						<tr *ngFor="let invoice of selectedInvoice.Invoices; index as i;">
							<td rowspan="1">
								{{ invoice.invoiceId }}
								<input type="hidden" [(ngModel)]="invoice.invoiceId" name=invoiceId_{{invoice.invoiceId}} #invoiceId_{{invoice.invoiceId}}="ngModel" required>
							</td>
							<td rowspan="1">{{ invoice.serviceType ? invoice.serviceType : '-'}}</td>
							<!-- <td>-</td> -->
							<td>{{ invoice.rawCost | currency:'KRW' }}</td>
							<td>{{ (invoice.rawCost - invoice.realCost)| currency:'KRW' }}</td>
							<td>
								<input type="number" [ngModel]="invoice.realCost" name=realCost_{{invoice.invoiceId}} 
									#realCost_{{invoice.invoiceId}}="ngModel" (change)="changeRealCost($event, invoice)"
									pattern="\d*" autocomplete="off" required [readonly]="selectedInvoice.Calculation"
								>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="totalCount < 1">
						<tr><td>조회된 내역이 없습니다</td></tr>
					</tbody>
					<tbody *ngIf="totalCount > 0">
						<tr>
							<td colspan="2" class="backcolor-gray">예상 합계</td>
							<td colspan="3">{{ totalRealCost | currency:'KRW' }}</td>
						</tr>
						<tr>
							<td colspan="2" class="backcolor-gray">크레딧</td>
							<td colspan="3">
								<input type="number" [(ngModel)]="credit" name="credit" pattern="\d*" autocomplete="off" 
									(change)="changeCredit($event)" required [readonly]="selectedInvoice.Calculation"
								>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="backcolor-gray">합계</td>
							<td colspan="3">{{ totalRealCost - credit | currency:'KRW' }}</td>
						</tr>
						<tr>
							<td colspan="2" class="backcolor-gray">청구금액 (VAT 포함)</td>
							<td colspan="3">{{ (totalRealCost - credit ) * 1.1 | currency:'KRW' }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="btnArea tp1">
				<button *ngIf="!selectedInvoice.Calculation" type="submit" class="btn big orange mg_r4" [disabled]="!f.form.valid">청구
				</button>
				<a href="#close-modal" rel="modal:close" id="close-modal" class="btn big orange" (click)="initCredit();">닫기</a>
			</div>
		</form>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->