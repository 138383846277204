<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
	  <h2>크레딧 사용내역</h2>
	  <div class="lineMap">
		  청구/정산관리 /  <span>크레딧 사용내역</span>
	  </div>
	</div>
	<!-- //content_header -->
  
	<!-- content_body -->
	<div class="content_body">
	  <!-- section -->
	  <section class="sec col12">
		<div class="sec_content">
		  <!-- lyBox -->
		  <div class="lyBox consoleRightBody">
			<div class="titArea">
			  <h3>크레딧 사용내역</h3>
			  <!-- <div class="btnArea">
				<div class="input-group fl">
					<input type="search" class="form-control" id="search" placeholder="검색어를 입력해주세요" (change)="changeSearchValue($event.target.value)"/>
					<div class="input-group-btn pd_r10">
					<button type="submit" class="btn mid dBlue" (click)="serachCreditHistory()"><i class="ico ico_search_white"></i>검색</button>
					</div>
					<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
				</div>
				</div> -->
			</div>
			<div class="contArea" style="display: block;">
			  <div class="boardBox">
				<table class="tbl_col_tp1 pdTp2">
				  <colgroup>
					<col style="width: 60px;"/>
					<col style="width: 80px;"/>
					<col style="width: 150px;"/>
					<col style="width: 100px;"/>
					<col style="width: 100px;"/>
					<col style="width: 250px;"/>
					<col style="width: 120px;"/>
				  </colgroup>
				  <thead>
					<tr>
					  <th scope="col">번호</th>
					  <th scope="col">ID</th>
					  <th scope="col">회사명</th>
					  <th scope="col">크레딧</th>
					  <th scope="col">증감(+/-)</th>
					  <th scope="col">설명(비고)</th>
					  <th scope="col">생성일자</th>
					</tr>
				  </thead>
  
				  <tbody>
					<tr *ngFor="let credit of creditHistoryInfo; index as i">
						<td>{{ 10 * curPage + (i + 1) }}</td>
						<td>{{ credit.Company.adminId }}</td>
						<td>{{ credit.Company.companyName === 'empty company' ?  '개인' : credit.Company.companyName }}</td>
						<td>{{ credit.credit }}</td>
						<td>
							<label [class]="credit.sign === 0 ? 'dot red' : 'dot green'"></label>
							{{ credit.sign === 0 ? '감소(-)' : '증가(+)' }}
						</td>
						<td>{{ credit.description }}</td>
						<td>{{ credit.createdDate | date:'yyyy-MM-dd HH:mm:ss' }}</td>
					</tr>
				  </tbody>
				</table>

				<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
	
			  </div>
			</div>
		  </div>
		  <!-- //lyBox -->
  
		  <!-- //lyBox -->
		</div>
	  </section>
	  <!-- //section -->
	</div>
	<!-- //content_body -->
  </div>
  <!-- //Contents -->
  