
<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>API 게이트웨이</h2>
        <div class="lineMap">
            Service / <span>API Gateway</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
<div class="lyBox" style="height: 100%;">
  <div class="titArea">
      <h3>API Gateway 서비스 신청</h3>
  </div>
  <div class="contArea" style="display: block;">
      <div class="boardBox">
          <h1 style="font-size: 40px; margin: 40px;font-weight: bold;">API Gateway</h1>
          <p style="margin: 50px 0 10px 90px;font-size: 20px;font-weight: bold;">안정적인 API 호출을 돕는 다양한 관리 기능을 제공합니다.</p>
          <p style="font-size: 20px; margin: 0 0 60px 90px;line-height: 30px;">
              API Gateway를 통해 API 호출과 관련된 모든 작업들을 편리하게 진행할 수 있습니다. <br />
              API Gateway에서 발급한 API Key와 IP ACL을 이용하여 사용자의 액세스를 제어할 수 있습니다.<br />
              API Gateway는 호출량을 제한하여 과도한 트래픽이 인입되는 것을 제한하거나, 캐시 설정을 통해서 백엔드 서비스로 인입되는 트래픽을 제어할 수 있습니다.<br />
          </p>
          <div class="contSub" style="display: flex;justify-content: center;align-items: center;">
              <button *ngIf="!already_used_flag" (click)="startGatewayService()" class="btn big blue mg_r4"  >
                신청 하기
              </button>
              <button *ngIf="already_used_flag" class="btn big blue mg_r4"  disabled >
                이미 신청하였습니다.
              </button>
          </div>
      </div>
  </div>
</div>
</div>
</section>
</div>
</div>
