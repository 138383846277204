<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>게시판관리</h2>
		<div class="lineMap">
			게시판관리 /  <span>FAQ</span>
		</div>
	</div>
	<!-- //content_header -->


	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
				<div class="lyBox">								
					<div class="contArea" style="display:block"> 
						<div class="boardBox">
							<table class="tbl_row_tp1">
								<colgroup>
									<col style="width:20%">
									<col style="width:80%">
								</colgroup>
								<tbody>                                    
									<tr>
										<th scope="row">FAQ 생성</th>
										<td>
											<a class="btn mid orange mg_r4" onclick="fn_modalOpen('faq_insert')">FAQ 생성</a>
										</td>
									</tr>                                                
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>FAQ</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" (keydown.enter)="fn_faqSearch($event.target.value)" [value]="searchTitle" (change)="onSearchTitleChange($event.target.value)" placeholder="검색어를 입력해주세요"/>
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid orange" (click)="fn_faqSearch(searchTitle)">검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block"> 

						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2 dropmenu">
								<colgroup>
									<col style="width:5%">
									<col style="width:20%">
									<col style="width:60%">
									<col style="width:15%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">번호</th>
										<th scope="col">카테고리</th>
										<th scope="col">질문</th>
										<th scope="col">작성일</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let faq of faqList; index as i;" (contextmenu)="onClickList(faq)" (click)="getFaqDetail(faq)">
										<td>{{10*curPage+i+1}}</td>
										<td >{{ faq.category }}</td>
										<td class="l">
											<a (click)="getFaqDetail(faq)">{{faq.title}}</a>
										</td>
										<td >{{ faq.createdDate.substring(0, 10) }}</td>
									</tr>
								</tbody>
							</table>
							<app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
						</div>
					</div>
				</div>
				<!-- //lyBox -->

				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->


<div class="dropdown">
	<div id="myDropdown" class="dropdown-content">
	  <a onclick="fn_modalMsg('삭제하시겠습니까?')">FAQ 삭제</a>
	</div>
</div>

<!-- Modal : FAQ 추가모달 -->
<div id="faq_insert" class="modal">
	<!-- modal_header -->
	<div class="modal_header">
		<h2>FAQ 생성</h2>
	</div>
	<!-- //modal_header -->

	<!-- modal_content -->
	<div class="modal_content">
		<form [formGroup]="faqForm" (ngSubmit)="onSubmit()">
			<input type="hidden" formControlName="boardId" value="2" >
			<div class="boardBox">			
				<table class="tbl_row_tp1">
					<colgroup>
						<col style="width:20%">
						<col style="width:80%">
					</colgroup>
					<tbody>
						<tr class="required">
							<tH><span>카테고리</span></tH>
							<td>
								<select formControlName="category" >
									<option value="요금" selected>요금</option>
									<option value="이벤트">이벤트</option>
									<option value="사이트">사이트</option>
								</select>
							</td>
						</tr>
						<tr class="required">
							<th><span>질문</span></th>
							<td>
								<input type="text" formControlName="title" placeholder="질문을 입력하세요." required>								
							</td>
						</tr>
						<tr class="required">
							<th><span>답변</span></th>
							<td >
								<textarea rows="7" formControlName="contents" placeholder="질문의 답변을 입력하세요."></textarea>
								
							</td>
							
						</tr>
					</tbody>
				</table>				
			</div>
			<div class="btnArea tp1">
				<button type="submit" class="btn big orange mg_r4" [disabled]='!faqForm.valid'>저장</button>
				<a href="#close-modal" rel="modal:close" id="closeModalBtn" class="btn big orange">닫기</a>
			</div>
		</form>
	</div>
	<!-- //modal_content -->
</div>
<!-- //Modal -->


<!-- Modal : FAQ 수정모달 -->
<div id="faq_detail" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>FAQ 수정</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox" *ngIf="selected_faq != null">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tbody>
					<tr class="required">
						<th><span>카테고리</span></th>
						<td>
							<select [value]="selected_faq.category" (change)="onCategoryChange($event.target.value)">
								<option value="요금" selected>요금</option>
								<option value="이벤트">이벤트</option>
								<option value="사이트">사이트</option>
							</select>
						</td>
					</tr>
                    <tr class="required">
						<th><span>질문</span></th>
						<td><input type="text" [value]="selected_faq.title" maxlength="60" (change)="onTitleChange($event.target.value)"></td>
                    </tr>
                    <tr class="required">
						<th><span>답변</span></th>
						<td >
						<textarea rows="7" maxlength="250" (change)="onContentsChange($event.target.value)">{{selected_faq.contents}}</textarea>
						</td>
					</tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a class="btn big orange mg_r4" (click)="updateFaq()">수정</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange" id="closeModalBtn2">닫기</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->


<!-- Modal : msg 공통 -->
<div class="modal modalMsg">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>삭제확인</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="msg"></div>
        <div class="btnArea">
				<a class="btn big orange mg_r4" href="#close-modal" (click)="deleteFaq()"  rel="modal:close">삭제</a>
            <a class="btn big orange" href="#close-modal" rel="modal:close">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="medium" color="#fff" type="line-spin-fade-rotating" [fullScreen]="false">
	<p style="color: black;">Loading...</p>
  </ngx-spinner>