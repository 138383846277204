<!-- Modal : Bucket 생성 모달 -->
<div id="objectstorage_join" class="modal2">
  <!-- modal_header -->
  <div class="modal_header">
    <h2>
      이용 신청
      <!-- <p class="sub-txt">Object Storage Bucket을 생성합니다.</p> -->
    </h2>
  </div>
  <!-- //modal_header -->

  <!-- modal_content -->
  <div class="modal_content">
    <div class="boardBox">
      <div class="msg">
        Object Storage 이용을 신청합니다.<br />
        Object Storage API 는 포털에 등록되어 있는 API 인증키로 사용하실 수 있습니다.
      </div>

      <!-- <table class="tbl_row_tp1">
        <colgroup>
          <col style="width: 30%;" />
          <col style="width: 70%;" />
        </colgroup>
        <tbody>
          <tr>
            <th>Bucket 이름</th>
            <td><input type="text" [(ngModel)]="newBucket.name" placeholder="이름을 입력해주세요" /></td>
          </tr>

          <tr>
            <th>설명</th>
            <td><input type="text" [(ngModel)]="newBucket.description"  placeholder="설명을 입력해주세요" /></td>
          </tr>
        </tbody>
      </table> -->
    </div>
    <div class="btnArea tp2">
      <a (click)="joinObjectStorage()" class="btn big ciblue mg_r4">적용</a>
      <a (click)="closeDialog()" class="btn big bor_gray">닫기</a>
    </div>
  </div>
  <!-- //modal_content -->
</div>
<!-- //Modal -->