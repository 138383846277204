import { Component, OnInit, Inject } from '@angular/core';
import { CephapiService } from '../../../cephapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../dialog-common';
import { ITreeOptions, TreeNode } from '@circlon/angular-tree-component';

@Component({
  selector: 'app-dialog-objectstorage-copymove',
  templateUrl: './dialog-objectstorage-copymove.component.html',
  styleUrls: ['../dialog-common.css', 'dialog-objectstorage-copymove.css']
})
export class DialogObjectStorageCopyMoveComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private cephapi: CephapiService,
    public dialogRef: MatDialogRef<DialogObjectStorageCopyMoveComponent>

  ) {
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  public objectList: any;
  public currentPath: string;
  public source_bucket: string;
  public target_bucket: string;
  public type: string;
  public title: string;
  public target_prefix: string;

  public nodes = [];
  public options: ITreeOptions = {
    getChildren: async (node: TreeNode) => {
      let bucket = '';
      let prefix = '';
      if(node.isRoot) {
        bucket = node.data.name;
      } else {
        let root = this.getRootName(node, '');
        bucket = root.bucket;
        prefix = root.prefix;
      }
      // console.log(node);
      // console.log(node.data.name);
      return await this.cephapi.getChildrenDir({bucket, prefix}).toPromise();
    },
    useVirtualScroll: true,
  };

  ngOnInit() {
    this.type = this.dialogData.type;
    if(this.type == 'copy') {
      this.title = '오브젝트 복사';
    } else if(this.type == 'move') {
      this.title = '오브젝트 이동';
    }
    this.nodes = this.dialogData.bucketList;
    this.objectList = this.dialogData.objectList;
    this.source_bucket = this.dialogData.currentBucket;
    this.target_bucket = this.source_bucket;
    let idx = this.nodes.findIndex(i => i.name === this.target_bucket);
    this.nodes[idx].isExpanded = true;
    this.currentPath = this.target_bucket;
  }

  onEvent = ($event) => {
    let path = this.getRootName($event.node, '');
    this.target_bucket = path.bucket;
    this.currentPath = path.prefix === '' ? this.target_bucket : this.target_bucket + '/' + path.prefix;
    this.target_prefix = path.prefix;
  };

  getRootName(node: TreeNode, prefix) {
    if(node.isRoot) {
      return {
        bucket: node.data.name,
        prefix
      };
    }
    prefix = node.data.name + '/' + prefix;
    return this.getRootName(node.parent, prefix);
  }

  clickCopyMove() {
    try {
      this.spinner.show();

      let sendData = {
        source_bucket: this.source_bucket,
        target_bucket: this.target_bucket,
        source_object: this.objectList.map(m => {
          return {
            originName: m.originName,
            type: m.type,
            name: m.name
          }
        }),
        target_object: {
          name: this.target_prefix
        },
        // target_object: this.objectList.map(m => {
        //   return {
        //     // name: this.target_prefix + m.name
        //     name: this.target_prefix
        //   }
        // }),
        type: this.type
      }
      // console.log(sendData);

      this.cephapi.copyMoveObject(sendData).subscribe((data) => {
        this.spinner.hide();
        this.closeDialogMsg({code:"success",data:data});
      },
      (error) => {
        let msg = "일시적으로 문제가 발생하였습니다. 새로고침해주세요.";
        // console.log(error);
        // if(409 == error.status) msg = "이미 존재하는 버킷 이름입니다.";
        // else if(403 === error.status) msg = "입력하신 버킷명은 규칙에 맞지 않습니다.<br>다시 한번 확인바랍니다.";
        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:msg
        }
      });
      });
    } catch (e) {
      this.spinner.hide();

    }
  }

}
