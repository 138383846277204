import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService } from '../../frontapi.service';
import { WebApiService } from '../../webapi.service';
import { ToastComponent, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.css']
})
export class MobileNavComponent implements OnInit {
  projectLists: any = [];
  userInfo: any;
  emptyProject: boolean = false;
  isAdmin: boolean = false;
  defaultProject: String;
  opened: boolean;
  message: any = {};

  @ViewChild('toasttype')
  public toastObj: ToastComponent;
  public position: ToastPositionModel = { X: "Right" };
  
  public onCreate = (): void => {
    // setTimeout((): void => {
    //   this.toastObj.show();
    // }, 200);
  }

  public showToastError = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-danger',
        // icon: 'e-error toast-icons'
      }
    );
  }

  public showToastSuccess = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-success',
        // icon: 'e-success toast-icons'
        // icon: 'fa fa-check'
      }
    );
  }

  public showToastInfo = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-info',
        // icon: 'e-info toast-icons'
      }
    );
  }

  public showToastWarning = (): void => {
    this.toastObj.show(
      {
        title: this.toastTitle,
        content: this.toastMessage,
        cssClass: 'e-toast-warning',
        // icon: 'e-warning toast-icons'
      }
    );
  }

  public hideToast = (): void => {
    this.toastObj.hide('All');
  }

  toastTitle: string;
  toastMessage: string;
  toastType: string;

  constructor(
    private router: Router,
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private webapi: WebApiService,
  ) {
  }
  
  ngOnInit(): void {
    this.spinner.show();
    this.getUserInfo();

    this.frontapi.currentMessage.subscribe(message => {
      this.message = message;
      if (this.message.type == "toast") {
        this.toastType = this.message.body.type;
        this.toastTitle = this.message.body.title;
        this.toastMessage = this.message.body.message;
        switch (this.toastType) {
          case "success":
            this.showToastSuccess();
            break;
          case "info":
            this.showToastInfo();
            break;
          case "warning":
            this.showToastWarning();
            break;
          case "error":
            this.showToastError();
            break;
        }
      } else if (this.message.type == 'console-nav') {
        if (this.message.body.type == 'change project') {
          this.getProjectList();
        }
      } else if (this.message.type == 'logout') {
        this.webapi.blsLogout().subscribe(
          (data) => {
            this.router.navigate(['/member/login']);
          }
        )
      }
    },
      (error) => {
        this.spinner.hide();
      });
  }

  menuListOpen() {
    document.getElementById("mobile-demo").style.width = "200px";
  }

  menuListClose() {
    document.getElementById("mobile-demo").style.width = "0";
  }

  menuList() {
    
    // var btnMenu = document.querySelector('.btn-menu');
    // var sideNav = document.querySelector('.side-nav');

    // btnMenu.classList.toggle('active');
    // sideNav.classList.toggle('on');
  }

  routerDirection(link) {
    var a = document.getElementById("mobile-demo");
    if(a.style.width != "0") {
      this.menuListClose();
    }
    this.router.navigate([link]);
  }

  backMovement() {
    history.back();
  }

  getUserInfo() {
    try {
      this.osapi.getUserInfo().subscribe((data) => {
        this.userInfo = data;
        if(this.userInfo.userId == "bls@bizlinkersys.com"){
          this.isAdmin = true;
        }else{
          this.isAdmin = false;
        }
        this.getProjectList();
      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      console.error(err);
    }
  }

  getProjectList() {
    try {
      this.osapi.getProjectList().subscribe((data: { items: object }) => {
        if (data.items == null) {
          throw 'osapi.getProjectList Fail';
        } else {
          this.projectLists = data.items;
        }

        for(var i=0; i < this.projectLists.length; i++) {
          if(this.userInfo.projectId == this.projectLists[i].resource_data.projectId) {
            this.defaultProject = this.projectLists[i].resource_data.projectId;
            break;
          }
        }

        if (this.emptyProject) {
          this.onProjectChange(this.projectLists[0].id);
          this.userInfo.projectId = this.projectLists[0].id;
          this.emptyProject = false;
        }
      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (err) {
      console.error(err);
    }
  }

  onProjectChange(project_id) {
    try {
      this.spinner.show();
      this.osapi.switchProject(project_id).subscribe((data) => {
        this.spinner.hide();
        setTimeout(() => {
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'info',
              title: '프로젝트 변경',
              message: '프로젝트가 변경되었습니다.'
            }
          });
        }, 1000);
        this.router.navigate(['/m/dashboard']);
      },
        (error) => {
          console.error(error.status);
          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {
      this.emptyProject = true;
      this.spinner.hide();
    }
  }
}
