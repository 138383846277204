<!-- Contents -->
<div id="Contents">
	<!-- content_header -->
	<div class="content_header col12">
		<h2>로그 목록</h2>
		<div class="lineMap">
			로그 목록 / <span>회원 관리</span>
		</div>
	</div>
	<!-- //content_header -->

	<!-- content_body -->
	<div class="content_body">
		<!-- section -->
		<section class="sec col12">
			<div class="sec_content">
            <div class="lyBox">								
                    <div class="contArea" style="display:block"> 
                        <div class="boardBox">
                            <table class="tbl_row_tp1">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:80%">
                                </colgroup>
                                <tbody>                                    
                                    <tr>
                                        <th scope="row">로그 목록</th>
                                        <td></td>
                                    </tr>           
                                    <tr>
                                        <th scope="row">TYPE</th>
                                        <td>
                                            <a class="btn mid orange mg_r4" (click)="changeList(1)">전체</a>
                                            <a class="btn mid blue mg_r4" (click)="changeList(2)">정상</a>
                                            <a class="btn mid red mg_r4" (click)="changeList(3)">오류</a>
                                        </td>
                                    </tr>                                     
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- lyBox -->
				<!-- lyBox -->
				<div class="lyBox consoleRightBody">
					<div class="titArea">
						<h3>로그 목록</h3>
						<div class="btnArea">
							<div class="input-group fl">
								<input type="search" class="form-control" name="" id="search" value="" placeholder="검색어를 입력해주세요"
									spellcheck="false" autocomplete="off" />
								<div class="input-group-btn pd_r10">
									<button type="submit" class="btn mid orange" >검색</button>
								</div>
								<a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a>
							</div>
						</div>
					</div>
					<div class="contArea" style="display:block">

						<div class="boardBox">
							<table class="tbl_col_tp1 pdTp2">
                                <colgroup>
                                    <col style="width:14.28%">
									<col style="width:14.28%">
									<col style="width:14.28%">
									<col style="width:14.28%">
									<col style="width:14.28%">
									<col style="width:14.28%">
									<col style="width:14.28%">
								</colgroup>
								<thead>
									<tr>
                                        <th scpoe="col">시간</th>
										<th scope="col">ID</th>
										<th scope="col">URL</th>
                                        <th scope="col">Reqeust Parameter</th>
                                        <th scope="col">접속IP</th>
										<th scope="col">HTTP STATUS</th>
										<th scope="col">Request Body</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of logList">
                                        <td>{{item.createdDate}}</td>
                                        <td>{{item.userId}}</td>
                                        <td>{{item.url}}</td>
                                        <td>{{item.paramters}}</td>
                                        <td>{{item.userIp}}</td>
                                        <td>{{item.httpStatusMsg}} ({{item.httpStatusCode}})</td>
                                        <td>{{item.reqBody}}</td>
                                    </tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- //lyBox -->

				<!-- //lyBox -->
			</div>
		</section>
		<!-- //section -->
	</div>
	<!-- //content_body -->
</div>
<!-- //Contents -->