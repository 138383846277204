import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ConsoleNavComponent } from '../../console/console-nav/console-nav.component';
import { NgxSpinnerService } from "ngx-spinner";
import { OsapiService } from '../../osapi.service';
import { FrontApiService} from '../../frontapi.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-console-server-list',
  templateUrl: './mobile-console-server-list.component.html',
  styleUrls: ['./mobile-console-server-list.component.css'],
  providers: [ConsoleNavComponent]
})

export class MobileConsoleServerListComponent implements OnInit {
  serverLists:any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private comp: ConsoleNavComponent,
    public dialog: MatDialog,
    private frontapi: FrontApiService,
  ) {
    
  }
  ngOnInit(): void {
    this.spinner.show();
    this.getServerList();
  }

  async getServerList() {
    this.serverLists = await this.comp.outSendServerList();

    for (let server of this.serverLists) {
      server.is_remote_access = false;
      server.remote_status_msg = "접속 차단"
      server.remote_acecss_msg = "보안그룹 규칙 없음"
      for (let groups of server.security_groups) {
        for (let rule of groups.security_group_rules) {
          if (rule.port_range_max == null && rule.port_range_min == null && 
            (rule.protocol == null || rule.protocol == "tcp") && rule.direction == "ingress"){
              server.is_remote_access = true;  
              server.remote_status_msg = "접속 허용 (ALL)"
              server.remote_acecss_msg = "모든 포트 수신 허용"
              break;
          }
          if (server.os_type === "win") {
            if (rule.port_range_min <= 3389 && rule.port_range_max >= 3389) {
              server.is_remote_access = true;
              server.remote_status_msg = "접속 허용 (3389)"
              server.remote_acecss_msg = "3389포트 수신 허용"
            }
          } else {
            if (rule.port_range_min <= 22 && rule.port_range_max >= 22) {
              server.is_remote_access = true;
              server.remote_status_msg = "접속 허용 (22)"
              server.remote_acecss_msg = "22포트 수신 허용"
            }
          }
        }
      }
    }
    this._blank = ((this.serverLists != 0) ? true : false);
    this.spinner.hide();
  }

  reloadServerStatus(server_id, status, setting_status?) {
    if (!Array.isArray(status)) {
      throw 'status required Array Object';
    }
    this.osapi.getServer(server_id).subscribe((data: any) => {
      let server = this.serverLists.find(e=> e.server_id == server_id);
      // server = data;
      if(setting_status){
        server.status = setting_status;
      }else{
        server.status = data.status;
      }
      // console.log(data)
      if(data.image_name){
        server.image_name = data.image_name;
      }
      if(data.flavor_name){
        server.flavor_name = data.flavor_name;
      }
      if(data.flavor_id){
        server.flavor_id = data.flavor_id;
      }
      if(data.ip_private){
        server.ip_private = data.ip_private;
      }
      if(data.network_interfaces){
        server.network_interfaces = data.network_interfaces;
      }
      if (status.indexOf(data.status.toUpperCase()) != -1) {
        server.status = data.status;

        this.comp.isLoadedServer = false;
        this.comp.getServerList();
        this.getServerList();
        return 'stop reload';
      }
      if(setting_status){
        this.reloadServerStatus(server_id, status,setting_status);
      }else{
        this.reloadServerStatus(server_id, status);
      }
    },
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  openDialogStart(server_name, server_id) {
    const dialogRef = this.dialog.open(DialogConsoleServerListComponent, {
      width: '300px',
      data: {gubn: 'start', id: server_id, title: "서버 시작"
      , content: "'" + server_name + "'를 시작하시겠습니까?"}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.reloadServerStatus(server_id, ['ACTIVE', 'ERROR'],"BOOT");
      }
    });
  }

  openDialogStop(server_name, server_id) {
    const dialogRef = this.dialog.open(DialogConsoleServerListComponent, {
      width: '300px',
      data: {gubn: 'stop', id: server_id, title: "서버 정지"
      , content: "'" + server_name + "'를 정지하시겠습니까?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.reloadServerStatus(server_id, ['SHUTOFF', 'ERROR'],"SHUTTING DOWN");
      }
    });
  }

  openDialogRestart(server_name, server_id) {
    const dialogRef = this.dialog.open(DialogConsoleServerListComponent, {
      width: '300px',
      data: {gubn: 'reboot', id: server_id, title: "서버 재시작"
      , content: "'" + server_name + "'를 재시작하시겠습니까?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.reloadServerStatus(server_id, ['ACTIVE', 'ERROR'],"REBOOT");
      }
    });
  }
}

@Component({
  selector: 'app-mobile-console-server-dialog',
  templateUrl: './mobile-console-server-dialog.component.html',
  styleUrls: ['./mobile-console-server-list.component.css'],
  providers: [MobileConsoleServerListComponent]
})

export class DialogConsoleServerListComponent {

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogConsoleServerListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    console.log(this.data)
  }

  startServer(server_id) {
    this.spinner.show();
    this.osapi.startServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  stopServer(server_id) {
    console.log("정지");
    this.spinner.show();
    this.osapi.stopServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  softRebootServer(server_id) {
    console.log("재시작");
    this.spinner.show();
    this.osapi.softRebootServer(server_id).subscribe(
      (data: {}) => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}