import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}
  // companies

  getCompanies() {
    return this.http.get('/blsapi/companies');
  }

  getThemes() {
    return this.http.get('/blsapi/get-themes');
  }

  changeThemes(gubn: any) {
    return this.http.get('/blsapi/themes/'+gubn);
  }

  // setCompanies() {}

  enableCompany(registrationNumber: string,registrationNumberSub: string) {
    return this.http.post('/blsapi/company/enable', { registrationNumber,registrationNumberSub });
  }

}
