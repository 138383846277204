<!-- Container -->
<div id="Container" class="mypage">
        <div class="visual tp1">
            <div class="cont_inner">
                <h2>결제수단</h2>
            </div>
        </div>
        <div>
            <!-- section -->
            <section class="sec sec_1">
                <!-- sec_inner -->
                <div class="sec_inner">
                    <!-- sec_header -->
                    <div class="sec_header">
                        <h3>결제수단</h3>
                        <!-- sec_content_결제정보 존재 시 -->
                        <div class="sec_content">
                            <div class="rowGroup">
                                <div class="row">
                                    <p class="tit"><B>등록된 결제수단</B></p>
                                    <p class="tit" style="width: fit-content;">
                                        <ng-template [ngIf]="activePaymentType === '0'">
                                                <span class="mg_r15">등록된 결제수단이 없습니다. 사용할 결제수단을 등록해주세요.</span>
                                        </ng-template>&nbsp;
                                        <ng-template [ngIf]="activePaymentType === '1'">
                                            계좌번호: <span class="mg_r15">{{paymentWayResult.accountNumber}}</span>
                                            은행명: <span class="mg_r15">{{paymentWayResult.bank}}</span>
                                            예금주: <span class="mg_r15">{{paymentWayResult.accountName}}</span>
                                        </ng-template>&nbsp;
                                        <ng-template [ngIf]="activePaymentType === '2'">
                                            카드번호: <span class="mg_r15">{{paymentWayResult.cardNumber}}</span>
                                            card-cvc: <span class="mg_r15">{{paymentWayResult.cardCvc}}</span>
                                            카드만료일자: <span class="mg_r15">{{paymentWayResult.cardExpire}}</span>
                                            카드이름: <span class="mg_r15">{{paymentWayResult.cardName}}</span>
                                        </ng-template>&nbsp;
                                        <ng-template [ngIf]="activePaymentType === '3'">
                                            무통장입금
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- sec_content_결제정보 존재 시 -->
                        <div class="sec_content">
                            <div class="rowGroup">
                            <div class="row">
                                    <p class="">
                                    <ng-container *ngFor="let paymentType of paymentTypeList">
                                        &nbsp;<input type="radio" id={{paymentType.paymentwayId}} name="paymentTypeRadio" (change)="changePayType($event)" value={{paymentType.paymentwayId}} >
                                        &nbsp;<label for={{paymentType.paymentwayId}}>{{paymentType.type}}</label>                                    
                                    </ng-container>
                                    </p>
                            </div> 
                        </div>
                    </div>
                    </div>
                    <!-- //sec_header -->
                    
                    <!-- sec_content -->
                    <div class="sec_content">
                        <ng-template [ngIf]="selPaymentType==='1'">
                        <form [formGroup]="paymentBankForm">
                            <div class="rowGroup">
                                <div class="row">
                                    <p class="tit">계좌번호</p>
                                    <p class="cont">
                                        <input type="text" id="accountNumber" autocomplete="off" spellcheck="false" formControlName="accountNumber" placeholder="계좌번호를 입력해 주세요." required maxlength="14">
                                    </p>
                                    <div *ngIf="submitted && bankForm.accountNumber.errors" class="invalid-feedback">
                                        <div *ngIf="bankForm.accountNumber.errors.required" style="text-align: center;">계좌번호를 입력해 주세요.</div>
                                        <div *ngIf="bankForm.accountNumber.errors.pattern || bankForm.accountNumber.errors.minlength" style="text-align: center;">11 ~ 14자리 숫자만 입력해주세요.</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <p class="tit">은행명</p>
                                    <p class="cont">
                                        <input type="text" id="bank" autocomplete="off" spellcheck="false" formControlName="bank" placeholder="은행명을 입력해 주세요." required>
                                    </p>
                                    <div *ngIf="submitted && bankForm.bank.errors" class="invalid-feedback">
                                        <div *ngIf="bankForm.bank.errors.required" style="text-align: center;">은행명을 입력해 주세요.</div>
                                        <div *ngIf="bankForm.bank.errors.pattern" style="text-align: center;">한글만 입력 가능합니다.</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <p class="tit">예금주명</p>
                                    <p class="cont">
                                        <input type="text" id="accountName" autocomplete="off" spellcheck="false" formControlName="accountName" placeholder="예금주명을 입력해 주세요." required>
                                    </p>
                                    <div *ngIf="submitted && bankForm.accountName.errors" class="invalid-feedback">
                                        <div *ngIf="bankForm.accountName.errors.required" style="text-align: center;">예금주명을 입력해 주세요.</div>
                                        <div *ngIf="bankForm.accountName.errors.pattern" style="text-align: center;">한글만 입력 가능합니다.</div>
                                    </div>
                                </div>                            
                            </div>                                                
                            <div class="btn_wrap tp2">
                                <a class="btn big blue" (click)="onSubmitBank()">결제수단변경</a>
                            </div>
                        </form>
                        </ng-template>
                        <ng-template [ngIf]="selPaymentType==='2'">
                            <form [formGroup]="paymentCardForm">
                                <div class="rowGroup">
                                    <div class="row">
                                        <p class="tit">카드번호</p>
                                        <p class="cont">
                                            <input type="text" id="cardNumber" autocomplete="off" spellcheck="false" formControlName="cardNumber" placeholder="카드 번호를 입력해 주세요." required maxlength="14">
                                        </p>
                                        <div *ngIf="submitted && cardForm.cardNumber.errors" class="invalid-feedback">
                                            <div *ngIf="cardForm.cardNumber.errors.required" style="text-align: center;">카드 번호를 입력해 주세요.</div>
                                            <div *ngIf="cardForm.cardNumber.errors.pattern || cardForm.cardNumber.errors.minlength" style="text-align: center;">11 ~ 14자리 숫자만 가능합니다.</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <p class="tit">card-cvc</p>
                                        <p class="cont">
                                            <input type="text" id="cardCvc" autocomplete="off" spellcheck="false" formControlName="cardCvc" placeholder="card-cvc를 입력해 주세요." required maxlength="3">
                                        </p>
                                        <div *ngIf="submitted && cardForm.cardCvc.errors" class="invalid-feedback">
                                            <div *ngIf="cardForm.cardCvc.errors.required" style="text-align: center;">card-cvc를 입력해 주세요.</div>
                                            <div *ngIf="cardForm.cardCvc.errors.pattern || cardForm.cardExpire.errors.minlength">3자리 숫자만 가능합니다.</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <p class="tit">카드만료일자</p>
                                        <p class="cont">
                                            <input type="text" id="cardExpire" autocomplete="off" spellcheck="false" formControlName="cardExpire" placeholder="카드 만료 일자를 입력해 주세요." required maxlength="4">
                                        </p>
                                        <div *ngIf="submitted && cardForm.cardExpire.errors" class="invalid-feedback">
                                            <div *ngIf="cardForm.cardExpire.errors.required" style="text-align: center;">카드 만료 일자를 입력해 주세요.</div>
                                            <div *ngIf="cardForm.cardExpire.errors.pattern || cardForm.cardExpire.errors.minlength" style="text-align: center;">4자리 숫자만 가능합니다.</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <p class="tit">카드이름</p>
                                        <p class="cont">
                                            <input type="text" id="cardName" autocomplete="off" spellcheck="false" formControlName="cardName" placeholder="카드 이름을 입력해 주세요." required>
                                        </p>
                                        <div *ngIf="submitted && cardForm.cardName.errors" class="invalid-feedback">
                                            <div *ngIf="cardForm.cardName.errors.required" style="text-align: center;">카드 이름을 입력해 주세요.</div>
                                        </div>
                                    </div>
                                </div>                                                
                                <div class="btn_wrap tp2">
                                    <a class="btn big blue" (click)="onSubminCard()">결제수단변경</a>
                                </div>
                            </form>
                        </ng-template>
                        <ng-template [ngIf]="selPaymentType=== '3'">
                            <form [formGroup]="paymentAcountForm">
                                <div class="rowGroup">
                                    <div class="row">
                                        <p class="tit">계좌번호</p>
                                        <p class="tit">943-017922-01-034</p>
                                    </div>
                                    <div class="row">
                                        <p class="tit">예금주</p>
                                        <p class="tit">주식회사 비즈니스 링커 시스템</p>
                                    </div>
                                    <div class="row">
                                        <p class="tit">담당자이름</p>
                                        <p class="tit">이정신</p>
                                    </div>
                                    <div class="row">
                                        <p class="tit">전화번호</p>
                                        <p class="tit">070-7500-1290</p>
                                    </div>
                                    <div class="row">
                                        <p class="tit">이메일</p>
                                        <p class="tit">helpdesk@bizlinkersys.com</p>
                                    </div>
                                </div>
                                <div style="margin: 10px;">
                                    <p class="txt_red fs12"> * 입금시 입금자명은 '개인' 회원의 경우 회원 이름과 동일하게, '기업' 회원의 경우 법인명과 동일하게 보내주셔야 합니다.</p>
                                </div>
                                <div class="btn_wrap tp2">
                                    <a class="btn big blue" (click)="onSubmitAcount()">결제수단변경</a>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                    <!-- //sec_content -->
                </div>
                <!-- //sec_inner -->
            </section>
            <!-- //section -->            
        </div>
    </div>
    <!-- //Container -->
    <hr>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>