<div class="container">
  <div class="header pt_30">
    <ol class="breadcrumb">
      <li>Proejct</li>
      <li class="active">SubUser</li>
    </ol>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div *ngIf="_blank; else noneData;">
          <div class="card" *ngFor="let subUser of subUsers">
            <div class="card-content">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mFonts">
                        <i class="material-icons">supervisor_account</i>
                    </mat-panel-title>
                    <mat-panel-title class="mFonts">
                      {{subUser.userId}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="tbl_dtl_tp2">
                    <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{{subUser.userId}}</td>
                    </tr>
                    <tr>
                      <th>이름</th>
                      <td>{{subUser.name}}</td>
                    </tr>
                    <tr>
                      <th>담당 프로젝트</th>
                      <td><p *ngFor="let project of subUser.projects">
                        <ng-template [ngIf]="project.resource_data">
                          {{project.resource_data.value}}
                      </ng-template></p></td>
                    </tr>
                    <tr>
                      <th>사용자 유형</th>
                      <td>
                        <span *ngIf="subUser.userId==subUser.Company.adminId && subUser.type==0">대표관리자</span>
                        <span *ngIf="subUser.userId!=subUser.Company.adminId && subUser.type==0">중간관리자</span>
                        <span *ngIf="subUser.type==1">운영담당자</span>
                        <span *ngIf="subUser.type==2">회계담당자</span>
                      </td>
                    </tr>
                    <tr>
                      <th>생성일</th>
                      <td>{{subUser.createdDate.split("T")[0]}}</td>
                    </tr>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <ng-template #noneData>
          <h5 class="none_data">데이터 없음</h5>
        </ng-template>
      </div> 
    </div>
  </div>
  <div class="section">
    <div class="row">
    </div>
  </div>
  <br><br>
  <div class="section">
  </div>
</div>