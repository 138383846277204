import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';

@Component({
  selector: 'app-console-commercial-dbms',
  templateUrl: './console-commercial-dbms.component.html',
})
export class ConsoleCommercialDbmsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,) { }

  ngOnInit(): void {
      
  }
}
