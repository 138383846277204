<!-- Modal : 공인IP 할당 모달 -->
<div id="modal_publicip" class="modal2">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>
            멤버 수정
            <p class="sub-txt">로드 밸런서 멤버를 추가/수정/삭제 할 수 있습니다.</p>
        </h2>
    </div>
    <!-- //modal_header -->


    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">

            <div class="contArea" style="display:block">
                <div class="titleBox">추가 된 인스턴스 목록</div>
                <div class="boardCreateBox">
                    <table class="tbl_col_tp1">
                        <colgroup>
                            <col style="width:22%" />
                            <col style="width:22%" />
                            <col style="width:22%" />
                            <col style="width:22%" />
                            <col style="width: 10%; min-width: 100px;" />
                        </colgroup>
                        <thead>
                            <th>이름</th>
                            <th>IP 주소</th>
                            <th>PORT </th>
                            <th>Active/Standby</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let member of lbmember; let i = index;">
                                <td>{{member.name}}</td>
                                <td>{{member.address}}</td>
                                <td><input style="width:auto;" type="number" (change)="changeProtocol()" [(ngModel)]="member.protocol_port"></td>
                                <td *ngIf="!member.backup;else elseCase">Active</td>
                                <ng-template #elseCase>
                                    <td>Standby</td>
                                </ng-template>
                                <td><button class="btn sml dGray" (click)="delSubnet(i)">삭제</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="titleBox">사용 가능한 인스턴스 목록</div>
                <div class="boardCreateBox">
                    <table class="tbl_col_tp1">
                        <colgroup>
                            <col style="width:30%" />
                            <col style="width:30%" />
                            <col style="width:30%" />
                            <col style="width:10%; min-width: 100px;" />
                        </colgroup>
                        <thead>
                            <th>서버 이름 </th>
                            <th>IP 주소 </th>
                            <th>Active/Standby</th>
                            <th> </th>
                        </thead>
                        <tbody *ngFor="let server of serverList">
                            <tr *ngIf="checkSubnet(server)">
                                <td>{{server.server_name}}</td>
                                <td>
                                    <p *ngFor="let fixed_ip of server.ip_private">{{fixed_ip}}</p>
                                </td>
                                <td>
                                    <select
                                        (change)="changeMode($event.target.value,server)">
                                        <option value="0">Active</option>
                                        <option value="1">Standby</option>
                                    </select>
                                </td>
                                <td><button class="btn sml dGray" (click)="addSubnet(server)">추가</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="btn_wrap tp1"></div>
            </div>


        </div>
        <div class="btnArea tp1">
            <a (click)="updateMembers()" class="btn big ciblue mg_r4">적용</a>
            <a (click)="closeDialog()" class="btn big bor_gray">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->