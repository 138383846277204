<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>오브젝트 스토리지</h2>
        <div class="lineMap">Storage / <span>오브젝트 스토리지</span></div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <!-- section -->
        <section class="sec col12">
            <div class="sec_content">
                <!-- lyBox -->
                <div class="lyBox">
                    <div class="title_wrap">
                        <div class="contArea">
                            <div class="tit">
                                <app-console-projects></app-console-projects>
                            </div>
                            <div class="cont">
                                <app-console-storage-status></app-console-storage-status>
                            </div>
                            <ng-template [ngIf]="isJoined">
                                <div class="contSub">
                                    <button class="btn mid blue mg_r4" (click)="openNewBucketModal()"><i
                                            class="fa fa-hdd-o" style='margin-right: 5px;'></i>버킷 생성</button>
                                    <ng-template [ngIf]="currentBucket != ''">
                                        <button class="btn mid blue mg_r4" (click)="openNewObjectStorageFolderModal()">
                                            <!-- <span class="fa-stack" style='font-size: 10px; margin-right: 5px;'>
                                                <i class="fa fa-folder-o fa-stack-2x"></i>
                                                <i class="fa fa-plus fa-stack-1x"></i>
                                            </span> -->
                                            <i class="fa fa-plus-square-o" style='margin-right: 5px;'></i>
                                            새 폴더</button>
                                        <button class="btn mid ciblue mg_r4" (click)="openObjectUploadModal()"><i
                                                class="fa fa-cloud-upload" style='margin-right: 5px;'></i>업로드</button>
                                        <button class="btn mid ciblue mg_r4" (click)="objectDownload()" [disabled]="!isOneFile"><i
                                                class="fa fa-cloud-download" style='margin-right: 5px;'></i>다운로드</button>
                                        <button class="btn mid blue mg_r4" (click)="openCopyMoveObjectModal('copy')" [disabled]="chkCount == 0"><i
                                                class="fa fa-files-o" style='margin-right: 5px;'></i>복사</button>
                                        <button class="btn mid blue mg_r4" (click)="openCopyMoveObjectModal('move')" [disabled]="chkCount == 0"><i
                                                class="fa fa-share" style='margin-right: 5px;'></i>이동</button>
                                        <button class="btn mid blue mg_r4" (click)="openRenameObjectModal()" [disabled]="chkCount == 0"><i
                                                class="fa fa-pencil-square-o" style='margin-right: 5px;'></i>수정</button>
                                        <button class="btn mid blue mg_r4" (click)="openObjectDeleteModal()" [disabled]="chkCount == 0"><i
                                                class="fa fa-trash" style='margin-right: 5px;'></i>삭제</button>
                                        <button class="btn mid blue mg_r4" (click)="openObjectInfoModal()" [disabled]="chkCount != 1"><i
                                                class="fa fa-info-circle" style='margin-right: 5px;'></i>정보</button>
                                    </ng-template>
                                    <a class="btn mid blue" style="float: right;" (click)="openApiEndpointModal()"><i
                                            class="fa fa-globe mg_r8"></i>API 엔드포인트</a>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <!-- //lyBox -->
                <!-- lyBox -->
                <ng-template [ngIf]="isJoined === true" [ngIfElse]="notJoined">
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>오브젝트 스토리지 목록</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                        <!-- <div style="float:right;"> -->
                                                <span style="margin-right:5px;">
                                                    현재 경로 사용량 : {{ getCurrentBucketSize() }}
                                                </span>
                                                <!-- <div class="search-box search-object">
                                                    <span class="icon"><i class="fa fa-search"></i></span>
                                                    <input type="search" [(ngModel)]="filterObject" id="search"
                                                        placeholder="Search Objects..." />
                                                </div> -->
                                            <!-- </div> -->
                                    <input type="search" class="form-control" name="" [(ngModel)]="filterObject" id="search"
                                        value="" placeholder="Search Objects..." />
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid ciblue"><i
                                                class="ico ico_search_white"></i>검색</button>
                                    </div>
                                    <!-- 접기 버튼 -->
                                    <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <ng-template [ngIf]="currentBucket != ''">
                            <div class="pathArea">
                                <div class="file-manager-header" style="height: 6%;">
                                    <div class="header-path">
                                        <!-- /aaa/aaa2 -->
                                        <h3>
                                            <span class="main" aria-hidden="true"
                                                (click)="changeFolder('', 'folder')">{{
                                                currentBucket }}</span>
                                            <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                                            <span *ngFor="let p of currentPath" class="sub"
                                                (click)="changeFolder(p.path, 'folder')">{{
                                                p.name }}</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="contArea">
                            <div class="boardBox" style="height:79%; overflow:auto;">
                                <div class="boardBoxLeft">
                                    <div class=" box">
                                        <div class="search-box">
                                            <span class="icon"><i class="fa fa-search"></i></span>
                                            <input type="search" [(ngModel)]="filterBucket" id="search"
                                                placeholder="Search Buckets..." />
                                        </div>
                                    </div>
                                    <div class="bucketList">
                                        <ul>
                                            <li *ngFor="let bucket of bucketList  | tableFilter: filterBucket">
                                                <a class="bucket" (click)="onClickBucket($event)">{{ bucket.name }}</a>
                                                <div class="bucket-dropdown">
                                                    <button ejs-dropdownbutton [items]='items'
                                                        iconCss='fa fa-ellipsis-v'
                                                        cssClass='bucket-menu-icon e-caret-hide'
                                                        (select)='onBucketMenuSelect($event)'></button>
                                                    <!-- <i class="fa fa-ellipsis-v bucket-menu-icon"></i> -->
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="boardBoxRight">
                                    <!-- <ejs-filemanager id='multi' [ajaxSettings]='ajaxSettings'
                                        [navigationPaneSettings]='navigationPaneSettings'
                                        [toolbarSettings]='toolbarSettings' [allowMultiSelection]='allowMultiSelection'
                                        (fileSelect)='onFileSelect($event)'>
                                    </ejs-filemanager> -->
                                    <ng-template [ngIf]="currentBucket != ''">
                                        <!-- <div class="file-manager-header" style="height: 6%;">
                                            <div class="header-path">
                                                <h3>
                                                    <span class="main" aria-hidden="true"
                                                        (click)="changeFolder('', 'folder')">{{
                                                        currentBucket }}</span>
                                                    <span *ngFor="let p of currentPath" class="sub"
                                                        (click)="changeFolder(p.path, 'folder')">{{
                                                        p.name }}</span>
                                                </h3>
                                            </div>
                                        </div> -->
                                        <!-- <div class="file-manager-header-group">
                                            
                                            
                                        </div> -->
                                        <div class="file-manager-body" style="height: 80%;">
                                            <table class="tbl_col_tp1 pdTp2">
                                                <colgroup>
                                                    <col style="width: 5%;">
                                                    <col style="width: 60%;" />
                                                    <col style="width: 15%;" />
                                                    <col style="width: 20%;" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input type="checkbox" name="allchkbox" id="allChk" (change)="onChangeAllCheck($event)">
                                                        </th>
                                                        <th scope="col">이름</th>
                                                        <th scope="col">크기</th>
                                                        <th scope="col">수정날짜</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-template [ngIf]="objectList.length > 0" [ngIfElse]="NotFountObject">
                                                        <tr *ngFor="let object of objectList  | tableFilter: filterObject">
                                                            <td>
                                                                <input type="checkbox" name="chkbox" (change)="onChangeCheckedObject($event, object)">
                                                            </td>
                                                            <td class="object-text" style="text-align: left;"><i
                                                                    [className]="object.fileIcon + ' object-icon fa-lg'"
                                                                    aria-hidden="true"></i>
                                                                <label (click)="changeFolder(object.originName, object.type)">{{
                                                                    object.name
                                                                    }}</label>
                                                            </td>
                                                            <td class="object-text">{{ (object.type == 'file') ? object.size :
                                                                '' }}</td>
                                                            <td class="object-text">{{ (object.type == 'file') ?
                                                                object.lastModified : '' }}</td>
                                                            <!-- <ng-template [ngIf]="nas.description==null || nas.description==''">
                                                                <td>없음</td>
                                                            </ng-template>
                                                            <ng-template [ngIf]="nas.description">
                                                                <td>{{ nas.description }}</td>
                                                            </ng-template>
                                                            <td>{{ nas.size }} GB</td>
                                                            <td>
                                                                <label class="dot green"></label>{{ nas.status | uppercase }}
                                                            </td> -->
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template #NotFountObject>
                                                        <tr>
                                                            <td colspan="4" style="font-weight: bold; font-size:20px;height: 200px;">표시할 항목이 없습니다.</td>
                                                        </tr>
                                                    </ng-template>
                                                </tbody>
                                            </table>

                                            <div class="btn_wrap tp1"></div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template #notJoined>
                    <ng-template [ngIf]="isJoined === false" [ngIfElse]="waiting">
                        <div class="lyBox consoleRightBody">
                            <div class="titArea">
                                <h3>오브젝트 스토리지 서비스 신청</h3>
                            </div>
                            <div class="contArea" style="display: block;">
                                <div class="boardBox">
                                    <h1 style="font-size: 40px; margin: 40px;font-weight: bold;">Object Storage</h1>
                                    <p style="margin: 50px 0 10px 90px;font-size: 20px;font-weight: bold;">다양한 콘솔 디능에 연결
                                        가능한 데이터 저장공간</p>
                                    <p style="font-size: 20px; margin: 0 0 60px 90px;line-height: 30px;">
                                        Object Storage는 인터넷상에 원하는 데이터를 저장하고 사용할 수 있도록 주축된 오브젝트 스토리지입니다.<br />
                                        안전하게 보관해야 할 데이터나 대용량 데이터를 저장하는데 활용할 수 있으며,<br />
                                        S3 호환 API를 제공하므로 기존에 이용 중인 다양한 스토리지 관리도구를 활용할 수 있습니다.<br />
                                    </p>
                                    <div class="contSub"
                                        style="display: flex;justify-content: center;align-items: center;width: 165vh;">
                                        <a class="btn mid blue mg_r4"
                                            style="width: 200px;height: 40px;font-size: 17px;padding-top: 4px;"
                                            (click)="onClickJoin()">신청 하기</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-template>
                <ng-template #waiting>
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <div class="btnArea">
                                <div class="input-group fl">
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display: block;">
                            <div class="boardBox">
                                <div class="boardBoxLeft">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- <div *ngIf="isJoined; then Joined else notJoined"></div> -->
                <!-- <ng-template #Joined>

                </ng-template>
                <ng-template #notJoined>

                </ng-template>
                <ng-template #waiting></ng-template> -->
                <!-- //lyBox -->

                <!-- //lyBox -->
            </div>
        </section>
        <!-- //section -->
    </div>
    <!-- //content_body -->
</div>
<!-- //Contents -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#31a3cb" type="square-jelly-box" [fullScreen]="false">
    <!-- <p style="color: white;font-weight: bold;"></p> -->
</ngx-spinner>