<!-- Contents -->
<div id="Contents">
    <!-- content_header -->
    <div class="content_header col12">
        <h2>블록 스토리지</h2>
        <div class="lineMap">
            Storage / <span>Block Storage</span>
        </div>
    </div>
    <!-- //content_header -->

    <!-- content_body -->
    <div class="content_body">
        <form id="frm" class="frmBox1">
            <!-- section -->
            <section class="sec col12">
                <div class="sec_content">
                    <!-- lyBox -->
                    <div class="lyBox">
                        <div class="title_wrap">
                            <div class="contArea">
                                <div class="tit">
                                    <app-console-projects></app-console-projects>
                                </div>
                                <div class="cont">
                                    <app-console-storage-status></app-console-storage-status>
                                </div>
                                <div class="contSub">
                                    <button class="btn mid blue mg_r4" (click)="openNewStorageModal()"><i class="ico ico_register_white"></i>블록 스토리지 생성</button>
                                    <button class="btn mid ciblue mg_r4" (click)="openUpdateStorageModal()" [disabled]="!isActived">기본정보 수정</button>
                                    <button class="btn mid ciblue mg_r4" (click)="openAttachStorageModal()" [disabled]="!isActived || (selected_volume && selected_volume.attachments.length != 0)">서버연결 관리</button>
                                    <button class="btn mid ciblue mg_r4" (click)="openDetachStorageModal()" [disabled]="!isActived || (selected_volume && selected_volume.attachments.length == 0)">서버연결 해제</button>
                                    <!-- <button class="btn mid ciblue mg_r4" [disabled]="!isActived" onclick="fn_modalMsg('선택된 스토리지를 삭제합니다.')"><i class="ico ico_cancel_white"></i>스토리지 삭제</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
<!--                <div class="lyBox">
                        <div class="title_wrap">
                            <div class="contArea">
                                <div class="tit">
                                    <app-console-projects></app-console-projects>
                                </div>
                                <div class="cont">
                                    <a class="btn mid blue mg_r4" (click)="openNewStorageModal()"><i class="ico ico_register_white"></i>블록 스토리지 생성</a>
                                    <button class="btn mid ciblue mg_r4" [disabled]="!isActived" onclick="fn_modalMsg('선택된 스토리지를 삭제합니다.')"><i class="ico ico_cancel_white"></i>스토리지 삭제</button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- //lyBox -->
                    <!-- lyBox -->
                    <div class="lyBox consoleRightBody">
                        <div class="titArea">
                            <h3>스토리지 목록</h3>
                            <div class="btnArea">
                                <div class="input-group fl">
                                    <input type="search" class="form-control" name="" [(ngModel)]="filterText"
                                        [ngModelOptions]="{standalone: true}" id="search" value=""
                                        placeholder="검색어를 입력해주세요">
                                    <div class="input-group-btn pd_r10">
                                        <button type="submit" class="btn mid ciblue"><i class="ico ico_search_white"></i>검색</button>
                                    </div>
                                    <!-- 접기 버튼 -->
                                    <!-- <a class="btn_more btn mid_fix_one orange on"><i class="ico ico_moreTp1"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <div class="contArea" style="display:block">
                            <div class="boardBox">
                                <table class="tbl_col_tp1 dropmenu">
                                    <colgroup>
                                        <col style="width: 5%;">
                                        <col style="width: 25%;">
                                        <col style="width: 17%;">
                                        <col style="width: 17%;">
                                        <col style="width: 17%;">
                                        <col style="width: 19%;">
                                    </colgroup>
                                    <thead>
                                        <th scope="col">
                                            <!-- <app-common-chkbox [isChecked]="isChecked" (chkEvent)="onAllCheck($event)"></app-common-chkbox> -->
                                        </th>
                                        <th scope="col">이름</th>
                                        <th scope="col">설명</th>
                                        <th scope="col">크기</th>
                                        <th scope="col">상태</th>
                                        <!-- <th scope="col">그룹</th> -->
                                        <!-- <th scope="col">유형</th> -->
                                        <th scope="col">연결정보</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let volume of volumeList | tableFilter: filterText"
                                            (contextmenu)="onClickList(volume)">
                                            <td><input type="checkbox" name="sel_chkbox" 
                                                (change)="onListCheck($event)" [attr.data-id]="volume.id"
                                                (click)="onClickList(volume)"></td>
                                            <td><a class="btn_detail">{{volume.name}}</a></td>
                                            <td *ngIf="volume.description != null && volume.description != ''">{{volume.description}} </td>
                                            <td *ngIf="volume.description == null || volume.description == ''">없음</td>
                                            <td>{{volume.size}} GiB</td>
                                            <!-- {{volume.status}} -->
                                            <!-- <td>{{volume.status}}</td>-->
                                            <td>
                                                <ng-template [ngIf]="volume.status == 'available'">AVAILABLE</ng-template>
                                                <ng-template [ngIf]="volume.status == 'in-use'">IN-USE</ng-template>
                                            </td>
                                            <!-- <td *ngIf="volume.group_id != null;else elseCase">{{volume.group_id}}</td> -->
                                            <!-- <td>{{volume.volume_type}}</td> -->
                                            <ng-template [ngIf]="volume.attachments">
                                                <td *ngIf="volume.attachments.length != 0">
                                                    <ng-template [ngIf]="volume.attachments[0].server_name !=null">
                                                        {{volume.attachments[0].server_name}}:
                                                        {{volume.attachments[0].device}}
                                                    </ng-template>
                                                    <ng-template [ngIf]="volume.attachments[0].server_name ==null">
                                                        Loading...
                                                    </ng-template>
                                                </td>
                                                <td *ngIf="volume.attachments.length == 0">없음</td>

                                            </ng-template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="btn_wrap tp1"></div>
                        </div>
                    </div>
                    <!-- //lyBox -->
                </div>
            </section>
            <!-- //section -->

        </form>
    </div>
    <!-- //content_body -->

</div>
<!-- //Contents -->

<!-- dropdown -->
<div class="dropdown">
    <div *ngIf="selected_volume != null && selected_volume.attachments != null" id="myDropdown" class="dropdown-content">
        <a (click)="openNewSnapshotModal()">스토리지 스냅샷 생성</a>
        <a (click)="openUpdateStorageModal()">기본정보 수정</a>
        <!-- <a onclick="fn_modalOpen('storage_edit')">스토리지 타입 수정</a> -->
        
        <ng-template [ngIf]="selected_volume.bootable == 'true'">
            <a (click)="openNewImageModal()" >인스턴스 이미지로 업로드</a>
        </ng-template>
        <ng-template [ngIf]="selected_volume.bootable == 'false'">
            <a disabled>인스턴스 이미지로 업로드</a>
        </ng-template>
        <ng-template [ngIf]="selected_volume.attachments && selected_volume.attachments.length == 0">
            <a (click)="openAttachStorageModal()">서버연결 관리</a>
            <a disabled>서버연결 해제</a>
            <a (click)="openDeleteStorageModal()">스토리지 삭제</a>
        </ng-template>
        <ng-template [ngIf]="selected_volume.attachments && selected_volume.attachments.length != 0">
            <a disabled>서버연결 관리</a>
            <a (click)="openDetachStorageModal()">서버연결 해제</a>
            <a disabled>스토리지 삭제</a>
        </ng-template>
    </div>
</div>



<!-- Modal : 스토리지 타입 수정 모달 -->
<div id="storage_edit" class="modal">
    <!-- modal_header -->
    <div class="modal_header">
        <h2>스토리지 타입 수정</h2>
    </div>
    <!-- //modal_header -->

    <!-- modal_content -->
    <div class="modal_content">
        <div class="boardBox">
            <table class="tbl_row_tp1">
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row">유형</th>
                        <td>
                            <select name="" required>
                                <option value="">선택</option>
                                <option value="1">옵션 1</option>
                                <option value="2">옵션 2</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">마이그레이션 정책</th>
                        <td>
                            <select name="" required>
                                <option value="">선택</option>
                                <option value="1">옵션 1</option>
                                <option value="2">옵션 2</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btnArea tp1">
            <a href="#close-modal" rel="modal:close" class="btn big orange mg_r4">수정</a>
            <a href="#close-modal" rel="modal:close" class="btn big orange">취소</a>
        </div>
    </div>
    <!-- //modal_content -->
</div>
<!-- //Modal -->



