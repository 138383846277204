import { Injectable, Query } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class WebApiService {
  constructor(private http: HttpClient, private router: Router) { }

  blsLogin(param: any): Observable<HttpResponse<any>> {
    return this.http
      .post<any>('/webapi/log-in/', param, { observe: 'response' })
  }

  blsLogout() {
    return this.http.get('/blsapi/log-out/');
  }

  blsLoginCheck() {
    return this.http.get<any>('/blsapi/is-login-front').pipe(catchError(this.handleError));
  }

  blsAdminCheck() {
    return this.http.get<any>('/blsapi/is-admin-front');
  }

  blsUserList() {
    return this.http.get('/blsapi/user-list');
  }

  blsUserUpdate(param: any): Observable<HttpResponse<any>> {

    return this.http
      .post('blsapi/user-update', param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  blsGetCompanyId() {
    return this.http.get('blsapi/companyid');
  }

  blsGetResource() {
    return this.http.get('blsapi/resource');
  }
  blsCheckBSNO(registration_num) {
    const body = { registration_num: registration_num }
    return this.http.post('blsapi/chk-bsno', body);
  }
  getPfIp() {
    return this.http.get('blsapi/pf-ip');
  }

  isProjectAdmin() {
    return this.http.get<any>('blsapi/is-company-admin');
  }
  isSubAdmin() {
    return this.http.get<any>('blsapi/is-company-sub-admin');
  }

  getMemType() {
    return this.http.get<any>('blsapi/is-memtype');
  }

  isCompanyEnabled() {
    return this.http.get<any>('blsapi/is-company-enabled');
  }

  getServiceResourceList() {
    return this.http.get<any>('blsapi/services');
  }

  // 회원가입시 핸드폰 번호 인증 단계 1 - 코드 생성
  // 전송 내용 - mobile
  makeSignUpMobileCode(mobile: string): Observable<HttpResponse<any>> {
    return this.http
      .post('/webapi/registrations/make-code-mobile', { mobile: mobile}, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // 단계 2 - 코드 인증
  // 전송 내용 - {code} 
  confirmSignUpMobileCode(code: string): Observable<HttpResponse<any>> {

    return this.http
      .post('/webapi/registrations/confirm-code-mobile', { code: code }, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // find id 단계 1 - 코드 생성
  // 전송 내용 - { userName, mobile }
  makeFindIDCode(param: any): Observable<HttpResponse<any>> {

    return this.http
      .post('/webapi/registrations/make-code', param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // 단계 2 - 코드 인증
  // 전송 내용 - { code }
  confirmFindIDCode(code: string): Observable<HttpResponse<any>> {

    return this.http
      .post('/webapi/registrations/confirm-code', { code: code }, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // 단계 1 - 코드 생성
  // 전송 내용 - { userId, mobile }
  makePasswordResetCode(param: any): Observable<HttpResponse<any>> {

    return this.http
      .post('/webapi/passwords/make-code', param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // 단계 2 - 코드 인증
  // 전송 내용 - { code }
  confirmPasswordResetCode(param: any): Observable<HttpResponse<any>> {

    return this.http
      .post('/webapi/passwords/confirm-code', param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // 단계 3 - 비번 변경
  // 전송 내용 - { password }
  resetPaswordByCode(param: any): Observable<HttpResponse<any>> {

    return this.http
      .post('/webapi/passwords/reset-by-code', param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  isuniqueid(user_id: string) {
    const body = {
      userId: user_id,
    };
    return this.http.post('/webapi/signup/isuniqueid', body);
  }

  isuniquedomain(company_domain_id: string) {
    const body = {
      companyDomainId: company_domain_id
    };
    return this.http.post('/webapi/signup/isuniquedomain', body);
  }

  signup(param: any) {

    return this.http.post('/webapi/signup/form', param);
  }

  emailauth(param: any) {

    return this.http.post('/blsapi/signup/emailauth', { email: param });
  }

  emailenable(email){
    return this.http.post('/blsapi/email/enable',{email:email});
  }

  getCompanyList(param) : Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/companies?' + param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  getSubUserList(param): Observable<HttpResponse<any>>{
    return this.http
    .get('/webapi/subusers?'+ param, { observe: 'response' })
    .pipe(catchError(this.handleError));
  }

  getUserList() {
    return this.http
      .get('/webapi/users')
      .pipe(catchError(this.handleError));
  }

  getUserListWithStatus(companyStatus) {
    return this.http
      .get(`/webapi/users?status=${companyStatus}`)
      .pipe(catchError(this.handleError));
  }

  getUserListSearCh(param) : Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/companies?' + param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  getEmailHistoryList(param) {
    console.log(param);
    return this.http
      .get('/webapi/mailHistory?' + param)
      .pipe(catchError(this.handleError))
  }

  getUser(param: any): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/users/' + param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  updateUser(userId: any, userForm: any): Observable<HttpResponse<any>> {

    return this.http
      .put('/webapi/users/' + userId, userForm, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  getCompany(registrationNumber: any, registrationNumberSub: any): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/companies/' + registrationNumber +"/"+registrationNumberSub, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  getCompanyByRegistration(param:any){
    return this.http
    .post('/webapi/companies/getCompany',param)
    .pipe(catchError(this.handleError));
  }

  updateCompany(userId: any, companyForm: any) {

    return this.http
      .put(`/webapi/users/${userId}/both`, companyForm, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  //결제 수단 type get
  getCompanyPaymentWay(): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/paymentWays', { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  //등록된 결제 수단 get  
  getCompanyPaymentWayActive(param): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/companyPaymentWay?' + param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  //신규 결제 수단 등록
  postPaymentWayActive(param: any): Observable<HttpResponse<any>> {
    return this.http
      .post('/webapi/companyPaymentWay', param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }
  //결제 수단 변경
  updataPaymentWayActive(param: any): Observable<HttpResponse<any>> {

    return this.http
      .put('/webapi/companyPaymentWay/' + param.companypaymentwayId, param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }  

  //정산 내역 조회
  getClaimList(param: any): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/calculations?' + param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }
  
  updateClaim(param: any): Observable<HttpResponse<any>>{
    return this.http
      .put('/webapi/calculations/'+param.calculationId , param, { observe: 'response'})
      .pipe(catchError(this.handleError))
  }

  getInvoice(param) {
    return this.http
      .get('/webapi/invoices?' + param)
      .pipe(catchError(this.handleError));
  }

  getTotalInvoice(param) {
    return this.http
      .get('/webapi/totalInvoices?' + param)
      .pipe(catchError(this.handleError));
  }

  updateTotalInvoice(totalInvoicdId, param) {
    return this.http
      .put('/webapi/totalInvoices/' + totalInvoicdId, param)
      .pipe(catchError(this.handleError));
  }

  getCreditHistory(param) : Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/creditHistories?' + param, { observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  getAvailableCredit(registrationNumber,registrationNumberSub) : Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/creditHistories/sum/' + registrationNumber+'/'+registrationNumberSub, { observe: 'response'} )
      .pipe(catchError(this.handleError));
  }

  createCreditHistory(param) {

    return this.http
      .post('/webapi/creditHistories', param)
      .pipe(catchError(this.handleError));
  }

  getInquiryList(param): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/issues?' + param, { observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  getInquiryDetail(key): Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/issues?issue_id=' + key, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  getInquiryComment(key): Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/issue-comments?issue_id=' + key, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  insertInquiry(param: any) : Observable<HttpResponse<any>>{

    return this.http
      .post('/webapi/issues', param, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  updateInquiry(param: any) {
    return this.http
      .put('/webapi/issues/' + param.issueId, param)
      .pipe(catchError(this.handleError));
  }

  insertInquiryComment(param: any) {
    return this.http
      .post('/webapi/issue-comments', param)
      .pipe(catchError(this.handleError));
  }

  updateInquiryComment(param: any) {
    return this.http
      .put('/webapi/issue-comments/' + param.issueCommentId, param)
      .pipe(catchError(this.handleError));
  }

  getFaqList(param: any) : Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/articles?board_id=2' + param, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  createArticles(param: any) : Observable<HttpResponse<any>>{

    return this.http
      .post('/webapi/articles/form', param, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  updateArticlesForm(param: any) {
    return this.http
      .put('/webapi/articles/' + param.get('articleId') + '/form', param)
      .pipe(catchError(this.handleError));
  }

  updateArticles(param: any) {
    return this.http
      .put('/webapi/articles/' + param.articleId, param)
      .pipe(catchError(this.handleError));
  }

  deleteArticles(param: any){

    return this.http
      .delete('/webapi/articles/' + param.articleId, param )
      .pipe(catchError(this.handleError));
  }

  getNoticeList(querys): Observable<HttpResponse<any>>{

    return this.http
      .get('/webapi/articles?board_id=1' + querys, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  getNoticeView(key): Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/articles?article_id=' + key, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  getMaterialList(querys): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/articles?board_id=3' + querys, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }

  getMaterialView(key) : Observable<HttpResponse<any>>{
    return this.http
      .get('/webapi/articles?article_id=' + key, {observe: 'response'})
      .pipe(catchError(this.handleError));
  }


  getServiceList(param: any) {
    return this.http
      .get('/webapi/services?' + param)
      .pipe(catchError(this.handleError));
  }

  // getPriceList(param: any) {
  //   return this.http
  //     .get('/webapi/priceList?' + param)
  //     .pipe(catchError(this.handleError));
  // }

  createService(param: any) {

    return this.http
      .post('/webapi/services', param)
      .pipe(catchError(this.handleError));
  }

  updateService(param: any) {

    return this.http
      .put('/webapi/services/' + param.serviceId, param)
      .pipe(catchError(this.handleError));
  }

  deleteService(param: any) {

    return this.http
      .delete('/webapi/services/' + param.serviceId, param)
      .pipe(catchError(this.handleError));
  }

  getServiceHistory(param: any) {
    return this.http
      .get('/webapi/serviceHistory/test?board_id=1' + param)
      .pipe(catchError(this.handleError));
  }

  sendEmail(params) {
    /**
     * example
     * params={
     * receiver:'cbkim@bizlinkersys.com'
     * subject:'제목입니다.'
     * text:'본문입니다.'
     * }
     */
    return this.http
      .post('/blsapi/sendemail', params)
      .pipe(catchError(this.handleError));
  }

  createServiceHistoryPdf(data) {
    return this.http.post(
      '/webapi/invoices/servicehistory/pdf',
      data
    );
  }

  sendSms(params) {

    return this.http
      .post('/blsapi/sendsms', params)
      .pipe(catchError(this.handleError));
  }

  getSmsList(param: any) {
    return this.http
      .get('/blsapi/sms' + param)
      .pipe(catchError(this.handleError));
  }

  //회원 탈퇴 관련 서비스
  //회원 탈퇴 목록
  getWithdrawalsList(param: any): Observable<HttpResponse<any>> {
    return this.http
      .get('/webapi/withdrawals?' + param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }
  //회원 탈퇴 신청
  postWithdrawals(param: any): Observable<HttpResponse<any>> {
    return this.http
      .post('/webapi/withdrawals', param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  //회원 탈퇴 신청 수락
  putWithdrawalsConfirm(param: any): Observable<HttpResponse<any>> {

    return this.http
      .put('/webapi/withdrawals/' + param.withdrawalId, param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  //회원 탈퇴 신청 거부
  putWithdrawalsNotConfirm(param: any): Observable<HttpResponse<any>> {

    return this.http
      .put('/webapi/withdrawals/' + param.withdrawalId, param, { observe: 'response' })
      .pipe(catchError(this.handleError))
  }

  // 사용자 로그
  getUserLogs(){
    return this.http.get("/blsapi/user-log").pipe(catchError(this.handleError));
  }
  // 청구서 확인
  getInvoiceData(month){
    return this.http.get("/blsapi/invoice/"+month).pipe(catchError(this.handleError));
  }


  logout(){
    //alert('로그인 해주시기 바랍니다.');
    localStorage.clear();
    this.blsLogout().subscribe((data: {}) => {
      this.router.events.subscribe(e => {
        if(e instanceof NavigationEnd){
          window.location.reload();
        }
      });
      this.router.navigate(["/member/login/"]);
    });
  }
  //에러 handler
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        // 백엔드에서 실패한 것으로 보낸 에러.
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      switch (error.status) {
        case 500:
          break;
        case 401:          
          this.logout();
          break;
      }
    }
    return throwError(error);
  }
}
