import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';
import { ConsoleNavComponent } from '../../console-nav/console-nav.component';

@Component({
  selector: 'app-dialog-server-portforwarding-update',
  templateUrl: './dialog-server-portforwarding-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerPortforwardingUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerPortforwardingUpdateComponent>,
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  
  // selected_server: any = {
  //   image_snapshot_name: '',
  //   image_snapshot_description: '',
  // };
  
  resource: any = {};
  portForwardingLists: any = [];
  serverLists: any = [];

  /** 
   * 20200731 cbkim
   * 포트포워딩 다중 네트워크 지원하도록 수정
   * 네트워크 별 포트포워딩 IP 및 기타 정보
  */
  selected_pf: any = {
    pf_id: "",
    subnet_id: "",
    network_cidr: "",
    server_name:"",
    server_id: "",
    internal_port: "",
    external_port: "",
    internal_ip_address: "",
    protocol: "tcp",
    internal_port_id: "",
    is_port_forwarding: "",
    image_name: ""
  };
  pflist: any = [];

  ngOnInit(): void {

    // this.selected_server = this.dialogData.selected_server;
    this.serverLists = this.dialogData.serverLists;
    this.resource = this.dialogData.resource;
    this.portForwardingLists = this.dialogData.portForwardingLists;

    // this.setPflist(this.selected_server);
    this.setPflist();
  }

  
  createPortForwarding(pf) {



    if (pf.external_port == "" || pf.external_port == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '포트포워딩 설정',
          message: '외부 포트를 입력해주세요.'
        }
      });
      return;
    }

    if (pf.external_port < 1030 || pf.external_port > 49151) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '포트포워딩 설정',
          message: '외부포트는 1030 ~ 49151 범위의 포트만 입력해주세요.'
        }
      });
      return;
    }

    this.spinner.show();

    var id = pf.floatingip_id;

    /** 20200818 cbkim windows 3389 추가 */
    if (pf.image_name.includes("Windows")) {
      pf.internal_port = 3389;
    }

    let created_server_id = pf.server_id;
    delete pf['pf_id'];
    delete pf['image_name'];
    delete pf['floatingip_ip'];
    delete pf['floatingip_id']
    delete pf['network_cidr'];
    delete pf['server_id'];
    delete pf['server_name'];
    delete pf['subnet_id'];
    delete pf['is_port_forwarding'];
    delete pf['external'];

    this.osapi.createPortForwarding2(id, pf).subscribe((data: {}) => {

      for(let server of this.serverLists){
        if( created_server_id == server.server_id){
          server.is_port_forwarding = true;
          
        }

      }
      
      this.pflist = [];
      this.getPfList();

      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'success',
          title: '포트포워딩 설정',
          message: '포트포워딩 설정이 완료되었습니다.'
        }
      });

      
      this.spinner.hide();
    }, (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

  deletePortForwarding2(pf) {


    this.spinner.show();
    try {
      this.osapi
        .deletePortForwarding2(pf.fip_id, pf.id)
        .subscribe((data) => {
          this.spinner.hide();
          this.portForwardingLists = this.portForwardingLists.filter(
            (pfl) => pfl.id !== pf.id
          );
          this.frontapi.changeMessage({
            type: 'portfowarding refresh',
            body: {portForwardingLists:this.portForwardingLists,serverLists:this.serverLists}
          });
          // this.isAvailablePF = this.isAvailablePF_(
          //   this.selected_server.ip_private[0]
          // );

          // this.selected_server.is_port_forwarding = false;
          // this.setPflist();
          this.getPfList();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'success',
              title: '포트포워딩 삭제',
              message: '삭제가 완료되었습니다.'
            }
          });
        },
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });
    } catch (err) {
      console.error(err);
    }
  }
  
  
  getPfList() {
    this.selected_pf = {
      pf_id: "",
      subnet_id: "",
      network_cidr: "",
      server_name:"",
      server_id: "",
      internal_port: "",
      external_port: "",
      internal_ip_address: "",
      protocol: "tcp",
      internal_port_id: "",
      is_port_forwarding: "",
      image_name: ""
    };

    this.spinner.show();
    try {
      this.osapi.getPfList().subscribe((data: Array<any>) => {

        for(let server of this.serverLists){
          server.is_port_forwarding =false;
        }

        var t = data.map((item) => {
          this.serverLists.forEach((server) => {
            if (server.network_interfaces) {
              if (server.network_interfaces.length > 0) {
                for(let net_ifc of server.network_interfaces){
                  if (
                    net_ifc.adresses.indexOf(item.internal_ip_address) > -1
                  ) {
                    item['server_name'] = server.server_name;
                    item['port_forwarding_id'] = server.port_forwarding_id;
                    server.is_port_forwarding =true;
                  }
                }
              }
            }
            
          });
          return item;
        });
        
        this.setPflist();

        this.portForwardingLists = t;
        
        this.frontapi.changeMessage({
          type: 'portfowarding refresh',
          body: {portForwardingLists:this.portForwardingLists,serverLists:this.serverLists}
        });
        this.spinner.hide();
      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {

      this.spinner.hide();
    }
  }

  
  onChangeNew(pf_id){
    if(pf_id){
      this.selected_pf.external_port ="";
      for(let pf of this.pflist){
        if(pf.pf_id == pf_id){
          this.selected_pf = pf;
          this.selected_pf.external_port ="";
        }
      }
    }else{
      this.selected_pf = {
        pf_id: "",
        subnet_id: "",
        network_cidr: "",
        server_name:"",
        server_id: "",
        internal_port: "",
        external_port: "",
        internal_ip_address: "",
        protocol: "tcp",
        internal_port_id: "",
        is_port_forwarding: "",
        image_name: ""
      };
    }

  }
  
  setPflist() {
    this.pflist = [];
    let id_index = 0;

    for(let server of this.serverLists){

      if (!server.is_port_forwarding && server.ip_public == null) {
        // portforwarding 리소스 목록에서 cidr로 현재 설정 가능한 사설IP 추출
        // 네트워크 cidr 찾아오기
        for (let intf of server.network_interfaces) {
          for (let port of intf.ports) {
            for (let fixed_ip of port.fixed_ips) {
              // subnet->network->port_forwardings
              for (let res of this.resource) {

                if (res.resourceType === 'subnet') {
                  if (res.objectId == fixed_ip.subnet_id) {


                    let intport = 22;


                    if (server.image_name.includes("Windows")) {
                      intport = 3389;
                    }
                    this.pflist.push({
                      pf_id: id_index,
                      subnet_id: res.objectId,
                      network_cidr: res.value,
                      server_name: server.server_name,
                      server_id: server.server_id,
                      internal_port: intport,
                      external_port: server.port_forwarding_port,
                      internal_ip_address: fixed_ip.ip_address,
                      protocol: "tcp",
                      internal_port_id: port.id,
                      is_port_forwarding: server.is_port_forwarding,
                      image_name: server.image_name
                    })
                    id_index++;
                  }
                }
              }
            }
          }
        }
  
        for (let pf of this.pflist) {
          pf.external = false;
        }
        // pf 찾아서 셋팅
        for (let res of this.resource) {
          if (res.resourceType === 'portforwarding ip') {
            for (let pf of this.pflist) {
              if (res.description == pf.network_cidr) {
                // 같은네트워크
                pf.floatingip_ip = res.value;
                pf.floatingip_id = res.objectId;
                pf.external = true;
              }
            }
          }
        }
  
        this.pflist = this.pflist.filter(pf => pf.external === true);
      }

    }

  }


}
