import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-server-interface-update',
  templateUrl: './dialog-server-interface-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogServerInterfaceUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogServerInterfaceUpdateComponent>

  ) {
    super();
    this.dialogData = data
    this.dialogReference = dialogRef;
  }

  // inf
  // selected_fixedip: string;
  selected_subnet: any = {};

  subnetList: any = [];
  selected_server: any;

  ngOnInit(): void {

    this.subnetList = this.dialogData.subnetList;
    this.selected_server = this.dialogData.selected_server;
    this.setPrivateIpInterfaceName();
  }



  // selectFixedIp(ip) {
  //   this.selected_fixedip = ip;
  // }

  selectSubnet(cidr) {
    for (let subnet of this.subnetList) {
      if (subnet.cidr === cidr) {
        this.selected_subnet = subnet;
        break;
      }
    }
  }

  setPrivateIpInterfaceName() {
    let ip_private_data = [];
    for (let privateip of this.selected_server.ip_private) {
      let ip_private_object: any = {};
      ip_private_object.addr = privateip;
      for (let netinterface of this.selected_server.network_interfaces) {
        for (let port of netinterface.ports) {
          if(port.fixed_ips[0].ip_address==privateip){
            for(let subnet of this.subnetList){
              if (port.fixed_ips[0].subnet_id == subnet.id) {
                ip_private_object.name = subnet.name;
                break;
              }
            }

          }
        }
      }
      ip_private_data.push(ip_private_object);
    }
    this.selected_server.ip_private_data = ip_private_data;
  }


  getServer() {
    this.osapi.getServer(this.selected_server.server_id).subscribe((data: any) => {

      this.selected_server = data;
      this.setPrivateIpInterfaceName();
      this.spinner.hide();
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  addInterface() {


    var serverId = this.selected_server.server_id;
    var networkId = this.selected_subnet.network_id;


    if (serverId == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      return;
    }
    if (networkId == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'error',
          title: '오류 알림',
          message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi.serverInfAttach(serverId, networkId).subscribe((data: any) => {
      this.spinner.hide();

      this.selected_server.ip_private_data.push({name:this.selected_subnet.name,addr:"할당 중"});
      //location.reload();
      // setTimeout(() => {
        this.frontapi.changeMessage({
          type: 'serverlist refresh',
          body: {}
        });
        this.getServer();
      // }, 5000);
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

  detachInterface(chip) {
    /**
     * 20200921 cbkim
     * 해제 후 port 찾아서 삭제 
     */
    var network_id;

    this.spinner.show();
    // var chip = this.selected_fixedip;
    var port_id;
    var server_id;
    for (let inf of this.selected_server['network_interfaces']) {
      for (let port of inf['ports']) {
        for (let ip of port['fixed_ips']) {
          if (chip === ip.ip_address) {
            port_id = port.id;
            server_id = this.selected_server.server_id;
            network_id = port.network_id;
          }
        }
      }
    }

    this.osapi.serverInfDetach(server_id, port_id).subscribe((data: any) => {
      this.spinner.hide();

      this.osapi.deletePort(network_id,port_id).subscribe((data)=>{});
      
      for(let ip of this.selected_server.ip_private_data){

        if(ip.addr == chip){
          ip.addr = "해제 중";
        }
      }
      setTimeout(() => {
        
        this.frontapi.changeMessage({
          type: 'serverlist refresh',
          body: {}
        });
        this.getServer();
      }, 5000);
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

}
