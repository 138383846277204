import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import {Router} from '@angular/router'
import {WebApiService} from './webapi.service';

@Injectable({
  providedIn: 'root',
})
export class MonitorService {
  projects = [];
  // userInfo: { projectId: string; userId: string; companyId: number };
  userInfo: any;
  _that = this;

  constructor(private http: HttpClient,private router:Router, private webapi:WebApiService) {
    this.handleError = this.handleError.bind(this);
   }

  getServerDatas() {
    return this.http.get('/monitorapi/').pipe(catchError(this.handleError));
  }

  getServerMetrics(projectId,whereDate){ 
    return this.http.post(`/monitorapi/getMetric`,{projectId:projectId,whereDate:whereDate}).pipe(catchError(this.handleError));
  }

  logout(){
    //alert('로그인 해주시기 바랍니다.');
    localStorage.clear();
    this.webapi.blsLogout().subscribe((data: {}) => {
      this.router.navigate(["/member/login/"]);
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
    } else {
      // console.error(
      //   // 백엔드에서 실패한 것으로 보낸 에러.
      //   `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      // );

      // 백엔드 공통 오류 처리
      switch (error.status) {
        case 500:
          break;
        case 401:          
          this.logout();
          break;
      }
    }
    return throwError(error);
  }
}