import { Component, OnInit } from '@angular/core';

import { GWService } from '../../gwapi.service';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';

import { DialogSetService} from '../dialog/dialog-set.service';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';

@Component({
  selector: 'app-console-apigateway-auth-list',
  templateUrl: './console-apigateway-auth-list.component.html',
})

export class ConsoleApigatewayAuthListComponent implements OnInit {

  isActived: boolean = false;
  isChecked: boolean = false;
  // 검색 텍스트
  filterText: string;
  
  selected_auth: any = {};
  auth_list:any;
  constructor(
    // @Inject(DOCUMENT) private document: Document,
    // private renderer: Renderer2,
    // private http: HttpClient,
    private gwapi:GWService,
    private osapi:OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi:FrontApiService,
    private router:Router,
    public global: GlobalService,
    private dialogs:DialogSetService,
    private common:ConsoleNavComponent
    ) { }

    ngOnInit(): void {
      this.getAuthList();
    }
    getAuthList(){
      this.spinner.show();
      this.gwapi.getAuthList().subscribe((data: any) => {
        this.auth_list = data;
        console.log(data)
        this.spinner.hide();
      }, 
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });

    }
    onClickList(auth) {
      this.isActived = true;
      this.selected_auth = auth;
      console.log(auth)
    }

    
  openGwAuthCreateDialog() {
    this.dialogs.openGwAuthCreateDialog({});
    this.dialogs.setAfterClosed().subscribe(result => {
      if (result == "success") {
        this.getAuthList();
      }
    });

    
  }

  
  openDeleteAuthModal(auth){
    this.selected_auth = auth;
    this.dialogs.openDefaultDeleteDialog({
      title:"API KEY 삭제",
      content:"선택하신 API KEY를 삭제합니다. 삭제 후에는 더이상 해당 키로 API를 사용할 수 없습니다."});
    this.dialogs.setAfterClosed().subscribe(result => {
      if(result == "okay"){
        this.deleteAuth();
      }
    });
  }
  
  deleteAuth() {
    this.spinner.show();
    console.log(this.selected_auth)
    this.gwapi.deleteAuth(this.selected_auth.kong_consumer_id).subscribe((data: {}) => {
      this.spinner.hide();
      this.getAuthList();
    }, 
    (error) => {
      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }

}
