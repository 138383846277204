import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../../../osapi.service';
import { WebApiService } from '../../../../webapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AdminNavComponent } from '../../admin-nav/admin-nav.component'

@Component({
  selector: 'app-admin-subuser',
  templateUrl: './admin-subuser.component.html',
})
export class AdminSubuserComponent implements OnInit {

  constructor(
    private osapi: OsapiService, 
    private webapi: WebApiService, 
    private spinner: NgxSpinnerService,
    private common: AdminNavComponent
    ) {    
  }
  subUsers: any = [];

  //페이징변수
  curPage: any;
  totalCount: any;

  ngOnInit(): void {
    this.getSubUserPage(0);
  }

  getSubUserPage(page){
    this.curPage = page;
    var searchQuerys = 'o=created_date.DESC&l=10&s=' + this.curPage * 10;

    this.spinner.show();
    this.webapi.getSubUserList(searchQuerys).subscribe(
      (response) => {
        const data = response.body
        this.subUsers = data['result'];
        this.totalCount = data['total'];
        this.getProjectName();
        this.common.getThemeFont();
      }, (err) => {

      }
    );
    this.spinner.hide();
  }

  getProjectName(){
    for(let item of this.subUsers){
      this.osapi.getProjectsById(item.horizon_uid).subscribe(
        (data:any)=>{
          item.projects = data.items;
        }
      )
    }
  }

  //페이징함수
  getMovePage(e) {

    this.getSubUserPage(e.page);
  }
}
