import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { OsapiService } from './osapi.service';
import { FrontApiService } from './frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

declare function fn_modalOpen(msg):any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public selected_server_list:Array<any>=[];
  public selected_storage_list:Array<any>=[];
  public selected_keypair_list:Array<any>=[];
  volumeList: any=[];
  serverList: any=[];
  imageLists: any=[];
  snapshotLists: any=[];


  clearSelectedList(){
    this.selected_server_list =new Array;
    this.selected_storage_list =new Array;
    this.selected_keypair_list =new Array;
  }
  clearModalData(){

  }
  
  getImageList(){
    this.osapi.getImageList().subscribe(
      (res:any) => {

      this.imageLists= res.body;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });  
  }
  
  getSnapshotList(){
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:{items:[]}) => {

      this.snapshotLists= data.items;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });  
  }
  getVolumeList(){    
    this.osapi.getVolumeList().subscribe(
      (data:{items:[]}) => {

      this.volumeList= data.items;
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });    
  }
  getServerList(){
    this.osapi.getServerList().subscribe(
      (data: {}) => {

      this.serverList= data;

      this.volumeList.forEach(volume => {
        volume.attachments.forEach(attachment => {
          this.serverList.forEach(server => {
            if(attachment.server_id == server.server_id){
              attachment.server_name = server.server_name;
            }
          });
        });
      });
    }, 
    (error) => {

      this.spinner.hide();
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
    });
  }
  
  
  
  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router) { }
}
