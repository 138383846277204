import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../global.service';
import { Router} from '@angular/router';
import {DialogSetService} from '../dialog/dialog-set.service';
import { OsapiService } from '../../osapi.service';
import {StatusData} from './StatusData';
import { ConsoleNavComponent } from '../console-nav/console-nav.component';
import { CephapiService } from '../../cephapi.service';

@Component({
  selector: 'app-console-storage-status',
  templateUrl: './console-storage-status.component.html',
})
export class ConsoleStorageStatusComponent implements OnInit {

  @Input() message: string;
  @Output() modalMsg = new EventEmitter();

  volumeList:any=[];
  snapshotList:any=[];
  nasList:any=[];
  
  blockData=new StatusData();
  snapshotData=new StatusData();
  nasData=new StatusData();
  objectStorageData:any = {};

  constructor(private spinner: NgxSpinnerService,
    public global: GlobalService,
    private osapi:OsapiService,
    private common: ConsoleNavComponent,
    private cephapi: CephapiService,
  ) {
    
  }

  ngOnInit(): void {
    this.getVolumeList();
    this.getVolumeSnapshotList();
    this.getNasList();
    this.getObjectStorageUsage();
  }

  getNasList(){
    this.osapi.getNasList().subscribe(
      (data:any)=>{
        this.nasList = data;
        this.nasList.forEach(nas => {
          this.nasData.totalSize = this.nasData.totalSize + nas.size;
          if(nas.status == "in-use"){
            this.nasData.inuseCount++;
          }else if(nas.status == "available"){
            this.nasData.availableCount++;
          }
        });
      }
    )
  }

  getVolumeSnapshotList(){
    this.osapi.getVolumeSnapshotList().subscribe(
      (data:any)=>{

        this.snapshotList = data.items;
        this.snapshotList.forEach(snapshot => {
          this.snapshotData.totalSize = this.snapshotData.totalSize + snapshot.size;
          if(snapshot.status == "in-use"){
            this.snapshotData.inuseCount++;
          }else if(snapshot.status == "available"){
            this.snapshotData.availableCount++;
          }
        });
      }
    )
  }

  getVolumeList(){
    this.osapi.getVolumeList().subscribe(
      (data:any)=>{

        this.volumeList = data.items;
        this.volumeList.forEach(volume => {
          this.blockData.totalSize = this.snapshotData.totalSize + volume.size;
          if(volume.status == "in-use"){
            this.blockData.inuseCount++;
          }else if(volume.status == "available"){
            this.blockData.availableCount++;
          }
        });
        this.common.getThemeFont();
      }
    )
  }

  async getObjectStorageUsage() {
    var check_service:any = await this.cephapi.checkService().toPromise();
    if(check_service.check) {
      this.cephapi.getUsage().subscribe(
        (data:any) => {
          // console.log(data);
          this.objectStorageData.totalBuckets = data.buckets.length;
          this.objectStorageData.totalObjects = data.summary.totalEntries;
          this.objectStorageData.totalFile = data.summary.totalFile;
          this.objectStorageData.totalFolder = data.summary.totalFolder;
          this.objectStorageData.totalBytes = data.summary.transTotalBytes + " (" + data.summary.totalBytes + ")";
          this.objectStorageData.totalBytesRounded = data.summary.transTotalBytesRounded + " (" + data.summary.totalBytesRounded + ")";
        }
      )
    } else {
      this.objectStorageData.totalBuckets = 0;
      this.objectStorageData.totalObjects = 0;
      this.objectStorageData.totalFile = 0;
      this.objectStorageData.totalFolder = 0;
      this.objectStorageData.totalBytes = 0;
      this.objectStorageData.totalBytesRounded = 0;
    }
  }
}
