import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-nas-rule-update',
  templateUrl: './dialog-nas-rule-update.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNasRuleUpdateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNasRuleUpdateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  selected_nas: any = {};
  newRule: any = { access_level: 'rw', access_to: '' };
  
  ngOnInit(): void {

    this.selected_nas = this.dialogData.selected_nas;
  }

  
  createAccessRule() {
    if (this.newRule.access_level && this.newRule.access_to) {
      //TODO: IP에 대한 유효성 검사


      this.spinner.show();
      try {
        this.osapi
          .createNasAcceessRule(this.selected_nas.id, this.newRule)
          .subscribe((data: { access:any }) => {
            this.spinner.hide();

            if(data.access == null){
              throw "Access Data is NULL"
            }
            this.selected_nas.ruleList.push(data.access);

          }, 
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type:'toast',
              body:{
                type:'error',
                title:'오류 알림',
                message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
                }
            });
        });
      } catch (e) {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type:'toast',
          body:{
            type:'error',
            title:'오류 알림',
            message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      }
    }
  }

  deleteAccessRule(oldRuleId) {
    try {
      this.spinner.show();
      this.osapi.deleteNasAcceessRule(this.selected_nas.id, oldRuleId).subscribe(() => {
          this.spinner.hide();
          this.selected_nas.ruleList = this.selected_nas.ruleList.filter((rule) => rule.id !== oldRuleId);
        }, 
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type:'toast',
            body:{
              type:'error',
              title:'오류 알림',
              message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
    } catch (e) {

    }
  }
  


}
