import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-disassociate-ip',
  templateUrl: './dialog-disassociate-ip.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogDisassociateIpComponent extends DialogCommon implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogDisassociateIpComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }

  selected_floatingip;
  selected_server;

  ngOnInit(): void {

    if(this.dialogData.selected_floatingip){
      this.selected_floatingip = this.dialogData.selected_floatingip
    }
  }
  disassociateFloatingIp() {

    if(this.selected_floatingip == null){
      this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      return;
    }
    this.spinner.show();
    this.osapi
      .disassociateFloatingIp(this.selected_floatingip.id)
      .subscribe((data: {}) => {
        this.spinner.hide();
        this.closeDialogMsg("success");
        // if(this.router.url=='/console/publicip'){
        //   this.router.navigateByUrl('/console', {
        //     skipLocationChange: true,
        //   }).then(
        //     () => {
        //       this.router.navigateByUrl('/console/publicip');
        //     }
        //   );
        // }else{
        //   this.router.navigate(['/console/publicip']);
        // }
      }, 
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
        type:'toast',
        body:{
          type:'error',
          title:'오류 알림',
          message:'일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
        }
      });
      });
  }
 
}
