
export class DialogCommon {
    public dialogData;
    public dialogReference;
    constructor(){
    }
    closeDialog() {
        this.dialogReference.close();
    }
    closeDialogMsg(msg) {
        this.dialogReference.close(msg);
    }
}