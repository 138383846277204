import { Component, OnInit, Inject } from '@angular/core';
import { OsapiService } from '../../../osapi.service';
import { FrontApiService } from '../../../frontapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogCommon } from '../../dialog/dialog-common';

@Component({
  selector: 'app-dialog-network-create',
  templateUrl: './dialog-network-create.component.html',
  styleUrls: ['../dialog-common.css']
})
export class DialogNetworkCreateComponent extends DialogCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
    private router: Router,
    private osapi: OsapiService,
    public dialogRef: MatDialogRef<DialogNetworkCreateComponent>
    
  ) { 
    super();
    this.dialogData=data
    this.dialogReference =dialogRef;
  }
  
  networkModel: any;

  resource: any = [];

  network_cidr_validate= true;
  network_cidr_not_used= true;
  private_network_cidr_validate=true;

  ngOnInit(): void {

    this.networkModel = this.dialogData.networkModel;
    this.resource = this.dialogData.resource;
  }


  
  createNetwork() {
    
    if (this.networkModel.name == ''|| this.networkModel.name == null) {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `이름을 입력해주세요.`
        }
      });
      return;
    }
    if (!this.networkModel.cidr) {
      this.networkModel.cidr = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `CIDR을 입력해주세요.`
        }
      });
      return;
    }
    if (this.network_cidr_validate == false || this.private_network_cidr_validate == false) {
      this.networkModel.cidr = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `유효하지 않은 CIDR 입니다.`
        }
      });
      return;
    }
    if (this.isAlreadyUsedCidr(this.networkModel.cidr)) {
      this.networkModel.cidr = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: `이미 사용중인 네트워크 대역입니다.
          ('10.21.1.0/24','10.250~253.0.0/16' 대역은 
          사용불가능 대역입니다. )`
        }
      });
      return;
    }
    if ( this.networkModel.name =="net_str" || this.networkModel.name =="net_ext" || this.networkModel.name =="net_pri") {
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: "'net_str', 'net_ext', 'net_pri'는 사용불가능한 이름입니다."
        }
      });
      return;
    }
    this.spinner.show();
    let name = this.networkModel.name;
    let cidr = this.networkModel.cidr;
    let type = this.networkModel.type;
      /**
       * 외부 네트워크 생성
       * 1. 라우터 생성
       * 2. 네트워크 생성
       * 3. Resource 등록
       * 4. 인터페이스 연결 (X 서브넷에서 연결)
       * 5. 포트포워딩 IP 할당
       */


    // 1. 라우터 생성 & 3. Resource 등록
    this.osapi.createRouters({ cidr: cidr }).subscribe((data: any) => {
      var router = data;
      // 2. 네트워크 생성
      this.osapi.createNetwork(true, name, false).subscribe((data: any) => {
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'success',
            title: '네트워크 생성',
            message: `외부 네트워크 ${name} 생성이 완료되었습니다.`
          }
        });

        const params = {
          resourceType: 'network',
          value: cidr,
          objectId: data.id,
          description: "ext",
          status: "CREATING"
        };
        this.osapi.blsSetResource(params).subscribe((data: any) => {

        },
          (error) => {

            this.spinner.hide();
            this.frontapi.changeMessage({
              type: 'toast',
              body: {
                type: 'error',
                title: '오류 알림',
                message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
              }
            });
          });

      },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        });
      // 5. 포트포워딩 할당

      var subnetId;
      var networkId = router.external_gateway_info.network_id;
      for (let item of router.external_gateway_info.external_fixed_ips) {
        subnetId = item.subnet_id;
        break;
      }
      this.osapi.createFloatingIpFf({
        subnet_id: subnetId,
        floating_network_id: networkId,
        cidr: cidr
      }).subscribe(
        (data: any) => {

          this.spinner.hide();
          this.closeDialogMsg("success");

          // const params = {
          //   resourceType: 'portforwarding ip',
          //   value: data.floating_ip_address,
          //   objectId: data.id,
          //   description: cidr,
          //   status: "CREATING"
          // };
          // this.osapi.blsSetResource(params).subscribe((data: any) => {
          //   this.spinner.hide();
          //   this.closeDialogMsg("success");

          // }, (error) => {

          //     this.spinner.hide();
          //     this.frontapi.changeMessage({
          //       type: 'toast',
          //       body: {
          //         type: 'error',
          //         title: '오류 알림',
          //         message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          //       }
          //     });
          //   });
        },
        (error) => {

          this.spinner.hide();
          this.frontapi.changeMessage({
            type: 'toast',
            body: {
              type: 'error',
              title: '오류 알림',
              message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
            }
          });
        }
      )
    },
      (error) => {

        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            type: 'error',
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }
  

  
  validate_false_by_blsnet=false;
  isAlreadyUsedCidr(new_cidr) {
    //리소스 테이블에서 해당 유저가 사용중인 네트워크 CIDR 을 불러와 중복 체크 수행
    let chk_flag = false;
    const bls_network_list=[
      {addr:"10.21.1.0/24"},
      {addr:"10.250.0.0/16"},
      {addr:"10.251.0.0/16"},
      {addr:"10.252.0.0/16"},
      {addr:"10.253.0.0/16"},
    ];
    for(let blsnet of bls_network_list){
      if (!this.compareCidr(new_cidr, blsnet.addr)) {
        chk_flag = true;
        this.validate_false_by_blsnet=true;
      }
    }
    for(let rsc of this.resource){
      if(rsc.resourceType == "network"){

        if (!this.compareCidr(new_cidr, rsc.value)) {
          chk_flag = true;
        }
      }
    }
    // for (let network of this.networkList) {
    //   if (!this.compareCidr(new_cidr, network.cidr)) {
    //     chk_flag = true;
    //   }
    // }
    return chk_flag;
  }
  
  validNetworkName() {
    /**
     * 영문 숫자 _ 만 허용
     */
    var reg = /^[a-zA-Z0-9_\-+]*$/;
    if (reg.test(this.networkModel.name)) {

    } else {
      this.networkModel.name = '';
      this.frontapi.changeMessage({
        type: 'toast',
        body: {
          type: 'warning',
          title: '경고',
          message: '영문자, _(언더바), -(하이픈) 만 사용 가능합니다.'
        }
      });

    }
  }
  validNetworkCidr() {

    //CIDR 체크  masking 비트가 포함된 IPv4 주소 문자열 true
    var hard_cidr_filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/
   
    if(hard_cidr_filter.test(this.networkModel.cidr) == false){
      this.network_cidr_validate = false;
      this.network_cidr_not_used = true;
      this.private_network_cidr_validate = true;
      return;
    }else{
      this.network_cidr_validate = true;
    }

    // 사용 중인지 대역인지 유효성 검사
    if (this.isAlreadyUsedCidr(this.networkModel.cidr)) {
      this.network_cidr_not_used = false;
      this.network_cidr_validate = true;
      this.private_network_cidr_validate = true;
      return;
    }else{
      this.network_cidr_not_used = true;
    }
    this.private_network_cidr_validate = this.validPrivateNetworkCidr(this.networkModel.cidr);
    
  }

  validPrivateNetworkCidr(cidr) {
    var ip192 = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}/;
    var ip172 = /(172)\.(1[6-9]|2[0-9]|3[0-2])(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}/;
    var ip10 = /(10)(\.([2]([0-5][0-5]|[01234][6-9])|[1][0-9][0-9]|[1-9][0-9]|[0-9])){3}/;

    const ip_addr = cidr.split('/')[0];
    const masking_bit = cidr.split('/')[1];
    if (ip192.test(ip_addr)) {
      if (parseInt(masking_bit) < 16 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }
    else if (ip172.test(ip_addr)) {
      if (parseInt(masking_bit) < 12 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }
    else if (ip10.test(ip_addr)) {
      if (parseInt(masking_bit) < 8 || parseInt(masking_bit) > 24) {
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }
  
  compareCidr(cidr, cidr2) {
    var split_masking_bit = cidr.split('/');
    var split_masking_bit2 = cidr2.split('/');
    var splited = split_masking_bit[0].split('.');
    var splited2 = split_masking_bit2[0].split('.');

    let original_bit = '';
    for (var i of splited) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit += bit;
    }

    let original_bit2 = '';
    for (var i of splited2) {
      var bit = parseInt(i).toString(2)
      for (var j = 1; j < 9; j++) {
        if (bit.length < j) {
          bit = '0' + bit;
        }
      }
      original_bit2 += bit;
    }

    let chk_flag = false;
    for (var i: any = 0; i < split_masking_bit[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag = true;
    }
    let chk_flag2 = false;
    for (var i: any = 0; i < split_masking_bit2[1]; i++) {
      if (original_bit[i] != original_bit2[i])
        chk_flag2 = true;
    }
    return chk_flag && chk_flag2;
  }

  // network_create_message = "외부 네트워크 : 외부(인터넷) 통신이 가능한 네트워크를 생성합니다.";

  // setkNetworkType(b) {
  //   this.networkModel.type = b;
  //   if(b){
  //     this.network_create_message = "내부 : 외부 통신이 불가능한 사설 네트워크를 생성합니다."
  //   }else{
  //     this.network_create_message = "외부 : 외부(인터넷) 통신이 가능한 네트워크를 생성합니다."
  //   }
  // }
}
