import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogTestComponent} from './dialog-test/dialog-test.component';
import {DialogDefaultDeleteComponent} from './dialog-default-delete/dialog-default-delete.component';
import {DialogDefaultConfirmComponent} from './dialog-default-confirm/dialog-default-confirm.component';
import {DialogDefaultMultiactionComponent} from './dialog-default-multiaction/dialog-default-multiaction.component';
import {DialogCreateIpComponent} from './dialog-create-ip/dialog-create-ip.component';
import {DialogUpdateIpComponent} from './dialog-update-ip/dialog-update-ip.component';
import {DialogAssociateIpComponent} from './dialog-associate-ip/dialog-associate-ip.component';
import {DialogDisassociateIpComponent} from './dialog-disassociate-ip/dialog-disassociate-ip.component';
import {DialogStorageCreateComponent} from './dialog-storage-create/dialog-storage-create.component';
import {DialogStorageImageCreateComponent} from './dialog-storage-image-create/dialog-storage-image-create.component';
import {DialogStorageUpdateComponent} from './dialog-storage-update/dialog-storage-update.component';
import {DialogStorageAttachComponent} from './dialog-storage-attach/dialog-storage-attach.component';
import {DialogStorageDetachComponent} from './dialog-storage-detach/dialog-storage-detach.component';
import {DialogStorageSnapshotCreateComponent} from './dialog-storage-snapshot-create/dialog-storage-snapshot-create.component';
import {DialogStorageSnapshotUpdateComponent} from './dialog-storage-snapshot-update/dialog-storage-snapshot-update.component';
import {DialogServerImageCreateComponent} from './dialog-server-image-create/dialog-server-image-create.component';
import {DialogServerImageUpdateComponent} from './dialog-server-image-update/dialog-server-image-update.component';
import {DialogKeypairCreateComponent} from './dialog-keypair-create/dialog-keypair-create.component';
import {DialogKeypairCreateDetailComponent} from './dialog-keypair-create-detail/dialog-keypair-create-detail.component';
import {DialogKeypairUpdateComponent} from './dialog-keypair-update/dialog-keypair-update.component';
import {DialogSecurityGroupCreateComponent} from './dialog-security-group-create/dialog-security-group-create.component';
import {DialogSecurityGroupRuleCreateComponent} from './dialog-security-group-rule-create/dialog-security-group-rule-create.component';
import {DialogSecurityGroupRuleUpdateComponent} from './dialog-security-group-rule-update/dialog-security-group-rule-update.component';
import {DialogNetworkCreateComponent} from './dialog-network-create/dialog-network-create.component';
import {DialogNetworkExternalManageComponent} from './dialog-network-external-manage/dialog-network-external-manage.component';
import {DialogNetworkSnatManageComponent} from './dialog-network-snat-manage/dialog-network-snat-manage.component';
import {DialogPrivateNetworkCreateComponent} from './dialog-private-network-create/dialog-private-network-create.component';
import {DialogPrivateNetworkShareManageComponent} from './dialog-private-network-share-manage/dialog-private-network-share-manage.component';
import {DialogSubnetCreateComponent} from './dialog-subnet-create/dialog-subnet-create.component';
import {DialogSubnetUpdateComponent} from './dialog-subnet-update/dialog-subnet-update.component';
import {DialogServerCreateConfirmComponent} from './dialog-server-create-confirm/dialog-server-create-confirm.component';
import {DialogProjectCreateComponent} from './dialog-project-create/dialog-project-create.component';
import {DialogProjectDeleteComponent} from './dialog-project-delete/dialog-project-delete.component';
import {DialogProjectUpdateComponent} from './dialog-project-update/dialog-project-update.component';
import {DialogSubuserCreateComponent} from './dialog-subuser-create/dialog-subuser-create.component';
import {DialogSubuserUpdateComponent} from './dialog-subuser-update/dialog-subuser-update.component';
import { DialogServerUpdateComponent } from './dialog-server-update/dialog-server-update.component';
import { DialogServerInterfaceUpdateComponent } from './dialog-server-interface-update/dialog-server-interface-update.component';
import { DialogServerPortforwardingUpdateComponent } from './dialog-server-portforwarding-update/dialog-server-portforwarding-update.component';
import { DialogServerSecurityGroupUpdateComponent } from './dialog-server-security-group-update/dialog-server-security-group-update.component';
import { DialogServerWindowPasswordComponent } from './dialog-server-window-password/dialog-server-window-password.component';
import { DialogServerResizeComponent } from './dialog-server-resize/dialog-server-resize.component';
import {DialogNasCreateComponent} from './dialog-nas-create/dialog-nas-create.component';
import {DialogNasExpansionComponent} from './dialog-nas-expansion/dialog-nas-expansion.component';
import {DialogNasRuleUpdateComponent} from './dialog-nas-rule-update/dialog-nas-rule-update.component';
import {DialogNasServerInterfaceUpdateComponent} from './dialog-nas-server-interface-update/dialog-nas-server-interface-update.component';
import {DialogLoadbalancerAssociateIpComponent} from './dialog-loadbalancer-associate-ip/dialog-loadbalancer-associate-ip.component';
import {DialogLoadbalancerDisassociateIpComponent} from './dialog-loadbalancer-disassociate-ip/dialog-loadbalancer-disassociate-ip.component';
import {DialogLoadbalancerMemberUpdateComponent} from './dialog-loadbalancer-member-update/dialog-loadbalancer-member-update.component';
import {DialogLoadbalancerPoolUpdateComponent} from './dialog-loadbalancer-pool-update/dialog-loadbalancer-pool-update.component';
import {DialogLoadbalancerListenerUpdateComponent} from './dialog-loadbalancer-listener-update/dialog-loadbalancer-listener-update.component';
import {DialogLoadbalancerMonitorUpdateComponent} from './dialog-loadbalancer-monitor-update/dialog-loadbalancer-monitor-update.component';

//ldh 210120 게이트웨이 서비스 추가
import { DialogGwApiCreateComponent } from './dialog-gw-api-create/dialog-gw-api-create.component';
import { DialogGwAuthCreateComponent } from './dialog-gw-auth-create/dialog-gw-auth-create.component';
import { DialogGwRouteCreateComponent } from './dialog-gw-route-create/dialog-gw-route-create.component';
import { DialogGwRouteUpdateComponent } from './dialog-gw-route-update/dialog-gw-route-update.component';
import { DialogGwIprestrictionComponent } from './dialog-gw-iprestriction/dialog-gw-iprestriction.component';

import {DialogBucketCreateComponent} from './dialog-bucket-create/dialog-bucket-create.component';
import {DialogObjectStorageJoinComponent} from './dialog-objectstorage-join/dialog-objectstorage-join.component';
import { DialogObjectStorageFolderCreateComponent } from './dialog-objectstorage-folder-create/dialog-objectstorage-folder-create.component';
import { DialogObjectStorageUploadComponent } from './dialog-objectstorage-upload/dialog-objectstorage-upload.component';
import { DialogObjectStorageApiEndpointComponent } from './dialog-objectstorage-apiendpoint/dialog-objectstorage-apiendpoint.component';


import { Observable } from 'rxjs';
import { DialogObjectStorageCopyMoveComponent } from './dialog-objectstorage-copymove/dialog-objectstorage-copymove.component';
import { DialogObjectStorageRenameComponent } from './dialog-objectstorage-rename/dialog-objectstorage-rename.component';
import { DialogObjectStorageInfoComponent } from './dialog-objectstorage-info/dialog-objectstorage-info.component';


import { DialogBatchCreateComponent } from './dialog-batch-create/dialog-batch-create.component';

@Injectable({
  providedIn: 'root'
})
export class DialogSetService {

  constructor(public dialog: MatDialog) { }

  private current_dialog;
  openDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogTestComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  setAfterClosed():Observable<any>{
    return this.current_dialog.afterClosed();
  }
  openDefaultDeleteDialog(data:{title:string,content:string}) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogDefaultDeleteComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openDefaultConfirmDialog(data:{title:string,content:string,btnText?:string}) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogDefaultConfirmComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openDefaultMultiactionDialog(data:{checked_list:Array<any>,title:string,content:string,btnText?:string}) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogDefaultMultiactionComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openCreateIpDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogCreateIpComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openUpdateIpDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogUpdateIpComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openAssociateIpDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogAssociateIpComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openDisassociateIpDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogDisassociateIpComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  
  openStorageCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openStorageUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openStorageImageCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageImageCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openStorageAttachDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageAttachComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openStorageDetachDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageDetachComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openStorageSnapshotCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageSnapshotCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openStorageSnapshotUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogStorageSnapshotUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  
  openServerImageCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerImageCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerImageUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerImageUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openKeypairCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogKeypairCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openKeypairCreateDetailDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogKeypairCreateDetailComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openKeypairUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogKeypairUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSecurityGroupCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSecurityGroupCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSecurityGroupRuleCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSecurityGroupRuleCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSecurityGroupRuleUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSecurityGroupRuleUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openNetworkCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNetworkCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openNetworkExternalManageDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNetworkExternalManageComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openNetworkSnatManageDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNetworkSnatManageComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSubnetCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSubnetCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSubnetUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSubnetUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openPrivateNetworkCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogPrivateNetworkCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openPrivateNetworkShareManageDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogPrivateNetworkShareManageComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  
  openProjectCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogProjectCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openProjectDeleteDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogProjectDeleteComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openProjectUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogProjectUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSubuserCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSubuserCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openSubuserUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogSubuserUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  
  openServerCreateConfirmDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerCreateConfirmComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerSecurityGroupUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerSecurityGroupUpdateComponent, {
      width: '1100px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerInterfaceUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerInterfaceUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerPortforwardingUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerPortforwardingUpdateComponent, {
      width: '1100px',maxHeight: '90vh',disableClose:true ,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerWindowPasswordDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerWindowPasswordComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openServerResizeDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogServerResizeComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openNasCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNasCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openNasExpansionDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNasExpansionComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openNasServerInterfaceUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNasServerInterfaceUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openNasRuleUpdateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogNasRuleUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openLoadbalancerAssociateIpDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoadbalancerAssociateIpComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openLoadbalancerDisassociateIpDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoadbalancerDisassociateIpComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openLoadbalancerUpdateMemberDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoadbalancerMemberUpdateComponent, {
      width: '1100px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openLoadbalancerUpdatePoolDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoadbalancerPoolUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openLoadbalancerUpdateListenerDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoadbalancerListenerUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openLoadbalancerUpdateMonitorDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoadbalancerMonitorUpdateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  
  openGwApiCreateDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogGwApiCreateComponent, {
      width: '1100px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openGwAuthCreateDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogGwAuthCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openGwRouteCreateDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogGwRouteCreateComponent, {
      width: '1100px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openGwRouteUpdateDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogGwRouteUpdateComponent, {
      width: '1100px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
  openGwIpRestrictionDialog(data){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogGwIprestrictionComponent, {
      width: '720px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openBucketCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogBucketCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openObjectStorageFolderCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageFolderCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openObjectStorageUpload(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageUploadComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openObjectStorageJoinDialog() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageJoinComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default'
    });
    this.current_dialog = dialogRef;
  }

  openApiEndpointDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageApiEndpointComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openCopyMoveObjectDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageCopyMoveComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openRenameObjectDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageRenameComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openInfoObjectDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogObjectStorageInfoComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }

  openBatchCreateDialog(data) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogBatchCreateComponent, {
      width: '580px',maxHeight: '90vh',disableClose:true,panelClass: 'console-modal-default',data: data
    });
    this.current_dialog = dialogRef;
  }
}
