<!-- Container -->
<div id="Container" class="notice">
    <div class="visual tp1">
        <div class="cont_inner">
            <h2>자료실</h2>
        </div>
    </div>
    <div>
        <!-- section -->
        <section class="sec sec_1">
            <!-- sec_inner -->
            <div class="sec_inner">
                <!-- sec_content -->
                <div class="sec_content">
                    <!-- searchArea -->
                    <div class="searchArea">
                        <div class="inp inpSelect">
                            <select [value]="searchCate" (change)="onSearchCateChange($event.target.value)">
                                <option value="">전체</option>
                                <option value="요금">요금</option>
                                <option value="사이트">사이트</option>
                                <option value="이벤트">이벤트</option>
                            </select>
                        </div>
                        <div class="inp inpSearch">
                            <input type="text" (keydown.enter)="fn_materialSearch($event.target.value)" [value]="searchTitle" (change)="onSearchTitleChange($event.target.value)">
                            <button class="inpSearch-btn" (click)="fn_materialSearch(searchTitle)"></button>
                        </div>
                    </div>
                    <!-- //searchArea -->

                    <div class="boardBox tp2">
                        <table class="tbl_col_tp1 pdTp2">
                            <colgroup>
                                <col style="width:8%">
                                <col style="width:12%">
                                <col >
                                <col style="width:12%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">번호</th>
                                    <th scope="col">카테고리</th>
                                    <th scope="col">제목</th>
                                    <th scope="col">등록일</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template [ngIf]="materialList.length>0">
                                    <tr *ngFor="let material of materialList; index as i;" (click)="getMaterialView(material.articleId)">
                                        <td>{{ 10*curPage+i+1 }}</td>
                                        <td>{{ material.category ? material.category : "카테고리" }}</td>
                                        <td class="l"><a href="javascript:void(0)" >{{ material.title }}</a></td>
                                        <td>{{ material.createdDate.substring(0, 10) }}</td>
                                    </tr>
                                </ng-template>
                                <ng-template [ngIf]="materialList.length<1">
                                    <tr>
                                        <td colspan="4">조회된 내역이 없습니다.</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <app-common-paging [curPage]="curPage" [totalCount]="totalCount" (changePageGo)="getMovePage($event)"></app-common-paging>
                    </div>
                </div>
                <!-- //sec_content -->
            </div>
            <!-- //sec_inner -->
        </section>
        <!-- //section -->
    </div>
</div>
<!-- //Container -->
<hr>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>