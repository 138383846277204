import { Component, OnInit } from '@angular/core';
import { OsapiService } from '../../osapi.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FrontApiService} from '../../frontapi.service';

@Component({
  selector: 'app-mobile-console-security-group-list',
  templateUrl: './mobile-console-security-group-list.component.html',
  styleUrls: ['./mobile-console-security-group-list.component.css']
})
export class MobileConsoleSecurityGroupListComponent implements OnInit {
  securityGroupLists: any = [];
  _blank = true;

  constructor(
    private osapi: OsapiService,
    private spinner: NgxSpinnerService,
    private frontapi: FrontApiService,
  ) { }

  ngOnInit(): void {
    this.getSecurityGroupList();
  }

  getSecurityGroupList() {
    this.spinner.show();
    this.osapi.getSecurityGroupList().subscribe(
      (data: { items: [] }) => {
        this.securityGroupLists = data.items;

        for(let sg of this.securityGroupLists){
          if(sg.name == "default" ){
            sg.isDefault = true;
          }else{
            sg.isDefault = false;
          }
        }
        this._blank = ((this.securityGroupLists != 0) ? true : false);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.frontapi.changeMessage({
          type: 'toast',
          body: {
            title: '오류 알림',
            message: '일시적으로 문제가 발생하였습니다. 새로고침해주세요.'
          }
        });
      });
  }

}
