import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { MatFormFieldModule  } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { AngularCreatePdfModule } from 'angular-create-pdf';

import { WebNavigationComponent } from './web-navigation/web-navigation.component';
import { ServiceComponent } from './service/service.component';
import { ServicePriceComponent } from './service-price/service-price.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MainComponent } from './main/main.component';
import { WebRoutingModule } from './web-routing.module';
import { CustomerCenterComponent } from './customer-center/customer-center.component';
import { FaqListComponent } from './faq-list/faq-list.component';
import { InquiryListComponent } from './inquiry-list/inquiry-list.component';
import { InquiryRegisterComponent } from './inquiry-register/inquiry-register.component';
import { InquiryViewComponent } from './inquiry-view/inquiry-view.component';
import { NoticeListComponent } from './notice-list/notice-list.component';
import { NoticeViewComponent } from './notice-view/notice-view.component';
import { MaterialListComponent } from "./material-list/material-list.component";
import { MaterialViewComponent } from "./material-view/material-view.component";

import { MypageMemberComponent } from './mypage/mypage-member/mypage-member.component';
import { MypageCompanyComponent } from './mypage/mypage-company/mypage-company.component';
import { MypageAccountComponent } from './mypage/mypage-account/mypage-account.component';
import { MypagePaymentComponent } from './mypage/mypage-payment/mypage-payment.component';
import { MypageCreditComponent } from './mypage/mypage-credit/mypage-credit.component';
import { MypageServiceHistoryComponent } from './mypage/mypage-service-history/mypage-service-history.component';
import { MypageWithdrawalComponent } from './mypage/mypage-withdrawal/mypage-withdrawal.component';

import { CommonPagingComponent } from '../common/common-paging/common-paging.component';
import { CommonTopComponent } from '../common/common-top/common-top.component';

import { AdminNavComponent } from './admin/admin-nav/admin-nav.component';

import { AdminUserComponent } from './admin/admin-users/admin-user/admin-user.component';
import { AdminwithdrawalListComponent } from './admin/admin-users/admin-withdrawal-list/admin-withdrawal-list.component';
import { AdminSubuserComponent } from "./admin/admin-users/admin-subuser/admin-subuser.component";
import { AdminBoardFaqComponent } from "./admin/admin-board/admin-board-faq/admin-board-faq.component";
import { AdminBoardQnaComponent } from "./admin/admin-board/admin-board-qna/admin-board-qna.component";
import { AdminBoardNoticeComponent } from "./admin/admin-board/admin-board-notice/admin-board-notice.component";
import { AdminBoardRefComponent } from "./admin/admin-board/admin-board-ref/admin-board-ref.component";
import { AdminClaimClaimListComponent } from "./admin/admin-claim/admin-claim-claimList/admin-claim-claimList.component";
import { AdminClaimClaimComponent } from "./admin/admin-claim/admin-claim-claim/admin-claim-claim.component";
import { AdminClaimClaimPayComponent } from "./admin/admin-claim/admin-claim-claimPay/admin-claim-claimPay.component";
import { AdminClaimPayBoardComponent } from "./admin/admin-claim/admin-claim-payBoard/admin-claim-payBoard.component";
import { AdminSendMailComponent } from "./admin/admin-send/admin-send-mail/admin-send-mail.component";
import { AdminSendMaillistComponent } from "./admin/admin-send/admin-send-mail-list/admin-send-mail-list.component";
import { AdminSendSmsComponent } from "./admin/admin-send/admin-send-sms/admin-send-sms.component";
import { AdminCreditComponent } from "./admin/admin-claim/admin-claim-credit/admin-claim-credit.component";
import { AdminCreditListComponent } from "./admin/admin-claim/admin-claim-creditlist/admin-claim-creditlist.component";

import { AdminFlavorSettingComponent } from './admin/admin-flavor/admin-flavor-setting/admin-flavor-setting.component';
import { AdminFlavorListComponent } from './admin/admin-flavor/admin-flavor-list/admin-flavor-list.component';
import { AdminImageListComponent } from './admin/admin-image/admin-image-list/admin-image-list.component';
import { AdminImageSettingComponent } from './admin/admin-image/admin-image-setting/admin-image-setting.component';

import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminEquipmentListComponent } from './admin/admin-equipment/admin-equipment-list/admin-equipment-list.component';
import { AdminEquipmentMonitoringComponent } from './admin/admin-equipment/admin-equipment-monitoring/admin-equipment-monitoring.component';
import { AdminServiceDnsComponent } from './admin/admin-service/admin-service-dns/admin-service-dns.component';
import { AdminBackupServerComponent } from './admin/admin-service/admin-backup-server/admin-backup-server.component';
import { AdminBackupDbComponent } from './admin/admin-service/admin-backup-db/admin-backup-db.component';
import { AdminCommercialDbmsComponent } from './admin/admin-service/admin-commercial-dbms/admin-commercial-dbms.component';
import { AdminServiceBaremetalComponent } from './admin/admin-service/admin-service-baremetal/admin-service-baremetal.component';
import { AdminMessageQueueComponent } from './admin/admin-service/admin-message-queue/admin-message-queue.component';
import { AdminSettingComponent } from './admin/admin-setting/admin-setting.component';

import { LoginComponent } from './login/login.component';
import { MemberSignupComponent } from './member-signup/member-signup.component';
import { FindIdComponent } from './find-id/find-id.component';
import { FindPassWordComponent } from './find-password/find-password.component';
import { ResetPasswordByCodeComponent } from './reset-password-by-code/reset-password-by-code.component';

import { LoginAuthGuard, blsAdminAuthGuard,ThemeGuard } from '../auth.guards';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";


/**
 * 20200909 ldh
 * richeditor
 */
// import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { QuillModule } from 'ngx-quill'

/** i18 */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { AdminUsersLogComponent } from './admin/admin-users/admin-log/admin-users-log.component';
export function createTranslateLoader(http: HttpClient) { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); }

@NgModule({
  declarations: [
    WebNavigationComponent,
    ServiceComponent,
    ServicePriceComponent,
    ContactUsComponent,
    CustomerCenterComponent,
    MainComponent,
    LoginComponent,
    MemberSignupComponent,
    FindIdComponent,
    FindPassWordComponent,
    ResetPasswordByCodeComponent,
    FaqListComponent,
    InquiryListComponent,
    InquiryRegisterComponent,
    InquiryViewComponent,
    NoticeListComponent,
    NoticeViewComponent,
    MaterialListComponent,
    MaterialViewComponent,
    MypageMemberComponent,
    MypageCompanyComponent,
    MypageAccountComponent,
    MypagePaymentComponent,
    MypageCreditComponent,
    MypageServiceHistoryComponent,
    MypageWithdrawalComponent,
    AdminNavComponent,
    AdminUserComponent,
    AdminwithdrawalListComponent,
    AdminSubuserComponent,
    AdminBoardFaqComponent,
    AdminBoardQnaComponent,
    AdminBoardNoticeComponent,
    AdminBoardRefComponent,
    AdminClaimClaimListComponent,
    AdminClaimClaimComponent,
    AdminClaimClaimPayComponent,
    AdminClaimPayBoardComponent,
    AdminFlavorListComponent,
    AdminFlavorSettingComponent,
    AdminImageListComponent,
    AdminImageSettingComponent,
    CommonPagingComponent,
    AdminSendSmsComponent,    
    AdminSendMailComponent,
    AdminCreditComponent,
    AdminCreditListComponent,
    AdminSendMaillistComponent,
    AdminDashboardComponent,
    AdminEquipmentListComponent,
    AdminEquipmentMonitoringComponent,
    AdminServiceDnsComponent,
    AdminBackupServerComponent,
    AdminBackupDbComponent,
    AdminCommercialDbmsComponent,
    AdminServiceBaremetalComponent,
    AdminMessageQueueComponent,
    CommonTopComponent,
    RichTextEditorComponent,
    AdminUsersLogComponent,
    AdminSettingComponent,
  ],
  imports: [    
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    WebRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatRadioModule,
    /** 20201202 ldh pdf 다운로드 */
    AngularCreatePdfModule,
    // QuillModule.forRoot(),
    /** 20200813 cbkim 다국어지원 */
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient] } })
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  providers: [
    LoginAuthGuard,
    blsAdminAuthGuard,
    ThemeGuard,
  ]
})
export class WebModule {}